<template>
    <div v-if="props.disabled" class="dropzoneArea">
        <div class="uploadcontent dz-default dz-message">
            <i class="fal fa-file"></i> {{ currentFileName || "N/A" }}
        </div>
    </div>
    <div v-else @drop.prevent="onDrop" @dragover.prevent @dragenter.prevent @click="openFileDialog" class="dropzoneArea"
        ref="dropzoneElement" style="margin: 10px 0; padding: 24px; cursor: pointer;">
        <div class="uploadcontent dz-default dz-message">
            <template v-if="!currentFileName">
                <i class="fal fa-upload"></i> {{ T(props.dropText) }}
            </template>
            <template v-else>
                <i class="fal fa-file"></i> {{ currentFileName }}
                <button @click.stop="clearFile" class="clear-file">X</button>
            </template>
        </div>
        <input type="file" :accept="props.accept" ref="fileInput" style="display: none;" @change="handleFileChange" />
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { T } from '@/classes/i18n';

const dropzoneElement = ref<HTMLElement>();
const fileInput = ref<HTMLInputElement>();
const currentFileName = ref<string>('');

type ModelData = {
    filename: string
    data: string
}

const props = withDefaults(defineProps<{
    modelValue: ModelData
    dropText: string,
    accept: string
    disabled: boolean
}>(), {
    modelValue: {
        filename: '',
        data: ''
    } as any,
    dropText: "test",
    disabled: false,
});

const emit = defineEmits(['update:modelValue']);

function onDrop(event: DragEvent) {
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
        handleFiles(files);
    }
}

function openFileDialog() {
    fileInput.value?.click();
}

function handleFileChange(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
        handleFiles(files);
    }
}

function handleFiles(files: FileList) {
    const file = files[0];
    const fileName = file.name;

    currentFileName.value = fileName;

    const reader = new FileReader();
    reader.onload = (e) => {
        const result = e.target?.result as string;
        const base64String = result.split(',')[1];
        emit('update:modelValue', {
            filename: fileName,
            data: base64String
        });
    };
    reader.readAsDataURL(file);
}

function clearFile() {
    currentFileName.value = '';

    emit('update:modelValue', {
        filename: '',
        data: ''
    });

    if (fileInput.value) {
        fileInput.value.value = '';
    }
}

onMounted(() => {
    currentFileName.value = props.modelValue?.filename;
})
</script>

<style scoped>
.clear-file {
    background: none;
    border: none;
    cursor: pointer;
    color: red;
    font-weight: bold;
}
</style>