import getterHelpers from '@/helpers/helpers.getters';
import jsonHelpers from "@/helpers/helpers.json"
import { MutationTypes } from '@/store/vuex.store';
import Button, { type ButtonOptions } from '../button/button';

export interface Modal {
    "id"?: string,
    "key"?: string,
    "accountId"? : string,
    "abortable"?: boolean,
    "canUseFullscreen"?: boolean,
    "onInit"?:any,
    "onAbort"?:any,
    "content"?:{
        "title"?:{
            "text"?:string,
            "icon"?:string
        },
        "body"?:{
            "use"?:boolean,
            "content"?:string,
            "component"?:string,
            "maxHeight"?: string,
            "properties"?: {
                [property:string]:any
            }
        },
    },
    "buttons"?: Array<ModalButton>
}

export interface ModalButton {
    id?: string,
    text?: string,
    icon?: string,
    onClick?: (modalComponent:any,modal:Modal|ModalObject) => void,
    align?: "left"|"center"|"right",
    loading?: boolean,
    disabled?:boolean,
    focusOnMount?:boolean
}

type AllOptions = Partial<ModalObjectButton>
export type ModalObjectButtonOptions = AllOptions

export class ModalObjectButton implements Partial<Omit<ButtonOptions,'onClick'>> {
    id?: ButtonOptions["id"] = undefined
    text?: ButtonOptions["text"] = undefined
    title?: ButtonOptions["title"] = undefined
    htmlTooltip?: ButtonOptions["htmlTooltip"] = undefined
    icon?: ButtonOptions["icon"] = undefined
    align?: "left"|"center"|"right"
    focusOnMount?:boolean
    onClick?: (modalComponent:any,modal:ModalObject) => void = undefined
    link?: ButtonOptions["link"] = undefined
    target?: ButtonOptions["target"] = undefined
    loading?: ButtonOptions["loading"] = false
    disabled?: ButtonOptions["disabled"] = false
    topRightCircle?: ButtonOptions["topRightCircle"] = undefined
    type?: ButtonOptions['type'] = "default"
    size?: ButtonOptions['size'] = undefined
    dropdownEntries?:ButtonOptions['dropdownEntries'] = undefined
    color?:ButtonOptions['color'] = "primary"
    style?:ButtonOptions['style']
    constructor(options: ModalObjectButtonOptions) {
      if(options) {
          Object.assign(this,options)
      }
    }
    startLoader() {
      this.loading = true
    }
    stopLoader() {
      this.loading = false
    }
    disable() {
      this.disabled = true
    }
    enable() {
      this.disabled = false
    }
  }

type AllModalOptions = Partial<ModalObject>
export type ModalObjectOptions = AllModalOptions
export default class ModalObject {    
    id?: string
    key?: string
    accountId? : string
    abortable?: boolean
    canUseFullscreen?: boolean
    onInit?:(modalComponent:any) => void
    onAbort?:(modalComponent:any) => void
    content?:{
        title?:{
            text?:string
            icon?:string
        },
        body?:{
            use?:boolean,
            content?:string,
            component?:string,
            maxHeight?: string,
            properties?: {
                [property:string]:any
            }
        }
    }
    buttons?: Array<ModalObjectButton> = [
        new ModalObjectButton({
            "text":"Close",
            "icon":"fal fa-times",
            "onClick":function(modalComponent,modal) {
                this.loading = true
                modal.delete()
            },
            "align":"left",
            "loading":false
        }),
        new ModalObjectButton({
            "text":"OK",
            "icon":"fal fa-check",
            "onClick":function(modalComponent,modal) {
                this.loading = true
                setTimeout(() => {
                    modal.delete()
                },1000)
            },
            "align":"right",
            "loading":false
        })
    ]

    errors:string[] = []
    
    constructor(modalOptions:ModalObjectOptions) {
        jsonHelpers.merge(this,modalOptions)
        
    }
    show() {
        getterHelpers.useStore().commit(MutationTypes.addModal,this)
    }
    showFirst() {
        getterHelpers.useStore().commit(MutationTypes.addModalAsFirst,this)
    }
    delete() {
        getterHelpers.useStore().commit(MutationTypes.removeModal,this)
    }
    getButton(index:number) {
        return this.buttons?.[index]
    }
}