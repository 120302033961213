import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"

export interface EmailSubscriptionState {
    tenantDomain: string;
    email: string;
    token: string;
    optedIn: boolean;
}

const emailSubscription = {
    getState: async function (accountid: string, token: string): Promise<EmailSubscriptionState | false> {
        let result: EmailSubscriptionState | false = false
        try {
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request("GET", `/sms-mgt-api/api/2.0/tenants/${tenantDomain}/laas/reports/confirm/${token}`)
        }
        catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
            else {
                console.error(e)
            }
            result = false
        }
        return result
    },
    postState: async function (accountid: string, token: string, payload: { "opt": "in" | "out" }): Promise<boolean> {
        let result: boolean = false
        try {
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request("POST", `/sms-mgt-api/api/2.0/tenants/${tenantDomain}/laas/reports/confirm/${token}`, payload)

        }
        catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
            else {
                console.error(e)
            }
            result = false
        }
        return result
    }
}

export default emailSubscription