import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "../components/loader.vue";
import { vueApp } from "@/app";
import { useStore } from "@/store/vuex.store";

const utmsCheckForUpdatesDialogComponent = {
  mixins: [useGlobalMixin()],
  "name": "utms-check-for-updates-dialog",
  "template": `
        <div>
            <template v-if="initialized && !loading">
              <template v-if="hasChecked">
                

                <template v-if="Object.keys(accountsWithVulnerableUtms).length > 0">
    

                  {{ T('UTM versions were detected for which a security update is available. Please install them immediately!') }} 
                  <br><br>
                  {{ T('The following accounts are affected:') }} 
                  
                  <ul>
                    <template v-for="affectedId in Object.keys(accountsWithVulnerableUtms)">
                      <template v-if="accountsWithVulnerableUtms[affectedId].indexOf('USC') != -1">
                        <li>
                          <a :href="'#show-tenant-' + affectedId + '.sms-usc-utms-dashboard'">
                            {{ affectedId }}.sms - {{getAccountNameFromAccountId(affectedId)}}
                          </a>
                        </li>
     
                      </template>
                      <template v-else>
                        <li>
                          <a :href="'#show-tenant-' + affectedId + '.sms-utms-dashboard'">
                            {{ affectedId }}.sms - {{getAccountNameFromAccountId(affectedId)}}
                          </a>
                        </li>
                      </template>
                    </template>
                  </ul>

                  <br><br>
                  <p>
                    {{ T('More information can be found') }} <a href="https://wiki.securepoint.de/UTM/Changelog#Build_12.2.5.1" target="_blank">{{ T('here') }}</a>.
                  </p>



                </template>
                <template v-else>
                  <p>No vulnerable UTMs found</p>
                </template>


              </template>
              <template v-else>
                <p>
                    {{ T('A security update has been released for older UTM versions. Installation of this update is strongly recommended. Do you want to check now which UTMs are affected? This process may take some time.') }}
                </p>
              </template>
            </template>
            <template v-else>
                <div class="text-center padding-xs-y-8 col-xs">
                    <div class="text-size-3"><loader class="text-size-2 color-red" /></div>
                    <span v-if="loaderInfo"  style="opacity: 0.8;">{{ T(loaderInfo) }}</span>
                </div>
            </template>
        </div>
    `,
  "data": () => {
    return {
      "initialized": false,
      "loading":false,
      "loaderInfo": false,
      "hasChecked":false,
      "tenantCounter":{
        "current":0,
        "total":0
      },
      "accountsWithVulnerableUtms":{}
    }
  },
  "computed": {},
  "props": {
    "properties": {
      required: true,
      default: () => {
        return {
          "callback": undefined
        }
      }
    }
  },
  "methods": {
    "init": async function (this: any) {
      this.initialized = true
    },
    "submit": async function (this: any) {
      this.errors = []
      vueApp.$refs.modals.$refs.modal.modal.buttons[1].loading = true
      vueApp.$refs.modals.$refs.modal.modal.buttons[1].disabled = true
      this.loading = true
      vueApp.checkForVulnerableUtms()
    },
    getAccountNameFromAccountId:function(accountId:string) {
      return useStore().state.session.userInfo.scopes.list_accounts.find((accountInfo: any) => { 
        return String(accountInfo.accountid) == accountId 
      })?.accountname || ""
    }
  },
  "created": async function (this: any) {
    
  },
  "mounted": function (this: any) {
    this.init()
  },
  "watch": {
  },
  "beforeUnmount": function (this: any) { },
  "components": {
    "loader": loaderComponent
  }
}
export default utmsCheckForUpdatesDialogComponent
