<script setup lang="ts">
import devLog from "../../classes/log";
import { GenericObjectStore, type ItemlistDetail, type MenuEntry } from "@/classes/genericObjectStore";
import i18n, { T } from "@/classes/i18n";
import { useStore } from '@/store/vuex.store';
import { MutationTypes } from "@/store/vuex.store";
import { ActionTypes } from "@/store/vuex.store";
import objectStores from "@/classes/init";
import requestHandler from "@/queries/requests";
import Button from "@/templates/components/button/button";
import dialogs from "@/dialogs/dialogs";
import queries from "@/queries/queries";
import router from "@/router/router";
import moment from "moment";
import { sprintf } from "sprintf-js";
import type { License } from "@/classes/unifiedSecurity/licenses";
import type { ZeroTouchDevice } from "@/classes/mobileSecurity/zeroTouchDevices";
import loaderComponent from "./loader.vue";
import deviceInlineRenameComponent from "./device-inline-rename";
import inlineItemlistInputComponent from "./inline-itemlist-input.vue";
import regularlyUpdatedDataComponent from "./regularly-updated-data";
import tenantHelpers from "@/helpers/helpers.tenants";
import { vueApp } from "@/app";
import jsonHelpers from "@/helpers/helpers.json";
import stringHelpers from "@/helpers/helpers.strings";
import numberHelpers from "@/helpers/helpers.numbers";
import { computed, getCurrentInstance, inject, onMounted, provide, reactive, ref, watch } from "vue";
import mixinHelpers from "@/helpers/helpers.mixins";
import download from "downloadjs"
import config from "@/classes/config";
import useRouterStore from "@/router/routerStore";
import licenseHelpers from "@/helpers/helpers.license";
import products from "@/classes/objectTypes";
import type ObjectType from "@/classes/objectTypes/objectType";
import deviceHelpers from "@/helpers/helpers.devices";
import getterHelpers from "@/helpers/helpers.getters";
import arrayHelpers from "@/helpers/helpers.arrays";
import LabelComponent from "@/templates/components/label/label.vue"
// Props
const props = withDefaults(defineProps<{
	item: any,
	index?: number,
	filter?: string,
	showMenu?: boolean,
	editable?: boolean,
	clickable?: boolean
	altObjectType?: any
	altProductType?: any,
	altColor?: string,
	refreshCounterProp?: number,
	cellWidth?: number,
	viewMode?:string,
	fullTitle?:boolean
}>(), {
	cellWidth: 0,
	altColor: 'red',
	altObjectType: undefined,

	clickable: true,
	editable: true,
	showMenu: true,
	filter: '',
	index: 1000,
	fullTitle:false
});
// Ref Values
const visible = ref(true)
const loading = ref(false)
const error = ref(false)
const errorMsg = ref("")
const isHovering = ref(false)
const refreshCounter = ref(0)
const maxTitleLength = ref(12)
const devices = ref(<any>undefined)
const $el = ref(<any>null)
const editUsername = ref(false)
const editTags = ref(false)
const editAlias = ref(false)

// $data is not accessable with vue3 composition api
// <template v-if="$data[detail.editableContent.editingBoolProperty] != true"> => thisWorkaround[detail.editableContent.editingBoolProperty] != true
const thisWorkaround = reactive({
	"editTags": editTags.value,
	"editUsername": editUsername.value,
	"editAlias": editAlias.value
})
watch(editTags, () => {
	thisWorkaround.editTags = editTags.value
})
watch(editUsername, () => {
	thisWorkaround.editUsername = editUsername.value
})
watch(editAlias, () => {
	thisWorkaround.editAlias = editAlias.value
})
watch(() => props.viewMode, () => {
	setTimeout(() => {
		calcTitleLength()
	},600)
})

// Provide
provide("itemlistItemError", error)
provide("itemlistItemErrorMessage", errorMsg)	


// Computed Values
const currentInstance = getCurrentInstance()
const darkmode = computed(() => { return mixinHelpers.getDarkmode() })
const activeAccountInitialized = computed(() => { return mixinHelpers.isActiveAccountInitialized() })
const activeTenantDomain = computed(() => { return mixinHelpers.getActiveTenantDomain() })
const objectType = computed(() => { return mixinHelpers.getObjectType() || '' })
const activePage = computed(() => { return mixinHelpers.getActivePage() })
const activeAccountId = computed(() => { return mixinHelpers.getActiveAccountId() })
const activeAccount = computed(() => { return mixinHelpers.getActiveAccount() })
const ccutmStates = computed(() => { return mixinHelpers.getCcutemStates()})
const productType = computed(() => { return mixinHelpers.getProductType()})
const canUseAppleDEP = computed(() => { return mixinHelpers.canUseAppleDEP()})
const depProfiles = computed(() => { 
	return (config.canUseNewObjectType('depProfiles') ? products.mobileSecurity.depProfiles.useStore?.().getObjectStoreObjects(useStore()?.state.session.activeAccountId || '') : getterHelpers.useStore().getters.getObjects({
		"accountId": useStore()?.state.session.activeAccountId,
		"objectType": "depProfiles",
		"productType": "mobileSecurity"
	})) || []
})
const depProfileStatusInfo = computed(() => { return mixinHelpers.getDepProfileStatusInfo()})
const objectId = computed(() => { return useRouterStore().getObjectId })
// Injected Values
// Itemlist => No ObjectId , DetailsPage => ObjectId. We only inject from Itemlists.
const showInfo: boolean = (objectId.value == undefined && activePage.value != 'stats-dashboard' && activePage.value != 'secureDns-stats') ? inject("itemlistShowinfo") || true : true
const refresh = (objectId.value == undefined && activePage.value != 'stats-dashboard' && activePage.value != 'secureDns-stats') ? inject("itemlistRefresh") as Function : undefined
const activeItemsColor: string | undefined = (objectId.value == undefined && activePage.value != 'stats-dashboard' && activePage.value != 'secureDns-stats') ? inject("itemlistItemcolor") : undefined
	
// Functions
	function outputStringMaxLength(string: string, maxLength?: number, postFix?: string) {
		return stringHelpers.outputStringMaxLength(string, maxLength, postFix)
	} 

	const exportObject = async function(activeAccountId: string, objectId: string, objectType: string) {
		try {
			let productType = tenantHelpers.getProductTypeFromObjectType(objectType)
			let objectIdProperty: string = tenantHelpers.getObjectIdPropertyForObjectType(objectType)
			let objectNameProperty: string = tenantHelpers.getObjectNamePropertyForObjectType(objectType)?.primary

			let object = await (<any>queries[productType as keyof typeof queries])?.getObjectInfo(tenantHelpers.getAccountId(activeAccountId), objectType, objectId)

			let filename: string = function () {
				return objectType == 'androidProfiles' ? object.name.split('/')[3] : object[objectNameProperty]
			}()
			let extension: string = objectType.substring(0, objectType.length - 1)

			delete object[objectIdProperty]
			delete object.tenantDomain
			delete object.hasIcon
			delete object.checksums
			delete object.affectedDeviceIds
			delete object.potentiallyAffectedDeviceIds

			if (object.devices?.length) {
				object.devices = []
			}
			if (object.roles?.length) {
				object.roles = []
			}
			if (object.users?.length) {
				object.users = []
			}
			object[objectNameProperty] = filename + ' (' + (i18n.getLanguage() == 'de' ? 'Kopie' : 'Copy') + ')'

			download(new Blob([JSON.stringify(object)]), `${filename}.${extension == 'androidProfile' ? 'enterpriseProfile' : extension}`, "application/json")
		}
		catch (e: any) {
			console.error(e)
		}
	}

	function getMenuEntries(this:any): MenuEntry[] {
			let menuLinks: MenuEntry[] = []
			let item = props.item

			if (item) {

				let newProductType = config.canUseNewObjectType((props.altObjectType || objectType.value)) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
				let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]

				if (newObjectType != undefined && config.canUseNewObjectType(props.altObjectType || objectType.value)) {
					//@ts-ignore
					return (<ObjectType<any>>newObjectType).itemlistItem.getMenuEntries(activeAccountId.value, item,this)
				}

				if (objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores] instanceof GenericObjectStore) {
					return objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores].itemlist.getMenuEntries(activeAccountId.value, item)
				}
				switch (activePage.value) {
					case 'devices':
					case 'iosDevices':
						if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
							if (item?.zeroTouchDevice && item?.type == "zeroTouch") {
								// nothing
							}
							else if (jsonHelpers.getObjectProperty(item, "licenseUUID") && item?.licenseUUID != "") {
								if (!jsonHelpers.getObjectProperty(item, "depOnly")) {
									if (['ANDROID'].indexOf(item?.deviceType) == -1) {
										if (item?.configured) {
											if (item?.signedIn) {
												menuLinks.push(
													new Button({
														title: T('Details'),
														text: T('Details'),
														link: '#show-tenant-' + activeTenantDomain.value + '-' + (item?.enterpriseDevice ? 'android-' : 'ios-') + 'device-' + item?.deviceId + '-details',
														icon: 'fal fa-info-circle'
													})
												)
											}
											if (item?.deviceType == "IOS") {
												menuLinks.push(
													new Button({
														title: T('Download mobileconfig'),
														text: T('Download mobileconfig'),
														link: '#download-tenant-' + activeTenantDomain.value + '-device-' + item?.deviceId + '-mobileconfig',
														icon: 'fal fa-download'
													})
												)
												if (item?.signedIn) {
													menuLinks.push(
														new Button({
															title: T('Renew MDM-Profile'),
															text: T('Renew MDM-Profile'),
															onClick: () => {
																requestHandler.request("POST", '/sms-mgt-api/api/' + config.mgtApiVersion + '/tenants/' + activeTenantDomain.value + '/devices/' + item?.deviceId + '/reenroll')
															},
															icon: 'fal fa-sync-alt'
														})
													)
												}
											}
										}
									}

									if (item?.depDevice) {
										menuLinks.push(
											new Button({
												title: T('Assign DEP-Profile'),
												text: T('Assign DEP-Profile'),
												onClick: function () { renderAssignDepProfile(item) },
												icon: 'fal fa-link'
											})
										)
									}
									if (item?.depDeviceInfo?.profile_uuid && item?.depDeviceInfo?.profile_uuid != "") {
										menuLinks.push(
											new Button({
												title: T('Remove DEP-Profile'),
												text: T('Remove DEP-Profile'),
												onClick: async function () {
													const accountId = activeAccountId.value
													dialogs.misc.confirmDialog(
														accountId,
														T("Confirm Delete"),
														T("Do you want to remove the profile from this device?"),
														async () => {
															await queries.mobileSecurity.unassignDepProfile(useStore().state.session.activeAccountId || "", item?.depDeviceInfo?.profile_uuid, [item?.depDeviceInfo.serial_number])
															if(refresh) refresh()
															useStore().commit(MutationTypes.removeModal, { "accountId": accountId })
														}
													)
												},
												icon: 'fal fa-trash'
											})
										)
									}

								}
							}
							else {
								if (item?.configured) {
									if (item?.signedIn) {
										menuLinks.push(
											new Button({
												title: T('Assign license'),
												text: T('Assign license'),
												link: '#show-tenant-' + activeTenantDomain.value + '-licenses-dashboard',
												icon: 'fal fa-link'
											})
										)
									}
								}
								if (!jsonHelpers.getObjectProperty(item, "depOnly")) {
									if (item?.deviceType == "IOS") {
										menuLinks.push(
											new Button({
												title: T('Download mobileconfig'),
												text: T('Download mobileconfig'),
												link: '#download-tenant-' + activeTenantDomain.value + '-device-' + item?.deviceId + '-mobileconfig',
												icon: 'fal fa-download',
											})
										)
									}
								}
							}
						}

						if (!jsonHelpers.getObjectProperty(item, "depOnly")) {
							menuLinks.push(
								new Button({
									title: T('Delete'),
									text: T('Delete'),
									onClick: function () { renderDeleteDeviceDialog(item?.deviceId) },
									icon: 'fal fa-trash',
								})
							)
						}



						break;
					case 'profiles':
					case 'iosProfiles':
					case 'androidProfiles':
						if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
							if (jsonHelpers.getObjectProperty(item, 'profilename') != 'unlicensed') {
								if (jsonHelpers.getObjectProperty(item, "policy.type") == "autogenerated policy") {
									menuLinks.push(
										new Button({
											title: T('Edit Template'),
											text: T('Edit Template'),
											link: '#edit-tenant-' + activeTenantDomain.value + '-android-profile-enterprise_' + item?.profileId.slice(0, item?.profileId.indexOf("^")),
											icon: 'fal fa-edit',
										})
									)
								}
								else {

									if (activePage.value == "iosProfiles") {
										menuLinks.push(
											new Button({
												title: T('Edit'),
												text: T('Edit'),
												link: '#edit-tenant-' + activeTenantDomain.value + '-ios-profile-' + item?.profileId,
												icon: 'fal fa-edit',
											})
										)
									}
									else if (activePage.value == "androidProfiles") {
										menuLinks.push(
											new Button({
												title: T('Edit'),
												text: T('Edit'),
												link: '#edit-tenant-' + activeTenantDomain.value + '-android-profile-enterprise_' + item?.profileId,
												icon: 'fal fa-edit',
											})
										)
									}
								}
								if (jsonHelpers.getObjectProperty(item, "policy.type") != "autogenerated policy") {
									menuLinks.push(
										new Button({
											title: T('Copy'),
											text: T('Copy'),
											onClick: function () {
												saveToClipBoard(item)
											},
											icon: 'fal fa-clone'
										})
									)
								}

								if (!item?.enterpriseProfile) {
									menuLinks.push(
										new Button({
											title: T('Export'),
											text: T('Export'),
											onClick: function () { exportObject(activeTenantDomain.value, item?.profileId, 'profiles') },
											icon: 'fal fa-download',
										})
									)
									menuLinks.push(
										new Button({
											title: T('Delete'),
											text: T('Delete'),
											onClick: function () { dialogs.mobileSecurity.renderDeleteProfileDialog(activeTenantDomain.value, item?.profileId) },
											icon: 'fal fa-trash',
										})
									)
								}
								else {
									if (jsonHelpers.getObjectProperty(item, "policy.type") != "autogenerated policy") {
										menuLinks.push(
											new Button({
												title: T('Export'),
												text: T('Export'),
												onClick: function () { exportObject(activeTenantDomain.value, item?.profileId, 'androidProfiles') },
												icon: 'fal fa-download',
											})
										)
									}
									if (item?.devices?.length == 0) {
										let profileName = item.profilename
										if (jsonHelpers.getObjectProperty(item, "policy.type") == "autogenerated policy") {
											profileName = item.profilename.replace(/\^(.*)/, " ($1)")
										}
										else {
											profileName = item.profilename
										}
										menuLinks.push(
											new Button({
												title: T('Delete'),
												text: T('Delete'),
												onClick: function () { dialogs.mobileSecurity.renderDeleteEnterprisePolicyDialog(activeAccountId.value, item?.profileId, profileName) },
												icon: 'fal fa-trash',
											})
										)
									}

								}
							}
							else {
								if (item?.devices?.length == 0) {
									let profileName = item.profilename
									if (jsonHelpers.getObjectProperty(item, "policy.type") == "autogenerated policy") {
										profileName = item.profilename.replace(/\^(.*)/, " ($1)")
									}
									else {
										profileName = item.profilename
									}
									menuLinks.push(
										new Button({
											title: T('Delete'),
											text: T('Delete'),
											onClick: function () { dialogs.mobileSecurity.renderDeleteEnterprisePolicyDialog(activeAccountId.value, item?.profileId, profileName) },
											icon: 'fal fa-trash',
										})
									)
								}
							}
						} else if (item && !item?.enterpriseDevice) {
							menuLinks.push(
								new Button({
									title: T('Delete'),
									text: T('Delete'),
									onClick: function () { dialogs.mobileSecurity.renderDeleteProfileDialog(activeTenantDomain.value, item?.profileId) },
									icon: 'fal fa-trash',
								})
							)
						}
						else if (item?.devices?.length == 0) {
							let profileName = item.profilename
							if (jsonHelpers.getObjectProperty(item, "policy.type") == "autogenerated policy") {
								profileName = item.profilename.replace(/\^(.*)/, " ($1)")
							}
							else {
								profileName = item.profilename
							}
							menuLinks.push(
								new Button({
									title: T('Delete'),
									text: T('Delete'),
									onClick: function () { dialogs.mobileSecurity.renderDeleteEnterprisePolicyDialog(activeAccountId.value, item?.profileId, profileName) },
									icon: 'fal fa-trash',
								})
							)
						}
						break;
					case 'depProfiles':
						menuLinks.push(
							new Button({
								title: T('Edit'),
								text: T('Edit'),
								link: '#edit-tenant-' + activeTenantDomain.value + '-dep-profile-' + item?.profile_uuid,
								icon: 'fal fa-edit'
							}),
							new Button({
								title: T('Delete'),
								text: T('Delete'),
								onClick: function () {
									dialogs.misc.confirmDialog(activeAccountId.value, T('Confirm delete'), T('Are you sure to delete this object?'), async function () {
										await useStore().dispatch(ActionTypes.deleteObject, {
											"accountId": activeAccountId.value,
											"objectType": "depProfiles",
											"productType": "mobileSecurity",
											"objectIdProperty": "profile_uuid",
											"objectId": item?.profile_uuid
										})
										useStore().commit(MutationTypes.removeModal, { "accountId": activeAccountId.value })
									}),
										T('Delete')
								},
								icon: 'fal fa-trash',
							})
						)
						break;
					case 'apps':
						if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
							menuLinks.push(
								new Button({
									title: T('Edit'),
									text: T('Edit'),
									link: '#edit-tenant-' + activeTenantDomain.value + '-app-' + item?.appId,
									icon: 'fal fa-edit',
								}),
								new Button({
									title: T('Copy'),
									text: T('Copy'),
									onClick: function () {
										saveToClipBoard(item)
									},
									icon: 'fal fa-clone'
								}),
								new Button({
									title: T('Export'),
									text: T('Export'),
									onClick: function () { exportObject(activeTenantDomain.value, item?.appId, 'apps') },
									icon: 'fal fa-download',
								})
							)
							menuLinks.push(new Button({
								title: T('Install'),
								text: T('Install'),
								onClick: () => {
									dialogs.mobileSecurity.renderInstallOrUninstallAppDialog(activeAccountId.value, item?.appId, "install")
								},
								icon: 'fal fa-cog'
							}))
						}
						if (item?.uninstallPackageName || item.platform == "WEBCLIP") {
							menuLinks.push(new Button({
								title: T('Uninstall'),
								text: T('Uninstall'),
								onClick: () => {
									dialogs.mobileSecurity.renderInstallOrUninstallAppDialog(activeAccountId.value, item?.appId, "uninstall")
								},
								icon: 'fal fa-times'
							}))
						}
						menuLinks.push(new Button({
							title: T('Delete'),
							text: T('Delete'),
							link: '#delete-tenant-' + activeTenantDomain.value + '-app-' + item?.appId,
							icon: 'fal fa-trash',
						}))
						break;
					case 'vpns':

						if (item?.licenseUUID && licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
							menuLinks.push(
								new Button({
									title: T('Edit'),
									text: T('Edit'),
									link: '#edit-tenant-' + activeTenantDomain.value + '-vpn-' + item?.vpnId,
									icon: 'fal fa-edit',
								}),
								new Button({
									title: T('Copy'),
									text: T('Copy'),
									onClick: function () {
										saveToClipBoard(item)
									},
									icon: 'fal fa-clone'
								}),
								new Button({
									title: T('Export'),
									text: T('Export'),
									onClick: function () { exportObject(activeTenantDomain.value, item?.vpnId, "vpns") },
									icon: 'fal fa-download',
								}),
								new Button({
									title: T('Download config'),
									text: T('Download config'),
									onClick: function () {
										queries.mobileSecurity.downloadVPNConfig(activeAccountId.value, item?.vpnId, item?.vpnname)
									},
									icon: 'fal fa-download',
								}),
								new Button({
									title: T('Download CA.crt'),
									text: T('Download CA.crt'),
									onClick: function () {
										queries.mobileSecurity.downloadCA(activeAccountId.value, item?.vpnId, 'crt')
									},
									icon: 'fal fa-download',
								})
							)
						}
						menuLinks.push(
							new Button({
								title: T('Delete'),
								text: T('Delete'),
								link: '#delete-tenant-' + activeTenantDomain.value + '-vpn-' + item?.vpnId,
								icon: 'fal fa-trash',
							})
						)
						break;
					case 'certificates':
						if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
							menuLinks.push(
								new Button({
									title: T('Details'),
									text: T('Details'),
									onClick() { dialogs.mobileSecurity.renderEditCertificateDialog(activeTenantDomain.value, item?.certificateId) },
									icon: 'fal fa-info-circle',
								})
							)
						}
						menuLinks.push(
							new Button({
								title: T('Delete'),
								text: T('Delete'),
								onClick() { dialogs.mobileSecurity.renderDeleteCertificateDialog(activeTenantDomain.value, item?.certificateId) },
								icon: 'fal fa-trash',
							})
						)
						break;
					case 'alerts':
						menuLinks.push(new Button({
							title: T('Edit'),
							text: T('Edit'),
							link: '#edit-tenant-' + activeTenantDomain.value + '-alert-' + item?.alertId,
							icon: 'fal fa-edit',
						}),
							new Button({
								title: T('Delete'),
								text: T('Delete'),
								link: '#delete-tenant-' + activeTenantDomain.value + '-alert-' + item?.alertId,
								icon: 'fal fa-trash',
							}))
						break;
					case 'notifications':
						menuLinks.push(new Button({
							title: T('Edit'),
							text: T('Edit'),
							link: '#edit-tenant-' + activeTenantDomain.value + '-notification-' + item?.serviceId,
							icon: 'fal fa-edit',
						}),
							new Button({
								title: T('Delete'),
								text: T('Delete'),
								link: '#delete-tenant-' + activeTenantDomain.value + '-notification-' + item?.serviceId,
								icon: 'fal fa-trash',
							}))
						break;

					case 'utms':
						break;
					case 'uscUtms':
						let thisState = (ccutmStates.value.filter((state: any) => {
							return item?.utmId == state.deviceId
						}) || [])[0]
						if (thisState && !thisState.online) {

						}
						else {
							menuLinks.push(new Button({
								title: T('Start new websession'),
								text: T('Start new websession'),
								onClick: () => {
									objectStores.uscUtms.dialogs.renderNewWebSessionDialog(activeAccountId.value, item?.utmId)
								},
								icon: 'fal fa-laptop',
							}))
						}

						break;
					case 'reports':
						menuLinks.push(
							new Button({
								title: T("Edit"),
								text: T("Edit"),
								link: '#edit-tenant-' + activeTenantDomain.value + '-report-' + item?.reportId,
								icon: "fal fa-edit"
							}),
							new Button({
								title: T("Reports"),
								text: T("Reports"),
								onClick: () => { dialogs.unifiedReporting.showReportsDialog(item) },
								icon: "fal fa-list"
							})
						)
						break;
					case 'zeroTouchConfigurations':
						menuLinks.push(new Button({
							title: T('Edit'),
							text: T('Edit'),
							link: '#edit-tenant-' + activeTenantDomain.value + '-zerotouchconfiguration-' + (item?.name || "").split('/')[1] + '-' + item?.configurationId,
							icon: 'fal fa-edit',
						}))

						let thisDevices = (useStore().getters.getObjectTypeStore({
							"accountId": activeAccountId.value,
							"objectType": "enterpriseDevices"
						})?.items || [])
						thisDevices = thisDevices.filter((device: any) => {
							return ((device.zeroTouchInfo?.configuration != undefined) && device.zeroTouchInfo?.configuration?.split('/')[3] == (item?.name || "").split('/')[3]) || ((device.configuration != undefined) && device.configuration?.split('/')[3] == (item?.name || "").split('/')[3])
						})

						if (thisDevices.length == 0) {
							menuLinks.push(new Button({
								title: T('Delete'),
								text: T('Delete'),
								onClick: function () {
									if (config.canUseNewObjectType("zeroTouchConfigurations")) {
										products.mobileSecurity.zeroTouchConfigurations.dialogs.getDeleteObjectDialog(activeAccountId.value,item?.configurationId, (item?.name || "").split('/')[1])
									}
									else {
										dialogs.misc.confirmDialog(activeAccountId.value, T('Confirm delete'), T('Are you sure to delete this object?'), async function () {
											await useStore().dispatch(ActionTypes.deleteObject, {
												"accountId": activeAccountId.value,
												"objectType": "zeroTouchConfigurations",
												"productType": "mobileSecurity",
												"objectIdProperty": "configurationId",
												"objectId": item?.configurationId,
												"customerId": (item?.name || "").split('/')[1]
											})
											useStore().commit(MutationTypes.removeModal, { "accountId": activeAccountId.value })
										})
									}
								},
								icon: 'fal fa-trash',
							}))
						}
						break;
				}
			}
			return menuLinks
		}

function getDetails(): Array<ItemlistDetail> {

		props.refreshCounterProp;

		let thisPlatformHtml: string
		let item: any = props.item
		let objectId: string = item ? item[tenantHelpers.getObjectIdPropertyForObjectType(props.altObjectType || objectType.value)] : ""
		let inlineTenantDomain: string = "'" + activeTenantDomain.value + "'"
		let thisDetails: Array<ItemlistDetail> = []
		let thisPermissionsArray: any[] = []
		let thisDevices: any = undefined
		let thisTagsArray: any[] = []
		let thisRolesArray: any[] = []
		let thisUsersArray: any[] = []
		let thisDevicesArray: any[] = []
		let thisProfilesArray: any[] = []
		let thisDEPProfilesArray: any[] = []
		let thisTagsInlineEditable: boolean = licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid') ? (!item?.enterpriseDevice === true && item?.signedIn === true) || false : false
		let thisUsernameInlineEditable: boolean = (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid') ? item?.ownership == 'COPE' || item?.enterpriseDevice === true ? true : false : false) && item?.configured
		let thisDevicesHtml: any = ""
		let newProductType = config.canUseNewObjectType(props.altObjectType || objectType.value) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
		let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]

		if (newObjectType != undefined && config.canUseNewObjectType(props.altObjectType || objectType.value)) {
			return (<ObjectType<any>>newObjectType).itemlistItem.getDetails(activeAccountId.value, item,currentInstance)
		}
		if (objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores] instanceof GenericObjectStore) {
			return objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores].itemlist.getDetails(activeAccountId.value, item, currentInstance)
		}
		
		function capitalizeFirstLetter(string: string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}
		switch (props.altObjectType || objectType.value) {
			case 'devices':
			case 'iosDevices':
				let inlineDeviceId: string = "'" + item?.deviceId + "'"
				if (canUseAppleDEP.value && item?.depDevice === true && item?.depDeviceInfo != undefined) {
					let thisDeviceDepProfile = depProfiles.value?.find((depProfile: any) => {
						return depProfile?.profile_uuid == item?.depDeviceInfo?.profile_uuid
					})
					const profileText = (thisDeviceDepProfile ? deviceHelpers.getAliasedShortDeviceId(thisDeviceDepProfile?.profile_uuid, (thisDeviceDepProfile?.profile_name.length > 20 ? (thisDeviceDepProfile?.profile_name.substr(0, 20) + '...') : thisDeviceDepProfile?.profile_name), false) : undefined)
					if(profileText) {
						thisDEPProfilesArray.push(
							{
								"id": profileText + "_title",
								"text": profileText,
								"title": profileText,
								"icon": item?.depDeviceInfo.profile_status == 'assigned' ? "fa fa-exclamation-triangle" : undefined,
								"onClick": function () {
									router.navigate('edit-tenant-' + activeTenantDomain.value + '-dep-profile-' + thisDeviceDepProfile?.profile_uuid)
								},
								"displayType": "label"
							}
						)
					}
					else {
						thisDEPProfilesArray.push(
							{
								"id": T('Unkown') + "_title",
								"text": T('Unkown'),
								"title": T('Unkown'),
								"icon": "fa fa-question-circle",
								"displayType": "label"
							}
						)
					}
					let thisStatus: string = T(depProfileStatusInfo.value[(<keyof typeof depProfileStatusInfo.value>item?.depDeviceInfo.profile_status)])
					if (['empty', 'assigned'].indexOf(item?.depDeviceInfo.profile_status) == -1 && item?.depDeviceInfo.profile_assign_time) {
						thisStatus += T(" at ") + moment(item?.depDeviceInfo.profile_assign_time).format('DD.MM.YYYY HH:mm')
					}
					thisDEPProfilesArray.push(
						{
							"id": (thisDeviceDepProfile ? deviceHelpers.getAliasedShortDeviceId(thisDeviceDepProfile?.profile_uuid, (thisDeviceDepProfile?.profile_name.length > 20 ? (thisDeviceDepProfile?.profile_name.substr(0, 20) + '...') : thisDeviceDepProfile?.profile_name), false) : T("Unknown")) + "_state",
							"text": thisStatus,
							"title": thisStatus,
							"lineBreakBefore": true
						}
					)
				}
				if (item?.configured) {
					if (item?.profile && item?.profile?.profileId != "" && item?.profile?.status != "REVOKED" && item.profile.profileName) {
						thisProfilesArray.push(
							{
								"id": item.profile.profileName + "_title",
								"text": item.profile.profileName,
								"title": item.profile.profileName,
								"onClick": function () {
									router.navigate('edit-tenant-' + activeTenantDomain.value + '-ios-profile-' + item.profile.profileId)
								},
								"displayType": "label"
							}
						)
						if (item?.profile.status != "INSTALLED" && item?.profile.status != "") {
							thisProfilesArray.push(
								{
									"id": item.profile.profileName + "_state",
									"text": T(item?.profile.status),
									"title": T(item?.profile.status),
									"class": "bg-yellow",
									"onClick": function () {
										router.navigate('edit-tenant-' + activeTenantDomain.value + '-ios-profile-' + item.profile.profileId)
									},
									"displayType": "label"
								}
							)
						}
					}
				}

				let productName: string = ""
				if (item?.deviceFamily) {
					productName = item?.deviceFamily
				}

				else if (useStore().state.resources.apple.deviceNames[item?.info?.productName]) {
					productName = useStore().state.resources.apple.deviceNames[item?.info?.productName]
				}
				else if (useStore().state.resources.apple.deviceNames[item?.info?.model]) {
					productName = useStore().state.resources.apple.deviceNames[item?.info?.model]
				}
				else {
					productName = item?.info?.productName || ""
				}

				if (!jsonHelpers.getObjectProperty(item, "depOnly")) {
					thisDetails = [
						{
							iconClass: 'fal fa-fw fa-microchip',
							title: T('Model'),
							key: T('Model'),
							value: item ?
								item.deviceType != "UNKNOWN" ?
									(sprintf(
										"%s - %s - %s",
										item?.info?.manufacturer || "",
										productName || "",
										(item?.info?.os || "") + " " + (item?.info?.version || "")
									) || "")
									: ""
								: "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
						},
						{
							iconClass: 'fal fa-fw fa-hashtag',
							title: T('Serial Number'),
							key: T('Serial Number'),
							value: item ? (item?.info?.serial || "") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
						},
						{
							iconClass: 'fal fa-fw fa-hashtag',
							title: T('IMEI'),
							key: T('IMEI'),
							value: item ? (stringHelpers.removeSpaces(item?.info?.imei || "")) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
						}
					]


					thisDetails.push({
						iconClass: 'fal fa-fw fa-phone',
						title: T('Phone'),
						key: T('Phone'),
						value: item ? (item?.info?.PhoneNumber && item?.configured ? item?.info.PhoneNumber : '') : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					})

				if (item?.configured) {
					// Ownership
					if (item?.deviceType == 'IOS') {
						thisDetails.push(
							{
								iconClass: 'fal fa-fw fa-address-card',
								title: T('Ownership'),
								key: T('Ownership'),
								value: item ? item?.ownership : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							}
						)
					}
					else {
						thisDetails.push(
							{
								iconClass: 'fal fa-fw fa-address-card',
								title: T('Ownership'),
								key: T('Ownership'),
								value: item ? (item?.ownership == 'DEVICE_OWNER' ? 'Device Owner' : 'Profile Owner') : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
							}
						)
					}

					// Operating mode
					if(item?.info?.ModelName === "AppleTV") {
						thisDetails.push(
							{
								iconClass: 'fal fa-fw fa-address-card',
								title: T('Operating mode'),
								key: T('Operating mode'),
								value: item ? "Device Owner (COBO)" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							}
						)
					}
					else if (item?.info?.IsMultiUser === true) {
						thisDetails.push(
							{
								iconClass: 'fal fa-fw fa-address-card',
								title: T('Operating mode'),
								key: T('Operating mode'),
								value: item ? "Shared iPad" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							}
						)
					}
					else if (typeof item?.managedAppleId === "string" && item.managedAppleId !== "") {
						thisDetails.push(
							{
								iconClass: 'fal fa-fw fa-address-card',
								title: T('Operating mode'),
								key: T('Operating mode'),
								value: item ? "Profile Owner (Userenrollment)" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							}
						)
					}
					else if (item?.info?.IsSupervised === true) {
						thisDetails.push(
							{
								iconClass: 'fal fa-fw fa-address-card',
								title: T('Operating mode'),
								key: T('Operating mode'),
								value: item ? "Device Owner (COBO)" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							}
						)
					}
					else if (item?.info?.IsSupervised === false && item?.depDevice === false) {
						thisDetails.push(
							{
								iconClass: 'fal fa-fw fa-address-card',
								title: T('Operating mode'),
								key: T('Operating mode'),
								value: item ? "Profile Owner (BYOD)" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							}
						)
					}
				}
				else {
					thisDetails.push(
						{
							iconClass: 'fal fa-fw fa-address-card',
							title: T('Ownership'),
							key: T('Ownership'),
							value: item ? (T('Unconfigured')) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
						}
					)
				}

				thisDetails.push(

					{
						iconClass: 'fal fa-fw fa-shopping-cart',
						title: T('Store-Account'),
						key: T('Store-Account'),
						value: item ? (item?.info?.storeAccountActive ? (item?.configured ? T('Active') : "") : T('Inactive')) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					},
					{
						iconClass: 'fal fa-fw fa-user',
							title: T('User'),
							key: T('User'),
							value: item ? item?.configured ? (item?.username || '') : '' : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							editableContent: thisUsernameInlineEditable ? ({
								"type": "select",
								"options": "users",
								"ref": "editUser",
								"value": item?.username,
								"editingBoolProperty": "editUsername",
								"editButton": new Button({
									"onClick": function () {
										editUsername.value = true
									},
									"icon": "fal fa-edit"
								}),
								"submitFunction": async function (value: string) {
									if (value !== 'noUserSelected') {
										let payload = {
											"accountId": activeAccountId.value,
											"objectId": objectId,
											"productType": (props.altProductType || productType.value),
											"objectType": "devices",
											"queryType": item?.enterpriseDevice ? "enterpriseDeviceProperties" : "deviceProperties",
											"value": value,
											"object": { 'username': value },
											"property": "username"
										}
										try {
											await useStore().dispatch(ActionTypes.updateObjectProperty, payload)
											editUsername.value = false
										}
										catch (e: any) {
											editUsername.value = false
											error.value = true
											console.error(e)
											if (e?.errors?.errors?.[0]?.message != undefined) {
												errorMsg.value = e?.errors?.errors?.[0]?.message + ". " + T("Tags may not contain spaces or umlauts.")
											}
											setTimeout(function () {
												error.value = true
												errorMsg.value = ""
											}, 4000)
										}
									}
									// Delete Username from Device
									else {
									try {
										await requestHandler.request("DELETE", config.mgtApiUri + '/tenants/' + activeTenantDomain.value + '/devices/' + objectId + '/properties/username')
										await useStore().dispatch(ActionTypes.getObjectInfo, {
											"accountId": activeAccountId.value,
											"productType": (props.altProductType || productType.value),
											"objectType": props.altObjectType || objectType.value,
											"objectId": objectId,
										})
										editUsername.value = false
									} catch (e: any) {
										editUsername.value = false
										console.error(e)
									}
								}
								},
								"abortFunction": function () {
									editUsername.value = false
								}
							})
								: undefined
						},
						{
							iconClass: 'fal fa-fw fa-shield-alt',
							title: T('Device profile'),
							key: T('Device profile'),
							[item && thisProfilesArray.length > 0 ? "labels" : "value"]: item && thisProfilesArray.length > 0 ? thisProfilesArray : "",
						}
					)
					if (item?.managedAppleId) {
						thisDetails.push(
							{
								iconClass: 'fab fa-fw fa-apple',
								title: 'Managed Apple ID',
								key: 'Managed Apple ID',
								value: item ? item?.managedAppleId : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							}
						)
					}
					if (canUseAppleDEP.value && item?.depDevice === true && item?.depDeviceInfo != undefined) {
						thisDetails.push(
							{
								iconClass: 'fab fa-fw fa-apple',
								title: T('DEP Profile'),
								key: T('DEP Profile'),
								[item && thisDEPProfilesArray.length ? "labels" : "value"]: item && thisDEPProfilesArray.length > 0 ? thisDEPProfilesArray : "",
							}
						)
					}
				}
				else {
					thisDetails = [
						{
							iconClass: 'fal fa-fw fa-microchip',
							title: T('Model'),
							key: T('Model'),
							value: item ? sprintf(
								" %s - %s - %s",
								item?.info?.manufacturer ? item?.info.manufacturer : "",
								productName ? productName : "",
								item?.info?.os && item?.info?.version ? item?.info.os + " " + item?.info.version : ""
							) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
						},
						{
							iconClass: 'fal fa-fw fa-hashtag',
							title: T('Serial Number'),
							key: T('Serial Number'),
							value: item ? item?.info?.serial : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",

						}
					]
					if (canUseAppleDEP.value && item?.depDevice === true && item?.depDeviceInfo != undefined) {
						thisDetails.push(
							{
								iconClass: 'fab fa-fw fa-apple',
								title: T('DEP Profile'),
								key: T('DEP Profile'),
								[item && thisDEPProfilesArray.length ? "labels" : "value"]: item && thisDEPProfilesArray.length > 0 ? thisDEPProfilesArray : "",
							}
						)
					}
				}
				if (item?.iOSPerUserChannelProfiles?.length > 0) {
					let thisUserProfilesArray: any[] = []
					item?.iOSPerUserChannelProfiles.forEach((profile: any) => {
						if (profile.status.toLowerCase() == "installed") {
							thisUserProfilesArray.push(
								{
									"id": profile.profileName + "_title",
									"text": profile.profileName,
									"title": profile.profileName,
									"onClick": function () {
									},
									"displayType": "label"
								}
							)
						}
					});
					thisDetails.push({
						iconClass: 'fab fa-fw fa-apple',
						title: T('User profiles'),
						key: T('User profiles'),
						[item && thisUserProfilesArray.length ? "labels" : "value"]: item && thisUserProfilesArray.length ? thisUserProfilesArray : ""
					})
				}

				if (item?.depOnly != true) {

					if (!item?.enterpriseDevice && !item?.zeroTouchDevice) {
						thisTagsArray = item?.tags?.map(function (tag: string) {
							return {
								"id": tag,
								"text": tag,
								"title": tag,
								"onClick": undefined,
								"displayType": "label"
							}
						})
						thisDetails.push({
							iconClass: 'fal fa-fw fa-tags',
							title: T('Tags'),
							key: T('Tags'),
							[item ? "labels" : "value"]: item ? thisTagsArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							editableContent: thisTagsInlineEditable ? ({
								"type": "select2",
								"options": "tags",
								"select2Settings": {
									"tags": true,
									"multiple": true,
									"placeholder": "Select tags"
								},
								"ref": "editTags",
								"value": item?.tags || [],
								"editingBoolProperty": "editTags",
								"editButton": new Button({
									"onClick": function () {
										editTags.value = true
									},
									"icon": "fal fa-edit"
								}),
								"submitFunction": async function (value: string[]) {
									let payload = {
										"accountId": activeAccountId.value,
										"objectId": objectId,
										"productType": "mobileSecurity",
										"objectType": "devices",
										"queryType": "devices",
										"value": value,
										"object": { 'tags': value },
										"multiple": true,
										"property": "tags"
									}
									try {
										await useStore().dispatch(ActionTypes.updateObjectProperty, payload)
										getterHelpers.useStore().commit(MutationTypes.setObjectInfos, {
										'accountId': activeAccountId.value, 
										'products': {
											'mobileSecurity': {
												'tags': value.filter(arrayHelpers.onlyUniqueFilter).map(function (tag: string) {
													return { "id": tag, "text": tag }
												})
											}
										}
									})
										editTags.value = false
									}
									catch (e: any) {
										editTags.value = false
										error.value = true
										console.error(e)

										if (e.responseJSON != undefined) {
											e = e.responseJSON
										}

										if (e?.errors?.errors?.[0]?.message != undefined) {
											errorMsg.value = e?.errors?.errors?.[0]?.message + ". " + T("Tags may not contain spaces or umlauts.")
										}
										setTimeout(function () {
											error.value = false
											errorMsg.value = ""
										}, 4000)
									}
								},
								"abortFunction": function () {
									editTags.value = false
								}
							})
								: undefined
						})
					}

					if (item?.licenseInformation?.type != undefined) {
						thisDetails.push(
							{
								iconClass: 'fal fa-fw fa-key',
								title: T('License'),
								key: T('License'),
								[item ? "labels" : "value"]: item ? [{
									"id": item.licenseUUID + "_" + item.licenseInformation.name,
									"text": item.licenseInformation.name + " (" + deviceHelpers.getShortDeviceId(item?.licenseInformation?.id) + ")",
									"title": item.licenseInformation.name + " (" + deviceHelpers.getShortDeviceId(item?.licenseInformation?.id) + ")",
									"onClick": function () {
										if ((<License>item?.licenseInformation)?.state == "valid") {
											router.navigate('show-tenant-' + activeAccountId.value + '.sms-license-' + item.licenseInformation.id + '-details')
										}
									},
									"displayType": "label"
								}] : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							}
						)
					}
					else {
						thisDetails.push(
							{
								iconClass: 'fal fa-fw fa-key',
								title: T('License'),
								key: T('License'),
								[item ? "labels" : "value"]: item ? [{
									"id": "",
									"text": T("Not licensed"),
									"title": T("Not licensed"),
									"displayType": "label"
								}] : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							}
						)
					}
				}

				if (!jsonHelpers.getObjectProperty(item, "depOnly")) {
					thisDetails.push(
						{
							iconClass: 'fal fa-fw fa-clock',
							title: T('Contact'),
							key: T('Contact'),
							value: item ? undefined : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
							regularlyUpdatedData: !item ? undefined : {
								"inputValue": item ? item.enterpriseDevice ? item.enterpriseInfo.lastStatusReportTime : item.lastContact * 1000 : undefined,
								"method": (inputValue: any) => {
									let result = inputValue ? moment(inputValue).fromNow() : ""
									if (!jsonHelpers.getObjectProperty(item, "depOnly") && !jsonHelpers.getObjectProperty(item, "enterpriseDevice")) {
										let loaderHtml = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 50 50"  style="stroke-width: 4px;">
											ƒ	<circle cx="25" cy="25" r="20" style="stroke:rgba(0, 0, 0, 0.1); fill:none;"/>
												<g transform="translate(25,25) rotate(-90)">
														<circle style=" fill:none; stroke-linecap: round" stroke-dasharray="110" stroke-dashoffset="0" cx="0" cy="0" r="20">
															<animate attributeName="stroke-dashoffset" values="360;140" dur="2.2s" keyTimes="0;1" calcMode="spline" fill="freeze" keySplines="0.41,0.314,0.8,0.54" repeatCount="indefinite" begin="0"/>
															<animate attributeName="stroke" fill="freeze" dur="8s" begin="0" repeatCount="indefinite"/>
														</circle>
												</g>
											</svg>`
										if (item?.signedIn && item?.configured && licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
											result += ' <a id="pingbutton-' + item?.deviceId + '" class="btn padding-xs-y-0 btn-loader twist-in ' + (darkmode.value == "1" ? "btn-darkbluegrey" : "btn-white color-gray") + ' " onClick="arguments[0].stopPropagation();event.cancelBubble = true; app.queries.mobileSecurity.sendPushNotification(' + inlineTenantDomain + ', ' + inlineDeviceId + ')" title="' + T("Ping device") + '"><span class="animate" style="padding-top:0;padding-bottom:0;">' + loaderHtml + '</span><span style="padding-top:0;padding-bottom:0;"><i class="fal fa-sync"></i></span></a>'
										}
									}
									return result
								},
								"refreshAfterMs": 10000
							}

						}
					)
				}

				break;
			case 'profiles':
			case 'iosProfiles':
			case 'androidProfiles':
				let thisPartsArray: any[] = []

				thisRolesArray = item?.roles?.map(function (role: string) {
					return {
						"id": role,
						"text": role,
						"title": role,
						"onClick": function () {
							router.navigate('#show-tenant-' + activeTenantDomain.value + '-roles-dashboard-' + role + '')
						},
						"displayType": "label"
					}
				})
				thisTagsArray = item?.tags?.map(function (tag: string) {
					return {
						"id": tag,
						"text": tag,
						"title": tag,
						"onClick": undefined,
						"displayType": "label"
					}
				})
				thisUsersArray = item?.users?.map(function (user: string) {
					return {
						"id": user,
						"text": user,
						"title": user,
						"onClick": function () {
							router.navigate('#show-tenant-' + activeTenantDomain.value + '-users-dashboard-' + user)
						},
						"displayType": "label"
					}
				})
				if (item?.enterpriseProfile) {

					let enterpriseDevices = useStore().getters.getObjectTypeStore({
						"accountId": activeAccountId.value,
						"objectType": "enterpriseDevices",
					})?.items || []

					let thisDevicesInfo = enterpriseDevices.filter((device: any) => {
						let devicePolicyName: string = ""
						if (device.policyName != undefined) {
							devicePolicyName = device.policyName.split("/")[3]
						}
						else if (device.appliedPolicyName != undefined) {
							devicePolicyName = device.appliedPolicyName.split("/")[3]
						}
						if (devicePolicyName == item.profilename) {
							return true
						}
					})

					if (thisDevicesInfo.length >= 5) {
						thisDevicesArray = [{
							"id": "none",
							"text": thisDevicesInfo.length + " " + T('Devices'),
							"title": thisDevicesInfo.map((thisDevice: any) => {
								return deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || thisDevice?.id, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false)
							}).join(', '),
							"onClick": undefined,
							"displayType": "label"
						}]
					}
					else {
						thisDevicesArray = thisDevicesInfo?.map((thisDevice: any) => {
							return {
								"id": thisDevice.deviceId,
								"text": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || thisDevice?.id, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
								"title": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || thisDevice?.id, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
								"onClick": function () {
									router.navigate('#show-tenant-' + activeTenantDomain.value + '-enterprise-devices-dashboard-' + thisDevice.deviceId)
								},
								"displayType": "label"
							}
						})
					}
				}
				else {
					if (item?.devices.length >= 5) {
						let thisDevice: any = {}

						thisDevicesArray = [{
							"id": "none",
							"text": item?.devices.length + " " + T('Devices'),
							"title": item?.devices.map((deviceId: any) => {
								thisDevice = useStore().getters.getObject({
									"accountId": activeAccountId.value,
									"productType": "mobileSecurity",
									"objectType": "devices",
									"objectId": deviceId,
								})
								return deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || thisDevice?.id, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false)
							}).join(', '),
							"onClick": undefined,
							"displayType": "label"
						}]
					}
					else {
						thisDevicesArray = item?.devices?.map(function (deviceId: string) {
							let thisDevice: any = {}
							thisDevice = useStore().getters.getObject({
								"accountId": activeAccountId.value,
								"productType": "mobileSecurity",
								"objectType": "devices",
								"objectId": deviceId,
							})
							return {
								"id": deviceId,
								"text": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
								"title": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
								"onClick": function () {
									if (thisDevice?.deviceType == 'IOS') {
										router.navigate('#show-tenant-' + activeTenantDomain.value + '-ios-devices-dashboard-' + deviceId)
									}
									else if (thisDevice?.deviceType == 'ANDROID') {
										router.navigate('#show-tenant-' + activeTenantDomain.value + '-android-devices-dashboard-' + deviceId)
									}
								},
								"displayType": "label"
							}
						})
					}


				}


				if (item?.enterpriseProfile) {
					if (getEnterpriseProfileParts(item).length >= 5) {
						thisPartsArray = [{
							"id": "mulipleParts",
							"text": getEnterpriseProfileParts(item).length + " " + T("Parts"),
							"title": getEnterpriseProfileParts(item).join(', '),
							"displayType": "label"
						}]
					}
					else {
						thisPartsArray = getEnterpriseProfileParts(item).map((part: string) => {
							return {
								"id": item.profileId + "_" + part,
								"text": part,
								"title": part,
								"displayType": "label"
							}
						})
					}

				}
				else if (item?.profile) {

					if (Object.keys(item?.profile).length >= 5) {
						thisPartsArray.push({
							"id": "mulipleParts",
							"text": Object.keys(item?.profile).length + " " + T("Parts"),
							"title": Object.keys(item?.profile).map((profilePart) => {
								const thisPartsString = T((
									capitalizeFirstLetter(profilePart)
										.replace("OpenNetworkConfiguration", "Network")
										.replace("Exchanges", "Exchange")
										.replace("SharedDeviceConfiguration", "Shared Device")
										.replace("HomeScreenLayout", "Home Screen Layout")
										.replace("GoogleAccounts", "Google Accounts")
										.replace("AirPrint", "Air Print")
								))
								return thisPartsString
							}).join(", "),
							"displayType": "label"
						})
					}
					else {
						for (let profilePart in item?.profile) {
							const thisPartsString = T((
								capitalizeFirstLetter(profilePart)
									.replace("OpenNetworkConfiguration", "Network")
									.replace("Exchanges", "Exchange")
									.replace("SharedDeviceConfiguration", "Shared Device")
									.replace("HomeScreenLayout", "Home Screen Layout")
									.replace("GoogleAccounts", "Google Accounts")
									.replace("AirPrint", "Air Print")
							))
							thisPartsArray.push({
								"id": item.profileId + "_" + thisPartsString,
								"text": thisPartsString,
								"title": thisPartsString,
								"displayType": "label"
							})

						}
					}


				}
				thisPlatformHtml = T("Unknown")
				if (item?.platform == 'IOS') {
					thisPlatformHtml = T('Device Profile')
					if (item?.hasOwnProperty("iOSPerUserChannelUsers")) {
						thisPlatformHtml = T('Shared iPad')
					}
					if (jsonHelpers.getObjectProperty(item, "profile.custom.mobileconfig") != undefined && !item?.hasOwnProperty("iOSPerUserChannelUsers")) {
						//thisPlatformHtml = T('Apple Configurator 2 Profile')
					}
					thisDetails.push({
						iconClass: 'fal fa-fw fa-microchip',
						title: T('Type'),
						key: T('Type'),
						value: item ? thisPlatformHtml : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					})
				}
				if (item?.platform == 'TVOS') {
					thisDetails.push({
						iconClass: 'fal fa-fw fa-microchip',
						title: T('Type'),
						key: T('Type'),
						value: item ? "tvOS" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					})
				}
				if (item?.platform == 'IOS_BYOD') {
					thisDetails.push({
						iconClass: 'fal fa-fw fa-microchip',
						title: T('Type'),
						key: T('Type'),
						value: item ? "User Enrollment" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					})
				}
				if (!item?.enterpriseProfile) {
					thisDetails.push(
						{
							iconClass: 'fal fa-fw fa-users',
							title: T('Roles'),
							key: T('Roles'),
							[item ? "labels" : "value"]: item ? thisRolesArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
						},
						{
							iconClass: 'fal fa-fw fa-user',
							title: T('Users'),
							key: T('Users'),
							[item ? "labels" : "value"]: item ? thisUsersArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
						}
					)
				}
				thisDetails.push(
					{
						iconClass: 'fal fa-fw fa-mobile-alt',
						title: T('Devices'),
						key: T('Devices'),
						[item ? "labels" : "value"]: item ? thisDevicesArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					}
				)
				if (!item?.enterpriseProfile) {
					thisDetails.push(
						{
							iconClass: 'fal fa-fw fa-tags',
							title: T('Tags'),
							key: T('Tags'),
							[item ? "labels" : "value"]: item ? thisTagsArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
						}
					)
				}
				thisDetails.push(
					{
						iconClass: 'fal fas-fw fa-puzzle-piece',
						title: T('Parts'),
						key: T('Parts'),
						[item ? "labels" : "value"]: item ? thisPartsArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					}
				)
				break;
			case 'apps':
				thisRolesArray = item?.roles?.map(function (role: string) {
					return {
						"id": role,
						"text": role,
						"title": role,
						"onClick": function () {
							router.navigate('#show-tenant-' + activeTenantDomain.value + '-roles-dashboard-' + role + '')
						},
						"displayType": "label"
					}
				})
				thisTagsArray = item?.tags?.map(function (tag: string) {
					return {
						"id": tag,
						"text": tag,
						"title": tag,
						"onClick": undefined,
						"displayType": "label"
					}
				})
				thisUsersArray = item?.users?.map(function (user: string) {
					return {
						"id": user,
						"text": user,
						"title": user,
						"onClick": function () {
							router.navigate('#show-tenant-' + activeTenantDomain.value + '-users-dashboard-' + user)
						},
						"displayType": "label"
					}
				})
				if (item?.devices.length >= 5) {
					let thisDevice: any = {}

					thisDevicesArray = [{
						"id": "none",
						"text": item?.devices.length + " " + T('Devices'),
						"title": item?.devices.map((deviceId: any) => {
							thisDevice = useStore().getters.getObject({
								"accountId": activeAccountId.value,
								"productType": "mobileSecurity",
								"objectType": "devices",
								"objectId": deviceId,
							})
							return deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || thisDevice?.id, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false)
						}).join(', '),
						"onClick": undefined,
						"displayType": "label"
					}]
				}
				else {
					thisDevicesArray = item?.devices?.map(function (deviceId: string) {
						let thisDevice: any = {}
						thisDevice = useStore().getters.getObject({
							"accountId": activeAccountId.value,
							"productType": "mobileSecurity",
							"objectType": "devices",
							"objectId": deviceId,
						})
						return {
							"id": deviceId,
							"text": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
							"title": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
							"onClick": function () {
								if (thisDevice?.deviceType == 'IOS') {
									router.navigate('#show-tenant-' + activeTenantDomain.value + '-ios-devices-dashboard-' + deviceId)
								}
								else if (thisDevice?.deviceType == 'ANDROID') {
									router.navigate('#show-tenant-' + activeTenantDomain.value + '-android-devices-dashboard-' + deviceId)
								}
							},
							"displayType": "label"
						}
					})
				}

				thisPlatformHtml = T("Unknown")

				if (item?.platform == 'IOS') {
					thisPlatformHtml = T('App')
				}
				else if (item?.platform == 'WEBCLIP') {
					thisPlatformHtml = T('Webclip')
				}

				thisDetails = [
					{
						iconClass: 'fal fa-fw fa-microchip',
						title: T('Type'),
						key: T('Type'),
						value: item ? thisPlatformHtml : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-users',
						title: T('Roles'),
						key: T('Roles'),
						[item ? "labels" : "value"]: item ? thisRolesArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-user',
						title: T('Users'),
						key: T('Users'),
						[item ? "labels" : "value"]: item ? thisUsersArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-mobile-alt',
						title: T('Devices'),
						key: T('Devices'),
						[item ? "labels" : "value"]: item ? thisDevicesArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-tags',
						title: T('Tags'),
						key: T('Tags'),
						[item ? "labels" : "value"]: item ? thisTagsArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					}
				]
				break;
			case 'depProfiles':

				thisDevices = (useStore().getters.getObjectTypeStore({
					"accountId": activeAccountId.value,
					"objectType": "devices"
				})?.items || []).filter(function (device: any) {
					return device.depDevice && device.depDeviceInfo?.profile_uuid == item?.profile_uuid
				})


				thisDevicesArray = thisDevices.map(function (thisDevice: any) {
					return {
						"id": thisDevice.deviceId,
						"text": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
						"title": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
						"onClick": function () {
							if (thisDevice?.deviceType == 'IOS') {
								router.navigate('#show-tenant-' + activeTenantDomain.value + '-ios-devices-dashboard-' + thisDevice.deviceId)
							}
							else if (thisDevice?.deviceType == 'ANDROID') {
								router.navigate('#show-tenant-' + activeTenantDomain.value + '-android-devices-dashboard-' + thisDevice.deviceId)
							}
						},
						"displayType": "label"
					}
				})

				thisDetails = [
					{
						iconClass: 'fal fa-fw fa-hashtag',
						title: T('ID'),
						key: T('ID'),
						value: item ? (item?.profile_uuid || '') : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					},
					{
						iconClass: 'fal fa-fw fa-mobile-alt',
						title: T('Devices'),
						key: T('Devices'),
						[item ? "labels" : "value"]: item ? thisDevicesArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					}
				]

				break;
			case 'vppassets':
				thisDetails = [
					{
						iconClass: 'fal fa-fw fa-key',
						title: T('Available licenses'),
						key: T('Available licenses'),
						value: item ? item.availableCount + '/' + item?.totalCount : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					},
					{
						iconClass: 'fal far-fw fa-money-bill-alt',
						title: T('Pricing'),
						key: T('Pricing'),
						value: item ? item.pricingParam : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					}
				]

				break;
			case 'vpns':
				let thisSSLInterceptionText = ""

				if (item?.ssl_bump_mode) {
					const bumpModes: any = {
						"default": "Default",
						"bump": "Always",
						"splice": "Never"
					}

					thisSSLInterceptionText = bumpModes[item.ssl_bump_mode]
				}

				thisDetails = [{
					iconClass: 'fal fa-fw fa-user',
					title: T('SSL Interception'),
					key: T('SSL Interception'),
					value: item ? T(thisSSLInterceptionText) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
				},
				{
					iconClass: 'fal fa-fw fa-mobile-alt',
					title: T('Allowlist #'),
					key: T('Allowlist #'),
					value: item ? (item.cf_whitelist || []).length : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
				},
				{
					iconClass: 'fal fa-fw fa-cloud',
					title: T('Blocklist #'),
					key: T('Blocklist #'),
					value: item ? (item.cf_blacklist || []).length : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
				},
				{
					iconClass: 'fal fa-fw fa-sticky-note',
					title: T('IP addresses #'),
					key: T('IP addresses #'),
					value: item ? (item.exclude_routes || []).length : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
				}]


				if (item?.licenseInformation?.type != undefined) {
					thisDetails.push(
						{
							iconClass: 'fal fa-fw fa-key',
							title: T('License'),
							key: T('License'),
							[item ? "labels" : "value"]: item ? [{
								"id": item.utmId + "_" + item.licenseInformation.name,
								"text": item.licenseInformation.name + " (" + deviceHelpers.getShortDeviceId(item?.licenseInformation?.id) + ")",
								"title": item.licenseInformation.name + " (" + deviceHelpers.getShortDeviceId(item?.licenseInformation?.id) + ")",
								"onClick": function () {
									if ((<License>item?.licenseInformation)?.state == "valid") {
										router.navigate('show-tenant-' + activeAccountId.value + '.sms-license-' + item.licenseInformation.id + '-details')
									}
								},
								"displayType": "label"
							}] : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
						}
					)
				}
				else {
					thisDetails.push(
						{
							iconClass: 'fal fa-fw fa-key',
							title: T('License'),
							key: T('License'),
							[item ? "labels" : "value"]: item ? [{
								"id": "",
								"text": T("Not licensed"),
								"title": T("Not licensed"),
								"displayType": "label"
							}] : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
						}
					)
				}

				break;

			case 'certificates':
				thisDetails = [
					{
						iconClass: 'fal fa-fw fa-file',
						title: T('Type'),
						key: T('Type'),
						value: item ? item.type : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-info',
						title: T('CN'),
						key: T('CN'),
						value: item ? item.cn : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-info',
						title: T('DN'),
						key: T('DN'),
						value: item ? item.dn : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					}
				]
				break;
			case 'alerts':
				thisUsersArray = item?.users?.map(function (user: string) {
					return {
						"id": user,
						"text": user,
						"title": user,
						"onClick": function () {
							router.navigate('#show-tenant-' + activeTenantDomain.value + '-users-dashboard-' + user)
						},
						"displayType": "label"
					}
				})
				if (item?.applyonall) {
					thisDevicesHtml = "<span class='label'>" + T("All")
					if (tenantHelpers.getPrivacyLevel(activeAccountId.value) == "1") { thisDevicesHtml += " [" + T('Privacy mode') + "]" }
					thisDevicesHtml += "</span>"
					thisDevicesArray = [{
						"id": "All",
						"text": "All",
						"title": "All devices",
						"onClick": undefined,
						"displayType": "label"
					}]
				}
				else if (item?.devices?.length) {
					if (item?.devices.length >= 5) {
						let thisDevice: any = {}

						thisDevicesArray = [{
							"id": "none",
							"text": item?.devices.length + " " + T('Devices'),
							"title": item?.devices.map((deviceId: any) => {
								thisDevice = useStore().getters.getObject({
									"accountId": activeAccountId.value,
									"productType": "mobileSecurity",
									"objectType": "devices",
									"objectId": deviceId,
								})
								return deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || thisDevice?.id, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false)
							}).join(', '),
							"onClick": undefined,
							"displayType": "label"
						}]
					}
					else {
						thisDevicesArray = item?.devices?.map(function (deviceId: string) {
							let thisDevice: any = {}
							thisDevice = useStore().getters.getObject({
								"accountId": activeAccountId.value,
								"productType": "mobileSecurity",
								"objectType": "devices",
								"objectId": deviceId,
							})
							return {
								"id": deviceId,
								"text": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
								"title": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
								"onClick": function () {
									if (thisDevice?.deviceType == 'IOS') {
										router.navigate('#show-tenant-' + activeTenantDomain.value + '-ios-devices-dashboard-' + deviceId)
									}
									else if (thisDevice?.deviceType == 'ANDROID') {
										router.navigate('#show-tenant-' + activeTenantDomain.value + '-android-devices-dashboard-' + deviceId)
									}
								},
								"displayType": "label"
							}
						})
					}
				}
				thisDetails = [
					{
						iconClass: 'fal fa-fw fa-user',
						title: T('User'),
						key: T('User'),
						[item ? "labels" : "value"]: item ? thisUsersArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-mobile-alt',
						title: T('Devices'),
						key: T('Devices'),
						[item ? "labels" : "value"]: item ? thisDevicesArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-cloud',
						title: T('Source'),
						key: T('Source'),
						value: item ? (item.program == "spcfcd" ? T("HTTP") : T(item?.program)) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-sticky-note',
						title: T('Comment'),
						key: T('Comment'),
						value: item ? (item.comment) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					}
				]
				break;
			case 'notifications':

				let thisTopics: string = ""
				for (let i in item?.topics) {
					if (parseInt(i) >= 1 && parseInt(i) + 1 <= parseInt(item?.topics.length)) {
						thisTopics += ", "
					}
					let topic: string = item?.topics[i]

					if (topic == "/device/enrolled") {
						thisTopics += T("Enrolled device")
					}
					else if (topic == "/device/unenrolled") {
						thisTopics += T("Unenrolled device")
					}
					else if (topic == "/tenant/notification") {
						thisTopics += T("Tenant notification")
					}
				}

				thisDetails = [
					{
						iconClass: 'fal fa-fw fa-envelope',
						title: T('Type'),
						key: T('Type'),
						value: item ? capitalizeFirstLetter(item?.type).replace('Email', 'E-Mail') : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-calendar',
						title: T('Topics'),
						key: T('Topics'),
						value: item ? thisTopics : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					}
				]
				break;
			case 'utms':

				thisTagsInlineEditable = true
				thisTagsArray = item?.tags?.map(function (tag: string) {
					return {
						"id": tag,
						"text": tag,
						"title": tag,
						"displayType": "label"
					}
				})

				thisDetails = [
					{
						iconClass: 'fal fa-fw fa-server',
						title: T('Model'),
						key: T('Model'),
						value: item ? item.model : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-key',
						title: T('Expires on'),
						key: T('Expires on'),
						value: item ? moment(item?.expirationDate).format("DD.MM.YYYY") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					},


					{
						iconClass: 'fal fa-fw fa-tags',
						title: T('Tags'),
						key: T('Tags'),
						[item ? "labels" : "value"]: item ? thisTagsArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
						editableContent: thisTagsInlineEditable ? ({
							"type": "select2",
							"options": "tags",
							"select2Settings": {
								"tags": true,
								"multiple": true,
								"placeholder": "Select tags"
							},
							"ref": "editTags",
							"value": item?.tags || [],
							"editingBoolProperty": "editTags",
							"editButton": new Button({
								"onClick": function () {
									editTags.value = true
								},
								"icon": "fal fa-edit"
							}),
							"submitFunction": async function (value: string[]) {
								let payload = {
									"accountId": activeAccountId.value,
									"objectId": objectId,
									"productType": "unifiedReporting",
									"objectType": "utms",
									"queryType": "utms",
									"value": value,
									"object": {
										'utmname': item.utmname,
										'tags': value
									},
									"multiple": true,
									"property": "tags"
								}
								try {
									await useStore().dispatch(ActionTypes.updateObjectProperty, payload)
									getterHelpers.useStore().commit(MutationTypes.setObjectInfos, {
									'accountId': activeAccountId.value, 'products': {
										'mobileSecurity': {
											'tags': value.filter(arrayHelpers.onlyUniqueFilter).map(function (tag: string) {
												return { "id": tag, "text": tag }
											})
										}
									}
								})
									editTags.value = false
								}
								catch (e: any) {
									editTags.value = false
									error.value = true
									console.error(e)
									if (e.responseJSON != undefined) {
										e = e.responseJSON
									}
									if (e?.errors?.errors?.[0]?.message != undefined) {
										errorMsg.value = e?.errors?.errors?.[0]?.message + ". " + T("Tags may not contain spaces or umlauts.")
									}
									setTimeout(function () {
										error.value = false
										errorMsg.value = ""
									}, 4000)
								}
							},
							"abortFunction": function () {
								editTags.value = false
							}
						})
							: undefined
					},

					{
						iconClass: 'fal fa-fw fa-user',
						title: T('Licensee'),
						key: T('Licensee'),
						value: item ? (jsonHelpers.getObjectProperty(item, 'messages.spcli-system-info.license.organisation.name') ? item?.messages['spcli-system-info'].license.organisation.name : "") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					},
					{
						iconClass: 'fal fa-fw fa-user',
						title: T('License'),
						key: T('License'),
						[item ? "labels" : "value"]: item ? [{
							"id": item.utmId + "_" + item.license?.name,
							"text": item.license?.name + " (" + deviceHelpers.getShortDeviceId(item?.utmId) + ")",
							"title": item.license?.name + " (" + deviceHelpers.getShortDeviceId(item?.utmId) + ")",
							"onClick": function () {
								router.navigate('show-tenant-' + activeAccountId.value + '.sms-license-' + item.license.id + '-details')
							},
							"displayType": "label"
						}] : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
					}
				]




				break;
			case 'reports':
				let recipientsArray: any[] = []

				for (let i in item?.recipients) {
					recipientsArray.push({
						"id": item?.recipients[i],
						"text": item?.recipients[i],
						"lineBreakAfter": true,
						"title": (item?.confirmedRecipients || []).indexOf(item?.recipients[i]) != -1 ? T('Confirmed') : T('Not confirmed'),
						"icon": (item?.confirmedRecipients || []).indexOf(item?.recipients[i]) != -1 ? 'fas fa-fw fa-check' : 'fas fa-fw fa-times',
						"displayType": "label"
					})
				}
				let periodHtml: string = ""
				for (let i in item?.period) {
					periodHtml += T(item?.period[i])
					if (parseInt(i) != item?.period.length - 1) {
						periodHtml += ", "
					}
				}

				thisDetails = [
					{
						iconClass: 'fal fa-fw fa-info',
						title: T('Type'),
						key: T('Type'),
						value: item ? (item?.type?.replace("esr", "USR")) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					},
					{
						iconClass: 'fal fa-fw fa-paint-brush',
						title: T('Theme'),
						key: T('Theme'),
						value: item ? (item?.theme) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					},
					{
						iconClass: 'fal fa-fw fa-clock',
						title: T('Period'),
						key: T('Period'),
						value: item ? (periodHtml) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					},
					{
						iconClass: 'fal fa-fw fa-envelope',
						title: T('Recipients'),
						key: T('Recipients'),
						[item ? "labels" : "value"]: item ? recipientsArray : ""
					},
					{
						iconClass: 'fal fa-fw fa-hashtag',
						title: T('Reports'),
						key: T('Reports'),
						value: item ? undefined : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
						labels: [{
							title: (item?.filesCount || 0) + ' ' + T('Reports'),
							text: (item?.filesCount || 0) + ' ' + T('Reports'),
							onClick: () => { dialogs.unifiedReporting.showReportsDialog(item) },
							icon: 'fal fa-edit',
							class: "bg-red",
							displayType: "label"
						}]
					}
				]
				break;
			case 'zeroTouchConfigurations':
				thisDevices = (useStore().getters.getObjectTypeStore({
					"accountId": activeAccountId.value,
					"objectType": "zeroTouchDevices"
				})?.items || [])

				let enterpriseDevicesRaw = useStore().getters.getObjectTypeStore({
					"accountId": activeAccountId.value,
					"objectType": "enterpriseDevices"
				})?.items || []

				thisDevices = thisDevices.filter((device: ZeroTouchDevice) => {
					return ((device.configuration != undefined) && device.configuration?.split('/')[3] == (item?.name || "").split('/')[3]) || ((device.configuration != undefined) && device.configuration?.split('/')[3] == (item?.name || "").split('/')[3])
				})
				thisDevicesArray = thisDevices.map(function (device: ZeroTouchDevice) {

					let enterpriseDevice = tenantHelpers.getEnterpriseDeviceByZeroTouchDeviceIdentifier(device.deviceIdentifier, enterpriseDevicesRaw)

					let thisTitle = deviceHelpers.getAliasedShortDeviceId(enterpriseDevice?.deviceId || device.deviceId, enterpriseDevice?.alias || device.deviceIdentifier?.imei || device.deviceIdentifier?.serialNumber || "", false)
					return {
						"id": device.deviceId,
						"text": thisTitle,
						"title": thisTitle,
						"onClick": function () {
							router.navigate('#show-tenant-' + activeTenantDomain.value + '-enterprise-devices-dashboard-' + (device.deviceIdentifier?.imei ? stringHelpers.removeSpaces(device.deviceIdentifier.imei) : device.deviceId))
						},
						"displayType": "label"
					}
				})

				let dpcInfos = (item?.dpcExtras && item.dpcExtras != "undefined" ? JSON.parse(item?.dpcExtras || "{}") : undefined);
				let enrollmentTokenId = dpcInfos ? dpcInfos?.["android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE"]?.["com.google.android.apps.work.clouddpc.EXTRA_ENROLLMENT_TOKEN"] : undefined

				thisDetails = [
					{
						iconClass: 'fal fas-fw fa-building',
						title: T('Company'),
						key: T('Company'),
						value: item ? (item?.companyName) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					},
					{
						iconClass: 'fal fas-fw fa-check',
						title: T('Default'),
						key: T('Default'),
						value: item ? (item?.isDefault ? T("Yes") : T("No")) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
					},
					{
						iconClass: 'fal fas-fw fa-check',
						title: T('Enrollmenttoken'),
						key: T('Enrollmenttoken'),
						value: item && enrollmentTokenId ? enrollmentTokenId : ""
					},
					{
						iconClass: 'fal fas-fw fa-mobile',
						title: T('Devices'),
						key: T('Devices'),
						[item ? "labels" : "value"]: item ? thisDevicesArray : ""
					}
				]
				break;
		}
		return thisDetails
	}

	function saveToClipBoard (object: any) {
		
		if(object) {
			if (typeof object == "object") {
				object = JSON.parse(JSON.stringify(object))
			}
			if (activePage.value === "androidProfiles") {
				// Enterprise Profiles
				object.oldItemName = object.profileId
				object.profilename = object.profileId + T("_Copy")
				object.name = object.profileId + T("_Copy")
				object.devices = []
				delete object.profileId
				delete object.smsManagedConfiguration
				delete object.type;
				if(object.policy) {
					delete object.policy.smsManagedConfiguration
					delete object.policy.type

				}
				if (config.canUseNewObjectType("androidProfiles")) {
					delete object.profilename
				}
				(object.policy?.applications || object?.applications || []).forEach((application: any) => {
					if (jsonHelpers.getObjectProperty(application, "managedConfigurationTemplate.configurationVariables.%users%") != undefined) {
						application.managedConfigurationTemplate.configurationVariables["%users%"] = ""
						delete application.managedConfigurationTemplate.configurationVariables["%users%"]
					}
				});
				
			}
			else if(activePage.value === "iosProfiles") {
				object.oldItemName = object.profilename
				object.profilename = object.profilename + T("_Copy")
				object.devices = []
				delete object.profileId
			}
			else if (activePage.value === 'apps') {
				object.oldItemName = object.name
				object.name = object.name + T("_Copy")
				delete object.appId
				delete object.tenantDomain
			}
			else if (activePage.value === 'vpns') {
				object.oldItemName = object.vpnname
				object.vpnname = object.vpnname + T("_Copy")
				delete object.vpnId
				delete object.tenantDomain
			}
	
			useStore().commit(MutationTypes.setClipboard, {
				"objectType": activePage.value,
				"objects": [object]
			})
			useStore().dispatch(ActionTypes.addNotification, {
				"accountId": activeAccountId.value,
				"content": {
					"title": {
						"icon": "fal fa-exclamation-triangle",
						"text": "Clipboard"
					},
					"body": {
						"content": "Added item to clipboard"
					}
				}
			})
		}
	}
	function getStatusText(item: any) {
		let result: string = item ? (!item?.configured ? T('Unconfigured') : item?.signedIn === false ? T('Signed out') : T('Signed in')) : ""
		return result
	}
	function isItemClickable(item: any) {
		let result: boolean = true
		let newProductType = config.canUseNewObjectType((props.altObjectType || objectType.value)) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
		let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]

		if (!item) {
			return result
		}
		else if (newObjectType != undefined && config.canUseNewObjectType((props.altObjectType || objectType.value))) {
			return (<ObjectType<any>>newObjectType).itemlistItem.isClickable(activeAccountId.value,item)
		}
		else if (objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores] instanceof GenericObjectStore) {
			return objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores].itemlist.isClickable(activeAccountId.value, item)
		}
		switch (props.altObjectType || objectType.value) {
			case 'devices':
			case 'iosDevices':
				if (!licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
					result = false
				}
				if ((item?.licenseUUID == '' && item?.configured) || item?.signedIn === false || item?.depOnly || (item?.zeroTouchDevice && item?.type == 'zeroTouch')) {
					result = false
				}
				if (!item.enterpriseDevice && item.deviceType == 'ANDROID') {
					result = false
				}
				if((item?.info?.ProductName || "")?.indexOf("Mac") != -1) {
					result = false
				}
				break;
			case 'profiles':
			case 'iosProfiles':
			case 'androidProfiles':
				if (!licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
					result = false
				}
				if (jsonHelpers.getObjectProperty(item, 'policy.type') == 'autogenerated policy' || jsonHelpers.getObjectProperty(item, 'profilename') == 'unlicensed') {
					result = false
				}
				break;
			case 'certificates':
			case 'vpns':
			case 'apps':
				if (!licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
					result = false
				}
				break;
		}
		if (!props.clickable) {
			result = false
		}
		return result
	}
	function isItemDisabled() {
		let item: any = props.item
		let result: boolean = false
		let newProductType = config.canUseNewObjectType((props.altObjectType || objectType.value)) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
		let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]

		if (!item) {
			return result
		}
		else if (newObjectType != undefined && config.canUseNewObjectType((props.altObjectType || objectType.value))) {
			return (<ObjectType<any>>newObjectType).itemlistItem.getDisabledState(activeAccountId.value,item)
		}
		else if (objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores] instanceof GenericObjectStore) {
			return objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores].itemlist.isDisabled(activeAccountId.value, item)
		}
		else if (activePage.value == 'iosDevices' && item?.configured == true && item?.licenseUUID == '' && item?.signedIn) {
			result = true
		}
		else if (activePage.value == 'users' && item?.vppOnly && item?.vppInfo.status == 'Retired') {
			result = true
		}
		else if (activePage.value == 'profiles' && !tenantHelpers.hasFunctionality(activeAccountId.value, "dep") && item?.hasOwnProperty("iOSPerUserChannelUsers")) {
			result = true
		}
		return result
	}
	function toggleDropDown(elementID: string, targetName: string) {
		let $currentElement = $('#' + elementID)
		let $currentTarget = $('*[data-name="' + targetName + '"]')
		if ($currentElement.hasClass('open')) {
			$currentElement.removeClass('open');
			$currentTarget.removeClass('open')
			$currentTarget.off()
		}
		else {
			$currentElement.addClass('open');
			$currentTarget.addClass('open').focus()
				.on('focusout', function () {
					if (!$currentTarget.is(":hover")) {
						$currentTarget.removeClass('open')
						$currentElement.removeClass('open')
						$currentTarget.off('focusout', function () {
							$currentTarget.removeClass('open')
						})
					}
					else {
						$currentTarget.focus()
					}
				})
			$currentTarget.children('li').on('click', function () {
				$currentElement.removeClass('open')
				$currentTarget.removeClass('open')
				$currentTarget.off()
			})
		}
	}
	function getIconBackgroundImage(item: any) {
		let result: any = null
		if (!item) {
			return result
		}

		let newProductType = config.canUseNewObjectType((props.altObjectType || objectType.value)) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
		let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]

		if (newObjectType != undefined && config.canUseNewObjectType((props.altObjectType || objectType.value))) {
			return (<ObjectType<any>>newObjectType).itemlistItem.getIconBackgroundImage(activeAccountId.value, item)
		}

		if (objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores] instanceof GenericObjectStore) {
			const getIconBackgroundImage = objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores].itemlist.getIconBackgroundImage
			return typeof getIconBackgroundImage == "function" ? getIconBackgroundImage(activeAccountId.value, item) : getIconBackgroundImage
		}
		switch (props.altObjectType || objectType.value) {
			case 'apps':
				if (item?.hasIcon) {
					result = 'background-image:url(/sms-mgt-api/api/' + config.mgtApiVersion + '/tenants/' + activeTenantDomain.value + '/apps/' + item?.appId + '/icon)'
				}
				else if (item && item['Icon:data']) {
					result = 'background-image:url(data:image/png;base64,' + item['Icon:data'] + ')'
				}
				else if (item?.assetInfo?.artworkUrl60) {
					result = 'background-image:url(' + item?.assetInfo.artworkUrl60 + ')'
				}
				break
			case 'vppassets':
				if (item?.assetInfo?.artworkUrl60) {
					result = 'background-image:url(' + item?.assetInfo.artworkUrl60 + ')'
				}
				break
		}
		return result
	}
	function getIconBackground(item: any, hover: boolean = false) {
		let result: any = !hover ? 'bg-' + (props.altColor || activeItemsColor || 'red') : 'hover-bg-' + (props.altColor || activeItemsColor || 'red')
		if (!item) {
			return result
		}
		let newProductType = config.canUseNewObjectType((props.altObjectType || objectType.value)) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
		let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]

		if (newObjectType != undefined && config.canUseNewObjectType((props.altObjectType || objectType.value))) {
			return (<ObjectType<any>>newObjectType).itemlistItem.getIconBackground(activeAccountId.value, item)
		}
		if (objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores] instanceof GenericObjectStore) {
			return isItemChecked(item) ? "bg-" + objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores].settings.appearance.color : "hover-bg-" + objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores].settings.appearance.color
		}
		if (activePage.value == 'devices' && hover) {
			result = item?.deviceType == 'IOS' ? 'hover-bg-darkblue' : 'hover-bg-android'
		}
		else if (activePage.value == 'devices' && !hover) {
			result = item?.deviceType == 'IOS' ? 'bg-darkblue' : 'bg-android'
		}
		else if (activePage.value == 'profiles') {
			result = 'bg-red'
		}
		return result
	}

		function renderAssignDepProfile(deviceInfo?: any) {
			let newModal: any = {
				"id": "assignDepProfile",
				"accountId": activeAccountId.value,
				"abortable": true,
				"content": {
					"title": {
						"text": T('Assign DEP-Profile'),
						"icon": "fal fa-exclamation-triangle",
					},
					"body": {
						"use": true,
						"content": undefined,
						"component": "dep-profile-assignment",
						"properties": {
							"deviceInfo": deviceInfo,
						}
					},
				},
				"buttons": [
					{
						loading: false,
						onClick: function () {
							getterHelpers.useStore().commit(MutationTypes.removeModal, { "accountId":activeAccountId.value })
						},
						icon: "fal fa-times",
						text: T('Cancel'),
						align: "left",
						disabled: false
					},
					{
						loading: false,
						onClick: async function (modal: any) {
							this.disabled = true
							let profileUUID: string = modal.$refs.modalComponent.form.depProfile.value

							let deviceSerials: string[] = deviceInfo != undefined ? [deviceInfo.depDeviceInfo.serial_number] : modal.$refs.modalComponent.form.depDevices.value
							try {
								await queries.mobileSecurity.assignDepProfile(activeAccountId.value, profileUUID, deviceSerials);
								if(refresh) refresh()
							}
							catch (e: any) {
								console.error(e)
							}
							getterHelpers.useStore().commit(MutationTypes.removeModal, { "accountId":activeAccountId.value })
						},
						icon: "fal fa-save",
						text: T('Save'),
						align: "right",
						disabled: false
					}
				]
			}
			useStore().dispatch(ActionTypes.addModal, newModal)
		}
	function onIconMouseOver () {
		isHovering.value = true
	}
	function onIconMouseLeave() {
		isHovering.value = false
	}
	function onItemClick(item: any) {
		let newProductType = config.canUseNewObjectType((props.altObjectType || objectType.value)) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
		let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]

		if (item && newObjectType != undefined && config.canUseNewObjectType((props.altObjectType || objectType.value))) {
			(<ObjectType<any>>newObjectType).itemlistItem.onClick(activeAccountId.value, item)
		}
		else if (item && objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores] instanceof GenericObjectStore) {
			objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores].itemlist.onClick(activeAccountId.value, item)
		}
		else if (item) {
			switch (activePage.value) {
				case 'devices':
				case 'iosDevices':
					if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid') && item?.deviceId) {

						if (!item?.configured && !item.depOnly) {
							if (item?.managedAppleId) {
								dialogs.mobileSecurity.assignDeviceOwnership(activeAccountId.value, "mobileSecurity", "managedAppleIdDevices", item?.deviceId,item)
							}
							else {
								dialogs.mobileSecurity.assignDeviceOwnership(activeAccountId.value, "mobileSecurity", "devices", item?.deviceId,item)
							}
						}
						else if (!(item?.configured && item.consent == "") && item.consent != config.devicesConsentDate) {
							dialogs.mobileSecurity.assignDeviceOwnership(activeAccountId.value, "mobileSecurity", "devicesConsent", item?.deviceId,item)
						}
						else if (item?.depOnly) {
							renderAssignDepProfile(item)
						}
						else if (item?.licenseUUID == '' || !item?.signedIn || item?.depOnly) {
							// do nothing
						}
						else {
							router.navigate('#show-tenant-' + activeTenantDomain.value + '-ios-device-' + item?.deviceId + '-details')
						}

					}
					break;
				case 'iosProfiles':
					if (jsonHelpers.getObjectProperty(item, 'profilename') != 'unlicensed' && !item?.enterpriseProfile && item?.profileId) {
						router.navigate('#edit-tenant-' + activeTenantDomain.value + '-ios-profile-' + item?.profileId)
					}
					break;
				case 'androidProfiles':
					if (item?.enterpriseProfile && jsonHelpers.getObjectProperty(item, 'profilename') != 'unlicensed' && item?.profileId) {
						router.navigate('#edit-tenant-' + activeTenantDomain.value + '-android-profile-enterprise_' + item?.profileId)
					}
					break;
				case 'depProfiles':
					if (jsonHelpers.getObjectProperty(item, 'profile_uuid') != undefined && licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid') && item?.profile_uuid) {
						router.navigate('#edit-tenant-' + activeTenantDomain.value + '-dep-profile-' + item?.profile_uuid)
					}
					break;
				case 'apps':
					if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid') && item?.appId) {
						router.navigate('#edit-tenant-' + activeTenantDomain.value + '-app-' + item?.appId)
					}
					break;
				case 'vppassets':
					if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid') && item?.adamIdStr) {
						router.navigate('#show-tenant-' + activeTenantDomain.value + '-vpp-asset-' + item?.adamIdStr + '-details')
					}
					break;
				case 'vpns':
					if (item?.licenseUUID != '' && licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid') && item?.vpnId) {
						router.navigate('#edit-tenant-' + activeTenantDomain.value + '-vpn-' + item?.vpnId)
					}
					break;
				case 'certificates':
					if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid') && item?.certificateId) {
						dialogs.mobileSecurity.renderEditCertificateDialog(activeTenantDomain.value, item?.certificateId)
					}
					break;
				case 'alerts':
					if (item?.alertId) {
						router.navigate('#edit-tenant-' + activeTenantDomain.value + '-alert-' + item?.alertId)
					}
					break;
				case 'notifications':
					if (item?.serviceId) {
						router.navigate('#edit-tenant-' + activeTenantDomain.value + '-notification-' + item?.serviceId)
					}
					break;
				case 'utms':
					if (item?.utmId) {
						router.navigate('#edit-tenant-' + activeTenantDomain.value + '-utm-' + item?.utmId)
					}
					break;
				case 'reports':
					if (item?.reportId) {
						router.navigate('#edit-tenant-' + activeTenantDomain.value + '-report-' + item?.reportId)
					}
					break;
				case 'zeroTouchConfigurations':
					if (item?.configurationId) {
						router.navigate('edit-tenant-' + activeTenantDomain.value + '-zerotouchconfiguration-' + (item?.name || "").split('/')[1] + '-' + item?.configurationId)
					}
					break;
			}
		}
	}
	function getTitle(item?: any) {
		let result: any = {
			"title": "",
			"small": undefined,
			"link": undefined
		}

		if (item) {
			let newProductType = config.canUseNewObjectType((props.altObjectType || objectType.value)) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
			let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]
			
			if (newObjectType != undefined && config.canUseNewObjectType((props.altObjectType || objectType.value))) {
				return (<ObjectType<any>>newObjectType).itemlistItem.getTitle(item,currentInstance)
			}
			if (objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores] instanceof GenericObjectStore) {
				return objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores].itemlist.getTitle(item, currentInstance)
			}
			switch (props.altObjectType || objectType.value) {
				case 'devices':
				case 'iosDevices':
					if (item?.alias) {
						result.title = item?.alias
						result.small = '(' + deviceHelpers.getShortDeviceId(item?.deviceId) + ')'
					}
					else {
						result.title = item.zeroTouchDevice == true ? deviceHelpers.getShortDeviceId(stringHelpers.removeSpaces(item?.info?.imei)) : deviceHelpers.getShortDeviceId(item?.deviceId)
					}
					if ((item?.configured || item.consent == config.devicesConsentDate) && licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
						result.link = {
							"innerHtml": "<i class=\"fal fa-edit\"></i>",
							"onClick": function () {
								editAlias.value = true
							},
							"showIf": function () {
								return editAlias.value == false
							}
						}
					}
					break;
				case 'profiles':
				case 'iosProfiles':
				case 'androidProfiles':
					if (jsonHelpers.getObjectProperty(item, "policy.type") == "autogenerated policy") {
						result.title = item?.profilename.replace(/\^(.*)/, " ($1)")
					}
					else {
						result.title = item?.profilename
					}
					break;
				case 'apps':
					result.title = item?.name
					break;
				case 'vpns':
					result.title = item?.vpnname
					break;
				case 'certificates':
					result.title = item?.name
					break;
				case 'alerts':
					result.title = item?.alertname
					break;
				case 'notifications':
					result.title = item?.servicename
					break;
				case 'utms':
					result.title = item?.utmname
					break;
				case 'reports':
					result.title = item?.reportname
					break;
				case 'depProfiles':
					result.title = item?.profile_name != item?.profile_uuid
						? (item?.profile_name.length > 20 ? (item?.profile_name.substr(0, 20) + '...') : item?.profile_name)
						: T('Apple DEP Profile')
					result.small = "(" + deviceHelpers.getAliasedShortDeviceId(item?.profile_uuid, '', false) + ")"
					break;
				case 'vppassets':
					result.title = jsonHelpers.getObjectProperty(item, "assetInfo.trackName") ? jsonHelpers.getObjectProperty(item, "assetInfo.trackName") : jsonHelpers.getObjectProperty(item, "adamIdStr")
					break;
				default:
					let objectNameProperty: any = tenantHelpers.getObjectNamePropertyForObjectType(props.altObjectType || objectType.value).primary
					result.title = item[objectNameProperty] || "Missing name";
					break;
			}
		}
		return result
	}
	function getLabels(item: any) {
		let thisLabels: any = []
		if (item) {
			let newProductType = config.canUseNewObjectType((props.altObjectType || objectType.value)) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
			let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]

			if (newObjectType != undefined && config.canUseNewObjectType((props.altObjectType || objectType.value))) {
				return (<ObjectType<any>>newObjectType).itemlistItem.getLabels(activeAccountId.value, item)
			}
			if (objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores] instanceof GenericObjectStore) {
				return objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores].itemlist.getLabels(activeAccountId.value, item)
			}
			switch (activePage.value) {
				case 'devices':
				case 'iosDevices':
					var timeEnrolled = item?.enrolledAt ? Date.now() - (item?.enrolledAt * 1000) : null
					if (timeEnrolled != null) {
						if (timeEnrolled < (1000 * 60 * 60 * 24)) {
							thisLabels.push({
								title: T("New"),
								text: T("New"),
								class: "bg-green"
							})
						}
					}
					if (((!(item?.configured && item.consent == "") && item.consent != config.devicesConsentDate) || !item?.configured) && !item.depOnly) {
						thisLabels.push({
            				title: T('Terms not accepted'),
            				text: T('Terms not accepted'),
            				class: 'bg-red'
          				})
					}

					if (item?.depDevice == true) {
						thisLabels.push({
							title: T("DEP"),
							text: T("DEP"),
							class: ""
						})
					}
					if (item?.declarativeManagement?.managed == true) {
						thisLabels.push({
							title: T("Status reporting"),
							text: T("Status reporting"),
							class: ""
						})
					}

					if (item.availableUpdates?.length > 0 && (item.info?.supervised || item.info?.IsSupervised)) {
						thisLabels.push({
							title: T("Update available"),
							text: T("Update available"),
							class: "bg-green"
						})
					}
					if (item?.zeroTouchDevice == true) {
						thisLabels.push({
							title: T("Zero Touch"),
							text: T("Zero Touch"),
							class: ""
						})
					}

					if (item?.signedIn === false && !item?.enterpriseDevice && !item?.depOnly) {
						thisLabels.push({
							title: T("Signed out"),
							text: T("Signed out"),
							class: "bg-yellow"
						})
					}
					if (item?.configured) {
						if (item?.licenseUUID != "") {
							if (!jsonHelpers.getObjectProperty(item, "depOnly") && !item.enterpriseDevice) {
								if (item?.info?.supervised == true || item.info?.IsSupervised == true) {
									thisLabels.push({
										title: T("Supervised"),
										text: T("Supervised"),
										class: ""
									})
								}
							}
							if (item?.lostMode == true) {
								thisLabels.push({
									title: T("Lost"),
									text: T("Lost"),
									class: "bg-red"
								})
							}
						}
						else if (item.signedIn) {
							thisLabels.push({
								title: T("Not licensed"),
								text: T("Not licensed"),
								class: "bg-red"
							})
						}
					}
					else {
						if (item?.deviceType == "UNKNOWN") {
							thisLabels.push({
								title: T("Waiting for device info"),
								text: T("Waiting for device info"),
								class: "bg-yellow"
							})
						}
						else {
							thisLabels.push({
								title: T("Unconfigured"),
								text: T("Unconfigured"),
								class: ""
							})
						}
					}
					if (item?.licenseInformation != undefined && !licenseHelpers.isLicenseValid(item.licenseInformation)) {
						thisLabels.push({
							title: T("License expired"),
							text: T("License expired"),
							class: "bg-red"
						})
					}
					break;
				case 'vpns':
					if (item?.licenseUUID == "" || item?.licenseUUID == undefined) {
						thisLabels.push({
							title: T("Not licensed"),
							text: T("Not licensed"),
							class: "bg-red"
						})
					}
					break;
				case 'profiles':
				case 'iosProfiles':
				case 'androidProfiles':
					if (item?.priority != null) {
						thisLabels.push({
							'title': T('Priority') + ': ' + item?.priority,
							'text': item?.priority,
							'class': '',
							'icon': "fal fa-sort"
						})
					}
					let statusClass: string = ""
					if (['UPDATED', 'REVOKE', 'PUBLISH'].indexOf(item?.status) != -1) {
						statusClass = 'bg-yellow'
					}
					if (item?.users?.length || item?.devices?.length || item?.roles?.length || item?.tags?.length) {
						thisLabels.push({
							'title': T(item?.status?.toLowerCase().charAt(0).toUpperCase() + item?.status?.toLowerCase().slice(1)),
							'text': T(item?.status?.toLowerCase().charAt(0).toUpperCase() + item?.status?.toLowerCase().slice(1)),
							'class': statusClass
						})
					}
					if (jsonHelpers.getObjectProperty(item, "policy.type") != undefined) {
						if (item?.policy.type == "autogenerated policy") {
							thisLabels.push({
								'title': T("Generated"),
								'text': T("Generated"),
								'class': ''
							})
						} else if (item?.policy.type == "template policy") {
							thisLabels.push({
								'title': T("Template"),
								'text': T("Template"),
								'class': ''
							})
						}
					}

					if (licenseHelpers.hasLicense(activeAccountId.value, 'MDM') && jsonHelpers.getObjectProperty(item, "enterpriseProfile")) {
						if (item?.policy.applications) {
							let hasSecurity: boolean = item?.policy.applications.filter(function (application: any) {
								return application.packageName == 'de.securepoint.ms.agent'
							}).length > 0
							if (hasSecurity) {
								let policyName: any = item?.profilename
								let devices: any = useStore().getters.getObjectTypeStore({
                    				"accountId": activeAccountId.value,
                   	 				"objectType": "devices"})?.items || []
								let hasNonSecurityDevicesInPolicy: boolean = devices.filter(function (device: any) {
									// ONLY DEVICES WITH THIS POLICY AND WITHOUT MOBSEC LICENSE
									if (jsonHelpers.getObjectProperty(device, "profile.profileName") == policyName) {
										return jsonHelpers.getObjectProperty(device, "licenseInformation.type") != "Mobile Security"
									}
								}).length > 0

								if (hasNonSecurityDevicesInPolicy) {
									thisLabels.push({
										'title': T("License conflict"),
										'text': T("License conflict"),
										'class': 'bg-red'
									})
								}
							}
						}
					}

					break;
				case 'depProfiles':
					if (item?.is_default) {
						thisLabels.push({
							title: T("Default"),
							text: T("Default"),
							class: ''
						})
					}
					break;

				case 'alerts':
					if (item?.active) {
						thisLabels.push({
							title: T('Active'),
							text: T('Active'),
							class: 'bg-blue'
						})
					}
					if (item?.notify === 0) {
						thisLabels.push({
							title: T('Immediately'),
							text: T('Immediately'),
							class: 'bg-green'
						})
					}
					else if (item?.notify === 1) {
						thisLabels.push({
							title: T('Time'),
							text: T('Time'),
							class: 'bg-orange'
						})
					}
					else {
						thisLabels.push({
							title: T('Count'),
							text: T('Count'),
							class: 'bg-blue'
						})
					}
					break;
				case 'notifications':

				break;

				case 'vppassets':
					if (!item?.assetInfo.isInStore) {
						thisLabels.push({
							title: T("Not in App Store"),
							text: T("Not in App Store"),
							class: 'bg-yellow'
						})
					}
				break;
				case 'utms':
					
					if (item?.cluster?.isCluster === true && item.license.clusterUuid) {
						thisLabels.push(
							{
								title: "Cluster " + deviceHelpers.getShortDeviceId(item.license.clusterUuid, 4),
								text: "Cluster " + deviceHelpers.getShortDeviceId(item.license.clusterUuid, 4),
								class: "bg-blue"
							}
						)
					}

					if (objectStores.uscUtms.isUpdateNeeded(item)) {
						thisLabels.push({
							title: T("Update strongly recommended!"),
							text: T("Update strongly recommended!"),
							class: 'bg-red'
						})
					}
					break;
				case 'reports':
					break;
				case 'zeroTouchConfigurations':
					if (item.enrollmentToken && item.enrollmentToken !== null) {
						let timestamp = new Date(item.enrollmentToken.expirationTimestamp).getTime()
						if (timestamp - Date.now() <= 0) {
							thisLabels.push({
								title: T("Enrollmenttoken expired"),
								text: T("Enrollmenttoken expired"),
								class: ""
							})
						}
						else {
							thisLabels.push({
								title: T("Valid"),
								text: T("Valid"),
								class: "bg-green"
							})
						}
					}
					else if (item?.dpcExtras && item?.dpcExtras != "undefined") {

						let dpcInfos = JSON.parse(item?.dpcExtras || "{}")
						let enrollmentTokenId = dpcInfos?.["android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE"]?.["com.google.android.apps.work.clouddpc.EXTRA_ENROLLMENT_TOKEN"]
						if (!enrollmentTokenId) {
							thisLabels.push({
								title: T("No Enrollmenttoken"),
								text: T("No Enrollmenttoken"),
								class: ""
							})
						}

					}
					else {
						thisLabels.push({
							title: T("No Enrollmenttoken"),
							text: T("No Enrollmenttoken"),
							class: ""
						})
					}
					break;
			}
		}
		return thisLabels
	}
	function getIcon (item: any) {
		let iconClass: any = ''
		if (item) {
			let newProductType = config.canUseNewObjectType((props.altObjectType || objectType.value)) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
			let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]

			if (newObjectType != undefined && config.canUseNewObjectType((props.altObjectType || objectType.value))) {
				return (<ObjectType<any>>newObjectType).options.appearance.iconClass
			}
			if (objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores] instanceof GenericObjectStore) {
				return objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores].settings.appearance.iconClass
			}
			switch (props.altObjectType || objectType.value) {
				case 'devices':
				case 'iosDevices':
					iconClass = "fal fa-mobile-alt"
					break;
				case 'profiles':
				case 'iosProfiles':
				case 'androidProfiles':
					iconClass = "fal fa-shield-alt"
					break;
				case 'depProfiles':
					iconClass = "fal fa-shield-alt"
					break;
				case 'apps':
					iconClass = item?.hasIcon ? null : "fal fa-rocket"
					break;
				case 'vppassets':
					iconClass = "fal fa-rocket"
					break;
				case 'vpns':
					iconClass = "fal fa-laptop"
					break;
				case 'certificates':
					iconClass = "fal fa-certificate"
					break;
				case 'alerts':
					iconClass = "fal fa-bell"
					break;
				case 'notifications':
					iconClass = "fal fa-comment-alt"
					break;
				case 'utms':
					iconClass = "fal fa-server"
					break;
				case 'reports':
					iconClass = "fal fa-file-alt"
					break;
				case 'zeroTouchConfigurations':
					iconClass = "fab fa-android"
					break;
			}
		}
		return iconClass
	}
	async function renderDeleteDeviceDialog(deviceId: string) {
		dialogs.misc.confirmDialog(
			activeAccountId.value,
			T("Confirm Delete"),
			sprintf(T("Do you want to delete device %s?"), getTitle(props.item)?.title) + "<br>"
			+ T("This action deletes the device from the MDM portal. This action does not reset the device"),
			async function () {
				await useStore().dispatch(ActionTypes.deleteObject, {
					"accountId": activeAccountId.value,
					"objectType": "devices",
					"productType": "mobileSecurity",
					"objectIdProperty": "deviceId",
					"objectId": deviceId
				})
				useStore().commit(MutationTypes.removeModal, { "accountId": activeAccountId.value })
			},
			T('Delete')
		)
	}
	function findFieldsForTab (formJson: any, object: any, path: any = []): any {
		let result: boolean = false
		for (let fieldIndex in formJson.fields) {
			if (result == true) {
				break
			}
			let field = formJson.fields[fieldIndex]
			let fieldProperty: any = field.property || null
			let fieldPath: any = JSON.parse(JSON.stringify(path))
			if (fieldProperty) {
				fieldPath.push(fieldProperty)
			}
			if (field.type == "tabs" || field.type == "tab" || field.type == "section") {
				result = findFieldsForTab(field, object, fieldPath)
				if (result == true) {
					break
				}
			}
			else if (field.type == "loopentry") {
				for (let objectIndex in object) {
					result = findFieldsForTab(field, object[objectIndex], [])
					if (result == true) {
						break
					}
				}
			}
			else if (field.type == 'loop') {
				let objectValue: any = jsonHelpers.getObjectProperty(object, fieldPath.join('.'))
				if (objectValue != undefined && objectValue != null) {
					result = true
					break
				}
			}
			else {
				if (fieldProperty != null) {
					let objectValue: any = jsonHelpers.getObjectProperty(object, fieldPath.join('.'))
					if (objectValue != undefined && objectValue != null) {
						result = true
						break
					}
				}
			}
		}
		return result
	}
	function $getObjectProperty(obj: any, props: string) {
		return mixinHelpers.$getObjectProperty(obj, props)
	}
	function $getObjectIdPropertyForObjectType(objectType: string) {
		return mixinHelpers.$getObjectIdPropertyForObjectType(objectType)
	}
	function getEnterpriseProfileParts (policy: any) {
		let parts: any = []
		let profileForm: any = JSON.parse(JSON.stringify(useStore().state.resources.shemas['androidProfiles']))
		let tabs: any = {}
		profileForm.fields[0].fields.map(function (tab: any) {
			return tabs[tab.id] = tab
		})

		// get mobile security app
		if (policy.policy.applications) {
			if (policy.policy.applications.length) {
				for (let i in policy.policy.applications) {
					let app: any = policy.policy.applications[i]
					if (app.packageName == "de.securepoint.ms.agent") {
						policy.policy.smsManagedConfiguration = app.managedConfiguration
					}
				}
			}
		}

		let hasApps: boolean = policy.policy.applications != undefined ? true : false
		let hasPasscode: boolean = policy.policy.passwordRequirements != undefined ? true : false
		let hasNetwork: boolean = findFieldsForTab(tabs.emm_networks, policy.policy)
		let hasRestrictions: boolean = findFieldsForTab(tabs.emm_restrictions, policy.policy)
		let hasSecurity: boolean = findFieldsForTab(tabs.tab_emm_security, policy.policy)
		if (hasApps) {
			parts.push(T("Applications"))
		}
		if (hasRestrictions) {
			parts.push(T("Restrictions"))
		}
		if (hasSecurity) {
			parts.push(T("Security"))
		}
		if (hasPasscode) {
			parts.push(T("Passcode"))
		}
		if (hasNetwork) {
			parts.push(T("Network"))
		}

		return parts
	}
	function isItemCheckable(item: any) {
		const objectStore = objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores]
		let newProductType = config.canUseNewObjectType((props.altObjectType || objectType.value)) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
		let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]

		if (newObjectType != undefined && config.canUseNewObjectType((props.altObjectType || objectType.value))) {
			return typeof (<ObjectType<any>>newObjectType).itemlistItem.hasCheckbox == "function" ? (<Function>(<ObjectType<any>>newObjectType).itemlistItem.hasCheckbox)(item) : (<ObjectType<any>>newObjectType).itemlistItem.hasCheckbox
		}
		else if (objectStore instanceof GenericObjectStore) {
			return typeof objectStore.itemlist.hasCheckBox == "function" ? objectStore.itemlist.hasCheckBox(item) : objectStore.itemlist.hasCheckBox
		}
		return props.clickable ? item?.isCheckable || false : false
	}
	function isItemChecked(item: any) {
		return item?.$itemlist?.isCheckboxChecked || item?.isChecked || false
	}
	function isItemHovering() {
		return isHovering.value || false
	}
	function onClickIcon(item: any) {
		let isCheckable = item?.isCheckable
		let objectStore = objectStores[(props.altObjectType || objectType.value) as keyof typeof objectStores]
		let newValue = undefined
		let newProductType = config.canUseNewObjectType((props.altObjectType || objectType.value)) ? products[(props.altProductType || productType.value) as keyof typeof products] : undefined
		let newObjectType = newProductType?.[(props.altObjectType || objectType.value) as keyof typeof newProductType]

		if (newObjectType != undefined && config.canUseNewObjectType((props.altObjectType || objectType.value))) {
			isCheckable = isItemCheckable(item)
			if(isCheckable) {
				if(item.$itemlist == undefined) {
					item.$itemlist = {
						"isCheckboxChecked":false,
            			"isCheckboxHovering":false,
					}
				} 
				newValue = item.$itemlist.isCheckboxChecked != undefined ? !item.$itemlist.isCheckboxChecked : true
				item.$itemlist.isCheckboxChecked = newValue 
			}
		}
		else if (objectStore instanceof GenericObjectStore) {
			isCheckable = typeof objectStore.itemlist.hasCheckBox == "function" ? objectStore.itemlist.hasCheckBox(item) : objectStore.itemlist.hasCheckBox
			const objectId = item[objectStore.settings.primaryKeyProperty] as string
			const productType = objectStore.settings.productType
			const objectType = objectStore.settings.objectType
			if (isCheckable) {
				newValue = item.$itemlist.isCheckboxChecked != undefined ? !item.$itemlist.isCheckboxChecked : true
				useStore().commit(MutationTypes.setObjectProperty, {
					"accountId": activeAccountId.value,
					"objectId": objectId,
					"productType": productType,
					"objectType": objectType,
					"objectPropertyPath": ["$itemlist"],
					"property": "isCheckboxChecked",
					"value": newValue
				})
			}
		}
		else if (isCheckable) {
			let objectIdProperty: any = tenantHelpers.getObjectIdPropertyForObjectType(props.altObjectType || objectType.value || '')
			let objectId: any = item[objectIdProperty]
			newValue = item?.isChecked != undefined ? (!item.isChecked) : true
			useStore().commit(MutationTypes.setObjectProperty, {
				"accountId": activeAccountId.value,
				"objectId": objectId,
				"productType": props.altProductType || productType.value,
				"objectType": props.altObjectType || objectType.value,
				"objectPropertyPath": [],
				"property": "isChecked",
				"value": newValue
			})
		}
		if (newValue != undefined) {
			if (newValue === false) {
				vueApp.$refs.itemlist.checkedItemsCount--
			}
			else {
				vueApp.$refs.itemlist.checkedItemsCount++
			} 
		}
		onIconMouseLeave()
	}
	function calcTitleLength() {
		let cellWidth = $el.value?.clientWidth || 0
		if(props.fullTitle) {
			maxTitleLength.value = 128
		}
		else {
			if (props.viewMode == 'list') {
				maxTitleLength.value = 64
			}
			else if (cellWidth == 0) {
				maxTitleLength.value = 12
			}
			else if (cellWidth <= 330) {
				maxTitleLength.value = 12
			}
			else if (cellWidth <= 530) {
				maxTitleLength.value = 20
			}
			else {
				maxTitleLength.value = 32
			}
		}
	}

	const getPlaceholder = () => { return '<span class="content-placeholder" style="width:' + numberHelpers.getRandomArbitrary(50, 250) + 'px;" title="' + T("Loading...") + '"></span>' }

onMounted(() => {
	calcTitleLength()
})
defineExpose({
		editAlias,
		editUsername,
		editTags,
		error,
		errorMsg,
		exportObject,
		saveToClipBoard
	})


</script>

<template>
	<div ref="$el" v-if="visible" class="item" :class="{
		'disabled': isItemDisabled(),
		'clickable': isItemClickable(item) && clickable !== false
	}" v-bind:style="{
	'z-index': 999999 - index
}">
		
		<div class="box-shadow" :title="getTitle(item).title" :class="{
			'disabled': $getObjectProperty(item, 'depOnly') === true || item == undefined,
			'clickable': $getObjectProperty(item, 'policy.type') !== 'autogenerated policy' && isItemClickable(item) && clickable !== false
		}">
			<div class="overlay text-size-3" v-if="loading || error"
				:style="'background: ' + (darkmode == '1' ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.7)') + '; z-index: 10;'">
				<loaderComponent v-if="loading" style="position:absolute; top:50%; left:50%; transform:translate(-50%,-50%)" class="color-primary" />
				<i class="fal fa-exclamation-triangle color-primary" style="position:absolute; top:50%; left:50%; transform:translate(-50%,-50%)" v-if="error"></i>
				<p class="padding-xs box" v-if="errorMsg" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, 2em); font-size: calc(1em / 3); width: 90%;">
					{{ T(errorMsg) }}
				</p>
			</div>
			<ul class="box-menu" v-if="showMenu && item && (getMenuEntries() || []).length"
				:id="'itemlist-item-menu-' + (item ? item[$getObjectIdPropertyForObjectType(objectType)] : 'unknown')">
				<li>
					<span v-on:click="toggleDropDown('itemlistItemDropdown-' + index, 'box-dropdown-' + index)"
						class="hover-bg-lightgray link-gray"
						:id="'itemlist-item-menutoggle-' + (item ? item[$getObjectIdPropertyForObjectType(objectType)] : 'unknown')">
						<i class="fal fa-fw fa-bars"></i>
					</span>
					<ul class="box-dropdown" :data-name="'box-dropdown-' + index" tabindex="-1">
						<template v-for="entry in (getMenuEntries() || [])">
							<li :title="T(entry.title)">
								<a :disabled="entry.disabled || null"
									v-on:click="(entry.disabled || (typeof entry.onClick != 'function')) ? undefined : entry.onClick()"
									:href="entry.link ? entry.link : undefined"
									:target="entry.target ? entry.target : undefined" :class="{
										'disabled': entry.disabled
									}" :id="'itemlist-item-menu-entry-' + (entry.id ? entry.id : 'unknown') + '-' + (item ? item[$getObjectIdPropertyForObjectType(objectType)] : 'unknown')">
									<i class="fa-fw" :class="entry.icon"></i> {{ T(entry.text) }}
								</a>
							</li>
						</template>
					</ul>
				</li>
			</ul>
			<div v-on:click="isItemClickable(item) ? onItemClick(item) : undefined"
				:id="'itemlist-item-' + (item ? item[$getObjectIdPropertyForObjectType(objectType)] : 'unknown')">
				<div class="box-body">
					<div class="box-icon-left">
						<div class="box-icon-left" v-on:click.stop="clickable ? onClickIcon(item) : null"
							v-on:mouseover="clickable ? onIconMouseOver() : null"
							v-on:mouseleave="clickable ? onIconMouseLeave() : null"
						>
							<div class="icon-circle"
								:class="[getIconBackground(item, true), darkmode == '1' ? 'bg-darkbluegrey' : 'bg-gray']"
								:title="activePage == 'devices' ? getStatusText(item) : ''">
								<div class="checkbox-overlay-0" :class="{ 'visible': getIconBackgroundImage(item) != null }"
									:style="getIconBackgroundImage(item)">
								</div>
								<div class="checkbox-overlay-1"
									:class="[getIconBackground(item), { 'visible': item ? isItemCheckable(item) && (isItemChecked(item) || isItemHovering()) : false }]"
									v-if="($getObjectProperty(item, 'policy.type') != 'template policy') && ($getObjectProperty(item, 'profilename') != 'unlicensed')">
									<i v-if="item ? isItemChecked(item) : false" class="fal fa-check-square"></i>
									<i v-if="item ? (!isItemChecked(item) && isItemHovering()) : false"
										class="fal fa-square"></i>
								</div>
								<template v-if="activePage == 'devices' && item">
									<span v-if="!item.configured" class="status bg-yellow">
									</span>
									<span v-else-if="item.signedIn === false" class="status bg-red">
									</span>
									<span v-else-if="item.signedIn" class="status bg-green">
									</span>
								</template>
								<i v-if="getIcon(item)" :class="getIcon(item)"></i>
							</div>
						</div>

					</div>
					<div class="box-content-2">
						<div class="box-title" style="line-break: anywhere;">


							<template v-if="item">

								<strong class="margin-xs-r">
									<span v-if="(editable && !editAlias) || !editable">
										<template v-if="item && getTitle(item).title">
											{{ outputStringMaxLength(getTitle(item).title || "", maxTitleLength, '...') }}
										</template>
									</span>
									<template
										v-if="editable">
										<deviceInlineRenameComponent v-if="editAlias"
											:itemObjectId="item.id || item.deviceId || item.utmId"
											:itemObjectType="objectType" :accountid="activeAccountId" />
									</template>
								</strong>

								<a v-if="getTitle(item).link && getTitle(item).link.showIf() && editable"
									v-on:click.stop="getTitle(item).link.onClick()" v-html="getTitle(item).link.innerHtml"
									class="btn padding-xs-y-0"
									:class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'"></a>
								<template v-if="getTitle(item).small">
									&nbsp;
									<small>
										{{ getTitle(item).small }}
									</small>
								</template>
								&nbsp;
								<template v-for="label in getLabels(item)">
									<LabelComponent :is-tag="'small'" :label="label" style="margin-right:1px"></LabelComponent>
								</template>
								

								

							</template>
							<template v-else>
								<span class="content-placeholder" style="width:320px"></span>
							</template>
						</div>
					</div>
				</div>
				<hr>
				<div class="box-body details" v-if="showInfo === undefined ? true : showInfo"
					:style="{ opacity: ($getObjectProperty(item, 'policy.type') == 'autogenerated policy') || (isItemDisabled()) ? 0.7 : 1 }">
					<div class="text-smaller">
						<div class="data-row" v-for="detail in getDetails()">
							<span class="data-key text-semibold">
								<i class="fa-fw" :class="detail.iconClass"></i> {{ detail.key }}:
							</span>

							<span class="data-value" style="width:100%;">

								<template v-if="item && detail.iconValue">
									<i :class="detail.iconValue"></i>
								</template>

								<template
									v-if="item && detail.editableContent != undefined && detail.editableContent !== false">
									<template v-if="thisWorkaround[detail.editableContent.editingBoolProperty as keyof typeof thisWorkaround] !== true">
										<template v-if="detail.value != undefined">
											<span>
												<template v-if="item">
													{{ detail.value }}
												</template>
												<template v-else>
													<span v-html="getPlaceholder()"></span>
												</template>
											</span>
										</template>
										<template v-else-if="detail.labels != undefined">
											<template v-for="detailItem in detail.labels">
												<template v-if="detailItem.lineBreakBefore == true">
													<br>
												</template>
												<labelComponent :label="detailItem" style="margin-right:1px"></labelComponent>
												<template v-if="detailItem.lineBreakAfter == true">
													<br>
												</template>
											</template>
										</template>
									</template>
									&nbsp;&nbsp;
									<inlineItemlistInputComponent :inputType="detail.editableContent.type"
										:options="detail.editableContent.options"
										:select2Settings="detail.editableContent.select2Settings || undefined"
										:ref="detail.editableContent.ref" :value="detail.editableContent.value"
										:submitFunction="detail.editableContent.submitFunction"
										:abortFunction="detail.editableContent.abortFunction"
										:multiple="detail.editableContent.multiple"
										v-if="thisWorkaround[detail.editableContent.editingBoolProperty as keyof typeof thisWorkaround] !== false" />
									<a class="btn padding-xs-y-0"
										:class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'"
										v-if="thisWorkaround[detail.editableContent.editingBoolProperty as keyof typeof thisWorkaround] === false && editable && 
										detail.editableContent.editButton.onClick"
										v-on:click.stop="detail.editableContent.editButton.onClick()">
										<i :class="detail.editableContent.editButton.icon"></i>
									</a>
								</template>
								<template
									v-else-if="detail.regularlyUpdatedData && detail.regularlyUpdatedData != undefined">
									<regularlyUpdatedDataComponent :inputValue="detail.regularlyUpdatedData.inputValue"
										:method="detail.regularlyUpdatedData.method"
										:refreshAfterMs="detail.regularlyUpdatedData.frequency" />
								</template>
								<template v-else>
									<template v-if="detail.value != undefined">
										<template v-if="item">
											{{ detail.value }}
										</template>
										<template v-else>
											<span v-html="getPlaceholder()"></span>
										</template>
									</template>
									<template v-else-if="detail.labels != undefined">
										<template v-for="detailItem in detail.labels">
											<template v-if="detailItem.lineBreakBefore == true">
												<br>
											</template>
											<labelComponent :label="detailItem" style="margin-right:1px; margin-bottom:1px"></labelComponent>

											<template v-if="detailItem.lineBreakAfter == true">
												<br>
											</template>
										</template>
									</template>
								</template>
								<template v-if="detail.buttons">
									<template v-for="button in detail.buttons">
										<a class="btn btn-loader twist-in padding-xs-y-0" :class="[{
											'btn-loading': button.loading === true,
											'disabled': button.loading === true
										},
										darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'
										]" :disabled="button.loading || null" v-on:click.stop="button.onClick ? button.onClick() : undefined">
											<span class="animate">
												<loaderComponent v-if="button.loading === true" style="top: -7px;" />
											</span>
											<span style="padding:1px 10px">
												<template v-if="button.icon">
													<i class="faw" :class="button.icon"></i>
												</template>
												<template v-if="button.text">
													<span>{{ button.text }}</span>
												</template>
											</span>
										</a>
									</template>
								</template>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>