import { T } from "@/classes/i18n";
import jsonHelpers from "../../helpers/helpers.json";
import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "../components/loader.vue";
import { vueApp } from "@/app";
import googleMapComponent from "../components/map";
import inputTextComponent from "../inputtypes/input-text";
import inputTextareaComponent from "../inputtypes/input-textarea.vue";
import encodingHelpers from "@/helpers/helpers.encoding";
import getterHelpers from "@/helpers/helpers.getters";
import { MutationTypes } from "@/store/vuex.store";

const setInventoryLocationDialogComponent = {
    "name":"set-inventory-location-dialog",
    "mixins": [useGlobalMixin()],
    "template": `
        <div>
            <template v-if="initialized">
                <div class="row form-group validation-base border-bottom padding-xs-b">
                    <div class="col-lg-6">
                        <label class="control-label" for="inventoryStreet">
                            ${ T('Street') }:
                        </label>
                    </div>
                    <div class="col-lg-18">
                        <label>
                            <input type="text" class="form-control user-selection" placeholder="${T('Street')}" v-model="inventoryLocation.street"/>
                        </label>
                    </div>
                </div>
                <div class="row form-group validation-base border-bottom padding-xs-y">
                    <div class="col-lg-6">
                        <label class="control-label" for="inventoryPostalCode">
                            ${ T('Postal code') }:
                        </label>
                    </div>
                    <div class="col-lg-18">
                        <label>
                            <input type="text" class="form-control user-selection" placeholder="${T('Postal code')}" v-model="inventoryLocation.postalCode"/>
                        </label>
                    </div>
                </div>
                <div class="row form-group validation-base padding-xs-t padding-xs-b">
                    <div class="col-lg-6">
                        <label class="control-label" for="inventoryCity">
                            ${ T('City') }:
                        </label>
                    </div>
                    <div class="col-lg-18">
                        <label>
                            <input type="text" class="form-control user-selection" placeholder="${T('City')}" v-model="inventoryLocation.city"/>
                        </label>
                    </div>
                </div>
                <div class="row form-group validation-base padding-xs-t padding-xs-b" v-if="inventoryLocationDescription !== undefined">
                    <div class="col-lg-6">
                        <label class="control-label" for="inventoryLocationDescription">
                            ${ T('Description') }:
                        </label>
                    </div>
                    <div class="col-lg-18">
                        <label>
                            <input-textarea 
                                class="form-control user-selection" 
                                placeholder="${T('Description')}" 
                                v-model="inventoryLocationDescription"
                            />
                        </label>
                    </div>
                </div>
                <gmap
                    v-if="locationFound"
                    :entries="mapEntry"
                    :defaultZoom="10"
                    style="position:relative; height:320px;"
                    id="setInventoryMap"
                />
            </template>
            <template v-else>
                <p class="text-center padding-xs-y-2">
                    <loader class="color-red" style="font-size:4em"/>
                </p>
            </template>
        </div>
    `,
    "data":() => {
        return {
            "initialized":false,
            "error":undefined,
            "inventoryLocationDescription": "",
            "inventoryLocation": {
                "street":"",
                "postalCode":"",
                "city":"",
                "latitude":undefined,
                "longitude":undefined
            },
            "mapEntry":[],
            "locating":false,
            "locationFound":false
        }
    },
    "computed": {
    },
    "props": {
        "properties":{
            required:true,
            default:() => {
                return {
                    "callback":undefined,
                    "location":undefined
                }
            }
        }
    },
    "methods": {
        "init":function(this:any) {
            let result : boolean = true
            let location = jsonHelpers.copyObject(this.properties.inventoryLocation)
            if(location.street) {
                this.inventoryLocation.street = location.street
            }
            if(location.postalCode) {
                this.inventoryLocation.postalCode = location.postalCode
            }
            if(location.city) {
                this.inventoryLocation.city = location.city
            }
            this.inventoryLocationDescription = this.properties.inventoryLocationDescription

            this.initialized = true

            vueApp.$refs.modals.$refs.modal.modal.buttons.push(
                {
                    "text": T("Locate"),
                    "icon": "fal fa-search",
                    "onClick": async () => {
                        this.loading = true
                        this.disabled = true
                        this.locating = true
                        // get location
                        let hasFound: boolean = false

                        if (this.inventoryLocation.street != "" || this.inventoryLocation.postalCode != "" || this.inventoryLocation.city != "") {
                            let address: any = this.inventoryLocation.street + ' ' + this.inventoryLocation.postalCode + ' ' + this.inventoryLocation.city
                            var geocoder = new google.maps.Geocoder();
                            geocoder.geocode({ 'address': address }, (results, status) => {

                                if (status == google.maps.GeocoderStatus.OK) {
                                    hasFound = true
                                    var latitude = results[0].geometry.location.lat();
                                    var longitude = results[0].geometry.location.lng();

                                    this.inventoryLocation.latitude = latitude
                                    this.inventoryLocation.longitude = longitude

                                    if (this.mapEntry.length > 0) {
                                        this.mapEntry.splice(0, 1)
                                    }
                                    this.mapEntry.push({
                                        "position": {
                                            "lat": latitude,
                                            "lng": longitude,
                                            "accuracy": 1
                                        },
                                        "infoHtml": `
                                        <table style="margin:0;">
                                            <tr>
                                                <td><i class="fal fa-fw fa-road"></i>&nbsp;&nbsp;${T('Street')}</td>
                                                <td>${encodingHelpers.escapeHTML(this.inventoryLocation.street)}</td>
                                            </tr>
                                            <tr>
                                                <td><i class="fal fa-fw fa-map-marker"></i>&nbsp;&nbsp;${T('City')}</td>
                                                <td>${encodingHelpers.escapeHTML(this.inventoryLocation.postalCode) + ' ' + encodingHelpers.escapeHTML(this.inventoryLocation.city)}</td>
                                            </tr>
                                        </table>
                                        `
                                    })
                                }
                                this.locationFound = true
                            })
                        }
                        this.loading = false
                        this.disabled = false
                        this.locating = false
                    },
                    "align": "right",
                    "loading": false,
                    "disabled": false
                }
            )
        },
        "addressChanged": function(this:any) {
            if(this.initialized) this.locationFound = false
        }
    },
    "created": async function(this:any) {
    },
    "mounted": function(this:any) {
        this.init()
    },
    "watch": {
        "locationFound":{
            handler: function(this:any,locationFound:boolean) {
                let thisComponent : any = this

                if(locationFound) {
                    vueApp.$refs.modals.$refs.modal.modal.buttons.splice(1,1)
                    vueApp.$refs.modals.$refs.modal.modal.buttons.push(
                        {
                            "text":T("Save"),
                            "icon":"fal fa-hdd",
                            "onClick":async function() {
                                this.loading = true
                                this.disabled = true
                                if (!await thisComponent.properties.callback(jsonHelpers.copyObject(thisComponent.inventoryLocation), (thisComponent.inventoryLocationDescription || "").length > 0 ? thisComponent.inventoryLocationDescription : undefined)) {
                                    this.loading = false
                                    this.disabled = false
                                }
                                else {
                                    getterHelpers.useStore().commit(MutationTypes.removeModal)
                                }
                            },
                            "align":"right",
                            "loading":false,
                            "disabled":false
                        }
                    )
                }
                else {
                    vueApp.$refs.modals.$refs.modal.modal.buttons.splice(1,1)
                    vueApp.$refs.modals.$refs.modal.modal.buttons.push(
                        {
                            "text":T("Locate"),
                            "icon":"fal fa-search",
                            "onClick":async function() {
                                this.loading = true
                                this.disabled = true
                                thisComponent.locating = true
                                // get location
                                let hasFound : boolean = false

                                if(thisComponent.inventoryLocation.street != "" || thisComponent.inventoryLocation.postalCode != "" || thisComponent.inventoryLocation.city != "") {
                                    let address : any = thisComponent.inventoryLocation.street+' '+thisComponent.inventoryLocation.postalCode+' '+thisComponent.inventoryLocation.city
                                    var geocoder = new google.maps.Geocoder();
                                    geocoder.geocode({ 'address': address }, function(results, status) {

                                        if (status == google.maps.GeocoderStatus.OK) {
                                            hasFound = true
                                            var latitude = results[0].geometry.location.lat();
                                            var longitude = results[0].geometry.location.lng();

                                            thisComponent.inventoryLocation.latitude = latitude
                                            thisComponent.inventoryLocation.longitude = longitude

                                            if(thisComponent.mapEntry.length > 0) {
                                                thisComponent.mapEntry.splice(0,1)
                                            }
                                            thisComponent.mapEntry.push({
                                                "position":{
                                                    "lat":latitude,
                                                    "lng":longitude,
                                                    "accuracy":1
                                                },
                                                "infoHtml":`
                                                <table>
                                                    <tr>
                                                        <td><i class="fal fa-fw fa-road"></i>&nbsp;&nbsp;${ T('Street') }</td>
                                                        <td>${ encodingHelpers.escapeHTML(thisComponent.inventoryLocation.street) }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><i class="fal fa-fw fa-map-marker"></i>&nbsp;&nbsp;${ T('City') }</td>
                                                        <td>${ encodingHelpers.escapeHTML(thisComponent.inventoryLocation.postalCode) + ' ' + encodingHelpers.escapeHTML(thisComponent.inventoryLocation.city) }</td>
                                                    </tr>
                                                </table>
                                                `
                                            })

                                            thisComponent.locationFound = true
                                        }

                                    })
                                }
                                this.loading = false
                                this.disabled = false
                                thisComponent.locating = false
                            },
                            "align":"right",
                            "loading":false,
                            "disabled":false
                        }
                    )
                }
            }
        },
        "inventoryLocation.street": function(this:any){
            this.addressChanged()
        },
        "inventoryLocation.postalCode": function(this:any){
            this.addressChanged()
        },
        "inventoryLocation.city": function(this:any){
            this.addressChanged()
        }

    },
    "components": {
        "loader":loaderComponent,
        "gmap": googleMapComponent,
        "input-text":inputTextComponent,
        "input-textarea":inputTextareaComponent,
    }
}
export default setInventoryLocationDialogComponent