<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import CenterDialog from '@/templates/templates/center-dialog.vue';
import CodeEnrollment from "@/classes/objectTypes/mobileSecurity/apple/iOSCodeEnrollment";
import { T } from "@/classes/i18n";
import inputPin from "../inputtypes/input-pin.vue";

const enrollmentTokenId = ref<string>('');
const loading = ref(true);
const error = ref<string>('');
const isExpired = ref<boolean>(false);
const pin = ref<string>('');

let codeEnrollment: CodeEnrollment | null = null;

onMounted(async () => {
  const locationHash = location.hash;
  const decodedHash = decodeURIComponent(locationHash);
  const match = decodedHash.match(/^#ios-code-enrollment-enrollmentId-(.+)$/);

  if (match) {
    enrollmentTokenId.value = match[1];

    codeEnrollment = new CodeEnrollment(enrollmentTokenId.value);
    loading.value = false;
  } else {
    console.error('Invalid route format');
    error.value = T("Error on reading enrollmentTokenId");
    loading.value = false;
  }
});

const submitForm = async () => {
  if (!pin.value || !enrollmentTokenId.value || !codeEnrollment) return;

  try {
    const payload = { pin: pin.value };
    const response = await codeEnrollment.checkin(payload);

    if (response && response.redirectURL) {
      window.location.href = response.redirectURL;
    } else {
      error.value = T("Invalid PIN");
    }
  } catch (err) {
    console.error("Error during form submission:", err);
    error.value = T("An error occurred. Please try again.");
  }
};

// Automatically submit when it reaches 6 digits
watch(pin, (newPin, oldPin) => {
  if (newPin !== oldPin) {
    error.value = '';
  }

  if (newPin.length === 6) {
    submitForm();
  }
});
</script>

<template>
  <CenterDialog mode="ios-code-enrollment" :disable-any-navigation="true">
    <template #default>
      <div v-if="loading">
        {{ T('Loading...') }}
      </div>
      <div v-else>
        <div v-if="isExpired">
          <h3>{{ T('Oops, this link has expired!') }}</h3>
        </div>
        <div v-else-if="enrollmentTokenId">
          <h3>{{ T('Please enter the PIN to log in to the MDM.') }}</h3>
          <p>{{ T('Login to the MDM is PIN-protected.') }}</p>
          <p>{{ T('If you do not know the PIN, please contact your administrator.') }}</p>
          <div class="pin-container">
            <inputPin 
              v-model="pin"
              :numbers-only="true" 
              :focus-on-mount="true" 
              :pin-length="6" 
              :isDisabled="false"
            />
          </div>
          <div v-if="error" class="error">{{ error }}</div>
        </div>
      </div>
    </template>
  </CenterDialog>
</template>

<style scoped>
.pin-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.error {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
}
</style>
