<script setup lang="ts">
import { ref, onMounted } from 'vue';
import CenterDialog from '@/templates/templates/center-dialog.vue'; 
import emailSubscription, { type EmailSubscriptionState } from "@/classes/objectTypes/unifiedReporting/emailSubscription/emailSubscription";
import { T } from "@/classes/i18n";

const subscriptionState = ref<EmailSubscriptionState | false>(false);
const tenantDomain = ref<string | null>(null);
const token = ref<string | null>(null);
const isActionComplete = ref<string | null>(null);

const fetchSubscriptionState = async (tenantDomain: string, token: string) => {
  subscriptionState.value = await emailSubscription.getState(tenantDomain, token);
};

// Unified function to handle both opt-in and opt-out actions
const handleAction = async (actionType: 'in' | 'out') => {
  if (tenantDomain.value && token.value) {
    const result = await emailSubscription.postState(tenantDomain.value, token.value, { opt: actionType });

    if (result) {
      if (actionType === 'out') {
        isActionComplete.value = 'optOut';
      } else {
        isActionComplete.value = 'optIn';
      }
    }
  }
};

onMounted(() => {
  const hash = location.hash;
  const decodedHash = decodeURIComponent(hash);
  const match = decodedHash.match(/^#email-subscription-tenant-([^-]+?)-token-(.+)$/);
  if (match) {
    tenantDomain.value = match[1];
    token.value = match[2];
    fetchSubscriptionState(tenantDomain.value, token.value);
  } else {
    console.error('Invalid route format');
  }
});
</script>

<template>
  <CenterDialog mode="email-subscription">
    <div v-if="subscriptionState && !isActionComplete">
      <template v-if="subscriptionState?.optedIn">
        <h1>{{ T('Remove subscription') }}</h1>
        <p>{{ T('Your email address') }} <b>{{ subscriptionState.email }}</b> {{ T('is registered to receive an Unified Security Report (USR). To remove your email address click the button below.') }}</p>
        <br/>
        <button @click="() => handleAction('out')">{{ T("I don't want to receive any further USR") }}</button>
      </template>
      <template v-else-if="subscriptionState?.optedIn === false">
        <h1>{{ T('Confirm subscription') }}</h1>
        <p>{{ T('Your email address') }} <b>{{ subscriptionState.email }}</b> {{ T('was registered to receive an Unified Security Report (USR). Please confirm your email address by clicking the button below.') }}</p>
        <br/>
        <button @click="() => handleAction('in')">{{ T('I agree and like to receive the USR') }}</button>
      </template>
      <template v-else>
        <h1>{{ T('Subscription status unavailable') }}</h1>
        <p>{{ T('Unable to retrieve subscription information. Please try again later.') }}</p>
      </template>
    </div>
    <div v-else>
      <template v-if="isActionComplete === 'optOut'">
        <h1>{{ T('Remove subscription') }}</h1>
        <p>{{ T('Thank you. Your email address has been removed.') }}</p>
      </template>
      <template v-if="isActionComplete === 'optIn'">
        <h1>{{ T('Confirm subscription') }}</h1>
        <p>{{ T('Thank you. Your email address has been confirmed.') }}</p>
      </template>
      <template v-if="!isActionComplete">
        <h1>{{ T('Subscription status unavailable') }}</h1>
        <p>{{ T('Unable to retrieve subscription information. Please try again later.') }}</p>
      </template>
    </div>
  </CenterDialog>
</template>