<script lang="ts" setup>
    import tooltipComponent from "@/templates/components/tooltip.vue"
    import IconComponent from "../icon/icon.vue";
    import Label, { type LabelOptions } from "@/templates/components/label/label";
    import Icon from "@/templates/components/icon/icon";
    import { computed } from "vue";

    // Props
    const props = withDefaults(defineProps<{
        label:Label|LabelOptions
        
    }>(), {
        label:() => new Label()
    });

    const getIcon = (icon:LabelOptions["icon"]) => {
        if(typeof icon == "string") {
            return new Icon({
                "class":icon
            })
        }
        else {
            return icon
        }
    }

    const contents = computed(() => {
        let result : LabelOptions["content"] = []
        if(props.label.icon) {
            result.push({ "type":"icon", "value": props.label.icon})
        }
        if(props.label.icons) {
            props.label.icons.forEach((icon) => {
                if(result != undefined) {
                    result.push({ "type":"icon", "value": icon})
                }
            })
        }
        if(props.label.text) {
            result.push({ "type":"text", "value": props.label.text})
        }
        if(props.label.content) {
            result = result.concat(props.label.content)
        }
        return result
    })

    const onClick = (event:MouseEvent) => {
        if(props.label.stopPropagation === true) {
            event?.stopPropagation()
        }
        if(props.label.onClick) {
            if(props.label.stopPropagation == undefined) {
                event?.stopPropagation()
            }
            props.label.onClick(event)
        }
    }

</script>
<template>
    <template v-if="label.title">
        <tooltipComponent 
            :is-tag="'span'" 
            class="label" 
            :class="[label.class]" 
            :tooltip="label.title" 
            :html-tooltip="label.htmlTooltip"
            v-on:click="onClick"
            :style="{
                'cursor': label.onClick != undefined ? 'pointer' : undefined,
                'float': label.float ? label.float : undefined
            }"
            title
            :removeOnBeforeUnmount="true"
        >
            <span style="padding:1px 0px">
                <template v-for="content,index in contents">
                    <template v-if="content.type == 'icon' && getIcon(content.value)">
                        <IconComponent :icon="getIcon(content.value)"></IconComponent>
                    </template>
                    <template v-else-if="content.type == 'text'">
                        <span>{{ content.value }}</span>
                    </template>
                    <template v-if="label?.contentSeparator && (index + 1) < contents.length">
                        <span v-html="label.contentSeparator"></span>
                    </template>
                    <template v-else-if="(index + 1) < contents.length">
                        &nbsp;
                    </template>
                </template>
                <slot></slot>
            </span>
        </tooltipComponent>
    </template>
    <template v-else>
        <span 
            class="label" 
            :class="[label.class]"
            v-on:click="onClick"
            :style="{
                'cursor': label.onClick != undefined ? 'pointer' : undefined,
                'float': label.float != undefined ? label.float : undefined
            }"
        >
            <span style="padding:1px 0px">
                <template v-for="content,index in contents">
                    <template v-if="content.type == 'icon' && getIcon(content.value)">
                        <IconComponent :icon="getIcon(content.value)"></IconComponent>
                    </template>
                    <template v-if="content.type == 'text'">
                        <span>{{ content.value }}</span>
                    </template>
                    <template v-if="label?.contentSeparator && (index + 1) < contents.length">
                        <span v-html="label.contentSeparator"></span>
                    </template>
                    <template v-else-if="(index + 1) < contents.length">
                        &nbsp;
                    </template>
                </template>
                <slot></slot>
            </span>
        </span>
    </template>
</template>
