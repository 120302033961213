import type { ProductType } from "@/resources/registeredProducts"
import encodingHelpers from "./helpers.encoding"
import jsonHelpers from "./helpers.json"
import storeHelpers from "./helpers.store"
import timeHelpers from "./helpers.time"
import licenseHelpers from "./helpers.license"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import type { EnterpriseDevice } from "@/classes/mobileSecurity/enterpriseDevices"
import type { ZeroTouchDevice } from "@/classes/mobileSecurity/zeroTouchDevices"
import moment from "moment"
import config from "@/classes/config"
import { allObjectTypes } from "@/classes/objectTypes"
import getterHelpers from "./helpers.getters"
import deviceHelpers from "./helpers.devices"
import worldGeo from '@highcharts/map-collection/custom/world.geo.json'
import { type AccountInfo } from "@/classes/storeSession"

const tenantHelpers = {
    // GET TENANTDOMAIN FROM ACCOUNTID
    getTenantDomain: function(accountid:string): string {
        accountid = String(accountid).toLowerCase()
        let tenantDomain: any = (accountid.indexOf('.sms') == -1) ? accountid + '.sms' : accountid
        return tenantDomain
    },
    // GET ACCOUNTID FROM TENANTDOMAIN
    getAccountId: function(tenantDomain:string): string {
        tenantDomain = String(tenantDomain).toLowerCase()
        let accountid : any = (tenantDomain.indexOf('.sms') != -1) ? tenantDomain.replace('.sms','') : tenantDomain
        return accountid
    },
    tenantExists: function(accountId:string) {
        accountId = tenantHelpers.getAccountId(accountId)
        return getterHelpers.useStore().state.session?.userInfo?.scopes.list_accounts.some((account : AccountInfo) => {
            return String(account.accountid) == accountId
        })
    },
    hasAccountRole: function (roleName: string) {
        return getterHelpers.useStore()?.state.session.userInfo.roles.indexOf(roleName) != -1
    },
    /*
    * GET PRIVACY LEVEL FOR ACTIVE ACCOUNT: (0|1)
    */
    getPrivacyLevel: function(accountId:string) : string {
        accountId = tenantHelpers.getAccountId(accountId)
        let result = String(getterHelpers.useStore()?.state.session.accounts[accountId]?.unifiedSecurity.privacy.privacyLevel || "1")
        return result
    },
    isItemEnterprise: function(item:any) {
        return item ? (item.enterpriseProfile || item.enterpriseDevice) : false
    },
    isFunctionalityActivated: function(functionality:string) {
        functionality = functionality.toLowerCase()
        let result : boolean = false
        switch(functionality) {
            case 'enterprise':
            case 'emm':
                result = config.pluggable.enterprise || false
            break;
            case 'dep':
            case 'appledep':
                result = config.pluggable.appleDEP || false
            break;
            case 'vpp':
            case 'applevpp':
                result = config.pluggable.appleVPP || false
            break;
            case 'zerotouch':
                result = config.pluggable.zeroTouch || false
            break;
        }
        return result
    },
    
    hasFunctionality: function(accountid:string,functionality:"androidenterprise"|"enterprise"|"emm"|"zerotouch"|"applepush"|"apns"|"appledep"|"dep"|"applevpp"|"vpp"|"androidlegacy"|"legacy") {
        functionality = functionality?.toLowerCase() as "androidenterprise"|"enterprise"|"emm"|"zerotouch"|"applepush"|"apns"|"appledep"|"dep"|"applevpp"|"vpp"|"androidlegacy"|"legacy"
        accountid = tenantHelpers.getAccountId(accountid)
        let result : boolean = false
        switch(functionality) {
            case 'androidenterprise':
            case 'enterprise':
            case 'emm':
                if (licenseHelpers.hasOneOfLicenses(accountid,['Mobile Security','MDM'])) {
                    let enterpriseActivated: boolean = tenantHelpers.isFunctionalityActivated("enterprise")
                    let enterpriseRegistered : boolean = getterHelpers.useStore()?.state.session.accounts[accountid].mobileSecurity.settings.android?.enterprise?.enabled || false
                    let hasName : boolean = getterHelpers.useStore()?.state.session.accounts[accountid].mobileSecurity.settings.android?.enterprise?.name ? true : false
                    let disabledForThisSession: boolean = getterHelpers.useStore()?.state.session.accounts[accountid].mobileSecurity.settings.android?.enterprise?.disabledForThisSession ? true : false
                    result = enterpriseActivated && enterpriseRegistered && hasName && !disabledForThisSession
                }
            break;
            case 'zerotouch':
                if (licenseHelpers.hasOneOfLicenses(accountid,['Mobile Security','MDM'])) {
                    let enterpriseActivated: boolean = tenantHelpers.isFunctionalityActivated("enterprise")
                    let zeroTouchRegistered : boolean = getterHelpers.useStore()?.state.session.accounts[accountid].mobileSecurity.settings.android?.zeroTouch?.enabled || false
                    result = enterpriseActivated && zeroTouchRegistered
                }
            break;
            case 'applepush':
            case 'apns':
                if (licenseHelpers.hasOneOfLicenses(accountid,['Mobile Security','MDM'])) {
                    return getterHelpers.useStore()?.state.session.accounts[accountid].mobileSecurity.settings.ios?.apns?.expired === false || false
                }
            break;
            case 'dep':
            case 'appledep':
                if (licenseHelpers.hasOneOfLicenses(accountid,['Mobile Security','MDM'])) {
                    let depActivated: boolean = tenantHelpers.isFunctionalityActivated("appledep")
                    let depRegistered : boolean = getterHelpers.useStore()?.state.session.accounts[accountid].mobileSecurity.settings.ios?.dep?.enabled || false
                    result = depActivated && depRegistered
                }
            break;
            case 'vpp':
            case 'applevpp':
                if (licenseHelpers.hasOneOfLicenses(accountid,['Mobile Security','MDM'])) {
                    let vppActivated: boolean = tenantHelpers.isFunctionalityActivated("applevpp")
                    let vppRegistered : boolean = getterHelpers.useStore()?.state.session.accounts[accountid].mobileSecurity.settings.ios?.vpp?.enabled || false
                    result = vppActivated && vppRegistered
                }
            break;
            case 'androidlegacy':
            case 'legacy':
                if (licenseHelpers.hasOneOfLicenses(accountid,['Mobile Security','MDM'])) {
                    result = (getterHelpers.useStore()?.state.session.accounts[accountid].unifiedSecurity.account.created || 0) * 1000 <= 1569262907000
                }
            break;
        }
        return result
    },

    canOpenRoute: function(accountId:string,pageName:string) {
        let result : boolean = true
        accountId = tenantHelpers.getAccountId(accountId)

        if(Array.isArray(getterHelpers.useStore()?.state.resources.pages?.[pageName]?.options?.forLicenses)) {
            result = licenseHelpers.hasOneOfLicenses(accountId, getterHelpers.useStore()?.state.resources.pages[pageName].options.forLicenses)
        }
        if (getterHelpers.useStore()?.state.resources.pages?.[pageName]?.options?.activeAccountHasNoParentAccount == true && getterHelpers.useStore().state.session.accounts[accountId || ""].unifiedSecurity.account.parent_accountid != null) {
            result = false
        }
        if (getterHelpers.useStore()?.state.resources.pages?.[pageName]?.options?.resellerTenantOnly == true && jsonHelpers.getObjectProperty(getterHelpers.useStore(), "state.session.accounts." + accountId + ".unifiedSecurity.account.isReseller") != true) {
            result = false
        }
        if (getterHelpers.useStore()?.state.resources.pages?.[pageName]?.options?.vppOnly == true && !tenantHelpers.hasFunctionality(accountId,"vpp")) {
            result = false
        }
        if (getterHelpers.useStore()?.state.resources.pages?.[pageName]?.options?.depOnly == true && !tenantHelpers.hasFunctionality(accountId, "dep")) {
            result = false
        }
        if (getterHelpers.useStore()?.state.resources.pages?.[pageName]?.options?.zeroTouchOnly == true && !tenantHelpers.hasFunctionality(accountId, "zerotouch")) {
            result = false
        }
        return result
    },

    tenantItemNeedsRefresh: function(accountid:string,productType:string,objectType:string) {
        accountid = tenantHelpers.getAccountId(accountid)
        //@ts-ignore
        let objectTypeInfo = getterHelpers.useStore()?.state.session.accounts[accountid]?.[productType]?.[objectType]
        return (objectTypeInfo.lastUpdate == undefined) || (parseInt(moment().unix()+"") - objectTypeInfo.lastUpdate) >= 30
    },
    /* looping through registered products and returns the product-propertyname for this objectType */
    getProductTypeFromObjectType: function(objectType:string) : ProductType | undefined {
        let objectStore = objectStores[objectType as keyof typeof objectStores]
        if (config.canUseNewObjectType(objectType)) {
            return allObjectTypes[objectType].options.productType
        }
        if(objectStore != undefined) {
            return objectStore.settings.productType
        }
        else {
            let registeredProducts: any = getterHelpers.useStore()?.state.resources.registeredProducts
            let result : ProductType | undefined 
            for(let productName in registeredProducts) {
                let product: any = registeredProducts[productName]
                for(let objectTypeName in product.objectTypes) {
                    if(objectTypeName == objectType) {
                        result = productName as ProductType | undefined
                        break;
                    }
                }
            }
            return result
        }
    },
    getObjectIdPropertyForObjectType: function(objectType:string) {
        let objectStore = objectStores[objectType as keyof typeof objectStores] // before refactoring´
        let objectTypeClass = allObjectTypes[objectType] // after refactoring
        if (objectTypeClass != undefined) {
            return objectTypeClass.options.objectTypeInfo.primaryKeyProperty.property
        }
        else if (objectStore != undefined) {
            return objectStore.settings.primaryKeyProperty
        }
        else {
            let registeredProducts: any = getterHelpers.useStore()?.state.resources.registeredProducts
            let productType = tenantHelpers.getProductTypeFromObjectType(objectType)
            return jsonHelpers.getObjectProperty(registeredProducts,productType+'.objectTypes.'+objectType+'.idProperty')
        }
    },
    getObjectNamePropertyForObjectType: function(objectType:string) {
        let objectStore = objectStores[objectType as keyof typeof objectStores]
        if (objectStore != undefined) {
            return objectStore.settings.nameProperty
        }
        else {
            let registeredProducts: any = getterHelpers.useStore()?.state.resources.registeredProducts
            let productType = tenantHelpers.getProductTypeFromObjectType(objectType)
            return jsonHelpers.getObjectProperty(registeredProducts,productType+'.objectTypes.'+objectType+'.nameProperty')
        }
    },
    getSelectOptionsForObjectType: function(accountid:string,objectType:string) {
        let options : any = []
        let productType = tenantHelpers.getProductTypeFromObjectType(objectType)
        if(!productType) throw "Missing productType"

        let objects = storeHelpers.getObjectTypeStore(accountid,productType,objectType)?.items
        let objectIdProperty : any = tenantHelpers.getObjectIdPropertyForObjectType(objectType)
        let objectNameProperties : any = tenantHelpers.getObjectNamePropertyForObjectType(objectType)
        for (let i : number = 0; (objects || []).length > i; i++) {
            if(!objects) throw "missing objects"
            let object : any = objects[i]
            let objectName : string = object[objectNameProperties.primary]
            if (objectNameProperties.secondary != undefined && objectNameProperties.secondary.length > 0) {
                objectName += ' (' + deviceHelpers.getShortDeviceId(object[objectNameProperties.secondary])+')'
            }
            let objectId : string = object[objectIdProperty]

            options.push({'id':objectId,'text':objectName})
        }
        if(objectType == "tags") {
            if(!options.some((option:selectOption) => { return option.id == "utms" })) {
                options.push({
                    "id":"utms",
                    "text":"utms"
                })
            }
            options = options.sort((tagA: selectOption, tagB: selectOption) => {
                return tagA.text.toLowerCase() > tagB.text.toLowerCase() ? 1 : -1
            })
        }
        return options
    },

    getEnterpriseDeviceByZeroTouchDeviceIdentifier: function(deviceIdentifier:ZeroTouchDevice["deviceIdentifier"],enterpriseDevices:Array<EnterpriseDevice>) {
        return enterpriseDevices.find((device) => {
            const aeImei: string = device.networkInfo?.imei || "",
            aeMeid: string = device.networkInfo?.meid || "",
            aeManufacturer: string = device.hardwareInfo?.manufacturer || "",
            aeBrand: string = device.hardwareInfo?.brand || "",
            aeSerialNumber: string = device.hardwareInfo?.serialNumber || "",
            aeModel: string = device.hardwareInfo?.model || "",
            ztImei: string | undefined = deviceIdentifier.imei || undefined,
            ztMeid: string | undefined = deviceIdentifier.meid || undefined,
            ztManufacturer: string | undefined = deviceIdentifier.manufacturer || undefined,
            ztSerialNumber: string | undefined = deviceIdentifier.serialNumber || undefined,
            ztModel: string | undefined = deviceIdentifier.model || undefined,
            hasSameImei: boolean = ztImei ? ztImei === aeImei : false,
            hasSameMeid: boolean = ztMeid ? ztMeid === aeMeid : false,
            hasSameManufacturer: boolean = ztManufacturer ? (ztManufacturer.toLowerCase() === aeManufacturer.toLowerCase() || ztManufacturer.toLowerCase() === aeBrand.toLowerCase()) : false,
            hasSameSerial: boolean = ztSerialNumber ? ztSerialNumber === aeSerialNumber : false,
            hasSameModel: boolean = ztModel ? ztModel === aeModel : false

            return (hasSameImei && hasSameManufacturer) || (hasSameMeid && hasSameManufacturer) || (hasSameSerial && hasSameModel)
        })
    },

    generateSearchArrayFromObject: function(item: any) {
        let searchArray: string[] = []
        for (let property in item) {
            if (typeof item[property] == 'string') {
                searchArray.push(item[property].toLowerCase())
            }
            else if (typeof item[property] == 'number') {
                searchArray.push(String(item[property]))
            }
            else if (typeof item[property] == 'boolean') {
                searchArray.push((item[property] ? property.toLowerCase() : 'not ' + property.toLowerCase()))
            }
            else if (Array.isArray(item[property])) {
                item[property].forEach((entry: any) => {
                    if (typeof entry == 'string') {
                        searchArray.push(entry.toLowerCase())
                    }
                    else if (typeof entry == 'number') {
                        searchArray.push(String(entry))
                    }
                    else if (typeof entry == 'object' && !Array.isArray(entry)) {
                        let thisValues: string[] = tenantHelpers.generateSearchArrayFromObject(entry)
                        searchArray = searchArray.concat(thisValues)
                    }
                });
            }
            else if (typeof item[property] == "object") {
                let thisValues: string[] = tenantHelpers.generateSearchArrayFromObject(item[property])
                searchArray = searchArray.concat(thisValues)
            }
        }
        return searchArray
    },

    categoryFromIp: function(_ip: number | string) {
        let ip = "" + _ip
        let len = ip.length
        if (len == 12 || len == 16) {
            ip = ip.match(/(.{1,3})\.?/g)!.map((octed) => parseInt(octed, 10)).join(".")
        }

        if (ip == "127.0.999.999") {
            return T("No cf message")
        } else if (ip == "127.0.70.1") {
            return T("Server unreached. Global def.")
        } else if (ip == "127.0.70.2") {
            return T("Server unreached. Group def.")
        } else if (ip == "127.0.70.3") {
            return T("No rules for host, user or ip")
        } else if (ip == "127.0.70.4") {
            return T("List did't match")
        } else if (ip == "127.0.70.5") {
            return T("List did't match. Internal def.")
        } else if (ip == "127.0.70.6") {
            return T("Group define")
        } else if (ip == "127.0.70.7") {
            return T("List define")
        }
        return getterHelpers.useStore()?.state.resources.firewall.ContentFilterCategories[ip] ? getterHelpers.useStore()?.state.resources.firewall.ContentFilterCategories[ip].category : ""
    },
    convertObjectForStore: function(accountid:any,objectType:string,object:any,additionalData?:any) {
        let newObject : any
        let devices : any[] = [],
        profiles : any[] = [],
        users : any[] = [],
        roles : any[] = [],
        tags : any[] = []

        if(additionalData && jsonHelpers.getObjectProperty(additionalData,"devices") != undefined) {
            devices = additionalData.devices
        }
        switch(objectType) {
            case 'devices':
            case 'iosDevices':
                newObject = object
                if(object.info) {
                    newObject.info.udid = object.info?.UDID || object.info?.udid || ""
                    newObject.info.enrollmentId = object.info?.EnrollmentID || object.info?.enrollmentId || ""
                    newObject.info.deviceName = object.info?.DeviceName || object.info?.deviceName || ""
                    newObject.info.productName = object.info?.ProductName || object.info?.productName || ""
                    newObject.info.model = object.info?.Model || object.info?.model || ""
                    newObject.info.ModelName = object.info?.ModelName || object.info?.ModelName || ""
                    newObject.info.serial = object.info?.SerialNumber || object.info?.serial || object.serialNumber || "000000000000"
                    newObject.info.version = object.osVersion || object.info?.OSVersion || object.info?.version || ""
                    newObject.info.imei = object.info?.IMEI || object.info?.imei || ""
                    newObject.info.meid = object.info?.MEID || object.info?.meid || ""
                    newObject.info.build = object.info?.BuildVersion || object.info?.build || ""
                    newObject.info.networkOperator = object.info?.CurrentCarrierNetwork || object.info?.networkOperator || ""
                    newObject.info.BluetoothMAC = object.info?.BluetoothMAC || object.info?.bluetoothMAC || ""
                    newObject.info.wifiMAC = object.info?.WiFiMAC || object.info?.wifiMAC || ""
                    newObject.info.PhoneNumber = object.info?.PhoneNumber || object.info?.PhoneNumber || ""
                    newObject.info.IsRoaming = typeof object.info?.IsRoaming == 'boolean' ? (object.info?.IsRoaming ? true : false) : false
                    newObject.info.supervised = object.info?.IsSupervised || object.info?.supervised || false
                    newObject.info.storeAccountActive = object.info?.iTunesStoreAccountIsActive || object.info?.storeAccountActive || false
                    newObject.info.IsMultiUser = object.info?.IsMultiUser || object.info?.IsMultiUser || false
                }

                let bigOlObject = jsonHelpers.copyObject(object)
                if (object.info?.ServiceSubscriptions?.length) {
                    object.info.ServiceSubscriptions.forEach((ServiceSubscription:any) => {
                        if (ServiceSubscription.PhoneNumber) {
                            newObject.info.PhoneNumber.length > 0 ? newObject.info.PhoneNumber != ServiceSubscription.PhoneNumber ? (newObject.info.PhoneNumber += ", " + ServiceSubscription.PhoneNumber) : undefined : (newObject.info.PhoneNumber = ServiceSubscription.PhoneNumber);
                        }
                        if (ServiceSubscription.IMEI) {
                            if (bigOlObject.info.imei != ServiceSubscription.IMEI) {
                                newObject.info.imei.length > 0 ? newObject.info.imei != ServiceSubscription.IMEI ? (newObject.info.imei += ", " + ServiceSubscription.IMEI) : undefined : (newObject.info.imei = ServiceSubscription.IMEI);
                            }
                        }
                    });
                }

                newObject.isCheckable = true
                newObject.mouseOverCheckbox = false
            break;
            
            case 'depDevices':
                newObject = {
                    "alias": "",
                    "configured": false,
                    "depDevice": true,
                    "depOnly": true,
                    "deviceId": object.serial_number,
                    "deviceType": "IOS",
                    "type": "DEP",
                    "enrolledAt": undefined,
                    "depDeviceInfo": object,
                    "info": {
                        "deviceType": "IOS",
                        "serial": object.serial_number,
                        "supervised": false,
                        "manufacturer": 'Apple',
                        "productName": object.device_family,
                        "os": "IOS",
                        "version": ""
                    },
                    "lastContact": 0,
                    "lostMode": false,
                    "ownership": object.managementMode || "",
                    "profile": {
                        "compliant": false,
                        "installed": [],
                        "parts": [],
                        "profileId": "",
                        "profileName": "",
                        "status": "",
                    },
                    "depProfile": object.profile_status || undefined,
                    "signedIn": false,
                    "tags": [],
                    "tenantDomain": accountid + '.sms',
                    "username": ""
                }
                newObject.isCheckable = false
                newObject.mouseOverCheckbox = false
            break;
            case 'profiles':
                newObject = object
                newObject.isCheckable = true
                newObject.mouseOverCheckbox = false
            break;
            case 'enterpriseProfiles':
                newObject = {
                    "enterpriseProfile": true,
                    "profilename": encodingHelpers.unescapeHTML(object.name.split("/")[3] ? object.name.split("/")[3] : object.name),
                    "profileId": encodingHelpers.unescapeHTML(object.name.split("/")[3] ? object.name.split("/")[3] : object.name),
                    "policy": object,
                    "priority": null,
                    "status": "PUBLISHED",
                    "appconfigs": [],
                    "devices": devices,
                    "users": [],
                    "roles": [],
                    "tags": [],
                    "platform": "ANDROID_ENTERPRISE",
                    "comment": ""
                }
                if (object.type != "template policy" && object.type != "autogenerated policy") {
                    newObject.isCheckable = true
                    newObject.mouseOverCheckbox = false
                }
            break;
            case 'depProfiles':
                newObject = object
                newObject = {
                    "profile_name": object['profile_name'] || "Apple DEP Profile",
                    "profile_uuid": object['profile_uuid'] || object["url"].split('/')[7] || undefined,
                    "is_supervised": object['is_supervised'] || undefined,
                    "allow_pairing": object['allow_pairing'] || undefined,
                    "is_mandatory": object['is_mandatory'] || undefined,
                    "is_mdm_removable": object['is_mdm_removable'] || undefined,
                    "await_device_configured": object['await_device_configured'] || undefined,
                    "is_multi_user": object['is_multi_user'] || undefined,
                    "auto_advance_setup": object['auto_advance_setup'] || undefined,
                    "support_phone_number": object['support_phone_number'] || undefined,
                    "support_email_address": object['support_email_address'] || undefined,
                    "org_magic": object['org_magic'] || undefined,
                    "url": object['url'] || undefined,
                    "anchor_certs": object['anchor_certs'] || undefined,
                    "supervising_host_certs": object['supervising_host_certs'] || undefined,
                    "skip_setup_items": object['skip_setup_items'] || undefined,
                    "department": object['department'] || undefined,
                    "devices": object['devices'] || undefined,
                    "language": object['language'] || undefined,
                    "region": object['region'] || undefined,
                    "is_default": object['is_default'] || undefined
                }
                newObject.isCheckable = false
                newObject.mouseOverCheckbox = false
            break;
            case 'apps':
                newObject = object
                newObject.isCheckable = true
                newObject.mouseOverCheckbox = false
            break;
            default:
                newObject = object
            break;
        }
        return newObject
    },

    setItemlistSearchValue: async function(value:string) {
        if (getterHelpers.useVue()?.$refs.itemlist) {
            getterHelpers.useVue().$refs.itemlist.filter.value = decodeURI(value)
        }
        else {
            await timeHelpers.sleep(1000)
            tenantHelpers.setItemlistSearchValue(value)
        }
    },
    secureDnsCategoryIdToName: function(categoryId: number): string {
        const cats = [
            T("Content Filter"),
            T("Domain Blocklist"),
            T("IP Blocklist"),
            T("DNS Rebind Protection"),
            T("IDN Homograph Attacks Protection"),
            T("Cloaked third-party tracker"),
            T("Blocked country"),
            T("Custom Blocklist"),
        ]

        return cats[categoryId] || "N/A"
    },
    countryCodeToName: function(countryCode: string): string {
        return T(worldGeo.features.find((country: any) => country.id === countryCode.toUpperCase())?.properties.name) || countryCode
    }
}

export default tenantHelpers