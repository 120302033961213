<script setup lang=ts>
    import vSelect from "vue-select";
    import { T } from "@/classes/i18n";
    import i18n from "@/classes/i18n"
    import { ref, watch, computed } from "vue";
    import arrayHelpers from "@/helpers/helpers.arrays";
    import { ActionTypes, useStore } from "@/store/vuex.store";
    import encodingHelpers from "@/helpers/helpers.encoding";
    import router from "@/router/router";
    import tenantHelpers from "@/helpers/helpers.tenants";
    import useRouterStore from "@/router/routerStore";
    import licenseHelpers from "@/helpers/helpers.license";
    import tooltipComponent from '../components/tooltip.vue';
    const store = useStore()

    const objectId = computed(() => {
        return useRouterStore().getObjectId
    })

    const activePage = computed(() => {
        return useRouterStore().getActivePage
    })

    const activeAccountId = computed({
        get: () => { return store.getters.getActiveAccountId || "" },
        set: (accountId: string) => {
            if(objectId.value != undefined || (activePage.value && !tenantHelpers.canOpenRoute(accountId, activePage.value))) {
                router.navigate("#show-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-dashboard") 
            }
            else {
                store.dispatch(ActionTypes.switchAccount, accountId) 
            }
        }
    })

    const accountOptions = computed(() => {
        let result: any[] = []
        let accounts: any = useStore().state.session.accounts
        let reseller = [] 
        let tenants = []

        for (let accountId in accounts) {
            if(!accounts[accountId].unifiedSecurity.account.parent_accountid) {
                reseller.push({
                    "id": accountId,
                    "text": encodingHelpers.unescapeHTML(accounts[accountId].unifiedSecurity.account.accountname),
                    "child":false,
                    "tooltip":{
                        'content':T('Reseller'),
                        'customId':"account_"+accountId 
                    }
                })
            }
            else {
                let hasLicense = licenseHelpers.hasOneOfLicenses(accountId,['laas','unifiedSecurityConsole','Mobile Security','MDM'])
                tenants.push(<selectOption>{
                    "id": accountId,
                    "text": encodingHelpers.unescapeHTML(accounts[accountId].unifiedSecurity.account.accountname) + (hasLicense ? '' : ' ('+T('Keine Lizenz')+')'),
                    "child":true,
                    "disabled":!hasLicense,
                    "tooltip":{
                        'content':hasLicense ? T('Tenant') : T('Der Kunde verfügt über keine Lizenz. Bitte nutzen Sie bei Bedarf das Resellerportal für den Erwerb einer Lizenz.'),
                        'customId':"account_"+accountId 
                    }
                })
            }
        }

        result = reseller.concat(arrayHelpers.sortByObjProperty(tenants, "text"))
        return result
    })

    function isSelectable(option: selectOption) {
        if (option.disabled === true) {
            return false
        }
        return true
    }

    const onSelect = (event:any) => {
        // close tooltip
        if(event?.tooltip?.customId) {
            let tooltipId = event.tooltip.customId
            let contentEl = document.getElementById('tooltip#' + tooltipId)
            if (contentEl) {
                contentEl.remove()
            }
        }
    }

</script>

<template>
    <v-select 
        append-to-body 
        :options="accountOptions" 
        v-model="activeAccountId" 
        label="text" 
        :reduce="(option: selectOption) => option.id" 
        :clearable="false" 
        id="accountSelect"
        uid="sideBarDropdown"
        @option:selecting="(event:any) => { onSelect(event)  }"
        :selectable="isSelectable"
    >
        <template #no-options>
            {{ T('No options found') }}
        </template>
        <template #option="{ id, text, child, tooltip }">
            <component 
                :is="tooltip != undefined ? tooltipComponent : 'span'"
                :tooltip="((<selectOption['tooltip']>tooltip)?.content || '')"
                :htmlTooltip="((<selectOption['tooltip']>tooltip)?.htmlTooltip || false)"
                :customId="((<selectOption['tooltip']>tooltip)?.customId || undefined) " 
                :isTag="'span'"
            >
                <span v-if="child">&nbsp;&nbsp;&nbsp;</span><i :class="{ 'fal fa-fw fa-crown': !child,'fal fa-fw fa-building': child}"></i>&nbsp;&nbsp;{{ text }}
            </component>
            
        </template>
        <template #selected-option="{ id, text, child }">
            <span :title="child ? T('Tenant') : T('Reseller')"><i :class="{ 'fal fa-fw fa-crown': !child, 'fal fa-fw fa-building': child }"></i>&nbsp;&nbsp;{{ text }}</span>
        </template>
    </v-select>
</template>

<style lang="scss">
#main-sidebar nav ul li.select {
    >div input.vs__search {
        border: none !important;
        box-shadow: none;
        background: none;
        display: flex;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid transparent;
        border-left: none;
        outline: none;
        margin: 4px 0 0;
        padding: 0 7px;
        background: none;
        box-shadow: none;
        width: 0 !important;
        max-width: 100%;
        flex-grow: 1;
        z-index: 1;
        color: #fafafa;
        font-size: 1em;
        min-height: 25px;
    }

    .vs__dropdown-toggle {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 2px;
        background: darken(#545861, 5%);

        .vs__actions {
            width: 20px;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAhCAYAAADZPosTAAAAdklEQVRIS+2S0Q3AIAgFYbOO4hpMwBhNJ6WxSaNpFA0Yf4rfcHkeD2Hxw8U8CKDfaDgMhwYDe2rDzAkRTy2giFxElL4z3YQatAfLcPXLLagGGwLzQA0dwaaALxQAjpazaYeGxjwre2pjTRcJPebKblzZ7/GHDm+LjCQiegl+qAAAAABJRU5ErkJggg==");
            background-position: right 0;
            background-repeat: no-repeat;
        }

        .vs__selected {
            color: #fff;
        }

        svg.vs__open-indicator {
            display: none;
        }

        .vs__selected-options {
            flex-wrap: nowrap;
        }
    }

    .vs__dropdown-menu {
        background: darken(#545861, 5%);
        color: #fff;
        position: absolute;
        left: 0;
        width:min-content;
        >li {
            padding: 8px;

            &.vs__dropdown-option--highlight {
                background: #E74C3C;
            }

            &.vs__dropdown-option--selected {}
        }
    }

}
    body > .vs__dropdown-menu#vssideBarDropdown__listbox {
        width:max-content !important;
    }
    body > ul[id*="__listbox"] {
        width:max-content !important;
    }
</style>