const jsonHelpers = {
    syntaxHighlight: function(json: any): any {
        if (typeof json != 'string') {
            json = JSON.stringify(json, undefined, 2)
        }
        json = json.replace(/&/g, '&amp').replace(/</g, '&lt').replace(/>/g, '&gt')
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match: string) {
            let cls = 'number'
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key'
                } else {
                    cls = 'string'
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean'
            } else if (/null/.test(match)) {
                cls = 'null'
            }
            return '<span class="' + cls + '">' + match + '</span>'
        })
    },

    getObjectProperty: function(obj: any, props: string) {
        return (props || "").split(".").reduce(function(chain: any, prop: string) {
            chain = chain ? chain[prop] : undefined
            return chain
        }, obj)
    },

    isObjectEmpty: function(obj : any) {
        let result : boolean = true
        for(let key in obj) {
            if(obj.hasOwnProperty(key)) {
                result = false
                break
            }
        }
        return result;
    },

    getObjectLength: function(obj : any) {
        let result : number = 0
        for(let key in obj) {
            result++
        }
        return result;
    },

    removeDuplicates: function(array : any) {
        var a = array.concat();
        for(let i : number = 0; i < a.length; ++i) {
            for(let j : number = i + 1; j < a.length; ++j) {
                if(a[i] === a[j]) {
                    a.splice(j--, 1)
                }
            }
        }
        return a
    },

    setObjectDeep: function(obj : any,newObj:any,doConcatArrays:boolean = false,vueSet:boolean = false) : boolean {
        let result : boolean = false
        try {
            if(obj) {
            for (let property in newObj) {
                let value = newObj[property]
                if (["number","string","boolean"].indexOf(typeof value) != -1) {
                        obj[property] = value
                }
                else if (typeof value == "object"){
                    if (Array.isArray(value)) {
                        if(doConcatArrays) {
                            let concatArray : any = obj[property].concat(value)
                            obj[property] = concatArray
                        }
                        else {
                            obj[property] = value
                        }
                    }
                    else {
                        // value is an object
                        if(obj?.hasOwnProperty(property)) {
                            result = jsonHelpers.setObjectDeep(obj[property],value)
                        }
                        else if(obj){
                            obj[property] = value
                        }
                    }
                }
            }
            }
            result = true
        }
        catch(e:any) {
            console.error(e)
        }
        return result
    },

    merge: function(obj : any,newObj:any,doConcatArrays:boolean = false) {
        try {
            for (let property in newObj) {
                let value = newObj[property]
                if (typeof value == "object"){
                    if (Array.isArray(value)) {
                        if(doConcatArrays) {
                            if(obj[property] == undefined) {
                                obj[property] = []
                            }
                            let concatArray : any = obj[property].concat(value)
                            obj[property] = concatArray
                        }
                        else {
                            obj[property] = value
                        }
                    }
                    else {
                        // value is an object
                        if(obj.hasOwnProperty(property)) {
                            if(obj[property] == undefined) {
                                obj[property] = {}
                            }
                            obj[property] = jsonHelpers.merge(obj[property],value,doConcatArrays)
                        }
                        else {
                            obj[property] = value
                        }
                    }
                }
                else {
                    obj[property] = value
                }
            }
        }
        catch(e:any) {
            console.error(e)
        }
        return obj
    },


    objectContainsValue: function(object:any, searchForValue:string|number|boolean) {
        object = JSON.parse(JSON.stringify(object))
        searchForValue = typeof searchForValue == "string" ? searchForValue.toLowerCase() : searchForValue
        let result = false
        if(!result) {
            for(let key in object) {
                let propertyValue = object[key]
                if (["string", "number"].indexOf(typeof propertyValue) != -1) {
                    if (propertyValue.toString().toLowerCase().indexOf(searchForValue) != -1) {
                        result = true
                        break;
                    }
                }
                else if(typeof propertyValue == 'boolean') {
                    if(propertyValue == searchForValue) {
                        result = true
                        break;
                    }
                }
                else if(typeof propertyValue == 'object' && propertyValue != null) {
                    if(jsonHelpers.objectContainsValue(propertyValue,searchForValue)) {
                        result = true
                        break;
                    }
                }
            }
        }
        return result
    },

    copyObject: function(object:any) {
        return JSON.parse(JSON.stringify(object))
    },

    jsonSearchPair: function(jsonObj: any, property: string, value: string | number | boolean,skipProperties:string[]=[]) {
        let result: any = -1
        if (value != undefined) {
            if (typeof jsonObj == "object") {

                if (jsonObj.hasOwnProperty('length')) {
                    // ARRAY
                    for (let index in jsonObj) {
                        if (typeof jsonObj[index] == "object" && jsonObj[index] != null) {
                            result = jsonHelpers.jsonSearchPair(jsonObj[index], property, value, skipProperties)
                        }
                        if (result != -1) {
                            break;
                        }
                    }
                }
                else {
                    // OBJECT
                    for (let thisProperty in jsonObj) {
                        if(skipProperties.indexOf(thisProperty) == -1) {
                            if (thisProperty == property) {
                                if (jsonObj[thisProperty] == value) {
                                    result = jsonObj
                                }
                            }
                            else if (typeof jsonObj[thisProperty] == "object" && jsonObj[thisProperty] != null) {
                                result = jsonHelpers.jsonSearchPair(jsonObj[thisProperty], property, value,skipProperties)
                            }
                            if (result != -1) {
                                break;
                            }
                        }
                    }
                }
            }
            else {
                console.log("please provide an object")
            }
        }
        return result
    },
    swapKeyValue: function (jsonObj:any) {
        var ret : any = {};
        for(let key in jsonObj){
            ret[jsonObj[key]] = key;
        }
        return ret;
    }

}

export default jsonHelpers