<script setup lang="ts">
    declare var pagePrivacy: any
    import { T } from "@/classes/i18n";
    import { useGlobalMixin } from "@/mixins/mixins.global";
    import { ActionTypes } from "@/store/vuex.store";
    import { computed, nextTick, onMounted, ref, watch } from "vue";
    import { useStore } from '@/store/vuex.store';
    import loaderComponent from "../components/loader.vue";
    import dialogs from "@/dialogs/dialogs";
    import tenantHelpers from "@/helpers/helpers.tenants";
    import licenseHelpers from "@/helpers/helpers.license";

    const activeAccountId = computed(() => {
        return useStore().state.session.activeAccountId
    })

    const privacyLevel = computed(() => {
        return useStore().state.session.accounts[activeAccountId.value || ""]?.unifiedSecurity?.privacy.privacyLevel
    })

    const isTenantInitialized = computed(() => {
        return useStore().state.session.accounts[activeAccountId.value || ""]?.initialized || false
    })
    const initialized = ref(false)
    const loading = ref(true)
    const loaderInfo = ref(T("Initializing..."))
    const loadingPrivacyLevel = ref(false)
    const privacyLevelValue = ref(<"0"|"1"|boolean>true)

    

    watch(isTenantInitialized, (isInitialized) => {
        if(isInitialized) {
            privacyLevelValue.value = privacyLevel.value == "1"
            nextTick(function () {
                initialized.value = true
            })
        }
    })

    watch(privacyLevelValue, async (value) => {
        loadingPrivacyLevel.value = true
        if (initialized.value) {
            value = value ? "1" : "0"
            if (value == "0") {
                dialogs.unifiedSecurity.disablePrivacy(async function (reasonInput: string) {
                    try {
                        if (value != privacyLevel.value) {
                            await useStore().dispatch(ActionTypes.setTenantProperty, {
                                "accountId": activeAccountId.value,
                                "property": "privacyLevel",
                                "value": value,
                                "additionalData": {
                                    "reason": reasonInput
                                }
                            })
                        }
                        loadingPrivacyLevel.value = false
                    }
                    catch (e: any) {
                        privacyLevelValue.value = privacyLevel.value
                        loadingPrivacyLevel.value = false
                    }
                    return true
                }, 
                function () {
                    privacyLevelValue.value = privacyLevel.value == '1'
                    loadingPrivacyLevel.value = false
                })
            }
            else {
                try {
                    if (value != privacyLevel.value) {
                        await useStore().dispatch(ActionTypes.setTenantProperty, {
                            "accountId": activeAccountId.value,
                            "property": "privacyLevel",
                            "value": value
                        })

                    }
                }
                catch (e: any) {
                    privacyLevelValue.value = privacyLevel.value
                }
            }
        }
        loadingPrivacyLevel.value = false
    })

    const hasLicense = licenseHelpers.hasLicense


    onMounted(() => {
        if (isTenantInitialized.value) {
            privacyLevelValue.value = privacyLevel.value == "1"
            nextTick(function () {
                initialized.value = true
            })

        }
    })

</script>

<template>
     <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="row" >
            <div class="col-xs-24 col-lg-16 col-print-24">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <template v-if="isTenantInitialized">
                                <h3><i class="icon fal fa-ban"></i>&nbsp;{{T('Pseudonymization')}}</h3>
                                <span>
                                    {{T('Here you can setup the privacy settings. If the pseudonymization is activated, the statistics in the portal are pseudonymized and the Unified Security Report is anonymized. By default, this setting is enabled.')}}
                                </span>
                                <template v-if="hasLicense(activeAccountId || '', 'laas', 'valid')">
                                    <p>
                                        {{ T("If you want to see usernames, e-mail addresses, IPs etc. in plain text, You have to disable the privacy setting in the Unified Security Portal. This changes the following facts:")}}
                                    </p>
                                    <ul>
                                        <li>
                                            {{T('In the Securepoint Mobile Security dashboard, the devices in the statistics are no longer pseudonymized.')}}
                                        </li>
                                        <li>
                                            {{T('Usernames, e-mail addresses, IPs, etc. are no longer anonymous in the report.')}}
                                        </li>
                                        <li>
                                            {{T('In Mobile Security it is possible to create alerts for specific devices.')}}
                                        </li>
                                    </ul>
                                    <p>
                                        {{T('In addition, the anonymization of the services must be disabled on the UTM, otherwise the data will be anonymized on the UTM before being sent to the Unified Security Cloud.')}}
                                    </p>
                                    <br />
                                    <p class="notification">
                                        {{T('These settings can not be made automatically on the UTM and must therefore be performed by the administrator.')}}
                                    </p>
                                </template>
                                <hr class="margin-xs-t-2">
                                <form class="form-horizontal" action="javascript:0">
                                    <div class="row padding-xs-y form-group padding-xs-y">
                                        <div class="first col-xs-24 col-lg-8 col-xl-7">
                                            <label for="tenant-info-privacylevel" class="control-label float-xs-left"><span>{{T('Pseudonymization enabled')}}</span></label>
                                        </div>
                                        <div class="input col-xs-24 col-lg-6 col-xl-5">
                                            <label class="toggle checkbox">
                                                <input type="checkbox" id="tenant-info-privacylevel" class="form-control user-selection float-xs-right" v-model="privacyLevelValue" :disabled="loadingPrivacyLevel || null">
                                                <span></span>
                                                <loaderComponent
                                                    v-if="loadingPrivacyLevel"
                                                    class="text-size-2"
                                                    style="top: 3px;"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </form>

                            </template>
                            <template v-else>
                                <div class="text-center padding-xs-y-4 col-xs">
                                    <div class="text-size-3">
                                        <loaderComponent class="text-size-2 color-red" />
                                    </div>
                                    <span v-if="loaderInfo"  style="opacity: 0.8;">{{ T(loaderInfo) }}</span>
                                </div>
                            </template>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>