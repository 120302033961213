import ObjectType, { type AccountId, type AddObjectTypeObject, type ObjectTypeObject, type ObjectTypePayload } from "../../objectType"
import jsonHelpers from "@/helpers/helpers.json"

interface ZeroTouchCustomer extends ObjectType<ZeroTouchCustomer> {
    "id": string,
    "customers": [
        {
            "companyId": string,
            "companyName": string,
            "name": string
        }
    ]
}


class ZeroTouchCustomers extends ObjectType<ZeroTouchCustomer> {
    constructor(payload: ObjectTypePayload<ZeroTouchCustomer>) {
        super(payload)

    }
}

const zeroTouchCustomers = new ZeroTouchCustomers({
    "productType":"mobileSecurity",
    "objectType":"zeroTouchCustomers",
    "slug":"zeroTouchCustomers",
    "hasStore":true,
    "objectTypeInfo":{
        "nameProperty":{
            "primary": "id"
        },
        "primaryKeyProperty":{
            "property":"id"
        }
    },
    "appearance":{
        "iconClass":"fab fa-android",
    },
    "apiInfo":{
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=total",
        // GET
        "getObjectListResponseProperty": "accounts",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/android/androiddeviceprovisioning/customers",
        // delete
        "deleteObjectPath": "/tenants/{tenantDomain}/android/androiddeviceprovisioning/{objectId}",
    }
})
export default zeroTouchCustomers