<script setup lang="ts">
    import getterHelpers from "@/helpers/helpers.getters";
    import loader from "../components/loader.vue";
    import { T } from "@/classes/i18n";
    import { computed, onMounted, ref, watch } from "vue";
    import inputVueSelect from "../inputtypes/input-vue-select.vue";
    import inputNumber from "../inputtypes/input-number.vue";
    import inputText from "../inputtypes/input-text.vue";
    import deviceHelpers from "@/helpers/helpers.devices";
    import objectStores from "@/classes/init";
    import ipaddr from "@/lib/ipaddr";
    import validationHelpers from "@/helpers/helpers.validation";
    import timeHelpers from "@/helpers/helpers.time";
    import products from "@/classes/objectTypes";
    import { ActionTypes, MutationTypes } from "@/store/vuex.store";
    import { type UtmNode } from "@/classes/objectTypes/unifiedNetwork/nodes";
    import buttonComponent from "../components/button/button.vue";
    import { useVue } from "@/app";
    import { type UpdateDnsEntry, type UpdateDnsEntries, type CreateNode, type SunNkViewNode, type CreateService, type SunNkViewService } from "@/classes/objectTypes/unifiedNetwork/topologies";
    import Tooltip from "../components/tooltip.vue";
import encodingHelpers from "@/helpers/helpers.encoding";

    const props = defineProps<{
        properties: {
            utmId:"",
            port:"",
            interface:"",
            hostname:"",
            transferNetwork:"",
            alias:"",
            satelliteCount:0,
            topologyId:""
            type:"add"|"edit"
            primaryDNS:UpdateDnsEntry|undefined,
            secondaryDNS:UpdateDnsEntry|undefined
        }
    }>();

    const activeAccountId = computed(() => {
        return getterHelpers.useStore()?.getters.getActiveAccountId
    })

    const isSaving = computed(() => {
        return getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].loading
    })

    const utmOptions = computed(() => {
        let result = <selectOption[]>[]
        const utms = objectStores.uscUtms.getObjectsFromStore(activeAccountId.value)
        
        result = utms.map((utm) => {
            const thisUtmState = getterHelpers.useStore()?.getters.getObject({
                "accountId": activeAccountId.value,
                "productType": "unifiedSecurityConsole",
                "objectType": "ccutmStates",        
                "objectId": utm.utmId,
            })

            let utmVersion = objectStores.uscUtms.getCurrentUtmVersion(utm)
            const hasVersion = validationHelpers.versionCompare(utmVersion,"12.8.0") === 0 || validationHelpers.versionCompare(utmVersion,"12.8.0") === 1

            return <selectOption>{
                "id":utm.utmId,
                "text": utm?.utmname + " (" + deviceHelpers.getShortDeviceId(utm.utmId) + ")" + (thisUtmState.online == false ? " | " + T("Offline") : (!hasVersion ? " | " + T("Version nicht kompatibel") : (utm.permissions?.manageVPNEnabled !== true ? " | " + T("Keine Berechtigung") : (utm.pinState?.enabled !== true ? " | " + T("Keine PIN") : "")))),
                "disabled":thisUtmState.online === false || utm.permissions?.manageVPNEnabled !== true || utm.pinState?.enabled !== true || !hasVersion,
                "groupId": (thisUtmState.online === false || utm.permissions?.manageVPNEnabled !== true || utm.pinState?.enabled !== true || !hasVersion) ? '#unavailableUtmsHeader' : '#availableUtmsHeader'
            }
        })
        result.push({
            "type": 'groupName',
            id: '#availableUtmsHeader',
            text: T('Verfügbar'),
            disabled:true
        })
        result.push({
            "type": 'groupName',
            id: '#unavailableUtmsHeader',
            text: T('Nicht verfügbar'),
            disabled:true
        })
        return result
    })

    const initialized = ref(false)
    const selectedUtm = ref(<string|null>null)
    const port = ref(51820)
    const selectedInterface = ref(<null|number>null)
	const selectedHostname = ref("")
    const transferNetwork = ref("")
    const alias = ref("")
    const primaryDNS = ref(<undefined|UpdateDnsEntry>undefined)
    const secondaryDNS = ref(<undefined|UpdateDnsEntry>undefined)

    const primaryDNSNodeInfo =  computed(() => {
        if((<SunNkViewNode>primaryDNS.value?.node)?.id != undefined) {
            return selectedNodeInfo.value?.nkView?.nodes.find((node) => {
                return node.id == (<SunNkViewNode>primaryDNS.value?.node)?.id
            }) || primaryDNS.value?.node
        }
        return primaryDNS.value?.node
    })
    const primaryDNSServiceInfo =  computed(() => {
        if((<SunNkViewService>primaryDNS.value?.service)?.id != undefined) {
            return selectedNodeInfo.value?.nkView?.services.find((node) => {
                return node.id == (<SunNkViewService>primaryDNS.value?.service)?.id
            }) || primaryDNS.value?.service
        }
        return primaryDNS.value?.service
    })
    const secondaryDNSNodeInfo =  computed(() => {
        if((<SunNkViewNode>secondaryDNS.value?.node)?.id != undefined) {
            return selectedNodeInfo.value?.nkView?.nodes.find((node) => {
                return node.id == (<SunNkViewNode>secondaryDNS.value?.node)?.id
            }) || secondaryDNS.value?.node
        }
        return secondaryDNS.value?.node
    })
    const secondaryDNSServiceInfo =  computed(() => {
        if((<SunNkViewService>secondaryDNS.value?.service)?.id != undefined) {
            return selectedNodeInfo.value?.nkView?.services.find((node) => {
                return node.id == (<SunNkViewService>secondaryDNS.value?.service)?.id
            }) || secondaryDNS.value?.service
        }
        return secondaryDNS.value?.service
    })

    const hasIpChanged = ref(false)
    const transferNetworkErrors = computed(() => {
        let thisErrors : string[] = []
        if(hasIpChanged.value == true ) {
            if (transferNetwork.value.length >= 1) {
                let address : string | ipaddr = transferNetwork.value
                try {
                    address = new ipaddr(transferNetwork.value)
                }
                catch(e) {
                    
                }
                if (typeof address != "string" && (address.isIPv4() || address.isIPv6())) {
                    if(address.hasCidr()) {
                        if (address.isIPv4()) {
                            let cidr = Number(address.cidr) as NumericRange<CreateArrayWithLengthX<16>,30>
                            if (!validationHelpers.isPrivateIPv4(address.addr, false)) {
                                thisErrors.push("Enter a private IP address.")
                            }
                            if ((Number(address.cidr) <= 30 && Number(address.cidr) >= 16) && (props.properties.satelliteCount + 1) > products.unifiedSecurityConsole.topologies.v4CidrToIpLimit[String(address.cidr) as keyof typeof products.unifiedSecurityConsole.topologies.v4CidrToIpLimit]) {
                                thisErrors.push("There are too many UTMs in this network.")
                            }
                            if (Number(address.cidr) > 30 || Number(address.cidr) < 16) {
                                thisErrors.push("Enter a network mask between /16 and /30.")
                            }
                            else {
                                const baseCheckResult = validationHelpers.isCorrectIpV4Base(address.addr,cidr)
                                if(baseCheckResult !== true) {
                                    thisErrors.push(baseCheckResult.message)
                                }
                            }
                        }
                        if (address.isIPv6()) {
                            let cidr = Number(address.cidr) as NumericRange<CreateArrayWithLengthX<112>,126>
                            if (!validationHelpers.isPrivateIpV6(address.addr, false)) {
                                thisErrors.push("Enter a private IP address.")
                            }
                            if ((Number(address.cidr) <= 126 && Number(address.cidr) >= 112) && (props.properties.satelliteCount + 1) > products.unifiedSecurityConsole.topologies.v6CidrToIpLimit[String(address.cidr) as keyof typeof products.unifiedSecurityConsole.topologies.v6CidrToIpLimit]) {
                                thisErrors.push("There are too many UTMs in this network.")
                            }
                            if (Number(address.cidr) > 126 || Number(address.cidr) < 112) {
                                thisErrors.push("Enter a network mask between /112 and /126.")
                            }
                            else {
                                const baseCheckResult = validationHelpers.isCorrectIpV6Base(address.addr,cidr)
                                if(baseCheckResult !== true) {
                                    thisErrors.push(baseCheckResult.message)
                                }
                            }
                        }
                    }
                    else {
                        thisErrors.push("Missing network mask.")
                    }
                }
                else {
                    thisErrors.push("The specified address does not match an ipv4 or ipv6 address.")
                }
            }
            else {
                thisErrors.push("Enter a private IP address.")
            }
        }
        return thisErrors
    })
    
    

    const responseErrors = ref(<any[]>[])
    

    const formattedResponseErrors = ref(<{ 'property': string, 'error': string }[]>[])

    const selectedInterfaceInfo = computed(() => {
        return selectedInterface.value ? interfaceOptions.value?.find((option) => { return option.id == selectedInterface.value }) : undefined
    })

    const loadingNodeInfo = ref(false)
    
    const selectedNodeInfo = computed(() => {
        return products.unifiedSecurityConsole.utmNodes.useStore?.().getObjectStoreObject(activeAccountId.value,selectedUtm.value || "")
    })

    const doOldCloudModelsExist = computed(() => {
        if(props.properties.type == 'add') {
            return selectedNodeInfo.value?.nkView?.models?.some((model) => {
                return model.source == "cloud"
            })
        }
        return false
    })


    const selectedUtmErrors = ref(<string[]>[])

    const canUseUtm = computed(() => {
        return selectedNodeInfo.value != undefined && interfaceOptions.value?.length > 0 && !selectedNodeInfo.value.errors && networkViewErrors.value.length == 0 && selectedUtmErrors.value.length == 0 && !doOldCloudModelsExist.value
    })

    const interfaceOptions = computed(() => {
        let result = <(selectOption & any)[]>[]
        result = (selectedNodeInfo.value?.nkView?.interfaces?.map((interfaceInfo) => {
            const addressList = interfaceInfo.addresses?.map((addressInfo) => {
                return addressInfo.address
            }).filter((ipAddr) => {
                const address = new ipaddr(ipAddr)
                if (address.isIPv4() && !validationHelpers.isPrivateIPv4(address.addr, false)) {
                    return true
                }
                else if (address.isIPv6() && !validationHelpers.isPrivateIpV6(address.addr, false)) {
                    return true
                }
                return false
            }).join(", ")
            const hostname = interfaceInfo.dynDns?.hostname
            return {
                "text": interfaceInfo.name + (hostname ? " | " + hostname : "") + (addressList?.length ? " (" + addressList +")" : ""),
                ...interfaceInfo
            }
        }) || []).filter((interfaceInfo:any) => {
            return interfaceInfo.isCoreCompatible === true
        })
        return result
    })

	const hostnameOptions = computed(() => {
        const sif = selectedNodeInfo.value?.nkView?.interfaces?.find((NkViewInterface) => {
			return NkViewInterface.id === selectedInterface.value
		})
		if (!sif){ 
            return <selectOption[]>[] 
        }
        const hostnameOptions = [...(sif.addresses?.map((address) => address.address) || [])].filter((address) => {
            const testAddress = new ipaddr(address)
            if (testAddress.isIPv4()) {
                return !validationHelpers.isPrivateIPv4(testAddress.addr, false)
            }
            else if(testAddress.isIPv6()) {
                return !validationHelpers.isPrivateIpV6(testAddress.addr, false)
            }
            else {
                return false
            }
        })
		const hostname = sif.dynDns?.hostname
		if(hostname){
			hostnameOptions.push(hostname)
		}
		const result: selectOption[] = hostnameOptions.map((option) => {
            return { 
                "id": option,
                "text": option
            }
		}, [])
		return result
    })

    // FUNCTIONS
    const checkReadyState = () => {
        if (initialized.value && selectedUtm.value && selectedUtm.value != "" && selectedInterface.value != null && selectedHostname.value != "" && transferNetwork.value.length > 0 && transferNetworkErrors.value.length == 0 && alias.value) {
            getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = false
        }
        else {
            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true
        }
    }

    const addError = (e:any) => {
        responseErrors.value.push(e)
    }
    const clearErrors = () => {
        responseErrors.value = []
        formattedResponseErrors.value = []
    }

    const onSelectInterface = (e:any,a:any) => {
        console.log(e,a)
    }

    watch(responseErrors, (newErrors) => {
        responseErrors.value.forEach((error) => {
            if (Array.isArray(error?.data?.data?.error?.error) && error?.data?.data?.error.name.indexOf("TRANSFER_NETWORK") != -1) {
                error?.data?.data?.error?.error.forEach((errMsg:string) => {
                    formattedResponseErrors.value.push({
                        "property": "transferNetwork",
                        "error": errMsg
                    })
                })
            }

            if (Array.isArray(error?.data?.data?.errors)) {
                error.data.data.errors.forEach((err:any) => {
                    if (err.params?.missingProperty) {
                        formattedResponseErrors.value.push({
                            "property": err.params.missingProperty,
                            "error": err.message
                        })
                    }
                })
            }
        })

    }, { deep:true })

    // GET INTERFACES ON UTM CHANGE
    watch(selectedUtm,async () => {
        selectedUtmErrors.value = []
        if (props?.properties?.utmId) {

        }
        else if (initialized.value) {
            selectedInterface.value = null
            selectedHostname.value = ""
            transferNetwork.value = ""
        }
        if (selectedUtm.value) {
            loadingNodeInfo.value = true
            const hadOldModels = products.unifiedSecurityConsole.utmNodes.networkViewsWithOldModels.indexOf(selectedUtm.value) != -1
            try {

                if(props.properties.topologyId) {
                    let result = await products.unifiedSecurityConsole.utmNodes.queries.getObjectsFromApi(activeAccountId.value,undefined,[{ "property":"topologyId", "value":props.properties.topologyId }])
                    let thisNode = !(result instanceof Error) ? result?.find((node) => {
                        return node.utmId == selectedUtm.value
                    }) : null
                    if(!(result instanceof Error) && thisNode?.nkView != null) {
                        loadingNodeInfo.value = false
                    }
                    else if(!(result instanceof Error)) {
                        products.unifiedSecurityConsole.utmNodes.view.waitForNetworkViewViaWebsocket(activeAccountId.value,selectedUtm.value,() => {
                            loadingNodeInfo.value = false
                        })
                    }
                }
                else {
                    let result = await products.unifiedSecurityConsole.utmNodes.queries.getObjectFromApi(activeAccountId.value,selectedUtm.value,undefined,(hadOldModels ? [{'property':'force','value':1}] : undefined))
                    products.unifiedSecurityConsole.utmNodes.networkViewsWithOldModels = products.unifiedSecurityConsole.utmNodes.networkViewsWithOldModels.filter((id) => {
                        return id != selectedUtm.value
                    })
                    if(Array.isArray((<Error|UtmNode[]>result))) {
                        // @ts-ignore
                        result = result[0]
                    }
                    if(!(result instanceof Error) && result.nkView != null) {
                        loadingNodeInfo.value = false
                    }
                    else if(!(result instanceof Error)) {
                        products.unifiedSecurityConsole.utmNodes.view.waitForNetworkViewViaWebsocket(activeAccountId.value,selectedUtm.value,() => {
                            loadingNodeInfo.value = false
                        })
                    }
                }
       
            }
            catch(e:any) {
                if(e?.data?.data && Array.isArray(e.data.data)) {
                    selectedUtmErrors.value = e.data.data
                }
                loadingNodeInfo.value = false
            }
        }
        checkReadyState()
    })
    const networkViewErrors = computed(() => {
        let thisErrors :string[] = []
        if(selectedNodeInfo.value) {
            if(selectedNodeInfo.value.nkView == null) {
                thisErrors.push("Für diese UTM existieren keine Netzwerkinformationen")
            }
            if((selectedNodeInfo.value.errors || []).length > 0) {
                (selectedNodeInfo.value.errors || []).forEach((error) => {
                    if(error == "UTM_RECENTLY_REMOVED") {
                        thisErrors.push("Die UTM wurde erst kürzlich aus der Konfiguration entfernt und kann erst wieder hinzugefügt werden, wenn sie sich nach Löschung der alten Konfigurationsdaten einmal zurückgemeldet hat. Bitte haben Sie einen Moment Geduld.")
                    }
                    else {
                        thisErrors.push(error)
                    }
                })
            }
            if(doOldCloudModelsExist.value) {
                thisErrors.push('Auf dieser UTM existiert bereits eine veraltete VPN-Konfiguration. Daher kann sie aktuell nicht als Core-UTM eingesetzt werden. Bitte entfernen Sie die alte Konfiguration, indem Sie in der Web-Oberfläche der UTM den Knopf "alle VPN-Konfigurationen löschen" unter USP -> Unified Security Console und VPN-Konfiguration betätigen.')
            }
        }
        return thisErrors
    })

    watch(selectedInterface,() => {
        if (initialized.value) {
            selectedHostname.value = ""
            transferNetwork.value = ""
        }
        checkReadyState()
    })
    watch(selectedHostname,() => {
        checkReadyState()
    })
    watch(initialized,() => {
        checkReadyState()
    })
    watch(port,() => {
        checkReadyState()
    })
    watch(alias,() => {
        checkReadyState()
    })
    watch(transferNetworkErrors,() => {
        checkReadyState()
    })
    watch(transferNetwork,(value,oldValue) => {
        checkReadyState()
        if(value.length >= 1) {
            hasIpChanged.value = true
        }
    })
    watch(interfaceOptions,() => {
        if (initialized.value && props.properties.type == "add") {
            if(interfaceOptions.value.length > 0) {
                selectedInterface.value = interfaceOptions.value[0].id as number
            }
        }
    })
    watch(hostnameOptions,() => {
        if (initialized.value && props.properties.type == "add") {
            const foundDyndns = hostnameOptions.value.some((host) => {
                const testAddress = new ipaddr(host.id as string)
                if (!testAddress.isIPv4() && !testAddress.isIPv6()) {
                    selectedHostname.value = host.id as string
                    return true
                }
            })
            if (!foundDyndns && hostnameOptions.value.length > 0) {
                selectedHostname.value = hostnameOptions.value[0].id as string
            }
        }
    })
    watch(doOldCloudModelsExist,async () => {
        if(doOldCloudModelsExist.value == true && selectedUtm.value) {
            products.unifiedSecurityConsole.utmNodes.networkViewsWithOldModels.push(selectedUtm.value)
        }
    })
    onMounted(async () => {
        await getterHelpers.useStore().dispatch(ActionTypes.getObjectInfos, {
            "accountId": activeAccountId.value,
            "objectTypes": ['ccutmStates']
        })
        // IF UTMID IS GIVEN, USE IT
        if (props?.properties?.alias) {
            alias.value = props.properties.alias
        }
        if (props?.properties?.utmId) {
            selectedUtm.value = props.properties.utmId
        }
        if (props?.properties?.port) {
            port.value = props.properties.port
        }
        if (props?.properties?.interface) {
            selectedInterface.value = Number(props.properties.interface)
        }
        if (props?.properties?.transferNetwork != undefined) {
            transferNetwork.value = props.properties.transferNetwork
        }
        if (props?.properties?.hostname) {
            selectedHostname.value = props.properties.hostname
        }
        if (props?.properties?.primaryDNS && primaryDNS.value == undefined) {
            primaryDNS.value = props.properties.primaryDNS
        }
        if (props?.properties?.secondaryDNS && secondaryDNS.value == undefined) {
            secondaryDNS.value = props.properties.secondaryDNS
        }
        checkReadyState()
        await timeHelpers.sleep(1000)
        initialized.value = true
    })

    const addDNS = (type:"primary"|"secondary") => {

        let coreInfos = {
            alias:alias.value,
            selectedUtm:selectedUtm.value,
            port:port.value,
            selectedInterface:selectedInterface.value,
            transferNetwork:transferNetwork.value,
            selectedHostname:selectedHostname.value,
            primaryDNS:primaryDNS.value,
            secondaryDNS:secondaryDNS.value
        }

        const serviceId = type == 'primary' ? (<SunNkViewService>primaryDNS.value?.service)?.id || undefined : (<SunNkViewService>secondaryDNS.value?.service)?.id || undefined
        const nodeId = type == 'primary' ? (<SunNkViewNode>primaryDNS.value?.node)?.id || undefined : (<SunNkViewNode>secondaryDNS.value?.node)?.id || undefined

        products.unifiedSecurityConsole.topologies.view.getCoreDNSDialog('add',activeAccountId.value,props.properties.topologyId,selectedUtm.value || props.properties.utmId,nodeId,serviceId,undefined,(payload) => {
            setTimeout(() => {
                if (coreInfos.alias) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.alias = coreInfos.alias
                }
                if (coreInfos.selectedUtm) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.selectedUtm = coreInfos.selectedUtm
                }
                if (coreInfos.port) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.port = coreInfos.port
                }
                if (coreInfos.selectedInterface) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.selectedInterface = coreInfos.selectedInterface
                }
                if (coreInfos.transferNetwork != undefined) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.transferNetwork = coreInfos.transferNetwork
                }
                if (coreInfos.selectedHostname) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.selectedHostname = coreInfos.selectedHostname
                }
                if (coreInfos.primaryDNS) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.primaryDNS = coreInfos.primaryDNS
                }
                if (coreInfos.secondaryDNS) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.secondaryDNS = coreInfos.secondaryDNS
                }

                if (type == 'primary') {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.primaryDNS = payload
                }
                if (type == 'secondary') {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.secondaryDNS = payload
                }
            },1000)
            
            

        },undefined,() => {
            // set dialog inputs
            setTimeout(() => {
                if (coreInfos.alias) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.alias = coreInfos.alias
                }
                if (coreInfos.selectedUtm) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.selectedUtm = coreInfos.selectedUtm
                }
                if (coreInfos.port) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.port = coreInfos.port
                }
                if (coreInfos.selectedInterface) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.selectedInterface = coreInfos.selectedInterface
                }
                if (coreInfos.transferNetwork != undefined) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.transferNetwork = coreInfos.transferNetwork
                }
                if (coreInfos.selectedHostname) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.selectedHostname = coreInfos.selectedHostname
                }
                if (coreInfos.primaryDNS) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.primaryDNS = coreInfos.primaryDNS
                }
                if (coreInfos.secondaryDNS) {
                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.secondaryDNS = coreInfos.secondaryDNS
                }
            },1000)
        });
    }

    const getNodeTooltip = (node:CreateNode|SunNkViewNode|UpdateDnsEntry['node']) => {
        if (node?.node_zone) {
            return `<table style="margin:0;">
                        <tbody>
                            <tr><td>${T('Address')}:</td><td>${encodingHelpers.escapeHTML(node.node_address)}</td></tr>
                            <tr><td>Zone:</td><td>${encodingHelpers.escapeHTML(node.node_zone.name)}</td></tr>
                        </tbody>
                    </table>`;
        }
        else {
            return T('Keine Info')
        }
    }
    const getServiceTooltip = (service:CreateService|SunNkViewService|UpdateDnsEntry['service']) => {
        if ((<SunNkViewService>service)?.id) {
            return Array.isArray((<SunNkViewService>service).service_refs) ? `
                    <table style="margin:0;">
                        <tbody>
                            <tr>
                                <td><strong>${T('Service Group')}</strong></td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td>${T('Services:')}</td>
                            </tr>
                            <tr>
                                <td>
                                    ${(<SunNkViewService>service)?.service_refs?.join('<br>')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ` : `
                    <table style="margin:0;">
                        <tbody>
                            <tr>
                                <td><strong>${T('Service')}</strong></td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td>${T('Protocol')}:</td>
                                <td>${(<SunNkViewService>service).protocol}</td>
                            </tr>
                            <tr>
                                <td>${T('Source ports')}:</td>
                                <td>${((<SunNkViewService>service)["src-ports"]?.join(", ") || '-')}</td>
                            </tr>
                            <tr>
                                <td>${T('Destination ports')}:</td>
                                <td>${((<SunNkViewService>service)["dst-ports"]?.join(", ") || '-')}</td>
                            </tr>
                        </tbody>
                    </table>
                `;
        }
        else if (Array.isArray((<SunNkViewService>service)?.services) && (<SunNkViewService>service)?.id == undefined) {
            let allServices : string = ""
            ;(<SunNkViewService>service).services?.forEach((thisService:any) => {
                allServices = allServices + encodingHelpers.escapeHTML(thisService.name) + "<br>"
            })
            return `
                <table style="margin:0;">
                    <tbody>
                        <tr>
                            <td><strong>${T('Service Group')}</strong></td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td>${T('Services:')}</td>
                        </tr>
                        <tr>
                            <td>
                                ${allServices}
                            </td>
                        </tr>
                    </tbody>
                </table>`
        }
        else {
            return T('Keine Info')
        }
    }

    // EXPOSE SELECTED VALUES
    defineExpose({ 
        selectedUtm, 
        port, 
        selectedInterface, 
        selectedHostname, 
        selectedInterfaceInfo,
        transferNetwork,
        alias,
        primaryDNS,
        secondaryDNS,
        addError,
        clearErrors,
    })
</script>
<template>
    <div class="content-2">
        <template v-if="initialized">

            <template v-if="formattedResponseErrors.some((err) => { return err.property !== 'transferNetwork' })">
                <p>
                    <template v-for="err in formattedResponseErrors.filter((err) => { return err.property !== 'transferNetwork' }) ">
                        <span class="error-bubble label bg-red margin-xs-t"> {{ T(err.error) }}</span>
                    </template>
                </p>
            </template>

            <div class="row padding-xs-y form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-5">
                    <label class="control-label inputname" for="utmSelect">
                        {{ T('UTM') }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-8">
                    <input-vue-select 
                        id="utmSelect"
                        v-model="selectedUtm" 
                        :select-options="utmOptions"
                        :disabled="props.properties?.utmId != undefined"
                        :placeholder="T('Select an UTM')"
                    ></input-vue-select>
                </div>
                <div class="desc col-xs-24 col-lg-11">
                    <p class="input-description">
                        <template v-if="props.properties?.utmId">
                            {{ T('The UTM cannot be changed retrospectively.') }}
                        </template>
                        <template v-else>
                            {{ T('Currently only one UTM can be added.') }}
                        </template>
                    </p>
                </div>
            </div>
            <template v-if="selectedUtm && !loadingNodeInfo && canUseUtm">
                <div class="row padding-xs-y form-group border-bottom">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname" for="alias">
                            {{ T('Alias') }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8">
                        <input-text id="alias" v-model="alias" :regex="/^[a-zA-Z0-9_-]{0,32}/gm" :placeholder="T('Alias')"></input-text>
                    </div>
                    <div class="desc col-xs-24 col-lg-11">
                        <p class="input-description">
                            <strong>{{ T('Pflichtfeld') }}.</strong> {{ T('Vergeben Sie einen Alias (wie z. B. "LG1"), um generierte Objekte auf der UTM leichter zuordnen zu können. (max 32 Zeichen (a-zA-Z0-9_-)).') }}
                        </p>
                    </div>
                </div>
                <div class="row padding-xs-y form-group border-bottom" v-if="props?.properties?.utmId">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname" for="portSelect">
                            {{ T('Port') }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8">
                        <input-number id="portSelect" v-model="port" disabled :min="0" :max="65535" :placeholder="T('Enter port')"></input-number>
                    </div>
                    <div class="desc col-xs-24 col-lg-11">
                        <p class="input-description">
                            {{ T('The Port that is used by the VPN service.') }}
                        </p>
                    </div>
                </div>

                <div class="row padding-xs-y form-group border-bottom">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname" for="interfaceSelect">
                            {{ T('Interface') }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8">

                        <input-vue-select 
                            id="interfaceSelect" 
                            v-model="selectedInterface" 
                            :select-options="interfaceOptions"
                            :disabled="!selectedUtm ? true : null"
                            :placeholder="T('Select an interface')"
                            :onSelecting="onSelectInterface"
                        ></input-vue-select>

                    </div>
                    <div class="desc col-xs-24 col-lg-11">
                        <p class="input-description">
                            {{ T('The interface that will be used to establish the connections.') }}
                        </p>
                    </div>
                </div>

                <div class="row padding-xs-y form-group border-bottom">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname" for="hostnameSelect">
                            {{ T('Hostname/Public IP address') }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8">

                        <input-vue-select 
                            id="hostnameSelect" 
                            v-model="selectedHostname" 
                            :select-options="hostnameOptions"
                            :disabled="selectedInterface == null || Number.isNaN(selectedInterface) ? true : null" 
                            :placeholder="T('Please select')"
                        ></input-vue-select>

                    </div>
                    <div class="desc col-xs-24 col-lg-11">
                        <p class="input-description">
                            {{ T('The hostname that will be used to establish the connections.') }}
                        </p>
                    </div>
                </div>

                <div class="row padding-xs-y form-group border-bottom">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname" for="utmSelect">
                            {{ T('Transfer network') }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8">

                        <input-text v-model="transferNetwork" :placeholder="T('Enter a private IP')" :disabled="selectedInterface == null || Number.isNaN(selectedInterface) ? true : null" ></input-text>
                        <template v-if="formattedResponseErrors.some((err) => { return err.property == 'transferNetwork'})">
                            <template
                                v-for="err in formattedResponseErrors.filter((err) => { return err.property == 'transferNetwork'})">
                                <span class="error-bubble label bg-red margin-xs-t"> {{ T(err.error) }}</span>
                            </template>
                        </template>
                        <template v-if="transferNetworkErrors.length">
                            <template v-for="err in transferNetworkErrors">
                                <span class="error-bubble label bg-red margin-xs-t"> {{ T(err) }}</span>
                            </template>
                        </template>
                    </div>
                    <div class="desc col-xs-24 col-lg-11">
                        <p class="input-description">
                            {{ T('IPv4 or IPv6 address to be used as the transfer network.') }}
                        </p>
                    </div>

                </div>
                <div class="row padding-xs-y-2 form-group border-bottom">
                    <div class="col-xs-24">
                        <p class="text-bold">
                            {{ T('Sie können hier einen DNS-Server im Netzwerk der Core-UTM auswählen, damit die Namensauflösung für Ihre Roadwarrior-Clients funktioniert. Es werden in den Roadwarrior-Pools automatisch Regeln angelegt.') }}
                        </p>
                    </div>
                </div>
                <div class="row padding-xs-y form-group border-bottom">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname">
                            {{ T('Primärer DNS-Server') }} (Optional)
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8 col-xl-8">
                        <template v-if="primaryDNSNodeInfo &&  primaryDNSServiceInfo">
                            <table style="margin:0;" class="border">
                                <tbody>
                                    <tr>
                                        <th>
                                            {{ T('Netzwerkobjekt') }}
                                        </th>
                                        <th>
                                            {{ T('Service') }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i class="icon" :class="products.unifiedSecurityConsole.topologies.view.getIconClassForNode(primaryDNSNodeInfo)"></i> 
                                            {{ primaryDNSNodeInfo.name }}
                                            <Tooltip :is-tag="'span'" :tooltip="getNodeTooltip(primaryDNSNodeInfo)" :html-tooltip="true">
                                                <i class="fal fa-info-circle"></i>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <i class="icon" :class="(/^(tcp|udp|icmp|ipv6-icmp)$/.test( primaryDNSServiceInfo.protocol) ? 'icon icon-serviceobject-' +  primaryDNSServiceInfo.protocol : (Array.isArray( primaryDNSServiceInfo.service_refs) || (Array.isArray( primaryDNSServiceInfo?.services) &&  primaryDNSServiceInfo.id == undefined)) ? 'icon icon-serviceobject-group' : 'icon icon-serviceobject-other')"></i> 
                                            {{ primaryDNSServiceInfo.name }}
                                            <Tooltip :is-tag="'span'" :tooltip="getServiceTooltip(primaryDNSServiceInfo)" :html-tooltip="true">
                                                <i class="fal fa-info-circle"></i>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                        <template v-else>
                            <buttonComponent :button-options="{
                                'icon': 'fal fa-plus',
                                'id': 'addDNSButton',
                                'text': T('DNS-Server hinzufügen'),
                                'title': T('DNS-Server hinzufügen'),
                                'onClick': () => { 
                                    addDNS('primary')
                                },
                                'size': 'sm',
                                'disabled':isSaving || !selectedUtm
                            }"></buttonComponent>
                        </template>
                    </div>
                    <div class="col-xs-24 col-lg-4 col-xl-4" v-if="primaryDNS">
                        <buttonComponent :button-options="{
                            'icon': 'fal fa-trash',
                            'id': 'deletePrimaryDNSButton',
                            'title': T('DNS-Server entfernen'),
                            'onClick': () => { 
                                primaryDNS = undefined
                            },
                            'size': 'sm',
                            'disabled':isSaving || !selectedUtm || secondaryDNS
                        }"></buttonComponent>
                        <buttonComponent :button-options="{
                            'icon': 'fal fa-wrench',
                            'id': 'editPrimaryDNSButton',
                            'title': T('DNS-Server bearbeiten'),
                            'onClick': () => { 
                                addDNS('primary')
                            },
                            'size': 'sm',
                            'disabled':isSaving || !selectedUtm
                        }"></buttonComponent>
                    </div>
                    <div class="input-description col-xs-24" :class="primaryDNS ? 'col-lg-7 col-xl-7' : 'col-lg-11 col-xl-11'">
                        {{ T('Bearbeiten Sie einen bestehenden DNS-Server oder legen Sie einen Neuen an.') }}
                    </div>
                </div>
                <div class="row padding-xs-y form-group border-bottom">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname">
                            {{ T('Sekundärer DNS-Server') }} (Optional)
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8 col-xl-8">
                        <template v-if="secondaryDNSNodeInfo && secondaryDNSServiceInfo">
                            <div class="padding-xs-y">
                                <table style="margin:0;" class="border">
                                    <tbody>
                                        <tr>
                                            <th>
                                                {{ T('Netzwerkobjekt') }}
                                            </th>
                                            <th>
                                                {{ T('Service') }}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <i class="icon" :class="products.unifiedSecurityConsole.topologies.view.getIconClassForNode(secondaryDNSNodeInfo)"></i> 
                                                {{ secondaryDNSNodeInfo.name }}
                                                <Tooltip :is-tag="'span'" :tooltip="getNodeTooltip(secondaryDNSNodeInfo)" :html-tooltip="true">
                                                    <i class="fal fa-info-circle"></i>
                                                </Tooltip>
                                            </td>
                                            <td>
                                                <i class="icon" :class="(/^(tcp|udp|icmp|ipv6-icmp)$/.test( secondaryDNSServiceInfo.protocol) ? 'icon icon-serviceobject-' +  secondaryDNSServiceInfo.protocol : (Array.isArray( secondaryDNSServiceInfo.service_refs) || (Array.isArray( secondaryDNSServiceInfo?.services) &&  secondaryDNSServiceInfo.id == undefined)) ? 'icon icon-serviceobject-group' : 'icon icon-serviceobject-other')"></i> 
                                                {{ secondaryDNSServiceInfo.name }}
                                                <Tooltip :is-tag="'span'" :tooltip="getServiceTooltip(secondaryDNSServiceInfo)" :html-tooltip="true">
                                                    <i class="fal fa-info-circle"></i>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-else>
                            <buttonComponent :button-options="{
                                'icon': 'fal fa-plus',
                                'id': 'addSecondaryDNSButton',
                                'text': T('DNS-Server hinzufügen'),
                                'title': T('DNS-Server hinzufügen'),
                                'onClick': () => { 
                                    addDNS('secondary')
                                },
                                'size': 'sm',
                                'disabled':isSaving || !primaryDNS || !selectedUtm
                            }"></buttonComponent>
                        </template>
                    </div>
                    <div class="col-xs-24 col-lg-4 col-xl-4" v-if="secondaryDNS">
                        <buttonComponent :button-options="{
                            'icon': 'fal fa-trash',
                            'id': 'deleteSecondaryDNSButton',
                            'title': T('DNS-Server entfernen'),
                            'onClick': () => { 
                                secondaryDNS = undefined
                            },
                            'size': 'sm',
                            'disabled':isSaving || !selectedUtm
                        }"></buttonComponent>
                        <buttonComponent :button-options="{
                            'icon': 'fal fa-wrench',
                            'id': 'editSecondaryDNSButton',
                            'title': T('DNS-Server bearbeiten'),
                            'onClick': () => { 
                                addDNS('secondary')
                            },
                            'size': 'sm',
                            'disabled':isSaving || !primaryDNS || !selectedUtm
                        }"></buttonComponent>
                    </div>
                    <div class="input-description col-xs-24" :class="secondaryDNS ? 'col-lg-7 col-xl-7' : 'col-lg-11 col-xl-11'">
                        {{ T('Bearbeiten Sie einen bestehenden DNS-Server oder legen Sie einen Neuen an.') }}
                    </div>
                </div>

            </template>
            <template v-else-if="loadingNodeInfo">
                <div class="text-center padding-xs-y-8 col-xs">
                    <div class="text-size-3">
                        <loader class="text-size-2 color-red" />
                    </div>
                </div>
            </template>
            <template v-else-if="selectedUtm && !canUseUtm && networkViewErrors.length > 0">
                <template v-for="error in networkViewErrors">
                    <p class="notification bg-red color-white"> {{ T(error) }}</p>
                </template>
            </template>
            <template v-else-if="selectedUtm && !canUseUtm">
                <p class="error-bubble label bg-red margin-xs-t"> 
                    {{ T("Die ausgewählte UTM kann nicht als Core-UTM konfiguriert werden, da sie kein Interface mit einer öffentlichen IP-Adresse oder einem konfigurierten DynDNS besitzt.") }}
                </p>
            </template>
        </template>
        <template v-else>
            <div class="text-center padding-xs-y-8 col-xs">
                <div class="text-size-3">
                    <loader class="text-size-2 color-red" />
                </div>
            </div>
        </template>
    </div>
</template>