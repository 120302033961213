import { T } from "@/classes/i18n"
import ipaddr from "@/lib/ipaddr"

const validationHelpers = {
    isString: function(val:any) {
        return typeof val == "string"
    },
    isNumber: function(val:any) {
        return typeof val == "number"
    },
    isArray: function(val:any) {
        return Array.isArray(val)
    },
    isObj: function(val:any) {
        return (typeof val == "object" && !Array.isArray(val) && val != null)
    },
    isBoolean: function(val:any) {
        return typeof val == "boolean"
    },
    isEmail: function(val:string) {
        let emailRegex : any = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        return emailRegex.test(val)
    },
    isIp: function(val:string) {
        let ipRegex : any = RegExp(/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/)
        return ipRegex.test(val)
    },
    isIpv4Cidr: function(val: string) {
        let ipv4Regex: RegExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/
        return ipv4Regex.test(val)
    },
    isIpWithSubnet: function(val:string) {
        let ipRegex : any = RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/\d+$/)
        return ipRegex.test(val)
    },
    isPublicIPv4: function (address: string, useHardCodedAddresses = true): boolean {
        address = this.removeCidr(address)

        if (this.isIp(address) === false) {
            // treat invalid IPv4 as private
            return false
        }

        const octets = address.split(".")
        const octetNumbers = octets.map((octet) => parseInt(octet, 10))

        // Check if the address is in the private IPv4 address ranges
        if (
            // private ranges
            octetNumbers[0] === 10 ||
            (octetNumbers[0] === 172 && octetNumbers[1] >= 16 && octetNumbers[1] <= 31) ||
            (octetNumbers[0] === 192 && octetNumbers[1] === 168) ||
            // reserved ranges (there are more, add them as needed)
            (useHardCodedAddresses && (
                octetNumbers[0] === 0 ||
                address === "127.0.0.1" ||
                address === "255.255.255.255"))
        ) {
            return false // Private IPv4 address
        }
        return true
    },
    removeCidr(ip: string): string {
        if (ip.includes("/")) {
            return ip.split("/")[0]
        }
        return ip
    },
    isPrivateIPv4: function (address: string, useHardCodedAddresses = true): boolean {
        return !this.isPublicIPv4(address, useHardCodedAddresses)
    },
    isIpV6: function(address:string) {
        let ipV6Regex: any = RegExp(/(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/)
        return ipV6Regex.test(address)
    },
    isPublicIpV6: function (address: string, withReservedScopes = true) {
        address = this.removeCidr(address)

        if (this.isIpV6(address) === false) {
            // treat invalid IPv6 as private
            return true
        }

        if (
            (withReservedScopes && (
                address.startsWith("ff") || // Multicast
                address === "::1" || // Loopback
                address.startsWith("::ffff:") || // IPv4-Mapped Ipv6 Addresses
                (address.startsWith("::") && address.split(".").length === 4) || // IPv4-Compatible IPv6 Addresses
                address.startsWith("0100:") || // Deprecated
                address.startsWith("3ffe:") || // Deprecated
                address.startsWith("2002:") || // 6to4
                address.startsWith("2001:db8:") || // Documentation and Example Addresses
                (address.startsWith("2001:") && address !== "2001:0a" && address !== "2001:20") || // Additional documentation address
                address.startsWith("0100:"))) ||  // Deprecated

            address.startsWith("fe80:") || // Link-Local
            address.startsWith("fc") || // Unique-Local
            address.startsWith("fd") || // Unique-Local
            address.startsWith("fec0:") // Site-Local (deprecated)
        ) {
            return true // Address is private
        }

        return false // Address is not private
    },
    isPrivateIpV6: function(address:string, withReservedScopes = true) {
        try {
            if(!validationHelpers.isIpV6(address)) {
                return false
            }
            else if (!validationHelpers.isPublicIpV6(address, withReservedScopes)) {
                return false
            }
        }
        catch(e:any) {
            return false
        }
        return true
    },
    calculateNumOfIpAddressesByTransfernetwork(Transfernetwork: string): number | bigint {
        const [ip, prefixLength] = Transfernetwork?.split('/') || [];
        const prefix = parseInt(prefixLength);
    
        if (ip.includes(':')) {
            // IPv6-Adressraum
            const totalBits = 128;
            const availableBits = totalBits - prefix;
            return BigInt(2) ** BigInt(availableBits); // Anzahl der möglichen Adressen im IPv6-Subnetz
        } else {
            // IPv4-Adressraum
            const totalBits = 32;
            const availableBits = totalBits - prefix;
            return Math.pow(2, availableBits); // Anzahl der möglichen Adressen im IPv4-Subnetz
        }
    },
    getBaseIPV4Addresses:(networkAddress:string, subnetMask:number) => {
        const subnetSize = Math.pow(2, 32 - subnetMask);
        
        // Konvertiere die Netzwerkadresse in eine Ganzzahl
        const addressParts = networkAddress.split('.').map(Number);
        let baseAddress = (addressParts[0] << 24) | (addressParts[1] << 16) | (addressParts[2] << 8) | addressParts[3];
        
        // Berechne die Endadresse des Adressraums, der abgedeckt wird
        const endAddress = baseAddress + Math.pow(2, 32 - (subnetMask >= 24 ? 24 : subnetMask >= 16 ? 16 : subnetMask >= 8 ? 8 : 0)); // Hier wird für ein /16 Netz gerechnet (du kannst es anpassen)
        
        let baseIPAddresses = [];
        
        // Berechne Basis-IP-Adressen, die innerhalb des Adressbereichs bleiben
        for (let address = baseAddress; address < endAddress; address += subnetSize) {
            let a = (address >> 24) & 0xFF;
            let b = (address >> 16) & 0xFF;
            let c = (address >> 8) & 0xFF;
            let d = address & 0xFF;
            baseIPAddresses.push(`${a}.${b}.${c}.${d}`);
        }
        return baseIPAddresses;
    },
    ipv6ToBigInt: (ipv6:string) => {
        return BigInt(`0x${ipv6.split(':').map(part => part.padStart(4, '0')).join('')}`);
    },
    bigIntToIpv6: (bigInt:any) => {
        const hexString = bigInt.toString(16).padStart(32, '0');
        return hexString.match(/.{1,4}/g).join(':');
    },
    getBaseIPV6Addresses: (networkAddress:string, subnetMask:number) => {
        const addressParts = networkAddress.split(':').map(part => parseInt(part, 16));
        let baseAddress = validationHelpers.ipv6ToBigInt(networkAddress);
        
        const subnetSize = BigInt(2) ** BigInt(128 - subnetMask);
        const endAddress = baseAddress + BigInt(2) ** BigInt(128 - 112); // Zum Beispiel für /112 Netzwerke
    
        let baseIPAddresses = [];
    
        for (let address = baseAddress; address < endAddress; address += subnetSize) {
            baseIPAddresses.push(validationHelpers.bigIntToIpv6(address));
        }
    
        return baseIPAddresses;
    },
    isCorrectIpV4Base:(ip:string,cidr:NumericRange<CreateArrayWithLengthX<0>,32>) : true | {'error':true, 'message':string } => {
        const splitIp = ip.split(".")
        const baseIp = [splitIp[0],splitIp[1],(cidr >= 24 ? splitIp[2] : "0"),"0"].join(".")

        const possibleIps = validationHelpers.getBaseIPV4Addresses(baseIp,cidr)
        if (possibleIps.length > 0) {
            if(possibleIps.indexOf(ip) != -1) {
                return true
            }
            else {
                return {
                    "error":true,
                    "message": function() {
                        let msg = T("The given IP is not compatible with the subnet. Please use one of the following IPs: ")
                        for (let i = 0; i < 5; i++) {
                            if (possibleIps[i]) {
                                msg += possibleIps[i]
                                if(i <= 5) {
                                    msg += ", "
                                }
                            }
                        }  
                        return msg
                    }()
                }    
            }
        }
        else {
            return {
                "error":true,
                "message":"Given IP can't be validated ("+ ip + "/" + cidr + ")"
            }
        }
    },
    isCorrectIpV6Base:(ip:string,cidr:NumericRange<CreateArrayWithLengthX<0>,128>) : true | {'error':true, 'message':string } => {
        const address = new ipaddr(ip)
        const expandedAddress = address.ip6_expand(ip).toLocaleLowerCase()
        const splitIp = expandedAddress.split(":")
        const baseAddress = splitIp.slice(0,splitIp.length - 1).join(":") + ":0000"
        const possibleIps = validationHelpers.getBaseIPV6Addresses(baseAddress,cidr)

        if (possibleIps.length > 0) {
            if(possibleIps.indexOf(expandedAddress) != -1) {
                return true
            }
            else {
                return {
                    "error":true,
                    "message": function() {
                        let msg = T("The given IP is not compatible with the subnet. Please use one of the following IPs: ")
                        for (let i = 0; i < 5; i++) {
                            if (possibleIps[i]) {
                                msg += possibleIps[i]
                                if(i < 4 && possibleIps[i + 1]) {
                                    msg += ", "
                                }
                            }
                        }                        
                        return msg
                    }()
                }    
            }
        }
        else {
            return {
                "error":true,
                "message":"Given IP can't be validated ("+ ip + "/" + cidr + ")"
            }
        }
    },

    isFQDN: function (fqdn: string) {
        const errors = []

        // Maximum length of a FQDN is 255 characters
        if (fqdn.length > 255) {
            errors.push("FQDN length exceeds the maximum allowed length of 255 characters.")
        }

        // FQDN should not end with a dot (it's optional)
        if (fqdn.endsWith(".")) {
            errors.push("FQDN should not end with a dot.")
            fqdn = fqdn.slice(0, -1) // remove the trailing dot
        }

        // Labels are separated by dots
        const labels = fqdn.split(".")

        // FQDN must consist of at least two labels (domain name and TLD)
        if (labels.length < 2) {
            errors.push("FQDN must consist of at least two labels separated by dots.")
        }

        // Each label should be between 1 and 63 characters long
        for (let i = 0; i < labels.length; i++) {
            const label = labels[i]
            if (label.length < 1 || label.length > 63) {
                errors.push(
                    `Label ${i + 1
                    } "${label}" length is invalid. Labels must be between 1 and 63 characters.`
                )
            }

            // Labels can only contain alphanumeric characters (a-z, A-Z, 0-9) and hyphens (-)
            if (!/^[a-zA-Z0-9-]+$/.test(label)) {
                errors.push(
                    `Label ${i + 1
                    } "${label}" contains invalid characters. Labels can only contain alphanumeric characters and hyphens.`
                )
            }

            // Labels cannot start or end with a hyphen
            if (label.startsWith("-") || label.endsWith("-")) {
                errors.push(
                    `Label ${i + 1} "${label}" starts or ends with a hyphen, which is not allowed.`
                )
            }

            // Top-level domain cannot be all numeric
            if (i === labels.length - 1 && /^[0-9]+$/.test(label)) {
                errors.push(`Top-level domain "${label}" cannot be all numeric.`)
            }
        }

        // If there are no errors, FQDN is valid
        const isValid = errors.length === 0
        return isValid
    },
    isBase64Image: function(val:string) {
        let base64Regex : any = RegExp(/data:image\/([a-zA-Z]*);base64,([^\"]*)/)
        return base64Regex.test(val)
    },
    isUUID: function(val:string) {
        let uuidRegex : any = RegExp(/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/)
        return uuidRegex.test(val)
    },
    checkLength: function(val:string|any[],minLength?:number,maxLength?:number) {
        let result : boolean = true
        if(minLength != undefined && maxLength != undefined) {
            if(val.length < minLength || val.length > maxLength) {
                result = false
            }
        }
        else if (minLength != undefined) {
            if(val.length < minLength) {
                result = false
            }
        }
        else if (maxLength != undefined) {
            if(val.length > maxLength) {
                result = false
            }
        }
        return result
    },

    /**
     * Simply compares two string version values.
     * 
     * Example:
     * versionCompare('1.1', '1.2') => -1
     * versionCompare('1.1', '1.1') =>  0
     * versionCompare('1.2', '1.1') =>  1
     * versionCompare('2.23.3', '2.22.3') => 1
     * 
     * Returns:
     * -1 = left is LOWER than right
     *  0 = they are equal
     *  1 = left is GREATER = right is LOWER
     *  And FALSE if one of input versions are not valid
     *
     * @function
     * @param {String} left  Version #1
     * @param {String} right Version #2
     * @return {Integer|Boolean}
     * @since 2011-07-14
     */
    versionCompare: function(left:string, right:string) {
        if (typeof left + typeof right != 'stringstring') {
            return false
        }

        let a = left.split('.'), 
        b = right.split('.'), 
        len = Math.max(a.length, b.length);

        for (let i = 0; i < len; i++) {
            if ((a[i] && !b[i] && parseInt(a[i]) > 0) || (parseInt(a[i]) > parseInt(b[i]))) {
                return 1
            } else if ((b[i] && !a[i] && parseInt(b[i]) > 0) || (parseInt(a[i]) < parseInt(b[i]))) {
                return -1
            }
        }
        return 0;
    }    
}
export default validationHelpers