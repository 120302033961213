<template>
    <tempalte v-if="!loading">
        <div class="box-border padding-xs-2" style="height: 350px;overflow: auto;width:100%;">
            <pre class="text-field">
                {{ licenseConditions }}
            </pre>
        </div>
    </tempalte>
    <template v-else>
        <div class="text-center padding-xs-y-8 col-xs">
            <div class="text-size-3"><loader class="text-size-2 color-red" /></div>
        </div>
    </template>
</template>

<script setup lang="ts">
import i18n from '@/classes/i18n';
import requestHandler from '@/queries/requests';
import { onMounted, ref } from 'vue';
import Loader from "../components/loader.vue"

const loading = ref(true)
const licenseConditions = ref('')
const lang = ref(i18n.getLanguage())

onMounted(async () => {
    licenseConditions.value = await requestHandler.request("GET", `/sms-policy/utm/license-conditions?lang=${lang.value}`)
    loading.value = false
})

</script>

<style scoped lang="scss">
.text-field {
    border: 0;
    background: transparent;
    color: black;
    margin: 0;
    width: 100%;
    height: 100%;
}

.darkmode {
    .text-field {
        color: white;
    }
}
</style>