import { nextTick } from "vue";
import jsonHelpers from "../../helpers/helpers.json";
import { useGlobalMixin } from "@/mixins/mixins.global";
import frontendNotifications from "@/classes/notifications";
import moment from "moment";
import { ActionTypes, MutationTypes } from "@/store/vuex.store";
import i18n, { T } from "@/classes/i18n";
import loaderComponent from "../components/loader.vue";
import table2Component from "../components/table2";
import itemlistItemComponent from "../components/itemlist-item.vue";
import { sprintf } from "sprintf-js"
import router from "@/router/router";
import requestHandler from "@/queries/requests";
import objectStores from "@/classes/init";
import dialogs from "@/dialogs/dialogs";
import inputDateComponent from "../inputtypes/input-date-async";
import googleMapComponent from "../components/map";
import cronojoblistComponent from "../components/cronojoblist";
import queries from "@/queries/queries";
import { useStore } from '@/store/vuex.store'
import numberHelpers from "@/helpers/helpers.numbers";
import encodingHelpers from "@/helpers/helpers.encoding";
import type { CanUseFeatureNames, UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms";
import timeHelpers from "@/helpers/helpers.time";
import { vueApp } from "@/app";
import stringHelpers from "@/helpers/helpers.strings";
import validationHelpers from "@/helpers/helpers.validation";
import tenantHelpers from "@/helpers/helpers.tenants";
import Chart from 'chart.js/auto';
import Highcharts from "highcharts"
import useRouterStore from "@/router/routerStore";
import config from "@/classes/config";
import sessionHelpers from "@/helpers/helpers.session";
import vue from "@/main";
import Button from "@/templates/components/button/button";
import buttonComponent from "../components/button/button.vue";
import getterHelpers from "@/helpers/helpers.getters";
import tabs from "@/templates/components/tabs.vue"
import tableNext from "../components/tableNext.vue";
import devLog from "@/classes/log";
import products from "@/classes/objectTypes";
import type { UscProfile } from "@/classes/unifiedSecurityConsole/uscProfiles";
import inputNumber from "../inputtypes/input-number.vue";
import Label from "@/templates/components/label/label";
import Icon from "@/templates/components/icon/icon";

const LANG = i18n.getLanguage()

const utmDetailsComponent = {
	name: "sms-page-utm-details",
	mixins: [useGlobalMixin()],
	template: `
		<section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-if="!initialized">
			<div class="row media-screen-only">
				<div class="col-xs-24 padding-xs">
					<div class="box-shadow">
						<div v-if="!initialized" class="page-loader">
							<div class="text-size-3 text-center padding-xs-t-4">
								<loader
									class="color-red"
									:progress="$parent.loadingProgress"
								></loader>
							</div>
							<template v-if="loaderText">
								<div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
									<span>
										{{ T(loaderText) }}
									</span>
								</div>
							</template>
							<template v-else>
								<div class="padding-xs-b-4"></div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-else-if="initialized">
			<div class="row itemlist show-details view-grid">
				<div class="col-xs-24 col-print-24 col-lg-10 padding-xs">
					<itemlist-item
						:item="utm"
						:index="1"
						:showInfo="true"
						:key="'utmDetails'"
						:ref="'utmDetailsTile'"
						:noCol="true"
						style="height:100%"
						:refreshCounterProp="refreshCount"
						:full-title="true"
					/>
				</div>

				<div class="col-xs-24 col-print-24 col-lg-14 padding-xs">
					<div class="box-shadow box-fullheight">
						<div class="padding-xs-t-2 padding-xs-l-2 padding-xs-r-2 padding-xs-b-0">
							<div class="row flexrow" style="flex-wrap:wrap">

								<div class="col-xs-12">
								<h4>Unified Security Report</h4>
								<p v-if="utm?.cluster?.isCluster === true" class="input-description">${T('Aggregated from cluster members')}</p>
								</div>
								<div class="col-xs-12 text-right">
									<label class="form-field margin-xs-b-0" style="margin-bottom: 8px;">
										<span class="form-icon-prefix"><i class="fal fa-clock"></i></span>
										<select id="tenant-report-dashboard-period" v-model="widgets.period" class="form-control">
											<option value="lastday">${T('Yesterday')}</option>
											<option value="lastweek">${T('Last week')}</option>
											<option value="currentmonth">${T('Current month')}</option>
										</select>
									</label>
								</div>
							</div>
								
							<div class="row flexrow" style="flex-wrap:wrap">
								<div class="col-xs-24 col-md-12 padding-xs-b-2">
									<div
										class="box border box-fullheight"
										style="color:#fff; border-radius: 5px; overflow: hidden;"
										:style="'background:'+(getWidgetBackground(widgets.blockedThreats.riskStatus,'body'))"
									>
										<div
											class="box-title text-center border-bottom padding-xs" :style="'background:'+(getWidgetBackground(widgets.blockedThreats.riskStatus,'header'))"
										>
											<h4 style="color:#fff" class="text-bold">{{ T(widgets.blockedThreats.title) }}</h4>
										</div>
										<div class="box-content padding-xs-y-2">
											<template v-if="widgets.blockedThreats.riskStatus != 'loading'">
												<div class="row flexrow" style="flex-wrap:wrap">
													<template v-if="getWidgetIcon(widgets.blockedThreats.riskStatus)">
														<div
															class="icon-area padding-xs-y"
															style="font-size: 50pt;text-align: center;display: flex;align-content: center;"
															:style="'width:'+ (getBlockedThreadsLines().length == 1 ? '100%' : '90px')"
														>
															<i :class="getWidgetIcon(widgets.blockedThreats.riskStatus)" style="display: block; align-self: center; margin: 0 auto;"></i>
														</div>
														<template v-if="getBlockedThreadsLines().length == 1 && utm.license.laasEnabled">
															<p class="text-bold text-center col-xs-24">{{ getBlockedThreadsLines()[0] }}</p>
														</template>
													</template>
													<div class="padding-xs-x">
														<template v-if="getBlockedThreadsLines().length > 1">
															<template v-for="thread in getBlockedThreadsLines()">
																<p class="padding-xs-t">
																	<strong>
																		{{ thread }}
																	</strong>
																</p>
															</template>
														</template>
													</div>
												</div>
											</template>
											<template v-else>
												<div class="text-center padding-xs-y-4">
													<loader style="font-size: 3em;"></loader>
												</div>
											</template>
										</div>
									</div>
								</div>


								<div class="col-xs-24 col-md-12 padding-xs-b-2">
									<div class="box border box-fullheight" style="color:#fff; border-radius: 5px; overflow: hidden;" :style="'background:'+(getWidgetBackground(mapClamAvAndCtAvStatus(widgets.antivirusClamStatus.riskStatus,widgets.antivirusCtavStatus.riskStatus),'body'))">
										<div
											class="box-title text-center border-bottom padding-xs" :style="'background:'+(getWidgetBackground(mapClamAvAndCtAvStatus(widgets.antivirusClamStatus.riskStatus,widgets.antivirusCtavStatus.riskStatus),'header'))"
										>
											<h4 style="color:#fff" class="text-bold">{{ T(widgets.antivirusClamStatus.title) }}</h4>
										</div>
										<div class="box-content padding-xs-y-2">
											<template v-if="widgets.antivirusClamStatus.riskStatus != 'loading'">
												<div
													class="icon-area padding-xs-y"
													style="font-size: 50pt;width: 100%;text-align: center;display: flex;align-content: center;">
													<i
														:class="getWidgetIcon(mapClamAvAndCtAvStatus(widgets.antivirusClamStatus.riskStatus,widgets.antivirusCtavStatus.riskStatus))"
														style="display: block; align-self: center; margin: 0 auto;"
													></i>
												</div>
												<div class="text-center padding-xs-y">
													<strong> {{ T(mapClamAvAndCtAvStatusToText(widgets.antivirusClamStatus.riskStatus,widgets.antivirusCtavStatus.riskStatus)) }}</strong>
												</div>
											</template>
											<template v-else>
												<div class="text-center padding-xs-y-4">
													<loader style="font-size: 3em;"></loader>
												</div>
											</template>
										</div>
									</div>
								</div>

								
								<div class="col-xs-24 col-md-6 padding-xs-b-2">
									<div class="box border box-fullheight" style="color:#fff; border-radius: 5px; overflow: hidden;" :style="'background:'+(getWidgetBackground(widgets.kernelStatus.riskStatus,'body'))">
										<div
											class="box-title text-center border-bottom padding-xs" :style="'background:'+(getWidgetBackground(widgets.kernelStatus.riskStatus,'header'))"
										>
											<h4 style="color:#fff" class="text-bold">{{ T(widgets.kernelStatus.title) }}</h4>
										</div>
										<div class="box-content padding-xs-y-2">
											<template v-if="widgets.kernelStatus.riskStatus != 'loading' && widgets.kernelStatus.data.Drops != undefined && widgets.kernelStatus.data.Rejects != undefined">
												<div class="text-center padding-xs-y">
													<p>
														<span class="text-h3 text-bold" style="color:#fff">
															{{ T(widgets.kernelStatus.data.Drops) }}
														</span>
														<br>
														<strong>{{ T(widgets.kernelStatus.dropsText) }}</strong>
													</p>
													<p class="padding-xs-t">
														<span class="text-h3 text-bold" style="color:#fff">
															{{ T(widgets.kernelStatus.data.Rejects) }}
														</span>
														<br>
														<strong>{{ T(widgets.kernelStatus.rejectsText) }}</strong>
													</p>
												</div>
											</template>
											<template v-else-if="widgets.kernelStatus.riskStatus == 'loading'">
												<div class="text-center padding-xs-y-4">
													<loader style="font-size: 3em;"></loader>
												</div>
											</template>
											<template v-else-if="widgets.kernelStatus.data.Drops == undefined && widgets.kernelStatus.data.Rejects == undefined">
												<div
													class="icon-area padding-xs-y"
													style="font-size: 50pt;width: 100%;text-align: center;display: flex;align-content: center;"
												>
													<i
														:class="getWidgetIcon(widgets.kernelStatus.riskStatus)"
														style="display: block; align-self: center; margin: 0 auto;"
													></i>
												</div>
											</template>
										</div>
									</div>
								</div>

								<div class="col-xs-24 col-md-6 padding-xs-b-2">
									<div class="box border box-fullheight" style="color:#fff; border-radius: 5px; overflow: hidden;" :style="'background:'+(getWidgetBackground(widgets.alertStatus.riskStatus,'body'))">
										<div
											class="box-title text-center border-bottom padding-xs" :style="'background:'+(getWidgetBackground(widgets.alertStatus.riskStatus,'header'))"
										>
											<h4 style="color:#fff" class="text-bold">{{ T(widgets.alertStatus.title) }}</h4>
										</div>
										<div class="box-content padding-xs-y-2">
											<template v-if="widgets.alertStatus.riskStatus != 'loading'">
												<div
													class="icon-area padding-xs-y"
													style="font-size: 50pt;width: 100%;text-align: center;display: flex;align-content: center;">
													<i
														:class="getWidgetIcon(widgets.alertStatus.riskStatus)"
														style="display: block; align-self: center; margin: 0 auto;"
													></i>
												</div>
												<div class="text-center padding-xs-y">
													<strong>{{ T(widgets.alertStatus.text) }}</strong>
												</div>
											</template>
											<template v-else>
												<div class="text-center padding-xs-y-4">
													<loader style="font-size: 3em;"></loader>
												</div>
											</template>
										</div>
									</div>
								</div>


								<div class="col-xs-24 col-md-6 padding-xs-b-2">
									<div class="box border box-fullheight" style="color:#fff; border-radius: 5px; overflow: hidden;" :style="'background:'+(getWidgetBackground(widgets.httpTrafficStatus.riskStatus,'body'))">
										<div
											class="box-title text-center border-bottom padding-xs" :style="'background:'+(getWidgetBackground(widgets.httpTrafficStatus.riskStatus,'header'))"
										>
											<h4 style="color:#fff" class="text-bold">{{ T(widgets.httpTrafficStatus.title) }}</h4>
										</div>
										<div class="box-content padding-xs-y-2">
											<template v-if="widgets.httpTrafficStatus.riskStatus != 'loading'">
												<template v-if="widgets.httpTrafficStatus.riskStatus != 'no license'">
													<template v-if="widgets.httpTrafficStatus.data.Traffic > 0">
														<div class="text-center" style="padding-top:34px; padding-bottom:34px">
															<strong>
																<span class="text-size-2">
																	{{ formatBytes(widgets.httpTrafficStatus.data.Traffic).value }}
																</span> <br>
																{{ formatBytes(widgets.httpTrafficStatus.data.Traffic).fullUnit }}
															</strong>
														</div>
													</template>
													<template v-if="widgets.httpTrafficStatus.data.Traffic == 0">
														<div
															class="icon-area padding-xs-y"
															style="font-size: 50pt;width: 100%;text-align: center;display: flex;align-content: center;"
														>
															<i
																:class="getWidgetIcon(widgets.httpTrafficStatus.riskStatus)"
																style="display: block; align-self: center; margin: 0 auto;"
															></i>
														</div>
														<p class="text-center">
															<strong class="">{{ T('No traffic') }}</strong>
														</p>
													</template>
												</template>
												<template v-else>
													<div
														class="icon-area padding-xs-y"
														style="font-size: 50pt;width: 100%;text-align: center;display: flex;align-content: center;"
													>
														<i
															:class="getWidgetIcon(widgets.alertStatus.riskStatus)"
															style="display: block; align-self: center; margin: 0 auto;"
														></i>
													</div>
												</template>
											</template>
											<template v-else>
												<div class="text-center padding-xs-y-4">
													<loader style="font-size: 3em;"></loader>
												</div>
											</template>
										</div>
									</div>
								</div>

								<div class="col-xs-24 col-md-6 padding-xs-b-2">
									<div class="box border box-fullheight" style="color:#fff; border-radius: 5px; overflow: hidden;" :style="'background:'+(getWidgetBackground(widgets.loginStatus.riskStatus,'body'))">
										<div
											class="box-title text-center border-bottom padding-xs" :style="'background:'+(getWidgetBackground(widgets.loginStatus.riskStatus,'header'))"
										>
											<h4 style="color:#fff" class="text-bold">{{ T(widgets.loginStatus.title) }}</h4>
										</div>
										<div class="box-content padding-xs-y-2">
											<template v-if="widgets.loginStatus.riskStatus != 'loading'">
												<div
													class="icon-area padding-xs-y"
													style="font-size: 50pt;width: 100%;text-align: center;display: flex;align-content: center;">
													<i
														:class="getWidgetIcon(widgets.loginStatus.riskStatus)"
														style="display: block; align-self: center; margin: 0 auto;"
													></i>
												</div>
												<div class="text-center padding-xs-y">
													<strong>
													<template v-if="this.widgets.loginStatus.data.Logins != 0">
														{{ this.widgets.loginStatus.data.Logins }}
													</template>&nbsp;{{ T(widgets.loginStatus.text) }}
													</strong>
												</div>
											</template>
											<template v-else>
												<div class="text-center padding-xs-y-4">
													<loader style="font-size: 3em;"></loader>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>

						</div>

						<template v-if="getLaasState(utm) != 'active'">
							<div
								class="no-license-overlay"
								style="position: absolute; left: 0; top: 0; z-index: 5; background: rgba(0,0,0,0.5); color: #fff; right: 0; bottom: 0; padding-top: 159px; text-align: center;"
							>
								<div class="overlay-text" style="text-shadow: 0px 0px 7px #000">
									<h2 class="color-white">

										<template v-if="getLaasState(utm) == 'not_licensed'">
											{{ T('Unified Reporting is not licensed for you') }}
										</template>

										<template v-else-if="getLaasState(utm) == 'licensed'">
											{{ T('Unified Reporting is not activated for this UTM') }}
										</template>

									</h2>
									<template v-if="$store.state.session.isReseller">
										<p style="font-size:1.5em" v-if="getLaasState(utm) == 'not_licensed'">
											{{ T('License now!') }}
										</p>
									</template>
									<template v-else>
										<p style="font-size:1.5em" v-if="getLaasState(utm) == 'not_licensed'">
											{{ T('Please contact your IT system house to license this product') }}
										</p>
										<p style="font-size:1.5em" v-if="getLaasState(utm) == 'licensed'">
											{{ T('Please activate this feature for your UTM') }}
										</p>
									</template>
								</div>
								<template v-if="$store.state.session.isReseller">
									<a rel="noreferrer noopener" style="position:absolute; top:0; left:0; right:0; bottom:0; display:block" :href="getRPAccountLink()" target="_blank"></a>
								</template>
							</div>
						</template>

					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-24 padding-xs">
					<div class="box-shadow">



						<tabs
							ref="tabs"
							:tabs="[
								{
									'text':T('Overview'),
									'tooltip':T('Overview'),
									'id':'overview',
									'icon':'fal fa-server'
								},
								{
									'text': T('Cluster'),
									'tooltip': T('Cluster'),
									'id': 'cluster',
									'icon': 'fal fa-boxes',
									'visible':utm?.cluster?.isCluster
								},
								{
									'text': T('Operations'),
									'id': 'operations',
									'icon': 'fal fa-wrench',
									'tooltip':() => { return (canUseFeature(utm.utmId,'sendJobs').result === false && utmStatus.online) ? getTooltip(canUseFeature(utm.utmId,'sendJobs').reasons) : T('Operations') },
									'disabled':() => { return (!utmStatus.online || !canUseFeature(utm.utmId,'sendJobs').result) }
								},
								{
									'text':T('Websession'),
									'tooltip':() => { return (canUseFeature(utm.utmId,'websession').result === false && utmStatus.online) ? getTooltip(canUseFeature(utm.utmId,'websession').reasons) : T('Websession') },
									'id':'websession',
									'icon': 'fal fa-laptop',
									'disabled':() => { return (!utmStatus.online || !canUseFeature(utm.utmId,'websession').result) }

								},
								{
									'text':T('Cloud-Backup'),
									'tooltip':() => { return (canUseFeature(utm.utmId,'cloudBackup').result === false && utmStatus.online) ? getTooltip(canUseFeature(utm.utmId,'cloudBackup').reasons) : T('Cloud-Backup') },
									'id':'cloudBackup',
									'icon': 'fal fa-cloud-upload-alt',
									'disabled':() => { return !canUseFeature(utm.utmId, 'cloudBackup').result },
									'onClick':() => { getBackupInfo() }
								},
								{
									'text':T('Operations') + ' Log',
									'tooltip':T('Operations') + ' Log',
									'id':'operationsLog',
									'icon': 'fal fa-list'
								},
								{
									'text':T('Applications'),
									'tooltip': () => { return (canUseFeature(utm.utmId,'applications').result === false && utmStatus.online) ? getTooltip(canUseFeature(utm.utmId,'applications').reasons) : T('Applications') },
									'id':'applications',
									'icon': 'fal fa-list',
									'disabled':() => { return (!utmStatus.online || !canUseFeature(utm.utmId,'applications').result) }
								},
								{
									'text':T('Status'),
									'tooltip':() => { return (canUseFeature(utm.utmId,'status').result === false && utmStatus.online) ? getTooltip(canUseFeature(utm.utmId,'status').reasons) : T('Status') },
									'id':'status',
									'icon': 'fal fa-rocket',
									'disabled': () => { return (!utmStatus.online || !canUseFeature(utm.utmId, 'status').result) },
									'onClick':() => { return !rrdLoaded ? getRrdInfo() : undefined }
								},
								{
									'text':T('Security Scan'),
									'tooltip':() => { return (canUseFeature(utm.utmId,'scans').result === false && utmStatus.online) ? getTooltip(canUseFeature(utm.utmId,'scans').reasons) : T('Security Scan') },
									'id':'securityScan',
									'icon': 'fal fa-eye',
									'disabled':() => { return (!utmStatus.online || !canUseFeature(utm.utmId,'scans').result) }
								},
								{
									'text':T('Inventory'),
									'tooltip':T('Inventory'),
									'id':'inventory',
									'icon': 'fal fa-info'
								},
								{
									'text':T('UTM-State'),
									'tooltip':T('UTM-State'),
									'id':'utmState',
									'icon': 'fal fa-project-diagram',
									'visible':() => { return $hasAccountRole('__SUPPORT__') }
								},
								{
									'text':T('Cloud Scheduler Log'),
									'tooltip':T('Cloud Scheduler Log'),
									'id':'jobs',
									'icon': 'fal fa-list',
									'visible':() => { return canUseUscJobs }
								}
							]"
						>
							<template v-slot:overview>
								<div class="row padding-xs-y">
									<div class="col-xs-24 col-lg-12">
										<small>${T('Last update:')} <span>{{ getUtmMessageData(utm, 'spcli-system-info') ? getDate(getUtmMessageData(utm, 'spcli-system-info')?.timestamp,true) : T('Unknown') }}</span></small>
									</div>
									<div class="col-xs-24 col-lg-12 text-right">
										<buttonComponent
											id="refreshUtmInfo"
											:buttonOptions="{
												loading: deviceActionStatus('system info').loading,
												disabled: deviceActionStatus('system info').disabled || deviceActionStatus('system poweroff').rebooting || !utmStatus.online || null,
												title: T('Refresh'),
												text: undefined,
												icon: 'fal fa-sync',
												onClick: () => { execDeviceAction(utm,'system info');execDeviceAction(utm,'interface address get') },
											}"
										></buttonComponent>
									</div>
								</div>
								<div class="row">
									<div class="col-xs-24 col-lg-12">

									<h4>{{ T('Memory info') }}</h4>
									<template v-if="getUtmMessageData(utm, 'spcli-system-info') && getUtmMessageData(utm, 'spcli-system-info').memory">
										<div
											class="progress-bar progress-bar-sm color-red"
											:data-progress="'0'"
											:class="{
												'color-red':getMemoryPercentage(getUtmMessageData(utm, 'spcli-system-info').memory) >= 90,
												'color-yellow':getMemoryPercentage(getUtmMessageData(utm, 'spcli-system-info').memory) < 90 && getMemoryPercentage(getUtmMessageData(utm, 'spcli-system-info').memory) >= 70,
												'color-green':getMemoryPercentage(getUtmMessageData(utm, 'spcli-system-info').memory) < 70
											}"
										>
											<div
												style="position:absolute;border-bottom:1em solid; top:0;"
												:style="'width:'+getMemoryPercentage(getUtmMessageData(utm, 'spcli-system-info').memory) + '%'"
												:data-progress="'0'"
											>
											</div>
										</div>
										<span>
											{{T('Memory usage')}}:
											{{
												formatBytes(getUtmMessageData(utm, 'spcli-system-info').memory.total * 1024 - getUtmMessageData(utm, 'spcli-system-info').memory.available * 1024, 2).value +
												formatBytes(getUtmMessageData(utm, 'spcli-system-info').memory.total * 1024 - getUtmMessageData(utm, 'spcli-system-info').memory.available * 1024, 2).unit
											}}
											{{
												T('of')
											}}
											{{
												formatBytes(getUtmMessageData(utm, 'spcli-system-info').memory.total * 1024 , 2).value + this.formatBytes(getUtmMessageData(utm, 'spcli-system-info').memory.total * 1024 , 2).unit
											}}
										</span>
									</template>


									<h4>{{ T('CPU') }}</h4>
									<template v-if="getUtmMessageData(utm, 'spcli-system-info') && getUtmMessageData(utm, 'spcli-system-info').cpu && getUtmMessageData(utm, 'spcli-system-info').cpu.stats">
										<div class="progress-bar progress-bar-sm color-red" :data-progress="'0'">
											<div
												style="position:absolute;border-bottom:1em solid; top:0;"
												:style="'width:'+getCpuPercentage(getUtmMessageData(utm, 'spcli-system-info').cpu.stats)+'%'"
												:class="{
													'color-red':getCpuPercentage(getUtmMessageData(utm, 'spcli-system-info').cpu.stats) >= 90,
													'color-yellow':getCpuPercentage(getUtmMessageData(utm, 'spcli-system-info').cpu.stats) < 90 && getCpuPercentage(getUtmMessageData(utm, 'spcli-system-info').cpu.stats) >= 70,
													'color-green':getCpuPercentage(getUtmMessageData(utm, 'spcli-system-info').cpu.stats) < 70
												}"
											>
											</div>
										</div>
										<span>
											{{T('CPU load')}}: {{ getCpuPercentage(getUtmMessageData(utm, 'spcli-system-info').cpu.stats) }}%&nbsp;&nbsp;<small> ({{T('Nice')}}: {{ getUtmMessageData(utm, 'spcli-system-info').cpu.stats.nice }} | {{T('System')}}: {{ getUtmMessageData(utm, 'spcli-system-info').cpu.stats.system }} | {{T('User')}}: {{ getUtmMessageData(utm, 'spcli-system-info').cpu.stats.user }} | {{T('Other')}}: {{ 100 - parseInt(getUtmMessageData(utm, 'spcli-system-info').cpu.stats.idle || 0) - parseInt(getUtmMessageData(utm, 'spcli-system-info').cpu.stats.nice || 0) - parseInt(getUtmMessageData(utm, 'spcli-system-info').cpu.stats.system || 0) - parseInt(getUtmMessageData(utm, 'spcli-system-info').cpu.stats.user || 0) }}%)</small>
										</span>
									</template>

									<h4>{{ T('Storage') }}</h4>
									<template v-if="getUtmMessageData(utm, 'spcli-system-info') && getUtmMessageData(utm, 'spcli-system-info').storage">
										<div 
											class="progress-bar progress-bar-sm color-red" :data-progress="'0'"
											:class="{
												'color-red':getStoragePercentage(getUtmMessageData(utm, 'spcli-system-info').storage) >= 90,
												'color-yellow':getStoragePercentage(getUtmMessageData(utm, 'spcli-system-info').storage) < 90 && getStoragePercentage(getUtmMessageData(utm, 'spcli-system-info').storage) >= 70,
												'color-green':getStoragePercentage(getUtmMessageData(utm, 'spcli-system-info').storage) < 70
											}"
										>
											<div
												style="position:absolute;border-bottom:1em solid; top:0;"
												:style="'width:'+getStoragePercentage(getUtmMessageData(utm, 'spcli-system-info').storage) + '%'"
											>
											</div>
										</div>
										<span>
											{{T('Storage used')}}:
											{{
												formatBytes(getUtmMessageData(utm, 'spcli-system-info').storage.total - getUtmMessageData(utm, 'spcli-system-info').storage.available, 2).value +
												formatBytes(getUtmMessageData(utm, 'spcli-system-info').storage.total - getUtmMessageData(utm, 'spcli-system-info').storage.available, 2).unit
											}}
											{{
												T('of')
											}}
											{{
												formatBytes(getUtmMessageData(utm, 'spcli-system-info').storage.total, 2).value + formatBytes(getUtmMessageData(utm, 'spcli-system-info').storage.total, 2).unit
											}}
										</span>
									</template>


									<h4>{{ T('Upgrades') }}</h4>
									<table class="noborder border-h ">
										<tbody>
											<tr v-if="getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('rollback') != -1">
												<td class="title">
													<i class="fal fa-fw fa-sync"></i> ${T('Rollback')}
												</td>
												<td>
													{{ T('Rollback possible') }}
												</td>
												<td>
													<template v-if="utmStatus.online">
														<div :title="canUseFeature(utm.utmId, 'sendJobs').reasons">
															<a :class="{'btn btn-sm': true, 'disabled': !canUseFeature(utm.utmId,'sendJobs').result }" v-on:click="setActiveTab('operations')">{{ T('Go to operations') }}</a>
														</div>
													</template>
												</td>
											</tr>
											<tr v-if="getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('finalize') != -1">
												<td class="title">
													<i class="fal fa-fw fa-sync"></i> ${T('Finalize')}
												</td>
												<td>
													{{ T('Finalize possible') }}
												</td>
												<td>
													<template v-if="utmStatus.online">
														<div :title="canUseFeature(utm.utmId, 'sendJobs').reasons">
															<a :class="{'btn btn-sm': true, 'disabled': !canUseFeature(utm.utmId,'sendJobs').result }" v-on:click="setActiveTab('operations')">{{ T('Go to operations') }}</a>
														</div>
													</template>
												</td>
											</tr>
											<tr v-if="getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('confirm eula') != -1">
												<td class="title">
													<i class="fal fa-fw fa-sync"></i> ${T('Confirm eula')}
												</td>
												<td>
													{{ T('Eula needs to be confirmed') }}
												</td>
												<td>
													<template v-if="utmStatus.online">
														<div :title="canUseFeature(utm.utmId, 'sendJobs').reasons">
															<a :class="{'btn btn-sm': true, 'disabled': !canUseFeature(utm.utmId,'sendJobs').result }" v-on:click="setActiveTab('operations')">{{ T('Go to operations') }}</a>
														</div>
													</template>
												</td>
											</tr>
											<tr v-if="getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('confirm privacy') != -1">
												<td class="title">
													<i class="fal fa-fw fa-sync"></i> ${T('Confirm privacy')}
												</td>
												<td>
													{{ T('Privacy needs to be confirmed') }}
												</td>
												<td>
													<template v-if="utmStatus.online">
														<div :title="canUseFeature(utm.utmId, 'sendJobs').reasons">
															<a :class="{'btn btn-sm': true, 'disabled': !canUseFeature(utm.utmId,'sendJobs').result }" v-on:click="setActiveTab('operations')">{{ T('Go to operations') }}</a>
														</div>
													</template>
												</td>
											</tr>
											<template 
												v-if="getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('confirm eula') == -1 && 
												getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('confirm privacy') == -1"
											>
												<tr v-if="getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('dryrun') != -1">
													<td class="title">
														<i class="fal fa-fw fa-sync"></i> ${T('Dry run')}
													</td>
													<td>
														<span class="label padding-xs-y margin-xs-b">
															<i class="fal fa-fw fa-exclamation-triangle"></i>
															{{ T('The update to') + ' '+ getUtmMessageData(utm, 'merged-update-info').new +' ' + T('has been downloaded and can be activated') }}
														</span><br>
														{{ T('Dry run possible') }}

													</td>
													<td>
														<template v-if="utmStatus.online">
															<div :title="canUseFeature(utm.utmId, 'sendJobs').reasons">
																<a :class="{'btn btn-sm': true, 'disabled': !canUseFeature(utm.utmId,'sendJobs').result }" v-on:click="setActiveTab('operations')">{{ T('Go to operations') }}</a>
															</div>
														</template>
													</td>
												</tr>
											</template>
											<tr v-if="getUtmMessageData(utm, 'merged-update-info') && getUtmMessageData(utm, 'merged-update-info')?.timestamp">
												<td class="title">
													<i class="fal fa-fw fa-calendar-alt"></i> ${T('Last upgrade')}
												</td>
												<td>
													{{ getDate(getUtmMessageData(utm, 'merged-update-info')?.timestamp) || "" }}
												</td>
											</tr>
										</tbody>
									</table>
									<br>
									<a href="https://wiki.securepoint.de/UTM/Changelog" target="_blank">
										{{ T('Click here for infos about this firmware.') }}
									</a>
										<h4>${T('License')}</h4>
										<table class="noborder border-h margin-xs-0">
											<tbody>
												<tr>
													<td class="title"><i class="fal fa-fw fa-server"></i> ${T('Product')}</td>
													<td>
														{{ $getObjectProperty(getUtmMessageData(utm, 'spcli-system-info'),"license.product") || "" }}
													</td>
												</tr>
												<tr>
													<td class="title"><i class="fal fa-fw fa-key"></i> ${T('Licensed')}</td>
													<td>
														<i class="fal" :class="{ 'fa-check' : utm.licensed, 'fa-times' : !utm.licensed}"></i>
													</td>
												</tr>
												<tr>
													<td class="title"><i class="fal fa-fw fa-calendar-alt"></i> ${T('Expires on')}</td>
													<td>
														{{ getDate(utm.expirationDate) || ""  }}
													</td>
												</tr>
											</tbody>
										</table>
										<h5>{{ T('Organization') }}</h5>
										<table class="noborder border-h ">
											<tbody>
												<tr>
													<td class="title"><i class="fal fa-fw fa-thumbtack"></i> ${T('Name')}</td>
													<td>
														{{ $getObjectProperty(getUtmMessageData(utm, 'spcli-system-info'),"license.organisation.name") ? getUtmMessageData(utm, 'spcli-system-info').license.organisation.name.replace(/unknown/ig,'')  : ""  }}
													</td>
												</tr>
												<tr>
													<td class="title"><i class="fal fa-fw fa-thumbtack"></i> ${T('Country')}</td>
													<td>
														{{ $getObjectProperty(getUtmMessageData(utm, 'spcli-system-info'),"license.organisation.country") ? getUtmMessageData(utm, 'spcli-system-info').license.organisation.country.replace(/unknown/ig,'')  : ""  }}
													</td>
												</tr>
												<tr>
													<td class="title"><i class="fal fa-fw fa-thumbtack"></i> ${T('State')}</td>
													<td>
														{{ $getObjectProperty(getUtmMessageData(utm, 'spcli-system-info'),"license.organisation.state") ? getUtmMessageData(utm, 'spcli-system-info').license.organisation.state.replace(/unknown/ig,'')  : "" }}
													</td>
												</tr>
												<tr>
													<td class="title"><i class="fal fa-fw fa-thumbtack"></i> ${T('City')}</td>
													<td>
														{{ $getObjectProperty(getUtmMessageData(utm, 'spcli-system-info'),"license.organisation.city") ? getUtmMessageData(utm, 'spcli-system-info').license.organisation.city.replace(/unknown/ig,'')  : "" }}
													</td>
												</tr>
												<tr>
													<td class="title"><i class="fal fa-fw fa-sitemap"></i> ${T('Unit')}</td>
													<td>
														{{ typeof $getObjectProperty(getUtmMessageData(utm, 'spcli-system-info'),"license.organisation.unit") == 'string' ? (getUtmMessageData(utm, 'spcli-system-info').license.organisation.unit || "").replace(/unknown/ig,'')  : "" }}
													</td>
												</tr>
												<tr>
													<td class="title"><i class="fal fa-fw fa-user"></i> ${T('Contact')}</td>
													<td>
														{{ $getObjectProperty(getUtmMessageData(utm, 'spcli-system-info'),"license.organisation.contact") ? getUtmMessageData(utm, 'spcli-system-info').license.organisation.contact.replace(/unknown/ig,'')  : "" }}
													</td>
												</tr>
												<tr>
													<td class="title"><i class="fal fa-fw fa-user"></i> ${T('E-Mail')}</td>
													<td>
														{{ $getObjectProperty(getUtmMessageData(utm, 'spcli-system-info'),"license.organisation.email") ? getUtmMessageData(utm, 'spcli-system-info').license.organisation.email.replace(/unknown/ig,'')  : "" }}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div class="col-xs-24 col-lg-12" style="position:relative">
										<template v-if="utm.inventory && utm.inventory.inventoryLocation != undefined && !isObjectEmpty(utm.inventory.inventoryLocation)">
												<h3 class="float-xs-left">${T('Device location')}</h3>
												<a 
													v-on:click="getInventoryLocationDialog()" 
													class="btn padding-xs-y-0 float-xs-right" 
													:class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'" 
													:title="T('Set inventory device location')"
												><i class="fal fa-edit"></i></a>
										</template>
										<div class="clearfix"></div>
										<template 
												v-if="utm 
													&& utm.inventory 
													&& utm.inventory.inventoryLocation 
													&& utm.inventory.inventoryLocation.latitude 
													&& utm.inventory.inventoryLocation.longitude
												"
										>
											<googleMap
												id="inventoryMap"
												:entries="[{
														'position':{
															'lat':utm.inventory.inventoryLocation.latitude,
															'lng':utm.inventory.inventoryLocation.longitude
														},
														'infoHtml':getInfoBoxText() 
												}]"
											/>
										</template>

										<template v-if="isObjectEmpty(utm.inventory ? utm.inventory.inventoryLocation : {})">
											<div style="position:relative; min-height:250px;">
												<div :style="
														(darkmode == '1' ? 'background: rgba(0, 0, 0, 0.2);' : 'background: rgba(0, 0, 0, 0.08);')
														+ 'top: 0;'
														+ 'height:100%;'
														+ 'left:8px;'
														+ 'right:8px;'
														+ 'position: absolute;'
												">
														<div style="position:absolute;top:50%;transform: translate(0, -50%); width: 100%;" class="text-center">
															<h3 style="width: 100%;text-align: center;">
																	{{ T('Inventory location is not set') }}
															</h3>
															<p>{{ T('Click the following link to set the inventory location:') }} <br>
															<br><a class="btn" :title="T('Set inventory device location')" v-on:click="getInventoryLocationDialog()"> <i class="fal fa-fw fa-edit"></i> {{T('Set location')}}</a></p>
														</div>
												</div>
											</div>
										</template>

									</div>
								</div>
							</template>
							<template v-slot:cluster v-if="utm?.cluster?.isCluster">
								<div class="row">
									<table class="noborder border-h">
										<tbody>
											<tr>
												<td class="title">
													Cluster-UUID:
												</td>
												<td>
													{{ utm.license.clusterUuid }}
												</td>
											</tr>
										</tbody>
									</table>
									<table class="noborder border-h">
										<tbody>
											<tr>
												<td class="title">
													Status:
												</td>
												<td v-if="utmStatus.online">
													{{ getUtmMessageData(utm, "spcli-system-info")?.cluster?.state || "" }}
												</td>
												<td v-else>
													{{ T('The cluster member must be active for this information') }}
												</td>
											</tr>
											<tr>
												<td class="title">
													${T('Cluster version')}:
												</td>
												<td v-if="utmStatus.online">
													{{ getUtmMessageData(utm, "spcli-system-info")?.cluster?.version || "" }}
												</td>
												<td v-else>
													{{ T('The cluster member must be active for this information') }}
												</td>
											</tr>
											<tr>
												<td class="title">
													${T('Synchronisation status')}:
												</td>
												<td v-if="utmStatus.online">
													{{ getUtmMessageData(utm, "spcli-system-info")?.cluster?.sync || "" }}
												</td>
												<td v-else>
													{{ T('The cluster member must be active for this information') }}
												</td>
											</tr>
											<br>
											<tr>
												<td class="title">
													${T('License serial number')}:
												</td>
												<td>
													{{ getUtmMessageData(utm, "spcli-system-info")?.license?.serial || "" }}
												</td>
											</tr>
											<br>
											<tr>
												<td colspan="2">
													<a v-on:click="goToClusterUtm()"
													title="${T('Go to other cluster UTM')}">
														${T('Go to other cluster UTM')}
													</a>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</template>
							<template v-slot:operations>
								<div class="row padding-xs-y">
									<div class="col-xs-24 text-right">
										<a
											id="refreshOperations"
											v-on:click="getOperationsLogData"
											class="btn btn-primary btn-loader twist-in"
											:class="{'btn-loading':operationsLogTableData.loading, 'disabled': !utmStatus.online || operationsLogTableData.loading}"
											:disabled="!utmStatus.online || operationsLogTableData.loading || null"
										>
											<span class="animate">
												<loader
													v-if="deviceActionStatus('system info').loading"
													style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
												></loader>
											</span>
											<span><i class="fal fa-sync"></i></span>
										</a>
									</div>
								</div>




								<div class="row padding-xs-y">
									<div class="col-xs-2">
										<p style="margin-top:8px;">
											<strong>
												{{ T('Device') }}
											</strong>
										</p>
									</div>
									<div class="col-xs-22">
										<div class="row">
											<div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
												<buttonComponent
													class="col-xs-24"
													v-if="deviceActionStatus('system reboot').view"
													:buttonOptions="{
														loading: deviceActionStatus('system reboot').loading,
														disabled: deviceActionStatus('system reboot').disabled 
															|| deviceActionStatus('system poweroff').rebooting 
															|| !utmStatus.online
															|| !canUseFeature(utm.utmId,'sendJobs').result
															|| null,
														title: deviceActionStatus('system reboot').disabledReason || T('Reboot'),
														text: T('Reboot'),
														icon: 'fal fa-sync',
														onClick: () => { execDeviceAction(utm,'system reboot') },
														topRightCircle:{ icon:'fa fa-lock' }  
													}"
												></buttonComponent>
											</div>
											<div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
												<buttonComponent
													class="col-xs-24"
													v-if="deviceActionStatus('system poweroff').view"
													:buttonOptions="{
														loading: deviceActionStatus('system poweroff').loading,
														disabled: deviceActionStatus('system poweroff').disabled 
															|| deviceActionStatus('system poweroff').rebooting 
															|| !utmStatus.online
															|| !canUseFeature(utm.utmId,'sendJobs').result
															|| null,
														title: deviceActionStatus('system poweroff').disabledReason || T('Shut down'),
														text: T('Shut down'),
														icon: 'fal fa-power-off',
														onClick: () => { execDeviceAction(utm,'system poweroff') },
														topRightCircle:{ icon:'fa fa-lock' }  
													}"
												></buttonComponent>
											</div>
										</div>
									</div>
								</div>
								<hr>

								<div class="row padding-xs-y">
									<div class="col-xs-2">
										<p style="margin-top:8px;">
											<strong>
												{{ T('Firmware') }}
											</strong>
										</p>
									</div>
									<div class="col-xs-22">
										<template v-if="[0,-1].indexOf(versionCompare('12.3',$getObjectProperty(getUtmMessageData(utm, 'merged-update-info'),'cur'))) && hasLessThan3GBRam(utm)">
											<div class="row">
												<div class="col-xs-24 padding-xs-2">
													<p class="notification" :class="{'color-white':darkmode == '1'}">
														<i class="fal fa-exclamation-triangle fa-fw color-red"></i>&nbsp;&nbsp;{{ T('Attention: This UTM has less than 3GB of memory. If a update is performed, this UTM will no longer have a virus scanner.') }}
													</p>
												</div>
											</div>
										</template>
										<div class="row">
											
											<div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
												<buttonComponent
													class="col-xs-24"
													v-if="deviceActionStatus('system config factorysettings').view"
													:buttonOptions="{
														loading: deviceActionStatus('system config factorysettings').loading,
														disabled: deviceActionStatus('system config factorysettings').disabled 
															|| deviceActionStatus('system config factorysettings').rebooting 
															|| !utmStatus.online
															|| !canUseFeature(utm.utmId,'sendJobs').result
															|| null,
														title: deviceActionStatus('system config factorysettings').disabledReason || T('Reset factory settings'),
														text: T('Reset factory settings'),
														icon: 'fal fa-undo',
														onClick: () => { execDeviceAction(utm,'system config factorysettings') },
														topRightCircle:{ icon:'fa fa-lock' }  
													}"
												></buttonComponent>
											</div>
											<template v-if="getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('rollback') != -1">
												<div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
													<buttonComponent
														class="col-xs-24"
														v-if="deviceActionStatus('system upgrade rollback').view"
														:buttonOptions="{
															loading: deviceActionStatus('system upgrade rollback').loading,
															disabled: deviceActionStatus('system upgrade rollback').disabled 
																|| deviceActionStatus('system upgrade rollback').rebooting 
																|| !utmStatus.online
																|| !canUseFeature(utm.utmId,'sendJobs').result
																|| null,
															title: shouldDisplayLock && deviceActionStatus('system upgrade rollback').disabled ? deviceActionStatus('system upgrade rollback').disabledReason : T('Rollback to version') + ': ' + getUtmMessageData(utm, 'merged-update-info').old,
															text: T('Rollback to version') + ': ' + getUtmMessageData(utm, 'merged-update-info').old,
															icon: 'fal fa-sync',
															onClick: () => { execDeviceAction(utm,'system upgrade rollback') },
															topRightCircle: shouldDisplayLock ? { icon:'fa fa-lock' } : undefined
														}"
													></buttonComponent>
												</div>
											</template>
											
											<template 
												v-if="getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('dryrun') != -1 &&
												getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('confirm eula') == -1 &&
												getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('confirm privacy') == -1"
											>
												<div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
													<buttonComponent
														class="col-xs-24"
														v-if="deviceActionStatus('system upgrade dryrun').view"
														:buttonOptions="{
															loading: deviceActionStatus('system upgrade dryrun').loading,
															disabled: deviceActionStatus('system upgrade dryrun').disabled 
																|| deviceActionStatus('system upgrade dryrun').rebooting 
																|| !utmStatus.online
																|| !canUseFeature(utm.utmId,'sendJobs').result
																|| null,
															title: shouldDisplayLock && deviceActionStatus('system upgrade dryrun').disabled ? deviceActionStatus('system upgrade dryrun').disabledReason : T('Dryrun the new Version') + ': ' + getUtmMessageData(utm, 'merged-update-info').new,
															text: T('Dryrun the new Version') + ': ' + getUtmMessageData(utm, 'merged-update-info').new,
															icon: 'fal fa-sync',
															onClick: () => { execDeviceAction(utm,'system upgrade dryrun') },
															topRightCircle: shouldDisplayLock ? { icon:'fa fa-lock' } : undefined
														}"
													></buttonComponent>
												</div>
											</template>
											<template v-if="getUtmMessageData(utm, 'merged-update-info') && getUtmMessageData(utm, 'spcli-system-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('finalize') != -1">
												<div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
													<buttonComponent
														class="col-xs-24"
														v-if="deviceActionStatus('system upgrade finalize').view"
														:buttonOptions="{
															loading: deviceActionStatus('system upgrade finalize').loading,
															disabled: deviceActionStatus('system upgrade finalize').disabled 
																|| deviceActionStatus('system upgrade finalize').rebooting 
																|| !utmStatus.online
																|| !canUseFeature(utm.utmId,'sendJobs').result
																|| null,
															title: shouldDisplayLock && deviceActionStatus('system upgrade finalize').disabled ? deviceActionStatus('system upgrade finalize').disabledReason : T('Finalize the new Version') + ': ' + getUtmMessageData(utm, 'spcli-system-info').version,
															text: T('Finalize the new Version') + ': ' + getUtmMessageData(utm, 'spcli-system-info').version,
															icon: 'fal fa-sync',
															onClick: () => { execDeviceAction(utm,'system upgrade finalize') },
															topRightCircle: shouldDisplayLock ? { icon:'fa fa-lock' } : undefined
														}"
													></buttonComponent>


												</div>
											</template>
											<template v-if="getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('confirm eula') != -1">
												<div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
													<buttonComponent
														class="col-xs-24"
														v-if="deviceActionStatus('system upgrade confirm eula').view"
														:buttonOptions="{
															loading: deviceActionStatus('system upgrade confirm eula').loading,
															disabled: deviceActionStatus('system upgrade confirm eula').disabled 
																|| deviceActionStatus('system upgrade confirm eula').rebooting 
																|| !utmStatus.online
																|| !canUseFeature(utm.utmId,'sendJobs').result
																|| null,
															title: shouldDisplayLock && deviceActionStatus('system upgrade confirm eula').disabled ? deviceActionStatus('system upgrade confirm eula').disabledReason : T('Accept eula'),
															text: T('Accept eula'),
															icon: 'fal fa-check',
															onClick: () => { execDeviceAction(utm,'system upgrade confirm eula') },
															topRightCircle: shouldDisplayLock ? { icon:'fa fa-lock' } : undefined
														}"
													></buttonComponent>
												</div>
											</template>
											<template v-if="getUtmMessageData(utm, 'merged-update-info') && (getUtmMessageData(utm, 'merged-update-info').hint || '').indexOf('confirm privacy') != -1">
												<div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
													<buttonComponent
														class="col-xs-24"
														v-if="deviceActionStatus('system upgrade confirm privacy').view"
														:buttonOptions="{
															loading: deviceActionStatus('system upgrade confirm privacy').loading,
															disabled: deviceActionStatus('system upgrade confirm privacy').disabled 
																|| deviceActionStatus('system upgrade confirm privacy').rebooting 
																|| !utmStatus.online
																|| !canUseFeature(utm.utmId,'sendJobs').result
																|| null,
															title: shouldDisplayLock && deviceActionStatus('system upgrade confirm privacy').disabled ? deviceActionStatus('system upgrade confirm privacy').disabledReason : T('Accept privacy'),
															text: T('Accept privacy'),
															icon: 'fal fa-check',
															onClick: () => { execDeviceAction(utm,'system upgrade confirm privacy') },
															topRightCircle: shouldDisplayLock ? { icon:'fa fa-lock' } : undefined
														}"
													></buttonComponent>
												</div>
											</template>



											<div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
												<buttonComponent class="col-xs-24" :button-options="{
													'icon': 'fal fa-play',
													'text': T('Schedule a one-time update'),
													'onClick': getPlanSingleUpdateDialog
												}"></buttonComponent>
											</div>


											<div class="col-xs-24 padding-xs-x-2">
												<p>
													<a href="https://wiki.securepoint.de/UTM/Changelog" target="_blank">
														{{ T('Click here for infos about this firmware.') }}
													</a>
												</p>
											</div>
										</div>
										
									</div>
								</div>
							</template>
							<template v-slot:websession>
								<div class="row">
									<div class="padding-xs-t" style="flex-wrap:wrap">
										<template v-if="!canUseWebsession">
											<p class="notification bg-red color-white" style="display:inline-block;">
												{{ T("Invalid scopes. Needed some of:") + T("Full administration") + ", " + T("Monitoring access") + "." }}<br>
												{{ T("Scopes can be edited in roles by the administrator.") }}
											</p>
										</template>
										<p>
											{{T('Here you can open the administrative web interface of the UTM. If the UTM is accessible via internet connection, no user credentials are required.')}}
											<br>
											{{T('When the websession is started, an additional pop-up window opens. Please note that pop-up blockers may prevent the window from opening.')}}
										</p>
										<p>
											<template v-if="canUseWebsession">
												<buttonComponent
													:buttonOptions="{
														loading: false,
														disabled: false,
														title: T('Start new websession'),
														text: T('Start new websession'),
														icon: 'fal fa-laptop',
														onClick: getWebsessionDialog,
														topRightCircle:{ icon:'fa fa-lock' }
													}"
												></buttonComponent>
											</template>
											<template v-else>
												<a class="btn btn-primary" disabled="true">
													<i class="fal fa-fw fa-exclamation-triangle"></i> ${T('Missing permission scopes')}
												</a>
											</template>
										</p>
									</div>
								</div>
							</template>
							<template v-slot:cloudBackup>
								<div>
									<div class="text-right padding-xs-y">
										<a
											id="refreshBackups"
											v-on:click="getBackupInfo"
											class="btn btn-primary btn-loader twist-in"
											:class="{'btn-loading disabled':backupsTableData.loading, }"
											:disabled="backupsTableData.loading || null"
										>
											<span class="animate">
												<loader
													v-if="backupsTableData.loading"
													style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
												></loader>
											</span>
											<span><i class="fal fa-sync"></i></span>
										</a>
									</div>
									<div style="flex-wrap:wrap">
										<template v-if="utmStatus.online">
											<template v-if="utmVars.GLOB_CLOUDBACKUP_TIME != undefined">
												<template v-if="utmVars.GLOB_CLOUDBACKUP_TIME == 0 || !utmVars.GLOB_CLOUDBACKUP_TIME">
													<p>
														{{ T('Cloud backup is disabled for this UTM') }}
													</p>
												</template>
												<template v-else>
													<p v-if="utmVars.GLOB_CLOUDBACKUP_TIME">
														{{T("The automatic backup is scheduled daily at")}} {{ utmVars.GLOB_CLOUDBACKUP_TIME.split(' ')[1] }}:{{ utmVars.GLOB_CLOUDBACKUP_TIME.split(' ')[0] }} {{ T("Xo'clock") }}.
													</p>
												</template>
											</template>
											<template v-else>
												<loader
													style="font-size: 1.5em"
												></loader>
											</template>
											<hr>
										</template>
										<template v-if="utmStatus.online">
											<h4>${T('Operations')}</h4>
											<p>
												<template v-if="!utmVars.hasOwnProperty('passwordSet')">
													<loader
														style="font-size: 1.5em"
													></loader>
												</template>
												<template v-if="utmVars.GLOB_CLOUDBACKUP_TIME != undefined && utmVars.GLOB_CLOUDBACKUP_TIME != 0 && utmVars.hasOwnProperty('passwordSet')">
													<a
														id="disableCloudBackup"
														v-on:click="disableCloudBackup"
														class="btn btn-loader twist-in"
														:class="{'btn-loading':backupsTableData.loading}"
													>
														<span class="animate">
															<loader
																v-if="backupsTableData.loading"
																style="font-size: 1.5em;top: calc(50% - 0.375em);position: absolute;left: calc(50% - 0.375em);"
															></loader>
														</span>
														<span>
															<i class="fal fa-power-off"></i> {{ T('Disable cloud-backup') }}
														</span>
													</a>
												</template>
												<template v-if="utmVars.hasOwnProperty('passwordSet') && utmVars.GLOB_CLOUDBACKUP_TIME != undefined && utmVars.GLOB_CLOUDBACKUP_TIME == 0">
													<a
														id="enableCloudBackup"
														v-on:click="enableCloudBackup"
														class="btn btn-loader twist-in"
														:class="{'btn-loading':backupsTableData.loading}"
													>
														<span class="animate">
															<loader
																v-if="backupsTableData.loading"
																style="font-size: 1.5em;top: calc(50% - 0.375em);position: absolute;left: calc(50% - 0.375em);"
															></loader>
														</span>
														<span>
															<i class="fal fa-power-on"></i> {{ T('Enable cloud-backup') }}
														</span>
													</a>
												</template>
												<template v-if="utmVars.hasOwnProperty('passwordSet')">
													<a
														id="setBackupPassword"
														v-on:click="() => { renderSetCloudBackupPassword(false) }"
														class="btn btn-loader twist-in"
														:class="{'btn-loading':backupsTableData.loading}"
													>
														<span class="animate">
															<loader
																v-if="backupsTableData.loading"
																style="font-size: 1.5em;top: calc(50% - 0.375em);position: absolute;left: calc(50% - 0.375em);"
															></loader>
														</span>
														<span>
															<i class="fal fa-key"></i> {{ T('Set new password') }}
														</span>
													</a>
												</template>
												<template v-if="utmVars.passwordSet == true">
													<a
														v-on:click="makeBackup"
														class="btn btn-loader twist-in"
														:class="{'btn-loading':makingBackUp}"
													>
														<span class="animate">
															<loader
															v-if="makingBackUp"
															style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
															></loader>
														</span>
														<span><i class="fal fa-plus"></i> {{ T('Create a new backup') }}</span>
													</a>
												</template>
											</p>
										</template>
										<table2
											ref="backupsTable"
											class="backupsTable"
											:table="backupsTableData.tableData"
											:loading="backupsTableData.loading"
											:minheight="0"
										></table2>
									</div>
								</div>
							</template>
							<template v-slot:operationsLog>
								<div class="text-right padding-xs-y">
									<buttonComponent
										id="refreshOperations"
										:buttonOptions="{
											loading: deviceActionStatus('system info').loading || operationsLogTableData.loading,
											disabled: deviceActionStatus('system info').disabled || deviceActionStatus('system poweroff').rebooting || !utmStatus.online || null,
											title: T('Refresh'),
											text: undefined,
											icon: 'fal fa-sync',
											onClick: getOperationsLogData,
										}"
									></buttonComponent>
								</div>

								<tableNext 
									:selectable-columns="operationsLogTableData.operationsLogSelectableColumns || []"
									:object-list="mergedOperationsLog"
									:initial-sort-property="'timestamp'"
									:initial-sort-direction="'DESC'"
									:has-options="false"
									:is-filterable="false"
									:isLoading="operationsLogTableData.loading"
								></tableNext>
							</template>
							<template v-slot:applications>
								<div class="text-right padding-xs-y">
									<a
										id="refreshOperations"
										v-on:click="execDeviceAction(utm,'appmgmt status')"
										class="btn btn-primary btn-loader twist-in"
										:class="{
											'btn-loading':deviceActionStatus('appmgmt status').loading,
											'disabled': !utmStatus.online || deviceActionStatus('appmgmt status').loading
										}"
										:disabled="!utmStatus.online || deviceActionStatus('appmgmt status').loading || null"
									>
										<span class="animate">
											<loader
												v-if="deviceActionStatus('appmgmt status').loading"
												style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
											></loader>
										</span>
										<span><i class="fal fa-sync"></i></span>
									</a>
								</div>
								<table2
									ref="applicationsTable"
									class="applicationsTable"
									:loading="applicationsTableData.loading"
									:table="applicationsTableData.tableData"
									:noEntriesText="'No applications loaded yet. Please click refresh.'"
								></table2>
								
							</template>
							<template v-slot:status>
								<div class="row padding-xs-y">
									<div class="col-xs-24 col-md-12">
										<small>${T('Last update:')} <span> {{ rrdInfo.timestamp ? getDate(rrdInfo.timestamp,true) : T('Unknown') }}</span></small>
									</div>
									<div class="col-xs-24 col-md-12 text-right">
										<a
											id="refreshUtmInfo"
											v-if="deviceActionStatus('system info').view"
											:disabled="deviceActionStatus('system info').disabled || deviceActionStatus('system poweroff').rebooting || !utmStatus.online || null"
											v-on:click="getRrdInfo()"
											class="btn btn-primary btn-loader twist-in"
											:class="{'btn-loading': deviceActionStatus('system info').loading, 'disabled':!utmStatus.online}"
										>
											<span class="animate">
												<loader
													v-if="deviceActionStatus('system info').loading"
													style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
												></loader>
											</span>
											<span><i class="fal fa-sync"></i></span>
										</a>
									</div>
								</div>
								<div class="row flexrow padding-xs-t" style="flex-wrap:wrap">
									<div class="col-xs-24 col-md-12 padding-xs">
										<div class="box-shadow padding-xs" style="min-height:302px">
											<h4>{{ T('CPU-0')}}</h4><hr class="margin-xs-b">
											<div id="cpu0Chart" style="margin: 0 auto;"></div>
											<template v-if="cpu0Chart === undefined">
												<p class="text-size-4 text-center color-red padding-xs-y-8">
													<loader />
												</p>
											</template>
										</div>
									</div>
									<div class="col-xs-24 col-md-12 padding-xs">
										<div class="box-shadow padding-xs" style="min-height:302px">
											<h4>{{ T('Load')}}</h4><hr class="margin-xs-b">
											<div id="loadChart" style="margin: 0 auto;"></div>
											<template v-if="loadChart === undefined">
												<p class="text-size-4 text-center color-red padding-xs-y-8">
													<loader />
												</p>
											</template>
										</div>
									</div>
									<div class="col-xs-24 col-md-12 padding-xs">
										<div class="box-shadow padding-xs" style="min-height:302px">
											<h4>{{ T('Memory')}}</h4><hr class="margin-xs-b">
											<div id="memoryChart" style="margin: 0 auto;"></div>
											<template v-if="memoryChart === undefined">
												<p class="text-size-4 text-center color-red padding-xs-y-8">
													<loader />
												</p>
											</template>
										</div>
									</div>
									<div class="col-xs-24 col-md-12 padding-xs">
										<div class="box-shadow padding-xs" style="min-height:302px">
											<h4>{{ T('Data Disk')}}</h4><hr class="margin-xs-b">
											<div id="dfDataChart" style="margin: 0 auto;"></div>
											<template v-if="dfDataChart === undefined">
												<p class="text-size-4 text-center color-red padding-xs-y-8">
													<loader />
												</p>
											</template>
										</div>
									</div>
								</div>
							</template>
							<template v-slot:securityScan>
								<div class="row padding-xs-y">
									<div class="text-right col-lg-24">
										<a
											id="startNewScan"
											v-on:click="startNewScan"
											class="btn btn-primary btn-loader twist-in text-right"
											:class="{'btn-loading':scansLoading}"
										>
											<span><i class="fal fa-eye"></i> {{T('Start new scan')}}</span>
										</a>
										<a
											id="refreshScans"
											v-on:click="getScansData"
											class="btn btn-primary btn-loader twist-in"
											:class="{'btn-loading':scansLoading, 'disabled': !utmStatus.online}"
											:disabled="!utmStatus.online || null"
										>
											<span class="animate">
												<loader
													v-if="deviceActionStatus('system info').loading"
													style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
												></loader>
											</span>
											<span><i class="fal fa-sync"></i></span>
										</a>
									</div>
								</div>
								<hr class="margin-xs-y-2">
								<div class="row">
									<div class="col-xs-24">
										<table2
											ref="scansTable"
											class="scansTable"
											:table="scansTable"
											:loading="scansLoading"
										/>
									</div>
								</div>
							</template>
							<template v-slot:inventory>
								<div class="row">
									<div class="col-xs-24">
										<div class="row">
											<div class="col-xs-24 padding-xs-x">
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="billNumber">
															${T('Bill number')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input id="billNumber" type="text" class="form-control user-selection" placeholder="${T('Bill number')}" v-model="inventoryForm.billNumber" data-validation-property="/billNumber">
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Bill number')}
														</p>
													</div>
												</div>
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="billDate">
															${T('Bill date')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input-date
																v-model="inventoryForm.billDate"
																:value="inventoryForm.billDate"
																:placeholder="T('Select a date')"
																id="billDate"
															/>
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Bill date')}
														</p>
													</div>
												</div>
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="warrantyStartDate">
															${T('Warranty starts on')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input-date
																v-model="inventoryForm.warrantyStartDate"
																:value="inventoryForm.warrantyStartDate"
																:placeholder="T('Select a date')"
																id="warrantyStartDate"
															/>
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Warranty start')}
														</p>
													</div>
												</div>
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="warrantyEndDate">
															${T('Warranty ends on')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input-date
																v-model="inventoryForm.warrantyEndDate"
																:value="inventoryForm.warrantyEndDate"
																:placeholder="T('Select a date')"
																id="warrantyEndDate"
															/>
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Warranty end')}
														</p>
													</div>
												</div>
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="inventoryExternalLink">
															${T('External inventory link')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input id="inventoryExternalLink" type="text" class="form-control user-selection" placeholder="${T('URL')}" v-model="inventoryForm.inventoryExternalLink" data-validation-property="/inventoryExternalLink">
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Set link to inventory software')}
														</p>
													</div>
												</div>
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="hardwareDescription">
															${T('Description')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<textarea id="hardwareDescription" class="form-control user-selection" placeholder="${T('Description')}" v-model="inventoryForm.hardwareDescription" data-validation-property="/hardwareDescription"></textarea>
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Device description')}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-xs-24 padding-xs-t-4">
										<div class="row">
											<div class="col-xs-24">
												<h4>${T('Contract')}</h4>
												<span></span>
											</div>
										</div>
									</div>
									<hr class="margin-xs-y-2">
									<div class="col-xs-24">
										<div class="row">
											<div class="col-xs-24 padding-xs-x">
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="providerName">
															${T('Provider')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input id="providerName" type="text" class="form-control user-selection" placeholder="${T('Provider')}" v-model="inventoryForm.providerName" data-validation-property="/providerName">
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('The name of the provider')}
														</p>
													</div>
												</div>
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="providerTariff">
															${T('Tariff')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input id="providerTariff" type="text" class="form-control user-selection" placeholder="${T('Tariff')}" v-model="inventoryForm.providerTariff" data-validation-property="/providerTariff">
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Tariff')}
														</p>
													</div>
												</div>
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="providerCustomerNumber">
															${T('Customer number')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input id="providerCustomerNumber" type="text" class="form-control user-selection" placeholder="${T('Customer number')}" v-model="inventoryForm.providerCustomerNumber" data-validation-property="/providerCustomerNumber">
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Customer number')}
														</p>
													</div>
												</div>
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="contractNumber">
															${T('Contract number')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input id="contractNumber" type="text" class="form-control user-selection" placeholder="${T('Contract number')}" v-model="inventoryForm.contractNumber" data-validation-property="/contractNumber">
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Contract number')}
														</p>
													</div>
												</div>
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="contractDate">
															${T('Start of contract')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input-date
																v-model="inventoryForm.contractDate"
																:value="inventoryForm.contractDate"
																:placeholder="T('Select a date')"
																id="contractDate"
															/>
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Start of contract')}
														</p>
													</div>
												</div>
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="contractDuration">
															${T('Contract term')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input-number id="contractDuration" :min="0" class="form-control user-selection" placeholder="${T('Months')}" v-model="inventoryForm.contractDuration" data-validation-property="/contractDuration"></input-number>
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Contract term')} (${T('In months')})
														</p>
													</div>
												</div>
												<div class="row form-group validation-base border-bottom padding-xs-y">
													<div class="first col-xs-24 col-lg-6 col-xl-5">
														<label class="control-label" for="handoverDate">
															${T('Handover date')}
														</label>
													</div>
													<div class="input col-xs-24 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0">
															<input-date
																v-model="inventoryForm.handoverDate"
																:value="inventoryForm.handoverDate"
																:placeholder="T('Select a date')"
																id="handoverDate"
															/>
														</label>
													</div>
													<div class="desc col-xs-24 col-lg-10 col-xl-12">
														<p class="input-description">
															${T('Handover date')}
														</p>
													</div>
												</div>
												<button
													class="btn margin-xs-b-0 margin-xs-t-2 btn-loader twist-in float-xs-right"
													v-on:click="saveInventory()"
													:class="{'btn-loading' : savingInventory == true }"
													:disabled="savingInventory == true || null"
													id="saveButton"
													title="Save"
													type="submit"
												>
													<span class="animate">
														<loader
															v-if="savingInventory == true"
															style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
														></loader>
													</span>
													<span><i class="fal fa-save"></i> <span class="display-xs-none display-lg-inline">${T('Save')}</span></span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</template>
							<template v-slot:utmState v-if="$hasAccountRole('__SUPPORT__')">
								<div class="row">
									<div class="col-xs-24 padding-xs">
										<div class="box-shadow">
											<div class="box-body">
												<div class="padding-xs-t padding-xs-l">
													<div class="toolbar flexrow">
														<div class="col-xs flex-shrink" style="margin-bottom:8px; margin-right:8px;" title="${T('Period')}">
															<label class="form-field margin-xs-b-0">
																<span class="form-icon-prefix"><i class="fal fa-clock"></i></span>
																<select id="tenant-report-dashboard-period" v-model="utmVars.state.period" class="form-control">
																	<option value="today">${T('Today')}</option>
																	<option value="lastday">${T('Yesterday')}</option>
																	<option value="currentweek">${T('Current week')}</option>
																	<option value="currentmonth">${T('Current month')}</option>
																	<option value="last30days">${T('Last 30 days')}</option>
																</select>
															</label>
														</div>
														<div class="col-xs flex-grow" style="margin-bottom:8px; margin-right:8px; text-align:right">
															<a
																id="refreshUtmState"
																v-on:click="getUtmState(true)"
																class="btn btn-primary btn-loader twist-in"
																:class="{'btn-loading':utmVars.state.loading, 'disabled': !utmStatus.online || utmVars.state.loading}"
																:disabled="!utmStatus.online || utmVars.state.loading || null"
															>
																<span class="animate">
																	<loader
																		v-if="utmVars.state.loading"
																		style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
																	></loader>
																</span>
																<span><i class="fal fa-sync"></i></span>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
										
									</div>
								</div>

								<div class="row">
									<div class="col-xs-24 col-md-12 padding-xs">
										<div class="box-shadow">
											<div class="box-body">
												<div class="box-icon icon-center">
													<div class="icon-circle icon-circle bg-yellow">
														<i class="fal fa-ban"></i>
													</div>
												</div>
												<div class="box-content-2 text-center">
													<p class="text-size-3" v-if="utmVars.state.totalRejects.value != undefined">
														{{ utmVars.state.totalRejects.value }}
													</p>
													<p v-else class="text-size-3 color-red"><loader></loader></p>
												</div>
												<hr>
												<div class="text-center text-bold padding-xs-y-2">
													{{ T('Total Rejects') }}
												</div>
											</div>
										</div>
									</div>
									<div class="col-xs-24 col-md-12 padding-xs">
										<div class="box-shadow">
											<div class="box-body">
												<div class="box-icon icon-center">
													<div class="icon-circle icon-circle" :class="{
														'bg-red':utmStatus.online === false,
														'bg-green':utmStatus.online === true,
														'bg-yellow':utmStatus == undefined
													}">
														<i class="fal fa-power-off"></i>
													</div>
												</div>
												<div class="box-content-2 text-center">
													<p class="text-size-3">
														<template v-if="utmStatus.online === false">
															{{ T('Disconnected') }}
														</template>
														<template v-if="utmStatus.online === true">
															{{ T('Connected') }}
														</template>
														<template v-if="utmStatus == undefined">
															{{ T('Status unknown') }}
														</template>
													</p>
												</div>
												<hr>
												<div class="text-center text-bold padding-xs-y-2">
													{{ T('Current connection status') }}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-xs-24 padding-xs">
										<div class="box-shadow">
											<div class="box-body">
												<div class="box-content">
													<h3><i class="fa-fw fal fa-chart-area"></i> {{T('Rejected connections')}}</h3>
												</div>
												<hr class="margin-xs-0">
												<div class="box-content-2">
													<div id="utm-rejects-histogramm-container" style="min-height:200px" v-if="!utmVars.state.rejectsHistogramm.InfoText">
														<canvas id="utm-rejects-histogramm"></canvas>
													</div>
													<div style="line-height:200px; font-size:32px; text-align:center" v-else>
														{{ T(utmVars.state.rejectsHistogramm.InfoText) }}
													</div>
													<div v-if="utmVars.state.rejectsHistogramm.loading" class="overlay" style="background:rgba(255,255,255,0.8)">
														<loader style="stroke:#E74C3C;font-size: 48px; position:absolute; top:50%;left:50%; transform:translate(-50%,-50%);"></loader>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								
								<div class="row">
									<div class="col-xs-24 padding-xs">
										<div class="box-shadow">
											<div class="box-body">
												<div class="box-content">
													<h3><i class="fa-fw fal fa-chart-area"></i> {{T('Latest rejects')}}</h3>
												</div>
												<hr class="margin-xs-0">
												<div class="box-content-2">
													<table2
														:table="utmVars.state.latestRejects.tableData"
														:scrollbar="true"
														:maxheight="500"
														:loading="utmVars.state.latestRejects.loading"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
							<template v-slot:jobs>
								<div class="row padding-xs-y">
									<div class="col-xs-24 text-right">
										<a
											id="refreshOperations"
											v-on:click="getCronoJobsList"
											class="btn btn-primary btn-loader twist-in"
											:class="{'btn-loading':gettingHistory }"
											:disabled="!utmStatus.online || operationsLogTableData.loading || null"
										>
											<span class="animate">
												<loader
													v-if="deviceActionStatus('system info').loading"
													style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
												></loader>
											</span>
											<span><i class="fal fa-sync"></i></span>
										</a>
									</div>
								</div>
								<cronojoblist ref="cronojoblist"/>
							</template>
						</tabs>

					</div>
				</div>
			</div>
			<div class="padding-xs-y-4"></div>
		</section>
	`,
	data(this:any) {
		const thisComponent = this
		return {
			"initialized": false,
			"loadingCluster": false,
			"tabChanged": false,
			"loaderText": "Loading...",
			"gettingHistory":false,
			"rrdLoaded": false,
			"editAlias": false,
			"rrdInfo": {
				"cpu0Info": undefined,
				"loadInfo": undefined,
				"memoryInfo": undefined,
				"dfDataInfo": undefined,
				"timestamp": 0
			},
			"cpu0Chart": undefined,
			"loadChart": undefined,
			"memoryChart": undefined,
			"dfDataChart": undefined,
			"activeTab": "overview",
			"refreshCount": 0,
			"refreshCountDetails": 0,
			"applicationsTableData": {
				"loading": true,
				"tableData": {
					"rows": [],
					"titles": []
				}
			},
			"applicationNames": {
				'bird': {
					"en": 'Routing Server',
					"de": 'Routing Server'
				},
				'bird6': {
					"en": 'Routing Server IPv6',
					"de": 'Routing Server IPv6'
				},
				'charon': {
					"en": 'IPSec',
					"de": 'IPSec'
				},
				'checkrcpt': {
					"en": 'SMTP RCPT Check',
					"de": 'SMTP RCPT Check'
				},
				'clamd': {
					"en": 'ClamAV Virusscanner',
					"de": 'ClamAV Virenscanner'
				},
				'collectd': {
					"en": 'Statistics',
					"de": 'Statistiken'
				},
				'csamd': {
					"en": 'Cyren Virusscanner',
					"de": 'Cyren Virenscanner'
				},
				'ctasd': {
					"en": 'Spamfilter',
					"de": 'Spamfilter'
				},
				'cvpn': {
					"en": 'Clientless VPN',
					"de": 'Clientless VPN'
				},
				'dhcpd': {
					"en": 'DHCP-Server',
					"de": 'DHCP-Server'
				},
				'dhcprelay': {
					"en": 'DHCP-Relay',
					"de": 'DHCP-Relay'
				},
				'dovecot': {
					"en": 'IMAP Daemon',
					"de": 'IMAP Daemon'
				},
				'fetchmail': {
					"en": 'Mail-Connector',
					"de": 'Mail-Connector'
				},
				'postfix-policy-greylist': {
					"en": 'Greylisting Filter',
					"de": 'Greylisting Filter'
				},
				'postfix-policy-spf': {
					"en": "SPF Filter",
					"de": "SPF Filter"
				},
				'http_proxy': {
					"en": 'HTTP-Proxy',
					"de": 'HTTP-Proxy'
				},
				'ipsec': {
					"en": 'IPSEC',
					"de": 'IPSEC'
				},
				'ipsec_starter': {
					"en": 'IPSec',
					"de": 'IPSec'
				},
				'kryptochef': {
					"en": 'ACME',
					"de": 'ACME'
				},
				'l2tpd': {
					"en": 'L2TP VPN',
					"de": 'L2TP VPN'
				},
				'mailfilter': {
					"en": 'Mailfilter',
					"de": 'Mailfilter'
				},
				'named': {
					"en": 'Nameserver',
					"de": 'Nameserver'
				},
				'ntpd': {
					"en": 'NTP-Server',
					"de": 'NTP-Server'
				},
				'openvpn': {
					"en": 'SSL-VPN',
					"de": 'SSL-VPN'
				},
				'pop3_proxy': {
					"en": 'POP3-Proxy',
					"de": 'POP3-Proxy'
				},
				'rproxy': {
					"en": 'Reverse-Proxy',
					"de": 'Reverse-Proxy'
				},
				'smtpd': {
					"en": 'Mailrelay',
					"de": 'Mailrelay'
				},
				'snmpd': {
					"en": 'SNMP-Server',
					"de": 'SNMP-Server'
				},
				'spalertd': {
					"en": 'Alerting Center',
					"de": 'Alerting Center'
				},
				'spf2bd': {
					"en": 'IPS Bans',
					"de": 'IPS Sperrungen'
				},
				'squid': {
					"en": 'HTTP-Proxy',
					"de": 'HTTP-Proxy'
				},
				'squid-reverse': {
					"en": 'Reverse-Proxy',
					"de": 'Reverse-Proxy'
				},
				'sshd': {
					"en": 'SSH-Server',
					"de": 'SSH-Server'
				},
				'syslog': {
					"en": 'Log',
					"de": 'Log'
				},
				'Paket filter': {
					"en": 'Paketfilter',
					"de": 'Paketfilter'
				},
				'wap': {
					"en": 'WLAN-Server',
					"de": 'WLAN-Server'
				},
				'webfilter': {
					"en": 'Content-Filter',
					"de": 'Content-Filter'
				},
				'webserver': {
					"en": 'HTTP-Server',
					"de": 'HTTP-Server'
				},
				'winbindd': {
					"en": 'Active Direcory Service',
					"de": 'Active Direcory Dienst'
				},
				'voip_proxy': {
					"en": 'VOIP Proxy',
					"de": 'VOIP Proxy'
				},
			},
			"deviceActionStates": {},
			"profilePublishMessages":[],
			"operationsLog": [],
			"operationsLogTableData": {
				"loading": false,
				"operationsLogSelectableColumns": [
					{
						'property': 'timestamp',
						'text': T('Time'),
						'displayType': 'text',
						getSortValue: (message: any) => {
							return message?.issued || message?.created || ""
						},
						'getValue': (message: any) => {
							let date = message?.issued || message?.created || ""
							return date != '' ? moment(date * 1000).format("DD.MM.YYYY HH:mm:ss") : date
						}
					},
					{
						'property': 'type',
						'text': T('Job'),
						'displayType': 'text',
						'getValue': (message: any) => {
							let result: string
							if(message.isProfilePublishMessage == true && message.content?.payload?.clientContext) {
								result = stringHelpers.capitalizeFirstLetter(message.content.payload.clientContext.split("-").slice(1).join(" ").toLowerCase())
							}
							else {
								result = (message.context || (message.type ? stringHelpers.capitalizeFirstLetter(message.type.replaceAll("_", " ").toLowerCase()) : ""))
							}
							return result
						}
					},
					{
						'property': 'profile',
						'text': T('Profile'),
						'displayType': 'labels',
						'getValue': (message: any) => {
							let uscProfile: undefined|UscProfile = undefined
							if (message?.content?.payload?.profileId) {
								uscProfile = objectStores.uscProfiles.getObjectFromStore(thisComponent.activeAccountId, message?.content?.payload?.profileId)
							}
							if(uscProfile != undefined) {
								return [
									new Label({
										"icon": new Icon({ class:"",}),
										"text": (uscProfile?.name ? uscProfile.name : ""),
										"title": (uscProfile?.name ? uscProfile.name : ""),
										"onClick": () => {
											if(uscProfile != undefined) {
												router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(thisComponent.activeAccountId) + '-usc-profiles-' + uscProfile.id)
											}
										}
									})
								]
							}
							return []
						}
					},
					{
						'property': 'direction',
						'text': T('Direction'),
						'displayType': 'labels',
						'getValue': (message: any) => {
							return [
								new Label({
									"icon": new Icon({ class:message?.queue == 'in' ? "fal fa-arrow-left" : "fal fa-arrow-right",}),
									"text": T(stringHelpers.capitalizeFirstLetter(message?.queue)),
									"title": message?.queue == 'in' ? T('Message from device to server') : T('Message from server to device')
								})
							]
						}
					},
					{
						'property': 'status',
						'text': T('Status'),
						'displayType': 'labels',
						'getValue': (message: any) => {
							return [
								new Label({
									"title": message?.statusText ? message?.statusText : T(stringHelpers.capitalizeFirstLetter(message?.status)),
									"text": T(stringHelpers.capitalizeFirstLetter(message?.status)),
									"class": message?.status == 'ERROR' ? 'bg-red' : message?.status == 'PENDING' ? 'bg-yellow' : '',
									"icon": new Icon( { class:message?.status == 'ERROR' ? "fal fa-exclamation-triangle" : message?.status == 'PENDING' ? "fal fa-bell" : "fal fa-info",})
								})
							]
						}
					},
					{
						'property': 'statusText',
						'text': T('Info'),
						'displayType': 'text',
						'getValue': (message: any) => {
							return T(message.statusText) || ""
						}
					},
					{
						'property': 'actions',
						'text': T('Actions'),
						'displayType': 'buttons',
						'getValue': (message: any) => {
							let thisbuttons: any = []
							if (message.status == "PENDING" && !message?.content?.payload?.profileId) {
								thisbuttons.push(
									{
										"text": T("Revoke"),
										"title": T("Revoke"),
										"icon": "fal fas-fw fa-undo",
										"onClick": async function () {
											await objectStores.uscUtms.revokeJob(thisComponent.activeAccountId, thisComponent.objectId, message.jobId)
											thisComponent.getOperationsLogData()
										},
										"disabled": function () { },
										"loading": function () { }
									}
								)
							}
							return thisbuttons
						}
					}
				]
			},
			"scans": [],
			"scansTableData": [],
			"scansTable": {
				titles: [],
				rows: []
			},
			"scansLoading": true,
			"utmVars": {
				"backups": [],
				"state": {
					"loading": true,
					"period": "today",
					"rejectsHistogramm": {
						"loading": true
					},
					"totalRejects": {
						"loading": true,
						"value": undefined
					},
					"latestRejects": {
						"loading": true,
						"tableData": {
							"rows": [],
							"titles": []
						}
					}
				}
			},
			"makingBackUp": false,
			"backupsTableData": {
				"loading": true,
				"tableData": {
					"rows": [],
					"titles": []
				}
			},
			"savingInventory": false,
			"inventoryForm": {
				"billNumber": "",
				"billDate": "",
				"hardwareDescription": "",
				"providerName": "",
				"providerTariff": "",
				"contractDate": "",
				"contractDuration": "",
				"handoverDate": "",
				"contractNumber": "",
				"providerCustomerNumber": "",
				"warrantyStartDate": "",
				"warrantyEndDate": "",
				"inventoryExternalLink": "",
				"inventoryLocationDescription": ""
			},
			"widgets": {
				"period": "currentmonth",
				"blockedThreats": {
					"title": "Blocked threats",
					"riskStatus": "loading",
					"data": {
						"HttpVirus": 0,
						"MailVirus": 0,
						"KernelLog": 0,
						"KernelReject": 0,
						"HttpUrlBlocked": 0,
						"MailUrlBlocked": 0
					}
				},
				"antivirusClamStatus": {
					"title": "Antivirus",
					"riskStatus": "loading",
					"text": "",
					"data": {
						"LastUpdate": undefined,
						"State": undefined
					}
				},
				"antivirusCtavStatus": {
					"title": "Antivirus CT",
					"riskStatus": "loading",
					"text": "",
					"data": {
						"LastUpdate": undefined,
						"State": undefined
					}
				},
				"kernelStatus": {
					"title": "Kernel",
					"riskStatus": "loading",
					"dropsText": "",
					"rejectsText": "",
					"data": {
						"Drops": undefined,
						"Rejects": undefined
					}
				},
				"alertStatus": {
					"title": "Alerts",
					"riskStatus": "loading",
					"text": "",
					"data": {
						"Risk": undefined,
						"NoRisk": undefined
					}
				},
				"httpTrafficStatus": {
					"title": "Traffic HTTP",
					"riskStatus": "loading",
					"text": "",
					"data": {
						"Traffic": undefined
					}
				},
				"loginStatus": {
					"title": "Failed logins",
					"riskStatus": "loading",
					"text": "",
					"data": {
						"Logins": undefined
					}
				}
			},
			"contextMap": {
				"system info": "System information"
			},
			"scanPorts": [
				{ "port": 7, "usedBy": "Echo-Service" },
				{ "port": 9, "usedBy": "Discard-Server" },
				{ "port": 13, "usedBy": "Daytime [RFC867]" },
				{ "port": 21, "usedBy": "File Transfer Protocol [RFC 959]" },
				{ "port": 22, "usedBy": "Secure Shell (SSH) Protocol [RFC 4251]" },
				{ "port": 23, "usedBy": "Telnet" },
				{ "port": 25, "usedBy": "SMTP" },
				{ "port": 26, "usedBy": "RSFTP, alternate to port 25 SMTP" },
				{ "port": 37, "usedBy": "TIME protocol [RFC 868] [RFC 956]" },
				{ "port": 53, "usedBy": "Domain Name System (DNS)" },
				{ "port": 79, "usedBy": "Finger" },
				{ "port": 80, "usedBy": "Hyper Text Transfer Protocol (HTTP)" },
				{ "port": 81, "usedBy": "Hyper Text Transfer Protocol (HTTP)" },
				{ "port": 88, "usedBy": "Kerberos (v5)" },
				{ "port": 106, "usedBy": "poppassd" },
				{ "port": 110, "usedBy": "POP3" },
				{ "port": 111, "usedBy": "Sun Remote Procedure Call" },
				{ "port": 113, "usedBy": "IDENT" },
				{ "port": 119, "usedBy": "Network News Transfer Protocol [RFC3977]" },
				{ "port": 135, "usedBy": "Remote Procedure Call (RPC)" },
				{ "port": 139, "usedBy": "NetBIOS" },
				{ "port": 143, "usedBy": "IMAP (Internet Mail Access Protocol)" },
				{ "port": 144, "usedBy": "Universal Management Architecture" },
				{ "port": 179, "usedBy": "Border Gateway Protocol(BGP) [RFC 4960]" },
				{ "port": 199, "usedBy": "SMUX" },
				{ "port": 389, "usedBy": "Lightweight Directory Access Protocol (LDAP)" },
				{ "port": 443, "usedBy": "HTTP protocol over TLS/SSL [RFC4960]" },
				{ "port": 444, "usedBy": "Cisco Webex Teams, Simple Network Paging Protocol [RFC1568]" },
				{ "port": 445, "usedBy": "Microsoft-DS Active Directory, Windows shares" },
				{ "port": 465, "usedBy": "Outgoing SMTP Mail over SSL (SMTPS) [RFC 2487]" },
				{ "port": 513, "usedBy": "Login via Telnet" },
				{ "port": 514, "usedBy": "Shell(RSH), Syslog" },
				{ "port": 515, "usedBy": "Printer Daemon" },
				{ "port": 543, "usedBy": "Kerberos login" },
				{ "port": 544, "usedBy": "Kerberos Remote Shell" },
				{ "port": 548, "usedBy": "Apple Filing Protocol (AFP)" },
				{ "port": 554, "usedBy": "Real Time Streaming Protocol (RTSP) Windows" },
				{ "port": 587, "usedBy": "Outgoing SMTP Mail [RFC2476]" },
				{ "port": 631, "usedBy": "Internet Printing Protocol" },
				{ "port": 646, "usedBy": "Label Distribution Protocol  (LDP)" },
				{ "port": 873, "usedBy": "QNAP NAS, Rsync" },
				{ "port": 990, "usedBy": " FTP over TLS/SSL" },
				{ "port": 993, "usedBy": "IMAP (Internet Mail Access Protocol)" },
				{ "port": 995, "usedBy": "POP3 mail over SSL" },
				{ "port": 1025, "usedBy": "Windows RPC" },
				{ "port": 1433, "usedBy": "MsSQL-Server" },
				{ "port": 1494, "usedBy": "Citrix ICA Terminal-Server" },
				{ "port": 1720, "usedBy": "Microsoft NetMeeting" },
				{ "port": 1723, "usedBy": "Point-to-Point Tunneling Protocol (PPTP)" },
				{ "port": 2001, "usedBy": "Often use by trojans/backdoors" },
				{ "port": 2049, "usedBy": "Network File System (NFS) [RFC 1813] [RFC5665]." },
				{ "port": 2121, "usedBy": "FTP-Proxy" },
				{ "port": 2717, "usedBy": "KnowShowGo P2P" },
				{ "port": 3000, "usedBy": "Ruby on Rails applications, NodeJS etc." },
				{ "port": 3128, "usedBy": "Squid HTTP-Proxy Standard" },
				{ "port": 3306, "usedBy": "MySQL-Server" },
				{ "port": 3389, "usedBy": "Windows Remote Desktop" },
				{ "port": 4899, "usedBy": "Radmin remote administration tool" },
				{ "port": 5000, "usedBy": "Universal Plug and Play (UPnP)" },
				{ "port": 5004, "usedBy": "Cisco Webex Teams" },
				{ "port": 5009, "usedBy": "Apple AirPort Admin Utility, Microsoft Windows Filesystem" },
				{ "port": 5060, "usedBy": "Session Initiation Protocol (SIP)" },
				{ "port": 5101, "usedBy": "Yahoo P2P Instant Messages" },
				{ "port": 5190, "usedBy": "ICQ, AIM (AOL Instant Messenger), Apple iChat" },
				{ "port": 5432, "usedBy": "PostgreSQL Database" },
				{ "port": 5631, "usedBy": "PC-Anywhere" },
				{ "port": 5666, "usedBy": "Nagios NRPE" },
				{ "port": 5800, "usedBy": "Virtual Network Computing (VNC)" },
				{ "port": 5900, "usedBy": "Virtual Network Computing (VNC)" },
				{ "port": 6000, "usedBy": "X Window System" },
				{ "port": 6001, "usedBy": "X Window System" },
				{ "port": 6178, "usedBy": "Securepoint Operation Center (SOC)" },
				{ "port": 8000, "usedBy": "Alternate HTTP port" },
				{ "port": 8008, "usedBy": "Citrix virtual Desktops" },
				{ "port": 8080, "usedBy": "HTTP-Proxy" },
				{ "port": 8081, "usedBy": "HTTP-Proxy" },
				{ "port": 8110, "usedBy": "Pop3Proxy" },
				{ "port": 8443, "usedBy": "Alternate HTTP over TLS/SSL port" },
				{ "port": 8888, "usedBy": "Alternate HTTP port" },
				{ "port": 9200, "usedBy": "Elasticsearch Query & Adminitration" },
				{ "port": 9999, "usedBy": "SOC Logcenter" },
				{ "port": 10000, "usedBy": "Webmin - Web-based Linux admin tool" },
				{ "port": 11115, "usedBy": "Securepoint UTM Administration" },
				{ "port": 49152, "usedBy": "Microsoft Lync, P2P Torent, Apple AirPlay" }
			]
		}
	},
	computed: {
		"utm": function (this: any) {
			this.refreshCount;
			let utm: any = useStore().getters.getObject({
				"accountId": this.activeAccountId,
				"productType": "unifiedSecurityConsole",
				"objectType": "uscUtms",
				"objectId": useRouterStore().getObjectId || ""
			})
			return utm
		},
		"canUseUscJobs": function (this: any) {
			return true
		},
		"utmStatus": function (this: any) {
			let result = {}
			if ((this.ccutmStates || []).length) {
				result = (this.ccutmStates.filter((state: any) => {
					return this.utm.utmId == state.deviceId
				}) || [])[0] || {}
			}
			return result
		},
		"seconds": () => new Date().getTime() / 1000,
		isRebooting: function (this: any) {
			let result: boolean = false
			let lastFeatureStatus: any = this.getLastDeviceActionStatusFromOperationLog('system reboot')
			if (lastFeatureStatus == "REBOOTING" || lastFeatureStatus == "PENDING") {
				result = true
			}
			return result
		},
		canUseWebsession: function() {
			return sessionHelpers.hasOneOfScopes(["usc:administration", "usc:monitoring"])
		},
		mergedOperationsLog: function(this:any) {
			return [].concat(this.operationsLog || [],this.profilePublishMessages || [])
		},
		shouldDisplayLock: function(this: any) {
			return validationHelpers.versionCompare(this.getUtmMessageData(this.utm, 'spcli-system-info')?.version || "0", config.unifiedSecurityConsole.utmVersionForPin) !== -1
		}
	},
	methods: {
		getCronoJobsList:async function(this:any) {
			this.gettingHistory = true
			await this.$refs?.cronojoblist?.getJobList?.()
			this.gettingHistory = false
		},
		getPlanSingleUpdateDialog: function(this:any) {
			let existingDate = undefined
			if (this.$refs.cronojoblist.data?.[0] != undefined) {
				let lastUpdateEntry = this.$refs.cronojoblist.data.find((entry:any) => {
					return entry.type == "utm/update" && entry.status == "SCHEDULED"
				})
				if (lastUpdateEntry && lastUpdateEntry.nextTrigger > moment().unix()) {
					existingDate = moment(lastUpdateEntry.nextTrigger * 1000).format("DD.MM.YYYY HH:mm")
				}
			}
			objectStores.uscUtms.getPlanSingleUpdateDialog(this.activeAccountId,this.utm.utmId,existingDate)
		},
		getTooltip(this: any, reasons: Array<string> | string | undefined) {
			let reasonString: string | undefined = undefined
			if(reasons && Array.isArray(reasons) && reasons.length > 0) {
				reasonString = ""
				reasons.forEach((reason) => {
					reasonString = reason + ", " + reasonString
				})
				reasonString = reasonString.slice(0, -2)
			}
			else if(typeof reasons == "string") {
				reasonString = reasons
			}
			return reasonString
		},
		isObjectEmpty: jsonHelpers.isObjectEmpty,
		getLaasState: function (utm: UscUtm) { return objectStores.uscUtms.getLaasState(utm) },
		setActiveTab: function (this: any, tabName: string) {
			this.$refs.tabs.setActiveTab(tabName)
			this.tabChanged = true
		},
		goToClusterUtm: async function (this: any) {
			try {
				this.loadingCluster = true
				let thisComponent = this
				let partnerUtm: UscUtm | undefined = undefined
				let result: any | undefined = await useStore().dispatch(ActionTypes.getObjectInfos, {
					"accountId": this.activeAccountId,
					"objectTypes": ["uscUtms?props[]=utmId&props[]=cluster&props[]=license"]
				})
				if (result) {
					result = undefined
					result = useStore().getters.getObjects({
						"accountId": thisComponent.activeAccountId,
						"productType": "unifiedSecurityConsole",
						"objectType": "uscUtms",
					})
					if (result && Array.isArray(result)) {
						partnerUtm = result.find((uscUtm: UscUtm) => {
							return uscUtm.license?.clusterUuid === thisComponent.utm.license?.clusterUuid && uscUtm.utmId !== thisComponent.utm.utmId
						})
					}
					if (partnerUtm) {
						router.navigate('show-tenant-' + tenantHelpers.getTenantDomain(thisComponent.activeAccountId) + '-usc-utms-' + partnerUtm.utmId)
					}
					else {
						throw new Error(T("No other cluster UTM for this cluster could be found"))
					}
				}
				else {
					throw new Error(T('An Error occurred'))
				}
			}
			catch (e: unknown) {
				console.error(e)
				let message: string = T('An Error occurred')
				if(e instanceof Error) message = T(e.message);
				if (typeof e == "string") message = T(e);
				dialogs.misc.errorDialog(this.activeAccountId, T("Error Switchting Cluster Memeber"), message)
			}
			finally {
				this.loadingCluster = false
			}
		},
		unescape: encodingHelpers.unescapeHTML,
		formatBytes: numberHelpers.formatBytes,
		T: T,
		getMemoryPercentage: function (memory: any) {
			return (memory ? (memory.total - memory.available) / (memory.total / 100) : 0)
		},
		getStoragePercentage: function (storage: any) {
			return (storage ? (storage.total - storage.available) / (storage.total / 100) : 0)
		},
		getCpuPercentage: function (cpuInfo: any) {
			let other = 100 - parseInt(cpuInfo.idle) - (parseInt(cpuInfo.nice) + parseInt(cpuInfo.system) + parseInt(cpuInfo.user))
			let percentage = cpuInfo ? (parseInt(cpuInfo.nice) + parseInt(cpuInfo.system) + parseInt(cpuInfo.user)) + other : 0;
			return percentage
		},
		getDate: function (timestamp: any, dateTime: boolean = false) {
			let date: any = ""
			if (typeof timestamp == "number") {
				date = moment.unix(timestamp).format(dateTime ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY")
			}
			else {
				date = moment(timestamp).format(dateTime ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY")
			}
			return date;
		},
		getUtmInfo: async function (this: any) {
			let thisComponent: any = this
			this.loaderText = "Getting UTM info..."
			let hasUtm = await useStore().dispatch(ActionTypes.getObjectInfo, {
				"accountId": this.activeAccountId,
				"productType": "unifiedSecurityConsole",
				"objectType": "uscUtms",
				"queryType": "uscUtms",
				"objectId": useRouterStore().getObjectId
			})

			if (hasUtm === false) {
				router.navigate('404-' + useRouterStore().getObjectId)
			}
			else {
				await useStore().dispatch(ActionTypes.getObjectInfos, {
					"accountId": this.activeAccountId,
					"objectTypes": ["ccutmStates"],
				})
				this.loaderText = "Getting Operationslog info..."
				await thisComponent.getOperationsLogData();
				this.loaderText = "Getting Scans info..."
				await thisComponent.getScansData();
				this.loaderText = "Getting Jobs info..."
				thisComponent.getAppsTableData()
				this.loaderText = "Initiating inventory form..."
				thisComponent.initInventoryform()
				this.loaderText = "Initiating USR info..."
				thisComponent.getUsrData()
				thisComponent.initialized = true
				nextTick(async function () {
					thisComponent.refreshCount++
				})
			}

		},
		getOperationsLogData: async function (this: any) {
			let operationsLog: any = undefined
			this.operationsLogTableData.loading = true
			const objectId = useRouterStore().getObjectId
			try {
				operationsLog = objectId ? await objectStores.uscUtms.getJobs(this.activeAccountId, objectId,"?limit=20&state=all&queue=all") : {jobs:[]}
				if (operationsLog?.jobs) {
					operationsLog = operationsLog.jobs
					this.operationsLog = operationsLog
				}
				let messages = await objectStores.uscUtms.getUtmMessages(this.activeAccountId, useRouterStore().getObjectId || "")
				if (messages?.messages) {
					messages.messages.forEach((message: any) => {
						message.isProfilePublishMessage = true
					})
					this.profilePublishMessages = messages.messages
				}
			}
			catch (e: any) {
				console.error(e)
			}
			this.operationsLogTableData.loading = false
			return true
		},
		startNewScan: async function (this: any) {
			let thisComponent: any = this
			objectStores.uscUtms.dialogs.renderNewScanDialog(async function (scanOptions: any) {
				const scanData = {
					"host": {
						"address": scanOptions.host.address,
						"interface": scanOptions.host.interface
					},
					"profile": {
						"name": scanOptions.profile.name,
						"tcp": scanOptions.selectOptions.profiles[scanOptions.profile.name].tcp || true,
						"udp": scanOptions.selectOptions.profiles[scanOptions.profile.name].udp || false
					}
				}
				let result = await objectStores.uscUtms.startScan(thisComponent.activeAccountId,thisComponent.objectId,scanData)


				const thisScan = {
					id: result.jobId,
					issued: result.timestamp,
					lastUpdate: result.timestamp,
					status: result.status,
					statusText: result.status,
					result: {
						host: {
							address: scanOptions.host.address
						}
					}
				}
				thisComponent.scans.unshift(thisScan)
				return result
			})
		},
		getScansData: async function (this: any, uncached: boolean = false) {
			this.scansLoading = true
			const objectId = useRouterStore().getObjectId
			try {
				let scans = objectId ? await objectStores.uscUtms.getScans(this.activeAccountId, objectId, "?limit=20&state=all" + (uncached ? "&_=" + timeHelpers.getUnixTimeStamp() : "")) : {scans:[]}
				
				if (scans?.scans) {
					scans = scans.scans
					this.scans = scans
				}
			}
			catch (e: any) {
				console.error(e)
			}

			this.scansLoading = false

			return true
		},
		getAppsTableData: async function (this: any, useLoader: boolean = false, refresh: boolean = false) {
			let thisComponent: any = this
			let appList: any = []
			this.applicationsTableData.loading = true
			this.applicationsTableData.tableData.titles = [
				{
					"id": "name",
					"text": "Name",
					"sortable": false,
				},
				{
					"id": "flags",
					"text": "Flags",
					"sortable": false,
				},
				{
					"id": "status",
					"text": "Status",
					"sortable": false,
				},
				{
					"id": "action",
					"text": "Action",
					"sortable": false,
				},
			]


			let thisMessages: any = Array.isArray(thisComponent.getUtmMessageData(this.utm, 'spcli-appmgmt-status')) ? thisComponent.getUtmMessageData(this.utm, 'spcli-appmgmt-status') : []
			thisMessages.forEach(function (message: any) {
				message.applicationName = thisComponent.applicationNames[message.application]?.[LANG] || message.application
			})

			thisMessages = thisMessages.sort(function (a: any, b: any) {
				if ((a.applicationName || "") > (b.applicationName || "")) {
					return 1
				}
				else {
					return -1
				}
			}).filter((application: any) => {
				return application.flags?.indexOf('HIDDEN') == -1
			})

			this.applicationsTableData.tableData.rows = Array.isArray(thisMessages) ? thisMessages.map((application: any) => {
				let thisActionButtons: any = {}
				let applicationFlagLabels: Label[] = []

				application.flags.map((flag: string) => {
					return {
						"text": flag,
						"title": flag
					}
				})

				if (application.state == "UP") {
					thisActionButtons = {
						"buttons": [
							{
								"text": T("Restart"),
								"title": T("Restart"),
								"icon": "fal fa-fw fa-sync",
								"onClick": async function () {
									await thisComponent.execDeviceAction(thisComponent.utm, "appmgmt restart application", application.application)
									thisComponent.getAppsTableData()

								},
								"disabled": false,
								"loading": false
							},
							{
								"text": T("Stop"),
								"title": T("Stop"),
								"icon": "fal fa-fw fa-stop",
								"onClick": async function () {
									await thisComponent.execDeviceAction(thisComponent.utm, "appmgmt stop application", application.application)
									thisComponent.getAppsTableData()

								},
								"disabled": false,
								"loading": false
							}
						]
					}
				}
				else if (application.state == "DOWN") {
					thisActionButtons = {
						"buttons": [
							{
								"text": T("Start"),
								"title": T("Start"),
								"icon": "fal fa-fw fa-play",
								"onClick": async function () {
									await thisComponent.execDeviceAction(thisComponent.utm, "appmgmt start application", application.application)
									thisComponent.getAppsTableData()
								},
								"disabled": false,
								"loading": false
							}
						]
					}
				}

				return {
					data: {
						"name": thisComponent.applicationNames[application.application]?.[LANG] || application.application,
						"flags": application.flags ? applicationFlagLabels.join(' ') : "",
						"status": {
							"labels": <Label[]>[new Label({
								"title": T(application.state),
								"text": T(application.state),
								"class": application.state == "UP" ? 'bg-green' : application.state == 'DOWN' ? 'bg-red' : 'bg-yellow'
							})]
						},
						"action": thisActionButtons,
					}
				}

			}) : []

			thisComponent.refreshCount++
			thisComponent.applicationsTableData.loading = false

		},

		setThWidth: function (ref: string) {
			let thisComponent: any = this
			setTimeout(function () { thisComponent.$refs[ref]?.setThWidth() }, 100)
		},
		getInfoBoxText(this: any) {
			const utm = this.utm
			let utmStreet: any = jsonHelpers.getObjectProperty(utm, "inventory.inventoryLocation.street")
			let utmPostalCode: any = jsonHelpers.getObjectProperty(utm, "inventory.inventoryLocation.postalCode")
			let utmCity: any = jsonHelpers.getObjectProperty(utm, "inventory.inventoryLocation.city")
			let utmLatitude: any = jsonHelpers.getObjectProperty(utm, "inventory.inventoryLocation.latitude")
			let utmLongitude: any = jsonHelpers.getObjectProperty(utm, "inventory.inventoryLocation.longitude")
			let utmInventoryLocationDescription: any = jsonHelpers.getObjectProperty(utm, "inventory.inventoryLocationDescription")

			let template: any = '<div>';
			if (utmStreet) {
				template += '<i class="fal fa-fw fa-road"></i>&nbsp;' + encodingHelpers.escapeHTML(utmStreet) + '<br>'
			}
			if (utmPostalCode) {
				template += '<i class="fal fa-fw fa-globe"></i>&nbsp;' + encodingHelpers.escapeHTML(utmPostalCode) + '<br>'
			}
			template += encodingHelpers.escapeHTML(utmCity) + '<br>';
			if (utmInventoryLocationDescription) {
				template += '<p>' + encodingHelpers.escapeHTML(utmInventoryLocationDescription) + '</p>'
			}
			template += '<div>';
			return template
		},
		deviceActionStatus: function (this: any, context: string) {
			let utm: UscUtm = this.utm
			let showElement: boolean = true
			let isDisabled: boolean = false
			let isLoading: boolean = false
			let disabledReason: string|undefined
			let isRebooting: boolean = this.isRebooting
			let lastFeatureStatus: any = this.getLastDeviceActionStatusFromOperationLog(context)
			if (lastFeatureStatus == "PENDING") {
				isLoading = true
				isDisabled = true
			}
			else if (lastFeatureStatus == "REBOOTING") {
				isLoading = true
				isDisabled = true
				isRebooting = true
			}

			const contextToPinRequired = {
				"system reboot":true,
				"system poweroff":true,
				"system config factorysettings":true,
				"system upgrade rollback":this.shouldDisplayLock,
				"system upgrade dryrun":this.shouldDisplayLock,
				"system upgrade finalize":this.shouldDisplayLock,
				"system upgrade confirm eula":this.shouldDisplayLock,
				"system upgrade confirm privacy":this.shouldDisplayLock
			}

			if(contextToPinRequired[context as keyof typeof contextToPinRequired] && utm.pinState) {
				isDisabled = !utm.pinState.enabled || utm.pinState.locked
				if(!utm.pinState.enabled) {
					disabledReason = T('Dieses Feature ist auf dieser UTM nicht verfügbar, da die PIN deaktiviert ist.')
				}
				if( utm.pinState.locked) {
					disabledReason = T('Dieses Feature ist auf dieser UTM nicht verfügbar, da die PIN gesperrt ist.')
				}
			}

			return {
				"view": showElement,
				"disabled": isDisabled,
				"loading": isLoading,
				"rebooting": isRebooting,
				"disabledReason":disabledReason
			}
		},
		execDeviceAction: function (item: UscUtm, context: string, payload?: any) { return objectStores.uscUtms.execDeviceAction(item, context, payload) },
		getLastDeviceActionStatusFromOperationLog: function (this: any, context: string) {
			let status: any = undefined
			let thisActions: any[] = this.operationsLog.filter((entry: any) => {
				return entry.context == context || this.contextMap[context] == entry.context
			})
			if (thisActions.length) {
				status = thisActions[0].status
			}
			return status
		},
		initInventoryform: function () {
			let thisComponent: any = this
			if (!thisComponent.inventoryLoaded) {
				thisComponent.inventoryForm.billDate = thisComponent.utm?.inventory?.billDate ? moment(parseInt(thisComponent.utm?.inventory.billDate) * 1000).format("DD.MM.YYYY") : undefined
				thisComponent.inventoryForm.billNumber = thisComponent.utm?.inventory?.billNumber || undefined
				thisComponent.inventoryForm.contractDate = thisComponent.utm?.inventory?.contractDate ? moment(parseInt(thisComponent.utm?.inventory.contractDate) * 1000).format("DD.MM.YYYY") : undefined
				thisComponent.inventoryForm.contractDuration = thisComponent.utm?.inventory?.contractDuration || undefined
				thisComponent.inventoryForm.contractNumber = thisComponent.utm?.inventory?.contractNumber || undefined
				thisComponent.inventoryForm.handoverDate = thisComponent.utm?.inventory?.handoverDate ? moment(parseInt(thisComponent.utm?.inventory.handoverDate) * 1000).format("DD.MM.YYYY") : undefined
				thisComponent.inventoryForm.hardwareDescription = thisComponent.utm?.inventory?.hardwareDescription || undefined
				thisComponent.inventoryForm.inventoryExternalLink = thisComponent.utm?.inventory?.inventoryExternalLink || undefined
				thisComponent.inventoryForm.inventoryLocation = thisComponent.utm?.inventory?.inventoryLocation || {}
				thisComponent.inventoryForm.inventoryLocationDescription = thisComponent.utm?.inventory?.inventoryLocationDescription || undefined
				thisComponent.inventoryForm.providerCustomerNumber = thisComponent.utm?.inventory?.providerCustomerNumber || undefined
				thisComponent.inventoryForm.providerName = thisComponent.utm?.inventory?.providerName || undefined
				thisComponent.inventoryForm.providerTariff = thisComponent.utm?.inventory?.providerTariff || undefined
				thisComponent.inventoryForm.warrantyEndDate = thisComponent.utm?.inventory?.warrantyEndDate ? moment(parseInt(thisComponent.utm?.inventory.warrantyEndDate) * 1000).format("DD.MM.YYYY") : undefined
				thisComponent.inventoryForm.warrantyStartDate = thisComponent.utm?.inventory?.warrantyStartDate ? moment(parseInt(thisComponent.utm?.inventory.warrantyStartDate) * 1000).format("DD.MM.YYYY") : undefined

				thisComponent.inventoryLoaded = true
			}
		},
		getRPAccountLink: function (this: any) {
			return 'https://' + (document.location.origin.indexOf('portal-dev') != -1 ? 'dev' : '') + 'my.securepoint.de/2.0/accounts/' + this.activeAccountId
		},
		saveInventory: async function () {
			let thisComponent: any = this
			thisComponent.savingInventory = true
			let tenantDomain: any = thisComponent.activeTenantDomain
			let deviceId: any = thisComponent.objectId
			let properties: any = jsonHelpers.copyObject(thisComponent.inventoryForm)

			delete properties.inventoryLocation

			if (properties.billDate) {
				properties.billDate = moment(properties.billDate, "DD.MM.YYYY").format('X')
			}
			if (properties.contractDate) {
				properties.contractDate = moment(properties.contractDate, "DD.MM.YYYY").format('X')
			}
			if (properties.handoverDate) {
				properties.handoverDate = moment(properties.handoverDate, "DD.MM.YYYY").format('X')
			}
			if (properties.warrantyStartDate) {
				properties.warrantyStartDate = moment(properties.warrantyStartDate, "DD.MM.YYYY").format('X')
			}
			if (properties.warrantyEndDate) {
				properties.warrantyEndDate = moment(properties.warrantyEndDate, "DD.MM.YYYY").format('X')
			}
			if (properties.inventoryExternalLink && properties.inventoryExternalLink.indexOf('http://') == -1 && properties.inventoryExternalLink.indexOf('https://') == -1) {
				properties.inventoryExternalLink = 'http://' + properties.inventoryExternalLink
			}
			if (typeof properties.contractDuration == "number") {
				properties.contractDuration = properties.contractDuration.toString()
			}
			try {
				await objectStores.uscUtms.setUtmProperties(tenantDomain, deviceId, properties)
			}
			catch (e: any) {
				console.error(e)
			}
			await thisComponent.$store.dispatch(ActionTypes.getObjectInfo, {
				"accountId": thisComponent.activeAccountId,
				"productType": "unifiedReporting",
				"objectType": "utms",
				"queryType": "utms",
				"objectId": useRouterStore().getObjectId
			})
			thisComponent.initInventoryform()
			thisComponent.savingInventory = false
		},
		getInventoryLocationDialog: function (this: any) {

			dialogs.unifiedSecurity.setInventoryLocation(
				this.utm?.inventory?.inventoryLocation || {},
				async (inventoryLocation, inventoryLocationDescription) => {
					if (!jsonHelpers.isObjectEmpty(inventoryLocation)) {
						await useStore().dispatch(ActionTypes.updateObjectInventory, {
							"accountId": this.activeAccountId,
							"productType": 'unifiedSecurityConsole',
							"objectType": 'uscUtms',
							"queryObjectType": 'ccutmProperties',
							"objectId": this.utm.utmId,
							"object": {
								inventoryLocation: JSON.stringify(inventoryLocation),
								inventoryLocationDescription
							}
						})
						return true
					}
					else {
						return false
					}
				},
				this.utm?.inventory?.inventoryLocationDescription
			)
		},
		renderSetCloudBackupPassword: function (this: any,activateCloudBackup:boolean=false) {
			let thisComponent: any = this
			let newModal = {
				"id": "utm-new-password",
				"accountId": this.activeAccountId,
				"abortable": true,
				"content": {
					"title": {
						"text": activateCloudBackup ? T('Enable cloud-backup') : T('Set new password'),
						"icon": "fal fa-exclamation-triangle",
					},
					"body": {
						"use": true,
						"content": undefined,
						"component": "set-password",
						"properties": undefined
					},
				},
				"buttons": [
					{
						loading: false,
						onClick: () => {
							getterHelpers.useStore().commit(MutationTypes.removeModal,{accountId:this.activeAccountId})
						},
						icon: "fal fa-times",
						text: T('Cancel'),
						align: "left",
						disabled: false
					},
					{
						loading: false,
						onClick: async (modal: any) => {
							getterHelpers.useStore().state.session.accounts[thisComponent.activeAccountId].modals[0].buttons[1].disabled = true
							getterHelpers.useStore().state.session.accounts[thisComponent.activeAccountId].modals[0].buttons[1].loading = true
							try {
								let password: string = modal.$refs.modalComponent.password
								let result = await objectStores.uscUtms.sendSpcliMessage(thisComponent.activeAccountId, thisComponent.utm.utmId, { "data": ["system", "cloudbackup", "set", "password", password] })
								if(activateCloudBackup) {
									await objectStores.uscUtms.sendSpcliMessage(thisComponent.activeAccountId, thisComponent.utm.utmId, { "data": ["extc", "global", "set", "variable", "GLOB_CLOUDBACKUP_TIME", "value", "1"] })
								}
								await objectStores.uscUtms.sendSpcliMessage(thisComponent.activeAccountId, thisComponent.utm.utmId, { "data": ["system", "config", "save"] })
								await objectStores.uscUtms.sendSpcliMessage(thisComponent.activeAccountId, thisComponent.utm.utmId, { "data": ["system", "update", "system"] })
								thisComponent.getBackupInfo()
							}
							catch(e) {
								console.error(e)
							}
							getterHelpers.useStore().state.session.accounts[thisComponent.activeAccountId].modals[0].buttons[1].disabled = false
							getterHelpers.useStore().state.session.accounts[thisComponent.activeAccountId].modals[0].buttons[1].loading = false
							getterHelpers.useStore().commit(MutationTypes.removeModal,{accountId:this.activeAccountId})
						},
						icon: 'fal fa-save',
						text: T('Save'),
						align: "right",
						disabled: true
					}
				]
			}
			useStore().dispatch(ActionTypes.addModal, newModal)
		},
		getUtmMessageData: objectStores.uscUtms.getUtmMessageData,
		getUsrData: async function (this: any) {
			if (this.getLaasState(this.utm) == "active") {

				this.widgets.blockedThreats.riskStatus = "loading"
				this.widgets.antivirusClamStatus.riskStatus = "loading"
				this.widgets.antivirusCtavStatus.riskStatus = "loading"
				this.widgets.kernelStatus.riskStatus = "loading"
				this.widgets.alertStatus.riskStatus = "loading"
				this.widgets.httpTrafficStatus.riskStatus = "loading"
				this.widgets.loginStatus.riskStatus = "loading"


				let defaultQueryObject: any = {
					"query": {
						"modul": "CloudUtm",
						"name": "USRSum",
						"options": {
							"relate": "count",
							"period": this.widgets.period,
							"size": 1,
							"offset": 0,
							"timezone": "+02:00",
							"order": {
								"asc": false
							},
							"filter": [
								{
									"field": "device",
									"operator": "equal",
									"value": this.utm.utmId
								}
							]
						}
					}
				}

				let countVirusQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				countVirusQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountVirus"
				})

				let countMailScannerVirusQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				countMailScannerVirusQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountMailScannerVirus"
				})

				let countKernelCommandLogQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				countKernelCommandLogQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountKernelCommandLog"
				})

				let countKernelCommandRejectQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				countKernelCommandRejectQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountKernelCommandReject"
				})

				let countUrlsTIFBlockedQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				countUrlsTIFBlockedQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountUrlsTIFBlocked"
				})

				let countMailScannerTIFQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				countMailScannerTIFQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountMailScannerTIF"
				})

				let antivirusClamStatusQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				antivirusClamStatusQueryObject.query.name = "USRLatest"
				antivirusClamStatusQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "ValueSystemClamUpdate"
				})

				let valueSystemCtavUpdateQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				valueSystemCtavUpdateQueryObject.query.name = "USRLatest"
				valueSystemCtavUpdateQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "ValueSystemCtavUpdate"
				})

				let kernelStatusDropsQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				kernelStatusDropsQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountKernelDrop"
				})

				let kernelStatusRejectsQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				kernelStatusRejectsQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountKernelReject"
				})

				let countAlertsRiskQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				countAlertsRiskQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountAlertsRisk"
				})

				let countAlertsNoRiskQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				countAlertsNoRiskQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountAlertsNoRisk"
				})

				let countTrafficQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				countTrafficQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountTraffic"
				})

				let loginStatusQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
				loginStatusQueryObject.query.options.filter.push({
					"field": "query",
					"operator": "equal",
					"value": "CountAuthLoginsFailed"
				})

				let results: any = await Promise.all([
					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", countVirusQueryObject),
					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", countMailScannerVirusQueryObject),
					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", countKernelCommandLogQueryObject),
					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", countKernelCommandRejectQueryObject),
					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", countUrlsTIFBlockedQueryObject),
					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", countMailScannerTIFQueryObject),

					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", antivirusClamStatusQueryObject),

					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", valueSystemCtavUpdateQueryObject),

					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", kernelStatusDropsQueryObject),
					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", kernelStatusRejectsQueryObject),

					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", countAlertsRiskQueryObject),
					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", countAlertsNoRiskQueryObject),

					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", countTrafficQueryObject),

					requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + this.activeTenantDomain + "/stats/execute", loginStatusQueryObject),
				])

				let countVirus: number = results[0] ? results[0].result[0].value : 0;
				let countMailScannerVirus: number = results[1] ? results[1].result[0].value : 0;
				let countKernelCommandLog: number = results[2] ? results[2].result[0].value : 0;
				let countKernelCommandReject: number = results[3] ? results[3].result[0].value : 0;
				let countUrlsTIFBlocked: number = results[4] ? results[4].result[0].value : 0;
				let countMailScannerTIF: number = results[5] ? results[5].result[0].value : 0;
				let antivirusClamStatusKey: number | string = results[6] ? results[6].result[0].key : 0;
				let antivirusClamStatusValue: number = results[6] ? results[6].result[0].value : 0;
				let antivirusCtavStatusKey: number | string = results[7] ? results[7].result[0].key : 0;
				let antivirusCtavStatusValue: number = results[7] ? results[7].result[0].value : 0;
				let kernelStatusDrops: number = results[8] ? results[8].result[0].value : 0;
				let kernelStatusRejects: number = results[9] ? results[9].result[0].value : 0;
				let countAlertsRisk: number | string = results[10] ? results[10].result[0].value : 0;
				let countAlertsNoRisk: number | string = results[11] ? results[11].result[0].value : 0;
				let countTraffic: number = results[12] ? results[12].result[0].value : 0;
				let loginStatus: number = results[13] ? results[13].result[0].value : 0;

				if(typeof countAlertsRisk == "string") countAlertsRisk = parseInt(countAlertsRisk) || 0;
				if(typeof countAlertsNoRisk == "string") countAlertsNoRisk = parseInt(countAlertsNoRisk) || 0;

				this.widgets.blockedThreats.data.HttpVirus = countVirus
				this.widgets.blockedThreats.data.MailVirus = countMailScannerVirus
				this.widgets.blockedThreats.data.KernelLog = countKernelCommandLog
				this.widgets.blockedThreats.data.KernelReject = countKernelCommandReject
				this.widgets.blockedThreats.data.HttpUrlBlocked = countUrlsTIFBlocked
				this.widgets.blockedThreats.data.MailUrlBlocked = countMailScannerTIF
				this.widgets.blockedThreats.riskStatus = "none"

				this.widgets.antivirusClamStatus.data.State = antivirusClamStatusKey
				this.widgets.antivirusClamStatus.data.LastUpdate = antivirusClamStatusValue

				if (antivirusClamStatusKey == "as shipped") {
					this.widgets.antivirusClamStatus.riskStatus = "never"
					this.widgets.antivirusClamStatus.text = "Scanner is disabled"
				}
				else if (antivirusClamStatusKey == "not in use") {
					this.widgets.antivirusClamStatus.riskStatus = "never"
					this.widgets.antivirusClamStatus.text = "Not in use"
				}
				else if (antivirusClamStatusKey == "signature update started") {
					this.widgets.antivirusClamStatus.riskStatus = "never"
					this.widgets.antivirusClamStatus.text = "Update started"
				}
				else if (['up to date', 'signatures successfully updated', 'signatures up to date, nothing to do'].indexOf(antivirusClamStatusKey as string) != -1) {
					this.widgets.antivirusClamStatus.riskStatus = "none"
					if ((moment().unix() - antivirusClamStatusValue) < 86400 * 2) {
						this.widgets.antivirusClamStatus.riskStatus = "none"
						this.widgets.antivirusClamStatus.text = "Pattern is up to date"
					}
					else if ((moment().unix() - antivirusClamStatusValue) >= 86400 * 2) {
						if (antivirusClamStatusValue == 0) {
							this.widgets.antivirusClamStatus.riskStatus = "never"
							this.widgets.antivirusClamStatus.text = "No info"
						}
						else {
							this.widgets.antivirusClamStatus.riskStatus = "medium"
							this.widgets.antivirusClamStatus.text = T("Last Update:") + " " + moment(antivirusClamStatusValue * 1000).format('DD.MM.YYYY')
						}
					}
					else if ((moment().unix() - antivirusClamStatusValue) >= 86400 * 3) {
						this.widgets.antivirusClamStatus.riskStatus = "high"
						this.widgets.antivirusClamStatus.text = "Update is older than 2 days"
					}
				}
				else if (['not running'].indexOf(antivirusClamStatusKey as string) != -1) {
					this.widgets.antivirusClamStatus.riskStatus = "medium"
					this.widgets.antivirusClamStatus.text = "Scanner has been stopped"
				}
				else if (['is deactivated', 'license expired', 'missing license'].indexOf(antivirusClamStatusKey as string) != -1) {
					this.widgets.antivirusClamStatus.riskStatus = "high"
					this.widgets.antivirusClamStatus.text = "License error"
					if (antivirusClamStatusKey == "is deactivated") {
						this.widgets.antivirusClamStatus.text = "Update has been deactivated"
					}
				}
				else {
					this.widgets.antivirusClamStatus.riskStatus = "never"
					this.widgets.antivirusClamStatus.text = "No Info"
				}

				this.widgets.antivirusCtavStatus.data.State = antivirusCtavStatusKey
				this.widgets.antivirusCtavStatus.data.LastUpdate = antivirusCtavStatusValue

				if (antivirusCtavStatusKey == "as shipped") {
					this.widgets.antivirusCtavStatus.riskStatus = "never"
					this.widgets.antivirusCtavStatus.text = "Scanner is disabled"
				}
				else if (antivirusCtavStatusKey == "not in use") {
					this.widgets.antivirusCtavStatus.riskStatus = "never"
					this.widgets.antivirusCtavStatus.text = "Not in use"
				}
				else if (antivirusCtavStatusKey == "signature update started") {
					this.widgets.antivirusCtavStatus.riskStatus = "never"
					this.widgets.antivirusCtavStatus.text = "Update started"
				}
				else if (['up to date', 'signatures successfully updated', 'signatures up to date, nothing to do'].indexOf(antivirusCtavStatusKey as string) != -1) {
					this.widgets.antivirusCtavStatus.riskStatus = "none"
					if ((moment().unix() - antivirusCtavStatusValue) < 86400) {
						this.widgets.antivirusCtavStatus.riskStatus = "none"
						this.widgets.antivirusCtavStatus.text = "Pattern is up to date"
					}
					else if ((moment().unix() - antivirusCtavStatusValue) >= 86400) {
						if (antivirusCtavStatusValue == 0) {
							this.widgets.antivirusCtavStatus.riskStatus = "never"
							this.widgets.antivirusCtavStatus.text = "No info"
						}
						else {
							this.widgets.antivirusCtavStatus.riskStatus = "medium"
							this.widgets.antivirusCtavStatus.text = T("Last Update:") + " " + moment(antivirusCtavStatusValue * 1000).format('DD.MM.YYYY')
						}
					}
					else if ((moment().unix() - antivirusCtavStatusValue) >= 86400 * 2) {
						this.widgets.antivirusCtavStatus.riskStatus = "high"
						this.widgets.antivirusCtavStatus.text = "Update is older than 2 days"
					}
				}
				else if (['not running'].indexOf(antivirusCtavStatusKey as string) != -1) {
					this.widgets.antivirusCtavStatus.riskStatus = "medium"
					this.widgets.antivirusCtavStatus.text = "Scanner has been stopped"
				}
				else if (['is deactivated', 'license expired', 'missing license'].indexOf(antivirusCtavStatusKey as string) != -1) {
					this.widgets.antivirusCtavStatus.riskStatus = "high"
					this.widgets.antivirusCtavStatus.text = "License error"
					if (antivirusCtavStatusKey == "is deactivated") {
						this.widgets.antivirusCtavStatus.text = "Update has been deactivated"
					}
				}
				else {
					this.widgets.antivirusCtavStatus.riskStatus = "never"
					this.widgets.antivirusCtavStatus.text = "No Info"
				}

				this.widgets.kernelStatus.data.Drops = kernelStatusDrops == 0 ? '-' : kernelStatusDrops
				this.widgets.kernelStatus.data.Rejects = kernelStatusRejects == 0 ? '-' : kernelStatusRejects
				this.widgets.kernelStatus.riskStatus = "never"

				if (kernelStatusDrops == 0) {
					this.widgets.kernelStatus.dropsText = "No drops"
				}
				else if (kernelStatusDrops > 0) {
					this.widgets.kernelStatus.dropsText = "Drops"
				}
				if (kernelStatusRejects == 0) {
					this.widgets.kernelStatus.rejectsText = "No rejects"
				}
				else if (kernelStatusRejects > 0) {
					this.widgets.kernelStatus.rejectsText = "Rejects"
				}

				this.widgets.alertStatus.data.Risk = countAlertsRisk
				this.widgets.alertStatus.data.NoRisk = countAlertsNoRisk

				if (countAlertsRisk == 0) {
					this.widgets.alertStatus.text = T("No alerts occured")
					this.widgets.alertStatus.riskStatus = "none"
				}
				else if (countAlertsRisk > 0) {
					this.widgets.alertStatus.text = countAlertsRisk + "(" + (countAlertsRisk + countAlertsNoRisk) + ")" + " " + T("alerts occured")
					this.widgets.alertStatus.riskStatus = "medium"
				}

				this.widgets.httpTrafficStatus.data.Traffic = countTraffic
				if (countTraffic != 0) {
					this.widgets.httpTrafficStatus.riskStatus = "none"
				}
				else {
					this.widgets.httpTrafficStatus.riskStatus = "never"
				}

				this.widgets.loginStatus.data.Logins = loginStatus

				if (loginStatus == 0) {
					this.widgets.loginStatus.text = "No Logins"
					this.widgets.loginStatus.riskStatus = "none"
				}
				else if (loginStatus > 5) {
					this.widgets.loginStatus.text = "Logins"
					this.widgets.loginStatus.riskStatus = "high"
				}
				else if (loginStatus > 0) {
					this.widgets.loginStatus.text = "Logins"
					this.widgets.loginStatus.riskStatus = "medium"
				}
			}
			else {
				this.widgets.blockedThreats.riskStatus = "no license"
				this.widgets.antivirusClamStatus.riskStatus = "no license"
				this.widgets.antivirusCtavStatus.riskStatus = "no license"
				this.widgets.kernelStatus.riskStatus = "no license"
				this.widgets.alertStatus.riskStatus = "no license"
				this.widgets.httpTrafficStatus.riskStatus = "no license"
				this.widgets.loginStatus.riskStatus = "no license"
			}

		},
		getBlockedThreadsLines: function(this:any) {

			let result = []
			
			if (this.widgets.blockedThreats.data.HttpVirus > 0) {
				result.push(this.widgets.blockedThreats.data.HttpVirus + " " + T('malicious HTTP-Requests blocked'))
			}
			if (this.widgets.blockedThreats.data.MailVirus > 0) {
				result.push(this.widgets.blockedThreats.data.MailVirus + " " + T('E-Mail viruses blocked'))
			}
			if (this.widgets.blockedThreats.data.KernelLog > 0) {
				result.push(this.widgets.blockedThreats.data.KernelLog + " " + T('C&C-Accesses logged'))
			}
			if (this.widgets.blockedThreats.data.KernelReject > 0) {
				result.push(this.widgets.blockedThreats.data.KernelReject + " " + T('C&C-Accesses blocked'))
			}
			if (this.widgets.blockedThreats.data.HttpUrlBlocked + this.widgets.blockedThreats.data.MailUrlBlocked > 0) {
				result.push((Number(this.widgets.blockedThreats.data.HttpUrlBlocked) + Number(this.widgets.blockedThreats.data.MailUrlBlocked)) + " " + T('harmful URLs blocked'))
			}
			if(result.length == 0) {
				result.push(T('The system is protected'))
			}
			return result
		},
		getWidgetBackground: function (this: any, status: string, area: string/* header|body */): string {
			let bgAssignment: any = {
				"never": {
					"header": "#808080",
					"body": "#A8A8A8"
				},
				"none": {
					"header": "#2DCC71",
					"body": "#81E0AA"
				},
				"medium": {
					"header": "#F0AD4E",
					"body": "#F6CE95"
				},
				"high": {
					"header": "#E74C3C",
					"body": "#F1948A"
				},
				"no license": {
					"header": "#777777",
					"body": "#868686"
				},
				"loading": {
					"header": "#777777",
					"body": "#868686"
				}
			}
			return bgAssignment[status][area]
		},
		getWidgetIcon: function (this: any, status: string): string {
			let iconAssignment: any = {
				"loading": "fal fa-spinner",
				"never": "fal fa-shield",
				"none": "fal fa-shield-check",
				"medium": "fal fa-exclamation-circle",
				"high": "fal fa-exclamation-triangle",
				"no license": "fal fa-shield"
			}
			return iconAssignment[status]
		},

		mapClamAvAndCtAvStatusToText: function (this: any, statusClam: string, statusCtAv: string) {
			const statusListByPriority = ["loading", "never", "high", "medium", "none", "no license"]
			const clamStatusPriority = statusListByPriority.indexOf(statusClam)
			const ctStatusPriority = statusListByPriority.indexOf(statusCtAv)
			if (clamStatusPriority > ctStatusPriority) {
				return this.widgets.antivirusClamStatus.text
			}
			else if (clamStatusPriority < ctStatusPriority) {
				return this.widgets.antivirusCtavStatus.text
			}
			else {
				if ((this.widgets.antivirusClamStatus.text == "No Info" && this.widgets.antivirusCtavStatus.text == "Update started") || (this.widgets.antivirusClamStatus.text == "Update started" && this.widgets.antivirusCtavStatus.text == "No info")) {
					return "Update started"
				}
				return this.widgets.antivirusClamStatus.text
			}

		},

		mapClamAvAndCtAvStatus: function (statusClam: string, statusCtAv: string) {
			const statusListByPriority = ["loading", "never", "high", "medium", "none", "no license"]
			const clamStatusPriority = statusListByPriority.indexOf(statusClam)
			const ctStatusPriority = statusListByPriority.indexOf(statusCtAv)
			if (clamStatusPriority > ctStatusPriority) {
				return statusClam
			}
			else if (clamStatusPriority < ctStatusPriority) {
				return statusCtAv
			}
			return statusClam

		},

		getRrdInfo: async function (this: any) {
			let objectId = useRouterStore().getObjectId
			objectId ? await objectStores.uscUtms.sendRrdMessage(this.activeAccountId, objectId, { "data": { "def": "cpu-0", "start": "-0h1440m", "end": "-0h0m" } }) : null;
			objectId ? await objectStores.uscUtms.sendRrdMessage(this.activeAccountId, objectId, { "data": { "def": "memory", "start": "-0h1440m", "end": "-0h0m" } }) : null;
			objectId ? await objectStores.uscUtms.sendRrdMessage(this.activeAccountId, objectId, { "data": { "def": "load", "start": "-0h1440m", "end": "-0h0m" } }) : null;
			objectId ? await objectStores.uscUtms.sendRrdMessage(this.activeAccountId, objectId, { "data": { "def": "df-data", "start": "-0h1440m", "end": "-0h0m" } }) : null;
			this.rrdLoaded = true
		},
		getBackupInfo: async function (this: any) {
			this.backupsTableData.loading = true
			delete this.utmVars.passwordSet
			delete this.utmVars.backups
			delete this.utmVars.GLOB_CLOUDBACKUP_TIME
			if (this.utmStatus.online == true) {
				await this.execDeviceAction(this.utm, 'extc global get variable', 'GLOB_CLOUDBACKUP_TIME')
			}
			let result: any = await requestHandler.request("GET", config.mgtApiUriNext + "/tenants/" + this.activeTenantDomain + "/utms/" + useRouterStore().getObjectId + "/backups?_=" + Date.now())
			this.backupsTableData.loading = false
			this.utmVars.backups = result
			// #33726
			this.queuedBackupLoading()
		},
		queuedBackupLoading: async function(this: any) {
			if(this.activeTab == "cloudBackup" && this.utmVars.GLOB_CLOUDBACKUP_TIME == undefined && this.utmStatus.online == true) {
				this.tabChanged = false

				// Waiting for 45 Seconds
				for (let iteration = 1; iteration <= 45; iteration++) {
					await timeHelpers.sleep(1000)
					// If the tab is switched, then the dialog should not be displayed
					if(this.tabChanged == true) break;
				}
				if(this.activeTab == "cloudBackup" && !this.tabChanged && this.utmVars.GLOB_CLOUDBACKUP_TIME == undefined) {
					dialogs.misc.errorDialog(this.activeAccountId, T("No UTM Connection"), T("The process takes unusually long. Please check your connection to the UTM."))	
				}
			}
		},
		makeBackup: async function (this: any) {
			let result = await objectStores.uscUtms.sendSpcliMessage(this.activeAccountId, this.utm.utmId, { "data": ["system", "cloudbackup", "store"] })
			this.getBackupInfo()
		},
		deleteBackup: async function (this: any, backupId: string) {
			let thisComponent: any = this

			dialogs.misc.confirmDialog(
				thisComponent.activeAccountId,
				T('Are you sure?'),
				T('Do you really want to delete this backup?'),
				async function () {
					await objectStores.uscUtms.sendSpcliMessage(thisComponent.activeAccountId, thisComponent.utm.utmId, { "data": ["system", "cloudbackup", "delete", "id", backupId] })
					useStore().commit(MutationTypes.addSubscriptionHook, {
						"accountId": thisComponent.activeAccountId,
						"hookKey": "utmDeleteBackup",
						"hookFunction": async function (message: any) {
							if (message?.data?.utmId == thisComponent?.utm?.utmId && message?.data?.clientContext ==
								"spcli-system-cloudbackup-delete" && message?.data?.status == 200) {
								thisComponent.getBackupInfo()
								useStore().commit(MutationTypes.removeModal, { "accountId": thisComponent.activeAccountId })
								useStore().commit(MutationTypes.deleteSubscriptionHook, {
									"accountId": thisComponent.activeAccountId,
									"hookKey": "utmDeleteBackup"
								})
							}

						}
					})
				},undefined,undefined,undefined,undefined,undefined,undefined,false
			)

		},
		restoreBackup: async function (this: any, backupId: string, ctime: string = "") {
			let thisComponent: any = this
			await objectStores.uscUtms.dialogs.utmEnterPinDialog(thisComponent.utm, ["system", "cloudbackup", "restore", "id", backupId, "ctime", ctime], undefined, T("Restore backup"), true, ctime)
			this.getBackupInfo()
		},
		initRrdGraphs: function (this: any) {
			let thisComponent: any = this,
				xCat: any[] = [],
				series: any[] = [],
				seriesCount: number = 0,
				dataEntryCount: number = 0,
				seriesColors: any[] = [
					{
						color: '#337AB7',
						borderColor: '#337AB7'
					},
					{
						color: '#F0AD4E',
						borderColor: '#F0AD4E'
					},
					{
						color: '#2DCC71',
						borderColor: '#2DCC71'
					},
					{
						color: '#E74C3C',
						borderColor: '#E74C3C'
					},
					{
						color: '#3498DB',
						borderColor: '#3498DB'
					},
					{
						color: '#673AB7',
						borderColor: '#673AB7'
					},
					{
						color: '#EB3976',
						borderColor: '#EB3976'
					},
					{
						color: '#FF5722',
						borderColor: '#FF5722'
					},
					{
						color: '#16B8A9',
						borderColor: '#16B8A9'
					},
					{
						color: '#795548',
						borderColor: '#795548'
					}
				],
				propertyToChart: any = {
					"cpu0Info": "cpu0Chart",
					"loadInfo": "loadChart",
					"memoryInfo": "memoryChart",
					"dfDataInfo": "dfDataChart"
				}


			for (let property in this.rrdInfo) {
				series = []
				seriesCount = 0
				dataEntryCount = 0
				xCat = []
				if (this.rrdInfo[property] != undefined && property != "timestamp") {
					this.rrdInfo[property].meta.legend.forEach(function (name: string) {
						let seriesObject: any = {
							"name": stringHelpers.capitalizeFirstLetter(name),
							"color": seriesColors[seriesCount].color,
							"borderColor": seriesColors[seriesCount].borderColor,
							"data": thisComponent.rrdInfo[property].data.map(function (dataEntry: number[]) {
								let result: any = moment((thisComponent.rrdInfo[property].meta.start * 1000) + (dataEntryCount * (thisComponent.rrdInfo[property].meta.step * 1000))).format('HH:mm:ss')
								xCat.push(result)
								dataEntryCount++
								return dataEntry[seriesCount]
							})
						}

						series.push(seriesObject)
						seriesCount++
					})

					let chartData: any = {
						chart: {
							type: 'line',
							height: 250,
							options: {
								responsive: true,
								maintainAspectRatio: false
							}
						},
						credits: false,
						exporting: false,
						title: {
							text: ''
						},
						xAxis: {
							categories: xCat
						},

						plotOptions: {
							series: {
								stacking: 'normal'
							}
						},
						tooltip: {
							formatter: function (this: any) {
								return (["dfDataInfo", "memoryInfo"].indexOf(property) == -1 ? ("<strong>" + this.point.series.name + "</strong><br> " + this.y + "%") : ("<strong>" + this.point.series.name + "</strong><br> " + numberHelpers.formatBytes(this.y, 2).value + numberHelpers.formatBytes(this.y).unit))
							}
						},
						series: series
					}

					if (thisComponent[propertyToChart[property]] == undefined || thisComponent[propertyToChart[property]] == false) {
						thisComponent[propertyToChart[property]] = Highcharts.chart(propertyToChart[property], chartData)
					}
					else {
						thisComponent[propertyToChart[property]].update(chartData)
					}
				}
				else if (thisComponent[propertyToChart[property]] == undefined && property != "timestamp") {
					thisComponent[propertyToChart[property]] = false
				}
			}
		},
		"getUtmState": async function (this: any, refresh: boolean = false) {
			this.utmVars.state.loading = true
			let objectId = useRouterStore().getObjectId
			if (this.$hasAccountRole('__SUPPORT__')) {
				if (refresh || this.utmVars.state.rejectsHistogramm.loading) {
					this.utmVars.state.rejectsHistogramm.InfoText = ""
					let totalRejects = 0
					try {
						let histogrammData = objectId ? await objectStores.uscUtms.getUtmRejectsHistogramm(this.activeAccountId, objectId, this.utmVars.state.period) : { result:[] }
						var histogrammChartOptions = {
							type: 'line',
							data: {
								labels: <string[]>[],
								datasets: [{
									label: '',
									data: <number[]>[],
									backgroundColor: 'rgba(235, 76, 54, 0.2)',
									borderColor: 'rgba(235, 76, 54, 1)',
									fill: "start"
								}]
							},
							options: {
								responsive: true,
								maintainAspectRatio: false,
								elements: { line: { tension: 0.4 } },
								legend: { display: false },
								scales: {
									xAxes: [{ stacked: true }],
									yAxes: [{
										ticks: { beginAtZero: true },
										scaleLabel: { display: true, labelString: "Rejects" }
									}]
								}
							}
						}

						if (histogrammData.result?.length > 0) {
							histogrammData.result.forEach((bucket: { "count": number, "interval": number }) => {
								let bucketTime = bucket.interval
								let rejectsCount = bucket.count
								let bucketDate = timeHelpers.formatDate(bucketTime, timeHelpers.getDateFormatI18n(LANG, ["lastday", "today"].indexOf(this.utmVars.state.period) != -1))

								totalRejects = totalRejects + rejectsCount

								histogrammChartOptions.data.datasets[0].data.push(rejectsCount)
								histogrammChartOptions.data.labels.push(bucketDate)
							});
							this.utmVars.state.rejectsHistogramm.loading = false

							let thisChart = Chart.getChart("0") || Chart.getChart("utm-rejects-histogramm") || undefined
							if(thisChart) {
								thisChart.destroy()
							}

							var context = (<HTMLCanvasElement>document.getElementById("utm-rejects-histogramm"))!.getContext("2d")
							//@ts-ignore
							var chart = new Chart(context, histogrammChartOptions)
						}
						else {
							this.utmVars.state.rejectsHistogramm.loading = false
							this.utmVars.state.rejectsHistogramm.InfoText = T("No data found")
						}
						this.utmVars.state.totalRejects.value = totalRejects
						this.utmVars.state.totalRejects.loading = false
					}
					catch (e: any) {
						console.error(e)
					}
				}

				if (refresh || this.utmVars.state.latestRejects.loading) {
					let rejectsData = objectId ? await objectStores.uscUtms.getUtmLatestRejects(this.activeAccountId, objectId, this.utmVars.state.period) : {result:[]}
					if (rejectsData.result?.length > 0) {
						let titles: any = [
							{
								"id": "connectedOn",
								"text": "Connected on",
								"sortable": false,
								"width": 185
							},
							{
								"id": "ip",
								"text": "IP",
								"sortable": false,
								"width": 200
							},
							{
								"id": "lastContact",
								"text": "Last contact",
								"sortable": false,
								"width": 200
							},
							{
								"id": "server",
								"text": "Server",
								"sortable": false,
								"width": 200
							},
							{
								"id": "type",
								"text": "Type",
								"sortable": false
							}
						]
						let rows: any = []
						rejectsData.result.forEach((bucket: { "connectedOn": string, "ip": string, "isodate": number, "lastContact": number, "server": string, "type": string }) => {
							rows.push({
								data: {
									"connectedOn": bucket.connectedOn,
									"ip": bucket.ip,
									"lastContact": timeHelpers.formatDate(bucket.lastContact, timeHelpers.getDateFormatI18n(LANG, true)),
									"server": bucket.server,
									"type": bucket.type
								}
							})
						});


						this.utmVars.state.latestRejects.tableData.titles = titles
						this.utmVars.state.latestRejects.tableData.rows = rows
					}
					this.utmVars.state.latestRejects.tableData.loading = false

				}
			}
			this.utmVars.state.loading = false
		},
		"getWebsessionDialog": function (this: any) {
			objectStores.uscUtms.dialogs.renderNewWebSessionDialog(this.activeAccountId, useRouterStore().getObjectId)
		},
		refreshTask: async function (this: any) {
			let thisComponent: any = this
			if (this.initialized) {
				//this.refreshCount++
			}
			await timeHelpers.sleep(3000)
			this.refreshTask();
		},
		canUseFeature: function (utmId: UscUtm["utmId"], featureName: CanUseFeatureNames) { return objectStores.uscUtms.canUseFeature(utmId, featureName) },
		versionCompare: validationHelpers.versionCompare,
		hasLessThan3GBRam: function (item: UscUtm) { return objectStores.uscUtms.hasLessThan3GBRam(item) },
		disableCloudBackup: function (this: any) {
			dialogs.misc.confirmDialog(this.activeAccountId, T("Are you sure?"), T("Are you sure to disable the cloud-backup functionality?"), async () => {
				await objectStores.uscUtms.sendSpcliMessage(this.activeAccountId, this.utm.utmId, { "data": ["extc", "global", "set", "variable", "GLOB_CLOUDBACKUP_TIME", "value", "0"] })
				await objectStores.uscUtms.sendSpcliMessage(this.activeAccountId, this.utm.utmId, { "data": ["system", "config", "save"] })
				await objectStores.uscUtms.sendSpcliMessage(this.activeAccountId, this.utm.utmId, { "data": ["system", "update", "system"] })
			})
		},
		enableCloudBackup: function (this: any) {

			if(this.utmVars.passwordSet == false) {
				this.renderSetCloudBackupPassword(true)
			}
			else {
				dialogs.misc.confirmDialog(this.activeAccountId, T("Are you sure?"), T("Are you sure to enable the cloud-backup functionality?"), async () => {
					await objectStores.uscUtms.sendSpcliMessage(this.activeAccountId, this.utm.utmId, { "data": ["extc", "global", "set", "variable", "GLOB_CLOUDBACKUP_TIME", "value", "1"] })
					await objectStores.uscUtms.sendSpcliMessage(this.activeAccountId, this.utm.utmId, { "data": ["system", "config", "save"] })
					await objectStores.uscUtms.sendSpcliMessage(this.activeAccountId, this.utm.utmId, { "data": ["system", "update", "system"] })
				})
			}
		}
	},
	watch: {
		utm: {
			handler: function (this: any, utm: any, utmOld: any) {
				let thisComponent: any = this
				let tenantDomain: any = thisComponent.activeTenantDomain
				if (utm != undefined) {
					this.refreshCountDetails++
				}
			},
			deep: true
		},
		"utmVars.backups": function (this: any, backupsData: any[]) {
			let thisComponent: any = this
			const backupReqex = /SPCB\-(.*)?\-(....\-..\-.. ..:..:..)_?(.*)?/
			this.backupsTableData.loading = true
			this.backupsTableData.tableData.titles = [
				{
					"id": "time",
					"text": "Time",
					"sortable": false
				},
				{
					"id": "name",
					"text": "Name",
					"sortable": false
				},
				{
					"id": "version",
					"text": "Version",
					"sortable": false
				},
				{
					"id": "actions",
					"text": "Actions",
					"sortable": false
				}
			]




			this.backupsTableData.tableData.rows = (backupsData || []).filter((data: any) => {
				return data.cfgName ? true : false
			}).map((data: any) => {
				var name = data.cfgName
				data.ctime = Math.floor(new Date(data.uploadDate).getTime() / 1000).toString()
				if (!name) return
				const strippedData = name.match(backupReqex) || [, "", "", ""];
				const thisHost = strippedData[1] || "";
				const thisDate = strippedData[2] || "";
				const thisName = strippedData[3] || "";
				return {
					data: {
						"time": thisComponent.getDate(thisDate, true),
						"name": thisName,
						"version": data.cfgVersion,
						"actions": {
							"buttons": <Button[]>[
								new Button({
									"text": T("Download"),
									"title": T("Download config"),
									"icon": "fal fas-fw fa-download",
									"onClick": async function () {
										queries.misc.download(config.mgtApiUriNext + "/tenants/" + thisComponent.activeTenantDomain + "/utms/" + useRouterStore().getObjectId + "/backups/" + data.file_id, "utm_cloud_backup_" + thisDate + ".utm")
									},
									"disabled": false,
									"loading": false
								}),
								new Button({
									"text": T("Restore"),
									"title": !thisComponent.utm.pinState.enabled ? T('Dieses Feature ist auf dieser UTM nicht verfügbar, da die PIN deaktiviert ist.') : thisComponent.utm.pinState.locked ? T('Dieses Feature ist auf dieser UTM nicht verfügbar, da die PIN gesperrt ist.') : T("Restore"),
									"icon": "fal fas-fw fa-undo",
									"onClick": async function () {
										thisComponent.restoreBackup(data.file_id, data.ctime)
									},
									"disabled": !thisComponent.utm.pinState.enabled || thisComponent.utm.pinState.locked,
									"loading": false,
									"topRightCircle":{
										"icon":"fal fa-lock"
									}
								}),
								new Button({
									"text": T("Delete"),
									"title": T("Delete"),
									"icon": "fal fa-fw fa-trash",
									"onClick": async function () {
										thisComponent.deleteBackup(data.file_id)
									},
									"disabled": false,
									"loading": false
								})
							]
						}
					}
				}

			})
			this.backupsTableData.loading = false
		},
		"rrdInfo": {
			"handler": function (this: any, rrdInfo: any[]) {
				let thisComponent: any = this
				nextTick(function () {
					thisComponent.initRrdGraphs()
				})
			},
			"deep": true
		},
		scans: {
			handler: function (this: any, scansData: any[]) {
				let thisComponent: any = this
				let scansTableTitles: any[] = []
				let scansTableRows: any[] = []
				const current = moment()
				scansData.forEach(function (data: any) {
					// Show details button etc.
					let thisActionButtons: any = {}
					//


					let resultText: string = function () {
						let result = ""
						let invalidScanIpRegex = new RegExp(/Invalid scan ip. Request ip ([0-9.]*) is not equals with the scan ip ([0-9.]*)/i)
						if (data.status == 'RECEIVED') {
							result = data.result?.host?.ports?.length == 1 ? T('Found 1 open port') : sprintf(T('Found %s open ports'), data.result?.host?.ports?.length || "0")
						}
						else {
							let regexResult = invalidScanIpRegex.exec(data.statusText)

							if (regexResult?.length == 3) {
								result = sprintf(T('Invalid scan IP. Request IP %s is not equal to %s.'), regexResult[1], regexResult[2])
							}
							else {
								result = T(data.statusText)
							}
						}
						return result
					}()

					scansTableTitles = [
						{
							"id": "time",
							"title": "Time",
							"text": "Time",
							"sortable": true,
							"width": 145,
							"sortBy": "sortTime"
						},
						{
							"id": "interface",
							"title": "Interface",
							"text": "Interface",
							"sortable": true,
							"width": 145
						},
						{
							"id": "profile",
							"title": "Profile",
							"text": "Profile",
							"sortable": true,
							"width": 150
						},
						{
							"id": "status",
							"title": "Status",
							"text": "Status",
							"width": 125
						},
						{
							"id": "result",
							"title": "Result",
							"text": "Result",
						},
						{
							"id": "actions",
							"title": "Actions",
							"text": "Actions",
							"width": 180

						},
					]

					let thisRow: any = {
						"data": {
							"sortTime": data.lastUpdate,
							"time": thisComponent.getDate(data.lastUpdate, true),
							"interface": (data.status == 'RECEIVED' ? " (" + (data.result?.host?.address || "") + ")" : ""),
							"profile": "Securepoint Top Ports",
							"status": {
								labels: [
									{
										"title": T(stringHelpers.capitalizeFirstLetter(data.status)),
										"text": T(stringHelpers.capitalizeFirstLetter(data.status)),
										"class": data.status == 'ERROR' ? 'bg-red' : data.status == 'PENDING' ? 'bg-yellow' : '',
										"icon": data.status == 'ERROR' ? "fal fa-exclamation-triangle" : data.status == 'PENDING' ? "fal fa-bell" : "fal fa-info",
									}
								]
							},
							"result": resultText,
							"actions": ""
						},
						highlight: current.diff(moment.unix(data.lastUpdate), "hours") < 1 ? true : false
					}
					if (data.status == 'RECEIVED') {
						thisRow.data.actions = {
							"buttons": [{
								"text": T("Show details"),
								"title": T("Show details"),
								"icon": "fal fa-info",
								"onClick": "toggleSubTable",
								"disabled": false,
								"loading": false
							}]
						},
							thisRow.subTable = {
								"active": false,
								"titles": [
									{
										"id": "port",
										"title": "Port",
										"text": "Port"
									},
									{
										"id": "protocol",
										"title": "Protocol",
										"text": "Protocol"
									},
									{
										"id": "reason",
										"title": "Reason",
										"text": "Reason"
									},
									{
										"id": "service",
										"title": "Service",
										"text": "Service"
									},
								],
								"rows": []
							}

						data.result?.host?.ports?.forEach((portInfo: any) => {
							let services = ((thisComponent.scanPorts.filter((thisPortInfo: any) => {
								return thisPortInfo.port == portInfo.number
							}) || []).map((thisPortInfo: any) => {
								return thisPortInfo.usedBy
							}) || []).join(", ")
							thisRow.subTable.rows.push({
								data: {
									"port": portInfo.number,
									"protocol": portInfo.protocol,
									"reason": portInfo.reason,
									"service": services
								}
							})
						});
					}
					scansTableRows.push(thisRow)
				})
				//
				scansTableRows.sort(function (a, b) {
					return b.data.sortTime > a.data.sortTime ? 1 : -1
				})
				this.scansTable.titles = scansTableTitles
				this.scansTable.rows = scansTableRows
				nextTick(() => {
					this.scansLoading = false
					if (this.$refs?.scansTable) {
						this.$refs?.scansTable?.sortByTitle('time')
						this.$refs.scansTable.sorting.direction = "desc"
					}
				})
			},
			deep: true
		},
		"utmVars.state.period": function (this: any) {
			this.getUtmState(true)
		},
		"widgets.period": function (this: any) {
			this.getUsrData()
		}

	},
	created(this: any) {

	},
	mounted(this: any) {
		let thisComponent: any = this
		let tenantDomain: any = thisComponent.activeTenantDomain
		this.refreshTask()
		useStore().commit(MutationTypes.addSubscriptionHook, {
			"accountId": tenantHelpers.getAccountId(tenantDomain),
			"hookKey": "utmPage",
			"hookFunction": async function (message: any) {
				if (message.topic == "/usc/utm/" + thisComponent.objectId + "/message") {
					let jobId: string = message.data?.id
					let jobType: string = message.data?.type || "spcli"
					let jobContext: string = message.data?.clientContext.replace(jobType + '-', '').split('-').join(' ') || "UNKNOWN"
					let jobStatus: string = (message.data?.data?.state || message.data?.data?.hint?.status || (message.data?.status == 200 ? "OK" : "UNKNOWN") || "UNKNOWN").toUpperCase()
					let jobStatusText: string = message.data?.data?.message || message.data?.data?.hint?.message || message.data?.data?.[0]?.message || message.data?.data?.payload?.[0]?.message || ''
					let jobLastUpdate: number = message.timestamp || moment().unix()
					let jobIssued: undefined | number = message.data?.data?.start || undefined
					let jobEnded: undefined | number = message.data?.data?.end || undefined
					let jobQueue: string = "in"

					let isAbortable : boolean = true

					try {
						if (message.data?.status == 500 || message.data?.data.status == 500 || message.data?.data.status == 403) {

							const nonErrorsMsgs: string[] = [
								//"backup_restore: file already exists"
								"locked",
								"Invalid pin"
							]
							if (jobStatusText === "process_response: command failed: Rate-Limit exceeded." && jobContext == "system cloudbackup store") {
								jobStatusText = T("Process response: Command failed. You can only create a backup every 24h.")
							}

							if (nonErrorsMsgs.indexOf(jobStatusText) == -1) {
								frontendNotifications.addNotification(thisComponent.activeAccountId, {
									"accountId": thisComponent.activeAccountId,
									"content": {
										"title": {
											"text": thisComponent.utm.utmname + " | " + (message.data?.data.error || "An error occurred"),
											"icon": "fal fa-exclamation"
										},
										"body": {
											"content": jobStatusText
										}
									},
									"id": "utmDetailsError-" + timeHelpers.getUnixTimeStamp() * 1000,
									"timestamp": timeHelpers.getUnixTimeStamp() * 1000,
									"highlightNew": true,
									"state": "new",
									"product": "Unified Security Console"
								})

							}
						}

						if (jobContext == 'system info') {
							if (message.data?.status == 200) {
								jobStatus = "RECEIVED"
							}
							// add spcli-system-info if it does not exist
							if (!thisComponent.getUtmMessageData(thisComponent.utm, 'spcli-system-info')) {
								await thisComponent.$store.commit(MutationTypes.setObjectProperty, {
									"accountId": thisComponent.activeAccountId,
									"objectId": message.data?.utmId,
									"productType": "unifiedSecurityConsole",
									"objectType": "uscUtms",
									"queryType": "uscUtms",
									"property": "spcli-system-info",
									"objectPropertyPath": ["messages"],
									"value": {
										"timestamp": 0,
										"data": {
											"av": {},
											"cpu": {},
											"license": {},
											"memory": {},
											"network": {},
											"storage": {},
											"swap": {},
											"update": {},
											"timestamp": 0
										}
									}
								})
							}

							// update system info
							for (let property in message.data?.data) {
								// skip if timestamp is older than the one in utm object
								if (property == "timestamp" && message.data.data?.timestamp < thisComponent.getUtmMessageData(thisComponent.utm, 'spcli-system-info')?.timestamp) {
									continue;
								}

								await thisComponent.$store.commit(MutationTypes.setObjectProperty, {
									"accountId": thisComponent.activeAccountId,
									"objectId": message.data?.utmId,
									"productType": "unifiedSecurityConsole",
									"objectType": "uscUtms",
									"queryType": "uscUtms",
									"property": property,
									"objectPropertyPath": ["messages", "spcli-system-info", "data"],
									"value": message.data?.data[property]
								})
							}


							if(message.data?.data.update) {
								let updateInfo: any = message.data?.data
								await thisComponent.$store.commit(MutationTypes.setObjectProperty, {
									"accountId": thisComponent.activeAccountId,
									"objectId": message.data?.utmId,
									"productType": "unifiedSecurityConsole",
									"objectType": "uscUtms",
									"queryType": "uscUtms",
									"property": "data",
									"objectPropertyPath": ["messages", "merged-update-info"],
									"value": message.data?.data.update
								})
							}


							thisComponent.refreshCount++
						}

						if (['appmgmt start application', 'appmgmt stop application', 'appmgmt restart application'].indexOf(jobContext) != -1) {
							if (message.data?.status == 200) {
								thisComponent.execDeviceAction(thisComponent.utm, 'appmgmt status')
							}
						}

						if (jobContext == 'appmgmt status') {
							if (message?.data?.data?.length) {
								let appData: any[] = message.data?.data
								await thisComponent.$store.commit(MutationTypes.setObjectProperty, {
									"accountId": thisComponent.activeAccountId,
									"objectId": message.data?.utmId,
									"productType": "unifiedSecurityConsole",
									"objectType": "uscUtms",
									"queryType": "uscUtms",
									"property": "spcli-appmgmt-status",
									"objectPropertyPath": ["messages"],
									"value": message.data?.data
								})
								thisComponent.refreshCount++
							}
							// If the method is only called once there are render problems with the table. There is no scrollbar then
							await thisComponent.getAppsTableData()
							await thisComponent.getAppsTableData()
						}

						if (jobContext == 'extc global get') {
							if (message.data?.status == 200) {
								jobStatus = "RECEIVED"
							}
							// loop through data array
							thisComponent.execDeviceAction(thisComponent.utm, 'system cloudbackup password status get')
							message.data?.data.forEach(function (data: any) {
								thisComponent.utmVars[data.name] = data.value
							})
						}
						if (jobContext == 'extc global set') {
							if (message.data?.status == 200) {
								jobStatus = "RECEIVED"
							}
							thisComponent.backupsTableData.loading = true
							setTimeout(() => {
								thisComponent.getBackupInfo()
							}, 4000);

						}


						if (jobContext == 'system cloudbackup password') {
							if (message.data?.status == 200) {
								jobStatus = "RECEIVED"
							}
							if (message.data?.data[0].state === 1) {
								thisComponent.utmVars.passwordSet = true
								//thisComponent.execDeviceAction(thisComponent.utm,'system cloudbackup get')
							}
							else {
								thisComponent.utmVars.passwordSet = false
							}
						}
						if (jobContext == 'system cloudbackup get') {
							if (message.data?.status == 200) {
								jobStatus = "RECEIVED"
							}
							thisComponent.utmVars.backups = message.data?.data
						}
						if(jobContext == 'system cloudbackup store' && (message.data?.status == 200 || message.data?.data?.status == 200)) {
							thisComponent.getBackupInfo()
						}

						if (jobContext == 'system reboot') {
							if (jobStatus != "OK") {
								if(!vue?.$refs?.modals?.$refs?.modal) {
									throw "system reboot"
								}
							}
							if (jobStatusText == "UTM is going down for reboot") {
								jobStatus = "REBOOTING"
							}
							else if (jobStatusText == "rollback") {
								jobQueue = "in"
								jobStatus = "REBOOTED"
								let rebootDialogText = T('The UTM rebooted was successful')
								if (jobEnded && jobIssued) {
									jobStatusText = T("Time to reboot:") + ' ' + moment.duration(moment.unix(jobEnded).diff(moment.unix(jobIssued))).asSeconds() + ' ' + T('seconds');
									if (moment.duration(moment.unix(jobEnded).diff(moment.unix(jobIssued))).asSeconds() >= 0) {
										rebootDialogText = T('The reboot of the UTM took') + ' ' + moment.duration(moment.unix(jobEnded).diff(moment.unix(jobIssued))).asSeconds() + ' ' + T('seconds');
									}
								}

								dialogs.misc.confirmDialog(
									thisComponent.activeAccountId,
									T('Reboot'),
									rebootDialogText,
									async function () {
										useStore().commit(MutationTypes.removeModal, { "accountId": thisComponent.activeAccountId })
									},undefined,undefined,undefined,false
								)
							}
						}

						if (jobContext == 'system config factorysettings') {
							if (jobStatus != "OK") {
								if(!vue?.$refs?.modals?.$refs?.modal) {
									throw "system config factorysettings"
								}
							}
							if (jobStatusText == "UTM restored the factory settings") {
								jobQueue = "in"
								jobStatus = "ACKNOWLEDGED"
								if (jobEnded && jobIssued) {
									jobStatusText = T("Time to reset:") + ' ' + moment.duration(moment.unix(jobEnded).diff(moment.unix(jobIssued))).asSeconds() + ' ' + T('seconds');

									dialogs.misc.confirmDialog(
										thisComponent.activeAccountId,
										T('Rollback'),
										T('The factory reset of the UTM took') + ' ' + moment.duration(moment.unix(jobEnded).diff(moment.unix(jobIssued))).asSeconds() + ' ' + T('seconds'),
										async function () {
											useStore().commit(MutationTypes.removeModal, { "accountId": thisComponent.activeAccountId })
										}
									)
								}
							}
						}
						if (jobContext == 'cpu 0') {
							if (message.data?.status == 200) {
								jobStatus = "OK"
							}
							if (jobStatus != "OK") {
								throw "Error"
							}
							thisComponent.rrdInfo.cpu0Info = {
								"meta": message.data?.data?.payload?.meta,
								"data": message.data?.data?.payload?.data
							}
							thisComponent.rrdInfo.timestamp = message.timestamp
						}
						if (jobContext == 'load') {
							if (message.data?.status == 200) {
								jobStatus = "OK"
							}
							if (jobStatus != "OK") {
								throw "Error"
							}
							thisComponent.rrdInfo.loadInfo = {
								"meta": message.data?.data?.payload?.meta,
								"data": message.data?.data?.payload?.data
							}
							thisComponent.rrdInfo.timestamp = message.timestamp
						}
						if (jobContext == 'memory') {
							if (message.data?.status == 200) {
								jobStatus = "OK"
							}
							if (jobStatus != "OK") {
								throw "Error"
							}
							thisComponent.rrdInfo.memoryInfo = {
								"meta": message.data?.data?.payload?.meta,
								"data": message.data?.data?.payload?.data
							}
							thisComponent.rrdInfo.timestamp = message.timestamp
						}
						if (jobContext == 'df data') {
							if (message.data?.status == 200) {
								jobStatus = "OK"
							}
							if (jobStatus != "OK") {
								throw "Error"
							}
							thisComponent.rrdInfo.dfDataInfo = {
								"meta": message.data?.data?.payload?.meta,
								"data": message.data?.data?.payload?.data,
								"tooltip": {
									"formatter": function (this: any) {
										return numberHelpers.formatBytesToMb(Number(this.y), 5);
									}
								}
							}
							thisComponent.rrdInfo.timestamp = message.timestamp
						}

						if (jobContext == 'system upgrade finalize' || jobContext.indexOf("eula") != -1 || jobContext.indexOf("privacy") != -1) {
							thisComponent.execDeviceAction(thisComponent.utm, 'system info')
						}

						if (jobContext == 'merged update info') {
							if (message.data?.status == 200) {
								jobStatus = "OK"
							}
							if (jobStatus != "OK") {
								throw "merged update info"
							}
							let updateInfo: any = message.data?.data
							await thisComponent.$store.commit(MutationTypes.setObjectProperty, {
								"accountId": thisComponent.activeAccountId,
								"objectId": message.data?.utmId,
								"productType": "unifiedSecurityConsole",
								"objectType": "uscUtms",
								"queryType": "uscUtms",
								"property": "data",
								"objectPropertyPath": ["messages", "merged-update-info"],
								"value": updateInfo
							})
						}
						if (jobContext == 'merged reboot info') {
								await objectStores.uscUtms.getObjectFromApi(thisComponent.activeAccountId, thisComponent.objectId)
							if (message.data?.status == 200) {
								jobStatus = "OK"
							}
							if (jobStatus != "OK") {
								if(!vue?.$refs?.modals?.$refs?.modal) {
									throw "system reboot"
								}
							}
							let rebootInfo: any = message.data?.data
							await thisComponent.$store.commit(MutationTypes.setObjectProperty, {
								"accountId": thisComponent.activeAccountId,
								"objectId": message.data?.utmId,
								"productType": "unifiedSecurityConsole",
								"objectType": "uscUtms",
								"queryType": "uscUtms",
								"property": "data",
								"objectPropertyPath": ["messages", "merged-reboot-info"],
								"value": rebootInfo
							})
						}
						if (jobType == 'scan' && jobContext == 'state finished') {
							await thisComponent.getScansData(true);
						}

						jobContext = jobContext.replace('-', ' ').replace('variable', ' ')
						jobContext = thisComponent.contextMap[jobContext] || jobContext

						let operationsLogEntry: any = {
							"jobId": jobId,
							"queue": jobQueue,
							"lastUpdate": jobLastUpdate,
							"type": jobType,
							"status": jobStatus,
							"statusText": jobStatusText,
							"context": jobContext,
							"issued": jobIssued,
							"ended": jobEnded
						}
						if (operationsLogEntry.jobId) {

							let index = thisComponent.operationsLog.findIndex(function (entry: any) {
								return entry.jobId == operationsLogEntry.jobId
							})
							if (index != -1) {
								thisComponent.operationsLog[index].status = "ACKNOWLEDGE"
							}

							thisComponent.operationsLog.unshift(operationsLogEntry)
						}
					}
					catch (e: any) {
						console.error('COULD NOT EXECUTE JOB', e)

						dialogs.misc.confirmDialog(
							thisComponent.activeAccountId,
							T('Error') + ' - ' + T('Could not execute job') + ': ' + jobType,
							'<code>' + e + '</code>',
							async function () {
								useStore().commit(MutationTypes.removeModal, { "accountId": thisComponent.activeAccountId })
							},
							undefined,
							"OK",
							"fal fa-check"
						)

					}
				}
			}
		})
		this.getUtmInfo()

	},
	beforeCreate(this: any) {
	},
	beforeUnmount(this: any) {
		this.initialized = false
		useStore().commit(MutationTypes.deleteSubscriptionHook, {
			"accountId": this.activeAccountId,
			"hookKey": "utmPage"
		})
	},
	components: {
		"loader": loaderComponent,
		"table2": table2Component,
		"itemlist-item": itemlistItemComponent,
		"input-date": inputDateComponent,
		"googleMap": googleMapComponent,
		"cronojoblist": cronojoblistComponent,
		"buttonComponent":buttonComponent,
		"tabs": tabs,
		"tableNext": tableNext,
		"input-number": inputNumber
	}
}
export default utmDetailsComponent