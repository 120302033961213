declare var mediabrowser : any
import { useGlobalMixin } from "@/mixins/mixins.global";
import { ActionTypes } from "@/store/vuex.store";
import { nextTick } from "vue";
import { useStore } from '@/store/vuex.store';
import type { PromiseObject } from "@/helpers/helpers.promises";
import queries from "@/queries/queries";
import promiseHelpers from "@/helpers/helpers.promises";
import { T } from "@/classes/i18n";
import loaderComponent from "./loader.vue";
import Dropzone from "dropzone"
import config from "@/classes/config";
import timeHelpers from "@/helpers/helpers.time";
import getterHelpers from "@/helpers/helpers.getters";

const mediabrowserComponent = {
    "mixins": [useGlobalMixin()],
    "name":"mediabrowser",
    "template": `<div 
                    id="mediabrowser" 
                    ondragover="$('#mediabrowser').addClass('dragging')"
                    ondragleave="$('#mediabrowser').removeClass('dragging')"
                    ondrop="$('#mediabrowser').removeClass('dragging')"
                >

        <div class="padding-xs-y">
            <div class="box box-shadow padding-xs-2">
                <a class="btn btn-primary" id="mediabrowserUploadButton">
                    {{T('Upload new image')}} (JPG/PNG)
                </a><br>
                Max. 5MB
                <hr class="margin-xs-y"/>
                <div class="row">
                    <template v-if="images">
                        <template v-for="image in images.items">
                            <div class="col-xs-12 col-md-8 col-lg-6 padding-xs">
                                <div class="media box-shadow padding-xs-2" :class="{'selected':selection == image.imageId}">
                                    <template v-if="selection == image.imageId">
                                        <a class="deletebutton" v-on:click="deleteImage(image.imageId)">
                                            <i class="fal fa-trash"></i>
                                        </a>
                                    </template>
                                    <a class="frame" v-on:click="selectImage(image.imageId)">
                                        <img :src="imageMap[image.imageId] || null">
                                    </a>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <template v-if="properties.mode == 'select'">
                    <div class="row">
                        <div class="col-xs-24">

                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div id="mediabrowserDropZone">
            <div class="uploadcontent">
                <i class="fal fa-upload"></i> ${T('Upload file')}
            </div>
        </div>
    </div>`,
    "data":() => {
        return {
            "selection":"",
            "upload":undefined,
            "imageMap":{}
        }
    },
    "computed": {
        "images": function (this: any) {
            return useStore().getters.getObjectTypeStore({
                "accountId": this.activeAccountId,
                "objectType": "images"
            })
        },
    },
    "props": {
        "properties":{
            required:false,
            default:() => {
                return {
                }
            }
        }
    },
    "methods": {
        "getImageList": async function(this:any) {
            await useStore().dispatch(ActionTypes.getObjectInfos,{
                "accountId":this.activeAccountId,
                "objectTypes":["images"]
            })
        },
        "getImages": async function(this:any) {
            let promises : PromiseObject = {}
            this.images?.items?.forEach((image:any) => {
                promises[image.imageId] = queries.unifiedSecurity.getObjectInfo(this.activeAccountId,"images",image.imageId,undefined,undefined,undefined,[{"property":"type","value":"data-uri"}])
            });
            let imageMap = await promiseHelpers.resolvePromiseObject(promises)
            this.imageMap = imageMap
        },
        "selectImage": function(this:any,imageId:string) {
            this.selection = imageId
        },
        "deleteImage": async function(this:any,imageId:string) {
            await useStore().dispatch(ActionTypes.deleteObject,{
                "accountId":this.activeAccountId,
                "productType":"unifiedSecurity",
                "objectIdProperty":"imageId",
                "objectId":imageId,
                "objectType":"images"
            })
            await this.getImageList()
            this.selection = ""
        },
        "initDropZone": function(this:any) {
            let dropZone = {
                "acceptedFiles":'image/jpg,image/jpeg,image/png',
                "url":"/sms-mgt-api/api/"+config.mgtApiVersion+"/tenants/" + this.activeTenantDomain + "/images",
                "clickable": '#mediabrowserUploadButton',
                "uploadMultiple": false,
                "paramName": "image",
                "maxFilesize":5,
                "previewTemplate": `
                    <div class="dz-preview dz-file-preview">
                        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                        <div class="dz-success-mark"><span><i class="fal fa-check color-lightgreen"></i> ${T('FILE SUCCESSFULLY UPLOADED')}</span></div>
                        <div class="dz-error-mark"><span><i class="fal fa-times color-red"></i> ${T('ERROR UPLOADING FILE')}</span></div>
                        <br>
                        <div class="dz-details">
                            <div class="dz-filename"><strong>File:</strong> <span data-dz-name></span></div>
                        </div>
                        <div class="dz-error-message"><strong>Error:</strong> <span data-dz-errormessage></span></div>
                        <br>
                        <div class="dz-close empty"></div>
                    </div>`
            }
            let myDropzone = new Dropzone('#mediabrowserDropZone', dropZone)

            myDropzone.on('success',async () => {
                await this.getImageList()
                nextTick(() => {
                    this.getImages()
                })
                $('.dz-preview.dz-processing.dz-image-preview.dz-success.dz-complete').remove()
                $('#mediabrowserDropZone').removeClass('dz-started')
            })

            myDropzone.on('error', async () => {
                $('.dz-error-message').append("<p class='margin-xs-t-2'>"+T('This message disappears in')+" <span class='secondsCounter'>5</span> "+T('seconds')+"</p>")
                await timeHelpers.sleep(1000)
                $('.secondsCounter').text("4")
                await timeHelpers.sleep(1000)
                $('.secondsCounter').text("3")
                await timeHelpers.sleep(1000)
                $('.secondsCounter').text("2")
                await timeHelpers.sleep(1000)
                $('.secondsCounter').text("1")
                await timeHelpers.sleep(1000)
                $('.dz-preview.dz-file-preview.dz-error.dz-complete').remove()
                $('#mediabrowserDropZone').removeClass('dz-started')
            })
        }
    },
    "mounted":async function(this:any) {
        let modal = getterHelpers.useStore().getters.getActiveModal(this.activeAccountId)
        if(modal?.buttons && modal?.buttons[1]  !== undefined) {
            modal.buttons[1].disabled = true
        }
        await this.getImageList()
        nextTick(() => {
            this.getImages()
            // INIT Dropzone
            this.initDropZone()
        }) 
    },
    "watch": {
        "selection": function(this:any) {
            let modal = getterHelpers.useStore().getters.getActiveModal(this.activeAccountId)
            if(modal?.buttons && modal?.buttons[1] !== undefined) {
                if(this.selection == "") {
                    modal.buttons[1].disabled = true
                } 
                else {
                    modal.buttons[1].disabled = false
                }
            }
        }
    },
    "components": {
        "loader":loaderComponent
    }
}
export default mediabrowserComponent
