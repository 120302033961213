<script setup lang="ts">
    //@ts-ignore
    import type Highcharts from "highcharts"
    import { computed, onMounted, ref, watch } from 'vue';
    import jsonHelpers from '@/helpers/helpers.json';
    import { useStore } from '@/store/vuex.store';
    import { T } from '@/classes/i18n';
    import loaderComponent from '../components/loader.vue';
    import tenantHelpers from '@/helpers/helpers.tenants';
    import type { License } from '@/classes/unifiedSecurity/licenses';
    import moment from 'moment';
    import stringHelpers from '@/helpers/helpers.strings';
    import mixinHelpers from '@/helpers/helpers.mixins';
    import validationHelpers from '@/helpers/helpers.validation';
    import licenseHelpers from '@/helpers/helpers.license';
    import products from '@/classes/objectTypes';
    import config from '@/classes/config';
    import requestHandler from '@/queries/requests';
    import type { TableEntryInfo } from "../components/tableNext.vue";
    import TableNext from "../components/tableNext.vue";
    import { sprintf } from "sprintf-js";

    const props = defineProps<{
        tenantWide: boolean
    }>();
    //@ts-ignore
    let gaugeOptions = <Highcharts.Options>{
        chart: {
            type: "solidgauge",
            height: "80%"
        },
        title: null,
        exporting: false,
        tooltip: false,
        pane: {
            center: ['50%', '65%'],
            size: '120%',
            startAngle: -120,
            endAngle: 120,
            border: null,
            background: {
                backgroundColor: 'none',
                innerRadius: '80%',
                outerRadius: '100%',
                shape: 'arc',
                borderColor: "none",
                borderWidth: 0
            }
        },

        // the value axis

        yAxis: {
            allowDecimals:false,
            stops: [
                [0.1, '#E74C3C']
            ],

            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            min: 0,
            max: 0,
            tickPositions:[0,0],
            labels: {
                enabled: true,
                y: 0,
                distance: 10,
            },
            tickAmount:2,
            showLastLabel: true,
        },
        series: [{
            name: 'Speed',
            color: "#f10",
            data: [0],
            innerRadius: '80%',
            outerRadius: '100%',
            dataLabels: {
                y:20,
                format:'<div><span style="font-size:3em;">{y}</span></div>',
                position:'center',
                useHtml:true,
                verticalAlign:"middle"
            }
        }],
        credits: false
    }
//@ts-ignore
    let basicBarOptions = <Highcharts.Options>{
        chart: {
            type: 'bar',
            height: "80%"
        },
        title: {
            text: null,
            align: 'left'
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        xAxis: {
            allowDecimals: false,
            categories: [],
            title: {
                text: null
            },
            gridLineWidth: 1,
            lineWidth: 1
        },
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: null
            },
            labels: {
                overflow: 'justify'
            },
            gridLineWidth: 0
        },
        tooltip: {
            valueSuffix: ''
        },
        plotOptions: {
            bar: {
                borderRadius: '50%',
                dataLabels: {
                    enabled: true
                },
                groupPadding: 0.1,
                color: "#E74C3C",
                borderWidth: 0,
                borderColor: 'none',
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -40,
            y: 80,
            floating: true,
            borderWidth: 1,
            backgroundColor: 'none',
            shadow: true,
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            name: T("Devices"),
            borderWidth: 0,
            borderColor: 'none',
            pointWidth:16,
            data: []
        }]
    }


    let iosDevicesCounter = ref({
        "loading":true,
        "chartOptions": jsonHelpers.copyObject(gaugeOptions)
    })
    iosDevicesCounter.value.chartOptions.yAxis.stops[0][1] = "#3c3f46"

    let androidDevicesCounter = ref({
        "loading":true,
        "chartOptions": jsonHelpers.copyObject(gaugeOptions)
    })
    androidDevicesCounter.value.chartOptions.yAxis.stops[0][1] = "#91C747"

    let vpnsCounter = ref({
        "loading":true,
        "chartOptions": jsonHelpers.copyObject(gaugeOptions)
    })
    vpnsCounter.value.chartOptions.yAxis.stops[0][1] = "#e74c3c"

    let mdmLicensesCounter = ref({
        "loading":true,
        "chartOptions": jsonHelpers.copyObject(gaugeOptions)
    })
    let msLicensesCounter = ref({
        "loading": true,
        "chartOptions": jsonHelpers.copyObject(gaugeOptions)
    })
    let iosFirmwareChart = ref({
        "loading": true,
        "chartOptions": jsonHelpers.copyObject(basicBarOptions)
    })

    let androidFirmwareChart = ref({
        "loading": true,
        "chartOptions": jsonHelpers.copyObject(basicBarOptions)
    })
    
    let featuresTableSingle = ref({
        "loading":true,
        "tableData":{
            "titles": <any>[
                {
                    "id": "feature",
                    "text": "Feature",
                    "sortable": false
                },
                {
                    "id": "status",
                    "text": "Status",
                    "sortable": false
                },
            ],
            "rows": <any>[]
        }
    })
    const licensesLoading = ref(true)
    const featuresLoading = ref(true)

    const licensesSelectableColumns = <TableEntryInfo[]>[
        {
            "property": "accountname",
            "width": 200,
            "text": T("Tenant"),
            "displayType": "text",
            "getValue": (license: License) => {
                return license.accountName
            }
        },
        {
            "property": "license",
            "width": 200,
            "text": T("License"),
            "displayType": "link",
            "getValue": (license: License) => {
                return {
                    "text": license.name,
                    "href": "#show-tenant-" + license.accountId + ".sms-license-" + license.id + "-details",
                    "target": "_self"
                }
            }
        },
        {
            "property": "status",
            "width": 90,
            "text": T("Status"),
            "displayType": "status",
            "getSortValue": (license: License) => {
                return license.expirationTimestamp
            },
            "htmlTooltip": true,
            "getValue": (license: License) => {
                let color = license.state == "valid" ? "green" : "red"
                let tooltipText = license.state == "valid" ? T("Valid until:") + " " + moment.unix(license.expirationTimestamp).format("DD.MM.YYYY") : T(stringHelpers.capitalizeFirstLetter(license.state || ""))
                if (license.state == "valid" && license.expirationTimestamp && (moment.unix(license.expirationTimestamp).diff(moment(), 'days') + 1) <= 14) {
                    color = "yellow"
                    if (moment.unix(license.expirationTimestamp).startOf("day").diff(moment().startOf("day"), 'days') == 0) {
                        tooltipText = sprintf(T("License expires today"), (moment.unix(license.expirationTimestamp).diff(moment(), 'days') + 1)) + ' (' + moment.unix(license.expirationTimestamp).format("DD.MM.YYYY") + ')'
                    }
                    else if (moment.unix(license.expirationTimestamp).startOf("day").diff(moment().startOf("day"), 'days') == 1) {
                        tooltipText = sprintf(T("License expires tomorow"), (moment.unix(license.expirationTimestamp).diff(moment(), 'days') + 1)) + ' (' + moment.unix(license.expirationTimestamp).format("DD.MM.YYYY") + ')'
                    }
                    else {
                        tooltipText = sprintf(T("License expires in %s days"), (moment.unix(license.expirationTimestamp).diff(moment(), 'days') + 1)) + ' (' + moment.unix(license.expirationTimestamp).format("DD.MM.YYYY") + ')'
                    }
                }
                return {
                    "color": color,
                    "tooltip": tooltipText,
                }
            }
        },
        {
            "property": "id",
            "width": 200,
            "text": "ID",
            "displayType": "text",
            "getValue": (license: License) => {
                return license.id
            }
        },
        {
            "property": "type",
            "width": 90,
            "text": T("Type"),
            "displayType": "text",
            "getValue": (license: License) => {
                return license.type
            }
        }
    ]

    const activeAccountId = computed(() => {
        return useStore().state.session.activeAccountId
    })
    const isTenantReseller = computed(() => {
        return useStore().state.session.accounts[activeAccountId.value || ""]?.unifiedSecurity.account.isReseller || false
    })

    const searchTenantWide = computed(() => {
        return props.tenantWide && isTenantReseller.value
    })

    const allAccountIds = computed(() => {
        const allAccounts = useStore().state.session.accounts
        return searchTenantWide.value ? Object.keys(allAccounts) : [activeAccountId.value]
    })

    const accountIdsWithLicense = computed(() => {
        let filteredAccountIds = allAccountIds.value.filter((accountId) => {
            return licenseHelpers.hasOneOfLicenses(accountId || "", ["MDM","Mobile Security"])
        })
        return filteredAccountIds
    })
    
    const enterpriseDevices = computed(() => {
        let thisAndroidDevices: any = []
        accountIdsWithLicense.value.forEach((accountId) => {
            if (accountId) {
                thisAndroidDevices = config.canUseNewObjectType("iosDevices") ? thisAndroidDevices.concat(products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObjects(accountId)) : thisAndroidDevices.concat(useStore().getters.getObjects({
                    "accountId": accountId,
                    "productType": 'mobileSecurity',
                    "objectType": 'enterpriseDevices'
                }))
            }
        })
        return thisAndroidDevices.filter((device: any) => {
            return device?.name.indexOf('enterprises') != -1
        })
    })

    const licenses = computed(() => {
        let thisLicenses: any = []
        accountIdsWithLicense.value.forEach((accountId) => {
            thisLicenses = config.canUseNewObjectType("licenses") ? thisLicenses.concat(products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(accountId || "")) : thisLicenses.concat(useStore().getters.getObjects({
                "accountId": accountId,
                "productType": "unifiedSecurity",
                "objectType": "licenses",
            })).filter((license:License) => {
                return license.state = "valid"
            })
        });
        return thisLicenses
    })
    const mobileSecuritylicenses = computed(() => {
        return licenses.value.filter((license:License) => {
            return license.type == "Mobile Security" || license.type == "MDM"
        })
    })
    type FeaturesSingle = {
        "featureName": string,
        "value": string | boolean
    }
    type FeaturesMulti = {
        "accountId": string,
        "accountname": string,
        "applePushCert": string | false
        "appleDEP": string | false
        "appleVPP": string | false
        "androidEnterprise": boolean
        "androidZeroTouch": boolean
    }

    const featureInfo = ref<{
        [accountId: string]: any
    }>({})

    const features = computed(() => {
        const store = useStore()

        if(searchTenantWide.value) {
            let result : FeaturesMulti[] = []
            accountIdsWithLicense.value.forEach((accountId) => {
                if(accountId) {
                    result.push({
                        "accountId":accountId,
                        "accountname": useStore().state.session.accounts[String(accountId)]?.unifiedSecurity.account.accountname,
                        "applePushCert": featureInfo.value[accountId]?.ios?.apns?.notAfter || false,
                        "appleDEP": featureInfo.value[accountId]?.ios?.dep?.enabled ? featureInfo.value[accountId]?.ios.dep.expiry : false,
                        "appleVPP": featureInfo.value[accountId]?.ios?.vpp?.enabled ? featureInfo.value[accountId]?.ios.vpp.expiry : false,
                        "androidEnterprise": featureInfo.value[accountId]?.android?.enterprise?.enabled ? true : false,
                        "androidZeroTouch": featureInfo.value[accountId]?.android?.zeroTouch?.enabled ? true : false
                    })
                }
            })
            return result
        }
        else {
            return [
                {
                    "featureName":T("Apple Push Certificate"), 
                    "value":store.state.session.accounts[activeAccountId.value || ""].mobileSecurity.settings?.ios?.apns?.notAfter || false
                },
                {
                    "featureName":T("Apple DEP"), 
                    "value":store.state.session.accounts[activeAccountId.value || ""].mobileSecurity.settings?.ios?.dep?.enabled ? store.state.session.accounts[activeAccountId.value || ""].mobileSecurity.settings.ios.dep.expiry : false
                },
                {
                    "featureName": T("Apple VPP"), 
                    "value":store.state.session.accounts[activeAccountId.value || ""].mobileSecurity.settings?.ios?.vpp?.enabled ? store.state.session.accounts[activeAccountId.value || ""].mobileSecurity.settings.ios.vpp.expiry : false
                },
                {
                    "featureName":T("Android Enterprise"), 
                    "value":store.state.session.accounts[activeAccountId.value || ""].mobileSecurity.settings?.android?.enterprise?.enabled ? true : false
                },
                {
                    "featureName": T("Android Zero-Touch"), 
                    "value":store.state.session.accounts[activeAccountId.value || ""].mobileSecurity.settings?.android?.zeroTouch?.enabled ? true : false
                }
            ] as FeaturesSingle[]
        }
    })

    const mdmLicensesCount = computed(() => {
        let result = {
            "maxUsers":0,
            "assignedUsers":0
        }
        licenses.value?.filter((license:License) => {
            return license.type?.toLowerCase() == "mdm" && license.state == 'valid'
        }).forEach((license:License) => {
            result.assignedUsers += license.assignedUser
            result.maxUsers += mixinHelpers.getLicenseLimit(license)
        })
        return result
    })
    const mobsecLicensesCount = computed(() => {
        let result = {
            "maxUsers": 0,
            "assignedUsers": 0
        }
        licenses.value?.filter((license: License) => {
            return license.type?.toLowerCase() == "mobile security" && license.state == 'valid'
        }).forEach((license: License) => {
            result.assignedUsers += license.assignedUser
            result.maxUsers += mixinHelpers.getLicenseLimit(license)
        })
        return result
    })

    const enterpriseDevicesCount = ref(<number | undefined>undefined)
    const iosDevicesCount = ref(<number | undefined>undefined)
    const vpnsCount = ref(<number | undefined>undefined)
    const totalDevicesCount = computed(() => {
        return ((iosDevicesCount.value || 0) + (enterpriseDevicesCount.value || 0) + (vpnsCount.value || 0)) || 0
    })

    const initialized = ref(false)


    const updateIosCounter = () => {
        iosDevicesCounter.value.chartOptions.yAxis.max = totalDevicesCount.value
        iosDevicesCounter.value.chartOptions.yAxis.tickPositions = [0, totalDevicesCount.value]
        iosDevicesCounter.value.chartOptions.series[0].data = [iosDevicesCount.value]
        iosDevicesCounter.value.loading = false
    }

    const updateAndroidCounters = () => {
        androidDevicesCounter.value.chartOptions.yAxis.max = totalDevicesCount.value
        androidDevicesCounter.value.chartOptions.yAxis.tickPositions = [0, totalDevicesCount.value]
        androidDevicesCounter.value.chartOptions.series[0].data = [enterpriseDevicesCount.value]
        androidDevicesCounter.value.loading = false
    }
    const updateVpnCounters = () => {
        vpnsCounter.value.chartOptions.yAxis.max = totalDevicesCount.value
        vpnsCounter.value.chartOptions.yAxis.tickPositions = [0, totalDevicesCount.value]
        vpnsCounter.value.chartOptions.series[0].data = [vpnsCount.value]
        vpnsCounter.value.loading = false
    }
    const updateLicenseCounters = () => {
        mdmLicensesCounter.value.chartOptions.yAxis.max = mdmLicensesCount.value.maxUsers
        mdmLicensesCounter.value.chartOptions.yAxis.tickPositions = [0, mdmLicensesCount.value.maxUsers]
        mdmLicensesCounter.value.chartOptions.series[0].data = [mdmLicensesCount.value.assignedUsers]
        msLicensesCounter.value.chartOptions.yAxis.max = mobsecLicensesCount.value.maxUsers
        msLicensesCounter.value.chartOptions.yAxis.tickPositions = [0, mobsecLicensesCount.value.maxUsers]
        msLicensesCounter.value.chartOptions.series[0].data = [mobsecLicensesCount.value.assignedUsers]
        mdmLicensesCounter.value.loading = false
        msLicensesCounter.value.loading = false
    }

    const updateIosFirmwareCharts = (firmwareInfos: { [tenantDomain: string]: { [versionNumber: string]: number } }) => {
        let iosVersionMap: any = {
            [T("Unknown")]: 0
        }
        let chartTitles2: any = []
        let chartData2: any = []

        Object.keys(firmwareInfos).forEach((tenantDomain) => {
            let versions = firmwareInfos[tenantDomain]
            Object.keys(versions).forEach((versionNumber) => {
                let count = Number(versions[versionNumber])
                if (!iosVersionMap[versionNumber]) {
                    iosVersionMap[versionNumber] = 0
                }
                iosVersionMap[versionNumber] += count 
            })    
        })

        if (iosVersionMap[T("Unknown")] == 0) {
            delete iosVersionMap[T("Unknown")]
        }
        let keys = Object.keys(iosVersionMap).sort((a, b) => {
            if (a == T('Unknown')) return 1
            if (b == T('Unknown')) return -1
            let result = validationHelpers.versionCompare(a, b)
            if (result == false) result = 1
            return result
        }).reverse()

        for (const key in keys) {
            let firmware = keys[key]
            let thisData: any = {
                "name": firmware,
                "y": iosVersionMap[firmware]
            }
            if (firmware == T("Unknown")) thisData.color = "rgba(0, 0, 0, 0.1)"
            chartTitles2.push(firmware)
            chartData2.push(iosVersionMap[firmware])
        }
        iosFirmwareChart.value.chartOptions.series[0].data = chartData2
        iosFirmwareChart.value.chartOptions.xAxis.categories = chartTitles2
        iosFirmwareChart.value.loading = false

    }

    const updateAndroidFirmwareCharts = (firmwareInfos:{[tenantDomain:string]:{[versionNumber:string]:number}}) => {
        let androidVersionMap: any = {
            [T("Unknown")]: 0
        }
        let chartTitles2: any = []
        let chartData2: any = []
        Object.keys(firmwareInfos).forEach((tenantDomain) => {
            let versions = firmwareInfos[tenantDomain]
            Object.keys(versions).forEach((versionNumber) => {
                let count = Number(versions[versionNumber])
                if (androidVersionMap[versionNumber] == undefined) {
                    androidVersionMap[versionNumber] = 0
                }
                androidVersionMap[versionNumber] += count
            })
        })
        if(androidVersionMap[T("Unknown")] == 0) { 
            delete androidVersionMap[T("Unknown")]
        }
        let keys = Object.keys(androidVersionMap).sort((a, b) => {
            if (a == T('Unknown')) return 1
            if (b == T('Unknown')) return -1
            let result = validationHelpers.versionCompare(a, b)
            if(result == false) result = 1
            return result
        }).reverse()
        for (const key in keys) {
            let firmware = keys[key]
            let thisData: any = {
                "name": firmware,
                "y": androidVersionMap[firmware]
            }
            if (firmware == T("Unknown")) thisData.color = "rgba(0, 0, 0, 0.1)"
            chartTitles2.push(firmware)
            chartData2.push(androidVersionMap[firmware])
        }
        androidFirmwareChart.value.chartOptions.series[0].data = chartData2
        androidFirmwareChart.value.chartOptions.xAxis.categories = chartTitles2
        androidFirmwareChart.value.loading = false
    }


    const featuresSelectableColumnsMultiTenants = <TableEntryInfo[]>[
        {
            "property": "accountname",
            "width": 200,
            "text": T("Tenant"),
            "displayType": "text",
            "getValue": (featuresInfo: FeaturesMulti) => {
                return featuresInfo.accountname
            }
        },
        {
            "property": "applePushCert",
            "text": "Apple Push",
            "displayType": "status",
            "getValue": (featuresInfo: FeaturesMulti) => {
                let color = featuresInfo.applePushCert != false ? "green" : "grey"
                let tooltipText = typeof featuresInfo.applePushCert == "string" ?  T("Valid until:") + " " + moment(featuresInfo.applePushCert).format("DD.MM.YYYY") : T("Inactive")
                if (typeof featuresInfo.applePushCert == "string" && moment(featuresInfo.applePushCert).diff(moment(), 'days') <= 0) {
                    color = "red"
                    tooltipText = T("Expired")
                }
                return {
                    "color": color,
                    "tooltip": tooltipText
                }
            }
        },
        {
            "property": "appleDEP",
            "text": "Apple DEP",
            "displayType": "status",
            "getValue": (featuresInfo: FeaturesMulti) => {
                let color = featuresInfo.appleDEP != false ? "green" : "grey"
                let tooltipText = typeof featuresInfo.appleDEP == "string" ? T("Valid until:") + " " + moment(featuresInfo.appleDEP).format("DD.MM.YYYY") : T("Inactive")
                if (typeof featuresInfo.appleDEP == "string" && moment(featuresInfo.appleDEP).diff(moment(), 'days') <= 0) {
                    color = "red"
                    tooltipText = T("Expired")
                }
                return {
                    "color": color,
                    "tooltip": tooltipText
                }
            }
        },
        {
            "property": "appleVPP",
            "text": "Apple VPP",
            "displayType": "status",
            "getValue": (featuresInfo: FeaturesMulti) => {
                let color = featuresInfo.appleVPP != false ? "green" : "grey"
                let tooltipText = typeof featuresInfo.appleVPP == "string" ? T("Valid until:") + " " + moment(featuresInfo.appleVPP).format("DD.MM.YYYY") : T("Inactive")
                if (typeof featuresInfo.appleVPP == "string" && moment(featuresInfo.appleVPP).diff(moment(), 'days') <= 0) {
                    color = "red"
                    tooltipText = T("Expired")
                }
                return {
                    "color": color,
                    "tooltip": tooltipText
                }
            }
        },
        {
            "property": "androidEnterprise",
            "text": "Enterprise",
            "displayType": "status",
            "getValue": (featuresInfo: FeaturesMulti) => {
                return {
                    "color": featuresInfo.androidEnterprise != false ? "green" : "grey",
                    "tooltip": featuresInfo.androidEnterprise != false ? T("Active") : T("Inactive")
                }
            }
        },
        {
            "property": "androidZeroTouch",
            "text": "Zero-Touch",
            "displayType": "status",
            "getValue": (featuresInfo: FeaturesMulti) => {
                return {
                    "color": featuresInfo.androidZeroTouch != false ? "green" : "grey",
                    "tooltip": featuresInfo.androidZeroTouch != false ? T("Active") : T("Inactive")
                }
            }
        }
    ]

    const featuresSelectableColumnsSingleTenant = <TableEntryInfo[]>[
        {
            "property": "featureName",
            "width": 200,
            "text": T("Feature"),
            "displayType": "text",
            "getValue": (featureInfo: FeaturesSingle) => {
                return featureInfo.featureName
            }
        },
        {
            "property": "featured",
            "text": "Status",
            "displayType": "status",
            "getValue": (featuresInfo: FeaturesSingle) => {
                let color = featuresInfo.value != false ? "green" : "grey"
                let tooltipText = typeof featuresInfo.value == "string" ? T("Valid until:") + " " + moment(featuresInfo.value).format("DD.MM.YYYY") : featuresInfo.value ? T("Active") : T("Inactive")
                if (typeof featuresInfo.value == "string" && moment(featuresInfo.value).diff(moment(), 'days') <= 0) {
                    color = "red"
                    tooltipText = T("Expired")
                }
                return {
                    "color": color,
                    "tooltip": tooltipText
                }
            }
        }
    ]

    onMounted(async () => {
        //load tenant features
        const featureRes = await requestHandler.request("GET", `/sms-mgt-api/api/${config.mgtApiVersion}/tenants?props[]=android&props[]=ios&props[]=tenantDomain`)
        featureRes.tenants.forEach((tenant: any) => {
            const accountId = tenantHelpers.getAccountId(tenant.tenantDomain)
            featureInfo.value[accountId] = tenant
        })

        featuresLoading.value = false


        // GET METRICS
        let metricsURL = "/sms-mgt-api/api/2.0/metrics?metrics[]=mdm_android_device_version_distribution&metrics[]=mdm_ios_device_version_distribution&metrics[]=mdm_android_device_count&metrics[]=mdm_ios_device_count&metrics[]=mdm_vpn_device_count&version=2.2"  + (searchTenantWide.value ? "" : "&domains=" + activeAccountId.value + ".sms");
        let prometheusData = await requestHandler.request("GET", metricsURL)

        let result: any = {}
        prometheusData.split("\n").forEach((line: string) => {
            let lineResult: any = {}
            line = line.trim()
            line = line.length > 0 && line.charAt(0) != "#" ? line : "ignoreMe"

            if (line != "ignoreMe") {
                const property = line.substring(0, line.indexOf("{"))
                const amount = line.substring(line.indexOf("}") + 1).trim()
                const keyValuesString = line.substring(line.indexOf("{") + 1, line.indexOf("}"))
                // get keyValuePairs
                let keyValuePairs = keyValuesString.match(/(\w+)\s*=\s*("[^"]*"|[^,]*)/g) || []
                // loop through keyValuePairs and insert to lineResult
                keyValuePairs.forEach((keyValuePair) => {
                    const equalIndex = keyValuePair.indexOf('=');
                    if (equalIndex !== -1) {
                        try {
                            const key = keyValuePair.slice(0, equalIndex).trim();
                            const value = keyValuePair.slice(equalIndex + 1).trim().replace(/\"/g, '');
                            lineResult[key] = value
                        }
                        catch (e) {
                            console.error(e)
                        }
                    }
                })
                if (!result[property]) {
                    result[property] = <any>{}
                }
                if (lineResult.tenant_domain != undefined) {
                    if (!result[property][lineResult.tenant_domain]) {
                        result[property][lineResult.tenant_domain] = <any>{}
                    }
                    if (lineResult.version != undefined) {
                        if (!result[property][lineResult.tenant_domain][lineResult.version]) {
                            result[property][lineResult.tenant_domain][lineResult.version] = amount
                        }
                    }
                    else {
                        result[property][lineResult.tenant_domain] = amount
                    }
                }
            }
        })


        if(result.mdm_ios_device_count) {
            let tempIosDevicesCount = 0
            Object.keys(result.mdm_ios_device_count).forEach((tenantDomain) => {
                tempIosDevicesCount += Number(result.mdm_ios_device_count[tenantDomain])
            })
            iosDevicesCount.value = tempIosDevicesCount
        }

        if (result.mdm_android_device_count) {
            let tempAndroidDevicesCount = 0
            Object.keys(result.mdm_android_device_count).forEach((tenantDomain) => {
                tempAndroidDevicesCount += Number(result.mdm_android_device_count[tenantDomain])
            })
            enterpriseDevicesCount.value = tempAndroidDevicesCount
        }
        if (result.mdm_vpn_device_count) {
            let tempVpnDevicesCount = 0
            Object.keys(result.mdm_vpn_device_count).forEach((tenantDomain) => {
                tempVpnDevicesCount += Number(result.mdm_vpn_device_count[tenantDomain])
            })
            vpnsCount.value = tempVpnDevicesCount
        }
        updateIosCounter()
        updateAndroidCounters()
        updateVpnCounters()
        if (result.mdm_ios_device_version_distribution) {
            updateIosFirmwareCharts(result.mdm_ios_device_version_distribution)
        }   
        else {
            updateIosFirmwareCharts({
            [activeAccountId.value + '.sms']: {
                "0": 0
            }
        })
        }
        if (result.mdm_android_device_version_distribution) {
            updateAndroidFirmwareCharts(result.mdm_android_device_version_distribution)
        }   
        else {
            updateAndroidFirmwareCharts({
                [activeAccountId.value+'.sms']:{
                    "0":0
                }
            })
        }




    
    





        updateLicenseCounters()
        initialized.value = true
        licensesLoading.value = false
    })

</script>
<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="row padding-xs-b">
            <div class="col-xs-24 col-sm-12 col-md-8 col-lg-6 col-xl-4-8">
                <div class="box box-shadow" style="height:100%">
                    <div class="box-content-2">
                        <h5>iOS {{ T('Devices') }}</h5>
                        <template v-if="!iosDevicesCounter.loading">
                            <template v-if="iosDevicesCounter.chartOptions.series[0].data[0] == 0">
                                <div style="text-align:center; font-size:2em;padding:90px 0; opacity:0.7">
                                    {{ T('No Devices') }}
                                </div>
                            </template>
                            <template v-else>
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="iosDevicesCounter.chartOptions"></highcharts>
                            </template>
                        </template>
                        <template v-else>
                            <div style="text-align:center; padding:90px 0;">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-sm-12 col-md-8 col-lg-6 col-xl-4-8">
                <div class="box box-shadow" style="height:100%">
                    <div class="box-content-2">
                        <h5>Android {{ T('Devices') }}</h5>
                        <template v-if="!androidDevicesCounter.loading">
                            <template v-if="androidDevicesCounter.chartOptions.series[0].data[0] == 0">
                                <div style="text-align:center; font-size:2em;padding:90px 0; opacity:0.7">
                                    {{ T('No Devices') }}
                                </div>
                            </template>
                            <template v-else>
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="androidDevicesCounter.chartOptions"></highcharts>
                            </template>
                        </template>
                        <template v-else>
                            <div style="text-align:center; padding:90px 0;">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-sm-12 col-md-8 col-lg-6 col-xl-4-8">
                <div class="box box-shadow" style="height:100%">
                    <div class="box-content-2">
                        <h5>{{ T('Other devices (VPN)') }}</h5>
                        <template v-if="!vpnsCounter.loading">
                            <template v-if="vpnsCounter.chartOptions.series[0].data[0] == 0">
                                <div style="text-align:center; font-size:2em;padding:90px 0; opacity:0.7">
                                    {{ T('No Devices') }}
                                </div>
                            </template>
                            <template v-else>
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="vpnsCounter.chartOptions"></highcharts>
                            </template>
                        </template>
                        <template v-else>
                            <div style="text-align:center; padding:90px 0;">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="col-xs-24 col-sm-12 col-md-8 col-lg-6 col-xl-4-8">
                <div class="box box-shadow" style="height:100%">
                    <div class="box-content-2">
                        <h5>{{ T('Assigned devices') }} (MDM)</h5>
                        <template v-if="!mdmLicensesCounter.loading">
                            <template v-if="mdmLicensesCount.maxUsers == 0">
                                <div style="text-align:center; font-size:2em;padding:90px 0; opacity:0.7">
                                    {{ T('No License') }}
                                </div>
                            </template>
                            <template v-else>
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="mdmLicensesCounter.chartOptions"></highcharts>
                            </template>
                        </template>
                        <template v-else>
                            <div style="text-align:center; padding:90px 0;">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-sm-12 col-md-8 col-lg-6 col-xl-4-8">
                <div class="box box-shadow" style="height:100%">
                    <div class="box-content-2">
                        <h5>{{ T('Assigned devices') }} (Mobile Security)</h5>
                        <template v-if="!msLicensesCounter.loading">
                            <template v-if="mobsecLicensesCount.maxUsers == 0">
                                <div style="text-align:center; font-size:2em;padding:90px 0; opacity:0.7">
                                    {{ T('No License') }}
                                </div>
                            </template>
                            <template v-else>
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="msLicensesCounter.chartOptions"></highcharts>
                            </template>
                        </template>
                        <template v-else>
                            <div style="text-align:center; padding:90px 0;">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="row flexrow padding-xs-y">

            <div class="col-xs-24 col-lg-6">
                <div class="box box-shadow" style="height:100%">
                    <div class="box-content-2">
                        <h5>{{ T('Firmware distribution') }} iOS</h5>
                        <template v-if="!iosFirmwareChart.loading">
                            <template v-if="iosDevicesCounter.chartOptions.series[0].data[0] == 0">
                                <div style="text-align:center; font-size:2em;padding:130px 0; opacity:0.7">
                                    {{ T('No Devices') }}
                                </div>
                            </template>
                            <template v-else>
                                <highcharts class="highcharts-nobg" :options="iosFirmwareChart.chartOptions"></highcharts>
                            </template>
                        </template>
                        <template v-else>
                            <div style="text-align:center; padding:130px 0;">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="col-xs-24 col-lg-6">
                <div class="box box-shadow" style="height:100%">
                    <div class="box-content-2">
                        <h5>{{ T('Firmware distribution') }} Android</h5>
                        <template v-if="!androidFirmwareChart.loading">
                            <template v-if="androidDevicesCounter.chartOptions.series[0].data[0] == 0">
                                <div style="text-align:center; font-size:2em;padding:130px 0; opacity:0.7">
                                    {{ T('No Devices') }}
                                </div>
                            </template>
                            <template v-else>
                                <highcharts class="highcharts-nobg" :options="androidFirmwareChart.chartOptions"></highcharts>
                            </template>
                        </template>
                        <template v-else>
                            <div style="text-align:center; padding:130px 0;">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="col-xs-24 col-lg-12">
                <div class="box box-shadow" style="height:100%">
                    <div class="box-content-2">
                        <h5>{{ T('Features') }}</h5>

                        <template  v-if="!featuresLoading">
                            <TableNext
                                :has-options="false"
                                :is-filterable="false"
                                :is-searchable="false"
                                :object-list="features"
                                :selectable-columns="searchTenantWide ? featuresSelectableColumnsMultiTenants : featuresSelectableColumnsSingleTenant"
                                :max-height="250"
                            >
                            </TableNext>
                        </template>
                        <template v-else>
                            <div style="text-align:center; padding:122px 0;">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="row flexrow">
            <div class="col-xs-24 col-lg-24">
                <div class="box box-shadow" style="height:100%">
                    <div class="box-content-2">
                        <h5>{{ T('Licenses') }}</h5>
                        <template v-if="!licensesLoading">
                            <TableNext
                                :object-list="mobileSecuritylicenses"
                                :selectable-columns="licensesSelectableColumns"
                                :is-searchable="true"
                                :has-options="true"
                                :min-height="320"
                                :max-height="320"
                                :initialSortProperty="'status'"
                                :initialSortDirection="'ASC'"
                            ></TableNext>
                        </template>
                        <template v-else>
                            <div style="text-align:center; padding:122px 0;">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style lang="scss">

</style>