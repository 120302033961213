import ObjectType, { type AccountId, type GetPropertiesObjectList, type ItemlistItemDetail, type ObjectTypeObject, type ObjectTypePayload } from "../objectType";
import { type Label as ItemlistLabel } from "../objectType";
import Label from "@/templates/components/label/label";
import config from "@/classes/config";
import devLog from "@/classes/log";
import requestHandler from "@/queries/requests";
import apis from "@/classes/apis";
import jsonHelpers from "@/helpers/helpers.json";
import type { UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms";
import deviceHelpers from "@/helpers/helpers.devices";
import { T } from "@/classes/i18n";
import objectStores from "@/classes/init";
import getterHelpers from "@/helpers/helpers.getters";
import { ActionTypes, MutationTypes } from "@/store/vuex.store";
import ipaddr from "@/lib/ipaddr"
import websocketHandler from "@/classes/websocket";

import tenantHelpers from "@/helpers/helpers.tenants";
import products from "..";
import router from "@/router/router";
import dialogs from "@/dialogs/dialogs";
import { type TableEntryIcons } from "@/templates/components/tableNext.vue";
import useRouterStore from "@/router/routerStore";
import { type UtmNode } from "./nodes";
import Icon from "@/templates/components/icon/icon";
import timeHelpers from "@/helpers/helpers.time";
import ModalObject, { ModalObjectButton, type Modal } from "@/templates/components/modals/modalClass";

export type UpdateDnsEntry = {
    node: SunNkViewNode | CreateNode
    service: SunNkViewService | CreateService | CreateServiceGroup
}
export type UpdateDnsEntries = UpdateDnsEntry[]
export interface SunTopology extends ObjectType<SunTopology> {
    "id": string,
    "data":{
        "coreId":string,
        "properties": {
            id:string,
            alias:string,
            transferNetwork: string
            listenPort: number
            hostname: string
            interface: any
            interfaceId: number
            nodes: Record<string, CreateNode | CreateNodeGroup>
            services: Record<string, CreateService | CreateServiceGroup>
            dnsServer: {
                node: CreateNode | SunNkViewNode
                service: CreateService | SunNkViewService
            }[]
        }
        "satellites":SunSatellite[]
        "pools":SunRoadwarrior[]
    },
    "changes":{
        "data": SunTopologyDiff
    },
    "actions":{
        "isReversible":boolean,
        "isPublishable":boolean,
        "publishReason":"NO_CHANGES"|"CHANGES"|"ERRORS"
    }
}
export interface SunTopologyResponse {
    "id": string,
    "data":{
        "coreId":string,
        "properties": {
            id:string,
            alias:string,
            transferNetwork: string
            listenPort: number
            hostname: string
            interface: any
            interfaceId: number
            nodes: Record<string, CreateNode | CreateNodeGroup>
            services: Record<string, CreateService | CreateServiceGroup>
            dnsServer: {
                node: CreateNode | SunNkViewNode
                service: CreateService | SunNkViewService
            }[]
        }
        "satellites":SunSatellite[],
        "pools":SunRoadwarrior[]
    },
    "changes": SunTopologyDiff
}

export type CreateNode = {
    name: string
    node_address: string
    node_zone: { id: number; name: string }
}

export type CreateNodeGroup = {
    name?: string
    id?: number
    nodes: CreateNode[]
}

export type AddNodePayload = CreateNode | (CreateNodeGroup & { nodes: (CreateNode | { id: number })[] })

export type CreateService = {
    name: string
    protocol: string
    ct_helper?: string
    "src-ports"?: string[]
    "dst-ports"?: string[]
    "icmp-types"?: string[]
}

export type CreateServiceGroup = {
    name: string
    services: CreateService[]
}

export type SunTopologyDiff = {
    "addedNodes": string[]
    "removedNodes": string[]
    "changedNodes": SunTopologyChangedNode[]
    "changedSites": SunTopologyChangedSite[]

    "addedPools": string[]
    "removedPools": string[]
    "changedPools": SunTopologyChangedPool[]
}
export type SunTopologyChangedPool = {
    "poolId":string
    "rules": { 
        "added": string[]; 
        "removed": string[]; 
        "changed": string[] 
    }
}

export type SunTopologyChangedSite = {
    "serverId": string
    "clientId": string
    "rules": { 
        "added": string[]; 
        "removed": string[]; 
        "changed": string[] 
    }
}

export type SunTopologyChangedNode = {
    "nodeId": string
    "properties": {
        "added": Record<string, any>
        "removed": Record<string, any>
        "changed": Record<string, any>
    }
}

export interface SunSatellite {
    "id": string,
    "rules":SunRule[]
    "errors":string[]
    "alias"?:string
    "siteIps":{
        "clientAddress":string
        "serverAddress":string
    }
    "state":string,
    "toBeDeleted":boolean
}

export interface SunSatelliteOnAdd {
    "id": string
    "alias":string
}
export interface SunSatelliteOnUpdate {
    "alias":string
}
export enum PublishState {
    NOT_PUBLISHED = "NOT_PUBLISHED",
    PENDING = "PENDING",
    PUBLISHED = "PUBLISHED",
    FAILED = "FAILED" 
}

export interface SunNkViewInterface {
    id: number
    name: string
    description?: string
    isCoreCompatible:boolean
    type: number
    owner_id: number
    permissions: number
    refby_uuids: string[]
    interface_type: number
    flags?: string[]
    addresses?: {
        address:string,
        public:boolean
    }[]
    public_key?: string
    listen_port?: number
    options?: { name: string; value: string }[]
    dynDns?: {
        hostname?: string
    }
}
export interface SunNkViewNodeZone {
    id: number
    name: string
    description: string
    type: number
    permissions: number
    flags: string[]
    zone_interface: string
}
export interface SunNkViewNode {
    id: number
    name: string
    type: number
    static_node_addresses: string[]
    description: string
    owner_id: number
    permissions: number
    refby_uuids: string[]
    node_address: string
    /**
     * If node_refs is not empty, the node is a group node
     */
    node_refs?: string[]
    node_zone: SunNkViewNodeZone
}
export interface SunNkViewService {
    "id": number
    "name": string
    "description": string
    "type": number
    "refby_uuids": string[]
    "protocol_id": number
    "protocol": string
    "ct_helper_id": number
    "ct_helper"?: string
    "src-ports"?: string[],
    "dst-ports"?: string[],
    "service_refs"?:string[]
    /**
     * If the service is not Published but is a Service Group
     */
    "services"?: SunNkViewService[]
}

export interface SunNetworkView {
    "interfaces":SunNkViewInterface[],
    "nodes":SunNkViewNode[],
    "services": SunNkViewService[],
    
}
export interface SunUtmNode {
    "utmId":string,
    "online":boolean,
    "nkView": null | SunNetworkView,
}

export interface SunRule {
    "id":string,
    "src": {
        "utmId": string
        "node": SunNkViewNode
    }
    "dst": {
        "utmId": string
        "node": SunNkViewNode
        "service": SunNkViewService
    },
    "flags":('AUTO'|'DNS_PRIMARY'|'DNS_SECONDARY')[]
}
export interface SunRoadwarriorRule {
    "id":string,
    "src": {
        "utmId": string
        "node": SunNkViewNode
    }
    "dst": {
        "utmId": string
        "node": SunNkViewNode
        "service": SunNkViewService
    },
    "flags":('AUTO'|'DNS_PRIMARY'|'DNS_SECONDARY')[]
}

export type SunReferenceNode = { id: number } | { name: string }


export type SunRuleOnUpdate = (SunUTMRuleOnAdd | SunRoadwarriorRuleOnAdd) & { "id":string }
export type SunRuleOnAdd = SunUTMRuleOnAdd | SunRoadwarriorRuleOnAdd
export type SunUTMRuleOnAdd = {
    "src": {
        "utmId": string
        "node": SunReferenceNode
    }
    "dst": {
        "utmId": string
        "node": SunReferenceNode
        "service": SunReferenceNode
    }
}
export type SunRoadwarriorRuleOnAdd = {
    "dst": {
        "utmId": string
        "node": SunReferenceNode
        "service": SunReferenceNode
    }
}

export interface SunRoadwarriorOnAdd {
    "name":string,
    "transferNetwork":string,
    "numberOfDevices"?:number
    "entities":SunRoadwarriorEntity[]
}
export interface SunRoadwarriorEntity {
    "id":string,
    "type":"IOS_PROFILE"|"ANDROID_PROFILE"|"WINDOWS_PROFILE"
}

export interface SunRoadwarrior extends SunRoadwarriorOnAdd {
    state: string;
    rules: any;
    errors: any[];
    "transferNetworkCapacity":number
    "id":string
}




const geoIPRegEx = /^GEOIP:[A-Z]{2}$/,
    hostnameLabelRegEx = /^[a-z\d_]([a-z\d-_]{0,61}[a-z\d_]){0,1}$/i,
    hostnameLastLabelRegEx = /^[a-z_][a-z-_]{0,61}[a-z_]$/i

export class SunTopologies extends ObjectType<SunTopology> {
    public showDialogOnBeforeUnload = false
    public v4CidrToIpLimit = {
        "30": 4,
        "29": 8,
        "28": 16,
        "27": 32,
        "26": 64,
        "25": 128,
        "24": 256,
        "23": 512,
        "22": 1024,
        "21": 2048,
        "20": 4096,
        "19": 8192,
        "18": 16384,
        "17": 32768,
        "16": 65536
    }
    public v6CidrToIpLimit = {
        "126": 4,
        "125": 8,
        "124": 16,
        "123": 32,
        "122": 64,
        "121": 128,
        "120": 256,
        "119": 512,
        "118": 1024,
        "117": 2048,
        "116": 4096,
        "115": 8192,
        "114": 16384,
        "113": 32768,
        "112": 65536,
    }
    constructor(payload: ObjectTypePayload<SunTopology>) {
        super(payload)
        this.itemlist.getSortingOptions = () => [
            {
                "id": "name",
                "text": "Name"
            }
        ]
        this.itemlist.getInfoBoxContent = (accountId: string, itemlistComponent: any) => {
            let result = ""
            return result
        }
        this.itemlistItem.onClick = (accountId: string, item) => {
            
        }
        this.itemlistItem.getLabels = (accountId: string, item) => {
            let result: ItemlistLabel[] = []
            return result
        }

        this.itemlistItem.getDetails = (accountId, item?, component?) => {
            component = component?.exposed ? component?.exposed : component
            let result : ItemlistItemDetail[] = []
            return result
        }

        this.itemlistItem.hasCheckbox = () => {
            return false
        }
        this.itemlistItem.isClickable = (accountId, item) => {
            return true
        }

        this.itemlistItem.getDisabledState = (accountId, item) => {
            return false
        }
       
        
            
            

        /**
        * Gets object-list from api
        * @param accountId
        * @param props (optional) GET request Payoad as array
        */
        this.queries.getObjectsFromApi = async (accountId, customerId?, props?, updateStore = true, removeOldObjects = false) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let timestamp = store?.objectsTimestamp || 0
            let isRequestable = function() {
                 
                return store?.gettingObjects == false && timeHelpers.getUnixTimeStamp() - timestamp > 5 
            }()
            if((store && isRequestable) || store == undefined) {
                if(store) { 
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }
                let propertiesString: string = props ? this.getPropertiesString(props) : ""
                if (this.getListUrl(accountId).indexOf("?") != -1) {
                    propertiesString = propertiesString.replace("?","&")
                }


                let result: SunTopology[] | Error

                try {
                    let response = await requestHandler.request(this.options.apiInfo.getObjectListMethod, this.getListUrl(accountId) + propertiesString)
                    response = apis.parseApiResponse(response)
                    let propertyInresponse = this.options.apiInfo.getObjectListResponseProperty
                    if (typeof propertyInresponse == "string" && propertyInresponse.length > 0 && response[propertyInresponse]) {
                        result = response[propertyInresponse as string] as SunTopology[]
                    }
                    else if (typeof propertyInresponse == "function" && response[propertyInresponse()]) {
                        result = response[propertyInresponse()] as SunTopology[]
                    }
                    else if (Array.isArray(response)) {
                        result = response as SunTopology[]
                    }
                    else {
                        throw new Error("Error getting Objects")
                    }
                    if (updateStore) {
                        if(removeOldObjects == true) {
                            this.useStore?.().clearObjectTypeObjectsFromStore(accountId)
                        }
                        else {   
                            let oldTopologies = this.useStore?.().getObjectStoreObjects(accountId)
                            oldTopologies?.forEach((oldTopology) => {
                                const newTopology = (<SunTopology[]>result).find(((topology) => {
                                    return topology.id == oldTopology.id
                                }))
                                if(newTopology) {
                                    this.view.mergeTopologies(oldTopology,newTopology)
                                    result = (<SunTopology[]>result).filter((topology) => {
                                        return topology.id !== newTopology.id
                                    })
                                }
                            })
                            this.useStore?.().setObjectTypeObjects(accountId, result)
                        }
                    }
                    if(store) { store.gettingObjects = false }
                    return result
                }
                catch (e: any) {
                    if(store) { store.gettingObjects = false }
                    devLog.log("ObjectType", e.message, e, "error")
                    throw e as Error
                }
            }
            else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        },

        this.queries.addObjectToApi = async (accountId, object, customerId?, updateStore = true) => {
            let result: SunTopology | Error
            try {
                let response = await requestHandler.request(this.options.apiInfo.addObjectMethod, this.getAddObjectApiUrlPath(accountId, customerId), object)
                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = response
                    ;(<SunTopology>result).changes = {
                        data: {
                            "addedNodes":[],
                            "changedNodes":[],
                            "removedNodes":[],
                            "changedSites":[],
                            "addedPools":[],
                            "changedPools":[],
                            "removedPools":[]
                        }
                    }
                    ;(<SunTopology>result).actions = {
                        'isPublishable':false,
                        'isReversible':true,
                        'publishReason':'CHANGES'
                    }
                }
                else {
                    throw "Error getting objects"
                }
                if (updateStore) {
                    this.useStore?.().addObjectTypeObject(accountId, result as SunTopology)
                }
                return result
            }
            catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        }

    }

    public triggerNodeDiagnostics = async (accountId: AccountId, topologyId: string, nodeId: string) => {
        try {
            const response = await requestHandler.request("GET", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes/" + nodeId + "/diagnostics")

            if (response?.code === 200) {
                products.unifiedSecurityConsole.utmNodes.checkIntegrity.value.add(nodeId)
                return true
            }
        } catch (error: unknown) {
            if (typeof error === "object" && error != null && "status" in error && error.status === 429) {
                devLog.log("ObjectType", "Diagnostic request throttled", error, "info")
            } else {
                devLog.log("ObjectType", "Unknown error", error, "error")
                throw error
            }
        }

        return false
    }
  
     /**
    * Update single node in api
    * @param accountId 
    * @param topologyId  
    * @param object
    * @param props
    * @param updateStore
    */
     public updateProfilePoolEntities = async (accountId: AccountId,topologyId:string, poolId:string,entityId:string,payload:{'vpnOnDemand':boolean}) => {
        try {
            if (!payload) throw "Missing payload"
            if (!topologyId) throw "Missing topologyId"
            if (!poolId) throw "Missing poolId"
            if (!entityId) throw "Missing entityId"
            let response = await requestHandler.request("PUT", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/pools/" + poolId + "/entities/" + entityId,payload)
            response = apis.parseApiResponse(response)
            return response
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }


    /**
    * delete topology in api
    * @param accountId 
    * @param topologyId
    */
    public deleteTopologyFromApi = async (accountId: AccountId, topologyId: string) => {
        try {
            if (!topologyId) throw "Missing topologyId"
            let response = await requestHandler.request("DELETE", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId)
            response = apis.parseApiResponse(response)

            return true
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }

    /**
    * Adds single node to api
    * @param accountId 
    * @param topologyId  
    * @param object
    * @param props
    * @param updateStore
    */
    public addSatelliteToApi = async (accountId: AccountId,topologyId:string, object:SunSatelliteOnAdd, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunSatellite | Error
        try {
            if (!object) throw "Missing object"
            if (!topologyId) throw "Missing topologyId"
            let response = await requestHandler.request("POST", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes" + propertiesString,object)
            response = apis.parseApiResponse(response)
            if (!jsonHelpers.isObjectEmpty(response)) {
                result = response as SunSatellite
            }
            else {
                throw "Error getting objects"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * Update single node in api
    * @param accountId 
    * @param topologyId  
    * @param object
    * @param props
    * @param updateStore
    */
    public updateSatelliteInApi = async (accountId: AccountId,topologyId:string, object:SunSatelliteOnUpdate, satelliteId:string, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunSatellite | Error
        try {
            if (!object) throw "Missing object"
            if (!topologyId) throw "Missing topologyId"
            if (!satelliteId) throw "Missing satelliteId"
            let response = await requestHandler.request("PUT", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes/" + satelliteId + propertiesString,object)
            response = apis.parseApiResponse(response)
            if (!jsonHelpers.isObjectEmpty(response)) {
                result = response as SunSatellite
            }
            else {
                throw "Error getting objects"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }

    /**
    * delete single rule in api
    * @param accountId 
    * @param topologyId
    * @param satelliteId
    * @param ruleId
    */
    public deleteSatelliteInApi = async (accountId: AccountId, topologyId: string, satelliteId: string) => {
        try {
            if (!topologyId) throw "Missing topologyId"
            if (!satelliteId) throw "Missing satelliteId"
            let response = await requestHandler.request("DELETE", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes/" + satelliteId)
            return apis.parseApiResponse(response)
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * Adds single roadwarrior to api
    * @param accountId 
    * @param topologyId  
    * @param object
    * @param props
    * @param updateStore
    */
    public addRoadwarriorToApi = async (accountId: AccountId,topologyId:string, object:SunRoadwarriorOnAdd, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunSatellite | Error
        try {
            if (!object) throw "Missing object"
            if (!topologyId) throw "Missing topologyId"
            let response = await requestHandler.request("POST", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/pools" + propertiesString,object)
            response = apis.parseApiResponse(response)
            if (!jsonHelpers.isObjectEmpty(response)) {
                result = response as SunSatellite
            }
            else {
                throw "Error getting objects"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * update single roadwarrior in api
    * @param accountId 
    * @param topologyId
    * @param poolId
    * @param ruleId
    */
    public updateRoadwarriorInApi = async (accountId: AccountId, topologyId: string, poolId: string,object:SunRoadwarrior) => {
        try {
            if (!topologyId) throw "Missing topologyId"
            if (!poolId) throw "Missing poolId"
            let response = await requestHandler.request("PUT", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/pools/" + poolId,object)
            return apis.parseApiResponse(response)
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * delete single roadwarrior in api
    * @param accountId 
    * @param topologyId
    * @param poolId
    */
    public deleteRoadwarriorInApi = async (accountId: AccountId, topologyId: string, poolId: string) => {
        try {
            if (!topologyId) throw "Missing topologyId"
            if (!poolId) throw "Missing poolId"
            let response = await requestHandler.request("DELETE", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/pools/" + poolId)
            return apis.parseApiResponse(response)            
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }

    /**
    * Adds single rule to api
    * @param accountId 
    * @param topologyId
    * @param poolId
    * @param object
    * @param props
    * @param updateStore
    */
    public addRuleForRoadwarriorInApi = async (accountId: AccountId, topologyId: string, poolId:string, object: SunRoadwarriorRuleOnAdd, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunRule | Error
        let response: SunTopology | Error
        try {
            if (!object) throw "Missing object"
            if ((<SunRule>object).id) throw "Object should not contain property \"id\""
            if (!topologyId) throw "Missing topologyId"
            if (!poolId) throw "Missing poolId"
            response = await requestHandler.request("PUT", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/pools/" + poolId + "/rules" + propertiesString, object)
            return apis.parseApiResponse(response)

        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * updates single rule in api
    * @param accountId 
    * @param topologyId
    * @param poolId
    * @param object
    * @param props
    * @param updateStore
    */
    public updateRuleForRoadwarriorInApi = async (accountId: AccountId, topologyId: string, poolId:string, object: SunRoadwarriorRuleOnAdd, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunRule | Error
        let response: SunTopology | Error
        try {
            if (!object) throw "Missing object"
            if (!topologyId) throw "Missing topologyId"
            if (!poolId) throw "Missing poolId"
            response = await requestHandler.request("PUT", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/pools/" + poolId + "/rules/" + propertiesString, object)
            return apis.parseApiResponse(response)

        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * delete single rule in api
    * @param accountId 
    * @param topologyId
    * @param poolId
    * @param ruleId
    */
    public deleteRuleForRoadwarriorInApi = async (accountId: AccountId, topologyId: string, poolId: string, ruleId:SunRule['id']) => {
        try {
            if (!topologyId) throw "Missing topologyId"
            if (!poolId) throw "Missing poolId"
            if (!ruleId) throw "Missing ruleId"
            let response = await requestHandler.request("DELETE", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/pools/" + poolId + "/rules/" + ruleId)
            return apis.parseApiResponse(response)
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }


    /**
    * Adds single rule to api
    * @param accountId 
    * @param topologyId
    * @param satelliteId
    * @param object
    * @param props
    * @param updateStore
    */
    public addRuleForSatelliteInApi = async (accountId: AccountId, topologyId: string, satelliteId:string, object: SunUTMRuleOnAdd, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunRule | Error
        let response: SunTopology | Error
        try {
            if (!object) throw "Missing object"
            if ((<SunRule>object).id) throw "Object should not contain property \"id\""
            if (!topologyId) throw "Missing topologyId"
            if (!satelliteId) throw "Missing satelliteId"
            response = await requestHandler.request("PUT", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes/" + satelliteId + "/rules" + propertiesString, object)
            return apis.parseApiResponse(response)

        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * updates single rule in api
    * @param accountId 
    * @param topologyId
    * @param satelliteId
    * @param object
    * @param props
    * @param updateStore
    */
    public updateRuleForSatelliteInApi = async (accountId: AccountId, topologyId: string, satelliteId:string, object: SunRuleOnUpdate, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunSatellite | Error
        try {
            if (!object) throw "Missing object"
            if (!object.id) throw "Missing property \"id\" in object"
            if (!topologyId) throw "Missing topologyId"
            if (!satelliteId) throw "Missing satelliteId"
            let response = await requestHandler.request("PUT", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes/" + satelliteId + "/rules" + propertiesString, object)
            response = apis.parseApiResponse(response)
            if (!jsonHelpers.isObjectEmpty(response)) {
                result = response as SunSatellite
            }
            else {
                throw "Error getting objects"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * delete single rule in api
    * @param accountId 
    * @param topologyId
    * @param satelliteId
    * @param ruleId
    */
    public deleteRuleForSatelliteInApi = async (accountId: AccountId, topologyId: string, satelliteId: string, ruleId:SunRule['id']) => {
        try {
            if (!topologyId) throw "Missing topologyId"
            if (!satelliteId) throw "Missing satelliteId"
            if (!ruleId) throw "Missing ruleId"
            let response = await requestHandler.request("DELETE", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes/" + satelliteId + "/rules/" + ruleId)
            return apis.parseApiResponse(response)
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * publish topology in api
    * @param accountId 
    * @param topologyId
    * @returns current sate of the topology
    */
    public publishTopologyInApi = async (accountId: AccountId, topologyId: string,pin:string) => {
        let result: { publishId:string } | Error
        try {
            if (!accountId) throw "Missing accountId"
            if (!topologyId) throw "Missing topologyId"
            if (!pin) throw "Missing pin"
            let response = await requestHandler.request("POST", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/commit",{ "pin":pin})
            response = apis.parseApiResponse(response)
            if (!jsonHelpers.isObjectEmpty(response)) {
                this.showDialogOnBeforeUnload = false
                result = response
            }
            else {
                throw "Error publishing topology"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * publish topology in api
    * @param accountId 
    * @param topologyId
    */
    public restoreTopologyInApi = async (accountId: AccountId, topologyId: string) => {
        let result: true | Error
        try {
            if (!accountId) throw "Missing accountId"
            if (!topologyId) throw "Missing topologyId"
            let response = await requestHandler.request("POST", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/restore")
            response = apis.parseApiResponse(response)
            if (!jsonHelpers.isObjectEmpty(response)) {
                let oldTopology = this.useStore?.().getObjectStoreObject(accountId,topologyId)
                if(oldTopology) { 
                    this.view.mergeTopologies(oldTopology,response)
                }
                result = true
            }
            else {
                throw "Error restoring topology"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }




    getUtmLabels = (accountId: string, item:UscUtm) => {
        let result: Label[] = []
        if(item) {
            if (item?.cluster?.isCluster === true &&  item.license.clusterUuid) {
                result.push(
                    new Label({
                        title: "Cluster " + deviceHelpers.getShortDeviceId(item.license.clusterUuid, 4),
                        text:"",
                        class: "bg-blue",
                        icon: new Icon({ "class": "fa fa-circle-nodes" })
                    })
                )
            }
            if (objectStores.uscUtms.utmHasLicenseConflict(accountId, item)) {
                result.push(new Label({
                    "text": T('License conflict'),
                    "title": T('A license conflict occurs when the same license is installed on several UTMs. In addition to billing problems, this can lead to unpredictable side effects and incorrect configurations. Make sure that a unique license is installed on each UTM to ensure stable and reliable operation of the UTM.'),
                    "class": "bg-red",
                    "icon": new Icon({
                        "class":"fal fa-fw fa-exclamation-triangle",
                    }),
                    "displayType": "label"
                }))
            }
        }

        return result
    }
    hasSatellites = (topology:SunTopology) => {
        return topology.data.satellites.length > 0
    }
    hasPools = (topology:SunTopology) => {
        return topology.data.pools.length > 0
    }
    isPublishAfterCoreDeletionMandatory = (accountId:string,coreId:string,topology:SunTopology) : boolean => {
        let result = true

        const utmInfo = objectStores.uscUtms.getObjectFromStore(accountId,coreId)
        // Exists in USC
        if(utmInfo) {
            const currentTimestamp = timeHelpers.getUnixTimeStamp() * 1000
            const fourtyDays = 1000/*sec*/ * 60/*min*/ * 60/*hour*/ * 24 /*day*/ * 40 /*days*/;
            // Check last contact
            if(utmInfo.lastContact != undefined && utmInfo.lastContact !== -1) {
                if((currentTimestamp - utmInfo.lastContact) >= fourtyDays) {
                    return false
                }
            }
            else {
                const offlineSinceTimestamp = utmInfo.offlineReason?.since || utmInfo.offlineReason?.data?.since || -1
                if (offlineSinceTimestamp != -1) {    
                    if ((currentTimestamp - offlineSinceTimestamp) >= fourtyDays) {
                        // No PIN needed if the core is offline for longer than 40 days
                        result = false
                    }
                }
                // check scopes
                if (utmInfo.license.licenseScopes) {
                    if(!objectStores.uscUtms.canUseFeature(coreId,"USC",accountId)) {
                        // No PIN needed if the core is not enabled for USC anymore
                        result = false
                    }
                }
            }
        }
        // Does not exist in USC
        else {
            // No PIN needed if the core is not enabled for USC anymore
            result = false
        }
        if (topology.changes.data.addedNodes.indexOf(coreId) != -1) {
            // No PIN needed if the core was just added (not published yet)
            result = false
        }
        if (!this.hasSatellites(topology) && !this.hasPools(topology)) {
            // No PIN needed if the there are no peers (No peers -> no config)
            result = false
        }
        return result
    }


    view = {
        getSatellite: (topology: SunTopology, satelliteId:string) => {
            return (topology.data.satellites || []).find((satellite) => {
                return satellite.id == satelliteId
            })
        },
        getRoadwarrior: (topology: SunTopology, poolId:string) => {
            return (topology.data.pools || []).find((pool) => {
                return pool.id == poolId
            })
        },

        getSatelliteStates: (topology: SunTopology) => {
            let satelliteStates: string[] = [];
            (topology.data.satellites || []).forEach((satellite) => {

                const topologyChanges = topology.changes.data
                let removedNodes = topologyChanges.removedNodes || []
                const hasBeenRemoved = removedNodes.indexOf(satellite.id) != -1 || satellite.toBeDeleted
                const hasBeenAdded = topology.changes.data.addedNodes.indexOf(satellite.id || '') != -1
                const hasBeenChanged = topology.changes.data.changedNodes.some((node) => { return node.nodeId == satellite.id }) || topology.changes.data.changedSites.some((site) => {
                    return site.clientId == satellite.id
                })
                const state = satellite.state != 'FAILED' ? hasBeenAdded || hasBeenChanged || hasBeenRemoved ? 'NOT_PUBLISHED' : satellite.state : 'FAILED';

                satelliteStates.push(state)
            })
            return satelliteStates
        },
        getTunnelErrors: (topology: SunTopology) => {
            const satellites = topology.data.satellites || []
            const pools = topology.data.pools || []

            return [...satellites, ...pools].reduce((errors, satellite) => {
                if (Array.isArray(satellite.errors) && satellite.errors.length > 0) {
                    errors.push(...satellite.errors)
                }
                return errors;
            }, [] as string[]);
        },
        getIconForTableEntry: (accountId:string,entry: any, withColor = true) => {
            let result: TableEntryIcons = []
    
            if (entry) {
                const utm = objectStores.uscUtms.getObjectFromStore(accountId, entry.data ? entry?.data?.coreId : entry.id);
                const thisState = getterHelpers.useStore()?.getters.getObject({
                    "accountId": accountId,
                    "productType": "unifiedSecurityConsole",
                    "objectType": "ccutmStates",
                    "objectId":entry.data ? entry.data.coreId : entry.id
                })
    
                const isCoreUtm: boolean = Object.hasOwn((entry?.data || {}), "satellites") && Array.isArray(entry?.data?.satellites)
                const isCluster: boolean = utm?.cluster?.isCluster == true
                const iconClass: string = isCoreUtm ? (thisState?.online == true ? "fal fa-fw fa-crown" : "fal fa-fw fa-crown fa-overlay-xmark") : (thisState?.online == true ? "fal fa-satellite-dish" : "fal fa-satellite-dish fa-overlay-xmark") ;
                const colorClass: string = withColor == true ? thisState?.online == true ? "color-green" : "color-red" : "";
                const title = thisState?.online == true ? T("Connected") : T("Disconnected");
    
                result.push({
                    "class": iconClass + " " + colorClass + " margin-xs-l",
                    "title": title
                })
            }
            return result
        },
        nodeIsGeoIP: (node:SunNkViewNode|CreateNode) => {
            let nodeAddress = typeof node === 'string' ? node : node.node_address;
            return geoIPRegEx.test(nodeAddress!);
        },
        getHostnameValidationMessage: (text: string, forceTestWithTrailingDot:boolean) => {
            let extendedText = forceTestWithTrailingDot === true && text[text.length - 1] !== '.' ? text + '.' : text,
                labels = extendedText.split('.'),
                hasTrailingDot = extendedText[extendedText.length - 1] === '.',
                invalidLabels: string[];

            if (extendedText.length < 1) {
                return T('Too short!');
            } 
            else if (hasTrailingDot && extendedText.length > 255) {
                return T('Too long!');
            } 
            else if (!hasTrailingDot && extendedText.length > 254) {
                return T('Too long!');
            } 
            else {
                if (hasTrailingDot) {
                    labels = labels.slice(0, -1);

                    invalidLabels = labels.filter(function (label, i) {
                        if (i < labels.length - 1) {
                            return !hostnameLabelRegEx.test(label);
                        } else {
                            return !hostnameLastLabelRegEx.test(label);
                        }
                    });
                } else {
                    invalidLabels = labels.filter(function (label, i) {
                        return !hostnameLabelRegEx.test(label);
                    });
                }

                if (invalidLabels.length > 0) {
                    return T('Some labels are invalid:') + ' ' + invalidLabels.join(' ');
                } else {
                    return '';
                }
            }
        },
        getIconClassForNode: (node : SunNkViewNode|CreateNode) => {
            let type : "host"|"network"|"world"|"vpn-host"|"vpn-network"|"interface"|"geo-ip" = 'network'
            if (node.node_address) {
                const nodeIpAddr = new ipaddr(node.node_address)
                const nodeIpCIDR = nodeIpAddr.cidr
                const zone = node.node_zone

                if (this.view.nodeIsGeoIP(node)) {
                    type = 'geo-ip';
                } 
                else {
                    if (nodeIpAddr.isValidIP6Address()) {
                        if (nodeIpCIDR == 128) {
                            type = 'host';
                        } 
                        else if (nodeIpCIDR == 0 && node.node_zone?.name !== 'internal' && node.node_zone?.name !== 'internal_v6') {
                            type = 'world';
                        }
                    } 
                    else if (nodeIpAddr.isValidIP4Address()) {
                        if (nodeIpCIDR == 32) {
                            type = 'host';
                        } 
                        else if (nodeIpCIDR == 0 && node.node_zone?.name !== 'internal' && node.node_zone?.name !== 'internal_v6') {
                            type = 'world';
                        }
                    } 
                    else if (nodeIpAddr.addr !== null && this.view.getHostnameValidationMessage(nodeIpAddr.addr, true) === '') {
                        type = 'host'
                    }
                }
                    var zoneFlags = (<SunNkViewNode['node_zone']>zone).flags || [];
                    if (zone.name.indexOf('vpn-') === 0 || zoneFlags.indexOf('POLICY_IPSEC') > -1 || zoneFlags.indexOf('PPP_VPN') > -1) {
                        if (type === 'network') {
                            type = 'vpn-network';
                        } 
                        else if (type === 'host') {
                            type = 'vpn-host';
                        }
                    } 
                    else if (zoneFlags.indexOf('INTERFACE') > -1) {
                        type = 'interface';
                    }
                    
            }
            return 'icon icon-node-' + type;
        },
        getNodeTypeNameFromNodeType: (type: "host" | "network" | "world" | "vpn-host" | "vpn-network" | "interface" | "geo-ip") => {
            const map = {
                "host":"Network Objects",
                "network":"Network Group",
                "world":"World",
                "vpn-host":"VPN Host",
                "vpn-network":"VPN Network",
                "interface":"Interface",
                "geo-ip":"Geo-IP"
            }
            return map[type]
        },
        getSatelliteUtmIdsForCoreUtm: (topology?: SunTopology, utmId?: string) => {
            if(topology) {
                return topology.data.satellites.map((satellite) => {
                    return satellite.id
                })
            }
            return []
        },
        getRuleInfo: (topology: SunTopology|undefined, satelliteId: string, ruleId: string) => {
                return topology?.data.satellites.find((satellite) => {
                    return satellite.id == satelliteId
                })?.rules.find((rule) => {
                    return rule.id == ruleId
                })
        },
        getServiceInfo: (nodes: SunUtmNode[], utmId: string, serviceId: number) => {
            let utmNode = nodes.find((utmNode) => {
                return utmNode.utmId == utmId
            })
            if (utmNode) {
                return utmNode.nkView?.services.find((service: any) => {
                    return service.id == serviceId
                })
            }
            else {
                return undefined
            }
        },
        getPublishInfoFromApi: async (accountId:string) => {
            const response = await this.queries.getObjectsFromApi(accountId)
            if (
                Array.isArray(response) 
                && response?.length 
                && (response[0].actions.isPublishable === true
                || (response[0].changes.data.addedNodes.length > 0
                || response[0].changes.data.changedNodes.length > 0
                || response[0].changes.data.changedSites.length > 0
                || response[0].changes.data.removedNodes.length > 0))
            ) {
                this.showDialogOnBeforeUnload = true
                return true
            }
            this.showDialogOnBeforeUnload = false
            return false
        },
        getUnpulishedChangesDialog: (accountId:string,onAbort?:Function,loggingOut:boolean=false,switchingTenant:boolean=false) => {
            accountId = tenantHelpers.getAccountId(accountId)
            const activePage = useRouterStore().getActivePage
            let modalOptions : Modal = {
                "id": "unpublishedChanges",
                "accountId": accountId,
                "abortable": true,
                "content": {
                    "title": {
                        "icon": "fal fa-bell",
                        "text": T("VPN-Konfiguration") + " - " + T("Unpublished changes")
                    },
                    "body": {
                        "content": loggingOut ? T(`You still have unpublished changes. Please check your changes and publish them if necessary.`) : T(`You still have unpublished changes. Please switch to the Unified Network Console to check your changes and publish them if necessary.`),
                        "use":true
                    }
                },
                "buttons":[
                    {
                        "text": loggingOut == true ? T('Sign out') : switchingTenant ? T('Switch tenant') : T('Close'),
                        "icon": switchingTenant ? "fal fa-sync" : "fal fa-times",
                        "onClick":() => {
                            onAbort?.()
                            getterHelpers.useStore().commit(MutationTypes.removeModal,{ "accountId": accountId})
                        }
                    }, 
                    {
                        "text": activePage == 'unifiedNetworkConfiguration' ? T('Continue editing') : T('Switch to config'),
                        "icon": "fal fa-angle-right",
                        "onClick": async () => {
                            router.navigate('show-tenant-' + accountId + '.sms-usc-vpn-configuration')
                            getterHelpers.useStore().commit(MutationTypes.removeModal, { "accountId": accountId })
                        }
                    }
                ]
            }
            getterHelpers.useStore().dispatch(ActionTypes.addModal,modalOptions)
        },
        mergeTopologies: (oldTopology:SunTopology,newTopology:SunTopology|SunTopologyResponse) => {

            // update actions
            if((<SunTopology>newTopology).actions) {
                oldTopology.actions = (<SunTopology>newTopology).actions
            }

            // update changes
            if ((<SunTopology>newTopology).changes.data?.addedNodes || (<SunTopologyResponse>newTopology).changes.addedNodes) {
                oldTopology.changes.data.addedNodes = (<SunTopology>newTopology).changes.data?.addedNodes || (<SunTopologyResponse>newTopology).changes.addedNodes
            }
            if ((<SunTopology>newTopology).changes.data?.changedNodes || (<SunTopologyResponse>newTopology).changes.changedNodes) {
                oldTopology.changes.data.changedNodes = (<SunTopology>newTopology).changes.data?.changedNodes || (<SunTopologyResponse>newTopology).changes.changedNodes
            }
            if ((<SunTopology>newTopology).changes.data?.changedSites || (<SunTopologyResponse>newTopology).changes.changedSites) {
                oldTopology.changes.data.changedSites = (<SunTopology>newTopology).changes.data?.changedSites || (<SunTopologyResponse>newTopology).changes.changedSites
            }
            if ((<SunTopology>newTopology).changes.data?.removedNodes || (<SunTopologyResponse>newTopology).changes.removedNodes) {
                oldTopology.changes.data.removedNodes = (<SunTopology>newTopology).changes.data?.removedNodes || (<SunTopologyResponse>newTopology).changes.removedNodes
            }

            if ((<SunTopology>newTopology).changes.data?.addedPools || (<SunTopologyResponse>newTopology).changes.addedPools) {
                oldTopology.changes.data.addedPools = (<SunTopology>newTopology).changes.data?.addedPools || (<SunTopologyResponse>newTopology).changes.addedPools
            }
            if ((<SunTopology>newTopology).changes.data?.changedPools || (<SunTopologyResponse>newTopology).changes.changedPools) {
                oldTopology.changes.data.changedPools = (<SunTopology>newTopology).changes.data?.changedPools || (<SunTopologyResponse>newTopology).changes.changedPools
            }
            if ((<SunTopology>newTopology).changes.data?.removedPools || (<SunTopologyResponse>newTopology).changes.removedPools) {
                oldTopology.changes.data.removedPools = (<SunTopology>newTopology).changes.data?.removedPools || (<SunTopologyResponse>newTopology).changes.removedPools
            }

            // update core properties
            oldTopology.data.properties = newTopology.data.properties
            // update satellites
            newTopology.data.satellites.forEach((newSatellite) => {
                let oldSatellite = oldTopology.data.satellites.find((satellite) => {
                    return satellite.id == newSatellite.id
                })
                if(oldSatellite) {
                    oldSatellite = jsonHelpers.merge(oldSatellite,newSatellite)
                }
                else {
                    oldTopology.data.satellites.push(newSatellite)
                }
            })
            // delete old satellites that are not in the new topology
            oldTopology.data.satellites = oldTopology.data.satellites.filter((oldSatellite) => {
                return (newTopology.data.satellites.some((newSatellite) => {
                    return newSatellite.id == oldSatellite.id
                }))
            })
            
            // update pools
            newTopology.data.pools.forEach((newPool) => {
                let oldPool = oldTopology.data.pools.find((pool) => {
                    return pool.id == newPool.id
                })
                if(oldPool) {
                    oldPool = jsonHelpers.merge(oldPool,newPool)
                }
                else {
                    oldTopology.data.pools.push(newPool)
                }
            })
            // delete old pools that are not in the new topology
            oldTopology.data.pools = oldTopology.data.pools.filter((oldPool) => {
                return (newTopology.data.pools.some((newPool) => {
                    return newPool.id == oldPool.id
                }))
            })
        },
        getAddOrEditCoreDialog: (type:"add"|"edit",accountId:string,entry?:SunTopology,onSubmit?:(result:any) => void,onError?:(e:any) => void) => {
            new ModalObject({
                "accountId": accountId,
                "id": "addCoreUtm",
                "content": {
                    "title": {
                        "text":type == "add" ? T('Add Core-UTM') : T('Edit Core-UTM'),
                        "icon": type == "add" ? "fal fa-plus" : "fal fa-wrench"
                    },
                    "body": {
                        "component": "add-edit-core-utm",
                        "properties": {
                            "utmId": entry?.data.coreId,
                            "port": entry?.data.properties.listenPort,
                            "hostname": entry?.data.properties.hostname,
                            "interface": entry?.data.properties.interfaceId,
                            "transferNetwork": entry?.data.properties.transferNetwork,
                            "alias": entry?.data.properties.alias,
                            "satelliteCount":entry?.data.satellites.filter((satellite) => {
                                return entry.changes.data.removedNodes.indexOf(satellite.id) == -1
                            }).length,
                            "topologyId":entry?.id,
                            "primaryDNS":entry?.data.properties.dnsServer?.[0] || undefined,
                            "secondaryDNS":entry?.data.properties.dnsServer?.[1] || undefined,
                            "type":type
                        }
                    }
                },
                "buttons": [
                    new ModalObjectButton({
                        'text': T('Abort'),
                        'icon': 'fal fa-times',
                        'disabled': false,
                        'onClick': (modalComponent,modal) => {
                            modal.delete()
                        }
                    }),
                    new ModalObjectButton({
                        'text': type == "add" ? T('Add UTM') : T('Save'),
                        'icon': type == "add" ? 'fal fa-plus' : 'fal fa-save',
                        'disabled': false,
                        'onClick': async (modalWrapper,modal) => {
                            let modalComponent = modalWrapper.$refs.modalComponent
                            try {
                                modal.getButton(1)?.disable()
                                modal.getButton(1)?.startLoader()
                                modalComponent.clearErrors()
                                
                                if(type == 'add') {
                                    const selectedUtmId = modalComponent.selectedUtm
                                    const alias = modalComponent.alias
                                    const utm = objectStores.uscUtms.getObjectFromStore(accountId, selectedUtmId)
                                    if (utm) {
                                        const payload = {
                                            "coreId": selectedUtmId,
                                            "properties": <any>{
                                                "interfaceId": modalComponent.selectedInterface,
                                                "dnsServer":<UpdateDnsEntry[]>[]
                                            },
                                            "satellites": [],
                                            
                                        }
                                        if (alias) {
                                            payload.properties.alias = alias
                                        }
                                        if (modalComponent.port) {
                                            payload.properties.listenPort = modalComponent.port
                                        }
                                        if (modalComponent.transferNetwork) {
                                            payload.properties.transferNetwork = modalComponent.transferNetwork
                                        }
                                        if (modalComponent.selectedHostname) {
                                            payload.properties.hostname = modalComponent.selectedHostname
                                        }
                                        if (modalComponent.primaryDNS) {
                                            payload.properties.dnsServer.push(modalComponent.primaryDNS)
                                        }
                                        if (modalComponent.secondaryDNS) {
                                            payload.properties.dnsServer.push(modalComponent.secondaryDNS)
                                        }                                            
                                        if(payload.properties.dnsServer.length) {
                                            ((<UpdateDnsEntry[]>payload.properties.dnsServer) || []).forEach((server,index) => {
                                                payload.properties.dnsServer[index] = {
                                                    'node':{
                                                        'id':(<SunNkViewNode>server.node).id,
                                                        'name':(<SunNkViewNode>server.node).id != undefined ? undefined : server.node.name,
                                                        'node_address':(<SunNkViewNode>server.node).id != undefined ? undefined : server.node.node_address,
                                                        'static_node_addresses':(<SunNkViewNode>server.node).id != undefined ? undefined : (<SunNkViewNode>server.node).static_node_addresses,
                                                        'node_zone':(<SunNkViewNode>server.node).id != undefined ? undefined : server.node.node_zone
                                                    },
                                                    'service':{
                                                        'id':(<SunNkViewService>server.service).id,
                                                        'name':(<SunNkViewService>server.service).id != undefined ? undefined : server.service.name,
                                                        'protocol':(<SunNkViewService>server.service).id != undefined ? undefined : (<SunNkViewService>server.service).protocol,
                                                        'ct_helper':(<SunNkViewService>server.service).id != undefined ? undefined : (<SunNkViewService>server.service).ct_helper,
                                                        'src-ports':(<SunNkViewService>server.service).id != undefined ? undefined : (<SunNkViewService>server.service)["src-ports"],
                                                        'dst-ports':(<SunNkViewService>server.service).id != undefined ? undefined : (<SunNkViewService>server.service)["dst-ports"],
                                                        'icmp-types':(<SunNkViewService>server.service).id != undefined ? undefined : (<CreateService>server.service)["icmp-types"]
                                                    },
                                                }
                                                // remove undefined fields
                                                Object.keys(payload.properties.dnsServer[index].node).forEach(key => payload.properties.dnsServer[index].node[key] === undefined && delete payload.properties.dnsServer[index].node[key])
                                                Object.keys(payload.properties.dnsServer[index].service).forEach(key => payload.properties.dnsServer[index].service[key] === undefined && delete payload.properties.dnsServer[index].service[key])                                            
                                            });
                                        }
                                        else {
                                            delete payload.properties.dnsServer
                                        }
                                        let result = await products.unifiedSecurityConsole.topologies.queries.addObjectToApi(accountId, payload)
                                        onSubmit?.(result)
                                        modal.getButton(1)?.enable()
                                        modal.getButton(1)?.stopLoader()
                                        getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                                    }
                                }
                                else if(type == 'edit' && entry) {
                                    modalComponent.clearErrors()
                                    const selectedUtmId = modalComponent.selectedUtm
                                    const utm = objectStores.uscUtms.getObjectFromStore(accountId, selectedUtmId)

                                    const hostnameBefore = entry.data.properties.hostname
                                    const interfaceIdBefore = entry.data.properties.interfaceId
                                    const transferNetworkBefore = entry.data.properties.transferNetwork
                                    const hostnameAfter = modalComponent.selectedHostname
                                    const interfaceIdAfter = modalComponent.selectedInterface
                                    const transferNetworkAfter = modalComponent.transferNetwork
                                    const aliasBefore = entry.data.properties.alias
                                    const alias = modalComponent.alias

                                    const changedProperties = []
                                    if(hostnameBefore != hostnameAfter) { changedProperties.push("hostname") } 
                                    if(interfaceIdBefore != interfaceIdAfter ) { changedProperties.push("interfaceId") }
                                    if(transferNetworkBefore != transferNetworkAfter) { changedProperties.push("transferNetwork") }
                                    if(aliasBefore != alias) { changedProperties.push("alias") }

                                    if (utm) {
                                        // If has any changes
                                        let payload = {
                                            "coreId": selectedUtmId,
                                            "properties": <any>{
                                                "interfaceId": modalWrapper.$refs.modalComponent.selectedInterface,
                                                "transferNetwork": modalWrapper.$refs.modalComponent.transferNetwork,
                                                "hostname": modalWrapper.$refs.modalComponent.selectedHostname,
                                                "alias":alias,
                                                "dnsServer":<UpdateDnsEntry[]>[]
                                            }
                                        }
                                        if (modalComponent.primaryDNS) {
                                            payload.properties.dnsServer.push(modalComponent.primaryDNS)
                                        }
                                        if (modalComponent.secondaryDNS) {
                                            payload.properties.dnsServer.push(modalComponent.secondaryDNS)
                                        }                                            
                                        if(payload.properties.dnsServer.length) {
                                            ((<UpdateDnsEntry[]>payload.properties.dnsServer) || []).forEach((server,index) => {
                                                console.log(server)
                                                payload.properties.dnsServer[index] = {
                                                    'node':{
                                                        'id':(<SunNkViewNode>server.node).id,
                                                        'name':(<SunNkViewNode>server.node).id != undefined ? undefined : server.node.name,
                                                        'node_address':(<SunNkViewNode>server.node).id != undefined ? undefined : server.node.node_address,
                                                        'static_node_addresses':(<SunNkViewNode>server.node).id != undefined ? undefined : (<SunNkViewNode>server.node).static_node_addresses,
                                                        'node_zone':(<SunNkViewNode>server.node).id != undefined ? undefined : server.node.node_zone
                                                    },
                                                    'service':{
                                                        'id':(<SunNkViewService>server.service).id,
                                                        'name':(<SunNkViewService>server.service).id != undefined ? undefined : server.service.name,
                                                        'protocol':(<SunNkViewService>server.service).id != undefined ? undefined : (<SunNkViewService>server.service).protocol,
                                                        'ct_helper':(<SunNkViewService>server.service).id != undefined ? undefined : (<SunNkViewService>server.service).ct_helper,
                                                        'src-ports':(<SunNkViewService>server.service).id != undefined ? undefined : (<SunNkViewService>server.service)["src-ports"],
                                                        'dst-ports':(<SunNkViewService>server.service).id != undefined ? undefined : (<SunNkViewService>server.service)["dst-ports"],
                                                        'icmp-types':(<SunNkViewService>server.service).id != undefined ? undefined : (<CreateService>server.service)["icmp-types"]
                                                    },
                                                }
                                                // remove undefined fields
                                                Object.keys(payload.properties.dnsServer[index].node).forEach(key => payload.properties.dnsServer[index].node[key] === undefined && delete payload.properties.dnsServer[index].node[key])
                                                Object.keys(payload.properties.dnsServer[index].service).forEach(key => payload.properties.dnsServer[index].service[key] === undefined && delete payload.properties.dnsServer[index].service[key])  
                                            });
                                            
                                        }
                                        else {
                                            delete payload.properties.dnsServer
                                        }
                                        // @ts-ignore
                                        let result = await products.unifiedSecurityConsole.topologies.queries.updateObjectFromApi(accountId, entry.id, payload,undefined,false) as Error | { data:SunTopology }

                                        // Set new properties
                                        entry.data.properties.interfaceId = interfaceIdAfter
                                        entry.data.properties.transferNetwork = transferNetworkAfter
                                        entry.data.properties.hostname = hostnameAfter || modalWrapper.$refs.modalComponent.selectedInterfaceInfo.dynDns?.hostname || modalWrapper.$refs.modalComponent.selectedInterfaceInfo?.addresses?.map((address: any) => { return address.address }).join(", ")
                                        
                                        if(result && entry && !(entry instanceof Error)) {
                                            //@ts-ignore
                                            this.view.mergeTopologies(entry,result as SunTopology)
                                        }
                                        onSubmit?.(result)
                                        
                                        
                                        modal.getButton(1)?.enable()
                                        modal.getButton(1)?.stopLoader()
                                        getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                                    }
                                }
                            }
                            catch (e) {
                                console.error(e)
                                onError?.(e)
                                modalComponent.addError(e)
                                modal.getButton(1)?.enable()
                                modal.getButton(1)?.stopLoader()
                            }
                        }
                    })
                ]
            }).show()
        },
        getDeleteCoreDialog: (accountId:string,topologyId:string,coreId:string,doPublish:boolean=false,isReversible:boolean=false,onSuccess?:() => void,onError?:(e:any) => void) => {
            const modal: Modal = {
                "accountId": accountId,
                "id": "deleteCoreUtm",
                "content": {
                    "title": {
                        "text": T('Delete Core-UTM'),
                        "icon": "fal fa-trash"
                    },
                    "body": {
                        "component": "delete-core-utm",
                        "properties": {
                            "revertChanges": () => { products.unifiedSecurityConsole.topologies.view.getRevertDialog(accountId,topologyId) },
                            "doPublish": doPublish,
                            "isReversible":isReversible
                        }
                    }
                },
                "buttons": [
                    {
                        'text': T('Abort'),
                        'icon': 'fal fa-times',
                        'disabled': false,
                        'onClick': () => {
                            getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                        }
                    },
                    {
                        'id': "publishConfig",
                        'text': T('Delete'),
                        'icon': 'fal fa-trash',
                        'disabled': true,
                        'onClick': async (modalWrapper: any) => {
                            modalWrapper.modal.buttons[0].disabled = true
                            modalWrapper.modal.buttons[1].loading = true
                            modalWrapper.modal.buttons[1].disabled = true
                            try {
                                const pin = modalWrapper.$refs.modalComponent.pin
                                const setError = modalWrapper.$refs.modalComponent.setError
                                const clearErrors = modalWrapper.$refs.modalComponent.clearErrors
                                const clearPin = modalWrapper.$refs.modalComponent.clearPin
                                
                                

                                clearErrors()
                                let thisTopology = this.useStore?.().getObjectStoreObject(accountId,topologyId)
                                
                                let result = await products.unifiedSecurityConsole.topologies.deleteSatelliteInApi(accountId, topologyId, coreId)
                                
                                // If should be published
                                if (doPublish) {

                                    // publish this delete
                                    let response = await products.unifiedSecurityConsole.topologies.publishTopologyInApi(accountId, topologyId || '', pin)
                                    if (!(response instanceof Error) && response?.publishId != undefined) {
                                        let publishId = response?.publishId
                                        // wait for publish answer
                                        if (websocketHandler.hasHookWithId("unifiedNetworkWaitForPublishAnswer") == false) {
                                            getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
                                                "accountId": accountId,
                                                "hookKey": "unifiedNetworkWaitForPublishAnswer",
                                                "hookFunction": (message: any) => {
                                                    if(message.topic == "/sun/publish" && message.data.publishId == publishId) {

                                                        if(message.data?.error == "LOCKED_PIN") {
                                                            clearPin()
                                                            setError('The UTM was locked due to too many failed attempts')
                                                        }
                                                        else if (message.data?.error == "INVALID_PIN") {
                                                            clearPin()
                                                            setError('The PIN you have entered is incorrect')
                                                        }
                                                        else if (message.data?.error == "DISABLED_PIN") {
                                                            clearPin()
                                                            setError('The PIN authentication method is disabled for this UTM and the action is therefore not available.')
                                                        }
                                                        else if(message.data?.success === true) {
                                                            this.showDialogOnBeforeUnload = false
                                                            // Delete & Publish succeeded -> delete topology from store
                                                            this.useStore?.().deleteObjectTypeObjectFromStore(accountId, topologyId)
                                                            onSuccess?.()
                                                            getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                                                        }
                                                        // stop waiting for answer
                                                        getterHelpers.useStore().commit(MutationTypes.deleteSubscriptionHook, {
                                                            "accountId": accountId,
                                                            "hookKey": "unifiedNetworkWaitForPublishAnswer",
                                                        })
                                                        modalWrapper.modal.buttons[0].disabled = false
                                                        modalWrapper.modal.buttons[1].loading = false
                                                        modalWrapper.modal.buttons[1].disabled = false
                                                    }
                                                }
                                            })
                                        }
                                    }
                                    else {
                                        // error or missing publishId in response
                                        modalWrapper.modal.buttons[0].disabled = false
                                        modalWrapper.modal.buttons[1].loading = false
                                        modalWrapper.modal.buttons[1].disabled = false
                                        onError?.(response)
                                        
                                    }
                                }
                                else {
                                    // has been added after last publish -> delete from store (No publish needed)
                                    this.useStore?.().deleteObjectTypeObjectFromStore(accountId, topologyId)
                                    // request the topology
                                    modalWrapper.modal.buttons[0].disabled = false
                                    modalWrapper.modal.buttons[1].loading = false
                                    modalWrapper.modal.buttons[1].disabled = false
                                    onSuccess?.()
                                    getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                                }
                                
                            }
                            catch (e) {
                                console.error(e)
                                modalWrapper.modal.buttons[0].disabled = false
                                modalWrapper.modal.buttons[1].loading = false
                                modalWrapper.modal.buttons[1].disabled = false
                                getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                            }
                        }
                    }
                ]
            }
            getterHelpers.useStore().commit(MutationTypes.addModal, modal)
        },
        getRevertDialog: (accountId:string,topologyId:string,onSuccess?:() => void,onAbort?:() => void,onError?:(e:any) => void) => {
            dialogs.misc.confirmDialog(accountId, T("Discard changes"), T("Do you really want to discard the changes on this configuration?"), async (modal:any) => {
                try {
                    await products.unifiedSecurityConsole.topologies.restoreTopologyInApi(accountId, topologyId)
                    modal.modal.buttons[1].loading = false
                    modal.modal.buttons[1].disabled = true
                    modal.modal.buttons[1].text = T('Discarded')
                    modal.modal.buttons[1].icon = "fal fa-check"
                    onSuccess?.()
                    setTimeout(() => {
                        getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                    }, 2000)
                }
                catch (e) {
                    console.error(e)
                    onError?.(e)
                }
            }, undefined, T("Discard"), "fal fa-clock-rotate-left", true, "fal fa-clock-rotate-left",onAbort,false)
        },
        getPublishDialog: async (accountId:string,topologyId:string,onSuccess:() => void,onAbort:() => void,onError?:(e:any) => void) => {
            await products.unifiedSecurityConsole.topologies.view.getPublishInfoFromApi(accountId)
                let modalOptions: Modal = {
                    "id": "publishChanges",
                    "accountId": accountId,
                    "abortable": true,
                    "content": {
                        "title": {
                            "icon": "fal fa-save",
                            "text": T("VPN-Konfiguration") + " - " + T("Publish configuration")
                        },
                        "body": {
                            "component": "unc-publish-changes",
                            "properties": {
                            },
                            "use": true
                        }
                    },
                    "buttons": [
                        {
                            "text": T('Abort'),
                            "icon": "fal fa-times",
                            "onClick": () => {
                                onAbort()
                                getterHelpers.useStore().commit(MutationTypes.removeModal, { "accountId": accountId })
                            }
                        },
                        {
                            "id":"publishConfig",
                            "text": T('Publish'),
                            "icon": "fal fa-save",
                            "onClick": async (modal: any) => {
                                const pin = modal.$refs.modalComponent.pin
                                const setError = modal.$refs.modalComponent.setError
                                const clearErrors = modal.$refs.modalComponent.clearErrors
                                const setSuccess = modal.$refs.modalComponent.onSuccess
                                const clearPin = modal.$refs.modalComponent.clearPin

                                clearErrors()
                                modal.modal.buttons[0].disabled = true
                                modal.modal.buttons[1].loading = true
                                modal.modal.buttons[1].disabled = true
                                try {
                                    let response = await products.unifiedSecurityConsole.topologies.publishTopologyInApi(accountId, topologyId || '',pin)
                                    
                                    if (!(response instanceof Error) && response?.publishId != undefined) {
                                        let publishId = response?.publishId

                                        if (websocketHandler.hasHookWithId("unifiedNetworkWaitForPublishAnswer") == false) {
                                            getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
                                                "accountId": accountId,
                                                "hookKey": "unifiedNetworkWaitForPublishAnswer",
                                                "hookFunction": (message: any) => {
                                                    if (message.topic == "/sun/publish" && message.data.publishId == publishId) {
                                                        if (message.data?.error == "LOCKED_PIN") {
                                                            clearPin()
                                                            setError('The UTM was locked due to too many failed attempts')
                                                        }
                                                        else if (message.data?.error == "INVALID_PIN") {
                                                            clearPin()
                                                            setError('The PIN you have entered is incorrect')
                                                        }
                                                        else if (message.data?.error == "DISABLED_PIN") {
                                                            clearPin()
                                                            setError('The PIN authentication method is disabled for this UTM and the action is therefore not available.')
                                                        }
                                                        else if (message.data?.success === true) {
                                                            this.showDialogOnBeforeUnload = false
                                                            onSuccess()   
                                                            setSuccess()                                                                   
                                                            getterHelpers.useStore().commit(MutationTypes.removeModal, { "accountId": accountId })  
                                                        }
                                                        modal.modal.buttons[0].disabled = false
                                                        modal.modal.buttons[1].loading = false
                                                        modal.modal.buttons[1].disabled = false
                                                        getterHelpers.useStore().commit(MutationTypes.deleteSubscriptionHook, {
                                                            "accountId": accountId,
                                                            "hookKey": "unifiedNetworkWaitForPublishAnswer",
                                                        })
                                                    }
                                                }
                                            })
                                        }
                                    }
                                    else {
                                        modal.modal.buttons[0].disabled = false
                                        modal.modal.buttons[1].loading = false
                                        modal.modal.buttons[1].disabled = false
                                        onError?.(response)
                                    }
                                }
                                catch(e) {
                                    modal.modal.buttons[0].disabled = false
                                    modal.modal.buttons[1].loading = false
                                    modal.modal.buttons[1].disabled = false
                                    onError?.(e)
                                }
                            },
                            "disabled": true
                        }
                    ]
                }
                getterHelpers.useStore().dispatch(ActionTypes.addModal, modalOptions)

        },
        getRuleDialog: (
            type:"add"|"edit",
            objectType:"satellite"|"roadwarrior",
            accountId:string,
            topologyId:string,
            coreUtmId:string,
            satelliteUtmId?:string,
            roadwarriorId?:string,
            sourceUTM?:string,
            sourceNode?:string|number,
            destinationUTM?:string,
            destinationNode?:string|number,
            service?:string|number,
            ruleId?:string|number,
            onSubmit?:(payload:SunRuleOnAdd|SunRuleOnUpdate) => (Promise<void>|void),
            onError?:(e:any) => void,
            onAbort?:() => void
        ) => {
            const modal: Modal = {
                "accountId": accountId,
                "id": "addRule",
                "content": {
                    "title": {
                        "text": type ? (type == "add" ? T('Add a rule') : T('Edit rule')) : (destinationNode ? T('Edit rule') : T('Add a rule')),
                        "icon": type ? (type == "add" ? "fal-fa-plus" : "fal fa-wrench") : (destinationNode ? "fal fa-wrench" : "fal fa-plus")
                    },
                    "body": {
                        "component": "add-edit-rule",
                        "properties": {
                            coreUtmId,
                            roadwarriorId,
                            satelliteUtmId,
                            topologyId,
                            sourceUTM,
                            sourceNode,
                            destinationUTM,
                            destinationNode,
                            service,
                            type,
                            objectType
                        }
                    }
                },
                "buttons": [
                    {
                        'text': T('Abort'),
                        'icon': 'fal fa-times',
                        'disabled': false,
                        'onClick': () => {
                            
                            onAbort?.()
                            getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                        }
                    },
                    {
                        'text': type ? (type == "add" ? T('Add rule') : T('Save')) : (destinationNode ? T("Save") : T('Add rule')),
                        'icon': 'fal fa-save',
                        'disabled': true,
                        'onClick': async (modalWrapper: any) => {
                            modalWrapper.modal.buttons[0].disabled = true
                            modalWrapper.modal.buttons[1].loading = true
                            modalWrapper.modal.buttons[1].disabled = true
                            try {
                                const sourcePoolId = modalWrapper.$refs.modalComponent.sourcePool
                                const sourceUtmId = modalWrapper.$refs.modalComponent.sourceUTM
                                const sourceNode = modalWrapper.$refs.modalComponent.sourceNode
                                const destinationUtmId = modalWrapper.$refs.modalComponent.destinationUTM
                                const destinationNode = modalWrapper.$refs.modalComponent.destinationNode
                                const service = modalWrapper.$refs.modalComponent.service

                                if (sourceUtmId && sourceNode && destinationUtmId && destinationNode && service) {
                                    const payload : SunRuleOnAdd | SunRuleOnUpdate = {
                                        "src": {
                                            "utmId": sourceUtmId,
                                            "node": {} as SunReferenceNode
                                        },
                                        "dst": {
                                            "utmId": destinationUtmId,
                                            "node": {} as SunReferenceNode,
                                            "service": {} as SunReferenceNode
                                        }
                                    }
                                    if (typeof sourceNode == "string") { 
                                        payload.src.node = { name: sourceNode };
                                    }
                                    else {
                                        payload.src.node = { id: sourceNode };
                                    }

                                    if (typeof destinationNode == "string") {
                                        payload.dst.node = { name: destinationNode };
                                    }
                                    else {
                                        payload.dst.node = { id: destinationNode }
                                    }

                                    if (typeof service == "string") {
                                        payload.dst.service = { name: service };
                                    }
                                    else {
                                        payload.dst.service = { id: service };
                                    }
                                    if(ruleId) {
                                        (<SunRuleOnUpdate>payload).id = ruleId as string
                                    }
                                    await onSubmit?.(payload)
                                    modalWrapper.modal.buttons[0].disabled = false
                                    modalWrapper.modal.buttons[1].loading = false
                                    modalWrapper.modal.buttons[1].disabled = false
                                }
                                else if (sourcePoolId && destinationUtmId && destinationNode && service) {
                                    const payload : SunRuleOnAdd = {
                                        "dst": {
                                            "utmId": destinationUtmId,
                                            "node": {} as SunReferenceNode,
                                            "service": {} as SunReferenceNode
                                        }
                                    }
                                    if (typeof destinationNode == "string") {
                                        payload.dst.node = { name: destinationNode };
                                    }
                                    else {
                                        payload.dst.node = { id: destinationNode }
                                    }

                                    if (typeof service == "string") {
                                        payload.dst.service = { name: service };
                                    }
                                    else {
                                        payload.dst.service = { id: service };
                                    }
                                    if(ruleId) {
                                        (<SunRuleOnUpdate>payload).id = ruleId as string
                                    }
                                    await onSubmit?.(payload)
                                    modalWrapper.modal.buttons[0].disabled = false
                                    modalWrapper.modal.buttons[1].loading = false
                                    modalWrapper.modal.buttons[1].disabled = false
                                }
                                getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                            }
                            catch (e) {
                                console.error(e)
                                modalWrapper.modal.buttons[0].disabled = false
                                modalWrapper.modal.buttons[1].loading = false
                                modalWrapper.modal.buttons[1].disabled = false
                            }
                        }
                    }
                ]
            }
            getterHelpers.useStore().commit(MutationTypes.addModal, modal)
        },

        getCoreDNSDialog: (
            type:"add"|"edit",
            accountId:string,
            topologyId:string,
            coreUtmId:string,
            node?:string|number,
            service?:string|number,
            ruleId?:string|number,
            onSubmit?:(payload:any) => (Promise<void>|void),
            onError?:(e:any) => void,
            onAbort?:() => void,
        ) => {
            new ModalObject({
                "accountId":accountId,
                "id": "coreDNSDialog",
                "content": {
                    "title": {
                        "text": type == "add" ? T('Add DNS') : T('Edit DNS'),
                        "icon": type == "add" ? "fal-fa-plus" : "fal fa-wrench"
                    },
                    "body": {
                        "component": "add-edit-core-dns",
                        "properties": { coreUtmId, topologyId, node, service, type }
                    }
                },
                "buttons": [
                    new ModalObjectButton({
                        'text': T('Abort'),
                        'icon': 'fal fa-times',
                        'disabled': false,
                        'onClick': (modalComponent,modal) => {
                            onAbort?.()
                            modal.delete()
                        }
                    }),
                    new ModalObjectButton({
                        'text': type == "add" ? T('Add DNS') : T('Save'),
                        'icon': 'fal fa-save',
                        'disabled': true,
                        'onClick': async (modalWrapper: any,modal) => {
                            modal.getButton(0)?.disable()
                            modal.getButton(1)?.startLoader()
                            modal.getButton(1)?.disable()
                            try {
                                
                                const utmId = modalWrapper.$refs.modalComponent.coreUtmId
                                const selectedNode = modalWrapper.$refs.modalComponent.node
                                const selectedService = modalWrapper.$refs.modalComponent.service

                                const addedService = modalWrapper.$refs.modalComponent.addedService as  CreateService|undefined
                                const addedNode = modalWrapper.$refs.modalComponent.addedNode as  CreateNode|undefined

                                const nkView = modalWrapper.$refs.modalComponent.nkView
                                
                                let nodePayload : { id?:number, name?:string } | undefined;
                                let servicePayload : { id?:number, name?:string } | undefined;

                                if(typeof selectedNode == 'number') {
                                    let selectedNodeInfo = nkView?.nkView?.nodes?.find((nkViewNode:any) => {
                                        return nkViewNode.id == selectedNode
                                    })
                                    nodePayload = { id:selectedNode, name:selectedNodeInfo?.name }
                                    if (nodePayload.name == undefined) {
                                        delete nodePayload.name
                                    }
                                }
                                else {
                                    nodePayload = { name:selectedNode }
                                }
                                if(typeof selectedService == 'number') {
                                    let selectedServiceInfo = nkView?.nkView?.services?.find((nkViewService:any) => {
                                        return nkViewService.id == selectedService
                                    })
                                    servicePayload = { id:selectedService, name:selectedServiceInfo?.name }
                                    if (servicePayload.name == undefined) {
                                        delete servicePayload.name
                                    }
                                }
                                else {
                                    servicePayload = { name:selectedService }
                                }

                                if (utmId && selectedNode && selectedService) {
                                    const payload = {
                                        "node": addedNode ? addedNode : nodePayload,
                                        "service": addedService ? addedService : servicePayload
                                    }
                                    await onSubmit?.(payload)
                                    modal.getButton(0)?.enable()
                                    modal.getButton(1)?.stopLoader()
                                    modal.getButton(1)?.enable()
                                }
                  
                                modal.delete()
                            }
                            catch (e) {
                                console.error(e)
                                modal.getButton(0)?.enable()
                                modal.getButton(1)?.stopLoader()
                                modal.getButton(1)?.enable()
                            }
                        }
                    })
                ]
            }).showFirst()
        },

        handleWebsocketHooks: (accountId:string) => {
            if(websocketHandler.hasHookWithId("unifiedNetwork") == false) {
                getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
                    "accountId": accountId,
                    "hookKey": "unifiedNetwork",
                    "hookFunction": async (message: any) => {

                        if (message.topic == "/usc/utm/message" && message.data.clientContext == "handled-model-nwkview-get") {
                            const messageData = message.data
                            delete messageData.clientContext
                            delete messageData.tenantDomain
                            if(messageData.data) {
                                messageData.nkView = messageData.data
                                delete messageData.data
                            }
                            const nodeInfo = messageData as UtmNode
                            products.unifiedSecurityConsole.utmNodes.useStore?.().setObjectTypeObjects(accountId,[nodeInfo])
                        }
                        if (message.topic == "/sun/topology/site/state/change") {
                            const messageState: {
                                "topologyId": string,
                                "satelliteId": string,
                                "state": PublishState,
                                "coreId": string
                            } | undefined = message?.data
                            if (messageState) {
                                const topology = this.useStore?.().getObjectStoreObject(accountId, messageState.topologyId)
                                if (topology) {
                                    const satellite = topology.data.satellites.find((satellite) => { return satellite.id == messageState.satelliteId })
                                    if (satellite) {
                                        satellite.state = messageState.state
                                    }
                                    else {
                                        console.error('satellite not found')
                                    }
                                }
                                else {
                                    console.error('topology not found')
                                }
                            }
                        }
                        if (message.topic == "/sun/topology/pool/state/change") {
                            const messageState: {
                                "topologyId": string,
                                "poolId": string,
                                "state": PublishState
                            } | undefined = message?.data
                            if (messageState) {
                                const topology = this.useStore?.().getObjectStoreObject(accountId, messageState.topologyId)
                                if (topology) {
                                    const pool = topology.data.pools.find((pool) => { return pool.id == messageState.poolId })
                                    if (pool) {
                                        pool.state = messageState.state
                                    }
                                    else {
                                        console.error('pool not found')
                                    }
                                }
                                else {
                                    console.error('pool not found')
                                }
                            }
                        }

                    }
                })
            }
        },
        deleteWebsocketHooks: (accountId:string) => {
            getterHelpers.useStore().commit(MutationTypes.deleteSubscriptionHook, {
                "accountId": accountId,
                "hookKey": "unifiedNetwork",
            })
        },
        handleGlobalWebsocketHooks: () => {
            if(websocketHandler.hasHookWithId("unifiedNetworkGlobal") == false) {
                getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
                    "hookKey": "unifiedNetworkGlobal",
                    "hookFunction": async (message: any) => {
                        const refreshConfiguration = async (accountId: string) => {
                            const topologies = await this.queries.getObjectsFromApi(accountId, undefined, undefined, false)

                            if (!(topologies instanceof Error) && topologies.length > 0) {
                                const oldTopology = this.useStore?.().getObjectStoreObject(accountId, topologies[0].id)
                                
                                if (oldTopology) {
                                    this.view.mergeTopologies(oldTopology, topologies[0])
                                }
                                this.showDialogOnBeforeUnload = topologies[0].actions.isPublishable
                            } else {
                                this.showDialogOnBeforeUnload = false
                            }
                        }

                        if (message.topic == "/sun/state/changed") {
                            const messageAccountId = tenantHelpers.getAccountId(message.tenantDomain)
                            const user = message.data.username                           
                            const currentUser = getterHelpers.useStore().state.session.userInfo.username

                            if (currentUser != user) {
                                await refreshConfiguration(messageAccountId)
                            }
                        }

                        if (message.topic == "/sun/publish" && message.data?.success === true) {
                            this.showDialogOnBeforeUnload = false
                        }

                        if (message.topic == "/sun/diagnostics/finished") {
                            const { nodeId } = message.data 
                            products.unifiedSecurityConsole.utmNodes.checkIntegrity.value.delete(nodeId)
                            await refreshConfiguration(tenantHelpers.getAccountId(message.tenantDomain))
                        }
                    }
                })
            }
        },
        
        deleteGlobalWebsocketHooks: (accountId:string) => {
            getterHelpers.useStore().commit(MutationTypes.deleteSubscriptionHook, {
                "accountId": accountId,
                "hookKey": "unifiedNetworkGlobal",
            })
        },
        doesRuleBelongToPeer:(rule:SunRule|SunRoadwarriorRule,peer:SunSatellite|SunRoadwarrior) => {
            
        },
        isRoadwarriorRule:(rule:SunRule|SunRoadwarriorRule) => {
            return rule.src.node.name === 'address_pool'
        },
        isSatelliteRule:(rule:SunRule|SunRoadwarriorRule) => {
            return rule.src.node.name !== 'address_pool'
        },
        isSameSatelliteRule:(rule:SunRule|SunRoadwarriorRule,satellite:SunSatellite) => {
            return rule.src.node.name !== 'address_pool' && (rule.dst.utmId == satellite.id || rule.src.utmId == satellite.id)
        },
        pasteRule:(accountId:string,ruleEntry:SunRule|SunRoadwarriorRule,topology:SunTopology,coreUtmId:string,pasteTo:SunSatellite|SunRoadwarrior) => {
            
            const isCopiedFromSatellite = ruleEntry.src.node.name !== 'address_pool'
            const isPastedOnSatellite = topology.data.satellites.some((satellite) => { return satellite.id == pasteTo.id })

            let srcUtmId : string|undefined = ruleEntry.src.utmId
            let srcNodeId : string|number|undefined = ruleEntry.src.node.id || ruleEntry.src.node.name

            let dstUtmId : string|undefined = ruleEntry.dst.utmId
            let dstNodeId : string|number|undefined = ruleEntry.dst.node.id || ruleEntry.dst.node.name
            let dstServiceId : string|number|undefined = ruleEntry.dst.service.id || ruleEntry.dst.service.name

            /*
                console.log(isCopiedFromSatellite ? "copied from satellite" : "copied from pool")
                console.log(isPastedOnSatellite ? "pasted in satellite" : "pasted in pool")
                console.log("core-UTM: " + coreUtmId)
                console.log("satellite?: " + (isPastedOnSatellite ? pasteTo.id : undefined))
                console.log("roadwarrior?: " + (isPastedOnSatellite ? undefined : pasteTo.id))
                console.log("src UTM: " + srcUtmId)
                console.log("src Node: " + srcNodeId)
                console.log("dst UTM: " + dstUtmId)
                console.log("dst Node: " + dstNodeId)
            */

            if(!isCopiedFromSatellite) {
                // Rule came from roadwarrior:
                // dst is the core UTM (node and service are available)
                // src is the new satellite/roadwarrior
                srcUtmId = pasteTo.id
                srcNodeId = undefined
            }



            products.unifiedSecurityConsole.topologies.view.getRuleDialog(
                "add",
                isPastedOnSatellite ? "satellite": "roadwarrior",
                accountId,
                topology.id,
                coreUtmId,
                isPastedOnSatellite ? pasteTo.id : undefined, // satelliteId
                isPastedOnSatellite ? undefined : pasteTo.id, // roadwarriorId
                srcUtmId,
                srcNodeId,
                dstUtmId,
                dstNodeId,
                dstServiceId,
                undefined,
                async (payload) => {                  
                    try {
                        let result : any = undefined
                        if(isPastedOnSatellite) {
                            result = await products.unifiedSecurityConsole.topologies.addRuleForSatelliteInApi(accountId, topology?.id || "", pasteTo?.id || "",(<SunUTMRuleOnAdd>payload))
                        }
                        else {
                            result = await products.unifiedSecurityConsole.topologies.addRuleForRoadwarriorInApi(accountId, topology?.id || "", pasteTo?.id || "",(<SunUTMRuleOnAdd>payload))
                        }

                        if(result && topology) {
                            products.unifiedSecurityConsole.topologies.view.mergeTopologies(topology,(<any>result))
                        }
                        
                        getterHelpers.useStore().commit(MutationTypes.removeModal,{ "accountId": accountId})
                    }
                    catch(e) {
                        throw e
                    }
                    
                },
                (e) => {
                    console.error(e)
                },
                () => {
                    
                }
            )
        }


    }



}


const topologies = new SunTopologies({
    "objectType": "topologies",
    "productType": "unifiedNetwork",
    "slug": "topologies",
    "hasStore": true,
    "objectTypeInfo": {
        "nameProperty": {
            "primary": "utmname"
        },
        "primaryKeyProperty": {
            "property": "id"
        },
    },
    "appearance": {
        "color": "red",
        "iconClass": "fal fa-server",
        "showInSidebar": true,
        "text": {
            "plural": "Topologies",
            "sidebarName": "Topologies",
            "title": "Topologies",
            "singular": "Topology"
        }
    },
    "apiInfo": {
        "getCountGETProperties": "?props[]=null&select=data.total",
        "url": config.mgtApiUriNext,
        "getObjectListPath": "/tenants/{tenantDomain}/sun/topologies",
        "getObjectListResponseProperty": "topology",
        "getObjectPath": "/tenants/{tenantDomain}/sun/topologies/{objectId}"
    },

})
export default topologies