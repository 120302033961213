<script setup lang="ts">
  import { T } from '@/classes/i18n';
  import tableComponent, { type TableEntryInfo } from './tableNext.vue';
  import objectStores from '@/classes/init';
  import { computed, onMounted, ref } from 'vue';
  import mixinHelpers from '@/helpers/helpers.mixins';
  import stringHelpers from '@/helpers/helpers.strings';
  import moment from 'moment';
  import router from '@/router/router';
  import Button from "@/templates/components/button/button";
  import type { UscUtm } from '@/classes/unifiedSecurityConsole/uscUtms';
  import Label from '@/templates/components/label/label';
  import Icon from '@/templates/components/icon/icon';

  // Props
  const props = withDefaults(defineProps<{
    isUtm?:boolean
  }>(), {
    isUtm:false
  });

  const activeAccountId = computed(() => { return mixinHelpers.getActiveAccountId() })
  const objectId = computed(() => { return mixinHelpers.getObjectId() })

  const messages = ref(<any[]>[])

  const tableSelectableColumns : TableEntryInfo[] = [
    {
      "text": T('Time'),
      "property": "lastStatusChanged",
      "displayType": "text",
      "getSortValue": (entry: any) => {
        return String(entry?.updated)
      },
      "getValue": (entry: any) => {
        return moment(entry?.updated * 1000).format("DD.MM.YYYY HH:mm:ss")
      }
    },
    {
      "text":T('Type'),
      "property":"type",
      "displayType":"text",
      "getValue": (entry: any) => {
        let result: string
        if (entry.content?.payload?.clientContext) {
          result = stringHelpers.capitalizeFirstLetter(entry.content.payload.clientContext.split("-").slice(1).join(" ").toLowerCase())
        }
        else {
          result = entry.type ?  stringHelpers.capitalizeFirstLetter(entry.type.replaceAll("_", " ").toLowerCase()) : ""
        }
        return result
      }
    },
    {
      "text": T('Client'),
      "property": "deviceId",
      "displayType": "labels",
      "getSortValue": (entry: any) => {
        let uscUtm = undefined
        if (entry?.deviceId) {
          uscUtm = objectStores.uscUtms.getObjectFromStore(activeAccountId.value, entry?.deviceId)
        }
        return uscUtm?.utmname || ""
      },
      "getValue": (entry: any) => {
        let result: Label[] = []
        let uscUtm: UscUtm | undefined = undefined
        if (entry?.deviceId) {
          uscUtm = objectStores.uscUtms.getObjectFromStore(activeAccountId.value, entry?.deviceId)
        }
        if (uscUtm) {
          result.push(new Label({
            "text": uscUtm.utmname,
            "title": uscUtm.utmname,
            "onClick": () => {
              if (uscUtm) {
                router.navigate("show-tenant-" + uscUtm.tenantDomain + "-usc-utms-" + uscUtm.utmId)
              }
            }
          }))
        }

        return result

      }
    },
    {
      "text": T('Direction'),
      "property": "queue",
      "displayType": "labels",
      "getValue": (entry: any) => {
        return [
          new Label({
            "icon": new Icon({
              "class": entry?.queue == 'in' ? "fal fa-arrow-left" : "fal fa-arrow-right",
            }),
            "text": T(stringHelpers.capitalizeFirstLetter(entry?.queue)),
            "title": entry?.queue == 'in' ? T('Message from device to server') : T('Message from server to device')
          })
        ]
      }
    },


    {
      "text": T('Status'),
      "property": "status",
      "displayType": "labels",
      "getValue": (entry: any) => {
        return [
          new Label({
            "title": entry?.statusText ? entry?.statusText : T(stringHelpers.capitalizeFirstLetter(entry?.status)),
            "text": T(stringHelpers.capitalizeFirstLetter(entry?.status)),
            "class": entry?.status == 'ERROR' ? 'bg-red' : entry?.status == 'PENDING' ? 'bg-yellow' : '',
            "icon": new Icon({
              "class":entry?.status == 'ERROR' ? "fal fa-exclamation-triangle" : entry?.status == 'PENDING' ? "fal fa-bell" : "fal fa-info",
            })
          })
        ]
      }
    }
  ]
  const tableButtons = computed(() => { 
    return [new Button({
      "onClick":() => { refresh() },
      "icon":"fal fa-sync",
      "loading":loading.value
    })] 
  })
  
  const loading = ref(false)

  onMounted(() => {
    refresh()
  })


  const refresh = async () => {
    loading.value = true
    let result = await objectStores.uscProfiles.getPublishMessages(activeAccountId.value, objectId.value || "");

    if (!(result instanceof Error) && result) {
      messages.value = result
    }
    loading.value = false
  }



</script>
<template>
  <div>

    <tableComponent
      :buttons="tableButtons"
      :object-list="messages"
      :selectable-columns="tableSelectableColumns"
      :has-options="false"
      :is-loading="loading"
    ></tableComponent>

  </div>
</template>
