<script setup lang="ts">
	import { useStore } from '@/store/vuex.store';
	import jsonHelpers from "../../helpers/helpers.json";
	import { T } from "@/classes/i18n";
	import sidebarAccountselect from "./main-sidebar-accountselect.vue";
	import tenantHelpers from '@/helpers/helpers.tenants';
	import jsonFormHelpers from '@/helpers/helpers.jsonForms';
	import config from '@/classes/config';
	import licenseHelpers from '@/helpers/helpers.license';
	import { useAzureStore } from '@/classes/objectTypes/unifiedSecurity/users/azure';
	import { computed, ref } from 'vue';
	import useRouterStore from '@/router/routerStore';
	import { type LabelOptions } from '@/templates/components/label/label';
	import { useFeatureStore } from '@/classes/featureStore';

	type PagesOptions = {
		[pageName:string]:{
			text?: {
				singular?: string
				plural?: string
				add?: string
				edit?: string
				sidebar?:string
			},
			link?: {
				href?: string
				target?: string
				onClick?: string
				opened?:boolean
			},
			addLink?: {
				href?:string,
				target?:string,
				forLicenses:string[],
				conditions: string[]
			},
			appearance?: {
				color?:string
				icon?:string
				label?:LabelOptions
				labels?:LabelOptions[]
			},
			options?: {
				type?:"accountSelection"|"title"
				show?:boolean
				productType?: string,
				showOnDashboard?: boolean,
				devOnly?: boolean,
				featureFlag?: string,
				resellerTenantOnly?:boolean
				vppOnly?:boolean
				depOnly?:boolean
				enterpriseOnly?:boolean
				zeroTouchOnly?:boolean
				parent?: string,
				activeAccountHasNoParentAccount?: boolean,
				resellerAccountOnly?: boolean,
				requiresSession?:boolean
				forLicenses?:string[],
				forValidLicenses?:string[],
				forLicensesInAnyTenant?:string[],
				forValidLicensesInAnyTenant?:string[],
				forLicensesInAnyChildOrThisTenant?:string[],
				forValidLicensesInAnyChildOrThisTenant?:string[],
				forRoles?: string[],
				forTenants?: string[],
			}
    	}
	}

	const refreshCounter = ref(0)
	const activeAccountId = computed (() => {
		return useStore().state.session.activeAccountId || ""
	})
	const activeTenantDomain = computed (() => {
		return activeAccountId.value ? activeAccountId.value + ".sms" : ""
	})
	const accounts = computed(() => {
		return useStore().state.session.accounts
	})
	const licenses = computed(() => {
		refreshCounter.value;
		return useStore().state.session.accounts[activeAccountId.value]?.unifiedSecurity?.licenses?.items
	})
	const pages = computed(() => {
		return useStore().state.resources.pages as PagesOptions
	})
	const activePage = computed(() => {
		return useRouterStore().getActivePage
	})
	const featureStore = useFeatureStore()


	// METHODS
	const getUrlPath = () => {
		return document.location.pathname
	}
	const doRefresh = () => {
		refreshCounter.value = refreshCounter.value + 1
	}
	const getObjectLength = jsonHelpers.getObjectLength
	const getPages = (parent: any = null) => {
		let result : PagesOptions = {}
		refreshCounter.value;
		licenses.value;
		for (let slug in pages.value) {
			let page = pages.value[slug]
			let addThisPage: boolean = true
			if (page.options?.parent != parent) {
				addThisPage = false
			}
			if (page.options?.show != undefined) {
				if (page.options.show == false) {
					addThisPage = false
				}
			}
			if (page.options?.activeAccountHasNoParentAccount) {
				if (useStore().state.session.accounts[useStore().state.session.activeAccountId || ""].unifiedSecurity.account.parent_accountid != null) {
					addThisPage = false
				}
			}
			if (page.options?.resellerAccountOnly) {
				if (useStore().state.session.isReseller != true) {
					addThisPage = false
				}
			}
			if (page.options?.resellerTenantOnly) {
				if (useStore().state.session.accounts[useStore().state.session.activeAccountId || ""].unifiedSecurity.account.isReseller != true) {
					addThisPage = false
				}
				
			}
			if (page.options?.vppOnly) {
				if (tenantHelpers.hasFunctionality(activeAccountId.value, 'vpp') != true) {
					addThisPage = false
				}
			}
			if (page.options?.depOnly) {
				if (tenantHelpers.hasFunctionality(activeAccountId.value, 'dep') != true) {
					addThisPage = false
				}
			}
			if (page.options?.enterpriseOnly) {
				if (tenantHelpers.hasFunctionality(activeAccountId.value, 'enterprise') != true) {
					addThisPage = false
				}
			}
			if (page.options?.zeroTouchOnly) {
				if (tenantHelpers.hasFunctionality(activeAccountId.value, 'zerotouch') != true) {
					addThisPage = false
				}
			}
			if (page.options?.forLicenses) {
				if (!licenseHelpers.hasOneOfLicenses(activeAccountId.value, page.options.forLicenses) === true) {
					addThisPage = false
				}
			}
			if (page.options?.forValidLicenses) {
				if (!licenseHelpers.hasOneOfLicenses(activeAccountId.value, page.options.forValidLicenses) === true) {
					addThisPage = false
				}
			}
			if (page.options?.forTenants) {
				if (!config.devMode) {
					if (page.options.forTenants.indexOf(tenantHelpers.getAccountId(useStore().state.session.userInfo.targetDomain)) == -1) {
						addThisPage = false
					}
				}
			}
			if (page.options?.forRoles) {
				// check for roles
				let hasAnyRole = false
				useStore().state.session.userInfo.roles.forEach((roleName:string) => {
					if(page.options?.forRoles?.indexOf(roleName) != -1) {
						hasAnyRole = true;
					}
				})
				if(!hasAnyRole) {
					addThisPage = false
				}
			}
			if (page.options?.forLicensesInAnyTenant) {
				let accountIds : string[] = useStore().state.session.userInfo.scopes.list_accounts.map(function (accountInfo:any) { return accountInfo.accountid })
				let foundLicense = accountIds.some((accountId) => {
					//@ts-ignore
					return licenseHelpers.hasOneOfLicenses(accountId, page.options.forLicensesInAnyTenant)
				})

				if (!foundLicense) {
					addThisPage = false
				}
			}
			if (page.options?.forValidLicensesInAnyTenant) {
				let accountIds : string[] = useStore().state.session.userInfo.scopes.list_accounts.map(function (accountInfo:any) { return accountInfo.accountid })
				let foundLicense = accountIds.some((accountId) => {
					//@ts-ignore
					return licenseHelpers.hasOneOfLicenses(accountId, page.options.forValidLicensesInAnyTenant,'valid')
				})

				if (!foundLicense) {
					addThisPage = false
				}
			}

			if (page.options?.forLicensesInAnyChildOrThisTenant) {
				let accountIds : string[] = useStore().state.session.userInfo.scopes.list_accounts.filter((accountInfo:any) => {
					return String(accountInfo.parent_accountid) == activeAccountId.value || String(accountInfo.accountid) == activeAccountId.value
				}).map(function (accountInfo:any) { 
					return String(accountInfo.accountid)
				})
				let foundLicense = accountIds.some((accountId) => {
					//@ts-ignore
					return licenseHelpers.hasOneOfLicenses(accountId, page.options.forLicensesInAnyChildOrThisTenant)
				})
				if (!foundLicense) {
					addThisPage = false
				}
			}
			if (page.options?.forValidLicensesInAnyChildOrThisTenant) {
				let accountIds : string[] = useStore().state.session.userInfo.scopes.list_accounts.filter((accountInfo:any) => {
					return String(accountInfo.parent_accountid) == activeAccountId.value || String(accountInfo.accountid) == activeAccountId.value
				}).map(function (accountInfo:any) { 
					return String(accountInfo.accountid)
				})
				
				let foundLicense = accountIds.some((accountId) => {
					//@ts-ignore

					return licenseHelpers.hasOneOfLicenses(accountId, page.options.forValidLicensesInAnyChildOrThisTenant,'valid')
				})
				
				if (!foundLicense) {
					addThisPage = false
				}
			}

			if (!config.devMode && page.options?.devOnly == true || (page.options?.featureFlag && featureStore.isEnabled(page.options.featureFlag) === false)) {
				addThisPage = false
			}
			if (addThisPage) {
				result[slug] = page
			}
		}
		return result
	}
	const openSubmenu = (submenuName: string) => {
		if(pages.value[submenuName]?.link) {
			pages.value[submenuName].link.opened =  true
		}
	}
	const closeSubmenu = (submenuName: string) => {
		if(pages.value[submenuName]?.link) {
			pages.value[submenuName].link.opened =  false
		}
	}
	const toggleSubmenu = (submenuName: string) => {
		if(pages.value[submenuName]?.link) {
			pages.value[submenuName].link.opened =  !pages.value[submenuName].link.opened
		}
	}
	const hasChildren = (pageIndex: string) => {
		for (let page in pages.value) {
			if (pages.value[page].options?.parent == pageIndex) {
				return true
			}
		}
		return false
	}
	const useAddLink = (page: any) => {
		let result: boolean = false
		if (page.addLink != undefined) {
			result = true
			if (page.addLink.forLicenses) {
				if (!licenseHelpers.hasOneOfLicenses(activeAccountId.value, page.addLink.forLicenses) === true) {
					result = false
				}
			}
			if ((page.text?.plural == "Other devices (VPN)" || page.text?.plural == "Devices") && useStore().state.session.accounts[activeAccountId.value].mobileSecurity.settings.enrollmentAllowed === false) {
				result = false
			}
			if(page.addLink.conditions) {
				if(!jsonFormHelpers.areConditionsFulfilled(this, page.addLink.conditions, undefined)) {
					result = false
				}
			}
		}
		return result
	}
	const checkAddLink = (addLinkText: string|undefined) => {
		let result: boolean = true
		if((addLinkText == "Add role" || addLinkText == "Add user") && useAzureStore().isAzureImporting) {
			result = false
		}
		return result
	}

	defineExpose({
		getUrlPath,
		doRefresh,
		openSubmenu,
		closeSubmenu,
		toggleSubmenu
	})

</script>
<template>
	<nav class="menu-vertical" style="z-index:5">
		<ul class="sidebar-menu tenants" data-widget="tree">
			

			<template v-for="page,key in getPages()">
				<template v-if="(page.options?.type || null) == 'title'">
					<li class="title" v-html="T(page.text?.singular)">
					</li>
				</template>
				<template v-else-if="(page.options?.type || null) == 'accountSelection'">
					<li class="select hide-collapsed margin-xs-y" v-if="getObjectLength(accounts) > 1">
						<div id="selector" class="has-feedback clearfix">
							<i class="fal fa-building form-control-feedback"></i>
							<sidebar-accountselect></sidebar-accountselect>
						</div>
					</li>
				</template>
				<template v-else>
					<li
						v-bind:class="{'treeview':hasChildren(String(key)),'active': activePage == key,'menu-open': page.link?.opened}"
					>
						<a
							:href="page.link?.href?.replace('{tenantDomain}',activeTenantDomain)"
							:target="page.link?.target"
							:title="T(page.text?.sidebar || page.text?.plural)"
							:id="'linkTo'+key"
							:onclick="page.link?.onClick"
						>
							<i :class="page.appearance?.icon" class="fa-fw"></i>
							<span class="title">
								{{ T(page.text?.sidebar || page.text?.plural) }}
								<template v-if="page.appearance?.labels">
									<template v-for="label in page.appearance?.labels">
										<small class="label" :class="label.class" :title="T(label.title)">
											{{ T(label.text) }}
										</small>
									</template>
								</template>
							</span>
							<span class="pull-right-container hide-collapsed" v-if="hasChildren(String(key))">
								<i class="fal fa-fw fa-angle-left"></i>
							</span>
							<a class="addLink" style="float:right" v-if="useAddLink(page) && checkAddLink(page.text?.add)" :title="T(page.text?.add)" :href="page.addLink?.href?.replace('{tenantDomain}',activeTenantDomain)">
								<i class="fal fa-fw fa-plus-circle"></i>
							</a>
						</a>

						<ul v-if="hasChildren(String(key)) == true" class="treeview-menu">
							<li v-for="childPage,childKey in getPages(key)" v-bind:class="{'treeview':hasChildren(String(childKey)),'active': activePage == childKey,'menu-open': childPage.link?.opened}">
								<a
									:href="childPage.link?.href?.replace('{tenantDomain}',activeTenantDomain)"
									:target="childPage.link?.target"
									:title="T(childPage.text?.sidebar || childPage.text?.plural)"
									:id="'linkTo'+childKey"
									:onclick="childPage.link?.onClick"
								>
									<i :class="childPage.appearance?.icon" class="fa-fw"></i>
									<span class="title">
										{{ T(childPage.text?.sidebar || childPage.text?.plural) }}

										<template v-if="childPage.appearance?.label">
											<small class="label" :class="childPage.appearance?.label.class" :title="T(childPage.appearance?.label.title)">
												{{ T(childPage.appearance?.label.text) }}
											</small>
										</template>
										<template v-if="childPage.appearance?.labels">
											<template v-for="label in childPage.appearance?.labels">
												<small class="label" :class="label.class" :title="T(label.title)">
													{{ T(label.text) }}
												</small>
											</template>
										</template>
									</span>
									<span class="pull-right-container hide-collapsed" v-if="hasChildren(String(childKey))">
										<i class="fal fa-fw fa-angle-left"></i>
									</span>
									<a class="addLink" style="float:right" v-if="useAddLink(childPage) && checkAddLink(childPage.text?.add)" :title="T(childPage.text?.add)" :href="childPage.addLink?.href?.replace('{tenantDomain}',activeTenantDomain)">
										<i class="fal fa-fw fa-plus-circle"></i>
									</a>
								</a>

								<ul v-if="hasChildren(String(childKey)) == true" class="treeview-menu">
									<li v-for="childChildPage,childChildKey in getPages(childKey)" v-bind:class="{'treeview':hasChildren(String(childChildKey)),'active': activePage == childChildKey,'menu-open': childPage.link?.opened}">
										<a
											:href="childChildPage.link?.href?.replace('{tenantDomain}',activeTenantDomain)"
											:target="childChildPage.link?.target"
											:title="T(childChildPage.text?.sidebar || childChildPage.text?.plural)"
											:id="'linkTo'+childChildKey"
											:onclick="childChildPage.link?.onClick"
										>
											<i :class="childChildPage.appearance?.icon" class="fa-fw"></i>
											<span class="title">
												{{ T(childChildPage.text?.sidebar || childChildPage.text?.plural) }}

												<template v-if="childChildPage.appearance?.label">
													<small class="label" :class="childChildPage.appearance?.label.class" :title="T(childChildPage.appearance?.label.title)">
														{{ T(childChildPage.appearance?.label.text) }}
													</small>
												</template>
												<template v-if="childChildPage.appearance?.labels">
													<template v-for="label in childChildPage.appearance?.labels">
														<small class="label" :class="label.class" :title="T(label.title)">
															{{ T(label.text) }}
														</small>
													</template>
												</template>

											</span>
											<a class="addLink" style="float:right" v-if="useAddLink(childChildPage) && checkAddLink(childChildPage.text?.add)" :title="T(childChildPage.text?.add)" :href="childChildPage.addLink?.href?.replace('{tenantDomain}',activeTenantDomain)">
												<i class="fal fa-fw fa-plus-circle"></i>
											</a>
										</a>

									</li>
								</ul>

							</li>
						</ul>

					</li>
				</template>
			</template>
		</ul>
	</nav>
</template>
<style lang="scss">
	#main-sidebar nav ul li a .label {
		padding:0 4px;
		margin-right:1px;
		top:-1px;
		position: relative;
		transition: 0.3s;
	}
	#main-sidebar nav ul li.active > a .label {
		font-weight:300;
	}
	#main-sidebar nav ul li:not(.menu-open) ul li a .label {
		padding:0;
		font-size: 0;
		line-height: 0;
		min-height: 0px;
		border:0;
	}
</style>
