import { T } from "@/classes/i18n";
import ObjectType, { type GetPropertiesObjectList, type ItemlistItemDetail, type Label, type MenuEntry, type ObjectTypeObject, type ObjectTypePayload } from "../../objectType";
import tenantHelpers from "@/helpers/helpers.tenants";
import router, { useRouter } from "@/router/router";
import Button from "@/templates/components/button/button";
import products from "../..";
import numberHelpers from "@/helpers/helpers.numbers";
import deviceHelpers from "@/helpers/helpers.devices";
import jsonHelpers from "@/helpers/helpers.json";
import { MutationTypes, useStore } from "@/store/vuex.store";
import useRouterStore from "@/router/routerStore";
import requestHandler from "@/queries/requests";
import encodingHelpers from "@/helpers/helpers.encoding";
import devLog from "@/classes/log";

export type WindowsProfileSunInfos = Array<{
  "topologyId": string
  "coreUtmName": string
  "coreUtmId": string
  "pools": Array<{
    "id": string,
    "name": string,
    "serverNodeId": string,
    "transferNetwork": string,
    "transferNetworkCapacity": number,
    "numberOfDevices": number,
    "entities": Array<{
          "id": string,
          "entityId": string,
          "type": "WINDOWS_PROFILE"|"ANDROID_PROFILE"|"IOS_PROFILE",
          "vpnOnDemand": boolean,
          "new": boolean
    }>,
    "rules": Array<{
        "id": string
        "src": {
          "utmId": string
          "node": {
            "name": string
            "static_node_addresses":Array<string>
          }
        },
        "dst": {
          "utmId": string
          "node": {
            "id": number
            "name": string
            "type": number
            "owner_id": number
            "permissions": number
            "refby_uuids": Array<string>,
            "node_address": string
            "node_zone": {
              "id": number
              "name": string
              "description": string
              "type": number
              "owner_id": number
              "permissions": number
              "flags": Array<any>,
              "zone_interface": string
            },
            "static_node_addresses": Array<string>
          },
          "service": {
            "id": number
            "name": string
            "description": string,
            "type": number
            "owner_id": number
            "permissions": number
            "refby_uuids": Array<string>,
            "service_refs": Array<string>
          }
        },
        "flags": Array<string>
      }>,
    "errors": Array<any>,
    "state": string
    "serverInterfaceInfos": {
      "listenPort": number,
      "publicKey": string
    }
  }>
}>

export interface WindowsProfile {
    "comment": "",
    "devices": string[],
    "id": string,
    "name": string,
    "priority": number,
    "tags": string[],
    "tenantDomain": string,
    "profile": WindowsProfileContent,
    "sun"?:WindowsProfileSunInfos
}
export interface WindowsProfileContent {
    "version": string,
    "settings": {
        "start": {
            "startOnWindows": boolean,
            "startMinimized": boolean,
            "hideMigrationAssistant": boolean,
            "startMinimalGui": boolean,
        },
        "connections": {
            "minimizeAfterConnect": boolean,
            "allowMultipleConnections": boolean,
            "blockShutdown": boolean,
            "showPopupWhenMinimized": boolean,
        },
        "appearance": {
            "darkmode": "system" | "dark" | "light",
            "language": "system|en|de"
        },
        "advanced": {
            "enableAutoUpdates": boolean,
            "preventDnsLeaks": boolean,
        }
    },
    "restrictions": {
        "allowAddingConnections": boolean,
        "allowEditingConnections": boolean,
        "allowEditingClientSettings": boolean
    },
    "vpn":{
        "wireGuard":Array<any>
    }
}
export interface WindowsProfilePublishState {
    "content": {
        "payload": {
        "type": string,
        "clientContext": string,
        "data": {
            "name": string,
            "data": {
            "version": string,
            "settings": {
                "appearance": {
                "darkmode": "system" | "dark" | "light",
                "language": string
                },
                "start": {
                "startOnWindows": boolean,
                "startMinimized": boolean,
                "hideMigrationAssistant": boolean,
                "startMinimalGui": boolean
                },
                "connections": {
                "minimizeAfterConnect": boolean,
                "allowMultipleConnections": boolean,
                "blockShutdown": boolean,
                "showPopupWhenMinimized": boolean
                },
                "advanced": {
                "enableAutoUpdates": boolean,
                "preventDnsLeaks": boolean
                }
            },
            "restrictions": {
                "connectionDeactivateAllowed": boolean,
                "connectionDeleteAllowed": boolean,
                "connectionExportAllowed": boolean,
                "connectionEditAllowed": boolean,
                "connectionAddNewCertificatesAllowed": boolean,
                "connectionCredentialsDeleteAllowed": boolean,
                "connectionCredentialsModifyAllowed": boolean,
                "connectionsCreateSSLVPNAllowed": boolean,
                "connectionsCreateWireGuardAllowed": boolean,
                "connectionsImportSSLVPNAllowed": boolean,
                "connectionsImportWireGuardAllowed": boolean,
                "settingsSetMultiConnectionsAllowed": boolean,
                "settingsSetPreventDnsLeaskAllowed": boolean,
                "settingsSetClientOnSystemStartAllowed": boolean,
                "settingsSetBaloonNotifyAllowed": boolean,
                "settingsSetDisplayModeAllowed": boolean,
                "settingsSetAutoUpdateAllowed": boolean
            }
            }
        },
        "profileId": string
        },
        "type": string,
        "status": string,
        "messageId": string,
        "timestamp": number
    },
    "created": number,
    "deviceId": string,
    "queue": string,
    "status": string,
    "statusText": string,
    "type": string,
    "updated": number
}
      

class WindowsProfiles extends ObjectType<WindowsProfile> {
    constructor(payload: ObjectTypePayload<WindowsProfile>) {
        super(payload)
        const thisObjectType = this


        // ITEMLIST
        thisObjectType.itemlist.getToolbarButtons = (accountId: string, itemlistComponent: any) => {
            let toolbarButtons = []
            toolbarButtons.push(
                {
                    icon: 'fal fa-plus',
                    title: T('Add profile'),
                    link: '#add-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-windows-profiles',
                    id: 'profilesButtonAdd',
                    vIf: false
                }
            )
            return toolbarButtons
        }
        thisObjectType.itemlist.getSortingOptions = (accountId: string, itemlistComponent: any) => {
            let sortingOptions = [
                {
                    "id": "name",
                    "text": T("Name")
                },
                {
                    "id": "priority",
                    "text": T("Priority")
                }
            ]
            return sortingOptions
        }
        thisObjectType.itemlist.getInfoBoxContent = (accountId: string, itemlistComponent) => {
            return ""
        }


        thisObjectType.itemlistItem.getMenuEntries = (accountId: string, item: ObjectTypeObject<WindowsProfile>, component: any) => {
            let menuLinks: MenuEntry[] = []
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
            const thisClass = this

            menuLinks.push(new Button({
                id: 'edit',
                title: T('Edit'),
                text: T('Edit'),
                link: '#edit-tenant-' + tenantDomain + '-windows-profiles-' + item?.id,
                icon: 'fal fa-edit',
            }))
            menuLinks.push(new Button({
                id: 'copy',
                title: T('Copy'),
                text: T('Copy'),
                onClick: function () {
                    thisClass.saveToClipboard(item)
                },
                icon: 'fal fa-clone'
            }))
            menuLinks.push(new Button({
                id: 'delete',
                title: T('Delete'),
                text: T('Delete'),
                onClick: () => {
                    this.dialogs.getDeleteObjectDialog(accountId, item)
                },
                icon: 'fal fa-trash',
            }))
            return menuLinks
        }
        thisObjectType.itemlistItem.getDetails = (accountId: string, item: ObjectTypeObject<WindowsProfile>, component: any) => {
            let result: ItemlistItemDetail[] = []
            const objectId: string | undefined = item?.id
            const clients = products.unifiedSecurityConsole.windowsVpns.useStore?.().getObjectStoreObjects(accountId) || []

            const thisClients = clients.filter((client) => {
                return item?.devices?.includes(client.id)
            }).map((client) => {
                return {
                    "text": deviceHelpers.getAliasedShortDeviceId(client.id, client.alias, false),
                    "title": deviceHelpers.getAliasedShortDeviceId(client.id, client.alias, false),
                    "displayType": "label",
                    "onClick": () => {
                        router.navigate('#show-tenant-' + accountId + '.sms-windows-vpns-' + client.id + '-details')
                    }
                }
            })
            const thisTagsArray = item?.tags?.map(function (tag) {
                return {
                    "id": tag,
                    "text": tag,
                    "title": tag,
                    "onClick": function () { },
                    "displayType": "label"
                }
            })

            result.push({
                iconClass: 'fal fa-fw fa-hashtag',
                title: T('ID'),
                key: T('ID'),
                value: item ? (objectId) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
            })

            result.push({
                "iconClass": "fal fa-tags",
                "key": T("Tags"),
                "title": T("Tags"),
                [item ? "labels" : "value"]: item ? thisTagsArray : '<span class="content-placeholder" style="width:' + numberHelpers.getRandomArbitrary(50, 250) + 'px;" title="' + T("Loading...") + '"></span>',
                "editableContent": undefined
            })

            result.push({
                "iconClass": "fal fa-tags",
                "key": T("Clients"),
                "title": T("Clients"),
                [item?.devices?.length ? "labels" : "value"]: item?.devices?.length ? thisClients : "",
                "editableContent": undefined
            })

            result.push({
                iconClass: "fal fa-fw fa-comment",
                title: T("Comment"),
                key: T("Comment"),
                value: item ? item.comment : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
            })

            return result
        }
        thisObjectType.itemlistItem.getDisabledState = (accountId: string, item: ObjectTypeObject<WindowsProfile>) => {
            return false;
        }
        thisObjectType.itemlistItem.getLabels = (accountId: string, item: ObjectTypeObject<WindowsProfile>) => {
            let thisLabels: Label[] = []

            if (item?.priority != null) {
                thisLabels.push({
                    'title': T('Priority') + ': ' + item?.priority.toString(),
                    'text': item.priority.toString(),
                    'class': '',
                    'icon': "fal fa-sort"
                })
            }


            return thisLabels
        }
        thisObjectType.itemlistItem.getTitle = (item: ObjectTypeObject<WindowsProfile>, component: any) => {
            let result: any = {
                "title": "",
                "small": undefined,
                "link": undefined
            }
            result.title = item?.name ? item.name : item?.id
            return result
        }
        thisObjectType.itemlistItem.onClick = (accountId: string, item: ObjectTypeObject<WindowsProfile>) => {
            if (item?.id) {
                router.navigate('#edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-windows-profiles-' + item?.id)
            }
        }
        this.itemlistItem.hasCheckbox = true
    }
    saveToClipboard(item: WindowsProfile) {
        let thisItem = jsonHelpers.copyObject(item)
        thisItem = {
            oldItemName: thisItem.name,
            name: thisItem.name + T("_Copy"),
            profile: thisItem.profile
        }

        useStore().commit(MutationTypes.setClipboard, {
            "objectType": useRouterStore().getActivePage || "",
            "objects": [thisItem]
        })
    }
    async getPublishMessages(accountId: string, objectId: string, props?: GetPropertiesObjectList): Promise<WindowsProfilePublishState[] | Error> {
        let result: any[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request("GET", this.getApiUrl() + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/windows/profiles/" + encodingHelpers.encodeURI(objectId as string) + "/messages" + propertiesString)
            if (response.code == 200) {
                result = response.data
            }
            else if (Array.isArray(response)) {
                result = response
            }
            else if (Array.isArray(response)) {
                result = response
            }
            else if (response.error) {
                throw new Error(response.error)
            }
            else {
                throw new Error('Error getting jobs history for utm')
            }
        }
        catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscProfilesStoreClass", message, result, "error")
            throw result
        }
        return result
    }



    async beforeSavingForm(accountId:string,object:WindowsProfile,objectId?:string,rootObject?:WindowsProfile) {
        // SUN
        if(object.sun && objectId) {
            let promises : Promise<any>[] = []
            object.sun.forEach((sunInfo:any) => {
                sunInfo.pools?.forEach((pool:any) => {
                    let entity = pool.entities.find((entity:any) => {
                        return entity.id == objectId || entity.id == objectId
                    })
                    if (entity) {
                        promises.push(products.unifiedSecurityConsole.topologies.updateProfilePoolEntities(accountId,sunInfo.topologyId,pool.id,entity.entityId,{ 'vpnOnDemand':entity.vpnOnDemand || false }))
                    }
                });	
            });

            if(promises.length > 0) {
                try { 
                    await Promise.all(promises) 
                } 
                catch(e) {

                }
            }
            
            if(rootObject?.profile.vpn) {
                object.profile.vpn = rootObject?.profile.vpn
            }
            
            object.sun?.forEach((sunInfo) => {
                const topologyId = sunInfo.topologyId
                sunInfo.pools.forEach((pool) => {
                    const poolId = pool.id
                    const serverNodeId = pool.serverNodeId
                    let entity = pool.entities.find((entity) => {                        
                        return entity.id == objectId
                    })
                    let wireguardInfo = object.profile.vpn.wireGuard.find((wireguard) => {
                        return wireguard.topologyId == topologyId && wireguard.id == poolId
                    })
                    wireguardInfo.options.isMonitoring = entity?.vpnOnDemand

                })
            })

            /*
                object.profile?.vpns?.forEach((vpn:any) => {
                    vpn.wireGuard.forEach((wireguard:any) => {
                        object.sun?.forEach((sunInfo:any) => {
                            sunInfo.pools?.forEach((pool:any) => {
                                if(pool.id == wireguard.id) {
                                    let entity = pool.entities.find((entity:any) => {
                                        return entity.id == this.objectId
                                    })
                                    entity.vpnOnDemand = wireguard.options.isMonitoring
                                }
                            });	
                        });
                    });
                });
            */

            delete object.sun
        }
    }
}


const windowsProfiles = new WindowsProfiles({
    "productType": "unifiedSecurityConsole",
    "slug": "windowsProfiles",
    "objectType": "windowsProfiles",
    "hasStore": true,
    "appearance": {
        "iconClass": "fal fa-shield-alt",
        "text": {
            "plural": "Windows VPN-Profiles",
            "title": "Windows VPN-Profile",
            "sidebarName": "Windows VPN-Profiles",
            "singular": "Windows VPN-Profile"
        },
        "color": "blue",
        "showInSidebar": true,
        "showOnDashboard": true,
    },
    "objectTypeInfo": {
        "primaryKeyProperty": {
            "property": "id",
            "pathToPrimaryProperty": undefined
        },
        "nameProperty": {
            "primary": "name",
            "pathToPrimaryProperty": undefined,
            "secondary": "id",
            "pathToSecondaryProperty": undefined
        }
    },
    "apiInfo": {
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=data.count",
        // GET
        "getObjectListResponseProperty": "profiles",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/windows/profiles",
        // POST
        "addObjectMethod": "POST",
        "addObjectPath": "/tenants/{tenantDomain}/windows/profiles",
        // PUT
        "updateObjectMethod": "PUT",
        "updateObjectPath": "/tenants/{tenantDomain}/windows/profiles/{objectId}",
        // DELETE
        "deleteObjectMethod": "DELETE",
        "deleteObjectPath": "/tenants/{tenantDomain}/windows/profiles/{objectId}"
    },
})
export default windowsProfiles