import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "../components/loader.vue";
import queries from "@/queries/queries";
import { ActionTypes, MutationTypes } from "@/store/vuex.store";
import { T } from "@/classes/i18n";
import moment from "moment"
import $ from "jquery"
import { useStore } from '@/store/vuex.store'
import dialogs from "@/dialogs/dialogs";
import jsonHelpers from "@/helpers/helpers.json";
import tenantHelpers from "@/helpers/helpers.tenants";
import inputVueSelectVue from "../inputtypes/input-vue-select.vue";
import mixinHelpers from "@/helpers/helpers.mixins";
import products from "@/classes/objectTypes";
import config from "@/classes/config";
import objectStores from "@/classes/init";
import getterHelpers from "@/helpers/helpers.getters";


const androidInviteDialogComponent = {
    mixins: [useGlobalMixin()],
    "name":"android-invite-dialog",
    template: `
        <div>
            <template v-if="initialized">
                <template v-if="tenant.mobileSecurity.settings.android.enterprise.name != undefined">
                    <div class="row padding-xs-y form-group border-bottom" v-if="false">
                        <div class="first col-xs-24 col-lg-3">
                            <label
                                class="control-label inputname"
                                for="enrollmentType"
                            >
                                {{ T('Enrollment type') }}
                            </label>
                        </div>
                        <div class="input col-xs-24 col-lg-12">
                            <label>
                                <select v-model="enrollmentType" id="enrollmentType">
                                    <option value="0">
                                        {{ T('iOS device enrollment') }}
                                    </option>
                                    <option value="1" v-if="tenant.mobileSecurity.settings.ios.apns.expired === false &&  false">
                                        {{ T('iOS user enrollment') }}
                                    </option>
                                    <option value="2" v-if="tenant.mobileSecurity.settings.android.enterprise.name != undefined">
                                        {{ T('Android Enterprise enrollment') }}
                                    </option>
                                </select>
                            </label>
                        </div>
                        <div class="desc col-xs-24 col-lg-9">
                            <p class="input-description">
                                {{ T('Enrollment type') }}
                            </p>
                        </div>
                    </div>
                    
                    <template v-if="['0','1'].indexOf(enrollmentType) == -1">
                        <template v-if="enterprise.enrollmentTokens.length > 0">
                            <div class="row padding-xs-y form-group border-bottom">
                                <div class="first col-xs-24 col-lg-3">
                                    <label class="control-label padding-xs-t-2" for="useEnrollmentToken" style="padding-top:0">
                                        {{T('Enrollment token')}}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-12">
                                    <select id="useEnrollmentToken" v-model="enterprise.selectedToken.index" :disabled="enterprise.loading || null">
                                        <option value="none">
                                            {{T('Please select an enrollment token')}}
                                        </option>
                                        <template v-for="enrollmentToken,index in enterprise.enrollmentTokens">
                                            <template v-if="!enrollmentToken.expired">
                                                <option :value="index">
                                                    {{ T('Profile') + ': ' + enrollmentToken.policyName.split('/')[3] + ' | ' + enrollmentToken.name.split('/')[3] }}
                                                </option>
                                            </template>
                                        </template>
                                    </select>
                                </div>
                                <div class="desc col-xs-24 col-lg-9">
                                    <p class="input-description">
                                        {{ T('Choose an enrollent token') }}
                                    </p>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="row padding-xs-y form-group border-bottom">
                                <div class="first col-xs-24">
                                    <p class="notification">
                                        <i class='fas fa-exclamation-triangle color-red'></i> <strong>{{ T('No enrollment token found') }}</strong><br>
                                        {{ T('Create an enrollment token first, in order to invite/enroll android enterprise devices.') }}
                                        <a v-on:click="openEnrollmentDialog()">
                                            <i class="fal fa-link"></i> {{ T('Open enrollment dialog') }}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </template>


                        <template v-if="enterprise.selectedToken.index != 'none'">
                            <div class="row padding-xs-y form-group">
                                <div class="first col-xs-24 col-lg-3">
                                    <label class="control-label">
                                        {{T('Profile:')}}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-12">
                                    <input type="text" readonly disabled :value="enterprise.enrollmentTokens[enterprise.selectedToken.index].policyName.split('/')[3]">
                                </div>
                            </div>
                            <div class="row padding-xs-y form-group">
                                <div class="first col-xs-24 col-lg-3">
                                    <label class="control-label">
                                        {{T('License:')}}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-12">
                                    <input type="text" readonly disabled :value="getLicenseTypeFromAdditionalData(enterprise.enrollmentTokens[enterprise.selectedToken.index].additionalData)">
                                </div>
                            </div>
                            <div class="row padding-xs-y form-group">
                                <div class="first col-xs-24 col-lg-3">
                                    <label class="control-label">
                                        {{T('Expires on:')}}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-12">
                                    <input type="text" readonly disabled :value="getDate(enterprise.enrollmentTokens[enterprise.selectedToken.index].expirationTimestamp)">
                                </div>
                            </div>
                            <div class="row padding-xs-y form-group border-bottom">
                                <div class="first col-xs-24 col-lg-3">
                                    <label class="control-label">
                                        {{T('Value:')}}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-12">
                                    <input type="text" readonly disabled v-model="enterprise.enrollmentTokens[enterprise.selectedToken.index].value">
                                </div>
                            </div>
                        </template>
                    </template>


                    <div class="row padding-xs-y form-group border-bottom">
                        <div class="first col-xs-24 col-lg-3">
                            <label
                                class="control-label inputname"
                                for="emails"
                                v-html="['0','2'].indexOf(enrollmentType) != -1 ? T('Emails') : T('Managed Apple IDs')"
                            ></label>
                        </div>
                        <div class="input col-xs-24 col-lg-12">
                            <label>
                                <input-vue-select
                                    v-model="value"
                                    :selectOptions="options"
                                    :placeholder="T('Emails')"
                                    :multiple="true"
                                    :tags="true"
                                    id="emails"
                                >
                                </input-vue-select>
                            </label>
                        </div>
                        <div class="desc col-xs-24 col-lg-9">
                            <p class="input-description">
                                {{ T('Choose a user or enter an email address') }}
                            </p>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="row padding-xs-y form-group border-bottom">
                        <div class="first col-xs-24">
                            <p class="notification">
                                <i class='fas fa-exclamation-triangle color-red'></i>
                                <strong>
                                    {{ T('No invite/enrollment type usable') }}
                                </strong><br>
                                {{ T('Please set up android enterprise functionality.') }}
                            </p>
                            <a :href="'#show-tenant-'+activeTenantDomain+'-mobile-security-settings'" class="btn btn-red">
                                <i class="fal fa-cogs"></i> {{ T('Set up now') }}
                            </a>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else-if="!initialized">
                <div class="text-size-3 text-center padding-xs-t-4">
                    <loader class="color-red"></loader>
                </div>
                <template v-if="loaderText">
                    <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
                        <span>{{ T(loaderText) }}</span>
                    </div>
                </template>
            </template>
        </div>
    `,
    data: function() {
        return {
            "initialized":false,
            "loaderText":"Loading...",
            "value":[],
            "options":[],
            "enrollmentType":"2",
            "license": {
                "licenseOptions":[],
                "selected":"",
                "disabled":false
            },
            "enterprise": {
                "enabled":false,
                "selectedToken": {
                    "index":"none",
                    "type":"DEVICE_OWNER",
                },
                "enrollmentTokens":[],
                "generateNewToken":{
                    "policy":{
                        "options":[],
                        "value":"",
                    }
                }
            }
        }
    },
    computed: {
        "tenant": function(this:any) {
            return useStore().state.session.accounts[this.activeAccountId] || undefined
        }
    },
    methods: {
        "T":function(content:string) {
            return T(content)
        },
        "getDate":function(this:any, date:string) {
            return moment(date).format('DD.MM.YYYY HH:mm')
        },
        "isExpired":function(this:any,expirationDate:string) {
            return !moment(expirationDate).isSameOrAfter(moment())
        },
        "getLicenseTypeFromAdditionalData": function(this:any,additionalData:string) {
            let licenseUUID : string = jsonHelpers.getObjectProperty(JSON.parse(additionalData),"licenseUUID") || ""
            let licenseType = "Unknown"

            if(licenseUUID != "") {
                let licenses: any = (config.canUseNewObjectType("licenses")) ? products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(this.activeAccountId) : this.tenant.unifiedSecurity.licenses.items || []
                let foundLicenses = licenses.filter(function(license:any) {
                    return license.uuid == licenseUUID
                })
                if(foundLicenses.length == 1) {
                    licenseType = foundLicenses[0].type
                }
            }
            return licenseType
        },
        "updateButtonState": function(this:any) {
            if(["1","2"].indexOf(this.enrollmentType) != -1 && this.license.selected == '') {
                getterHelpers.useStore().getters.getActiveModal(getterHelpers.useStore().state.session.activeAccountId).buttons[1].disabled = true
            }
            else if(this.enrollmentType == 2 && this.enterprise.selectedToken.index == 'none') {
                getterHelpers.useStore().getters.getActiveModal(getterHelpers.useStore().state.session.activeAccountId).buttons[1].disabled = true
            }
            else if (this.value.length == 0) {
                getterHelpers.useStore().getters.getActiveModal(getterHelpers.useStore().state.session.activeAccountId).buttons[1].disabled = true
            }
            else  {
                getterHelpers.useStore().getters.getActiveModal(getterHelpers.useStore().state.session.activeAccountId).buttons[1].disabled = false
            }
        },
        "openEnrollmentDialog": function(this:any) {
            useStore().commit(MutationTypes.removeModal, { "accountId": this.activeAccountId })
            dialogs.mobileSecurity.enrollDeviceDialog(this.activeAccountId ,'enterpriseDevices')
        }
    },
    created: async function(this : any) {
        getterHelpers.useStore().getters.getActiveModal(getterHelpers.useStore().state.session.activeAccountId).buttons[1].disabled = true

        let activeTenantDomain : any = this.activeTenantDomain
        let thisComponent : any = this

        // LICENSE SELECTION
        this.loaderText = "Getting licenses..."

        await useStore().dispatch(ActionTypes.getObjectInfos,{
            "accountId":this.activeAccountId,
            "objectTypes":["licenses"]
        })

        let licenses : object[] = [],
        licenseOptions : any[] = []

        licenses = (config.canUseNewObjectType("licenses") ? products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(this.activeAccountId) : objectStores.licenses.getObjectsFromStore(this.activeAccountId)) || []

        for(let i : number = 0; licenses.length > i; i++) {
            let license : any = licenses[i]
            let licenceUser = mixinHelpers.getLicenseLimit(license)
            if((license.type == 'Mobile Security' || license.type == 'MDM') && license.uuid && license.assignedUser < licenceUser && !license.expired && !license.dead) {
                licenseOptions.push({
                    "id":license.uuid,
                    "text":license.name+" | "+license.type+" ["+license.assignedUser+"/"+licenceUser+"] ("+license.uuid.substring(0,4)+")",
                    "disabled":license.assignedUser >= licenceUser || license.expired || license.dead,
                    "licenseType":license.type
                })
            }
        }
        this.license.licenseOptions = licenseOptions

        if(licenseOptions.length) {
            this.license.selected = licenseOptions[0].id
        }

        this.loaderText = "Getting registered Email addresses..."
        try {
            let users : any = {}
            if(this.tenant.unifiedSecurity.users.length > 0) {
                users = JSON.parse(JSON.stringify(this.tenant.unifiedSecurity.users))
            }
            else {
                if (config.canUseNewObjectType("users")) {
                    await products.unifiedSecurity.users.queries.getObjectsFromApi(this.activeAccountId)
                }
                else {
                    await useStore().dispatch(ActionTypes.getObjectInfos, {
                        "accountId": this.activeAccountId,
                        "objectTypes": ["users"]
                    })
                }

                users = config.canUseNewObjectType("users") ? products.unifiedSecurity.users.useStore?.()?.getObjectStoreObjects(this.activeAccountId) : useStore().getters.getObjectTypeStore({
                    "accountId": this.activeAccountId,
                    "objectType": "users"
                })?.items || []
            }
            if(users.length) {
                let leadingZeros : number = users.length.toString().length

                for(let userIndex in users) {
                    let user : any = users[userIndex]
                    this.options.push(
                        {
                            "text": user.username +'<'+user.profile.email+'>',
                            "id": user.profile.email +"#usrNr"+((Array(leadingZeros + 1).join("0") + userIndex).slice(-leadingZeros))
                        }
                    )
                }
            }
        }
        catch(e:any) {
            console.error(e)
            this.loaderText = "<i class='fas fa-exclamation-triangle color-red'></i> Something went wrong"
        }


        if (tenantHelpers.hasFunctionality(this.activeAccountId,'enterprise')) {
            this.enterprise.enabled = true
            this.enterprise.disabledReason = ""

            this.loaderText = "Getting enterprise policies..."
            let activeAccountId = useStore().state.session.activeAccountId
            if (!activeAccountId) {
                throw "Missing accountId"
            }
            let enterprisePolicies : any = await queries.mobileSecurity.getObjectInfos(activeAccountId,'enterpriseProfiles')
            enterprisePolicies = enterprisePolicies.policies
            enterprisePolicies.map(function(emmProfile : any, emmProfileIndex : number) {
                if(emmProfile.name.split("/")[3] != 'unlicensed') {
                    thisComponent.enterprise.generateNewToken.policy.options.push(
                        {
                            "id":emmProfile.name.split("/")[3],
                            "text":emmProfile.name.split("/")[3]
                        }
                    )
                    if(emmProfileIndex == 0) {
                        thisComponent.enterprise.generateNewToken.policy.value =  emmProfile.name.split("/")[3]
                    }
                }
            })

            this.loaderText = "Getting enrollment tokens..."
            let enrollmentTokens: any = await queries.mobileSecurity.getEnterpriseEnrollmentTokens(thisComponent.activeAccountId)
            for(let enrollmentTokenIndex in enrollmentTokens.enrollmentTokens) {
                enrollmentTokens.enrollmentTokens[enrollmentTokenIndex].expired = false
                if (this.isExpired(enrollmentTokens.enrollmentTokens[enrollmentTokenIndex].expirationTimestamp)) {
                    enrollmentTokens.enrollmentTokens[enrollmentTokenIndex].expired = true
                }
                this.enterprise.enrollmentTokens.push(enrollmentTokens.enrollmentTokens[enrollmentTokenIndex])
            }
        }
        else {
            this.enterprise.disabledReason = '<i class="fal fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;Android: '+T('Android Enterprise is not set up')
            this.enterprise.enabled = false
        }


        if(this.$parent.passData != undefined) {
            this.value = this.$parent.passData
        }
        this.loaderText = "Finished loading..."
        this.initialized = true
    },
    mounted: function(this:any) {
        $('#device-assign-alias').focus()
    },
    watch: {
        "value": function(this:any) {
            this.updateButtonState()
        },
        "enrollmentType": function(this:any) {
            this.updateButtonState()
        },
        "license.selected": function(this:any) {
            this.updateButtonState()
        },
        "enterprise.selectedToken.index": function(this:any) {
            this.updateButtonState()
        }
    },
    beforeCreate(this: any) {
    },
    "components": {
        "loader": loaderComponent,
        "input-vue-select": inputVueSelectVue
    }
}
export default androidInviteDialogComponent