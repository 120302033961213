import ObjectType, { type ObjectTypePayload } from "../../objectType"
import type { AssetInfo } from "./iosApps"
import dialogs from "@/dialogs/dialogs"
import numberHelpers from "@/helpers/helpers.numbers"
import router from "@/router/router"
import licenseHelpers from "@/helpers/helpers.license"
import jsonHelpers from "@/helpers/helpers.json"
import queries from "@/queries/queries"
import { T } from "@/classes/i18n"
import vppApi from "./vpp"
import apis from "@/classes/apis"
import devLog from "@/classes/log"
import timeHelpers from "@/helpers/helpers.time"

export interface WebsocketVppAssetManagmentData {
    "adamId": string,
    "countDelta": number,
    "pricingParam": string
}


export interface VppAsset {
    adamId:string,
    assignedCount: number,
    availableCount: number,
    deviceAssignable: boolean,
    pricingParam:"STDQ"|"PLUS",
    productType:"App"|"Book",
    retiredCount: number,
    revocable: boolean,
    totalCount: number,
    supportedPlatforms: ("iOS" | "macOS" | "tvOS" | "watchOs")[],

    assetInfo?:AssetInfo
}


class VppAssets extends ObjectType<VppAsset> {
    constructor(payload: ObjectTypePayload<VppAsset>) {
        super(payload)
        const thisClass = this
        
        this.itemlist.getSortingOptions = () => {
            return [
                {
                    "id": "assetInfo.trackName",
                    "text": T("Name")
                }
            ]
        }

        this.itemlist.getToolbarButtons = (accountId) => {
            return [
                {
                    icon: 'fal fa-link',
                    title: T('Assign Licenses'),
                    onClick: () => {
                        dialogs.mobileSecurity.renderVPPLicenseAssignmentDialog(accountId)
                    },
                    id: 'appsButtonAdd',
                    vIf: false
                }
            ]
        }

        this.itemlistItem.getDetails = (accountId,item,component) => {
            return [
                {
                    iconClass: 'fal fa-fw fa-key',
                    title: T('Available licenses'),
                    key: T('Available licenses'),
                    value: item ? item.availableCount + '/' + item?.totalCount : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: 'fal far-fw fa-money-bill-alt',
                    title: T('Pricing'),
                    key: T('Pricing'),
                    value: item ? item.pricingParam : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                }
            ]
        }

        this.itemlistItem.getIconBackgroundImage = (accountId,item) => {
            if (item?.assetInfo?.artworkUrl60) {
                return 'background-image:url(' + item?.assetInfo.artworkUrl60 + ')'
            }
            return null
        }
        this.itemlistItem.onClick = (accountId,item) => {
            if (licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid') && item?.adamId) {
                router.navigate('#show-tenant-' + accountId + '.sms-vpp-asset-' + item?.adamId + '-details')
            }
        }
        this.itemlistItem.getTitle = (item,component) => {
            let result = {
                title: item?.assetInfo?.trackName ? item.assetInfo.trackName : item?.adamId || "Undefined",
            }
            return result
        }
        this.itemlistItem.getLabels = (accountId,item) => {
            let thisLabels = []
            if (!item?.assetInfo?.isInStore) {
                thisLabels.push({
                    title: T("Not in App Store"),
                    text: T("Not in App Store"),
                    class: 'bg-yellow',
                    icon: "fa fa-bell"
                })
            }
            return thisLabels
        }

        

        this.replaceStoreFunctionWith.setObjectTypeObjects = async (accountId,objects) => {
            let accountObjectStore = thisClass.useStore?.().getObjectStore(accountId)
            let objectStore = thisClass.useStore?.()

            let objectTypeInfo = thisClass?.options.objectTypeInfo
            let idProperty = objectTypeInfo?.primaryKeyProperty.property
            let idPropertyPath = objectTypeInfo?.primaryKeyProperty.pathToPrimaryProperty
            if (accountObjectStore?.objects?.length) {
                if (idProperty) {
                    let existingObject: VppAsset | undefined = undefined
                    objects.forEach(async (object) => {
                        if (object && thisClass?.convertObjectForStore) {
                            thisClass.convertObjectForStore(accountId, object)
                        }
                        existingObject = undefined
                        // check if object already exists
                        const thisObjectId = (idPropertyPath ? jsonHelpers.getObjectProperty(object, idPropertyPath + "." + String(idProperty)) : object[idProperty as keyof typeof object])
                        existingObject = thisClass.useStore?.().getObjectStoreObject(accountId, thisObjectId)
                        if (existingObject != undefined) {
                            // replace
                            //@ts-ignore
                            Object.keys(object).forEach((key) => {
                                //@ts-ignore
                                existingObject[key as keyof T] = object[key as keyof T]
                            })
                        }
                        else if (accountObjectStore) {
                            // add object
                            let singleObjectList = [object]
                            try {
                                singleObjectList = await queries.mobileSecurity.vpp.completeAssetInfos([object], "adamId")
                            }
                            catch(e) {
                                console.error(e)
                            }
                            accountObjectStore.objects?.push(singleObjectList[0])
                        }

                    })
                }
            }
            else if (accountObjectStore) {
                try {
                    objects = await queries.mobileSecurity.vpp.completeAssetInfos(objects,"adamId")
                }
                catch(e) {
                    console.error(e)
                }
                objects.forEach((object) => {
                    if (object && thisClass?.convertObjectForStore) {
                        thisClass.convertObjectForStore(accountId, object)
                    }
                })
                accountObjectStore.objects = objects
            }
            
        }


        this.queries.getObjectsFromApi = async (accountId, customerId?, props?, updateStore = true) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let timestamp = store?.objectsTimestamp || 0
            let isRequestable = function() {
                 
                return store?.gettingObjects == false && timeHelpers.getUnixTimeStamp() - timestamp > 5 
            }()
            if((store && isRequestable) || store == undefined) {
                if(store) { 
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }
                const propertiesString: string = props ? this.getPropertiesString(props) : ""
                let result: VppAsset[] | Error
                try {
                    let response = await vppApi.getAllVppAssets(accountId)
                    if (Array.isArray(response)) {
                        result = response as VppAsset[]
                    }
                    else {
                        throw new Error("Error getting Objects")
                    }
                    if (updateStore) {
                        this.useStore?.().setObjectTypeObjects(accountId, result)
                    }
                    if(store) { store.gettingObjects = false }
                    return result
                }
                catch (e: any) {
                    if(store) { store.gettingObjects = false }
                    devLog.log("VppAssets", e.message, e, "error")
                    throw e as Error
                }
            }
            else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        }


    }
}

const vppassets = new VppAssets({
    "productType": "mobileSecurity",
    "slug": "vppassets",
    "objectType": "vppassets",
    "hasStore": true,
    "appearance": {
        "iconClass": "fab fa-apple",
        "text": {
            "plural": "VPP Licenses",
            "title": "VPP Licenses",
            "sidebarName": "VPP Licenses",
            "singular": "VPP License"
        },
        "color": "red",
        "showInSidebar": true,
        "showOnDashboard": true,
    },
    "objectTypeInfo": {
        "primaryKeyProperty": {
            "property": "adamId",
            "pathToPrimaryProperty": undefined
        },
        "nameProperty": {
            "primary": "trackName",
            "pathToPrimaryProperty": "assetInfo",
            "secondary": undefined,
            "pathToSecondaryProperty": undefined
        }
    },
    "apiInfo": {
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=data.currentPageSize",
        // GET
        "getObjectListResponseProperty": "assets",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/apple/vpp/v2/assets",
    }
})
export default vppassets