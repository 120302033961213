<script setup lang="ts">
import jsonHelpers from "../../helpers/helpers.json";
import apis from "../../classes/apis";
import { MutationTypes } from "@/store/vuex.store";
import cookies from "@/classes/cookieHandler";
import { computed, getCurrentInstance, nextTick, onBeforeMount, onMounted, provide, reactive, ref, watch } from "vue";
import { useStore } from '@/store/vuex.store';
import type { vuexClipboard } from '@/store/vuex.store';
import { T } from "@/classes/i18n";
import { GenericObjectStore } from "@/classes/genericObjectStore";
import type { PromiseObject } from "@/helpers/helpers.promises";
import { debounce, throttle } from "throttle-debounce";
import loaderComponent from "../components/loader.vue";
import { sprintf } from "sprintf-js";
import { ActionTypes } from "@/store/vuex.store";
import itemlistItemComponent from "../components/itemlist-item.vue";
import objectStores from "@/classes/init";
import queries from "@/queries/queries";
import moment from "moment";
import dialogs from "@/dialogs/dialogs";
import tenantHelpers from "@/helpers/helpers.tenants";
import { useVue, vueApp } from "@/app";
import promiseHelpers from "@/helpers/helpers.promises";
import timeHelpers from "@/helpers/helpers.time";
import numberHelpers from "@/helpers/helpers.numbers";
import localStorageHandler from "@/classes/localStorage";

//@ts-ignore
import Dropzone from "dropzone"
import mixinHelpers from "@/helpers/helpers.mixins";
import $ from 'jquery'
import products, { allObjectTypes } from "@/classes/objectTypes";
import config from "@/classes/config";
import licenseHelpers from "@/helpers/helpers.license";
import type ObjectType from "@/classes/objectTypes/objectType";
import encodingHelpers from "@/helpers/helpers.encoding";
import deviceHelpers from "@/helpers/helpers.devices";
import { useAzureStore } from "@/classes/objectTypes/unifiedSecurity/users/azure";
import type { ApiKey } from "@/classes/objectTypes/unifiedSecurity/apiKeys/apiKeys";
import { type TUscZtProfile } from "@/classes/objectTypes/unifiedSecurityConsole/uscZtProfile";
import getterHelpers from "@/helpers/helpers.getters";
import { useFeatureStore } from "@/classes/featureStore";


declare var Papa: any;
const store = useStore()

// Ref Values
const viewMode = ref('grid')
const showInfo = ref(true)
const filterSignedOutDevices = ref(false)
const dropZoneInitialized = ref(false)
const refreshCounter = ref(0)
const map = ref(<any>undefined)
const error = ref(undefined)
const loading = ref(true)
const loaderInfo = ref("Loading...")
const sortBy = ref('')
const sortDirection = ref('ASC')
const checkedItemsCount = ref(0)
const cellWidth = ref(0)
const filterGeneratedPolicies = ref(false)
const showExpiredLicenses = ref(false)
const showExpiredTokens = ref(false)
const showExpiredKeys = ref(false)
const showExpiredUscZtProfiles = ref(false)
const mapView = ref(false)
const refreshing = ref(false)

// Reactive Values

const filter = ref({
	"value": "",
	"readValue": ""
})
const bulk = reactive({
	"action": "none",
	"loading": false
})
const dropZone = reactive({
	"dropZoneUploadText": T('Upload file'),
	"dropZoneActive": false,
	"fileTypes": [],
	"dropZoneSendToUrl": "",
})
const virtualScrolling = reactive({
	"lastScrollDistance": 0,
	"scrollDistance": 0,
	"frameHeight": 0,
	"maxItemsInViewPort": 18,
	"fromIndex": 0,
	"renderedRows": 6,
	"itemsPerRow": 3,
	"heightList": <number[]>[],
	"paddingsTop": <number[]>[],
	"paddingsBottom": []
})
// Computed Values
const currentInstance = getCurrentInstance()
const darkmode = computed(() => { return mixinHelpers.getDarkmode() })
const activeAccountInitialized = computed(() => { return mixinHelpers.isActiveAccountInitialized() })
const activeTenantDomain = computed(() => { return mixinHelpers.getActiveTenantDomain() })
const objectType = computed(() => { return mixinHelpers.getObjectType() || '' })
const productType = computed(() => { return mixinHelpers.getProductType() || '' })
const activePage = computed(() => { return mixinHelpers.getActivePage() })
const activeAccountId = computed(() => { return mixinHelpers.getActiveAccountId() })
const activeAccount = computed(() => { return mixinHelpers.getActiveAccount() })
const itemlistTopPadding = computed(() => { return (virtualScrolling.paddingsTop as number[]).reduce((pv, cv) => pv + cv, 0) });
const itemlistBottomPadding = computed(() => { return (virtualScrolling.paddingsBottom as number[]).reduce((pv, cv) => pv + cv, 0) });
const canImportRSPUsers = computed(() => { return activeAccount.value.unifiedSecurity.account.rspUserConnectable || false });
const accountRestrictions = computed(() => { return useStore().state.session.accounts[activeAccountId.value].mobileSecurity.settings.accountRestrictions });
const isAzureImporting = computed(() => { return useAzureStore().isAzureImporting })
const canCopy = computed(() => {
	return (['profiles', 'iosProfiles', 'androidProfiles', 'apps', 'vpns'].indexOf(activePage.value) != -1 && hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) || ['windowsProfiles', 'secureDnsProfiles', 'homescreenlayouts'].includes(activePage.value)
})
const enrollmentAllowed = computed(() => {
	const session = useStore().state.session;
	return (session.accounts[activeAccountId.value].mobileSecurity.settings.enrollmentAllowed &&
		licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')
	)
});
const hasCheckedItems = computed(() => {
	return checkedItemsCount.value > 0
})
const activeItems = computed(() => {
	let result = {
		"count": <number|undefined>undefined,
		"items": <any[]|undefined>undefined,
		"androidCount": <number | undefined>undefined,
		"iosCount": <number | undefined>undefined
	}	
	let newProductType = config.canUseNewObjectType(objectType.value) ? products[productType.value as keyof typeof products] : undefined
	let newObjectType = newProductType?.[objectType.value as keyof typeof newProductType]

	if (config.canUseNewObjectType(objectType.value) && newObjectType) {
		let thisResult = (<ObjectType<any>>newObjectType).useStore?.().getObjectStore(activeAccountId.value, { sortBy: sortBy.value, direction: sortDirection.value},{ "searchString": filter.value.readValue })
		result.items = thisResult?.objects
		result.count = thisResult?.count
	}
	else {
		result = useStore().getters.getObjectTypeStore({
			"accountId": activeAccountId.value,
			"objectType": activePage.value,
			"sort": {
				sortBy: sortBy.value,
				direction: sortDirection.value
			},
			"filter": {
				"searchString": filter.value.readValue
			}
		})
	}

	if (activePage.value == 'androidProfiles' && filterGeneratedPolicies.value === true) {
		result.items = result.items?.filter((item: any) => {
			return config.canUseNewObjectType("androidProfiles") ? item.type != "autogenerated policy" : jsonHelpers.getObjectProperty(item, "policy.type") != "autogenerated policy"
		})
	}
	else if (activePage.value == 'licenses' && !showExpiredLicenses.value) {
		result.items = result.items?.filter((item: any) => {
			return item.dead === false && !(item.state === 'expired')
		})
	}

	else if(activePage.value == "windowsEnrollmentTokens" && !showExpiredTokens.value) {
		result.items = result.items?.filter((item: any) => {
			return (!(item.hasOwnProperty('isExpired') && item.isExpired) || products.unifiedSecurityConsole.windowsEnrollmentTokens.checkLastInvalidAttempt(item?.lastInvalidEnrollmentAttempt).isInvalid)
		})
	}
	else if(activePage.value == "apiKeys" && !showExpiredKeys.value) {
		result.items = result.items?.filter((item: ApiKey) => {
			return products.unifiedSecurity.apiKeys.isApiKeyExpired(item) == false
		})
	}
	else if (activePage.value == "uscZtProfiles" && !showExpiredUscZtProfiles.value) {
		result.items = result.items?.filter((item: TUscZtProfile) => {
			return products.unifiedSecurityConsole.uscZtProfiles.isObjectExpired(item) == false
		})
	}
	return result
})

const introductionComponent = computed(() => {
	let newProductType = config.canUseNewObjectType(objectType.value) ? products[productType.value as keyof typeof products] : undefined
	let newObjectType = newProductType?.[objectType.value as keyof typeof newProductType]
	if (config.canUseNewObjectType(objectType.value) && newObjectType) {
		return (<ObjectType<any>>newObjectType).itemlist.getIntroductionComponent?.(activeAccountId.value, currentInstance)
	}}
)

const isEmpty = computed(() => {
	return placeHolderLength.value === 0
		&& activeItemsCount.value === 0
		&& activeItems.value != undefined
		&& activeItems.value.items != undefined
		&& activeItems.value.items.length === 0
		&& loading.value === false
})

const showToolbar = computed(() => {
	if (isEmpty.value && introductionComponent.value !== undefined) {
		return false
	}

	return true
})

const sortingOptions = computed(() => {
	let objectStore = objectStores[objectType.value as keyof typeof objectStores]
	let newProductType = config.canUseNewObjectType(objectType.value) ? products[productType.value as keyof typeof products] : undefined
	let newObjectType = newProductType?.[objectType.value as keyof typeof newProductType]
	if (config.canUseNewObjectType(objectType.value) && newObjectType) {
		return (<ObjectType<any>>newObjectType).itemlist.getSortingOptions(activeAccountId.value, currentInstance)
	}
	else if (objectStore != undefined) {
		return objectStore.itemlist.sortingOptions
	}
	else {
		let sortingOptions: { "id": string, "text": string }[] = []
		switch (activePage.value) {
			case "iosDevices":
				sortingOptions = [
					{
						"id": "alias",
						"text": T("Name")
					},
					{
						"id": "deviceId",
						"text": T("ID")
					},
					{
						"id": "serialNumber",
						"text": T("Serial")
					},
					{
						"id": "profile.profileName",
						"text": T("Profile")
					},
					{
						"id": "lastContact",
						"text": T("Contact")
					}
				]
				break;
			case "iosProfiles":
				sortingOptions = [
					{
						"id": "profilename",
						"text": T("Name")
					},
					{
						"id": "priority",
						"text": T("Priority")
					}
				]
				break;
			case "apps":
				sortingOptions = [
					{
						"id": "name",
						"text": T("Name")
					}
				]
				break;
			case "homescreenlayouts":
				sortingOptions = [
					{
						"id": "Name",
						"text": T("Name")
					}
				]
				break;
			case "vppassets":
				sortingOptions = [
					{
						"id": "assetInfo.trackName",
						"text": T("Name")
					}
				]
				break;
			case "depProfiles":
				sortingOptions = [
					{
						"id": "profile_name",
						"text": T("Name")
					},
					{
						"id": "profile_uuid",
						"text": T("ID")
					}
				]
				break;
			case "androidProfiles":
				sortingOptions = [
					{
						"id": "profilename",
						"text": T("Name")
					}
				]
				break;
			case "zeroTouchConfigurations":
				sortingOptions = [
					{
						"id": "configurationName",
						"text": T("Name")
					},
					{
						"id": "companyName",
						"text": T("Company")
					}
				]
				break;
			case "vpns":
				sortingOptions = [
					{
						"id": "vpnname",
						"text": T("Name")
					}
				]
				break;
			case "certificates":
				sortingOptions = [
					{
						"id": "name",
						"text": T("Name")
					}
				]
				break;
			case "utms":
				sortingOptions = [
					{
						"id": "utmname",
						"text": "Name"
					},
					{
						"id": "model",
						"text": T("model")
					}
				]
				break;
			case "notifications":
				sortingOptions = [
					{
						"id": "servicename",
						"text": "Name"
					}
				]
				break;
		}
		return sortingOptions
	}
})
const activeItemsCount = computed(() => {
	const thisPageMeta = useStore().state.resources.pages[activePage.value]
	let thisObjectType = objectType.value
	let newProductType = config.canUseNewObjectType(objectType.value) ? products[productType.value as keyof typeof products] : undefined
	let newObjectType = newProductType?.[objectType.value as keyof typeof newProductType]
	if (config.canUseNewObjectType(objectType.value) && newObjectType != undefined) {
		if(activePage.value == 'licenses' && showExpiredLicenses.value === false) {
			return activeItems.value?.items?.length || 0
		}
		else if (activePage.value == "windowsEnrollmentTokens" && showExpiredTokens.value === false) {
			return activeItems.value?.items?.length || 0
		}
		else if(activePage.value == "apiKeys" && showExpiredKeys.value === false) {
			return activeItems.value?.items?.length || 0
		}
		else if(activePage.value == "uscZtProfiles" && showExpiredUscZtProfiles.value === false) {
			return activeItems.value?.items?.length || 0
		}
		else {
			return (<ObjectType<any>>newObjectType).useStore?.().getObjectStoreCount(activeAccountId.value)
		}
	}
	else {
		if (thisObjectType == "iosProfiles") {
			thisObjectType = "profiles"
		}
		else if (thisObjectType == "iosDevices") {
			thisObjectType = "devices"
		}
		else if (thisObjectType == "androidProfiles") {
			thisObjectType = "profiles"
		}
		if (activePage.value != 'licenses' || showExpiredLicenses.value) {
			return useStore().getters.getCount({
				"accountId": activeAccountId.value,
				"countProperty": thisPageMeta?.options.countProperty || "count",
				"objectType": thisObjectType,
				"productType": tenantHelpers.getProductTypeFromObjectType(objectType.value || '') || "unifiedSecurity"
			})
		}
		else {
			return getActiveItemsCount()
		}
	}
})
const renderedItemsCount = computed(() => {
	activePage.value;
	return filter.value.readValue.length > 0 ? 
		((virtualScrolling.fromIndex + virtualScrolling.maxItemsInViewPort) > (activeItems.value.items?.length || 0)) ?
			((activeItems.value.items?.length || 0) - virtualScrolling.fromIndex) :
			virtualScrolling.maxItemsInViewPort : 
		((virtualScrolling.fromIndex + virtualScrolling.maxItemsInViewPort) > activeItemsCount.value) ? 
			(activeItemsCount.value - virtualScrolling.fromIndex) : 
			virtualScrolling.maxItemsInViewPort
})

const placeHolderLength = computed(() => {
	const thisPageMeta = useStore().state.resources.pages[activePage.value]
	const countProperty : "count" | "androidCount" | "iosCount" = thisPageMeta?.options.countProperty || "count"
	let result: number = 0
	let objectsLength: number = result = activeItems.value?.items != undefined ? activeItems.value.items?.length || 0 : 0
	let objectsCount: number | undefined 
	let newProductType = config.canUseNewObjectType(objectType.value) ? products[productType.value as keyof typeof products] : undefined
	let newObjectType = newProductType?.[objectType.value as keyof typeof newProductType]
	if (config.canUseNewObjectType(objectType.value) && newObjectType) {
		objectsCount = activeItems.value.count
	}
	else {
		objectsCount= activeItems.value[countProperty] != undefined ? activeItems.value[countProperty] : undefined
	}
	
	if (objectsCount != undefined) {
		result = objectsCount - objectsLength
		if (result < 0 && result != undefined) {
			result = 0
		}
	}

	return result
})
const toolBarEntries = computed(() => {
	let thisToolbarEntries: any = [];
	showExpiredLicenses.value;
	showExpiredTokens.value;
	showExpiredKeys.value;
	showExpiredUscZtProfiles.value;
	let objectStore = objectStores[objectType.value as keyof typeof objectStores]
	let newProductType = config.canUseNewObjectType(objectType.value) ? products[productType.value as keyof typeof products] : undefined
	let newObjectType = newProductType?.[objectType.value as keyof typeof newProductType]
	if (config.canUseNewObjectType(objectType.value) && newObjectType) {
		return (<ObjectType<any>>newObjectType).itemlist.getToolbarButtons(activeAccountId.value,currentInstance)
	} 
	else if (objectStore instanceof GenericObjectStore) {
		return objectStore.itemlist.getToolbar(activeAccountId.value, currentInstance)
	}
	else {
		switch (activePage.value) {
			case 'devices':
			case 'iosDevices':
				if (enrollmentAllowed.value && accountRestrictions.value == 0) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-envelope',
							title: T('Send invite'),
							onClick: renderInviteDeviceDialog,
							id: 'devicesButtonInvite',
							vIf: false
						},
						{
							icon: 'fal fa-plus',
							title: T('Enroll new device'),
							onClick: renderEnrollDeviceDialog,
							id: 'devicesButtonEnroll',
							vIf: false
						}
					)
				}
				else if (accountRestrictions.value > 0) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-exclamation-triangle',
							title: T('Enrollment disabled'),
							link: '',
							id: 'devicesButtonEnroll',
							disabled: true,
							vIf: false
						}
					)
				}
				else if (!enrollmentAllowed.value) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-exclamation-triangle',
							title: T('You have reached the devices limit'),
							link: '',
							id: 'devicesButtonEnroll',
							disabled: true,
							vIf: false
						}
					)
				}

				thisToolbarEntries.push(
					{
						icon: 'fal fa-link',
						title: T('Assign licenses'),
						link: "#show-tenant-" + activeTenantDomain.value + "-licenses-dashboard",
						id: 'assignLicenses',
						vIf: "hasDevicesWithoutLicense"
					},
					{
						icon: 'fal fa-sync',
						title: T('OS update'),
						onClick: async function () {
							dialogs.mobileSecurity.updateAppleDevice(activeAccountId.value, async (updateType: any) => {
								let promiseObject: PromiseObject = {}
								activeItems.value.items?.forEach((item: any) => {
									if (item?.deviceId && item.deviceType == 'IOS' && item.availableUpdates?.length > 0 && (item.info?.supervised || item.info?.IsSupervised)) {
										promiseObject[item.deviceId] = queries.mobileSecurity.sendDeviceMessage(activeAccountId.value, item.deviceId, {
											"type": "SCHEDULE_OS_UPDATE",
											"payload": {
												"Updates": [{
													"InstallAction": updateType,
													"ProductVersion": item.availableUpdates[0].ProductVersion
												}],
												"RequestRequiresNetworkTether": false
											}
										})
									}
								})
								await promiseHelpers.resolvePromiseObject(promiseObject)
							})
						},
						id: 'updateDevices',
						vIf: "hasUpdatableDevices"
					}
				)

				break;
			case 'iosProfiles':
				if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-plus',
							title: T('Add profile'),
							link: '#add-tenant-' + activeTenantDomain.value + '-ios-profile',
							id: 'profilesButtonAdd',
							vIf: false
						}
					)
				}
				break;
			case 'androidProfiles':
				if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-plus',
							title: T('Add profile'),
							link: '#add-tenant-' + activeTenantDomain.value + '-android-profile',
							id: 'profilesButtonAdd',
							disabled: !tenantHelpers.hasFunctionality(activeAccountId.value, "enterprise"),
							vIf: false
						}
					)
				}
				break;
			case 'apps':
				if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-plus',
							title: T('Add app'),
							link: '#add-tenant-' + activeTenantDomain.value + '-app',
							id: 'appsButtonAdd',
							vIf: false
						}
					)
					if (tenantHelpers.hasFunctionality(activeAccountId.value, 'vpp')) {
						thisToolbarEntries.push(
							{
								icon: 'fal fa-sync',
								title: T('Sync with Apple VPP'),
								onClick: () => {
									queries.mobileSecurity.vpp.syncApps(activeAccountId.value)
									refresh()
								},
								id: 'appsButtonAdd',
								vIf: 'hideMeForNow'
							}
						)
					}
				}
				break;
			case 'vppassets':
				thisToolbarEntries.push(
					{
						icon: 'fal fa-sync',
						title: T('Sync with Apple VPP'),
						onClick: () => {
							queries.mobileSecurity.vpp.syncApps(activeAccountId.value)
						},
						id: 'appsButtonAdd',
						vIf: 'hideMeForNow'
					},
					{
						icon: 'fal fa-link',
						title: T('Assign Licenses'),
						onClick: () => {
							dialogs.mobileSecurity.renderVPPLicenseAssignmentDialog(activeAccountId.value)
						},
						id: 'appsButtonAdd',
						vIf: false
					}
				)
				break;
			case 'vpns':
				if (enrollmentAllowed.value && accountRestrictions.value == 0) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-plus',
							title: T('Add VPN'),
							link: '#add-tenant-' + activeTenantDomain.value + '-vpn',
							id: 'vpnsButtonAdd',
							vIf: false
						}
					)
				}
				else if (accountRestrictions.value > 0) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-exclamation-triangle',
							title: T('Enrollment disabled'),
							link: '',
							id: 'vpnsButtonAdd',
							disabled: true,
							vIf: false
						}
					)
				}
				else if (!enrollmentAllowed.value) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-exclamation-triangle',
							title: T('You have reached the devices limit'),
							link: '',
							id: 'vpnsButtonAdd',
							disabled: true,
							vIf: false
						}
					)
				}

				break;

			case 'roles':
				thisToolbarEntries.push(
					{
						icon: 'fal fa-plus',
						title: T('Add role'),
						link: '#add-tenant-' + activeTenantDomain.value + '-role',
						id: 'rolesButtonAdd',
						vIf: false
					}
				)
				break;
			case 'certificates':
				if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-plus',
							title: T('Add certificate'),
							onClick: () => {
								dialogs.mobileSecurity.renderAddCertificateDialog(activeAccountId.value)
							},
							id: 'certificatesButtonAdd',
							vIf: false
						}
					)
				}
				break;
			case 'alerts':
				thisToolbarEntries.push(
					{
						icon: 'fal fa-plus',
						title: T('Add alert'),
						link: '#add-tenant-' + activeTenantDomain.value + '-alert',
						id: 'alertsButtonAdd',
						vIf: false
					}
				)
				break;
			case 'notifications':
				thisToolbarEntries.push(
					{
						icon: 'fal fa-plus',
						title: T('Add notification'),
						link: '#add-tenant-' + activeTenantDomain.value + '-notification',
						id: 'notificationsButtonAdd',
						vIf: false
					}
				)
				break;

			case 'utms':
				break;
			case 'reports':
				break;
			case 'depProfiles':
				if(tenantHelpers.hasFunctionality(activeAccountId.value,"dep")) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-plus',
							title: T('Add profile'),
							link: '#add-tenant-' + activeTenantDomain.value + '-dep-profile',
							hoverText: T("Add profile"),
							id: 'addProfile',
							vIf: false
						}
					)
				}
				break;
			case 'zeroTouchConfigurations':
				if (tenantHelpers.hasFunctionality(activeAccountId.value, "enterprise")) {
					thisToolbarEntries.push(
						{
							icon: 'fal fa-plus',
							title: T('Add config'),
							link: '#add-tenant-' + activeTenantDomain.value + '-zerotouchconfiguration',
							hoverText: T("Add config"),
							id: 'addConfig',
							vIf: false
						}
					)
				}
				break;
		}
		return thisToolbarEntries || []
	}
})
// Functions
function getActiveItemsCount() {
	return activeItems.value.items?.length
}
function getNoItemsText() {
	return sprintf(T("No %s found"), T(getTextForActivePage(activePage.value, 'plural').replace("Utms", "UTMs")))
}
function hasUpdatableDevices() {
	let result : boolean = false
	if (activePage.value == 'iosDevices') {
		result = activeItems.value?.items?.some((device: any) => {
			return device?.deviceId && device.deviceType == 'IOS' && device.availableUpdates?.length > 0 && (device.info?.supervised || device.info?.IsSupervised)
		}) || false
	}
	return result
}
function getTextForActivePage(activePage: string, textProperty: string) {
	const thisPageMeta = useStore().state.resources.pages[activePage]
	const value: string = thisPageMeta.text[textProperty]
	return value
}
async function refresh(force: boolean = false) {
	refreshing.value = true
	let requestObjectTypes: string[] = []
	loading.value = true
	if (activeAccountInitialized.value) {
		if(tenantHelpers.canOpenRoute(activeAccountId.value, activePage.value)){
			if (config.canUseNewObjectType(activePage.value)) {
				let product = products?.[productType.value as keyof typeof products]
				let objectType = product?.[activePage.value as keyof typeof product];
				await (<ObjectType<any>>objectType).queries.getCountFromApi(activeAccountId.value,true)
			}
			else {
				await useStore().dispatch(ActionTypes.getObjectTypeCount, { "accountId": activeAccountId.value, "objectType": activePage.value })
			}

			switch (activePage.value) {
				
				case 'androidDevices':
					const enterpriseGetProperties = [{ "property": "props[]", "value": "appliedPolicyName" }, 
					{ "property": "props[]", "value": "appliedState" }, 
					{ "property": "props[]", "value": "enrollmentTime" }, 
					{ "property": "props[]", "value": "props" }, 
					{ "property": "props[]", "value": "hardwareInfo" }, 
					{ "property": "props[]", "value": "softwareInfo" }, 
					{ "property": "props[]", "value": "networkInfo" }, 
					{ "property": "props[]", "value": "lastStatusReportTime" }, 
					{ "property": "props[]", "value": "managementMode" }, 
					{ "property": "props[]", "value": "name" }, 
					{ "property": "props[]", "value": "ownership" }, 
					{ "property": "props[]", "value": "policyName" }, 
					{ "property": "props[]", "value": "previousDeviceNames" }, 
					{ "property": "props[]", "value": "state" }, 
					{ "property": "props[]", "value": "alias" }, 
					{ "property": "props[]", "value": "deviceId" }, 
					{ "property": "props[]", "value": "username" }, 
					{ "property": "props[]", "value": "consent" }, 
					{ "property": "props[]", "value": "licenseUUID" }, 
					{ "property": "props[]", "value": "signedIn" }, 
					{ "property": "props[]", "value": "lostMode" }, 
					{ "property": "fields", "value": "*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)" }]
					products.mobileSecurity.androidDevices.queries.getObjectsFromApi(activeAccountId.value, undefined, enterpriseGetProperties)
					products.mobileSecurity.androidProfiles.queries.getObjectsFromApi(activeAccountId.value)
					if (tenantHelpers.hasFunctionality(activeAccountId.value, 'zerotouch')) {
						products.mobileSecurity.zeroTouchConfigurations.queries.getObjectsFromApi(activeAccountId.value, undefined, undefined, true)
					}
				break;
				case 'enterpriseDevices':
					if (tenantHelpers.hasFunctionality(activeAccountId.value, "enterprise")) {
						requestObjectTypes.push('enterpriseProfiles')
						requestObjectTypes.push('enterpriseDevices?props[]=appliedPolicyName&props[]=networkInfo&props[]=appliedState&props[]=enrollmentTime&props[]=props&props[]=hardwareInfo&props[]=lastStatusReportTime&props[]=managementMode&props[]=name&props[]=ownership&props[]=policyName&props[]=previousDeviceNames&props[]=state&props[]=alias&props[]=deviceId&props[]=username&props[]=consent&props[]=licenseUUID&props[]=signedIn&fields=*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)')
					}
					if (tenantHelpers.hasFunctionality(activeAccountId.value, 'zerotouch')) {
						requestObjectTypes.push('zeroTouchConfigurations')
						requestObjectTypes.push('zeroTouchDevices')
					}
				break;
				case 'androidProfiles':
					if (tenantHelpers.hasFunctionality(activeAccountId.value, "enterprise")) {
						const enterpriseGetProperties = [{ "property": "props[]", "value": "appliedPolicyName" }, 
						{ "property": "props[]", "value": "appliedState" }, 
						{ "property": "props[]", "value": "enrollmentTime" }, 
						{ "property": "props[]", "value": "props" }, 
						{ "property": "props[]", "value": "hardwareInfo" }, 
						{ "property": "props[]", "value": "lastStatusReportTime" }, 
						{ "property": "props[]", "value": "managementMode" }, 
						{ "property": "props[]", "value": "networkInfo" },
						{ "property": "props[]", "value": "name" }, 
						{ "property": "props[]", "value": "ownership" }, 
						{ "property": "props[]", "value": "policyName" }, 
						{ "property": "props[]", "value": "previousDeviceNames" }, 
						{ "property": "props[]", "value": "state" }, 
						{ "property": "props[]", "value": "alias" }, 
						{ "property": "props[]", "value": "deviceId" }, 
						{ "property": "props[]", "value": "username" }, 
						{ "property": "props[]", "value": "consent" }, 
						{ "property": "props[]", "value": "licenseUUID" }, 
						{ "property": "props[]", "value": "signedIn" }, 
						{ "property": "fields", "value": "*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)" }]
						if (config.canUseNewObjectType("androidDevices") && config.canUseNewObjectType("androidProfiles")) {
							products.mobileSecurity.androidProfiles.queries.getObjectsFromApi(activeAccountId.value)
							products.mobileSecurity.androidDevices.queries.getObjectsFromApi(activeAccountId.value,undefined, enterpriseGetProperties)
						}
						else {
							requestObjectTypes.push('enterpriseProfiles')
							requestObjectTypes.push('enterpriseDevices?props[]=appliedPolicyName&props[]=networkInfo&props[]=appliedState&props[]=enrollmentTime&props[]=props&props[]=hardwareInfo&props[]=lastStatusReportTime&props[]=managementMode&props[]=name&props[]=ownership&props[]=policyName&props[]=previousDeviceNames&props[]=state&props[]=alias&props[]=deviceId&props[]=username&props[]=consent&props[]=licenseUUID&props[]=signedIn&fields=*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)')
						}
						
					}
				break;
				case 'iosDevices':
					if (config.canUseNewObjectType("iosDevices")) {
						products.mobileSecurity.iosDevices.queries.getObjectsFromApi(activeAccountId.value,undefined,[
							{ "property":"props[]","value":"deviceId"}, 
							{ "property":"props[]","value":"declarativeManagement"}, 
							{ "property":"props[]","value":"osVersion"}, 
							{ "property":"props[]","value":"consent"}, 
							{ "property":"props[]","value":"deviceType"}, 
							{ "property":"props[]","value":"signedIn"}, 
							{ "property":"props[]","value":"profile"}, 
							{ "property":"props[]","value":"tags"}, 
							{ "property":"props[]","value":"ownership"}, 
							{ "property":"props[]","value":"lastContact"}, 
							{ "property":"props[]","value":"managedAppleId"}, 
							{ "property":"props[]","value":"signedIn"}, 
							{ "property":"props[]","value":"info"}, 
							{ "property":"props[]","value":"configured"}, 
							{ "property":"props[]","value":"licenseUUID"}, 
							{ "property":"props[]","value":"username"}, 
							{ "property":"props[]","value":"info"}, 
							{ "property":"props[]","value":"enrolledAt"}, 
							{ "property":"props[]","value":"alias"}, 
							{ "property":"props[]","value":"lostMode"}, 
							{ "property":"props[]","value":"availableUpdates"}, 
							{ "property":"props[]","value":"deviceFamily"},
							{ "property": "props[]", "value": "serialNumber" }
						],true,true)
					}
					else {
						requestObjectTypes.push(`devices?props[]=deviceId&props[]=declarativeManagement&props[]=osVersion&props[]=consent&props[]=deviceType&props[]=signedIn&props[]=profile&props[]=tags&props[]=ownership&props[]=lastContact&props[]=managedAppleId&props[]=signedIn&props[]=info&props[]=configured&props[]=licenseUUID&props[]=username&props[]=info&props[]=enrolledAt&props[]=alias&props[]=lostMode&props[]=availableUpdates&props[]=deviceFamily`)
						if (tenantHelpers.hasFunctionality(activeAccountId.value, "dep")) {
							requestObjectTypes.push('depDevices')
						}
					}

					if (config.canUseNewObjectType("depProfiles")) {
						products.mobileSecurity.depProfiles.queries.getObjectsFromApi(activeAccountId.value)
					}
					else {
						if (tenantHelpers.hasFunctionality(activeAccountId.value, "dep")) {
							requestObjectTypes.push('depProfiles')
						}
					}

					if (config.canUseNewObjectType("iosProfiles")) {
						products.mobileSecurity.iosProfiles.queries.getObjectsFromApi(activeAccountId.value, undefined,
							[
								{ "property": "props[]", "value": "profileId" },
								{ "property": "props[]", "value": "priority" },
								{ "property": "props[]", "value": "profilename" },
								{ "property": "props[]", "value": "platform" },
								{ "property": "props[]", "value": "roles" },
								{ "property": "props[]", "value": "users" },
								{ "property": "props[]", "value": "devices" },
								{ "property": "props[]", "value": "tags" },
								{ "property": "props[]", "value": "profile" },
								{ "property": "props[]", "value": "status" },
								{ "property": "props[]", "value": "iOSPerUserChannelUsers" },
								{ "property": "props[]", "value":"sun" },
								{ "property": "props[]", "value":"comment" },
							])
					}
					else {
						requestObjectTypes.push(`profiles?props[]=profileId&props[]=priority&props[]=profilename&props[]=devices&props[]=status&props[]=iOSPerUserChannelUsers`)
					}
				break;
				case 'iosProfiles':
					if(config.canUseNewObjectType('iosProfiles')) {
						products.mobileSecurity.iosProfiles.queries.getObjectsFromApi(activeAccountId.value,undefined,
						[
							{ "property": "props[]", "value":"profileId" },
							{ "property": "props[]", "value":"priority" },
							{ "property": "props[]", "value":"profilename" },
							{ "property": "props[]", "value":"platform" },
							{ "property": "props[]", "value":"roles" },
							{ "property": "props[]", "value":"users" },
							{ "property": "props[]", "value":"devices" },
							{ "property": "props[]", "value":"tags" },
							{ "property": "props[]", "value":"profile" },
							{ "property": "props[]", "value":"status" },
							{ "property": "props[]", "value":"iOSPerUserChannelUsers" },
							{ "property": "props[]", "value":"sun" },
							{ "property": "props[]", "value":"comment" },
						])
					}
					else {
						requestObjectTypes.push('profiles?props[]=profileId&props[]=priority&props[]=profilename&props[]=platform&props[]=roles&props[]=users&props[]=devices&props[]=tags&props[]=profile&props[]=status&props[]=iOSPerUserChannelUsers')
					}
					if (config.canUseNewObjectType("iosDevices")) {
						products.mobileSecurity.iosDevices.queries.getObjectsFromApi(activeAccountId.value,undefined,[
							{ "property":"props[]","value":"deviceId"}, 
							{ "property":"props[]","value":"declarativeManagement"}, 
							{ "property":"props[]","value":"osVersion"}, 
							{ "property":"props[]","value":"consent"}, 
							{ "property":"props[]","value":"deviceType"}, 
							{ "property":"props[]","value":"signedIn"}, 
							{ "property":"props[]","value":"profile"}, 
							{ "property":"props[]","value":"tags"}, 
							{ "property":"props[]","value":"ownership"}, 
							{ "property":"props[]","value":"lastContact"}, 
							{ "property":"props[]","value":"managedAppleId"}, 
							{ "property":"props[]","value":"signedIn"}, 
							{ "property":"props[]","value":"info"}, 
							{ "property":"props[]","value":"configured"}, 
							{ "property":"props[]","value":"licenseUUID"}, 
							{ "property":"props[]","value":"username"}, 
							{ "property":"props[]","value":"info"}, 
							{ "property":"props[]","value":"enrolledAt"}, 
							{ "property":"props[]","value":"alias"}, 
							{ "property":"props[]","value":"lostMode"}, 
							{ "property":"props[]","value":"availableUpdates"}, 
							{ "property":"props[]","value":"deviceFamily"},
							{ "property": "props[]", "value": "serialNumber" }
						],true,true)
					}
					else {
						requestObjectTypes.push(`devices?props[]=deviceId&props[]=declarativeManagement&props[]=osVersion&props[]=consent&props[]=deviceType&props[]=signedIn&props[]=profile&props[]=tags&props[]=ownership&props[]=lastContact&props[]=managedAppleId&props[]=signedIn&props[]=info&props[]=configured&props[]=licenseUUID&props[]=username&props[]=info&props[]=enrolledAt&props[]=alias&props[]=lostMode&props[]=availableUpdates&props[]=deviceFamily`)
						if (tenantHelpers.hasFunctionality(activeAccountId.value, "dep")) {
							requestObjectTypes.push('depDevices')
						}
					}

					
				break;
				case 'depProfiles':
					if (config.canUseNewObjectType("depProfiles")) {
						products.mobileSecurity.iosDevices.queries.getObjectsFromApi(activeAccountId.value,undefined,[
							{ "property":"props[]","value":"deviceId"}, 
							{ "property":"props[]","value":"declarativeManagement"}, 
							{ "property":"props[]","value":"osVersion"}, 
							{ "property":"props[]","value":"consent"}, 
							{ "property":"props[]","value":"deviceType"}, 
							{ "property":"props[]","value":"signedIn"}, 
							{ "property":"props[]","value":"profile"}, 
							{ "property":"props[]","value":"tags"}, 
							{ "property":"props[]","value":"ownership"}, 
							{ "property":"props[]","value":"lastContact"}, 
							{ "property":"props[]","value":"managedAppleId"}, 
							{ "property":"props[]","value":"signedIn"}, 
							{ "property":"props[]","value":"info"}, 
							{ "property":"props[]","value":"configured"}, 
							{ "property":"props[]","value":"licenseUUID"}, 
							{ "property":"props[]","value":"username"}, 
							{ "property":"props[]","value":"info"}, 
							{ "property":"props[]","value":"enrolledAt"}, 
							{ "property":"props[]","value":"alias"}, 
							{ "property":"props[]","value":"lostMode"}, 
							{ "property":"props[]","value":"availableUpdates"}, 
							{ "property":"props[]","value":"deviceFamily"},
							{ "property": "props[]", "value": "serialNumber" }
						],true,true)
						products.mobileSecurity.depProfiles.queries.getObjectsFromApi(activeAccountId.value)
					}
					else {
						if (tenantHelpers.hasFunctionality(activeAccountId.value, "dep")) {
							requestObjectTypes.push('depProfiles')
							requestObjectTypes.push('depDevices')
						}
						requestObjectTypes.push(`devices?props[]=deviceId&props[]=consent&props[]=deviceType&props[]=signedIn&props[]=profile&props[]=tags&props[]=ownership&props[]=lastContact&props[]=managedAppleId&props[]=signedIn&props[]=info&props[]=configured&props[]=licenseUUID&props[]=username&props[]=info&props[]=enrolledAt&props[]=alias`)
					}
					
				break;
				case 'iosApps':

					products.mobileSecurity.iosDevices.queries.getObjectsFromApi(activeAccountId.value,undefined,[
							{ "property":"props[]","value":"deviceId"}, 
							{ "property":"props[]","value":"declarativeManagement"}, 
							{ "property":"props[]","value":"osVersion"}, 
							{ "property":"props[]","value":"consent"}, 
							{ "property":"props[]","value":"deviceType"}, 
							{ "property":"props[]","value":"signedIn"}, 
							{ "property":"props[]","value":"profile"}, 
							{ "property":"props[]","value":"tags"}, 
							{ "property":"props[]","value":"ownership"}, 
							{ "property":"props[]","value":"lastContact"}, 
							{ "property":"props[]","value":"managedAppleId"}, 
							{ "property":"props[]","value":"signedIn"}, 
							{ "property":"props[]","value":"info"}, 
							{ "property":"props[]","value":"configured"}, 
							{ "property":"props[]","value":"licenseUUID"}, 
							{ "property":"props[]","value":"username"}, 
							{ "property":"props[]","value":"info"}, 
							{ "property":"props[]","value":"enrolledAt"}, 
							{ "property":"props[]","value":"alias"}, 
							{ "property":"props[]","value":"lostMode"}, 
							{ "property":"props[]","value":"availableUpdates"}, 
							{ "property":"props[]","value":"deviceFamily"},
							{ "property": "props[]", "value": "serialNumber" }
						],true,true)
					
					products.mobileSecurity.iosApps.queries.getObjectsFromApi(activeAccountId.value, undefined, [
						{ "property": "props[]", "value": "name" },
						{ "property": "props[]", "value": "iTunesStoreID" },
						{ "property": "props[]", "value": "uninstallPackageName" },
						{ "property": "props[]", "value": "platform" },
						{ "property": "props[]", "value": "roles" },
						{ "property": "props[]", "value": "users" },
						{ "property": "props[]", "value": "devices" },
						{ "property": "props[]", "value": "tags" },
						{ "property": "props[]", "value": "appId" },
						{ "property": "props[]", "value": "Identifier" },
						{ "property": "props[]", "value": "hasIcon" },
						{ "property": "props[]", "value": "Icon" },
						{ "property": "props[]", "value": "Icon:data" }
					])
					
				break;
				case 'apps':
					requestObjectTypes.push('apps?props[]=name&props[]=iTunesStoreID&props[]=uninstallPackageName&props[]=platform&props[]=roles&props[]=users&props[]=devices&props[]=tags&props[]=appId&props[]=Identifier&props[]=ManifestURL')
					requestObjectTypes.push('devices?props[]=deviceId&props[]=consent&props[]=deviceType&props[]=signedIn&props[]=profile&props[]=tags&props[]=ownership&props[]=lastContact&props[]=managedAppleId&props[]=signedIn&props[]=info&props[]=configured&props[]=licenseUUID&props[]=username&props[]=info&props[]=enrolledAt&props[]=alias&props[]=lostMode')
					if(tenantHelpers.hasFunctionality(activeAccountId.value,"dep")) {
						requestObjectTypes.push('depDevices')
					}
				break;
				case 'users':
					if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'])) {
						requestObjectTypes.push('devices?props[]=deviceId&props[]=deviceType&props[]=username&props[]=info&props[]=alias')
						if (tenantHelpers.hasFunctionality(activeAccountId.value, "enterprise")) {
							requestObjectTypes.push('enterpriseDevices?props[]=appliedPolicyName&props[]=networkInfo&props[]=appliedState&props[]=enrollmentTime&props[]=props&props[]=hardwareInfo&props[]=lastStatusReportTime&props[]=managementMode&props[]=name&props[]=ownership&props[]=policyName&props[]=previousDeviceNames&props[]=state&props[]=alias&props[]=deviceId&props[]=username&props[]=consent&props[]=licenseUUID&props[]=signedIn&fields=*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)')
						}
					}
					if (config.canUseNewObjectType("roles") && config.canUseNewObjectType("users")) {
						products.unifiedSecurity.roles.queries.getObjectsFromApi(activeAccountId.value,undefined,[
							{ "property":"props[]", "value":"rolename" },
							{ "property":"props[]", "value":"permissionScopes" },
							{ "property":"props[]", "value":"users" }
						])
						products.unifiedSecurity.users.queries.getObjectsFromApi(activeAccountId.value)
					}
					else {
						requestObjectTypes.push('roles?props[]=rolename&props[]=permissionScopes&props[]=users')
						requestObjectTypes.push("users")
					}
					
					if (tenantHelpers.hasFunctionality(activeAccountId.value, "vpp")) {
						if (config.canUseNewObjectType("vppUsers")) {
							products.mobileSecurity.vppUsers.queries.getObjectsFromApi(activeAccountId.value)
						}
						else {
							requestObjectTypes.push("vppUsers")	
						}
					}
				break;
				case 'roles':
					if (config.canUseNewObjectType("roles")) {
						products.unifiedSecurity.roles.queries.getObjectsFromApi(activeAccountId.value)
					}
					else {
						requestObjectTypes.push(activePage.value)
					}
				break;
				case 'reports':
					if (config.canUseNewObjectType("reports")) {
						products.unifiedReporting.reports.queries.getObjectsFromApi(activeAccountId.value,undefined,[
							{ "property":"props[]", "value":"reportId" },
							{ "property":"props[]", "value":"reportname" },
							{ "property":"props[]", "value":"theme" },
							{ "property":"props[]", "value":"type" },
							{ "property":"props[]", "value":"recipients" },
							{ "property":"props[]", "value":"confirmedRecipients" },
							{ "property":"props[]", "value":"period" },
							{ "property":"props[]", "value":"filesCount" }
						])
					}
					else {
						requestObjectTypes.push("reports?props[]=reportId&props[]=reportname&props[]=theme&props[]=type&props[]=recipients&props[]=confirmedRecipients&props[]=period&props[]=filesCount")
					}
				break;
				case 'vppassets':
					if (tenantHelpers.hasFunctionality(activeAccountId.value, "vpp")) {
						if(config.canUseNewObjectType("vppassets")) {
							products.mobileSecurity.vppassets.queries.getObjectsFromApi(activeAccountId.value)
						}
						else {
							requestObjectTypes.push(activePage.value)
						}
					}
				break;
				case 'zeroTouchConfigurations':
					if (tenantHelpers.hasFunctionality(activeAccountId.value, "zerotouch")) {
						const enterpriseGetProperties = [{ "property": "props[]", "value": "appliedPolicyName" },
						{ "property": "props[]", "value": "appliedState" },
						{ "property": "props[]", "value": "enrollmentTime" },
						{ "property": "props[]", "value": "props" },
						{ "property": "props[]", "value": "hardwareInfo" },
						{ "property": "props[]", "value": "softwareInfo" },
						{ "property": "props[]", "value": "networkInfo" },
						{ "property": "props[]", "value": "lastStatusReportTime" },
						{ "property": "props[]", "value": "managementMode" },
						{ "property": "props[]", "value": "name" },
						{ "property": "props[]", "value": "ownership" },
						{ "property": "props[]", "value": "policyName" },
						{ "property": "props[]", "value": "previousDeviceNames" },
						{ "property": "props[]", "value": "state" },
						{ "property": "props[]", "value": "alias" },
						{ "property": "props[]", "value": "deviceId" },
						{ "property": "props[]", "value": "username" },
						{ "property": "props[]", "value": "consent" },
						{ "property": "props[]", "value": "licenseUUID" },
						{ "property": "props[]", "value": "signedIn" },
						{ "property": "fields", "value": "*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)" }]
						await products.mobileSecurity.androidDevices.queries.getObjectsFromApi(activeAccountId.value, undefined, enterpriseGetProperties, true, true)
						products.mobileSecurity.zeroTouchConfigurations.queries.getObjectsFromApi(activeAccountId.value, undefined, undefined, true, true)
					}
				break;
				case 'uscUtms':
					requestObjectTypes.push('ccutmStates')
					requestObjectTypes.push('uscProfiles')
					requestObjectTypes.push("uscUtms?props[]=dead&props[]=cluster&props[]=license&props[]=lastContact&props[]=appliedProfileId&props[]=expirationDate&props[]=licensed&props[]=messages&props[]=model&props[]=offlineReason&props[]=spare&props[]=tags&props[]=tenantDomain&props[]=utmId&props[]=utmname&props[]=permissions")
				break;
				case 'usrUtms':
					products.unifiedReporting.usrUtms.queries.getObjectsFromApi(activeAccountId.value,undefined,[
						{
							"property":"props[]",
							"value":"dead"
						},
						{
							"property": "props[]",
							"value": "license"
						
						},
						{
							"property": "props[]",
							"value": "cluster"
						},
						{
							"property": "props[]",
							"value": "expirationDate"
						},
						{
							"property": "props[]",
							"value": "licensed"
						},
						{
							"property": "props[]",
							"value": "messages"
						},
						{
							"property": "props[]",
							"value": "offlineReason"
						},
						{
							"property": "props[]",
							"value": "spare"
						},
						{
							"property": "props[]",
							"value": "tags"
						},
						{
							"property": "props[]",
							"value": "tenantDomain"
						},
						{
							"property": "props[]",
							"value": "utmId"
						},
						{
							"property": "props[]",
							"value": "utmname"
						}
					])
				break;
				case 'utms':
					requestObjectTypes.push('utms?props[]=dead&props[]=license&props[]=cluster&props[]=expirationDate&props[]=licensed&props[]=messages&props[]=model&props[]=offlineReason&props[]=spare&props[]=tags&props[]=tenantDomain&props[]=utmId&props[]=utmname')
				break;
				case 'uscProfiles':
					requestObjectTypes.push('uscUtms?props[]=dead&props[]=cluster&props[]=license&props[]=appliedProfileId&props[]=expirationDate&props[]=licensed&props[]=messages&props[]=model&props[]=offlineReason&props[]=spare&props[]=tags&props[]=tenantDomain&props[]=utmId&props[]=utmname')
					requestObjectTypes.push(activePage.value)
				break;
				case 'windowsVpns':
					products.unifiedSecurityConsole.windowsVpns.queries.getObjectsFromApi(activeAccountId.value)
					products.unifiedSecurityConsole.windowsVpnStates.queries.getObjectsFromApi(activeAccountId.value)
					if(useFeatureStore?.().isEnabled('WINDOWS_DEVICE_PROFILES')) {
						products.unifiedSecurityConsole.windowsProfiles.queries.getObjectsFromApi(activeAccountId.value, undefined, [
							{
								"property": "props[]",
								"value": "id"
							},
							{
								"property": "props[]",
								"value": "name"
							},
							{
								"property": "props[]",
								"value": "sun"
							}])
						}
				break;
				case 'windowsProfiles':
					if(useFeatureStore?.().isEnabled('WINDOWS_DEVICE_PROFILES')) {
						products.unifiedSecurityConsole.windowsProfiles.queries.getObjectsFromApi(activeAccountId.value)
					}
					products.unifiedSecurityConsole.windowsVpns.queries.getObjectsFromApi(activeAccountId.value, undefined, [
						{
							"property": "props[]",
							"value": "alias"
						},
						{
							"property": "props[]",
							"value": "id"
						}
						])
				break;
				default:
					if (config.canUseNewObjectType(activePage.value)) {
						let product = products?.[productType.value as keyof typeof products]
						let objectType = product?.[activePage.value as keyof typeof product];
						(<ObjectType<any>>objectType).queries?.getObjectsFromApi(activeAccountId.value)
					}
					else {
						requestObjectTypes.push(activePage.value)
					}
				break;
			}
			if (requestObjectTypes.length) {
				useStore().dispatch(ActionTypes.getObjectInfos, {
					"accountId": activeAccountId.value,
					"objectTypes": requestObjectTypes
				})
			}
			reloadMap()
			checkLegacyProfiles()

			checkedItemsCount.value = 0
			
			nextTick(() => {
				calcCellWidth()
			})
		}
		else {
			await timeHelpers.sleep(1000)
			refresh()
		}
	}
	loading.value = false
	refreshing.value = false
}
function hasDevicesWithoutLicense() {
	let result: boolean = false
	if (activeItems.value && activeItems.value.items && activeItems.value.items.length) {
		for (let i: number = 0; activeItems.value.items.length > i; i++) {
			let device: any = activeItems.value.items[i]
			if (device.signedIn && !device?.licenseUUID && !device.depOnly && device.configured == true) {
				result = true;
				break;
			}
		}
	}
	return result
}
function hasNotEnrolledEnterpriseDevices() {
	return (activeItems.value?.items || []).some((device: any) => {
		return (device?.enterpriseInfo?.nonComplianceDetails || []).some((nonComplianceDetail: any) => {
			return nonComplianceDetail?.installationFailureReason == "NOT_ENROLLED"
		})
	})
}
function toggleFilterGeneratedPolicies() {
	filterGeneratedPolicies.value = !filterGeneratedPolicies.value
}
function toggleExpiredLicenses() {
	showExpiredLicenses.value = !showExpiredLicenses.value
}
function toggleExpiredTokens() {
	showExpiredTokens.value = !showExpiredTokens.value
}
function toggleExpiredKeys() {
	showExpiredKeys.value = !showExpiredKeys.value
}
function toggleExpiredUscZtProfiles() {
	showExpiredUscZtProfiles.value = !showExpiredUscZtProfiles.value
}

function checkToolbarEntry(condition: string) {
	let result: boolean = false
	switch (condition) {
		case 'isNotEnterpriseOnly':
			for (let i in activeItems.value.items) {
				if (activeItems.value.items[i as keyof typeof activeItems.value.items].platform != 'ANDROID_ENTERPRISE') {
					result = true
					break;
				}
			}
		break;
		case 'vppEnabled':
			if (tenantHelpers.hasFunctionality(activeAccountId.value, "vpp")) {
				result = true
			}
		break;
		case 'canUseAppleDEP':
			if(tenantHelpers.hasFunctionality(activeAccountId.value,"dep")) {
				result = true
			}
		break;
		case 'hasUsers':
			// missing condition check for exisiting users
			result = true
		break;
		case 'hasDevicesWithoutLicense':
			result = hasDevicesWithoutLicense()
		break;
		case 'hasUpdatableDevices':
			result = hasUpdatableDevices()
		break;
		case 'canImportRSPUsers':
			result = canImportRSPUsers.value
		break;
	}
	return result
}
function hasOneOfLicenses(accountid: string, licenseNames: string[], state: string | undefined = undefined) {
	return licenseHelpers.hasOneOfLicenses(accountid, licenseNames, state)
}
async function pasteItems() {
	let thisObjectType = activePage.value
	// Get enterprise policies
	let items: any[] = useStore().state.clipBoard[thisObjectType as keyof vuexClipboard]
	if (items?.length) {
		let newModal = {
			"id": "renameCopiedItems",
			"accountId": activeAccountId.value,
			"abortable": true,
			"content": {
				"title": {
					"text": T("Paste items"),
					"icon": "fal fa-sync-alt",
				},
				"body": {
					"use": true,
					"content": undefined,
					"component": "rename-policies-onpaste",
					"properties": {
						"items": items
					}
				},
			},
			"buttons": [
				{
					loading: false,
					onClick: () => {
						getterHelpers.useStore().commit(MutationTypes.removeModal,{accountId:activeAccountId.value})
					},
					icon: "fal fa-times",
					text: T('Cancel'),
					align: "left",
					disabled: false
				},
				{
					loading: false,
					onClick: async function () {
						useStore().state.session.accounts[activeAccountId.value].modals[0].buttons[1].loading = true
						useStore().state.session.accounts[activeAccountId.value].modals[0].buttons[1].disabled = true

						if(typeof useVue()?.$refs?.modals?.$refs?.modal?.$refs?.modalComponent?.error == "string")
							useVue().$refs.modals.$refs.modal.$refs.modalComponent.error = "";

						let nameProperty = ''
						switch (activePage.value) {
							case 'vpns':
								nameProperty = "vpnname"
								break;
							case 'androidProfiles':
								if (config.canUseNewObjectType("androidProfiles")) {
									nameProperty = 'name'
								}
								else {
									nameProperty = 'profileId'
								}
							break;
							case 'iosProfiles':
								nameProperty = 'profilename'
							break;
							case 'apps':
							case 'iosApps':
								nameProperty = 'name'
							break;
							case 'windowsProfiles':
								nameProperty = "name"
							break;
							case 'secureDnsProfiles':
								nameProperty = "name"
							case 'homescreenlayout':
								nameProperty = 'Name'
							break;
						}

						try {
							for (let i: number = 0; items.length > i; i++) {
							delete items[i].oldItemName

							if (thisObjectType == "androidProfiles") {

								let profile: any = items[i]
								
								
								if (config.canUseNewObjectType("androidProfiles")) {
									profile.name = profile.name.trim()
									delete profile.profileId
									delete profile.devices
									delete profile.version
									delete profile.sun
									let securepointApp = (profile.applications || []).find(function(application:any) {
										return application.packageName == "de.securepoint.ms.agent"
									})
									if(securepointApp?.managedConfiguration?.sun != undefined) { 
										delete securepointApp.managedConfiguration.sun
									}

									await products.mobileSecurity.androidProfiles.queries.addOrUpdateObjectFromApi(activeAccountId.value, undefined, profile)
								}
								else {
									profile.profilename = profile.profilename.trim()
									profile.policy.platform = 'ANDROID_ENTERPRISE'
									profile.policy.name = profile.profilename.trim()
									delete profile.devices
									delete profile.profileId
									delete profile.policy.profilename
									delete profile.sun
									let securepointApp = (profile.applications || []).find(function(application:any) {
										return application.packageName == "de.securepoint.ms.agent"
									})
									if(securepointApp?.managedConfiguration?.sun != undefined) { 
										delete securepointApp.managedConfiguration.sun
									}
									await useStore().dispatch(ActionTypes.addOrUpdateObject, {
										"accountId": activeAccountId.value,
										"objectType": "profiles",
										"queryType": "enterpriseProfiles",
										"object": profile.policy
									})
								}
							}
							else if (thisObjectType == "iosProfiles") {
								let profile: any = items[i]
								profile.profilename = profile.profilename.trim()
								delete profile.profile.vpns
								
								if (config.canUseNewObjectType("iosProfiles")) {
									await products.mobileSecurity.iosProfiles.queries.addOrUpdateObjectFromApi(activeAccountId.value, undefined, profile)
								}
								else {
									await useStore().dispatch(ActionTypes.addOrUpdateObject, {
										"accountId": activeAccountId.value,
										"objectType": "profiles",
										"queryType": "profiles",
										"object": profile
									})
								}
							}
							else if(thisObjectType == 'windowsProfiles') {
								let profile: any = items[i]
								profile.name = (profile.name || "").trim()
								if(useFeatureStore?.().isEnabled('WINDOWS_DEVICE_PROFILES')) {
									await products.unifiedSecurityConsole.windowsProfiles.queries.addOrUpdateObjectFromApi(activeAccountId.value, undefined, profile)
								}
							}
							else if(thisObjectType == "secureDnsProfiles") {
								let profile: any = items[i]
								profile.name = (profile.name || "").trim()
								await products.secureDns.secureDnsProfiles.queries.addOrUpdateObjectFromApi(activeAccountId.value, undefined, profile)
							}
							else if(thisObjectType == "homescreenlayouts") {
								let profile: any = items[i]
								profile.Name = (profile.Name || "").trim()
								await products.mobileSecurity.homescreenlayouts.queries.addOrUpdateObjectFromApi(activeAccountId.value, undefined, profile)
							}
							else {
								let item: any = (<any>useStore().state.clipBoard)[thisObjectType][i]
								if(config.canUseNewObjectType(thisObjectType)) {
									await allObjectTypes[thisObjectType]?.queries.addOrUpdateObjectFromApi(activeAccountId.value,undefined,item)
								}
								else {
									await useStore().dispatch(ActionTypes.addOrUpdateObject, {
										"accountId": activeAccountId.value,
										"objectType": thisObjectType,
										"queryType": thisObjectType,
										"object": item
									})
								}
							}
						}
						await refresh(true)
						for (let i in activeItems.value.items) {
							
							let item = activeItems.value.items[i as keyof typeof activeItems.value.items]
							item[nameProperty] = item?.[nameProperty]?.trim()
							if (item.isChecked) {
								item.isChecked = false
							}
							if (item.$itemlist?.isCheckboxChecked) {
								item.$itemlist.isCheckboxChecked = false
							}
						}
						(<any>useStore().state.clipBoard)[thisObjectType] = []
						checkedItemsCount.value = 0
						bulk.loading = false
						useStore().state.session.accounts[activeAccountId.value].modals[0].buttons[1].loading = false
						useStore().state.session.accounts[activeAccountId.value].modals[0].buttons[1].disabled = false
						getterHelpers.useStore().commit(MutationTypes.removeModal,{accountId:activeAccountId.value})
						}
						catch (e: any) {
							console.error(e)
							if (typeof useVue()?.$refs?.modals?.$refs?.modal?.$refs?.modalComponent?.error == "string") {

								if (e instanceof Error) useVue().$refs.modals.$refs.modal.$refs.modalComponent.error = e.message

								else if (typeof e?.data?.data?.errors?.errors?.[0]?.message == "string")
									useVue().$refs.modals.$refs.modal.$refs.modalComponent.error = e.data.data.errors.errors[0].message;

								else if (typeof e?.data?.data?.errors?.[0]?.message == "string")
									useVue().$refs.modals.$refs.modal.$refs.modalComponent.error = e.data.data.errors[0].message;
							}
						}
						useStore().state.session.accounts[activeAccountId.value].modals[0].buttons[1].loading = false
						useStore().state.session.accounts[activeAccountId.value].modals[0].buttons[1].disabled = false


					},
					icon: "fal fa-fw fa-paste",
					text: T('Paste items'),
					align: "right",
					disabled: false
				}
			]
		}
		useStore().dispatch(ActionTypes.addModal, newModal)
	}
}
function getInfoBoxContent() {
	let result: string = ""
	let objectStore = objectStores[objectType.value as keyof typeof objectStores]
	let newProductType = config.canUseNewObjectType(objectType.value) ? products[productType.value as keyof typeof products] : undefined
	let newObjectType = newProductType?.[objectType.value as keyof typeof newProductType]
	if (config.canUseNewObjectType(objectType.value) && newObjectType) {
		return (<ObjectType<any>>newObjectType).itemlist.getInfoBoxContent(activeAccountId.value, currentInstance)
	}
	else if (objectStore instanceof GenericObjectStore) {
		return objectStore.itemlist.getInfoBoxContent(activeAccountId.value, currentInstance)
	}
	switch (activePage.value) {
		case 'devices':
		case 'iosDevices':
			if (hasNotEnrolledEnterpriseDevices()) {
				result += (result.length ? '<br>' : '') + '<i class="fal fa-fw fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;' + T("We have detected that one or more devices deny app installation with state NOT_ENROLLED. This either means the enterprise is no longer enrolled with Managed Google Play or the admin has not accepted the latest Managed Google Play Terms of Service.")
				result += " " + '<a href="https://play.google.com/work/termsofservice" target="_blank">' + T('Click here') + '</a>' + " "
				result += T('to accept the new Terms of Service.')
			}
			if (hasDevicesWithoutLicense()) {
				result += (result.length ? '<br>' : '') + '<i class="fal fa-fw fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;' + T("One or more devices are unlicensed, please assign a license to each of your devices.")
			}
			break;
		case 'apps':
			break;
		case 'reports':
			if (licenseHelpers.hasLicense(activeAccountId.value, 'laas', 'valid') && tenantHelpers.getPrivacyLevel(activeAccountId.value) == "1") {

				result = `<i class="fal fa-fw fa-info color-red"></i>
						<p>
							${T('For this tenant the pseudonymization is active. If you want to see usernames, e-mail addresses, IPs etc. in plain text, You have to disable the privacy setting in the Unified Security Portal. This changes the following facts:')}
							<ul>
								<li>
									${T('In the Securepoint Mobile Security dashboard, the devices in the statistics are no longer pseudonymized.')}
								</li>
								<li>
									${T('Usernames, e-mail addresses, IPs, etc. are no longer anonymous in the report.')}
								</li>
								<li>
									${T('In Mobile Security it is possible to create alerts for specific devices.')}
								</li>
							</ul>
						</p>
						<p>${T('In addition, the anonymization of the services must be disabled on the UTM, otherwise the data will be anonymized on the UTM before being sent to the Unified Security Cloud.')}<p>
						<br />
						<p>${T('These settings can not be made automatically on the UTM and must therefore be performed by the administrator.')}</p>`
			}
			break;
	}
	return result
}
async function doBulkAction() {
	let checkedItems: any[] = []
	let vpnLicenses: Set<string> = new Set()
	bulk.loading = true

	for (let i in activeItems.value.items) {
		let item = activeItems.value.items[i as keyof typeof activeItems.value.items]
		if (item.isChecked || item.$itemlist?.isCheckboxChecked) {
			checkedItems.push(JSON.parse(JSON.stringify(item)))
		}
	}

	switch (bulk.action) {
		case 'copy':
			checkedItems = checkedItems.map((item: any) => {
				switch (activePage.value) {
					case 'androidProfiles':
						if (config.canUseNewObjectType("androidProfiles")) {
							item.oldItemName = item.profileId
							item.name = item.profileId + T("_Copy")
							item.devices = []
							delete item?.smsManagedConfiguration
							delete item?.type;
							delete item?.profileId;
							(item?.applications || []).forEach((application: any) => {
								if (jsonHelpers.getObjectProperty(application, "managedConfigurationTemplate.configurationVariables.%users%") != undefined) {
									application.managedConfigurationTemplate.configurationVariables["%users%"] = ""
									delete application.managedConfigurationTemplate.configurationVariables["%users%"]
								}
							});
						}
						else {
							item.oldItemName = item.profilename
							item.profilename = item.profilename + T("_Copy")
							item.devices = []
							delete item?.profileId
							delete item?.policy?.smsManagedConfiguration
							delete item?.policy?.type;
							(item.policy?.applications || []).forEach((application: any) => {
								if (jsonHelpers.getObjectProperty(application, "managedConfigurationTemplate.configurationVariables.%users%") != undefined) {
									application.managedConfigurationTemplate.configurationVariables["%users%"] = ""
									delete application.managedConfigurationTemplate.configurationVariables["%users%"]
								}
							});
						}
						
					break;
					case 'iosProfiles':
						item.oldItemName = item.profilename
						item.profilename = item.profilename + T("_Copy")
						item.devices = []
						item.roles = []
						item.users = []
						item.tags = []

						delete item.profileId

					break;
					case 'apps':
						item.oldItemName = item.name
						item.name = item.name + T("_Copy")
						delete item.appId
						delete item.tenantDomain
						break;

					case 'vpns':
						item.oldItemName = item.vpnname
						item.vpnname = item.vpnname + T("_Copy")
						delete item.vpnId
						delete item.tenantDomain
						break;
					case 'windowsProfiles':
						item = {
							oldItemName: item.name,
							name: item.name + T("_Copy"),
							profile: item.profile
						}
						break;
					case 'secureDnsProfiles':
						item.oldItemName = item.name
						item.name = item.name + T("_Copy")
						delete item.id
						delete item.endpoints
						break;
					case 'homescreenlayouts':
						item.oldItemName = item.Name
						item.Name = item.Name + T("_Copy")
						break;
				}

				return item
			})

			useStore().commit(MutationTypes.setClipboard, {
				"objectType": activePage.value,
				"objects": checkedItems
			})
		break;
		case 'delete':
			let hasDepItems: boolean = false
			let depItems: any[] = []
			if (activePage.value === "iosDevices") {
				// #30842 - DEP Devices should not be deletable
				const originalLength: number = checkedItems.length
				depItems = checkedItems.filter((iosDevice) => {
					return jsonHelpers.getObjectProperty(iosDevice, "depOnly")
				})
				checkedItems = checkedItems.filter((iosDevice) => {
					return !jsonHelpers.getObjectProperty(iosDevice, "depOnly")
				})
				hasDepItems = !(originalLength === checkedItems.length)
			}
			if (checkedItems.length) {
				dialogs.misc.confirmDialog(
					activeAccountId.value,
					"Confirm Delete",
					T("Do you really want to delete selected objects?"),
					async () => {
						for (let i: number = 0; checkedItems.length > i; i++) {
							let item = checkedItems[i]
							switch (activePage.value) {
								case 'androidDevices':
									try {
										await products.mobileSecurity.androidDevices.queries.deleteObjectFromApi(activeAccountId.value, item.id)
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting device"
												}
											}
										})
									}
								break;
								case 'enterpriseDevices':
									try {
										await useStore().dispatch(ActionTypes.deleteObject, {
											"accountId": activeAccountId.value,
											"productType": 'mobileSecurity',
											"objectIdProperty": 'id',
											"objectId": item.id,
											"objectType": 'enterpriseDevices'
										})
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting device"
												}
											}
										})
									}
									break;
								case 'devices':
								case 'iosDevices':
									try {
										if(config.canUseNewObjectType('iosDevices')) {
											await products.mobileSecurity.iosDevices.queries.deleteObjectFromApi(activeAccountId.value, item.deviceId)
										}	
										else {
											await useStore().dispatch(ActionTypes.deleteObject, {
												"accountId": activeAccountId.value,
												"productType": 'mobileSecurity',
												"objectIdProperty": 'deviceId',
												"objectId": item.deviceId,
												"objectType": item.enterpriseDevice ? 'enterpriseDevices' : 'devices'
											})
										}			
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting device"
												}
											}
										})
									}
									break;
								case 'profiles':
								case 'iosProfiles':
									try {
										await products.mobileSecurity.iosProfiles.queries.deleteObjectFromApi(activeAccountId.value, item.profileId)
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting profile"
												}
											}
										})
									}
									break;
								case 'androidProfiles':
									try {
										if (config.canUseNewObjectType("androidProfiles")) {
											await products.mobileSecurity.androidProfiles.queries.deleteObjectFromApi(activeAccountId.value, item.profileId)
										}
										else {
											await useStore().dispatch(ActionTypes.deleteObject, {
												"accountId": activeAccountId.value,
												"productType": 'mobileSecurity',
												"objectIdProperty": 'profileId',
												"objectId": item.profileId,
												"objectType": item.enterpriseProfile ? 'enterpriseProfiles' : 'profiles'
											})
										}
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting profile"
												}
											}
										})
									}
									break;
								case 'apps':
									try {
										await useStore().dispatch(ActionTypes.deleteObject, {
											"accountId": activeAccountId.value,
											"productType": 'mobileSecurity',
											"objectIdProperty": 'appId',
											"objectId": item.appId,
											"objectType": 'apps'
										})
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting app"
												}
											}
										})
									}
									break;
								case 'iosApps':
									try {
										await products.mobileSecurity.iosApps.queries.deleteObjectFromApi(activeAccountId.value, item.appId)
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting app"
												}
											}
										})
									}
								break;
								case 'vpns':
									try {
										vpnLicenses.add(item?.licenseUUID || "")
										if (config.canUseNewObjectType("vpns")) {
											await products.mobileSecurity.vpns.queries.deleteObjectFromApi(activeAccountId.value, item.vpnId)
										}
										else {
											await useStore().dispatch(ActionTypes.deleteObject, {
												"accountId": activeAccountId.value,
												"productType": 'mobileSecurity',
												"objectIdProperty": 'vpnId',
												"objectId": item.vpnId,
												"objectType": 'vpns'
											})
										}
										
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting vpn"
												}
											}
										})
									}
								break;
								case 'homescreenlayouts':
									try {
										if (config.canUseNewObjectType("homescreenlayouts")) {
											await products.mobileSecurity.homescreenlayouts.queries.deleteObjectFromApi(activeAccountId.value, item.layoutId)
										}
										else {
											await useStore().dispatch(ActionTypes.deleteObject, {
												"accountId": activeAccountId.value,
												"productType": 'mobileSecurity',
												"objectIdProperty": 'layoutId',
												"objectId": item.layoutId,
												"objectType": 'homescreenlayouts'
											})
										}
										
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting vpn"
												}
											}
										})
									}
								break;
								case 'users':
									if (!item.vppOnly) {
										try {
											if(config.canUseNewObjectType("users")) {
												await products.unifiedSecurity.users.queries.deleteObjectFromApi(activeAccountId.value,item.username)
											}
											else {
												await useStore().dispatch(ActionTypes.deleteObject, {
													"accountId": activeAccountId.value,
													"productType": 'unifiedSecurity',
													"objectIdProperty": 'username',
													"objectId": item.username,
													"objectType": 'users'
												})
											}
											
										}
										catch (err) {
											useStore().dispatch(ActionTypes.addNotification, {
												"accountId": activeAccountId.value,
												"content": {
													"title": {
														"icon": "fal fa-exclamation-triangle",
														"text": "Error"
													},
													"body": {
														"content": "Error deleting user"
													}
												}
											})
										}
									}
									break;
								case 'roles':
									try {
										if (config.canUseNewObjectType("roles")) {
											await products.unifiedSecurity.roles.queries.deleteObjectFromApi(activeAccountId.value, item.rolename)
										}
										else {
											await useStore().dispatch(ActionTypes.deleteObject, {
												"accountId": activeAccountId.value,
												"productType": 'unifiedSecurity',
												"objectIdProperty": 'rolename',
												"objectId": item.rolename,
												"objectType": 'roles'
											})
										}
										
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting role"
												}
											}
										})
									}
									break;
								case 'apiKeys':
									try {
										await products.unifiedSecurity.apiKeys.queries.deleteObjectFromApi(activeAccountId.value,item.id)
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting role"
												}
											}
										})
									}
								break;
								case "windowsEnrollmentTokens":
									try {
										await products.unifiedSecurityConsole.windowsEnrollmentTokens.queries.deleteObjectFromApi(activeAccountId.value, item.id)
									}
									catch (e: unknown) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting token"
												}
											}
										})
									}
								break;
								case "windowsVpns":
									try {
										await products.unifiedSecurityConsole.windowsVpns.queries.deleteObjectFromApi(activeAccountId.value, item.id)
									}
									catch (e: unknown) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting VPN"
												}
											}
										})
									}
								break;
								case "secureDnsProfiles":
									try {
										await products.secureDns.secureDnsProfiles.queries.deleteObjectFromApi(activeAccountId.value,item.id)
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting configuration"
												}
											}
										})
									}
								break
								case "uscZtProfiles":
									try {
										await products.unifiedSecurityConsole.uscZtProfiles.queries.deleteObjectFromApi(activeAccountId.value,item.id)
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting configuration"
												}
											}
										})
									}
								break;
								case 'windowsProfiles':
									try {
										if(useFeatureStore?.().isEnabled('WINDOWS_DEVICE_PROFILES')) {
											await products.unifiedSecurityConsole.windowsProfiles.queries.deleteObjectFromApi(activeAccountId.value, item.id)
										}
									}
									catch (err) {
										useStore().dispatch(ActionTypes.addNotification, {
											"accountId": activeAccountId.value,
											"content": {
												"title": {
													"icon": "fal fa-exclamation-triangle",
													"text": "Error"
												},
												"body": {
													"content": "Error deleting configuration"
												}
											}
										})
									}
									break;
							}
						}
						if (activePage.value == 'vpns' && Array.from(vpnLicenses).length > 0) {
							for (let licenseId of Array.from(vpnLicenses)) {
								await products.unifiedSecurity.licenses.queries.getObjectFromApi(activeAccountId.value, licenseId)
							}
						}
					},
					T("Delete")
				)
			}
			if (hasDepItems && depItems.length) {
				let modal: any = {
					"accountId": activeAccountId.value,
					"id": "deleteDepDevices",
					"abortable": true,
					"content": {
						"title": {
							"text": T("Error Deleting DEP Devices"),
							"icon": "fal fa-triangle-exclamation"
						},
						"body": {
							"use": true,
							"content": undefined,
							"component": "disabled-select-dialog",
							"properties": {
								"selectOptions": depItems.map((item: any) => {
									return {
										id: item.deviceId,
										text: item.alias ? item.alias : deviceHelpers.getShortDeviceId(item.deviceId)
									}
								}) as selectOption[],
        						"text": T("Some of the selected devices are DEP devices. To remove DEP enrollment please visit <a href='https://business.apple.com/' target='_blank'>Apple</a>.<br>The following devices are affected:")
							}
						},
					},
					"buttons": [
						{
							"text": T("Ok"),
							"icon": "fal fa-check",
							"onClick": async function () {
								getterHelpers.useStore().commit(MutationTypes.removeModal,{accountId:activeAccountId.value})
							},
							"align": "right",
							"loading": false
						}
					],
				}
				useStore()?.commit(MutationTypes.addModal, modal)
			}
			break;
		case 'updates':
			dialogs.mobileSecurity.updateAppleDevice(activeAccountId.value, async (updateType: any) => {
				let failedItems: string[] = []
				let promiseObject: PromiseObject = {}
				checkedItems.forEach((item: any) => {
					if (item.deviceId && item.deviceType == 'IOS' && item.availableUpdates?.length > 0) {
						promiseObject[item.deviceId] = config.canUseNewObjectType('iosDevices') ? products.mobileSecurity.iosDevices.sendDeviceMessage(activeAccountId.value,item.deviceId, {
							"type": "SCHEDULE_OS_UPDATE",
							"payload": {
								"Updates": [{
									"InstallAction": updateType,
									"ProductVersion": item.availableUpdates[0].ProductVersion
								}],
								"RequestRequiresNetworkTether": false
							}
						}) : queries.mobileSecurity.sendDeviceMessage(activeAccountId.value, item.deviceId, {
							"type": "SCHEDULE_OS_UPDATE",
							"payload": {
								"Updates": [{
									"InstallAction": updateType,
									"ProductVersion": item.availableUpdates[0].ProductVersion
								}],
								"RequestRequiresNetworkTether": false
							}
						})
					} 
					else {
						failedItems.push(item.alias !== "" ? item.alias : deviceHelpers.getShortDeviceId(item.deviceId))
					}
				})
				await promiseHelpers.resolvePromiseObject(promiseObject)
				if (failedItems.length !== 0) {
					let modal: any = {
						"id": "failedOsUpdates",
						"content": {
							"title": {
								"text": T("Failed OS Updates"),
								"icon": "fal fa-exclamation-triangle"
							},
							"body": {
								"content": undefined,
								"use": true,
								"component": 'disabled-select-dialog',
								"properties": {
									"selectOptions": failedItems.map((item: string) => { return { "id": item, "text": item } }) as selectOption[],
									"text": T('The updates have been initiated. The following devices could not be updated, because there are no available updates:')
								}
							},
						},
						"buttons": [
							{
								"text": T("Close"),
								"icon": "fal fa-times",
								"onClick": async function () {
									getterHelpers.useStore().commit(MutationTypes.removeModal)
								},
								"align": "right",
								"loading": false
							}
						],
					}
					useVue().$refs.modals.addModal(modal)
				} else {
					dialogs.misc.confirmDialog(activeAccountId.value, T('Initiated OS Updates'), T('The Updates have been initiated successfully'), () => {
						getterHelpers.useStore().commit(MutationTypes.removeModal,{accountId:activeAccountId.value})
					}
					,undefined,undefined,undefined,undefined, "fal fa-check")
				}
			})
			break;
	}

	for (let i in activeItems.value.items) {
		let item = activeItems.value.items[i as keyof typeof activeItems.value.items]
		if (item.isChecked) {
			item.isChecked = false
		}
		if (item.$itemlist?.isCheckboxChecked) {
			item.$itemlist.isCheckboxChecked = false
		}
	}
	checkedItemsCount.value = 0
	bulk.loading = false
}
function toggleMapView() {
	mapView.value = !mapView.value
}
function toggleView() {
	if (viewMode.value == 'list') {
		viewMode.value = 'grid';
		cookies.set('gridView', '1', 15)
	}
	else {
		viewMode.value = 'list';
		cookies.set('gridView', '0', 15)
	}
	calcItemsPerRow()
	calcRowHeights()
}
function toggleInfo() {
	if (showInfo.value == true) {
		showInfo.value = false
		cookies.set('showDetails', '0', 15)
	}
	else {
		showInfo.value = true
		cookies.set('showDetails', '1', 15)
	}
}
function toggleSignedInOnly() {
	if (filterSignedOutDevices.value == true) {
		filterSignedOutDevices.value = false
		cookies.set('signedInOnly', '0', 15)
	}
	else {
		filterSignedOutDevices.value = true
		cookies.set('signedInOnly', '1', 15)
	}
}
async function getDropZoneSettings() {

	let thisDropZone: any = {
		dropZoneActive: false,
		fileTypes: [],
		dropZoneSendToUrl: "",
	}
	switch (activePage.value) {
		case 'profiles':
		case 'iosProfiles':
			if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
				thisDropZone.dropZoneUploadText = T('Import profile')
				thisDropZone.dropZoneActive = true
				thisDropZone.fileTypes = ['profile']
				thisDropZone.dropZoneSendToUrl = "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain.value + "/profiles"
			}
			break;
		case 'androidProfiles':
				if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid') && tenantHelpers.hasFunctionality(activeAccountId.value, "enterprise")) {
					thisDropZone.dropZoneUploadText = T('Import profile')
					thisDropZone.dropZoneActive = true
					thisDropZone.fileTypes = ['enterpriseProfile']
					thisDropZone.dropZoneSendToUrl = "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') + "/tenants/" + activeTenantDomain.value + "/android/emm/enterprise/policies"
				}
			break;
		case 'apps':
			if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
				thisDropZone.dropZoneUploadText = T('Import app')
				thisDropZone.dropZoneActive = true
				thisDropZone.fileTypes = ['app']
				thisDropZone.dropZoneSendToUrl = "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain.value + "/apps"
			}
			break;
		case 'iosApps':
			if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ['Mobile Security', 'MDM'], 'valid')) {
				thisDropZone.dropZoneUploadText = T('Import app')
				thisDropZone.dropZoneActive = true
				thisDropZone.fileTypes = ['iosApp']
				thisDropZone.dropZoneSendToUrl = "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain.value + "/apps"
			}
			break;
		case 'vpns':
			if (enrollmentAllowed.value) {
				thisDropZone.dropZoneUploadText = T('Import configuration')
				thisDropZone.dropZoneActive = true
				thisDropZone.fileTypes = ['vpn']
				thisDropZone.dropZoneSendToUrl = "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain.value + "/vpns"
			}
			break;
	}
	dropZone.dropZoneActive = thisDropZone.dropZoneActive
	dropZone.dropZoneSendToUrl = thisDropZone.dropZoneSendToUrl
	dropZone.dropZoneUploadText = thisDropZone.dropZoneUploadText
	dropZone.fileTypes = thisDropZone.fileTypes
}
async function getSubscriptions() {
	switch (activePage.value) {
		case 'devices':
		case 'iosDevices':
			useStore().commit(MutationTypes.addSubscriptionHook, {
				"accountId": activeAccountId.value,
				"hookKey": "itemlist-devices",
				"hookFunction": function (message: any) {
					if (message.topic?.includes('/device/ping')) {
						let deviceId: any = message.data?.deviceId
						$('#pingbutton-' + deviceId).addClass('btn-loading')
						setTimeout(function () {
							$('#pingbutton-' + deviceId).removeClass('btn-loading')
						}, 5000)
					}
					else if (message.topic?.includes('/device/pong')) {
						let deviceId: any = message.data?.deviceId
						$('#pingbutton-' + deviceId).removeClass('btn-loading')
					}
					if (message.topic?.includes('/device/pong') || message.topic?.includes('remove') || message.topic?.includes('enrolled')) {
						return true
					}
				}
			})

			break;
	}
}
function initDropZone() {
	if (!dropZoneInitialized.value && dropZone.dropZoneActive) {
		Dropzone.options.itemListDropArea = {
			"clickable": '#filebrowser',
			"uploadMultiple": false,
			"paramName": "file",
			"accept": function (file: any, done: Function) {
				let reader: any = new FileReader();
				reader.addEventListener('loadend', function (event: any) {
					let filecontent: string = event.target.result
					let fileJson : any = ""
					try {
						fileJson = JSON.parse(filecontent)
						// Add validation
						if (typeof fileJson == 'string') fileJson = JSON.parse(fileJson)
					}
					catch(e) {
						dialogs.misc.errorDialog(activeAccountId.value, T("Wrong content"),T("File content is not supported") + ".")
						done(T("File content is not supported"))
					}


					if (activePage.value == "androidProfiles" || activePage.value == "iosProfiles" || activePage.value == "vpns" || activePage.value == "iosApps" || activePage.value == "apps") {
						let object = jsonHelpers.copyObject(fileJson)
						let callBacks: {[key: string]: () => void
						} = {
							"vpns": () => {
								products.mobileSecurity.vpns.convertObjectForStore(activeAccountId.value, object)
								products.mobileSecurity.vpns.saveToClipBoard(activeAccountId.value, object, false)
							},
							"androidProfiles": () => {
								products.mobileSecurity.androidProfiles.convertObjectForStore(activeAccountId.value, object)
								products.mobileSecurity.androidProfiles.saveToClipBoard(activeAccountId.value, object, false)
							},
							"iosProfiles": () => {
								products.mobileSecurity.iosProfiles.convertObjectForStore(activeAccountId.value, object)
								products.mobileSecurity.iosProfiles.saveToClipboard(object, false)
							},
							"iosApps": () => {
								products.mobileSecurity.iosApps.convertObjectForStore(activeAccountId.value, object)
								products.mobileSecurity.iosApps.saveToClipBoard(activeAccountId.value, object, false)
							},
							"apps": () => {
								products.mobileSecurity.iosApps.convertObjectForStore(activeAccountId.value, object)
								products.mobileSecurity.iosApps.saveToClipBoard(activeAccountId.value, object, false)
							}
						}
						callBacks[activePage.value]();
						pasteItems()
						$('#itemListDropArea').removeClass('dz-started')
						$('#itemListDropArea').find('.dz-preview').remove()
					}
					else {
						done()
					}
				})
				if (file.name.indexOf(dropZone.fileTypes) == -1) {
				
					dialogs.misc.errorDialog(activeAccountId.value,T("Wrong file type"), T("Wrong file type")+". "+ T("Please upload a file with the following file type:") + " *." + dropZone.fileTypes.join(", *.") + ".")

					done(T("Wrong file type"))
				}
				else {
					reader.readAsText(file);
				}
			},
			"previewTemplate": `
					<div class="dz-preview dz-file-preview">
						<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
						<div class="dz-success-mark"><span><i class="fal fa-check color-lightgreen"></i> ${T('FILE SUCCESSFULLY UPLOADED')}</span></div>
						<div class="dz-error-mark"><span><i class="fal fa-times color-red"></i> ${T('ERROR UPLOADING FILE')}</span></div>
						<br>
						<div class="dz-details">
							<div class="dz-filename"><strong>File:</strong> <span data-dz-name></span></div>
						</div>
						<div class="dz-error-message"><strong>Error:</strong> <span data-dz-errormessage></span></div>
						<br>
						<div class="dz-close empty"></div>
					</div>`,
		}
		var myDropzone = new Dropzone('#itemListDropArea', { url: dropZone.dropZoneSendToUrl })
		let $buttonTemplate = $('<a class="btn btn-primary">' + T("back") + '</a>')
		myDropzone.on("dragover", function () {
			$('#itemListDropArea').removeClass('dz-started')
			$('#itemListDropArea').find('.dz-preview').remove()
		})
		myDropzone.on("dragend", function () {
			$('#itemListDropArea').removeClass('dz-started')
			$('#itemListDropArea').find('.dz-preview').remove()
		})
		myDropzone.on("complete", function () {
			$('.dz-preview').find('.dz-close.empty').each(function () {
				$(this).append($buttonTemplate).find('.btn').on('click', async function () {
					$('#itemListDropArea').removeClass('dz-started')
					$(this)
						.parent('.dz-close')
						.parent('.dz-preview')
						.remove()
				})
				$(this).removeClass('empty')
				initDropZone()
			})
			$('#itemListDropArea').removeClass('dz-started')
			$('#itemListDropArea').find('.dz-preview').remove()
		})
		myDropzone.on("success", function () {
			$('#itemListDropArea')
				.removeClass('dz-started')
				.find('.dz-preview')
				.remove()

			refresh()
			initDropZone()
		})
		$('#itemListDropArea').removeClass('dz-started')
		$('#itemListDropArea').find('.dz-preview').remove()
		dropZoneInitialized.value = true
	}
}
function getObjectProperty(object: any, property: string) {
	return jsonHelpers.getObjectProperty(object, property)
}
async function syncDep() {
	let tenantDomain = activeTenantDomain.value
	await queries.mobileSecurity.dep.syncDEPDevices(tenantDomain)
	refresh(true)
}
function renderInviteDeviceDialog() {
	dialogs.mobileSecurity.inviteDeviceDialog(activeAccountId.value, <'iosDevices' | 'enterpriseDevices'>activePage.value)
}
async function renderEnrollDeviceDialog() {
	dialogs.mobileSecurity.enrollDeviceDialog(activeAccountId.value,<'iosDevices' | 'enterpriseDevices'>activePage.value)
}
function reloadMap() {
	map.value = undefined
	let $itemListMap: Element = $('#itemListMap')[0]
	let markerInfos: any[] = []
	const darkmodeMapStyles: google.maps.MapTypeStyle[] = [
		{ elementType: "geometry", stylers: [{ color: "#242f3e" }] },
		{ elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
		{ elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
		{
			featureType: "administrative.locality",
			elementType: "labels.text.fill",
			stylers: [{ color: "#d59563" }],
		},
		{
			featureType: "poi",
			elementType: "labels.text.fill",
			stylers: [{ color: "#d59563" }],
		},
		{
			featureType: "poi.park",
			elementType: "geometry",
			stylers: [{ color: "#263c3f" }],
		},
		{
			featureType: "poi.park",
			elementType: "labels.text.fill",
			stylers: [{ color: "#6b9a76" }],
		},
		{
			featureType: "road",
			elementType: "geometry",
			stylers: [{ color: "#38414e" }],
		},
		{
			featureType: "road",
			elementType: "geometry.stroke",
			stylers: [{ color: "#212a37" }],
		},
		{
			featureType: "road",
			elementType: "labels.text.fill",
			stylers: [{ color: "#9ca5b3" }],
		},
		{
			featureType: "road.highway",
			elementType: "geometry",
			stylers: [{ color: "#746855" }],
		},
		{
			featureType: "road.highway",
			elementType: "geometry.stroke",
			stylers: [{ color: "#1f2835" }],
		},
		{
			featureType: "road.highway",
			elementType: "labels.text.fill",
			stylers: [{ color: "#f3d19c" }],
		},
		{
			featureType: "transit",
			elementType: "geometry",
			stylers: [{ color: "#2f3948" }],
		},
		{
			featureType: "transit.station",
			elementType: "labels.text.fill",
			stylers: [{ color: "#d59563" }],
		},
		{
			featureType: "water",
			elementType: "geometry",
			stylers: [{ color: "#17263c" }],
		},
		{
			featureType: "water",
			elementType: "labels.text.fill",
			stylers: [{ color: "#515c6d" }],
		},
		{
			featureType: "water",
			elementType: "labels.text.stroke",
			stylers: [{ color: "#17263c" }],
		},
	]
	if (mapView.value) {
		$('#itemListMap').children().each(function (i: number, el: Element) {
			$(el).show()
		})

		activeItems.value?.items?.forEach(function (item: any) {
			let thisInventoryLocation: any = item.inventory?.inventoryLocation
			if (thisInventoryLocation && thisInventoryLocation.longitude && thisInventoryLocation.latitude) {
				let thisMarkerInfo: any = {
					"long": thisInventoryLocation.longitude,
					"lat": thisInventoryLocation.latitude,
					"infobox": ""
				}
				thisMarkerInfo.infobox += "<div class='margin-xs-2 box box-border padding-xs'>"
				thisMarkerInfo.infobox += "<p><strong>" + encodingHelpers.escapeHTML(item.utmname) + "</strong></p>"
				thisMarkerInfo.infobox += "<hr class='margin-xs-y'>"
				thisMarkerInfo.infobox += "<strong>" + T("Model") + ": </strong> " + item.model + "<hr>"
				thisMarkerInfo.infobox += "<strong>" + T("CPU") + ": </strong> " + (jsonHelpers.getObjectProperty(item, 'messages.spcli-system-info.cpu.name') ? jsonHelpers.getObjectProperty(item, 'messages.spcli-system-info.cpu.name') + jsonHelpers.getObjectProperty(item, 'messages.spcli-system-info.cpu.mhz') + ' Mhz' : "") + "<hr>";
				thisMarkerInfo.infobox += "<strong>" + T("RAM") + ": </strong> " + (jsonHelpers.getObjectProperty(item, 'messages.spcli-system-info.memory.total') ? numberHelpers.formatBytes(item.messages['spcli-system-info'].memory.total * 1000, 2).value + " " + numberHelpers.formatBytes(item.messages['spcli-system-info'].memory.total * 1000, 2).unit : "") + "<hr>";
				thisMarkerInfo.infobox += "<strong>" + T("HDD") + ": </strong> " + (jsonHelpers.getObjectProperty(item, 'messages.spcli-system-info.storage.total') ? numberHelpers.formatBytes(item.messages['spcli-system-info'].storage.total).value + ' ' + numberHelpers.formatBytes(item.messages['spcli-system-info'].storage.total).unit : "") + "<hr>";
				thisMarkerInfo.infobox += "<strong>" + T("Interfaces") + ": </strong> " + (item.messages['spcli-system-info']?.network?.interfaces || "") + "<hr>";
				thisMarkerInfo.infobox += "<strong>" + T("Vesion") + ": </strong> " + (jsonHelpers.getObjectProperty(item, 'messages.spcli-system-info.version') ? item.messages['spcli-system-info'].version : "") + "<hr>";
				thisMarkerInfo.infobox += "<strong>" + T("Licensee") + ": </strong> " + (jsonHelpers.getObjectProperty(item, 'messages.spcli-system-info.license.organisation.name') ? item.messages['spcli-system-info'].license.organisation.name : "") + "<hr>";
				thisMarkerInfo.infobox += "<strong>" + T("Last refresh") + ": </strong> " + (jsonHelpers.getObjectProperty(item, 'messages.spcli-system-info.timestamp') ? moment(Math.min(item.messages['spcli-system-info'].timestamp * 1000, Date.now())).fromNow() : "");
				thisMarkerInfo.infobox += "<hr class='margin-xs-y'>";
				if (thisInventoryLocation.street) {
					thisMarkerInfo.infobox += "<strong>" + T("Street") + ": </strong> " + encodingHelpers.escapeHTML(thisInventoryLocation.street) + '<hr>';
				}
				if (thisInventoryLocation.postalCode && thisInventoryLocation.city) {
					thisMarkerInfo.infobox += "<strong>" + T("City") + ": </strong> " + encodingHelpers.escapeHTML(thisInventoryLocation.postalCode) + ' ' + encodingHelpers.escapeHTML(thisInventoryLocation.city);
				}
				thisMarkerInfo.infobox += "<hr class='margin-xs-y'>";
				thisMarkerInfo.infobox += '<a class="btn btn-primary" href="' + '#show-tenant-' + activeTenantDomain.value + '-usc-utms-' + item.utmId + '"> <i class="fal fa-eye"></i> ' + T('Show details') + '</a>';
				thisMarkerInfo.infobox += '</div>';
				markerInfos.push(thisMarkerInfo)
			}
		})
	}

	if (mapView.value && $itemListMap) {

		let mapOptions: google.maps.MapOptions = {
			"scaleControl": true,
			"center": {
				"lat": markerInfos[0] ? markerInfos[0].lat : 51.87033,
				"lng": markerInfos[0] ? markerInfos[0].long : 10.34886
			},
			"zoom": markerInfos[0] ? 10 : 16,
			"styles": darkmode.value == "1" ? darkmodeMapStyles : []
		}
		if (map.value == undefined) {
			map.value = new google.maps.Map($itemListMap, mapOptions)
		}

		if (map.value != undefined) {
			let i: number = 0
			markerInfos.forEach(function (markerInfo: any) {
				setTimeout(function () {
					let marker: any = new google.maps.Marker({
						"position": {
							"lat": markerInfo.lat,
							"lng": markerInfo.long
						},
						"map": map.value,
						"label": $('<i class="fal fa-fw fa-server"></i>').html()
					})
					let info: google.maps.InfoWindow = new google.maps.InfoWindow({
						content: T($(markerInfo.infobox)).html()
					})
					marker.addListener("click", () => {
						info.open(map.value, marker);
					});
				}, i * 200);
				i++
			})
		}
	}
	else if (!mapView.value) {
		$('#itemlist').children().each(function (i: number, el: Element) {
			if ($(el).attr('style') == 'height: 100%; width: 100%; position: absolute; top: 0px; left: 0px; background-color: rgb(229, 227, 223);') {
				$(el).hide()
			}
		})
	}
}
function checkLegacyProfiles() {
	if (objectType.value == "iosProfiles") {
		let hasLegacyProfiles = activeAccount.value?.mobileSecurity?.profiles?.items?.some((profile: any) => {
			return profile.platform == "ANDROID"
		}) || false
		if (hasLegacyProfiles) {
			dialogs.misc.confirmDialog(
				activeAccountId.value,
				T('Legacy Android profiles found'),
				T('Legacy android profiles have been found.') + '<br>' + T('These profiles are not supported any more and will be deleted after closing this dialog.'),
				async () => {
					activeAccount.value?.mobileSecurity?.profiles?.items?.forEach(async (profile: any) => {
						if (profile.platform === "ANDROID") {
							await useStore().dispatch(ActionTypes.deleteObject, {
								"accountId": activeAccountId.value,
								"productType": "mobileSecurity",
								"objectId": profile.profileId,
								"objectType": "profiles"
							})
						}
					});
					refresh(true)
				},
				undefined,
				"OK",
				"fal fa-check",
				false
			)
		}
	}
}
function saveSortingOptions() {
	const sortKey = "sortBy:" + activePage.value
	const sortValue = sortBy.value
	const directionKey = "sortDirection:" + activePage.value
	const directionValue = sortDirection.value

	localStorageHandler.set(sortKey, sortValue)
	localStorageHandler.set(directionKey, directionValue)
}
function loadSortingOptions() {
	const sortKey = "sortBy:" + activePage.value
	const directionKey = "sortDirection:" + activePage.value

	const sortValue = localStorageHandler.get(sortKey)
	const directionValue = localStorageHandler.get(directionKey)

	let hasSelectedOption = sortingOptions.value?.some((option) => {
		return option.id == sortValue
	})

	if ((sortValue == null || !hasSelectedOption) && sortingOptions.value.length > 0) {
		sortBy.value = sortingOptions.value[0].id
	}
	else if (sortValue != null) {
		sortBy.value = sortValue
		if (directionValue != null) {
			sortDirection.value = directionValue
		}
	}
}
function $getObjectIdPropertyForObjectType(objectType: string) {
	return mixinHelpers.$getObjectIdPropertyForObjectType(objectType)
}
function calcCellWidth() {
	let cells = document.getElementById('itemlist')?.children
	if (cells?.length) {
		cellWidth.value = cells[0].clientWidth
	}
}
function calcRenderedRows() {
	let bodyHeight = document.body.clientHeight
	if(bodyHeight >= 1100) {
		virtualScrolling.renderedRows = showInfo.value ? 18 : 28
	}
	else {
		virtualScrolling.renderedRows = showInfo.value ? 12 : 24
	}
}
function calcItemsPerRow() {
	let bodyWidth = document.body.clientWidth
	if (viewMode.value != "grid" || bodyWidth < 768) {
		virtualScrolling.itemsPerRow = 1
	}
	else if (bodyWidth < 992) {
		virtualScrolling.itemsPerRow = 2
	}
	else {
		virtualScrolling.itemsPerRow = 3
	}
	virtualScrolling.maxItemsInViewPort = virtualScrolling.renderedRows * virtualScrolling.itemsPerRow

}
function calcRowHeights() {
	let cells = document.getElementById('itemlist')?.children
	let heightList: number[] = []
	if (cells?.length) {
		const itemsPerRow = virtualScrolling.itemsPerRow
		const renderedRows = virtualScrolling.renderedRows

		for (let i = 0; renderedRows > i; i++) {
			if (cells[i * itemsPerRow]) {
				heightList.push(cells[i * itemsPerRow].clientHeight)
			}
		}
		virtualScrolling.heightList = heightList
	}
}
// Lifecycles
onMounted(async () => {
	loading.value = true
	bulk.action = 'none'
	getDropZoneSettings()
	getSubscriptions()
	if (cookies.get('gridView') === "1") {
		viewMode.value = "grid"
	}
	else if (cookies.get('gridView') === "0") {
		viewMode.value = "list"
	}
	if (cookies.get('showDetails') === "1") {
		showInfo.value = true
	}
	else if (cookies.get('showDetails') === "0") {
		showInfo.value = false
	}
	if (cookies.get('signedInOnly') === "1") {
		filterSignedOutDevices.value = true
	}
	else if (cookies.get('signedInOnly') === "0") {
		filterSignedOutDevices.value = false
	}

	loadSortingOptions()
	const thisPageMeta = useStore().state.resources.pages[activePage.value]
	const countProperty: "count" | "androidCount" | "iosCount" = thisPageMeta?.options.countProperty || "count"
	if (activeItems.value[countProperty] != undefined && activeItems.value[countProperty] === activeItems.value?.items?.length) {
		loading.value = false
	}
	await refresh()

	nextTick(() => {
		calcRenderedRows()
		calcItemsPerRow()
		calcRowHeights()

		$('main').on('scroll', throttle(2, () => {
			// scrolled
			// Get real scroll distance
			const realScrollDistance = document.getElementsByTagName('main')[0].scrollTop
			// get distance from top to first row
			const distanceToFirstRow = document.getElementsByClassName('pageheader')[0].clientHeight + (document.getElementById('itemlist')?.offsetTop || 133)
			// get height of scrolling frame
			const frameHeight = document.getElementsByTagName('main')[0].clientHeight
			
			// calculate the scrolled distance
			const scrollDistance = virtualScrolling.scrollDistance = realScrollDistance > distanceToFirstRow ? realScrollDistance - distanceToFirstRow : 0
			// get scrolling direction
			const scrollDirection = virtualScrolling.scrollDistance > virtualScrolling.lastScrollDistance ? "down" : virtualScrolling.scrollDistance == virtualScrolling.lastScrollDistance ? "ignored" : "up"
			// calculate the height of each row
			calcRowHeights()

			const firstRowHeight = virtualScrolling.heightList.length ? virtualScrolling.heightList[0] : 0

			if (scrollDirection == "down") {
				// Scrolled down
				let paddingsTop = virtualScrolling.paddingsTop.length > 0 ? (<number[]>virtualScrolling.paddingsTop).reduce(function (pv, cv) { return pv + cv; }, 0) : 0
				if (scrollDistance > (firstRowHeight + paddingsTop)) {
					// if scroll distance is higher than the first row's heihgt -> all top-paddings
					// update starting item index 
					virtualScrolling.fromIndex = virtualScrolling.fromIndex + virtualScrolling.itemsPerRow
					// add top padding
					virtualScrolling.paddingsTop.push(firstRowHeight)
					// recalculate row heights
					calcRowHeights()
				}
			}
			else if (scrollDirection == "up" && virtualScrolling.paddingsTop.length > 0) {
				// Scrolled up
				let paddingsTop = virtualScrolling.paddingsTop.length > 0 ? (<number[]>virtualScrolling.paddingsTop).reduce(function (pv, cv) { return pv + cv; }, 0) : 0
				if (scrollDistance < (firstRowHeight + paddingsTop)) {
					//scroll distance is smaller than first row + all top-paddings
					// update starting item index 
					virtualScrolling.fromIndex = virtualScrolling.fromIndex - virtualScrolling.itemsPerRow
					// remove padding from top
					virtualScrolling.paddingsTop.pop()
					// recalculate row heights
					calcRowHeights()
				}
			}
			else if (scrollDirection == "ignored" && virtualScrolling.paddingsTop.length) {
				// scrolling ignored, should be at the top
				// reset paddings
				virtualScrolling.paddingsTop = []
				// reset starting item index
				virtualScrolling.fromIndex = 0
				// recalculate row heights
				calcRowHeights()
			}
			// set last scroll distance in order to detect the next scrolling direction
			virtualScrolling.lastScrollDistance = virtualScrolling.scrollDistance
		}))

		$(window).on('resize', () => {
			calcRenderedRows()
			calcItemsPerRow()
			calcRowHeights()
			calcCellWidth()
		})
	})
})
onBeforeMount(() => {
	dropZoneInitialized.value = false
	useStore().commit(MutationTypes.deleteSubscriptionHook, {
		"accountId": activeAccountId.value,
		"hookKey": "itemlist-devices",
	})
	if(activeItems.value.items) {
		for (let i in activeItems.value.items) {
			let item: any = activeItems.value.items[i]
			if (typeof item.isChecked == "boolean") {
				item.isChecked = false
			}
			if (typeof item.$itemlist?.isCheckboxChecked == "boolean") {
				item.$itemlist.isCheckboxChecked = false
			}
		}
	}
})
// Watches
watch(sortBy, (newValue, oldValue) => {
	saveSortingOptions()
});
watch(sortDirection, (newValue, oldValue) => {
	saveSortingOptions()
});
watch(() => filter.value.value, (newValue, oldValue) => {
		const debouncedFn = throttle(1000, () => {
			filter.value.readValue = newValue
		})
		debouncedFn()
});
watch(darkmode, (newValue, oldValue) => {
	reloadMap()
});
watch(() => dropZone, (newValue, oldValue) => {
	function initItemListDropZone() {
		setTimeout(function () {
			if ($('#itemListDropArea').length) {
				initDropZone()
			}
			else {
				initItemListDropZone()
			}
		}, 1000)
	}
	if (Dropzone != undefined) {
		if (Dropzone.instances.length > 0) {
			for (let instance of Dropzone.instances) {
				instance.destroy()
			}
		}
	}
	if (dropZone.dropZoneActive && !dropZoneInitialized.value) {
		initItemListDropZone()
	}
}, 
{ deep: true });
watch(activePage, (newValue, oldValue) => {
	loading.value = true
	refreshing.value = true
	getDropZoneSettings()
	getSubscriptions()

});
watch(activeTenantDomain, (newValue, oldValue) => {
	if (dropZoneInitialized.value) {
		dropZoneInitialized.value = false
	}
	getDropZoneSettings()
	getSubscriptions()
});
watch(mapView, (newValue, oldValue) => {
	nextTick(function () {
		reloadMap()
	})
});
watch(isAzureImporting, () => {
	if (isAzureImporting.value && (activePage.value == "users" || activePage.value == "roles")) {
		for (let i in activeItems.value.items) {
			let item = activeItems.value.items[i as keyof typeof activeItems.value.items]
			if (item.isChecked) {
				item.isChecked = false
			}
			if (item.$itemlist?.isCheckboxChecked) {
				item.$itemlist.isCheckboxChecked = false
			}
		}
		checkedItemsCount.value = 0
	}
})

defineExpose({
	hasNotEnrolledEnterpriseDevices,
	hasDevicesWithoutLicense,
	activeAccountId,
	refresh,
	accountRestrictions,
	activeTenantDomain,
	activeItems,
	checkedItemsCount,
	renderInviteDeviceDialog,
	refreshCounter,
	showInfo,
	filter,
	showExpiredLicenses,
	toggleExpiredLicenses,
	showExpiredTokens,
	toggleExpiredTokens,
	showExpiredKeys,
	toggleExpiredKeys,
	activeItemsCount,
	enrollmentAllowed,
	dropZoneInitialized,
	showExpiredUscZtProfiles,
	toggleExpiredUscZtProfiles
})
provide("itemlistRefresh", refresh)
provide("itemlistShowinfo", showInfo.value)
provide("itemlistItemcolor", useStore().state.resources.registeredProducts[productType.value]?.objectTypes[activePage.value]?.appearance?.color)
</script>
<template>
	<div style="min-height: calc(100vh - 258px); position: relative;">
		<section class="padding-xs padding-md-2 padding-lg-4 pagecontent"
			style="min-height: calc(100vh - 258px); position: relative;">
			<div v-if="showToolbar" class="row toolbar">
				<div class="col-xs-24 padding-xs-b">
					<div class="box-border bg-white padding-xs-t padding-xs-l">
						<div class="row flexrow flex-wrap margin-xs-0">

							<div class="col-xs padding-xs-0 padding-xs-b padding-xs-r" v-if="sortingOptions.length">
								<label class="form-inputgroup margin-xs-b-0" :title="T('Sort by')">
									<span class="form-icon-prefix">
										<i class="fal fa-sort"></i>
									</span>
									<select v-model="sortBy">
										<template v-for="sortingOption in sortingOptions">
											<option :value="sortingOption.id">{{ T(sortingOption.text) }}</option>
										</template>
									</select>
								</label>
							</div>

							<div class="col-xs padding-xs-0 padding-xs-b padding-xs-r" v-if="sortingOptions.length">
								<label class="form-inputgroup margin-xs-b-0" :title="T('Order')">
									<span class="form-icon-prefix">
										<i class="fal fa-sort-alpha-down"></i>
									</span>
									<select v-model="sortDirection">
										<option value="ASC">{{ T("Ascending") }}</option>
										<option value="DESC">{{ T("Descending") }}</option>
									</select>
								</label>
							</div>


							<div class="col-xs padding-xs-0 padding-xs-b padding-xs-r">
								<label class="form-inputgroup margin-xs-b-0">
									<span class="form-icon-prefix">
										<i class="fal fa-search"></i>
									</span>
									<input id="itemlistSearch" type="text" v-model="filter.value" :placeholder="T('Search')" />
								</label>
							</div>
							<div class="col-xs flex-shrink  padding-xs-0 padding-xs-b padding-xs-r">
								<div class="btn-toolbar btn-toolbar" v-if="toolBarEntries.length != -1">
									<div class="btn-group">
										<template v-for="toolBarEntry in toolBarEntries">
											<a class="btn btn-menu" :href="toolBarEntry.link"
												v-on:click="toolBarEntry.onClick ? toolBarEntry.onClick() : undefined"
												:class="toolBarEntry.class" :id="toolBarEntry.id"
												:disabled="toolBarEntry.disabled || null"
												:title="toolBarEntry.hoverText ? toolBarEntry.hoverText : toolBarEntry.title"
												v-if="toolBarEntry.vIf ? checkToolbarEntry(toolBarEntry.vIf) : true">
												<i class="fa-fw" :class="toolBarEntry.icon" :title="toolBarEntry.title"></i>
												<span>{{ toolBarEntry.title }}</span>
											</a>
										</template>
										<template v-if="dropZone.dropZoneActive">
											<a class="btn btn-menu" id="filebrowser">
												<i class="fal fa-fw fa-upload"></i> <span>{{ dropZone.dropZoneUploadText
												}}</span>
											</a>
										</template>
										
										<template v-if="store.state.clipBoard[activePage] && !mapView">
											<a class="btn btn-menu" v-if="store.state.clipBoard[activePage].length"
												v-on:click="pasteItems()">
												<i class="fal fa-paste"></i> {{ T('Paste') }}
											</a>
										</template>
										
									</div>
								</div>
							</div>
							<div class="col-xs padding-xs-0 padding-xs-b padding-xs-r"
								:class="{ 'flex-shrink': !mapView, 'text-right': mapView }">
								<div class="btn-toolbar btn-toolbar">
									<div class="btn-group">
										<a v-on:click="toggleSignedInOnly()" id="itemListToggleSignedIn"
											class="btn btn-menu" :class="{ 'active': filterSignedOutDevices }"
											v-if="activePage == 'devices' && !mapView">
											<i title="T('Filter devices')" class="fa-fw fal fa-eye"></i> {{ T('Filter devices')}}
										</a>
										<a v-on:click="toggleFilterGeneratedPolicies()" id="itemListToggleGeneratedPolicies"
											class="btn btn-menu" :class="{ 'active': filterGeneratedPolicies }"
											v-if="activePage == 'androidProfiles' && !mapView">
											<i title="T('Hide generated profiles')" class="fa-fw fal fa-eye"></i> {{ T('Hide generated profiles')}}
										</a>

										<a id="itemlistToggleDetails" class="btn btn-menu" v-on:click="toggleInfo()"
											v-bind:class="{ 'active': showInfo }" :title="T('Show/Hide details')"
											v-if="!mapView">
											<i class="fal fa-fw fa-info-circle"></i>
										</a>
										<a id="itemListToggleMap" class="btn btn-menu" v-on:click="toggleMapView()"
											v-if="objectType == 'uscUtms' && !mapView" :title="T('Switch to map-view')">
											<i class="fal fa-fw fa-map-marker-alt"></i>
										</a>
										<a id="itemListToggleMap" class="btn btn-menu" v-on:click="toggleMapView()"
											v-if="objectType == 'uscUtms' && mapView" :title="T('Disable map-view')">
											<i class="fal fa-fw fa-th"></i>
										</a>
										<a id="itemlistToggleView" class="btn btn-menu" v-on:click="toggleView()"
											v-if="viewMode == 'list' && !mapView" :title="T('Switch to grid-view')">
											<i class="fal fa-fw fa-th"></i>
										</a>
										<a id="itemlistToggleView" class="btn btn-menu" v-on:click="toggleView()"
											v-if="viewMode == 'grid' && !mapView" :title="T('Switch to list-view')">
											<i class="fal fa-fw fa-bars"></i>
										</a>
										<a id="itemlistButtonRefresh" class="btn btn-menu btn-loader twist-in" 
											style="max-height: 34.5px; max-width: 35.75px;"
											v-on:click="refresh(false)" :class="{ 'btn-loading': loading == true }">
											<span class="animate">
												<i v-if="loading == true" class="progress-circular no-progress"
													style="font-size:1.5em;top:50%;position:absolute;left:50%;transform:translate(-50%,-50%);">
													<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
														viewBox="0 0 50 50" style="stroke-width: 4px;">
														<circle cx="25" cy="25" r="20"
															style="stroke: rgba(0, 0, 0, 0.1); fill: none;"></circle>
														<g transform="translate(25,25) rotate(-90)">
															<circle stroke-dasharray="110" stroke-dashoffset="0" cx="0"
																cy="0" r="20" style="fill: none; stroke-linecap: round;"
																transform="rotate(14.0181)">
																<animate attributeName="stroke-dashoffset" values="360;140"
																	dur="2.2s" keyTimes="0;1" calcMode="spline"
																	fill="freeze" keySplines="0.41,0.314,0.8,0.54"
																	repeatCount="indefinite" begin="0"></animate>
																<animate attributeName="stroke" fill="freeze" dur="8s"
																	begin="0" repeatCount="indefinite"></animate>
															</circle>
														</g>
													</svg>
												</i>
											</span>
											<span v-if="!refreshing"><i :title="T('Refresh')"
													class="fal fa-fw fa-sync"></i></span>
											<span v-if="refreshing">
												<loaderComponent class="text-size-2 color-red" />
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="row flexrow flex-wrap" v-if="hasCheckedItems && !mapView">

							<div class="col-xs flex-shrink col-md padding-xs padding-xs-t-2 text-bold">
								{{ T('Action for selected items') }}:
							</div>
							<div class="col-xs col-md padding-xs">
								<label class="form-inputgroup margin-xs-b-0">
									<span class="form-icon-prefix">
										<i class="fal fa-wrench"></i>
									</span>
									<select id="itemlistSearchFilter" v-model="bulk.action">
										<option value="none">{{ T('Please choose') }}</option>
										<option value="copy" v-if="canCopy">
											{{ T('Copy') }}</option>
										<option value="delete">{{ T('Delete') }}</option>
										<option value="updates" v-if="['iosDevices', 'devices'].indexOf(activePage) != -1">
											Updates</option>
									</select>
								</label>
							</div>
							<div class="col-xs col-md padding-xs">
								<a :disabled="bulk.action == 'none' || bulk.loading || null"
									class="btn btn-menu btn-loader twist-in" v-on:click="doBulkAction()"
									:class="{ 'btn-loading': bulk.loading }">
									<span class="animate">
										<i v-if="bulk.loading != undefined" class="progress-circular no-progress"
											style="font-size:1.5em;top:50%;position:absolute;left:50%;transform:translate(-50%,-50%);">
											<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
												viewBox="0 0 50 50" style="stroke-width: 4px;">
												<circle cx="25" cy="25" r="20"
													style="stroke: rgba(0, 0, 0, 0.1); fill: none;"></circle>
												<g transform="translate(25,25) rotate(-90)">
													<circle stroke-dasharray="110" stroke-dashoffset="0" cx="0" cy="0"
														r="20" style="fill: none; stroke-linecap: round;"
														transform="rotate(14.0181)">
														<animate attributeName="stroke-dashoffset" values="360;140"
															dur="2.2s" keyTimes="0;1" calcMode="spline" fill="freeze"
															keySplines="0.41,0.314,0.8,0.54" repeatCount="indefinite"
															begin="0"></animate>
														<animate attributeName="stroke" fill="freeze" dur="8s" begin="0"
															repeatCount="indefinite"></animate>
													</circle>
												</g>
											</svg>
										</i>
									</span>
									<span>Ok</span>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-24 padding-xs-b" v-if="getInfoBoxContent()">
					<div class="box-border bg-white padding-xs" style="max-width:960px;width: fit-content;" v-html="getInfoBoxContent()"></div>
				</div>
			</div>

			<div id="itemlist" class="row itemlist" :class="{
				'show-details': showInfo,
				'view-grid': viewMode == 'grid'
			}" v-if="!mapView"
				:style="'padding-top:' + itemlistTopPadding + 'px;padding-bottom:' + itemlistBottomPadding + 'px;'">

				<template v-if="error != undefined">
					<div class="text-center padding-xs-y-3 col-xs text-size-3">
						<p>Error fetching data, please refresh this page.</p>
					</div>
				</template>

				<template v-else>
					<template v-if="activeItemsCount != undefined && activeItemsCount > 0">

						<itemlistItemComponent 
							v-for="i in renderedItemsCount"
							:key="'item/' + (i + virtualScrolling.fromIndex - 1) + '/' + (activeItems?.items?.[i + virtualScrolling.fromIndex - 1] ? activeItems?.items?.[i + virtualScrolling.fromIndex - 1][$getObjectIdPropertyForObjectType(objectType)] : 'unknown')"
							:item="activeItems.items ? activeItems.items[i + virtualScrolling.fromIndex - 1] : undefined"
							:index="i + virtualScrolling.fromIndex - 1" 
							:showInfo="showInfo" #
							:class="{ 'col-xs-24 col-md-12 col-lg-8 col-print-12 padding-xs': viewMode == 'grid', 'col-xs-24 col-print-24 padding-xs': viewMode == 'list' }" 
							:refreshCounterProp="refreshCounter" 
							:cellWidth="cellWidth" 
							:viewMode="viewMode"
						/>

					</template>
					<template v-else-if="activeItemsCount == undefined && (loading == true || refreshing == true)">
						<div class="text-center padding-xs-y-8 col-xs">
							<div class="text-size-3">
								<loaderComponent class="text-size-2 color-red" />
							</div>
							<span v-if="loaderInfo" style="opacity: 0.8;">{{ T(loaderInfo) }}</span>
						</div>
					</template>

					<template v-else-if="isEmpty">
						<template v-if="introductionComponent">
							<component :is="introductionComponent" />
						</template>
						<div v-else class="text-center padding-xs-y-8 margin-xs-t-8 margin-xs-b-4 col-xs text-size-2">
							<p>{{ getNoItemsText() }}</p>
						</div>
					</template>

				</template>
			</div>
			<template v-if="mapView">
				<div id="itemListMap" style="height: calc(100vh - 381px); position: relative; overflow: hidden;"></div>
			</template>
		</section>
		<div v-if="dropZone.dropZoneActive" id="itemListDropArea">
			<div class="uploadcontent">
				<i class="fal fa-upload"></i> {{T('Upload file')}}
		</div>
	</div>
</div></template>

	