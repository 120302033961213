<script setup lang="ts">
    import loaderComponent from "../components/loader.vue";
    import queries from "@/queries/queries";
    import jsonHelpers from "@/helpers/helpers.json";
    import { useStore } from "@/store/vuex.store";;
    import dialogs from "@/dialogs/dialogs";
    import { T } from "@/classes/i18n";
    import { ActionTypes } from "@/store/vuex.store";
    import router from "@/router/router";
    import { computed, onMounted, provide, ref } from "vue";
    import { useAzureStore } from '@/classes/objectTypes/unifiedSecurity/users/azure'

    const initialized = ref(false)

    const activeAccountId = computed(() => {
        return useStore().state.session.activeAccountId || ""
    })

    const accountInitialized = computed(() => {
        return useStore().state.session.accounts[activeAccountId.value]?.initialized || false
    })
    const settings = computed(() => {
        return useStore().state.session.accounts[activeAccountId.value]?.unifiedSecurity.settings || undefined
    })


    const getObjectLength = jsonHelpers.getObjectLength

    const linkAzureAd = () => {
        dialogs.misc.inputDialog(
            activeAccountId.value,
            T('Link Entra ID'),
            T('Enter the Entra ID primary domain you want to link (e.g. ****.onmicrosoft.com).'),
            async (value: string) => {
                value = typeof value == "string" ? value.trim() : value
                let redirectURL = await useAzureStore().getAzureRedirectURL(activeAccountId.value, value)
                if(redirectURL) {
                    window.location.href = redirectURL
                }
            },
            "text",
            (value:string) => { return value.length >= 1 },
            "****.onmicrosoft.com"
        )
    }

    const getConfirmUnlinkAzureAdDialog = (tenantId: string) => {
        dialogs.misc.confirmDialog(
            activeAccountId.value,
            T('Are you sure?'),
            T('Do you really want to unlink your Entra ID account?'),
            async () => {
                await queries.unifiedSecurity.unlinkAzureTenant(activeAccountId.value,tenantId)
                await refreshAzureInfo()
            },
            undefined,
            T('Unlink'),
            "fal fa-link-slash"
        )
    }

    const getConfirmAzureAdDialog = () => {
        dialogs.misc.confirmDialog(
            activeAccountId.value,
            T('You successfully added your Entra ID account'),
            (T('You successfully added your Entra ID account') + '.<br> ' + T('You may now close this window') + '.'),
            async () => {
                await useStore().dispatch(ActionTypes.updateAccount, activeAccountId.value)
                router.navigate('show-tenant-' + activeAccountId.value + '.sms-general-settings')
            },
            undefined,
            undefined,"fal fa-check", false
        )
    }

    const getFailedAzureAdDialog = () => {
        dialogs.misc.confirmDialog(
            activeAccountId.value,
            T('An error occured while trying to add your Entra ID account'),
            (T('An error occured while trying to add your Entra ID account') + '.<br> ' + T('Please try again later') + '.'),
            async () => {
                await useStore().dispatch(ActionTypes.updateAccount, activeAccountId.value)
                router.navigate('show-tenant-' + activeAccountId.value + '.sms-general-settings')
            },
            undefined,
            undefined, "fal fa-check", false
        )
    }

    const refreshAzureInfo = async () => {
        const azureInfo = await queries.unifiedSecurity.getAzureInfo(activeAccountId.value)
        settings.value.azureAd.enabled = azureInfo.enabled
        if (azureInfo.enabled === true) {
            const azureTenants = await queries.unifiedSecurity.getAzureTenants(activeAccountId.value)
            if (azureTenants.accounts?.length >= 1) {
                settings.value.azureAd.accounts = azureTenants.accounts
            }
        }
        else {
            settings.value.azureAd.accounts = []
        }
    }

    defineExpose({
        getConfirmAzureAdDialog, getFailedAzureAdDialog
    })



    onMounted(async () => {
        await refreshAzureInfo()
        initialized.value = true
    })

</script>
<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="row">
            <div class="col-xs-24 col-lg-24 col-xl-17 col-print-24">
                <div class="box-shadow">
                    <div class="box-content-2">
                        <h4>
                            <i class="fab fa-fw fa-microsoft"></i> Microsoft Entra ID
                        </h4>
                        <p>
                            {{ T('Entra ID is a cloud-based identity and access management service that provides organizations with unified identity management and enhanced security. With Entra ID, users can log in to multiple applications and services with a single sign-on, while advanced security features such as multi-factor authentication and threat intelligence enhance the protection of corporate data.') }}
                        </p>
                        <template v-if="initialized && accountInitialized">
                            <div class="row padding-xs-y ">
                                <div class="col-lg-6">
                                    <label for="azureAd" class="control-label">
                                        Entra ID
                                    </label>
                                </div>
                                <div class="col-lg-8">
                                    <input
                                        id="azureAd"
                                        type="text" 
                                        class="form-control"
                                        :value="settings.azureAd.enabled == true ? T('Linked') : T('Not set')"
                                        disabled
                                    />
                                </div>
                                <div class="col-lg-8">
                                    <a class="btn btn-primary col-xs-24" v-on:click="linkAzureAd()">
                                        <i class="fal fa-fw fa-link"></i>&nbsp;&nbsp{{ T('Link') }}
                                    </a>
                                </div>
                            </div>
                            <template v-if="settings.azureAd.accounts.length">
                                <div class="row padding-xs-y">
                                    <div class="col-lg-6">
                                        <label for="tenant-info-androidZeroTouch" class="control-label">
                                            {{T('Entra ID Accounts')}}
                                        </label>
                                    </div>
                                    <div class="col-lg-16">
                                        <template v-for="tenant in settings.azureAd.accounts">
                                            <div class="box-shadow box">
                                                <div class="row padding-xs">
                                                    <div class="col-lg-8 padding-xs-y" style="overflow-wrap: break-word;">
                                                        {{ tenant.domainName }}
                                                    </div>
                                                    <div class="col-lg-8 padding-xs-y" style="overflow-wrap: break-word;">
                                                        {{ tenant.id }}
                                                    </div>
                                                    <div class="col-lg-8">
                                                        <a class="btn btn-primary col-xs-24" v-on:click="getConfirmUnlinkAzureAdDialog(tenant.id)">
                                                            <i class="fal fa-fw fa-unlink"></i>&nbsp;&nbsp;{{T('Unlink Account')}}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <loaderComponent class="color-primary text-size-2 margin-xs-b-2"/>
                            <hr>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>