import { useGlobalMixin } from "@/mixins/mixins.global";
import moment from "moment";
import { T } from "@/classes/i18n";
import { ActionTypes, MutationTypes } from "@/store/vuex.store";
import queries from "@/queries/queries";
import loaderComponent from "../components/loader.vue";
import router from "@/router/router";
import { useStore } from '@/store/vuex.store'
import dialogs from "@/dialogs/dialogs";
import tenantHelpers from "@/helpers/helpers.tenants";
import jsonHelpers from "@/helpers/helpers.json";
import inputVueSelectVue from "../inputtypes/input-vue-select.vue";
import inputNumberComponent from "../inputtypes/input-number.vue";
import objectStores from "@/classes/init";
import mixinHelpers from "@/helpers/helpers.mixins";
import type { ButtonOptions } from "@/templates/components/button/button";
import Button from "@/templates/components/button/button";
import buttonComponent from "../components/button/button.vue";
import requestHandler from "@/queries/requests";
import config from "@/classes/config";
import products from "@/classes/objectTypes";
import deviceHelpers from "@/helpers/helpers.devices";
import arrayHelpers from "@/helpers/helpers.arrays";
import useRouterStore from "@/router/routerStore";
import licenseHelpers from "@/helpers/helpers.license";

const licenseDetailsComponent = {
    name: 'sms-page-licensedetails',
    mixins: [useGlobalMixin()],
    data() {
        return {
            "initialized": false,
            "activeTab": "general",
            "loader": {
                "loading": true,
                "saving": false,
                "loaderText": "Loading..."
            },
            "devicesValue": [],
            "enrollmentLimit": -1,
            "enrollmentSaveButton": <ButtonOptions | undefined>undefined,
            "enrollmentDeleteButton": <ButtonOptions | undefined>undefined

        }
    },
    computed: {
        "account": function (this: any) {
            return useStore().state.session.accounts[this.activeAccountId] || undefined
        },
        "licenses": function (this: any) {
            if (config.canUseNewObjectType("licenses")) {
                return products.unifiedSecurity.licenses.useStore?.()?.getObjectStoreObjects(this.activeAccountId) || []
            }
            else {
                return this.account.unifiedSecurity?.licenses?.items || []
            }
        },
        "devices": function (this: any) {
            return products.mobileSecurity.iosDevices.sortIosDevicesByAliasId((config.canUseNewObjectType('iosDevices') ? products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObjects(this.activeAccountId) : this.account.mobileSecurity?.devices?.items) || [])
        },
        "enterpriseDevices": function (this: any) {
            return products.mobileSecurity.androidDevices.sortAndroidDevicesByAliasId((config.canUseNewObjectType('androidDevices') ? products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObjects(this.activeAccountId)  : this.account.mobileSecurity?.enterpriseDevices?.items) || [])
        },
        "vpns": function (this: any) {
            return arrayHelpers.sortByObjProperty((config.canUseNewObjectType('vpns') ? products.mobileSecurity.vpns.useStore?.().getObjectStoreObjects(this.activeAccountId) : this.account.mobileSecurity?.vpns?.items) || [], "vpnname")
        },
        "objectId": function (this: any) {
            return useRouterStore().getObjectId
        },
        "license": function (this: any) {
            let thisComponent: any = this
            let result: object[] = this.licenses.filter(function (license: any) {
                return license.id == thisComponent.objectId
            }) || []
            return result.length ? result[0] : undefined
        },
        "assignment": function (this: any) {
            let thisComponent: any = this
            return {
                "thisDevices": this.devices.filter(function (device: any) {
                    return device.licenseUUID == thisComponent.license.uuid
                }),
                "thisEnterpriseDevices": this.enterpriseDevices.filter(function (device: any) {
                    return device.licenseUUID == thisComponent.license.uuid
                }),
                "thisVpns": this.vpns.filter(function (vpn: any) {
                    return vpn.licenseUUID == thisComponent.license.uuid
                })
            }
        },
        "isReseller": function (this: any) {
            return useStore().state.session.isReseller || false
        },
        "licenseLimit": function(this: any) {
            return mixinHelpers.getLicenseLimit(this.license)
        },
        "isMdmLicense": function(this: any) {
            return ['MDM', 'Mobile Security'].indexOf(this.license?.type) > -1
        },
        "isUserSupport": function(this: any) {
            return useStore()?.state?.session?.userInfo?.roles?.indexOf("__SUPPORT__") != -1 || undefined
        },
    },
    props: {},
    methods: {
        moment: moment,
        setActiveTab: function (this: any, tabName: string) {
            this.activeTab = tabName
        },
        init: async function (this: any) {
            let thisComponent: any = this
            this.loader.loading = true
            this.loader.loaderText = "Getting license..."
            await this.refresh()
            this.initialized = true
        },
        refresh: async function (this: any) {
            let thisComponent = this
            this.loader.loaderText = "Getting license..."
            let hasObject : any = new Error()
            if (config.canUseNewObjectType("licenses")) {
                hasObject = await products.unifiedSecurity.licenses.queries.getObjectFromApi(this.activeAccountId,this.objectId)
            }
            else {
                hasObject = await useStore().dispatch(ActionTypes.getObjectInfo, {
                    "accountId": this.activeAccountId,
                    "objectType": 'licenses',
                    "objectId": this.objectId,
                    "productType": "unifiedSecurity"
                })
            }
            

            if (!hasObject || hasObject instanceof Error) {
                router.navigate('404-' + this.objectId)
            }

            if (['Mobile Security', 'MDM'].indexOf(this.license.type) != -1) {
                this.enrollmentLimit = mixinHelpers.getLicenseLimit(this.license)
                this.loader.loaderText = "Getting devices..."
                let requesObjectTypes: string[] = []


                if (config.canUseNewObjectType('iosDevices')) {
                    await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(this.activeAccountId, undefined, [
                        { "property": "props[]", "value": "deviceId" },
                        { "property": "props[]", "value": "deviceType" },
                        { "property": "props[]", "value": "signedIn" },
                        { "property": "props[]", "value": "profile" },
                        { "property": "props[]", "value": "tags" },
                        { "property": "props[]", "value": "ownership" },
                        { "property": "props[]", "value": "lastContact" },
                        { "property": "props[]", "value": "configured" },
                        { "property": "props[]", "value": "props" },
                        { "property": "props[]", "value": "licenseUUID" },
                        { "property": "props[]", "value": "managedAppleId" },
                        { "property": "props[]", "value": "inventory" },
                        { "property": "props[]", "value": "alias" },
                        { "property": "props[]", "value": "info" },
                        { "property": "props[]", "value": "username" },
                        { "property":"props[]", "value":"serialNumber" }
                    ])
                }
                else {
                    requesObjectTypes.push('devices?props[]=deviceId&props[]=deviceType&props[]=signedIn&props[]=profile&props[]=tags&props[]=ownership&props[]=lastContact&props[]=managedAppleId&props[]=info&props[]=configured&props[]=props&props[]=licenseUUID&props[]=username&props[]=alias')
                }

                if (tenantHelpers.hasFunctionality(this.activeAccountId, 'enterprise')) {
                    if (config.canUseNewObjectType('androidDevices')) {
                        await products.mobileSecurity.androidDevices.queries.getObjectsFromApi(this.activeAccountId,undefined,[
                            { "property":"props[]", "value":"deviceId" }, 
                            { "property":"props[]", "value":"deviceType" }, 
                            { "property":"props[]", "value":"signedIn" }, 
                            { "property":"props[]", "value":"profile" }, 
                            { "property":"props[]", "value":"tags" }, 
                            { "property":"props[]", "value":"ownership" }, 
                            { "property":"props[]", "value":"lastContact" }, 
                            { "property":"props[]", "value":"managedAppleId" }, 
                            { "property":"props[]", "value":"signedIn" }, 
                            { "property":"props[]", "value":"info" }, 
                            { "property":"props[]", "value":"configured" }, 
                            { "property":"props[]", "value":"props" }, 
                            { "property":"props[]", "value":"licenseUUID" }, 
                            { "property":"props[]", "value":"username" }, 
                            { "property":"props[]", "value":"name" },
                            { "property":"props[]", "value":"alias" }
                        ])
                    }
                    else {
                        requesObjectTypes.push('enterpriseDevices?props[]=appliedPolicyName&props[]=appliedState&props[]=name&props[]=enrollmentTime&props[]=props&props[]=hardwareInfo&props[]=lastStatusReportTime&props[]=managementMode&props[]=name&props[]=ownership&props[]=policyName&props[]=previousDeviceNames&props[]=state&props[]=alias&props[]=deviceId&props[]=username&props[]=consent&props[]=licenseUUID&props[]=signedIn&fields=*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)')
                    }
                }
                if (config.canUseNewObjectType('vpns')) {
                    await products.mobileSecurity.vpns.queries.getObjectsFromApi(this.activeAccountId)
                }
                else {
                    requesObjectTypes.push('vpns')
                }
                await useStore().dispatch(ActionTypes.getObjectInfos, {
                    "accountId": this.activeAccountId,
                    "objectTypes": requesObjectTypes
                })
                this.setDevicesValue()
            }
            this.loader.loading = false
            this.loader.loaderText = "Loading..."
            return true
        },
        setDevicesValue: function (this: any) {
            let thisComponent: any = this
            this.assignment.thisDevices.forEach(function (device: any) {
                let deviceId: string = device.deviceId
                if (thisComponent.devicesValue.indexOf(deviceId) == -1) {
                    thisComponent.devicesValue.push(deviceId)
                }
            })
            this.assignment.thisEnterpriseDevices.forEach(function (device: any) {
                let deviceId: string = device.id
                if (thisComponent.devicesValue.indexOf(deviceId) == -1) {
                    thisComponent.devicesValue.push(deviceId)
                }
            })

            this.assignment.thisVpns.forEach(function (vpn: any) {
                let vpnId: string = vpn.vpnId
                if (thisComponent.devicesValue.indexOf(vpnId) == -1) {
                    thisComponent.devicesValue.push(vpnId)
                }
            })
        },
        getOptions: function (this: any) {

            let thisComponent: any = this
            let options: object[] = []
            this.devices.forEach((device: any) => {
                let deviceId: string = device.deviceId
                let isDisabled: boolean = false
                let disabledReason: string = ""
                let licenseInfo = licenseHelpers.getLicenseByUUID(thisComponent.activeAccountId, device?.licenseUUID || "")
                if (device.deviceId != undefined && !device.configured && device.name == undefined) {
                    isDisabled = true
                    disabledReason = T("Unconfigured")
                }
                else if (
                    ["MDM", "Mobile Security"].indexOf(licenseInfo?.type || '') != -1 && (licenseInfo?.expirationDate || 0) > (new Date()).toISOString().substring(0, 10) &&
                    device.licenseUUID != thisComponent.license.uuid
                ) {
                    if (licenseInfo?.type == 'MDM' && thisComponent.license.type == "Mobile Security") {

                    }
                    else {
                        isDisabled = true
                        disabledReason = T("Assigned to another license")
                    }
                }
                else if (device.signedIn === false) {
                    isDisabled = true
                    disabledReason = T("Signed out")
                }

                if (disabledReason.length > 0) {
                    disabledReason = " (" + disabledReason + ")"
                }

                let thisOption: selectOption = {
                    "id": deviceId,
                    "icon": "fab fa-apple",
                    "text": deviceHelpers.getAliasedShortDeviceId(device.deviceId, device.alias) + disabledReason,
                    "locked": true,
                    "objectTypeName": "devices",
                    "disabled": thisComponent.license.uuid != device.licenseUUID ? isDisabled : false
                }

                options.push(thisOption)
            })


            this.enterpriseDevices.forEach((device: any) => {
                let deviceId: string = device.id
                let isDisabled: boolean = false
                let disabledReason: string = ""
                let licenseInfo = licenseHelpers.getLicenseByUUID(thisComponent.activeAccountId, device?.licenseUUID || "")
                if (["MDM", "Mobile Security"].indexOf(licenseInfo?.type || "") != -1
                    && (licenseInfo?.expirationDate || 0) > (new Date()).toISOString().substring(0, 10)
                    && device.licenseUUID != thisComponent.license.uuid
                ) {
                    if (licenseInfo?.type == 'MDM' && thisComponent.license.type == "Mobile Security") {

                    }
                    else {
                        isDisabled = true
                        disabledReason = T("Assigned to another license")
                    }
                }

                if (disabledReason.length > 0) {
                    disabledReason = " (" + disabledReason + ")"
                }

                let thisOption: selectOption = {
                    "id": deviceId,
                    "icon": "fab fa-android",
                    "text": deviceHelpers.getAliasedShortDeviceId(device.id, device.alias) + disabledReason,
                    "locked": true,
                    "objectTypeName": "enterpriseDevices",
                    "disabled": thisComponent.license.uuid != device.licenseUUID ? isDisabled : false
                }
                options.push(thisOption)
            })


            this.vpns.forEach((vpn: any) => {
                let isDisabled: boolean = false
                let disabledReason: string = ""
                let licenseInfo = licenseHelpers.getLicenseByUUID(thisComponent.activeAccountId, vpn?.licenseUUID || "")
                if (
                    ["MDM", "Mobile Security"].indexOf(licenseInfo?.type || '') != -1 && (licenseInfo?.expirationDate || 0) > (new Date()).toISOString().substring(0, 10) &&
                    vpn.licenseUUID != thisComponent.license.uuid
                ) {
                    isDisabled = true
                    disabledReason = T("Assigned to another license")
                }
                else if (thisComponent.license.type == "MDM") {
                    isDisabled = true
                    disabledReason = T("Incompatible with license")
                }

                if (disabledReason.length > 0) {
                    disabledReason = " (" + disabledReason + ")"
                }

                let thisOption: selectOption = {
                    "id": vpn.vpnId,
                    "icon": "fal fa-fw fa-laptop",
                    "text": vpn.vpnname + disabledReason,
                    "locked": true,
                    "objectTypeName": "vpns",
                    "disabled": thisComponent.license.uuid != vpn.licenseUUID ? isDisabled : false
                }

                options.push(thisOption)
            })
            return arrayHelpers.sortByObjProperty(options, "text")
        },
        openRSP: function (this:any) {
            let win: any
            if (document.location.origin.indexOf('portal-dev') != -1) {
                win = window.open('https://devmy.securepoint.de/licenses/?search='+this.objectId, '_blank')
            }
            else {
                win = window.open('https://my.securepoint.de/licenses/?search='+this.objectId, '_blank')
            }
            win.focus()
        },
        onDeviceAdd: function (option: selectOption) {
            let thisComponent: any = this
            dialogs.misc.confirmDialog(
                thisComponent.activeAccountId,
                "Confirm assignment",
                T("Do you want to assign that device to this license?"),
                async () => {
                    try {
                        
                        switch (option.objectTypeName) {
                            case 'androidDevices':
                                if(config.canUseNewObjectType("androidDevices")) {
                                    await products.mobileSecurity.androidDevices.queries.updateObjectPropertiesFromApi(thisComponent.activeTenantDomain, option.id as string, { licenseUUID: thisComponent.license.uuid }, 'licenseUUID',undefined, undefined, "/properties?updateMask[]=licenseUUID")
                                }
                                else {
                                    await queries.mobileSecurity.setDeviceProperty(thisComponent.activeTenantDomain, option.id as string, "licenseUUID", thisComponent.license.uuid)
                                }
                            break;
                            case 'devices':
                            case 'iosDevices':
                                await queries.mobileSecurity.setDeviceProperty(thisComponent.activeTenantDomain, option.id as string, "licenseUUID", thisComponent.license.uuid)
                            break;
                            case 'enterpriseDevices':
                                await queries.mobileSecurity.setEnterpriseDeviceProperty(thisComponent.activeTenantDomain, option.id as string, ["licenseUUID"], { "licenseUUID": thisComponent.license.uuid })
                                break;
                            case 'vpns':
                                if (config.canUseNewObjectType("vpns")) {
                                    await products.mobileSecurity.vpns.queries.updateObjectPropertiesFromApi(thisComponent.activeTenantDomain, option.id as string, thisComponent.license.uuid, 'licenseUUID',undefined, undefined, "/properties/licenseUUID")
                                }
                                else {
                                    await queries.mobileSecurity.setVPNProperty(thisComponent.activeTenantDomain, option.id as string, "licenseUUID", thisComponent.license.uuid)
                                }
                                
                            break;
                        }

                        //thisComponent.devicesValue.push(option.id)

                    }
                    catch (e: any) {
                        console.error(e)
                    }
                    thisComponent.refresh()
                },undefined,undefined,undefined,true,undefined,async () => {
                    thisComponent.devicesValue = thisComponent.devicesValue.filter((deviceId:string) => {
                        return deviceId !== option.id
                    })
                    
                    thisComponent.$refs['licenseAssignmentSelect'][0]?.updateModelValue()
                    return true
                }
            )
        },
        saveEnrollmentLimit: async function(this: any) {
            let thisComponent = this
            if (thisComponent.enrollmentLimit !== '' && thisComponent.enrollmentLimit >= thisComponent.license.assignedUser && thisComponent.enrollmentLimit < 5001 && thisComponent.isMdmLicense && thisComponent.license.isAllowedToSetLimit) {
                thisComponent.enrollmentSaveButton.loading = true
                thisComponent.enrollmentSaveButton.disabled = true
                thisComponent.enrollmentDeleteButton.disabled = true
                let result : any
                // Set EnrollmentLimit
                if (config.canUseNewObjectType("licenses")) {
                    result = await products.unifiedSecurity.licenses.queries.updateEnrollmentlimitFromLicense?.(thisComponent.activeAccountId, thisComponent.license.id, thisComponent.enrollmentLimit)
                }
                else {
                    result = await objectStores["licenses"].updateEnrollmentlimitFromLicense(thisComponent.activeAccountId, thisComponent.license.id, thisComponent.enrollmentLimit)
                    await objectStores["licenses"].getObjectFromApi(thisComponent.activeAccountId, thisComponent.license.id)
                }
                // Getting new EnrollmentAllowed
                try {
                    if(useStore()?.state.session.accounts[thisComponent.activeAccountId]?.mobileSecurity?.settings?.enrollmentAllowed != undefined) {
                        let enrollmentAllowed = useStore().state.session.accounts[thisComponent.activeAccountId].mobileSecurity.settings.enrollmentAllowed
                        enrollmentAllowed = await requestHandler.request("GET", "/sms-mgt-api/api/2.0/tenants/" + thisComponent.activeTenantDomain +  "?props[]=enrollmentAllowed")
                        enrollmentAllowed = enrollmentAllowed.enrollmentAllowed
                        useStore().state.session.accounts[thisComponent.activeAccountId].mobileSecurity.settings.enrollmentAllowed = enrollmentAllowed;
                    }
                } catch (e) {
                    console.error(e)
                }
                result = result
                thisComponent.enrollmentLimit = thisComponent.license.enrollmentLimit
                thisComponent.enrollmentSaveButton.loading = false
                thisComponent.enrollmentSaveButton.disabled = false
                thisComponent.enrollmentDeleteButton.disabled = false
            }
        },
        deleteEntrollmentLimit: async function(this: any) {
            let thisComponent = this
            if (thisComponent.isMdmLicense && thisComponent.license.isAllowedToSetLimit) dialogs.misc.confirmDialog(thisComponent.activeAccountId, 
                T("Delete Device Limit"), 
                T("If you delete the device limit the tenant can enroll the maximum amount of devices from the license. The maximum amount is %s").replace("%s", thisComponent.license.user), 
                async () => {
                try {
                    thisComponent.enrollmentSaveButton.disabled = true
                    thisComponent.enrollmentDeleteButton.loading = true
                    thisComponent.enrollmentDeleteButton.disabled = true
                    
                    let result : any = undefined
                    // Set EnrollmentLimit
                    if (config.canUseNewObjectType("licenses")) {
                        result = await products.unifiedSecurity.licenses.queries.deleteEntrollmentlimitFromLicense?.(thisComponent.activeAccountId, thisComponent.license.id)
                        
                    }
                    else {
                        result = await objectStores["licenses"].deleteEntrollmentlimitFromLicense(thisComponent.activeAccountId, thisComponent.license.id)
                        await objectStores["licenses"].getObjectFromApi(thisComponent.activeAccountId, thisComponent.license.id)
                    }

                    thisComponent.enrollmentLimit = thisComponent.license.user
                    result = result 

                    // Getting new EnrollmentAllowed
                    try {
                        if(useStore()?.state.session.accounts[thisComponent.activeAccountId]?.mobileSecurity?.settings?.enrollmentAllowed != undefined) {
                            let enrollmentAllowed = useStore().state.session.accounts[thisComponent.activeAccountId].mobileSecurity.settings.enrollmentAllowed
                            enrollmentAllowed = await requestHandler.request("GET", "/sms-mgt-api/api/2.0/tenants/" + thisComponent.activeTenantDomain +  "?props[]=enrollmentAllowed")
                            enrollmentAllowed = enrollmentAllowed.enrollmentAllowed
                            useStore().state.session.accounts[thisComponent.activeAccountId].mobileSecurity.settings.enrollmentAllowed = enrollmentAllowed;
                        }
                    } catch (e) {
                        console.error(e)
                    }

                } catch(e) {
                    console.error(e)
                } finally {
                    thisComponent.enrollmentDeleteButton.loading = false
                    thisComponent.enrollmentDeleteButton.disabled = false
                    thisComponent.enrollmentSaveButton.disabled = false
                }
            });
        },
        beforeFilterInput: function(this: any) {
            if(this.enrollmentDeleteButton != undefined) {
                this.enrollmentDeleteButton.disabled = true
            }
            if(this.enrollmentSaveButton != undefined) {
                this.enrollmentSaveButton.disabled = true
            }
        },
        afterFilterInput: function(this: any) {
            if(this.enrollmentDeleteButton != undefined) {
                this.enrollmentDeleteButton.disabled = false
            }
            if(this.enrollmentSaveButton != undefined) {
                this.enrollmentSaveButton.disabled = false
            }
        }
    },
    watch: {
        "license": function (this: any) {
            if (!this.initialized) {
                this.init()
            }
        }
    },
    created: async function (this: any) {
        if (!this.initialized) {
            this.init()
        }
    },
    mounted: function (this: any) {
        if (this.isMdmLicense && (this.isReseller || this.isUserSupport) && this.license.isAllowedToSetLimit) {
            let thisComponent = this    
            thisComponent.enrollmentDeleteButton = new Button({
                "title": T("Delete"),
                "text": T("Delete"),
                "onClick": thisComponent.deleteEntrollmentLimit,
                "icon": "fal fa-trash",
                "loading": false,
                "disabled": false
            })
            thisComponent.enrollmentSaveButton = new Button({
                "title": T("Save"),
                "text": T("Save"),
                "onClick": thisComponent.saveEnrollmentLimit,
                "icon": "fal fa-save",
                "loading": false,
                "disabled": false
            })
        }
    },                                                            
    beforeCreate(this: any) {
    },
    "components": {
        "loader": loaderComponent,
        "input-vue-select": inputVueSelectVue,
        "input-number": inputNumberComponent,
        "buttonComponent": buttonComponent
    },
    template: `
    <div>
        <section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-if="initialized && license != undefined">
            <div class="row itemlist show-details view-grid">
                <div class="col-xs-24 col-print-24 col-lg-12  padding-xs item" style="z-index:999999">
                    <div class="box-shadow">
                        <div class="box-body">
                            <div class="box-icon-left">
                                <div class="icon-circle color-white bg-gray hover-bg-red" :title="T(license.name)">
                                    <i class="fal fa-key"></i>
                                </div>
                            </div>
                            <div class="box-content-2">
                                <div class="box-title">
                                    <strong class="margin-xs-r">{{license.name}}</strong>
                                    <template v-if="license.laasEnabled">
                                        <span :title="T('USR') + ' ' + T('booked')" class="label bg-green margin-xs-r">{{ T('USR') + ' ' + T('booked') }}</span>
                                    </template>
                                    <template v-if="license.uscMessagingEnabled">
                                        <span :title="T('USC') + ' ' + T('booked')" class="label bg-green margin-xs-r">{{ T('USC') + ' ' + T('booked') }}</span>
                                    </template>
                                    <template v-if="license.state != 'valid'">
                                        <span :title="T(license.state)" class="label bg-yellow margin-xs-r">{{ T(license.state) }}</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="box-body details">
                            <div class="text-smaller">
                                <div class="data-row">
                                    <span class="data-key text-semibold">
                                        <i class="fal fa-fw fa-key"></i> {{T('Type')}}:
                                    </span>
                                    <div class="data-value">
                                        {{ license.type }}
                                    </div>
                                </div>
                                <div class="data-row">
                                    <span class="data-key text-semibold">
                                        <i class="fal fa-fw fa-user"></i> {{T('Users')}}:
                                    </span>
                                    <div class="data-value">
                                        <template v-if="['Mobile Security','MDM'].indexOf(license.type) != -1">
                                            {{ license.assignedUser }}/{{ licenseLimit }}
                                        </template>
                                        <template v-else>
                                            {{ license.user || "" }}
                                        </template>
                                    </div>
                                </div>
                                <div class="data-row">
                                    <span class="data-key text-semibold">
                                        <i class="fal fa-fw fa-hashtag"></i> {{T('License') + ' UUID'}}:
                                    </span>
                                    <div class="data-value">
                                        {{ license.uuid ? license.uuid : '' }}
                                    </div>
                                </div>
                                <div class="data-row">
                                    <span class="data-key text-semibold">
                                        <i class="fal fa-fw fa-clock"></i> {{T('Expires')}}:
                                    </span>
                                    <div class="data-value">
                                        {{ moment(license.expirationDate).format("DD.MM.YYYY") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-24 col-print-24 col-lg-12 padding-xs">
                </div>
            </div>
            <div class="row">
                <div class="col-xs-24 padding-xs">
                    <div class="box-shadow">
                        <div class="tabs">
                            <nav>
                                <ul class="nav nav-tabs hide-on-print">
                                    <li>
                                        <a :class="{'active':activeTab == 'general'}" v-on:click="setActiveTab('general')">
                                            <i class="fal fa-fw fa-info-circle"></i> ${T('General')}
                                        </a>
                                    </li>
                                    <li v-if="['Mobile Security','MDM'].indexOf(license.type) != -1">
                                        <a :class="{'active':activeTab == 'assignment'}" v-on:click="setActiveTab('assignment')">
                                            <i class="fal fa-fw fa-info-circle"></i> ${T('Assignment')}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div class="tabcontent">
                                <div :class="{'hidden': activeTab != 'general'}">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24 col-xl-16">
                                                <h4>${T('Summary')}</h4>
                                                <table class="noborder border-h ">
                                                    <tbody>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-key"></i> ${T('License type')}</td>
                                                            <td>
                                                                {{ license.type }}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-mobile-alt"></i> ${T('Assigned users')}</td>
                                                            <td>
                                                                <template v-if="['Mobile Security','MDM'].indexOf(license.type) != -1">
                                                                    {{ license.assignedUser }}
                                                                </template>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-mobile-alt"></i> ${T('Max. users')}</td>
                                                            <td>
                                                                {{ licenseLimit }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-question"></i> ${T('Valid')}</td>
                                                            <td>
                                                                <template v-if="license.state == 'valid'">
                                                                    {{ T('Yes') }}
                                                                </template>
                                                                <template v-else-if="license.state == 'expired'">
                                                                    <a v-on:click="openRSP()">{{ T('Extend') }}</a>
                                                                </template>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-calendar"></i> ${T('Expires')}</td>
                                                            <td>
                                                                {{ moment(license.expirationDate).format("DD.MM.YYYY") }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-calendar"></i> ${T('Stale')}</td>
                                                            <td>
                                                                {{ moment(license.deadDate).format("DD.MM.YYYY") }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-address-card"></i> ${T('Licensee')}</td>
                                                            <td>{{ license.accountName }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-hashtag"></i> ${T('Account ID')}</td>
                                                            <td>{{ license.accountId }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table v-if="isMdmLicense && license.isAllowedToSetLimit">
                                                    <tbody>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-mobile fa-fw"></i> ${T('Allowed users')}</td>
                                                            <td>
                                                                <div class="row">
                                                                    <div class="col-xs-24 col-lg-10 col-xl-12">
                                                                        <input-number 
                                                                            v-if="isReseller || isUserSupport"
                                                                            v-model="enrollmentLimit"
                                                                            :max="license.user"
                                                                            :min="license.assignedUser || 0"
                                                                            :debounce="1000"
                                                                            :beforeFilterInput="beforeFilterInput"
                                                                            :afterFilterInput="afterFilterInput"
                                                                        />
                                                                        <input v-else :disabled="true" type="number" :value="enrollmentLimit" style="width:50%">
                                                                    </div>
                                                                    <div class="col-xs-24 col-lg-14 col-xl-12">
                                                                        <buttonComponent v-if="enrollmentSaveButton" :buttonOptions="enrollmentSaveButton" />
                                                                        <buttonComponent v-if="enrollmentDeleteButton" :buttonOptions="enrollmentDeleteButton" />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="{'hidden': activeTab != 'assignment'}">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>{{ T('Assignment') }}</h4>
                                                <div class="row flexrow">
                                                    <div class="col-xs-24">
                                                        <input-vue-select
                                                            :selectOptions="getOptions()"
                                                            :dontEscapeMarkup="true"
                                                            v-model="devicesValue"
                                                            :multiple="true"
                                                            :onSelecting="onDeviceAdd"
                                                            id="licenseSelection"
                                                            :noTranslations = "true"
                                                            :disabled="licenseLimit <= license.assignedUser"
                                                            ref="licenseAssignmentSelect"
                                                        >
                                                        </input-vue-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <template v-else>
            <p class="text-center padding-xs-t-4 text-size-4 padding-xs-b-0 text-center">
                <loader class="color-red"></loader>
            </p>
            <p class="text-center">{{ T(loader.loaderText) }}</p>
        </template>
    </div>
    `
}
export default licenseDetailsComponent
