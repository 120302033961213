import { useGlobalMixin } from "@/mixins/mixins.global";
import { ActionTypes } from "@/store/vuex.store";
import { MutationTypes } from "@/store/vuex.store";
import { useStore } from '@/store/vuex.store';
import modalComponent from "./modal";

const modalsComponent = {
    "mixins":[useGlobalMixin()],
    "name":"modals",
    "data":function() {
        return {}
    },
    "computed":{
        "modals":function(this:any) {
            return (useStore().state.modals || []).concat(this.activeAccount?.modals || []) || []
        }
    },
    "template": `
        <div id="modalArea">
            <transition name="modal"
                :duration="{ enter:700, leave:700 }"
                enterActiveClass="modal-entering"
                enterToClass="modal-enter-to"
                leaveActiveClass="modal-leaving"
                leaveToClass="modal-leave-to"
            >
                <modal ref="modal" v-if="modals[0]" :modal="modals[0]"/>
            </transition>
        </div>
    `,
    "methods": {
        "addModal":function(this:any,options:any={}) {
            options.accountId = options.accountid || this.activeAccountId
            useStore().dispatch(ActionTypes.addModal,options)
        },
        "removeModal":function(this:any,i?:number,accountId?:string) {
            useStore().commit(MutationTypes.removeModal,{
                "accountId":accountId || this.activeAccountId,
                "index":i || 0
            })
        }
    },
    "components":{
        "modal": modalComponent
    }
}

export default modalsComponent