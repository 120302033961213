<script setup lang="ts">
import i18n, { T } from '@/classes/i18n';
import getterHelpers from '@/helpers/helpers.getters';
import useRouterStore from '@/router/routerStore';
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import itemlistItem from '@/templates/components/itemlist-item.vue';
import loaderComponent from "../components/loader.vue";
import googleMap from '../components/map';
import products from '@/classes/objectTypes';
import Tabs from '../components/tabs.vue';
import moment from 'moment';
import inputDate from '../inputtypes/input-date.vue';
import jsonHelpers from '@/helpers/helpers.json';
import dialogs from '@/dialogs/dialogs';
import encodingHelpers from '@/helpers/helpers.encoding';
import mixinHelpers from '@/helpers/helpers.mixins';
import type { WindowsProfile } from '@/classes/objectTypes/mobileSecurity/windows/windowsProfiles';
import type { BitlockerStatus, Connection, VPNClientActionState, VPNSystemInfoMessage, VPNWebsocketContexts, WindowsVpn, WindowsVpnJob, WindowsVpnProfileMessage } from '@/classes/objectTypes/mobileSecurity/windows/windowsVpns';
import buttonComponent from "../components/button/button.vue";
import tableNext, { type TableEntryInfo, type TableEntryStatus } from '../components/tableNext.vue';
import Button, { type ButtonOptions } from "@/templates/components/button/button";
import type { VPNConnectionGetMessage, VPNStatsResponse } from '@/classes/objectTypes/mobileSecurity/windows/windowsVpns'
import stringHelpers from '@/helpers/helpers.strings';
import numberHelpers from '@/helpers/helpers.numbers';
import Tooltip from '../components/tooltip.vue';
import ClipboardHandler from '@/classes/clipboard';
import frontendNotifications from '@/classes/notifications';
import { MutationTypes } from '@/store/vuex.store';
import { useStore } from "@/store/vuex.store"
import { useVue } from '@/app';
import devLog from '@/classes/log';
import Label from "@/templates/components/label/label"
import LabelComponent from '../components/label/label.vue';
import Icon from '@/templates/components/icon/icon';
import tenantHelpers from '@/helpers/helpers.tenants';
import router from '@/router/router';
import { useFeatureStore } from '@/classes/featureStore';
// Ref Values

const initialized = ref(false)
const inventoryLoaded = ref(false)
const savingInventory = ref(false)
const vpnStateMessage = ref(<VPNClientActionState | undefined> undefined)
const tabs = ref(<any>undefined)

const loader = ref({
    "loading": true,
    "saving": false,
    "loaderText": "Loading..."
})
const connectionLoading = reactive({
    connectionId: "",
    timeoutId: 0
})

const commandTimeoutIds = reactive({
    systemInfo: 0,
    connectionGet: 0,
    deviceState: 0
})

const inventoryForm = ref({
    "billNumber": "",
    "billDate": "",
    "hardwareDescription": "",
    "providerName": "",
    "providerTariff": "",
    "contractDate": "",
    "contractDuration": "",
    "handoverDate": "",
    "contractNumber": "",
    "providerCustomerNumber": "",
    "warrantyStartDate": "",
    "warrantyEndDate": "",
    "inventoryExternalLink": ""
})


// Computed Values

const darkmode = computed(() => { return mixinHelpers.getDarkmode() })
const activeAccountId = computed(() => { return getterHelpers.useStore().getters.getActiveAccountId })
const objectId = computed(() => { return useRouterStore().getObjectId || "" })
const context = computed(() => { return useRouterStore().context })
const activePage = computed(() => { return useRouterStore().activePage })
const device = computed(() => { return objectId.value ? products.unifiedSecurityConsole.windowsVpns.useStore?.().getObjectStoreObject(activeAccountId.value, objectId.value) : undefined })
const deviceState = computed(() => { return objectId.value ? products.unifiedSecurityConsole.windowsVpnStates.useStore?.().getObjectStoreObject(activeAccountId.value, objectId.value) : undefined })
const isDeviceOnline = computed(() => { return (deviceState.value?.online || false) == true })
const lang = computed(() => { return i18n.getLanguage() })
const hasUscAdmin = computed(() => { return (useStore().state?.session?.userInfo?.permissionScopes || []).includes("usc:administration") })

const systemInfoMessage = computed(() => {
    return device?.value?.messages?.['vpn-system-info'] as VPNSystemInfoMessage | undefined
});
const connectionMessage = computed(() => {
    return device?.value?.messages?.['vpn-connection-get'] as VPNConnectionGetMessage | undefined
});

const hasConnections = computed(() => {
    let result: boolean = false
    if (initialized.value &&
        connectionMessage.value &&
        Array.isArray(connectionMessage.value.connections) &&
        connectionMessage.value.connections.length > 0 &&
        isMessageTooOld(connectionMessage.value.timestamp, 7) == false
    ) result = true;

    return result
})

// Buttons
const refreshSystemInfoButton = computed(() => {
    let thisTitle = T("Refresh")
    let isDisabled = false

    if (hasUscAdmin.value == false) {
        isDisabled = true
        thisTitle = T("Um dieses Feature benutzen zu können, benötigen Sie Rechte für die USC-Administration")
    }
    else if (isDeviceOnline.value == false) {
        isDisabled = true
        thisTitle = T('Das Gerät muss online sein, um die Daten anzufordern')
    }

    return {
        loading: loadingMessages.systemInfo,
        disabled: isDisabled == true || loadingMessages.systemInfo == true,
        title: thisTitle,
        text: undefined,
        icon: 'fal fa-sync',
        onClick: async () => {
            loadingMessages.systemInfo = true;
            await sendVpnMessage(activeAccountId.value, device.value?.id || '', 'system-info');

            commandTimeoutIds.systemInfo = setTimeout(() => {
                addConnectionFailureNotification("SystemInfo", device.value?.alias || "")
                commandTimeoutIds.systemInfo = 0
                loadingMessages.systemInfo = false
            }, 30000)
        }
    } as ButtonOptions
})

const refreshDeviceStateButton = computed(() => {
    return {
        loading: loadingMessages.deviceState,
        disabled: (loadingMessages.deviceState == true || isDeviceOnline.value == false),
        title: isDeviceOnline.value == false ? T('Das Gerät muss online sein, um die Daten anzufordern') : T('Refresh'),
        text: T('Status abfragen'),
        icon: 'fal fa-sync',
        onClick: async () => {
            loadingMessages.deviceState = true;
            await sendVpnMessage(activeAccountId.value, device.value?.id || '', 'client-action', undefined, { 'action': 'state' });

            commandTimeoutIds.deviceState = setTimeout(() => {
                addConnectionFailureNotification("DeviceState", device.value?.alias || "")
                commandTimeoutIds.deviceState = 0
                loadingMessages.deviceState = false
            }, 30000)
        }
    } as ButtonOptions
})

const refreshConnectionGetButton = computed(() => {
    return {
        loading: loadingMessages.connectionGet,
        disabled: (loadingMessages.connectionGet == true || isDeviceOnline.value == false || connectionLoading.connectionId != ''),
        title: isDeviceOnline.value == false ? T('Das Gerät muss online sein, um die Daten anzufordern') : T('Refresh'),
        text: undefined,
        icon: 'fal fa-sync',
        onClick: async () => {
            loadingMessages.connectionGet = true;
            await sendVpnMessage(activeAccountId.value, device.value?.id || '', 'connection-get');

            commandTimeoutIds.connectionGet = setTimeout(() => {
                addConnectionFailureNotification("ConnectionGet", device.value?.alias || "")
                commandTimeoutIds.connectionGet = 0
                loadingMessages.connectionGet = false
            }, 30000)
        }
    } as ButtonOptions
})

const updateButtonOptions = computed(() => {
    const isUpdateAvailable = systemInfoMessage.value?.client.updateAvailable == true
    const updateVersion = systemInfoMessage.value?.client.updateVersion

    const isButtonDisabled = (vpnStateMessage.value == undefined || vpnStateMessage.value.error == true || !isUpdateAvailable)

    let title = ""
    if(isDeviceOnline.value == false) {
        T("Das Gerät muss online sein, um diese Aktion durchführen zu können")
    }
    else if(vpnStateMessage.value == undefined) {
        title = T("Diese Aktion erfordert einen aktuellen Status")
    }
    else if(vpnStateMessage.value.error == true) {
        title = T("Um diese Aktion durchführen zu können, wird ein aktive Benutzer-Sitzung benötigt")
    }
    else if(isUpdateAvailable == true && updateVersion) {
        title = T("Update auf %s verfügbar").replace("%s", updateVersion)
    }
    else if(isUpdateAvailable == true) {
        title = T("Update verfügbar")
    }
    else {
        title = T("Kein Update verfügbar")
    }

    let text = ""
    if(isUpdateAvailable == true && updateVersion) {
        text = T("Update auf %s verfügbar").replace("%s", updateVersion)
    }
    else if(isUpdateAvailable == true) {
        text = title = T("Update verfügbar")
    }
    else {
        text = T("Kein Update verfügbar")
    }

    return {
        loading: loadingMessages.updatingClient,
        disabled: isButtonDisabled,
        title: title,
        text: text,
        icon: 'fal fa-sync',
        onClick: async () => {
            loadingMessages.updatingClient = true;
            await sendVpnMessage(activeAccountId.value, device.value?.id || '', 'client-action', undefined, { 'action': 'update' });
        },
    }
})

// Connection Tab
const connectionToColorMap = {
    "Error": "color-red",
    "Disconnected": "",
    "Connected": "color-green",
    "Connecting": "color-yellow"
}
const connectionToIconMap = {
    "Error": "fal fa-exclamation-triangle",
    "Disconnected": "fal fa-unlink",
    "Connected": "fal fa-link",
    "Connecting": "fal fa-link",
}
const flagToLabel: { [key: string]: Label | undefined } = {
    "Active": undefined,
    "User": undefined,
    "Inactive": undefined,
    "OTP deactivated": undefined,
    "Cloud": new Label({
        "class": "bg-blue",
        "text": "VPN",
        "title": T("Dieses VPN wird von der Unified Network Console unterstützt und befindet sich in einer Konfiguration"),
        "icon": "fal fa-network-wired"
    }),
    "Autostart": new Label({
        "class": "bg-green",
        "icon": new Icon({ class:"fal fa-rotate-left",}),
        "text": "",
        "title": T("Verbindung enthält gespeicherte Benutzerdaten und Autostart ist eingeschaltet")
    }),
    "CredentialsSaved": new Label({
        "class": "bg-green",
        "icon": new Icon({ class:"fal fa-user",}),
        "text": "",
        "title": T("Benutzerdaten sind gespeichert")
    }),
    "System": new Label({
        "class": "bg-red",
        "text": "System",
        "title": "System",
    }),
    "Wireguard": new Label({
        "class": "bg-wireguard",
        "text": "WireGuard",
        "title": "WireGuard"
    }),
    "SslVpn": new Label({
        "class": "bg-orange",
        "text": "SSLVPN",
        "title": "SSLVPN"
    }),
    "Otp": new Label({
        "class": "bg-green",
        "text": "OTP",
        "title": "OTP"
    }),
    "Favorite": new Label({
        "class": "bg-green",
        "icon": new Icon({ class:"fal fa-star",}),
        "text": "",
        "title": T("Favorit")
    }),
    "Pin": new Label({
        "class": "bg-green",
        "icon": new Icon({ class:"fal fa-lock",}),
        "text": "PIN",
        "title": T("Zum Starten wird ein PIN benötigt"),
    }),
    "ConnectOnStartup": new Label({
        "class": "bg-green",
        "icon": new Icon({ class:"fal fa-repeat",}),
        "text": "Autostart",
        "title": "Autostart"
    }),
    "AutoReconnect": new Label({
        "class": "bg-green",
        "icon": new Icon({ class:"fal fa-link",}),
        "text": "Auto-Reconnect",
        "title": T("Die Verbindung wird automatisch bei Trennung neu verbunden")
    })
}
const connectionsTableOptions: TableEntryInfo[] = [
    {
        "text": T("Connection"),
        "property": "name",
        "displayType": "text",
        "iconsBefore": (entry: Connection) => {
            let colorClass = connectionToColorMap[entry.currentState as keyof typeof connectionToColorMap] ? connectionToColorMap[entry.currentState as keyof typeof connectionToColorMap] : "";
            let iconClass = connectionToIconMap[entry.currentState as keyof typeof connectionToColorMap] ? connectionToIconMap[entry.currentState as keyof typeof connectionToColorMap] : "";
            
            let title: string;
            if(entry.currentState == "Connected") title = T("Connected")
            else if(entry.currentState == "Error") title = entry.lastErrorText
            else if(entry.lastConnect) title = getDate(entry.lastConnect, true)
            else title = T("Nicht verbunden")

            return [{
                "class": iconClass + " fal fa-link margin-xs-r margin-xs-l " + colorClass,
                "title": title
            }]

        },
        "getValue": (entry: Connection) => {
            return entry.name
        },
        "smallTextAfter": (entry: Connection) => {
            if(entry.lastStateChange <= 0) return " " + T("Letzte Änderung") + ": " + T("Keine Änderung vorhanden")
            else return " " + T("Letzte Änderung") + ": " + getDate(entry.lastStateChange, true)
        },
        "subContent": {
            "displayOrder": ["subLabels"],
            "marginLeft": 32,
            "subLabels": (entry: Connection) => {
                let result: Label[] = []
            let greenLabels: Label[] = []

            if (Array.isArray(entry.flags) && entry.flags.length > 0) entry.flags.forEach((flag) => {
                if (flagToLabel[flag] != undefined) {
                    //@ts-ignore
                    if (flagToLabel[flag]?.class?.includes("bg-green")) greenLabels.push(flagToLabel[flag]);
                    else result.push(flagToLabel[flag]);
                }
            });

            if(greenLabels.length == 1 && greenLabels[0]) result.push(greenLabels[0])
            else if (greenLabels.length > 1) {

                let greenIconLabel = new Label({
                    "content": [],
                    "text": "",
                    "title": "",
                    "class": "bg-green",
                    "htmlTooltip": true,
                    "contentSeparator": `<span style="margin-right: 5px;margin-left: 5px;">|</span>`
                })


                greenLabels.forEach((label: Label) => {
                    if (typeof label?.icon != "string" && typeof label?.icon?.class == "string") {
                        greenIconLabel?.content?.push({
                            "type": "icon",
                            "value": label.icon.class
                        })
                        greenIconLabel.title = greenIconLabel.title + `<div class='row margin-xs-l margin-xs-r-1'><i class='fa-fw ` + label.icon.class + `'></i>` + ": " + label.title + "</div><br>"
                    }
                    else if (label?.text) {
                        greenIconLabel.content?.push({
                            "type": "text",
                            "value": label.text
                        })
                        greenIconLabel.title = greenIconLabel.title + "<div class='row margin-xs-l margin-xs-r-1'>" + label.text + ": " + label.title + "</div><br>"
                    }
                })
                result.push(greenIconLabel)
            }

            return result
            }
        },
        "tdClass": (entry: Connection) => {
            const isInactive: boolean = (entry.flags || []).some((flag: string) => { return flag == "Inactive" })
            if (isInactive == true) return "disabled";
            else return "";
        }
    },
    {
        "text": T("Actions"),
        "property": "actions",
        "displayType": "buttons",
        "width": 395,
        "getValue": (entry: Connection) => {
            let result = []
            if (entry.currentState == "Disconnected" || entry.currentState == "Error") {
                let isButtonDisabled: boolean;
                let title: string

                if (isDeviceOnline.value == false) {
                    title = T("Um diese Aktion durchführen zu können, muss das Gerät Online sein");
                    isButtonDisabled = true;
                }
                else if (connectionLoading.connectionId == entry.id) {
                    title = T("Verbindet...");
                    isButtonDisabled = true;
                }
                else if (!entry.flags.some(flag => flag == "CredentialsSaved" || flag == "Wireguard")) {
                    title = T("Benutzerdaten müssen gespeichert sein");
                    isButtonDisabled = true
                }
                else if (entry.flags.includes("Otp")) {
                    isButtonDisabled = true
                    title = T("Diese Aktion kann nicht durchgeführt werden, da Otp aktiv ist")
                }
                else if (entry.flags.includes("Pin")) {
                    isButtonDisabled = true
                    title = T("Diese Aktion kann nicht durchgeführt werden,da Pin aktiv ist")
                }
                else if(connectionLoading.connectionId != '') {
                    isButtonDisabled = true
                    title = T("Eine andere Verbindung wird aktuell aufgebaut / getrennt. Bitte warten Sie bis der Vorgang abgeschlossen ist")
                }
                else {
                    isButtonDisabled = false
                    title = T("Verbinden");
                }
                result.push(new Button({
                    "text": T("Verbinden"),
                    "title": title,
                    "icon": "fal fa-link",
                    "disabled": isButtonDisabled,
                    "loading": connectionLoading.connectionId == entry.id || false,
                    "onClick": async () => {
                        if (entry.flags.includes("Pin")) {
                            enterPinForConnection(entry);
                        }
                        else {
                            entry.currentState = "Connecting"
                            connectionLoading.connectionId = entry.id
                            sendVpnMessage(activeAccountId.value, objectId.value, "connection-start", entry.id);

                            connectionLoading.timeoutId = setTimeout(() => {
                                if (connectionLoading.connectionId == entry.id) {
                                    connectionLoading.connectionId = ""
                                    entry.currentState = "Disconnected"
                                    addConnectionFailureNotification("Connect", device.value?.alias || "", entry.name)
                                }
                            }, 90000)
                        }
                    },
                    "size": "sm",
                    "style": lang.value == "en" ? "width: 109px;" : "width: 103px;"
                }))
            }
            else if (entry.currentState == "Connecting" || entry.currentState == "Connected") result.push(new Button({
                "text": T("Trennen"),
                "title": T("Trennen"),
                "icon": "fal fa-unlink",
                "loading": connectionLoading.connectionId == entry.id || false,
                "disabled": isDeviceOnline.value == false || connectionLoading.connectionId != '' || entry.flags.includes("Pin"),
                "onClick": async () => {
                    connectionLoading.connectionId = entry.id
                    sendVpnMessage(activeAccountId.value, objectId.value, "connection-stop", entry.id)
                    
                    connectionLoading.timeoutId = setTimeout(() => {
                        if (connectionLoading.connectionId == entry.id) {
                            connectionLoading.connectionId = ""
                            addConnectionFailureNotification("Disconnect", device.value?.alias || "", entry.name)
                        }
                    }, 45000)
                },
                "size": "sm",
                "style": lang.value == "en" ? "width: 109px;" : "width: 103px;"
            }))


            result.push(new Button({
                "text": "Log",
                "title": isDeviceOnline.value == false ? T('Um diese Aktion durchführen zu können, muss das Gerät Online sein') : "Log",
                onClick: () => {
                    openLogForConnection(entry)
                },
                "size": "sm",
                "icon": "fal fa-list",
                "disabled": isDeviceOnline.value == false,
                "loading": false,
            }))

            result.push(new Button({
                "text": T("Diagnosis"),
                "title": isDeviceOnline.value == false ? T('Um diese Aktion durchführen zu können, muss das Gerät Online sein') : T("Diagnosis"),
                "onClick": () => {
                    openDiagnosisForConnection(entry)
                },
                "size": "sm",
                "icon": "fal fa-stethoscope",
                "loading": loadingMessages.connectionDiagnose,
                "disabled": (loadingMessages.connectionDiagnose == true || isDeviceOnline.value == false)
            }))

            if(Array.isArray(entry?.flags) && !entry.flags.includes("Cloud")) result.push(new Button({
                "text": T("Entfernen"),
                "title": T("Entfernen"),
                "size": "sm",
                "icon": "fal fa-trash",
                "loading": connectionLoading.connectionId == entry.id || false,
                "disabled": (isDeviceOnline.value == false || connectionLoading.connectionId != ''),
                "onClick": async () => { removeConnection(entry)}
            }))

            return result
        },
        "tdClass": (entry: Connection) => {
            const isInactive: boolean = (entry.flags || []).some((flag: string) => { return flag == "Inactive" })
            if (isInactive == true) return "disabled";
            else return "";
        }

    },
]
// Messages
const loadingMessages = reactive({
    systemInfo: false,
    connectionGet: false,
    connectionDiagnose: false,
    securityInfo: false,
    deviceState: false,
    updatingClient: false
})
const getVpnMessage = products.unifiedSecurityConsole.windowsVpns.getVpnMessage
const sendVpnMessage = products.unifiedSecurityConsole.windowsVpns.sendVpnMessage
const isMessageTooOld = products.unifiedSecurityConsole.windowsVpns.isMessageTooOld
const setActiveTab = (tabId: string) => {
    if(typeof tabs.value?.setActiveTab == "function") {
        tabs.value.setActiveTab(tabId)
    }
}

// Operationslog
const loadingOperationsLog = ref(false)
const operationsLog = ref(<WindowsVpnJob[]>[])
const operationsLogTable: TableEntryInfo[] = [
    {
        'property': 'timestamp',
        'text': T('Time'),
        'displayType': 'text',
        getSortValue: (job: WindowsVpnJob) => {
            return job?.issued.toString() || job?.lastUpdate.toString() || ""
        },
        'getValue': (job: WindowsVpnJob) => {
            let date = job?.issued || job?.lastUpdate || ""
            return date != '' ? moment(date as number * 1000).format("DD.MM.YYYY HH:mm:ss") : date
        }
    },
    {
        'property': 'type',
        'text': T('Job'),
        'displayType': 'text',
        getSortValue: (job: WindowsVpnJob) => {
            return job?.context ? T(job.context) : ""
        },
        'getValue': (job: WindowsVpnJob) => {
            return T(job?.context || "")
        }
    },
    {
        'property': 'profile',
        'text': T('Profile'),
        'displayType': 'labels',
        getSortValue: (job: WindowsVpnJob) => {
            let sortValue = ""
            if(job?.profileId) {
                let windowsProfile = products.unifiedSecurityConsole.windowsProfiles.useStore?.().getObjectStoreObject(activeAccountId.value, job.profileId)
                sortValue = windowsProfile?.name ? windowsProfile.name : T("N/A")
            }
            return sortValue
        },
        'getValue': (job: WindowsVpnJob) => {
            let windowsProfile: undefined | WindowsProfile = undefined
            if (job.profileId) {
                windowsProfile = products.unifiedSecurityConsole.windowsProfiles.useStore?.().getObjectStoreObject(activeAccountId.value, job.profileId)

                return [
                    new Label({
                        "icon": new Icon({ class: "", }),
                        "text": (windowsProfile?.name ? windowsProfile.name : T("N/A")),
                        "title": (windowsProfile?.name ? windowsProfile.name : T("Das Profil konnte nicht gefunden werden. Möglicherweise wurde das Profil gelöscht")),
                        "onClick": () => {
                            if (windowsProfile != undefined) {
                                router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(activeAccountId.value) + '-windows-profiles-' + windowsProfile.id)
                            }
                        }
                    })
                ]
            }
            return []
        }
    },
    {
        property: "direction",
        text: T("Direction"),
        displayType: "labels",
        getValue: (job: WindowsVpnJob) => {
            return [
                new Label({
                    "icon": new Icon({ class:job?.queue == 'in' ? "fal fa-arrow-left" : "fal fa-arrow-right",}),
                    "text": T(stringHelpers.capitalizeFirstLetter(job?.queue)),
                    "title": job?.queue == 'in' ? T('Message from device to server') : T('Message from server to device')
                })
            ]
        }
    },
    {
        'property': 'status',
        'text': T('Status'),
        'displayType': 'labels',
        getSortValue: (job: WindowsVpnJob) => {
            return job?.status ? T(job?.status) : ""
        },
        'getValue': (job: WindowsVpnJob) => {
            return [
                new Label({
                    "title": job?.statusText ? job?.statusText : T(stringHelpers.capitalizeFirstLetter(job?.status)),
                    "text": T(stringHelpers.capitalizeFirstLetter(job?.status)),
                    "class": job?.status == 'ERROR' ? 'bg-red' : job?.status == 'PENDING' ? 'bg-yellow' : '',
                    "icon": new Icon({ "class":job?.status == 'ERROR' ? "fal fa-exclamation-triangle" : job?.status == 'PENDING' ? "fal fa-bell" : "fal fa-info",})
                })
            ]
        }
    },
    {
        'property': 'statusText',
        'text': T('Info'),
        'displayType': 'text',
        getSortValue: (job: WindowsVpnJob) => {
            return T(job?.statusText || "")
        },
        'getValue': (job: WindowsVpnJob) => {
            return T(job?.statusText) || ""
        }
    },
    {
        property: "actions",
        text: T("Actions"),
        displayType: "buttons",
        getValue: (job: WindowsVpnJob) => {
            let thisbuttons: any = []
            if (job?.status == "PENDING" && job?.jobId !== "none") {
                thisbuttons.push(
                    {
                        "text": T("Revoke"),
                        "title": T("Revoke"),
                        "icon": "fal fas-fw fa-undo",
                        "onClick": async function () {
                            await products.unifiedSecurityConsole.windowsVpns.revokeJob(activeAccountId.value, objectId.value, job.jobId)
                            resetLoadingStates()
                            await loadOperationsLog()
                        },
                        "disabled": function () { },
                        "loading": function () { }
                    }
                )
            }
            return thisbuttons
        }
    }
]

const loadOperationsLog = async () => {
    loadingOperationsLog.value = true

    try {
        const result = await products.unifiedSecurityConsole.windowsVpns.getOperationsLogEntries(activeAccountId.value, objectId.value)

        let publishMessages : WindowsVpnProfileMessage[] | WindowsVpnJob[] =  await products.unifiedSecurityConsole.windowsVpns.getProfileMessagesForDevice(activeAccountId.value, objectId.value) || []
        if(Array.isArray(publishMessages)) {
            publishMessages = publishMessages.map((message) => {
                let context = ""
                if (message.content?.payload?.clientContext) {
                    context = stringHelpers.capitalizeFirstLetter(message.content.payload.clientContext.split("-").slice(1).join(" ").toLowerCase())
                }
                else {
                    context = message.type ?  stringHelpers.capitalizeFirstLetter(message.type.replaceAll("_", " ").toLowerCase()) : ""
                }

                return {
                    context: context,
                    issued: message.created,
                    jobId:"none",
                    lastUpdate: message.updated,
                    queue: message.queue,
                    status: message.status,
                    statusText: message.statusText,
                    type: message.type,
                    profileId: message.content.payload.profileId
                    
                } as WindowsVpnJob
            })
        }

        if(Array.isArray(result) && Array.isArray(publishMessages)) operationsLog.value = result.concat(publishMessages);
    }
    catch(e: unknown) {
        console.error(e)
    }

    loadingOperationsLog.value = false
}

// Security Info
const securityStats = ref(<VPNStatsResponse[]>[])
const showSecurityInfo = ref(false)
const securtityTimePeriod = ref(<"lastday" | "lastweek" | "currentmonth">"currentmonth")
const securityTimestamp = ref(0)
const valueToColorSecurity: { [key: string]: "grey" | "green" | "yellow" | "orange" | "red" } = {
    "0": "green",
    "1": "yellow",
    "2": "red",
    "3": "yellow"
}
const valueToIconSecurity: { [key: string]: string } = {
    "0": "fal fa-circle-check",
    "1": "fal fa-circle-xmark",
    "2": "fal fa-triangle-exclamation",
    "3": "fal fa-circle-exclamation"
}
const valueToTooltipSecurity: { [key: string]: string } = {
    "0": T("Gut"),
    "1": T("Nicht überwacht"),
    "2": T("Schlecht"),
    "3": T("Temporär deaktiviert")
}
const securityTable: TableEntryInfo[] = [
    {
        'property': 'isodate',
        'text': T('Time'),
        'displayType': 'text',
        'getValue': (stat: VPNStatsResponse) => {
            const isoDateString = stat?.isodate || ""
            const isoDateTimestamp = new Date(isoDateString).getTime()

            return isoDateTimestamp ? getDate((isoDateTimestamp / 1000), true) : isoDateString
        },
        "labelsAfter": (stat: VPNStatsResponse) => {
            let result: Label[] = []

            if(stat.fromSystemInfo == true) {
                result.push(new Label({
                    "icon": "fal fa-info-circle",
                    "class": "bg-blue",
                    "text": T("Aktuell"),
                    "title": T("Aktuell")
                }))
            }
            return result
        },
        "width": 260
    },
    {
        'property': 'providerFirewall',
        'text': T('Firewall'),
        'displayType': 'status',
        'getSortValue': (stat: VPNStatsResponse) => {
            return stat?.providerFirewall
        },
        'getValue': (stat: VPNStatsResponse) => {
            let value = stat?.providerFirewall || ""
            if (Object.keys(valueToColorSecurity).includes(value)) return {
                "icon": valueToIconSecurity[value as keyof typeof valueToIconSecurity] as string,
                "tooltip": valueToTooltipSecurity[value as keyof typeof valueToTooltipSecurity] as string,
                "color": valueToColorSecurity[value as keyof typeof valueToColorSecurity] as string
            } as TableEntryStatus;
            else return {
                "icon": "fal fa-question",
                "tooltip": T("Keine Daten gefunden"),
                "color": "grey"
            } as TableEntryStatus;
        },
        "thAlign": "center"
    },
    {
        'property': 'providerAntivirus',
        'text': T('Antivirus'),
        'displayType': 'status',
        'getSortValue': (stat: VPNStatsResponse) => {
            return stat?.providerAntivirus
        },
        'getValue': (stat: VPNStatsResponse) => {
            let value = stat?.providerAntivirus || ""
            if (Object.keys(valueToColorSecurity).includes(value)) return {
                "icon": valueToIconSecurity[value as keyof typeof valueToIconSecurity] as string,
                "tooltip": valueToTooltipSecurity[value as keyof typeof valueToTooltipSecurity] as string,
                "color": valueToColorSecurity[value as keyof typeof valueToColorSecurity] as string
            } as TableEntryStatus;
            else return {
                "icon": "fal fa-question",
                "tooltip": T("Keine Daten gefunden"),
                "color": "grey"
            } as TableEntryStatus;
        },
        "thAlign": "center",
    },
    {
        'property': 'providerSettings',
        'text': T('Automatisches-Update'),
        'displayType': 'status',
        'getSortValue': (stat: VPNStatsResponse) => {
            return stat?.providerSettings
        },
        'getValue': (stat: VPNStatsResponse) => {
            let value = stat?.providerSettings || ""
            if (Object.keys(valueToColorSecurity).includes(value)) return {
                "icon": valueToIconSecurity[value as keyof typeof valueToIconSecurity] as string,
                "tooltip": valueToTooltipSecurity[value as keyof typeof valueToTooltipSecurity] as string,
                "color": valueToColorSecurity[value as keyof typeof valueToColorSecurity] as string
            } as TableEntryStatus;
            else return {
                "icon": "fal fa-question",
                "tooltip": T("Keine Daten gefunden"),
                "color": "grey"
            } as TableEntryStatus;
        },
        "thAlign": "center"
    },
    {
        'property': 'providerUAC',
        'text': T('Benutzerkontensteuerung'),
        'displayType': 'status',
        'getSortValue': (stat: VPNStatsResponse) => {
            return stat?.providerUAC
        },
        'getValue': (stat: VPNStatsResponse) => {
            let value = stat?.providerUAC || ""
            if (Object.keys(valueToColorSecurity).includes(value)) return {
                "icon": valueToIconSecurity[value as keyof typeof valueToIconSecurity] as string,
                "tooltip": valueToTooltipSecurity[value as keyof typeof valueToTooltipSecurity] as string,
                "color": valueToColorSecurity[value as keyof typeof valueToColorSecurity] as string
            } as TableEntryStatus;
            else return {
                "icon": "fal fa-question",
                "tooltip": T("Keine Daten gefunden"),
                "color": "grey"
            } as TableEntryStatus;
        },
        "thAlign": "center"
    },
    {
        'property': 'providerInternetSettings',
        'text': T('Internet-Einstellungen'),
        'displayType': 'status',
        'getSortValue': (stat: VPNStatsResponse) => {
            return stat?.providerInternetSettings
        },
        'getValue': (stat: VPNStatsResponse) => {
            let value = stat?.providerInternetSettings || ""
            if (Object.keys(valueToColorSecurity).includes(value)) return {
                "icon": valueToIconSecurity[value as keyof typeof valueToIconSecurity] as string,
                "tooltip": valueToTooltipSecurity[value as keyof typeof valueToTooltipSecurity] as string,
                "color": valueToColorSecurity[value as keyof typeof valueToColorSecurity] as string
            } as TableEntryStatus;
            else return {
                "icon": "fal fa-question",
                "tooltip": T("Keine Daten gefunden"),
                "color": "grey"
            } as TableEntryStatus;
        },
        "thAlign": "center"
    },
    {
        'property': 'providerService',
        'text': T('Dienst-Status'),
        'displayType': 'status',
        'getSortValue': (stat: VPNStatsResponse) => {
            return stat?.providerService
        },
        'getValue': (stat: VPNStatsResponse) => {
            let value = stat?.providerService || ""
            if (Object.keys(valueToColorSecurity).includes(value)) return {
                "icon": valueToIconSecurity[value as keyof typeof valueToIconSecurity] as string,
                "tooltip": valueToTooltipSecurity[value as keyof typeof valueToTooltipSecurity] as string,
                "color": valueToColorSecurity[value as keyof typeof valueToColorSecurity] as string
            } as TableEntryStatus;
            else return {
                "icon": "fal fa-question",
                "tooltip": T("Keine Daten gefunden"),
                "color": "grey"
            } as TableEntryStatus;
        },
        "thAlign": "center"
    },
]

const getSecurityInfo = async () => {
    try {
        loadingMessages.securityInfo = true;
        const result = await products.unifiedSecurityConsole.windowsVpns.getStatsForDevice(activeAccountId.value, objectId.value, securtityTimePeriod.value)
        if (result) {
            securityStats.value = result
            if (systemInfoMessage.value?.security?.wsc) {
                securityStats.value.unshift({
                    "isodate": getDate(systemInfoMessage.value.timestamp, true),
                    "tenant": tenantHelpers.getTenantDomain(activeAccountId.value),
                    "device": objectId.value,
                    "providerFirewall": systemInfoMessage.value.security.wsc.providerFirewall.toString(),
                    "providerSettings": systemInfoMessage.value.security.wsc.providerSettings.toString(),
                    "providerAntivirus": systemInfoMessage.value.security.wsc.providerAntivirus.toString(),
                    "providerInternetSettings": systemInfoMessage.value.security.wsc.providerInternetSettings.toString(),
                    "providerUAC": systemInfoMessage.value.security.wsc.providerUAC.toString(),
                    "providerService": systemInfoMessage.value.security.wsc.providerService.toString(),
                    "fromSystemInfo": true
                })
            }
            securityTimestamp.value = Math.floor((new Date().getTime()) / 1000);
        }
    }
    catch (e: unknown) {
        console.error(e)
    }
    finally {
        loadingMessages.securityInfo = false
    }
}
watch(securtityTimePeriod, () => {
    getSecurityInfo()
})

// Connection Modals
const enterPinForConnection = async (connection: Connection) => {
    try {
        let modal: any = {
            "id": "wireguadPinModal",
            "abortable": true,
            "content": {
                "title": {
                    "text": T("WireGuard-Verbindung starten"),
                    "icon": "fal fa-laptop"
                },
                "body": {
                    "use": true,
                    "content": undefined,
                    "component": "enter-wireguard-pin",
                    "properties": {
                        "connection": connection,
                        "vpnId": objectId.value
                    }
                }
            },
            "buttons": [
                {
                    "text": T("Close"),
                    "icon": "fal fa-times",
                    "onClick": async function (this: any) {
                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                    },
                    "align": "left",
                    "disabled": false,
                    "loading": false
                },
                {
                    "text": T("WireGuard-Verbindung starten"),
                    "icon": "fal fa-play",
                    "onClick": async function (this: any) {
                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.submit()
                    },
                    "align": "right",
                    "disabled": true,
                    "loading": false
                }
            ]
        }
        useVue().$refs.modals.addModal(modal)
    }
    catch (e: any) {
        devLog.log('dialog', e, undefined, "error", 0)
    }
}
const removeConnection = async (connection: Connection) => {
    try {
        let modal: any = {
            "id": "connectionDeleteDialog",
            "abortable": true,
            "content": {
                "title": {
                    "text": T("Löschen von") + " " + connection.name,
                    "icon": "fal fa-trash"
                },
                "body": {
                    "use": true,
                    "content": undefined,
                    "component": "windows-vpn-connection-remove",
                    "properties": {
                        "connection": connection,
                        "vpnId": objectId.value,
                    }
                }
            },
            "buttons": [
                {
                    "text": T("Close"),
                    "icon": "fal fa-times",
                    "onClick": async function (this: any) {
                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                    },
                    "align": "right",
                    "disabled": false,
                    "loading": false
                },
                {
                    "text": T("Delete"),
                    "icon": "fal fa-trash",
                    "onClick": async function (this: any) {
                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.submit()
                    },
                    "align": "right",
                    "disabled": true,
                    "loading": false
                }
            ]
        }
        useVue().$refs.modals.addModal(modal)
    }
    catch (e: any) {
        devLog.log('dialog', e, undefined, "error", 0)
    }
}
const openDiagnosisForConnection = async (connection: Connection) => {
    try {
        let modal: any = {
            "id": "connectionDiagnosisDialog",
            "abortable": true,
            "content": {
                "title": {
                    "text": T("Diagnose der Verbindung"),
                    "icon": "fal fa-stethoscope"
                },
                "body": {
                    "use": true,
                    "content": undefined,
                    "component": "windows-vpn-connection-diagnosis",
                    "properties": {
                        "connection": connection,
                        "vpnId": objectId.value
                    }
                }
            },
            "buttons": [
                {
                    "text": T("Close"),
                    "icon": "fal fa-times",
                    "onClick": async function (this: any) {
                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                    },
                    "align": "right",
                    "disabled": false,
                    "loading": false
                }
            ]
        }
        useVue().$refs.modals.addModal(modal)
    }
    catch (e: any) {
        devLog.log('dialog', e, undefined, "error", 0)
    }
}

const openLogForConnection = async (connection: Connection) => {
    try {
        let modal: any = {
            "id": "connectionLogDialog",
            "abortable": true,
            "canUseFullscreen": true,
            "content": {
                "title": {
                    "text": T("Log von") + " " + connection.name,
                    "icon": "fal fa-list"
                },
                "body": {
                    "use": true,
                    "content": undefined,
                    "maxHeight": "615",
                    "component": "windows-vpn-connection-log",
                    "properties": {
                        "connection": connection,
                        "vpnId": objectId.value
                    }
                }
            },
            "buttons": [
                {
                    "text": T("Close"),
                    "icon": "fal fa-times",
                    "onClick": async function (this: any) {
                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                    },
                    "align": "right",
                    "disabled": false,
                    "loading": false
                }
            ]
        }
        useVue().$refs.modals.addModal(modal)
    }
    catch (e: any) {
        devLog.log('dialog', e, undefined, "error", 0)
    }
}

//Inventory
const initInventoryform = () => {
        inventoryForm.value.billDate = device.value?.inventory?.billDate ? moment(parseInt(device.value?.inventory.billDate) * 1000).format("DD.MM.YYYY") : ""
        inventoryForm.value.billNumber = device.value?.inventory?.billNumber || ""
        inventoryForm.value.contractDate = device.value?.inventory?.contractDate ? moment(parseInt(device.value?.inventory.contractDate) * 1000).format("DD.MM.YYYY") : ""
        inventoryForm.value.contractDuration = device.value?.inventory?.contractDuration || ""
        inventoryForm.value.contractNumber = device.value?.inventory?.contractNumber || ""
        inventoryForm.value.handoverDate = device.value?.inventory?.handoverDate ? moment(parseInt(device.value?.inventory.handoverDate) * 1000).format("DD.MM.YYYY") : ""
        inventoryForm.value.hardwareDescription = device.value?.inventory?.hardwareDescription || ""
        inventoryForm.value.inventoryExternalLink = device.value?.inventory?.inventoryExternalLink || ""
        inventoryForm.value.providerCustomerNumber = device.value?.inventory?.providerCustomerNumber || ""
        inventoryForm.value.providerName = device.value?.inventory?.providerName || ""
        inventoryForm.value.providerTariff = device.value?.inventory?.providerTariff || ""
        inventoryForm.value.warrantyEndDate = device.value?.inventory?.warrantyEndDate ? moment(parseInt(device.value?.inventory.warrantyEndDate) * 1000).format("DD.MM.YYYY") : ""
        inventoryForm.value.warrantyStartDate = device.value?.inventory?.warrantyStartDate ? moment(parseInt(device.value?.inventory.warrantyStartDate) * 1000).format("DD.MM.YYYY") : ""
        inventoryLoaded.value = true
}

const saveInventory = async () => {
    savingInventory.value = true
    let properties: any = jsonHelpers.copyObject(inventoryForm.value)

    if (properties.billDate) {
        properties.billDate = moment(properties.billDate, "DD.MM.YYYY").format('X')
    }
    if (properties.contractDate) {
        properties.contractDate = moment(properties.contractDate, "DD.MM.YYYY").format('X')
    }
    if (properties.handoverDate) {
        properties.handoverDate = moment(properties.handoverDate, "DD.MM.YYYY").format('X')
    }
    if (properties.warrantyStartDate) {
        properties.warrantyStartDate = moment(properties.warrantyStartDate, "DD.MM.YYYY").format('X')
    }
    if (properties.warrantyEndDate) {
        properties.warrantyEndDate = moment(properties.warrantyEndDate, "DD.MM.YYYY").format('X')
    }
    if (properties.inventoryExternalLink && properties.inventoryExternalLink.indexOf('http://') == -1 && properties.inventoryExternalLink.indexOf('https://') == -1) {
        properties.inventoryExternalLink = 'http://' + properties.inventoryExternalLink
    }

    try {
        if (objectId.value) {
            await products.unifiedSecurityConsole.windowsVpns.queries.updateObjectPropertiesFromApi(activeAccountId.value, objectId.value,properties,undefined,undefined,undefined,"/properties")
            await products.unifiedSecurityConsole.windowsVpns.queries.getObjectFromApi(activeAccountId.value, objectId.value)
        }
    }
    catch (e: any) {
        console.error(e)
    }
    initInventoryform()
    savingInventory.value = false
}

const getInventoryLocationDialog = () => {
    let location = jsonHelpers.copyObject(device.value?.inventory?.inventoryLocation || {})
    dialogs.unifiedSecurity.setInventoryLocation(
        location,
        async (inventoryLocation, inventoryLocationDescription) => {
            if (!jsonHelpers.isObjectEmpty(inventoryLocation) && device.value?.id) {
                await products.unifiedSecurityConsole.windowsVpns.queries.updateObjectPropertiesFromApi(activeAccountId.value, device.value.id, { inventoryLocation: JSON.stringify(inventoryLocation) }, 'inventoryLocation', ["inventory"], undefined, "/properties")
                return true
            }
            else {
                return false
            }
        }
    )
}

// Other Functions
const formatBytes = numberHelpers.formatBytes
const addConnectionFailureNotification = (event: "Connect" | "Disconnect" | "Delete" | "SystemInfo" | "ConnectionGet" | "DeviceState", deviceName: string, connectionName?: string) => {
    let titleText = ""
    let bodyContent = ""

    if(event == "Connect") {
        titleText = T("Verbindung konnte nicht aufgebaut werden.")
        bodyContent = T("Beim Versuch die Verbindung von %s auf %y aufzubauen wurde die Zeit überschritten. Bitte versuchen Sie es erneut.").replace("%s", connectionName).replace("%y", deviceName)
    }
    else if(event == "Disconnect") {
        titleText = T("Verbindung konnte nicht getrennt werden")
        bodyContent = T("Beim Versuch die Verbindung von %s auf %y zu trennen wurde die Zeit überschritten. Bitte versuchen Sie es erneut.").replace("%s", connectionName).replace("%y", deviceName)
    }
    else if(event == "Delete") {
        titleText = T("Verbindung konnte nicht gelöscht werden")
        bodyContent = T("Beim Versuch die Verbindung %s auf %y zu löschen wurde die Zeit überschritten. Bitte versuchen Sie es erneut.").replace("%s", connectionName).replace("%y", deviceName)
    }
    else if(event == "ConnectionGet") {
        titleText = T("Zeitüberschreitung beim Abrufen von Daten")
        bodyContent = T("Das Abrufen der Verbindungen dauert ungewöhnlich lange. Bitte überprüfen Sie den Verbindungsstatus Ihres VPNs.")
    }
    else if(event == "DeviceState") {
        titleText = T("Zeitüberschreitung beim Abrufen von Daten")
        bodyContent = T("Das Abrufen des Gerätestatus dauert ungewöhnlich lange. Bitte überprüfen Sie den Verbindungsstatus Ihres VPNs.")
    }
    else if(event == "SystemInfo") {
        titleText = T("Zeitüberschreitung beim Abrufen von Daten")
        bodyContent = T("Das Abrufen der Verbindungsinformationen dauert ungewöhnlich lange. Bitte überprüfen Sie den Verbindungsstatus Ihres VPNs.")
    }


    frontendNotifications.addNotification(activeAccountId.value, {
        "accountId": activeAccountId.value,
        "content": {
            "title": {
                "icon": "fal fa-exclamation-triangle",
                "text": titleText
            },
            "body": {
                "content": bodyContent
            },
        },
        "highlightNew": true,
        "product": "Unified Network Console",
        "state": "new",
        "timestamp": (new Date()).getTime()
    })
}
const getLabelForHdd = (bitlockerStatus: BitlockerStatus) => {
    let propertyToName: { [prop: string]: string } = {
        "protectionStatus": T(""),
        "encryptionMethod": T("Verschlüsselungsmethode"),
        "encryptionStatus": T("Verschlüsselungsstatus"),
        "lockStatus": T("Status"),
        "encryptionPercentage": T("Prozent verschlüsselt")
    }
    let encryptionStatusToText: { [status: string]: string } = {
        "Fully decrypted": T("Das Volume ist vollständig entschlüsselt."),
        "Fully encrypted": T("Das Volume ist vollständig verschlüsselt."),
        "Encryption in progress": T("Das Volume ist teilweise verschlüsselt."),
        "Decryption in progress": T("Das Volume ist teilweise verschlüsselt."),
        "Encryption paused": T("Das Volume wurde während des Verschlüsselungsfortschritts angehalten. Das Volume ist teilweise verschlüsselt."),
        "Decryption paused": T("Das Volume wurde während des Verschlüsselungsfortschritts angehalten. Das Volume ist teilweise verschlüsselt.")
    }

    let color = bitlockerStatus.protectionStatus == "Protected" ? "bg-green" : "bg-red"
    let icon = bitlockerStatus.protectionStatus == "Protected" ? "fal fa-lock" : "fal fa-exclamation-triangle"
    let text = bitlockerStatus.protectionStatus == "Protected" ? T("Verschlüsselt") : T("Unverschlüsselt")
    let title: string[] = []

    Object.keys(bitlockerStatus).forEach((property) => {
        if (property == "encryptionStatus" && bitlockerStatus[property as keyof typeof bitlockerStatus] != undefined) {
            title.push(propertyToName[property] + ": " + encryptionStatusToText[bitlockerStatus[property as keyof typeof bitlockerStatus]])
        }
        else if (property != "protectionStatus" && bitlockerStatus[property as keyof typeof bitlockerStatus] != undefined) {
            title.push(propertyToName[property] + ": " + bitlockerStatus[property as keyof typeof bitlockerStatus])
        }
    })    

    return new Label({
        "icon": new Icon({
            "class": icon
        }),
        "text": text,
        "class": color + " margin-xs-l",
        "title": title,
        "htmlTooltip": false
    })
}

const getStoragePercentage = (used?: number, free?: number, size?: number): number => {
    let percenTage = 0;

    if(size == undefined && typeof used == "number" && typeof free == "number") size = used + free;
    if(typeof used == "number" && typeof size == "number") percenTage = (used / size) * 100;
    return percenTage;
}

const getTooltipForInterface = (thisInterface: { name: string, ips: string[] }): string[] => {
    let thisTooltip: string[] = []
    thisTooltip.push(T("Name") + ": " + thisInterface.name)
    thisTooltip.push("")

    thisInterface.ips.forEach((entry, index) => {
        thisTooltip.push((index + 1).toString() + ": " + entry)
    })
    return thisTooltip
}

const copyInterfaceToClipboard = (thisInterface: { name: string, ips: string[] }): void => {
    const clipboardHandler = new ClipboardHandler()
    let ips: string = ""
    thisInterface.ips.forEach((entry) => {
        ips = ips + encodingHelpers.escapeHTML(entry) + ", "
    })
    ips = ips.slice(0, -2)
    clipboardHandler.copyToClipboard(ips)
    frontendNotifications.addNotification(activeAccountId.value, {
        "accountId": activeAccountId.value,
        "content": {
            "body": {
                "content": T("Copied IP-adresses") + " " + ips + " " + T("to clipboard")
            },
            "title": {
                "text": T("Copied IP-adresses"),
                "icon": "fal fa-check"
            }
        },
        "highlightNew": true,
    })

}

const getInfoBoxText = () => {
    let inventoryStreet = device.value?.inventory?.inventoryLocation?.street
    let inventoryPostalCode = device.value?.inventory?.inventoryLocation?.postalCode
    let inventoryCity = device.value?.inventory?.inventoryLocation?.city
    const content = `
			<div> 
				${inventoryStreet ? ('<i class="fal fa-fw fa-road"></i>&nbsp;' + encodingHelpers.escapeHTML(inventoryStreet) + '<br>') : ''}
				${inventoryPostalCode ? ('<i class="fal fa-fw fa-globe"></i>&nbsp;' + encodingHelpers.escapeHTML(inventoryPostalCode) + '<br>') : ''}
				${inventoryCity ? encodingHelpers.escapeHTML(inventoryCity) : ''}
			<div>
		`
    return content
}

const isObjectEmpty = jsonHelpers.isObjectEmpty

const init = async (doRequests = true) => {
    loader.value.loading = true
    loader.value.loaderText = "Loading..."

    // do stuff
    if (objectId.value) {
        await products.unifiedSecurityConsole.windowsVpns.queries.getObjectFromApi(activeAccountId.value, objectId.value)

        const props = [{
            "property": "props[]",
            "value": "name"
        },
        {
            "property": "props[]",
            "value": "id"

        },
        {
            "property": "props[]",
            "value": "sun"
        }]


        if (products.unifiedSecurityConsole.windowsProfiles.useStore?.().getObjectStoreObjects(activeAccountId.value)?.length == 0) {
            try {
                if(useFeatureStore?.().isEnabled('WINDOWS_DEVICE_PROFILES')) {
                    await products.unifiedSecurityConsole.windowsProfiles.queries.getObjectsFromApi(activeAccountId.value, undefined, props)
                }
            }
            catch (e: any) {
                console.error(e)
            }
        }
        else if (device.value?.appliedProfileId) {
            try {
                if(useFeatureStore?.().isEnabled('WINDOWS_DEVICE_PROFILES')) {
                    await products.unifiedSecurityConsole.windowsProfiles.queries.getObjectFromApi(activeAccountId.value, device.value.appliedProfileId, undefined, props)
                }
            }
            catch (e: any) {
                console.error(e)
            }
        }
        initInventoryform()
        loadOperationsLog()
        getSecurityInfo()
    }

    loader.value.loading = false
    initialized.value = true
}

const resetLoadingStates = () => {
    Object.keys(loadingMessages).forEach((key) => { loadingMessages[key as keyof typeof loadingMessages] = false })
    Object.keys(commandTimeoutIds).forEach((key) => {
        let value = commandTimeoutIds[key as keyof typeof commandTimeoutIds]
        if (value > 0) {
            clearTimeout(value)
            commandTimeoutIds[key as keyof typeof commandTimeoutIds] = 0
        }
    })
    if (connectionLoading.connectionId) {
        clearTimeout(connectionLoading.timeoutId)
        connectionLoading.timeoutId = 0
        connectionLoading.connectionId = ""
    }
}

const getDate = (timestamp: any, dateTime: boolean = false) => {
    let date: any = ""
    if (typeof timestamp == "number") {
        date = moment.unix(timestamp).format(dateTime ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY")
    }
    else {
        date = moment(timestamp).format(dateTime ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY")
    }
    return date;
}

// Lifecycles
onMounted(async () => {
    await init()
    try {
        await products.unifiedSecurityConsole.windowsVpnStates.queries.getObjectsFromApi(activeAccountId.value)
    }
    catch (e: unknown) {
        console.error(e)
    }

    //TODO Errorhandling for { "error": true } Messages

    useStore().commit(MutationTypes.addSubscriptionHook, {
        "accountId": activeAccountId.value,
        "hookKey": "loadingVpnMessages",
        "hookFunction": (message: any) => {
            const messageTopic: string = message?.topic || message?.data?.topic || ""
            const clientContext: VPNWebsocketContexts = message?.data?.clientContext || ""
            if (typeof messageTopic == "string" && messageTopic.includes("/windows/vpn/") && clientContext) {
                switch (clientContext) {
                    case 'vpn-system-info':
                        loadingMessages.systemInfo = false;
                        if(commandTimeoutIds.systemInfo) {
                            clearTimeout(commandTimeoutIds.systemInfo)
                            commandTimeoutIds.systemInfo = 0
                        }
                    break;
                    case 'vpn-connection-get':
                        if(commandTimeoutIds.connectionGet) {
                            clearTimeout(commandTimeoutIds.connectionGet)
                            commandTimeoutIds.connectionGet = 0
                        }
                        loadingMessages.connectionGet = false;
                    break;
                    case 'vpn-client-action':
                        if(commandTimeoutIds.deviceState) {
                            clearTimeout(commandTimeoutIds.deviceState)
                            commandTimeoutIds.deviceState = 0
                        }
                        const data = message.data?.data as VPNClientActionState
                        if(data) {
                            vpnStateMessage.value = data
                        }
                        if(data?.state == "UPDATE") {
                            loadingMessages.updatingClient = false;
                        }
                        loadingMessages.deviceState = false;
                    break;
                    case 'vpn-connection-start':
                    case 'vpn-connection-stop':
                        if(message?.data?.status == 200) {
                            clearTimeout(connectionLoading.timeoutId)
                            connectionLoading.timeoutId = 0
                            connectionLoading.connectionId = ""
                        }
                    break;
                    default:
                        break;
                }
            }

        }
    })
})

onUnmounted(() => {
    resetLoadingStates()
    useStore().commit(MutationTypes.deleteSubscriptionHook, {
        "accountId": activeAccountId.value,
        "hookKey": "loadingVpnMessages",
    })
})

watch(systemInfoMessage, () => {
    getSecurityInfo()
}, { deep: true })

</script>
<template>
    <div>
        <section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-if="initialized && device != undefined">
            <div class="row itemlist show-details view-grid">
                <div class="col-xs-24 col-print-24 col-lg-12  padding-xs item" style="z-index:999999">
                    <itemlist-item :item="device" :editable="true" :show-menu="false" :clickable="false"></itemlist-item>
                </div>
                <div class="col-xs-24 col-print-24 col-lg-12 padding-xs" style="min-height:250px">
                    <div class="box-shadow box-fullheight content-2">


                        <template
                            v-if="device.inventory && device.inventory.inventoryLocation != undefined && !isObjectEmpty(device.inventory.inventoryLocation)">
                            <h3 class="float-xs-left">{{ T('Inventory location') }}</h3>
                            <a v-on:click="getInventoryLocationDialog()" class="btn padding-xs-y-0 float-xs-right"
                                :class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'"
                                :title="T('Set inventory device location')">
                                <i class="fal fa-edit"></i>
                            </a>
                        </template>
                        <div class="clearfix"></div>
                        <template v-if="device
                        && device.inventory
                        && device.inventory.inventoryLocation
                        && device.inventory.inventoryLocation.latitude
                        && device.inventory.inventoryLocation.longitude
                    ">
                            <googleMap id="inventoryMap" :entries="[{
                            'position': {
                                'lat': device.inventory.inventoryLocation.latitude,
                                'lng': device.inventory.inventoryLocation.longitude
                            },
                            'infoHtml': getInfoBoxText()
                        }]" />
                        </template>
                        <template
                            v-if="!device.inventory || device.inventory.inventoryLocation != undefined && isObjectEmpty(device.inventory.inventoryLocation)">
                            <div :style="(darkmode == '1' ? 'background: rgba(0, 0, 0, 0.2);' : 'background: rgba(0, 0, 0, 0.08);')
                            + 'top: 16px;'
                            + 'height:calc(100% - 32px);'
                            + 'left: 16px;'
                            + 'right: 16px;'
                            + 'position: absolute;'
                        ">
                                <div style="position:absolute;top:50%;transform: translate(0, -50%); width: 100%;"
                                    class="text-center">
                                    <h3 style="
                            width: 100%;
                            text-align: center;
                        ">
                                        {{ T('Inventory location is not set') }}
                                    </h3>
                                    <p>{{ T('Click the following link to set the inventory location:') }} <br>
                                        <br><a class="btn" :title="T('Set inventory device location')"
                                            v-on:click="getInventoryLocationDialog()"> <i
                                                class="fal fa-fw fa-edit"></i>
                                            {{ T('Set location') }}</a>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-24 padding-xs">
                    <div class="box-shadow">

                        <Tabs ref="tabs" :tabs="[
                        {
                            'text': T('Overview'),
                            'tooltip': T('Overview'),
                            'id': 'overview',
                            'icon': 'fal fa-laptop'
                        },
                        {
						    'text': T('Operations'),
							'tooltip': hasUscAdmin == false ? T('Um dieses Feature benutzen zu können, benötigen Sie Rechte für die USC-Administration') : isDeviceOnline == false ? T('Um dieses Feature nutzern zu können, muss das Gerät Online sein') : T('Operations'),
							'id': 'operations',
							'icon': 'fal fa-wrench',
                            'disabled': isDeviceOnline == false || hasUscAdmin == false
						},
                        {
                            'text': T('Connections'),
                            'tooltip': hasUscAdmin == false ? T('Um dieses Feature benutzen zu können, benötigen Sie Rechte für die USC-Administration') : T('Connections'),
                            'id': 'connections',
                            'icon': 'fal fa-project-diagram',
                            'disabled': hasUscAdmin == false
                        },
                        {
                            'text': T('Operations') + ' Log',
                            'tooltip': T('Operations') + ' Log',
                            'id': 'operationsLog',
                            'icon': 'fal fa-list'
                        },
                        {
                            'text': T('Windows-Sicherheit'),
                            'tooltip': T('Windows-Sicherheit'),
                            'id': 'security',
                            'icon': 'fal fa-key'
                        },
                        {
                            'text': T('Inventory'),
                            'tooltip': T('Inventory'),
                            'id': 'inventory',
                            'icon': 'fal fa-info'
                        }
                    ]">
                            <template v-slot:overview>
                                <template v-if="systemInfoMessage">
                                    <div class="row padding-xs-y">
                                        <div class="col-xs-24 col-lg-21">
                                            <small>{{T('Last update:')}} <span>{{ getVpnMessage(device, 'vpn-system-info') ? getDate(getVpnMessage(device, 'vpn-system-info')?.timestamp,true) : T('Unknown') }}</span></small>
                                        </div>
                                        <div class="col-xs-24 col-lg-3 text-right">
                                            <buttonComponent id="refreshSystemInfo" :buttonOptions="refreshSystemInfoButton"></buttonComponent>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-24 col-lg-15">
                                            
                                            <h4>{{ T('Arbeitsspeicherinformationen') }}</h4>
                                            <template v-if="systemInfoMessage && systemInfoMessage?.system?.ram">
                                                <div 
                                                    class="progress-bar progress-bar-sm color-red" :data-progress="'0'"
                                                    :class="{
                                                        'color-red':getStoragePercentage(systemInfoMessage.system.ram.used, systemInfoMessage.system.ram.free, systemInfoMessage.system.ram.size) >= 90,
                                                        'color-yellow':getStoragePercentage(systemInfoMessage.system.ram.used, systemInfoMessage.system.ram.free, systemInfoMessage.system.ram.size) < 90 && getStoragePercentage(systemInfoMessage.system.ram.used, systemInfoMessage.system.ram.free, systemInfoMessage.system.ram.size) >= 70,
                                                        'color-green':getStoragePercentage(systemInfoMessage.system.ram.used, systemInfoMessage.system.ram.free, systemInfoMessage.system.ram.size) < 70
                                                    }"
                                                >
                                                    <div
                                                        style="position:absolute;border-bottom:1em solid; top:0;"
                                                        :style="'width:'+getStoragePercentage(systemInfoMessage.system.ram.used, systemInfoMessage.system.ram.free, systemInfoMessage.system.ram.size) + '%'"
                                                    >
                                                    </div>
                                                </div>
                                                <span>
                                                    {{T('Storage used')}}:
                                                    {{
                                                        formatBytes(systemInfoMessage.system.ram.used, 2).value +
                                                        formatBytes(systemInfoMessage.system.ram.used, 2).unit
                                                    }}
                                                    {{
                                                        T('of')
                                                    }}
                                                    {{
                                                        formatBytes(systemInfoMessage.system.ram.size ?systemInfoMessage.system.ram.size : (systemInfoMessage.system.ram.free + systemInfoMessage.system.ram.used), 2).value + formatBytes(systemInfoMessage.system.ram.size ?systemInfoMessage.system.ram.size : (systemInfoMessage.system.ram.free + systemInfoMessage.system.ram.used), 2).unit
                                                    }}
                                                </span>
                                            </template>

                                            <h4>{{ T('CPU') }}</h4>
                                            <template v-if="systemInfoMessage && systemInfoMessage?.system?.cpu">
                                                <div 
                                                    class="progress-bar progress-bar-sm color-red" :data-progress="'0'"
                                                    :class="{
                                                        'color-red': systemInfoMessage.system.cpu.utilization >= 90,
                                                        'color-yellow':systemInfoMessage.system.cpu.utilization < 90 && systemInfoMessage.system.cpu.utilization >= 70,
                                                        'color-green': systemInfoMessage.system.cpu.utilization < 70
                                                    }"
                                                >
                                                    <div
                                                        style="position:absolute;border-bottom:1em solid; top:0;"
                                                        :style="'width:'+ systemInfoMessage.system.cpu.utilization + '%'"
                                                    >
                                                    </div>
                                                </div>
                                                <span>
                                                    {{ T('CPU Auslastung:') }} {{ systemInfoMessage.system.cpu.utilization }}% <small>(Cores: {{ systemInfoMessage.system.cpu.cores }})</small>
                                                </span>
                                            </template>

                                            <h4>{{ T('Speicher') }}</h4>
                                            <template v-if="systemInfoMessage && systemInfoMessage?.system?.hdd">
                                                <div v-for="hdd of systemInfoMessage.system.hdd" class="margin-xs-b">
                                                    {{ T("Laufwerk") + " " + hdd.name.replace(":\\", ":") }} 
                                                    <template v-if="hdd.bitlockerStatus">
                                                        &nbsp;
                                                        <LabelComponent :label="getLabelForHdd(hdd.bitlockerStatus)"></LabelComponent>
                                                    </template>
                                                    <div 
                                                    class="progress-bar progress-bar-sm color-red margin-xs-t" :data-progress="'0'"
                                                    :class="{
                                                        'color-red':getStoragePercentage(hdd.used, hdd.free, hdd.size) >= 90,
                                                        'color-yellow':getStoragePercentage(hdd.used, hdd.free, hdd.size) < 90 && getStoragePercentage(hdd.used, hdd.free, hdd.size) >= 70,
                                                        'color-green':getStoragePercentage(hdd.used, hdd.free, hdd.size) < 70
                                                    }"
                                                    >
                                                        <div
                                                            style="position:absolute;border-bottom:1em solid; top:0;"
                                                            :style="'width:'+getStoragePercentage(hdd.used, hdd.free, hdd.size) + '%'"
                                                        >
                                                        </div>
                                                    </div>
                                                    <span>
                                                        {{T('Storage used')}}:
                                                        {{
                                                            formatBytes(hdd.used, 2).value +
                                                            formatBytes(hdd.used, 2).unit
                                                        }}
                                                        {{
                                                            T('of')
                                                        }}
                                                        {{
                                                            formatBytes(hdd.size ?hdd.size : (hdd.free + hdd.used), 2).value + formatBytes(hdd.size ?hdd.size : (hdd.free + hdd.used), 2).unit
                                                        }}
                                                    </span>
                                                </div>
                                            </template>

                                            <h4>Upgrades</h4>
                                            <table class="noborder border-h">
                                                <tbody>
                                                    <tr>
                                                        <td class="title" colspan="2">
                                                            <i class="fal fa-fw fa-sync margin-xs-r"></i>
                                                            <span v-if="systemInfoMessage.client.updateAvailable == true && systemInfoMessage.client.updateVersion">
                                                                {{ T('Update auf %s verfügbar').replace("%s", systemInfoMessage.client.updateVersion) }}
                                                            </span>
                                                            <span v-else-if="systemInfoMessage.client.updateAvailable == true">
                                                                {{ T("Update verfügbar") }}
                                                            </span>
                                                            <span v-else>
                                                                {{ T('Kein Update verfügbar') }}
                                                            </span>
                                                        </td>
                                                        <td colspan="1" v-if="systemInfoMessage.client.updateAvailable == true">
                                                            <div :title="isDeviceOnline == true ? T('Go to operations') : T('Das Gerät muss online sein, um diese Aktion durchführen zu können')">
                                                                <a :class="{'btn btn-sm': true, 'disabled': isDeviceOnline == false }" v-on:click="setActiveTab('operations')">{{ T('Go to operations') }}</a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <a href="https://wiki.securepoint.de/VPN/Changelog" target="_blank">
                                                                {{ T('Für mehr Informationen zum Client klicken Sie bitte hier') }}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <h4>{{ T('Interfaces') }}</h4>
                                            <template v-if="systemInfoMessage && systemInfoMessage?.system?.interfaces">
                                                <Tooltip v-for="thisInterface of systemInfoMessage.system.interfaces" 
                                                    :class="{
                                                        'label margin-xs-r margin-xs-t': true,
                                                        'color-white': darkmode == '1'
                                                    }" 
                                                    :is-tag="'span'" :tooltip="getTooltipForInterface(thisInterface)" 
                                                    :html-tooltip="false"
                                                    @click="copyInterfaceToClipboard(thisInterface)"
                                                    :style="'cursor:pointer;'"
                                                >
                                                    <i class="fal fa-copy"></i>
                                                    {{ thisInterface.name }}

                                                </Tooltip>
                                            </template>

                                        </div>
                                    </div>
                                </template>
                                
                                <div class="margin-xs-t-1 margin-xs-b-1" v-else>
                                    <div class="col-xs-24 col-lg-21">
                                        {{ T('There is no system info for this VPN') }}
                                        <br>
                                        <br>
                                        <br>
                                    </div>
                                    <div class="col-xs-24 col-lg-3 text-right">
                                        <buttonComponent id="refreshSystemInfo" :buttonOptions="refreshSystemInfoButton"></buttonComponent>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:operations>
                                <div class="row padding-xs-y">
                                    <div class="col-xs-24 col-lg-21">
                                        <span v-html="T('Clientoperationen bieten die Möglichkeit, den Client beim Benutzer zu starten, zu beenden oder zu aktualisieren. Diese Aktionen benötigen einen aktuellen Status. Dieser kann über die Schaltfläche <strong>Status abfragen</strong> ermittelt werden. Ist keine aktive Benutzersitzung vorhanden, können keine Aktionen durchgeführt werden.')"></span>
                                        <br>
                                        <small>{{T('Last update:')}} <span>{{ typeof vpnStateMessage?.timestamp == 'number' ? getDate(vpnStateMessage.timestamp,true) : T('Unknown') }}</span></small>
                                    </div>
                                    <div class="col-xs-24 col-lg-3 text-right">
                                        <buttonComponent id="refreshDeviceState" :buttonOptions="refreshDeviceStateButton"
                                        ></buttonComponent>
                                    </div>
                                </div>

                                <template v-if="vpnStateMessage?.error == true">
                                    <div class="row margin-xs-t-2 margin-xs-b-2">
                                        <div class="col-xs-24 padding-xs-2 flex-shrink">
                                            <p class="notification" :class="{'color-white':darkmode == '1'}">
                                                <i class="fal fa-exclamation-triangle fa-fw color-red"></i>&nbsp;&nbsp;{{ T("Achtung: Momentan besteht keine aktive Benutzer-Sitzung. Der Securepoint VPN Client kann nicht gestartet werden oder andere benutzerdefinierte Aktionen ausführen.") }}
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                

                                <div class="row padding-xs-y">
									<div class="col-xs-4">
										<p style="margin-top:8px;">
											<strong>
												{{ T("Aktionen") }}
											</strong>
										</p>
									</div>
									<div class="col-xs-20">
									    
                                        <div class="row">    
                                            <div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
                                                <buttonComponent
                                                    class="col-xs-24"
                                                    :buttonOptions="{
                                                        loading: false,
                                                        disabled: (vpnStateMessage == undefined || vpnStateMessage.error == true || vpnStateMessage.state == 'OPEN'),
                                                        title: T('Client starten'),
                                                        text: T('Client starten'),
                                                        icon: 'fal fa-play',
                                                        onClick: async () => {
                                                            loadingMessages.deviceState = true;
                                                            await sendVpnMessage(activeAccountId, device?.id || '', 'client-action', undefined, { 'action': 'open' }); 
                                                        },
                                                    }"
                                                ></buttonComponent>
                                            </div>
                                            <div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
                                                <buttonComponent
                                                    class="col-xs-24"
                                                    :buttonOptions="{
                                                        loading: false,
                                                        disabled: (vpnStateMessage == undefined || vpnStateMessage.error == true || vpnStateMessage.state == 'CLOSED'),
                                                        title: T('Client beenden'),
                                                        text: T('Client beenden'),
                                                        icon: 'fal fa-power-off',
                                                        onClick: async () => { 
                                                            loadingMessages.deviceState = true;
                                                            await sendVpnMessage(activeAccountId, device?.id || '', 'client-action', undefined, { 'action': 'close' }); 
                                                        },
                                                    }"
                                                ></buttonComponent>
                                            </div>
										</div>

									</div>
								</div>

                                <div class="row padding-xs-y">
									<div class="col-xs-4">
										<p style="margin-top:8px;">
											<strong>Upgrades</strong>
										</p>
									</div>
									<div class="col-xs-20">
									    
                                        <div class="row">    
                                            <div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
                                                <buttonComponent
                                                    class="col-xs-24"
                                                    :buttonOptions="updateButtonOptions"
                                                ></buttonComponent>
                                            </div>
										</div>
									</div>
								</div>

                                <div class="row padding-xs-y">
									<div class="col-xs-4">
										<p style="margin-top:8px;">
											{{ " " }}
										</p>
									</div>
									<div class="col-xs-20">
                                        <div class="row">    
                                            <div class="col-xs-24 padding-xs-x-3">
                                                    <a href="https://wiki.securepoint.de/VPN/Changelog" target="_blank">
                                                        {{ T('Für mehr Informationen zum Client klicken Sie bitte hier') }}
                                                    </a>                                               
                                            </div>
										</div>
									</div>
								</div>

                            </template>

                            <template v-slot:connections>
                                    <div class="row padding-xs-y margin-xs-b-2">
                                        <div class="col-xs-24 col-lg-21" v-if="connectionMessage && hasConnections">
                                            {{ T('The currently configured connections of the VPN client are displayed here.') }} 
                                            {{ T('In addition to the current settings and the status of these connections, the status at the time of the query is also displayed.') }}
                                            {{ T('The connections are sent automatically when the VPN client is opened and can be queried again if required.') }}
                                            <small>{{T('Last update:')}} <span>{{ getVpnMessage(device, 'vpn-connection-get') ? getDate(getVpnMessage(device, 'vpn-connection-get')?.timestamp,true) : T('Unknown') }}</span></small>
                                        </div>

                                        <div class="col-xs-24 col-lg-21" v-else>
                                            {{ T('There are currently no connections for this VPN') }}
                                        </div>

                                        <div class="col-xs-24 col-lg-3 text-right">
                                            <buttonComponent
                                                id="refreshConnectionGet"
                                                :buttonOptions="refreshConnectionGetButton"
                                            ></buttonComponent>
                                        </div>
                                    </div>


                                    <template v-if="isDeviceOnline == false">
                                        <div class="row margin-xs-t-3 margin-xs-b-2">
                                            <div class="col-xs-24 padding-xs-1 flex-shrink">
                                                <p class="notification" :class="{'color-white':darkmode == '1'}">
                                                    <i class="fal fa-exclamation-triangle fa-fw color-red"></i>
                                                    &nbsp;&nbsp;
                                                    {{ T("Achtung: Die momentanen Daten sind möglicherweise veraltet. Um dieses Feature nutzen zu können, muss das Gerät Online sein.") }}
                                                </p>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- #43511 -->
                                    <template v-else-if="connectionMessage.clientOpen == false && typeof connectionMessage?.activeSession == 'boolean'">
                                        <div class="row margin-xs-t-3 margin-xs-b-2">
                                            <div class="col-xs-24 padding-xs-1 flex-shrink">
                                                <p class="notification" :class="{'color-white':darkmode == '1'}">
                                                    <i class="fal fa-exclamation-triangle fa-fw color-red"></i>
                                                    &nbsp;&nbsp;
                                                    
                                                    <template v-if="connectionMessage.activeSession == true">
                                                        {{ T("Achtung: Aktuell besteht zwar eine aktive Benutzer-Sitzung, allerdings ist der Clients geschlossen. Dadurch können nur Systemverbindungen abgerufen werden. Um den Client zu starten gehen Sie zu") + " " }}
                                                        <a @click="tabs?.setActiveTab?.('operations')">{{ T('Operations') }}</a>
                                                    </template>

                                                    <template v-else>
                                                        {{ T("Es besteht keine gültige Windows-Session. Der Client kann nicht geöffnet werden und es können nur Systemverbindungen abgerufen werden") }}
                                                    </template>
                                                    
                                                </p>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="row margin-xs-t-2" v-if="connectionMessage && hasConnections">
                                        <tableNext 
                                        :selectableColumns="connectionsTableOptions" 
                                        :objectList="((getVpnMessage(device, 'vpn-connection-get') as VPNConnectionGetMessage)?.connections || [])"
                                        :is-filterable="false"
                                        :is-searchable="true"
                                        :has-options="false"
                                        ></tableNext>
                                        
                                    </div>
                                </template>

                            <template v-slot:operationsLog>

                                <div class="text-right padding-xs-y">
									<buttonComponent
										id="refreshOperations"
										:buttonOptions="{
											loading: loadingOperationsLog,
											disabled: loadingOperationsLog == true,
											title: T('Refresh'),
											text: undefined,
											icon: 'fal fa-sync',
											onClick: loadOperationsLog,
										}"
									></buttonComponent>
								</div>

								<tableNext 
									:selectable-columns="operationsLogTable"
									:object-list="operationsLog"
									:initial-sort-property="'timestamp'"
									:initial-sort-direction="'DESC'"
									:has-options="false"
									:is-filterable="false"
									:isLoading="loadingOperationsLog"
								></tableNext>
                            
                            
                            </template>

                            <template v-slot:security>
                                <div class="row padding-xs-y">
                                    <div class="col-xs-24 col-lg-19">
                                        {{ T('Die Daten der Windows-Sicherheit kommen von dem Windows-Security-Center-Dienst (wscsvc). Das WSC sammelt die aktuellen Informationen zur Sicherheit des Windows-Clients. Neben dem Schutz durch Antivirenprodukte von Drittanbietern, Windows Firewall, dem Status des Dienstes, wird die Konfiguration der Internet-Einstellungen, des Automatischen-Updates und der Benutzerkontensteuerung (UAC) ausgewertet.') + " " }} 
                                        <a @click="showSecurityInfo = !showSecurityInfo"><i :class="{'fal fa-angle-right': showSecurityInfo == false, 'fal fa-angle-down': showSecurityInfo == true }"> </i> {{ showSecurityInfo == true ? ' ' + T('Informationen ausblenden') : ('' + T('Informationen anzeigen'))}}</a>
                                        <br>
                                        <small>{{T('Last update:')}} <span>{{ securityTimestamp ? getDate(securityTimestamp ,true) : T('Unknown') }}</span></small>
                                            
                                        <div id="animationContainer" style="overflow: hidden;">
                                            <Transition name="slide">
                                                <div v-if="showSecurityInfo == true">
                                                    <br>
                                                    {{ T('Jeder dieser Security-Provider hat einen Status von "Gut", "Nicht überwacht", "Schlecht" und "Temporär deaktiviert".') }} <br><br>
                                                    
                                                    <div class="row margin-xs-l margin-xs-t-1"><i :class="valueToIconSecurity['0'] + ' color-' + valueToColorSecurity['0']" :title="valueToTooltipSecurity['0']"></i> {{ T('Gut: Der Status der Sicherheitsanbieterkategorie ist gut und erfordert keine Aufmerksamkeit des Benutzers.') }}</div>
                                                    <div class="row margin-xs-l margin-xs-t-1"><i :class="valueToIconSecurity['1'] +' color-' + valueToColorSecurity['1']" :title="valueToTooltipSecurity['1']"></i> {{ T('Nicht überwacht: Der Status der Sicherheitsanbieterkategorie wird von WSC nicht überwacht.') }}</div>
                                                    <div class="row margin-xs-l margin-xs-t-1"><i :class="valueToIconSecurity['2'] + ' color-' + valueToColorSecurity['2']" :title="valueToTooltipSecurity['2']"></i> {{ T('Schlecht: Der Sicherheitsanbieterkategorie ist schlecht, und der Computer ist möglicherweise gefährdet.') }}</div>
                                                    <div class="row margin-xs-l margin-xs-t-1"><i :class="valueToIconSecurity['3'] + ' color-' + valueToColorSecurity['3']" :title="valueToTooltipSecurity['3']"></i> {{ T('Temporär deaktiviert: Die Sicherheitsanbieterkategorie befindet sich im Zustand "Temporär deaktiviert", der Computer ist möglicherweise gefährdet.') }}</div>
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                    
                                    <div class="col-xs-24 col-lg-5">
                                        <div class="flexrow" style="justify-content: flex-end;">
                                            <label class="form-field margin-xs-b-0 margin-xs-r-2" style="min-width: 160px;">
                                                <span class="form-icon-prefix"><i class="fal fa-clock"></i></span>
                                                <select id="tenant-report-dashboard-period" v-model="securtityTimePeriod" class="form-control">
                                                    <option value="lastday">{{T('Yesterday')}}</option>
                                                    <option value="lastweek">{{T('Last week')}}</option>
                                                    <option value="currentmonth">{{T('Current month')}}</option>
                                                </select>
                                            </label>
                                            <buttonComponent
                                                id="refreshSecurityInfo"
                                                :buttonOptions="{
                                                    loading: loadingMessages.securityInfo,
                                                    disabled: loadingMessages.securityInfo == true,
                                                    title: T('Refresh'),
                                                    text: undefined,
                                                    icon: 'fal fa-sync',
                                                    onClick: async () => { 
                                                        await getSecurityInfo()
                                                    }
                                                }"
                                            ></buttonComponent>
                                        </div>
                                    </div>
                                </div>    
                                    <tableNext
                                        :selectable-columns="securityTable"
                                        :object-list="securityStats"
                                        :initial-sort-property="'isodate'"
                                        :initial-sort-direction="'DESC'"
                                        :has-options="false"
                                        :is-filterable="false"
                                        :is-searchable="true"
                                        :isLoading="loadingMessages.securityInfo"
                                    >
                                    </tableNext>
                            </template>

                            <template v-slot:inventory>
                                <div class="row">
                                    <div class="col-xs-24">
                                        <div class="row">
                                            <div class="col-xs-24 padding-xs-x">
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="billNumber">
                                                            {{T('Bill number')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="billNumber" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Bill number')"
                                                                v-model="inventoryForm.billNumber"
                                                                data-validation-property="/billNumber">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Bill number')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="billDate">
                                                            {{T('Bill date')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input-date v-model="inventoryForm.billDate"
                                                                :value="inventoryForm.billDate"
                                                                :placeholder="T('Select a date')" id="billDate" />
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Bill date')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="warrantyStartDate">
                                                            {{T('Warranty starts on')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input-date v-model="inventoryForm.warrantyStartDate"
                                                                :value="inventoryForm.warrantyStartDate"
                                                                :placeholder="T('Select a date')"
                                                                id="warrantyStartDate" />
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Warranty start')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="warrantyEndDate">
                                                            {{T('Warranty ends on')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input-date v-model="inventoryForm.warrantyEndDate"
                                                                :value="inventoryForm.warrantyEndDate"
                                                                :placeholder="T('Select a date')"
                                                                id="warrantyEndDate" />
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Warranty end')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="inventoryExternalLink">
                                                            {{T('External inventory link')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="inventoryExternalLink" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('URL')"
                                                                v-model="inventoryForm.inventoryExternalLink"
                                                                data-validation-property="/inventoryExternalLink">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Set link to inventory software')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="hardwareDescription">
                                                            {{T('Description')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <textarea id="hardwareDescription"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Description')"
                                                                v-model="inventoryForm.hardwareDescription"
                                                                data-validation-property="/hardwareDescription"></textarea>
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Device description')}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-24 padding-xs-t-4">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>{{T('Contract')}}</h4>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="margin-xs-y-2">
                                    <div class="col-xs-24">
                                        <div class="row">
                                            <div class="col-xs-24 padding-xs-x">
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="providerName">
                                                            {{T('Provider')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="providerName" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Provider')"
                                                                v-model="inventoryForm.providerName"
                                                                data-validation-property="/providerName">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('The name of the provider')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="providerTariff">
                                                            {{T('Tariff')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="providerTariff" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Tariff')"
                                                                v-model="inventoryForm.providerTariff"
                                                                data-validation-property="/providerTariff">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Tariff')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="providerCustomerNumber">
                                                            {{T('Customer number')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="providerCustomerNumber" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Customer number')"
                                                                v-model="inventoryForm.providerCustomerNumber"
                                                                data-validation-property="/providerCustomerNumber">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Customer number')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="contractNumber">
                                                            {{T('Contract number')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="contractNumber" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Contract number')"
                                                                v-model="inventoryForm.contractNumber"
                                                                data-validation-property="/contractNumber">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Contract number')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="contractDate">
                                                            {{T('Start of contract')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input-date v-model="inventoryForm.contractDate"
                                                                :value="inventoryForm.contractDate"
                                                                :placeholder="T('Select a date')"
                                                                id="contractDate" />
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Start of contract')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="contractDuration">
                                                            {{T('Contract term')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="contractDuration" type="number" min="0"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Months')"
                                                                v-model="inventoryForm.contractDuration"
                                                                data-validation-property="/contractDuration">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Contract term')}} ({{T('In months')}})
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="handoverDate">
                                                            {{T('Handover date')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input-date v-model="inventoryForm.handoverDate"
                                                                :value="inventoryForm.handoverDate"
                                                                :placeholder="T('Select a date')"
                                                                id="handoverDate" />
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Handover date')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button
                                                    class="btn margin-xs-b-0 margin-xs-t-2 btn-loader twist-in float-xs-right"
                                                    v-on:click="saveInventory()"
                                                    :class="{ 'btn-loading': savingInventory == true }"
                                                    :disabled="savingInventory == true || null" id="saveButton"
                                                    title="Save" type="submit">
                                                    <span class="animate">
                                                        <loader v-if="savingInventory == true"
                                                            style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);">
                                                        </loader>
                                                    </span>
                                                    <span><i class="fal fa-save"></i> <span
                                                            class="display-xs-none display-lg-inline">{{T('Save')}}</span></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                        </Tabs>

                    </div>
                </div>
            </div>

        </section>
        <template v-else>
            <p class=" text-center padding-xs-t-4 text-size-4 padding-xs-b-0 text-center">
                <loaderComponent class="color-red"></loaderComponent>
            </p>
            <p class="text-center">
                {{ T(loader.loaderText) }}
            </p>
        </template>
    </div>
</template>
<style lang="scss">
td.disabled {
    opacity: 0.5;
    pointer-events: none;
    * {
        pointer-events: none;    
    }
}

.slide-enter-active, .slide-leave-active {
  transition: all 0.5s ease;
}
.slide-enter-from {
  transform: translateY(-100%);
}
.slide-enter-to {
  transform: translateY(0);
}
.slide-leave-from {
  transform: translateY(0);
}
.slide-leave-to {
  transform: translateY(-100%);
}

</style>