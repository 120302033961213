<template>
  <div class="active-users">
    <template v-if="activeUsers.length <= 4" v-for="user in activeUsers">
      <tooltip class="menu" :html-tooltip="true">
        <template #content>
          <p>{{ T("Name") }}: {{ user.username }}</p>
        </template>

        <ul>
          <li>
            <a class="toggle">
              <span style="color: white">
                {{ formatUsername(user.username) }}
              </span>

              <span class="count" v-if="!!user.count">
                x{{ user.count }}
              </span>
            </a>
          </li>
        </ul>
      </tooltip>
    </template>
    <tooltip v-else class="menu" :html-tooltip="true">
      <template #content>
        <div class="padding-xs-2 border-bottom">
          <i class="fal fa-person color-red"></i>&nbsp;&nbsp; <strong>{{ T('Logged in users') }}</strong>
        </div>
        <div class="padding-xs-2">
          <ul class="user-item" v-for="user in activeUsers">
            <li v-if="!!user.count">{{ user.username }} ({{ user.count }}x)</li>
            <li v-else>{{ user.username }}</li>
          </ul>
        </div>
      </template>

      <ul>
        <li>
          <a class="toggle">
            <i class="fa fa-person"></i>

            <span class="count">
              {{ simpleViewUserCount }}
            </span>
          </a>
        </li>
      </ul>
    </tooltip>
  </div>
</template>

<script setup lang="ts">
import products from "@/classes/objectTypes";
import getterHelpers from "@/helpers/helpers.getters";
import { MutationTypes } from "@/store/vuex.store";
import { computed, h, onMounted, watch } from "vue";
import { useStore } from "vuex";
import tooltip from "../components/html-tooltip.vue";
import { T } from "../../classes/i18n";

const store = useStore();

const activeUsers = computed(() =>
  products.mobileSecurity.activeUsers
    .useStore?.()
    ?.getObjectStoreObjects(store.state.session.activeAccountId)
    .sort((a, b) => a.username.localeCompare(b.username))
    .map((user, i, arr) => {
      const count = arr.filter((u) => isIdenticalUser(user, u)).length;

      if (count > 1) {
        return {
          ...user,
          count
        }
      } else {
        return user;
      }
    })
    .filter((user, i, arr) => arr.findIndex((u) => isIdenticalUser(user, u)) === i) as {
      username: string;
      userAgent: string;
      count?: number;
    }[]
);

const simpleViewUserMap = computed(() =>
  products.mobileSecurity.activeUsers
    .useStore?.()
    ?.getObjectStoreObjects(store.state.session.activeAccountId)
    .reduce((acc, user, i, arr) => {
      const internalUsername = `${user.username}@${user.domain}`

      if (user.username === "Securepoint Support") {
        if (acc[internalUsername] === undefined) {
          acc[internalUsername] = 1;
        } else {
          acc[internalUsername] += 1;
        }
      } else {
        acc[internalUsername] = 1;
      }

      return acc
    }, {} as {
      [username: string]: number;
    })
)
const simpleViewUserCount = computed(() => Object.values(simpleViewUserMap.value || {}).reduce((acc, count) => acc + count, 0));

const accountId = computed(() => store.state.session.activeAccountId);

function formatUsername(username: string) {
  const isSecurepointSupport = username === "Securepoint Support";

  if (isSecurepointSupport) {
    return "SP";
  }

  return username.charAt(0).toUpperCase();
}

function isIdenticalUser(userA: any, userB: any) {
  return userA.username === userB.username && userA.userAgent === userB.userAgent && userA.ip === userB.ip;
}

function hookActiveUsers() {
  const objectStore = products.mobileSecurity.activeUsers
    .useStore?.()
    ?.getObjectStore(store.state.session.activeAccountId);;

  getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
    accountId: store.state.session.activeAccountId.value,
    hookKey: "activeUsers",
    hookFunction: async (message: any) => {
      if (message.topic !== "/websocket/active_users") {
        return;
      }

      const objects = await products.mobileSecurity.activeUsers.queries.getObjectsFromApi(
        store.state.session.activeAccountId,
        undefined,
        undefined,
        false,
        false
      );

      if (objectStore && !(objects instanceof Error)) {
        objectStore.objects = objects;
      }
    },
  })
}

onMounted(async () => {
  await products.mobileSecurity.activeUsers.queries.getObjectsFromApi(
    store.state.session.activeAccountId
  );

  hookActiveUsers()
});

watch(accountId, async (newAccountId) => {
  hookActiveUsers()
});
</script>

<style scoped lang="scss">
@import "./../../styles/sass/settings";

$font-size: 1em;

.active-users {
  display: flex;

  .menu {
    ul {
      font-size: $font-size;

      li {
        font-size: $font-size;
      }
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.user-item:not(:last-child) {
  margin-bottom: 1em;
}

a {
  cursor: unset;
}
</style>