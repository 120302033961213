import loaderComponent from "../components/loader.vue"
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters";
import { useStore } from "@/store/vuex.store";

const inputDialogComponent = {
    "name":"input-dialog",
    "template": `<div>
        <div v-if="properties.content" v-html="properties.content"></div>
        <br v-if="properties.useLinebreak == true">
        <template v-if="properties.type == 'textarea'">
            <textarea v-model="value" @keyup="checkConditions" :placeholder="T(properties.placeholder)"></textarea>
        </template>
        <template v-if="properties.type == 'text'">
            <input type="text" v-model="value" @keyup="checkConditions" :placeholder="T(properties.placeholder)"/>
        </template>
    </div>`,
    "data":() => {
        return {
            "value":""
        }
    },
    "computed": {
        "routes":function(this:any) {
            return this.$router.options.routes
        }
    },
    "props": {
        "properties":{
            required:true,
            default:() => {
                return {
                    "content":undefined,
                    "type":"textarea",
                    "placeholder":undefined,
                    "sendCondition":undefined,
                    "useLinebreak": undefined
                }
            }
        }
    },
    "methods": {
        T:T,
        "submit":function(this:any) {
            let message : string = this.value
            getterHelpers.useStore().getters.getActiveModal(getterHelpers.useStore().state.session.activeAccountId).buttons[1].onClick(message)
        },
        "checkConditions": function (this: any) {
            if (typeof this.properties.sendCondition == "function") {    
                getterHelpers.useStore().getters.getActiveModal(getterHelpers.useStore().state.session.activeAccountId).buttons[1].disabled = this.properties.sendCondition(this.value) == false
            }
            else {
                getterHelpers.useStore().getters.getActiveModal(getterHelpers.useStore().state.session.activeAccountId).buttons[1].disabled = false
            }
        },
    },
    "mounted":function(this:any) {
       this.checkConditions()
    },
    "watch": {
        "value":function(this:any) {
            this.checkConditions()
        }
    },
    "components": {
        "loader":loaderComponent
    }
}
export default inputDialogComponent