import ObjectType, { type ItemlistItemDetail, type Label, type MenuEntry, type ObjectTypeObject, type ObjectTypePayload } from "../../objectType";
import config from "@/classes/config";
import { T } from "@/classes/i18n";
import router from "@/router/router";
import numberHelpers from "@/helpers/helpers.numbers";
import type { License } from "../../unifiedSecurity/licenses/licenses";
import getterHelpers from "@/helpers/helpers.getters";
import licenseHelpers from "@/helpers/helpers.license";
import tenantHelpers from "@/helpers/helpers.tenants";
import devLog from "@/classes/log";
import { MutationTypes } from "@/store/vuex.store";
import requestHandler from "@/queries/requests";

export interface Report extends ObjectType<Report> {
    "reportId": string,
    "reportname"?: string,
    "theme"?: "securepoint",
    "type"?: "esr",
    "licenses"?: License[]
    "recipients"?: string[],
    "period"?: ("week"|"month"|"day")[],
    "filesCount"?: number,
    "confirmedRecipients"?: string[]
    "files"?: any[]
}



export class Reports extends ObjectType<Report> {
    constructor(payload: ObjectTypePayload<Report>) {
        super(payload)
        this.itemlist.getSortingOptions = () => [
            {
                "id": "reportname",
                "text": "Name"
            }
        ]
        this.itemlist.getInfoBoxContent = (accountId: string, itemlistComponent: any) => {
            let result = ""
            if (licenseHelpers.hasLicense(accountId, 'laas', 'valid') && tenantHelpers.getPrivacyLevel(accountId) == "1") {
                result = `<i class="fal fa-fw fa-info color-red"></i>
						<p>
							${T('For this tenant the pseudonymization is active. If you want to see usernames, e-mail addresses, IPs etc. in plain text, You have to disable the privacy setting in the Unified Security Portal. This changes the following facts:')}
							<ul>
								<li>
									${T('In the Securepoint Mobile Security dashboard, the devices in the statistics are no longer pseudonymized.')}
								</li>
								<li>
									${T('Usernames, e-mail addresses, IPs, etc. are no longer anonymous in the report.')}
								</li>
								<li>
									${T('In Mobile Security it is possible to create alerts for specific devices.')}
								</li>
							</ul>
						</p>
						<p>${T('In addition, the anonymization of the services must be disabled on the UTM, otherwise the data will be anonymized on the UTM before being sent to the Unified Security Cloud.')}<p>
						<br />
						<p>${T('These settings can not be made automatically on the UTM and must therefore be performed by the administrator.')}</p>`
            }
            return result
        }
        this.itemlistItem.onClick = (accountId: string, item) => {
            if (item?.reportId) {
                router.navigate('#edit-tenant-' + accountId + '.sms-report-' + item?.reportId)
            }
        }
        this.itemlistItem.getLabels = (accountId: string, item) => {
            let result: Label[] = []
            return result
        }

        this.itemlistItem.getDetails = (accountId, item?,component?) => {
            component = component?.exposed ? component?.exposed : component
            

            const recipientsArray = item?.recipients?.map((recipient) => {
                return {
                    "id": recipient,
                    "text": recipient,
                    "lineBreakAfter": true,
                    "title": (item?.confirmedRecipients || []).indexOf(recipient) != -1 ? T('Confirmed') : T('Not confirmed'),
                    "icon": (item?.confirmedRecipients || []).indexOf(recipient) != -1 ? 'fas fa-fw fa-check' : 'fas fa-fw fa-times',
                    "displayType": "label"
                }
            })
                
            
            let periodHtml: string = ""
            if (item?.period) {
                for (let i in item?.period) {
                    periodHtml += T(item?.period[i])
                    if (parseInt(i) != item?.period.length - 1) {
                        periodHtml += ", "
                    }
                }
            }

            let result = [
                {
                    iconClass: 'fal fa-fw fa-info',
                    title: T('Type'),
                    key: T('Type'),
                    value: item ? (item?.type?.replace("esr", "USR") || "") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: 'fal fa-fw fa-paint-brush',
                    title: T('Theme'),
                    key: T('Theme'),
                    value: item ? (item?.theme) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: 'fal fa-fw fa-clock',
                    title: T('Period'),
                    key: T('Period'),
                    value: item ? (periodHtml) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: 'fal fa-fw fa-envelope',
                    title: T('Recipients'),
                    key: T('Recipients'),
                    [item ? "labels" : "value"]: item ? recipientsArray : ""
                },
                {
                    iconClass: 'fal fa-fw fa-hashtag',
                    title: T('Reports'),
                    key: T('Reports'),
                    [item ? "labels" : "value"]: item ? [{
                        title: (item?.filesCount || 0) + ' ' + T('Reports'),
                        text: (item?.filesCount || 0) + ' ' + T('Reports'),
                        onClick: () => { item ? this.showReportsDialog(item) : null },
                        icon: 'fal fa-edit',
                        class: "bg-red",
                        displayType: "label"
                    }] : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                }
            ]
            return result
        }

        this.itemlistItem.hasCheckbox = () => {
            return false
        }
        this.itemlistItem.isClickable = (accountId, item) => {
            return true
        }

        this.itemlistItem.getDisabledState = (accountId, item) => {
            return false
        }
    }

    showReportsDialog(item: Report) {
        try {
            let modal: any = {
                "id": "ReportFiles",
                "abortable": true,
                "content": {
                    "title": {
                        "text": T('Reports')
                    },
                    "body": {
                        "use": true,
                        "content": undefined,
                        "component": 'show-reports-dialog',
                        "properties": {
                            "reportId": item.reportId
                        }
                    }
                },
                "buttons": [
                    {
                        "text": T("Done"),
                        "icon": "fal fa-check",
                        "onClick": async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        "align": "right",
                        "disabled": false,
                        "loading": false
                    }
                ]
            }
            getterHelpers.useVue().$refs.modals.addModal(modal)
        }
        catch (e: any) {
            devLog.log('dialog', e, undefined, "error", 0)
        }
    }

    sendMail = async (accountId:string,reportId:string,filename:any) => {
        let result : boolean = false
        try {
            await requestHandler.request('POST',"/sms-mgt-api/api/1.1/tenants/"+accountId+".sms/laas/reports/"+reportId+"/files/"+filename)
            result = true
        }
        catch(e:any) {
            console.error(e)
            result = false
        }
        return result
    }
    
    deleteFile = async (accountId:string,reportId:string,filename:any) => {
        let result : boolean = false
        try {
            await requestHandler.request('DELETE',"/sms-mgt-api/api/1.1/tenants/"+accountId+".sms/laas/reports/"+reportId+"/files/"+filename)
            result = true
        }
        catch(e:any) {
            console.error(e)
            result = false
        }
        return result
    }

}


const reports = new Reports({
    "objectType": "reports",
    "productType": "unifiedReporting",
    "slug": "reports",
    "apiInfo": {
        "getCountGETProperties": "?props[]=null&select=data.total",
        "url": config.mgtApiUriNext,
        "getObjectListPath": "/tenants/{tenantDomain}/laas/reports",
        "getObjectListResponseProperty": "reports",
    },
    "hasStore": true,
    "objectTypeInfo": {
        "nameProperty": {
            "primary": "reportname"
        },
        "primaryKeyProperty": {
            "property": "reportId"
        },
    },
    "appearance": {
        "color": "red",
        "iconClass": "fal fa-file-alt",
        "showInSidebar": true,
        "text": {
            "plural": "Reports",
            "sidebarName": "Reports",
            "title": "Reports",
            "singular": "Report"
        }
    }

})
export default reports