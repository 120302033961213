import { useGlobalMixin } from "@/mixins/mixins.global"
import loaderComponent from "../components/loader.vue"
import inputVueSelectVue from "../inputtypes/input-vue-select.vue"
import { useStore } from "@/store/vuex.store";
import { ActionTypes } from "@/store/vuex.store"
import { T } from "@/classes/i18n"
import deviceHelpers from "@/helpers/helpers.devices";
import config from "@/classes/config";
import products from "@/classes/objectTypes";
import getterHelpers from "@/helpers/helpers.getters";

const depProfileAssignment = {
    name:"dep-profile-assignment",
    mixins: [useGlobalMixin()],
    template: `
        <div>
            <template v-if="initialized">
                <div class="row padding-xs-y form-group">
                    <div class="first col-xs-24 col-lg-3">
                        <label
                            class="control-label inputname"
                            for="depProfile"
                        >
                            {{ T('DEP-Profile') }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-12">
                        <label>
                            <select v-model="form.depProfile.value" id="depProfile">
                                <option disabled value="NONE">
                                    {{ T('Please select a DEP-Profile') }}
                                </option>
                                <template v-if="form.depProfile.options.length">
                                    <template v-for="options in form.depProfile.options">
                                        <option :value="options.id">
                                            {{ options.text }}
                                        </option>
                                    </template>
                                </template>
                            </select>
                        </label>
                    </div>
                    <div class="desc col-xs-24 col-lg-9">
                        <p class="input-description">
                            {{ T('') }}
                        </p>
                    </div>
                </div>
                <template v-if="properties.deviceInfo == undefined">
                    <div class="row padding-xs-y form-group">
                        <div class="first col-xs-24 col-lg-3">
                            <label
                                class="control-label inputname"
                                for="depdevices"
                            >
                                {{ T('DEP-Devices') }}
                            </label>
                        </div>
                        <div class="input col-xs-24 col-lg-12">
                            <label>

                                <input-vue-select
                                    id="depDevices"
                                    v-model="form.depDevices.value"
                                    :selectOptions="form.depDevices.options"
                                    :placeholder="T('Please select devices')"
                                    :multiple="true"
                                    :tags="true"
                                />

                            </label>
                        </div>
                        <div class="desc col-xs-24 col-lg-9">
                            <p class="input-description">
                                {{ T('') }}
                            </p>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else>
                <p class="text-center padding-xs-t-4 text-size-4 padding-xs-b-0 text-center">
                    <loader class="color-red"></loader>
                </p>
            </template>
        </div>
    `,
    data: function() {
        return {
            "initialized":false,
            "loaderText":"Loading...",
            "form":{
                "depProfile":{
                    "options":[],
                    "value":"NONE"
                },
                "depDevices":{
                    "options":[],
                    "value":[]
                }
            }
        }
    },
    props: {
        properties: {
            "required":false,
            "default": () => { return {
                "deviceInfo":undefined
            }}
        }
    },
    computed: {
        depProfiles: function(this:any) {
            if (config.canUseNewObjectType("depProfiles")) {
                return products.mobileSecurity.depProfiles.useStore?.().getObjectStoreObjects(this.activeAccountId) || []
            }
            return useStore()?.getters.getObjectTypeStore({
                "accountId": this.activeAccountId,
                "objectType": "depProfiles",
            })?.items || []
        },
        depDevices: function(this:any) {
            let devices : any = config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObjects(this.activeAccountId) : useStore().getters.getObjectTypeStore({
                "accountId":this.activeAccountId,
                "objectType":"devices"
            }).items
            let devicesList : any[] = devices?.items?.filter(function(device:any) {
                return (device.depDevice || device.depOnly)
            }) || []
            return { "items":devicesList, "count":devicesList.length }
        }
    },
    methods: {
        updateButtonState: function(this:any) {
            if(this.properties.deviceInfo == undefined) {
                if(this.form.depProfile.value != "NONE" && this.form.depDevices.value.length > 0) {
                    getterHelpers.useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = false
                }
                else {
                    getterHelpers.useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = true
                }
            }
            else {
                if(this.form.depProfile.value != "NONE") {
                    getterHelpers.useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = false
                }
                else {
                    getterHelpers.useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = true
                }
            }
        }
    },
    created: async function(this:any) {},
    watch: {
        "form.depProfile.value":function(this:any) {
            this.updateButtonState()
        },
        "form.depDevices.value":function(this:any) {
            this.updateButtonState()
        }
    },
    mounted: async function(this:any) {
        let thisComponent : any = this
        this.initialized = false
        this.loaderText = "Getting DEP-Profiles..."

        if(this.properties.deviceInfo == undefined) {
            if(config.canUseNewObjectType("iosDevices")) {
                await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(this.activeAccountId)
            }
            else {
                await useStore().dispatch(ActionTypes.getObjectInfos,{
                    "accountId":this.activeAccountId,
                    "objectTypes":["devices","depDevices"]
                })
            }
        }

        if (config.canUseNewObjectType("depProfiles")) {
            await products.mobileSecurity.depProfiles.queries.getObjectsFromApi(this.activeAccountId) 
        }
        else {
            await useStore().dispatch(ActionTypes.getObjectInfos, {
                "accountId": this.activeAccountId,
                "objectTypes": ["depProfiles"]
            })
        }
        

        let profileOptions : any[] = []
        this.depProfiles?.forEach(function(profile:any) {
            let thisName : string = profile.profile_name != profile.profile_uuid ? ((profile.profile_name.length > 20 ? (profile.profile_name.substr(0,20) + '...') : profile.profile_name)+' ('+deviceHelpers.getAliasedShortDeviceId(profile.profile_uuid,'')+')') : (T('Apple DEP Profile')+' ('+deviceHelpers.getAliasedShortDeviceId(profile.profile_uuid,'')+')')
            profileOptions.push({
                "id":profile.profile_uuid,
                "text":thisName
            })
        })
        this.form.depProfile.options = profileOptions
        if(this.properties.deviceInfo == undefined) {
            let deviceOptions : any[] = []
            this.depDevices?.items?.forEach(function(device:any) {
                deviceOptions.push({
                    "id": device.depDeviceInfo ? device.depDeviceInfo.serial_number : device.serial_number,
                    "text": deviceHelpers.getAliasedShortDeviceId(device.depDeviceInfo ? device.depDeviceInfo.serial_number : device.serial_number,device.alias,false)
                })
            })
            this.form.depDevices.options = deviceOptions
        }
        else if (this.properties.deviceInfo?.depDeviceInfo?.profile_uuid) {
            this.form.depProfile.value = this.properties.deviceInfo.depDeviceInfo.profile_uuid
        }

        this.updateButtonState()

        this.initialized = true
    },
    "components": {
        "loader": loaderComponent,
        "input-vue-select":inputVueSelectVue
    }
}
export default depProfileAssignment