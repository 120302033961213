<template>
    <p>{{ T("Reason") }}</p>
    <vue-select v-model="selectedOption" :select-options="selectOptions" />

    <p class="padding-xs-t">{{ T("Custom Reason") }}</p>
    <input v-model="customReason" type="text" :placeholder="T('Reason')" />
</template>

<script setup lang="ts">
import getterHelpers from '@/helpers/helpers.getters';
import { useStore } from '@/store/vuex.store';
import { computed, ref, watch } from 'vue';
import VueSelect from "../inputtypes/input-vue-select.vue"
import { T } from '@/classes/i18n';

const selectedOption = ref(T("Device loss"))
const selectOptions = ref([
    {
        id: T("Device loss"),
        text: T("Device loss")
    },
    {
        id: T("Employee has left"),
        text: T("Employee has left")
    },
    {
        id: T("Other reason"),
        text: T("Other reason")
    }
])

const customReason = ref<string>("")

const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})

function setConfirmDisabled() {
    getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true
}

function setConfirmEnabled() {
    getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = false
}

watch([selectedOption, customReason], () => {

    if (selectedOption.value === T("Other reason") && customReason.value.length < 3) {
        setConfirmDisabled()
    } else {
        setConfirmEnabled()
    }
})

defineExpose({
    selectedOption,
    customReason
})

</script>