
//@ts-ignore
import { createApp } from "vue/dist/vue.esm-bundler"
import router from "./router/router"
import { nextTick } from "vue"
import devLog from "./classes/log"
import cookies from "./classes/cookieHandler"
import vuexStore, { ActionTypes, MutationTypes } from "@/store/vuex.store"
import getterHelpers from "./helpers/helpers.getters"
import websocketHandler from "./classes/websocket"
import { useGlobalMixin } from "./mixins/mixins.global"
import loaderComponent from "./templates/components/loader.vue"
import pageLoginComponent from "./templates/pages/login.vue"
import pagePasswordResetComponent from "./templates/pages/ChangePasswordPage.vue"
import pageAndroidCodeEnrollment from "./templates/pages/androidCodeEnrollment.vue"
import pageiOSCodeEnrollment from "./templates/pages/iOSCodeEnrollment.vue"
import emailSubscriptionComponent from "./templates/pages/emailSubscription.vue"
import pageHeaderComponent from "./templates/templates/page-header.vue"
import modalsComponent from "./templates/components/modals/modals"

import type { ProductType } from "./resources/registeredProducts"
import mainSidebarComponent from "./templates/templates/main-sidebar.vue"
import i18n, { T } from "./classes/i18n"
import notificationsComponent from "./templates/templates/notifications.vue"
import mainHeaderComponent from "./templates/templates/main-header.vue"
import mainFooterComponent from "./templates/templates/footer.vue"
import pageVppAssetDetailsComponent from "./templates/pages/vpp.assetdetails"
import vppAssetDetailsComponent from "./templates/pages/vppasset-details.vue"
import moment from "moment"
import "moment/dist/locale/de.js"
import "moment/dist/locale/en-gb.js"
import mobileSecuritySettingsComponent from "./templates/pages/mobile-security-settings.vue"
import pageAccountComponent from "./templates/pages/account.vue"
import dashboardComponent from "./templates/pages/dashboard.vue"
import pagePrivacyComponent from "./templates/pages/privacy.vue"
import pageAuditLogComponent from "./templates/pages/auditlog.vue"
import requestHandler from "./queries/requests"
import inventoryComponent from "./templates/pages/inventory"
import pagePluginLicensesComponent from "./templates/pages/plugin-licenses.vue"
import itemlistComponent from "./templates/pages/itemlist.vue"
import appPage from "./templates/pages/app-page.vue"
import queries from "./queries/queries"
import objectStores from "./classes/init"
import pagePlaygroundComponent from "./templates/pages/playground.vue"
import pageWarningsComponent from "./templates/pages/warnings.vue"
import cookieConsentComponent from "./templates/components/cookie-consent"
import docsComponent from "./docs/docs.vue"
import androidDeviceDetailsVue from "./templates/pages/android-device-details.vue"
import type { UscUtm } from "./classes/unifiedSecurityConsole/uscUtms"
import dialogs from "./dialogs/dialogs"
import frontendNotifications from "./classes/notifications"
import deviceDetailsComponent from "./templates/pages/devicedetails"
import utmDetailsComponent from "./templates/pages/utmdetails"
import licenseDetailsComponent from "./templates/pages/licensedetails"
import editpageComponent from "./templates/pages/editpage"
import statspageComponent from "./templates/pages/mobile-security-statistics.vue"
import pageBrandingComponent from "./templates/pages/branding"
import windowsVpnsDetails from "./templates/pages/windowsVpns-details.vue"
import secureDnsStatsComponent from "./templates/pages/securedns-statistics.vue"
import secureDnsProtocolsComponent from "./templates/pages/securedns-protocols.vue"

import unifiedNetwork from "./templates/pages/unified-network.vue"

import tenantHelpers from "./helpers/helpers.tenants"
import timeHelpers from "./helpers/helpers.time"
import validationHelpers from "./helpers/helpers.validation"
import idleTimer from "./classes/idleTimer"
import TaskLooper from "./classes/taskLooper"

import logoLink from "./img/securepoint_w.svg"
import logoLinkSmall from "./img/securepoint-icon_w_r.svg"
import printLogoLink from "./img/securepoint.svg"
import zentManagementLink from "./img/zentrales_management.svg"
import widgetareaAddWidgetComponent from "./templates/widgetarea/widgetarea.add.widget"

import VueGridLayout from 'vue3-grid-layout-next';
import unifiedSecuritySettingsComponent from "./templates/pages/unified-security-settings.vue"
import $ from 'jquery'

import uscDashboardComponent from "@/templates/pages/usc-dashboard.vue"
import msDashboardComponent from "@/templates/pages/mobile-security-dashboard.vue"

import 'vue-select/dist/vue-select.css';
import ipadUserDeleteComponent from "./templates/dialogs/sms.dialog.ipaduser.delete"

import Highcharts from 'highcharts'
import SolidGaugeModuleInit from "highcharts/modules/solid-gauge";
import NetworkGraphModuleInit from "highcharts/modules/networkgraph";

import HighchartsMap from "highcharts/modules/map";
import HighChartsMoreInit from "highcharts/highcharts-more";
import HighchartsVue from 'highcharts-vue'
import { createPinia } from "pinia"
import config from "./classes/config"

import accounts from "@/modules/accounts"
import licenseHelpers from "./helpers/helpers.license"
import products from "./classes/objectTypes"
import deviceHelpers from "./helpers/helpers.devices"
import { useAzureStore } from "./classes/objectTypes/unifiedSecurity/users/azure"
import sessionHelpers from "./helpers/helpers.session"
import { debounce } from "throttle-debounce"

import VNetworkGraph from "v-network-graph"
import "v-network-graph/lib/style.css"
import scrollToTopButton from "./templates/components/scrollToTopButton.vue"
import userLogComponent from "./templates/components/userLog.vue"
import { useUserLogStore } from "./classes/userLogStore"
import useRouterStore from "./router/routerStore"
import type { AppliedProfileState, VPNWebsocketContexts } from "./classes/objectTypes/mobileSecurity/windows/windowsVpns"

const useStore = getterHelpers.useStore

HighchartsMap(Highcharts)
HighChartsMoreInit(Highcharts)
SolidGaugeModuleInit(Highcharts)
NetworkGraphModuleInit(Highcharts)
const pinia = createPinia()
//@ts-ignore
window.dummyGMapsCallback = () => {
    devLog.log("Google Maps", "Initialized")
}
const vue : any = createApp({
    mixins: [useGlobalMixin()],
    data() {
        return {
            "initialized": false,
            "loadingProgress": 0,
            "loaderText": "Loading...",
            "loadingTenant": false,
            "fullscreen":false,
            "oneTimeDialogs": {
                "enterpriseError": 0,
                "depError": 0
            },
            "userLogHeight": 0,
            "client": {
                "scrollDistance": 0,
                "scrollHeight": 0,
                "height": 0,
            },
            "googleApi": undefined,
            "gapi": undefined,
            "websocketChecker": undefined,
            "tempStorage":{},
            "mainheaderHeight":64,
            "isMobileView":false,
            "showLog": false,
            logoLink,
            logoLinkSmall,
            printLogoLink,
            zentManagementLink
        }
    },
    computed: {
        onClickHandlers() { return useStore().getters.getOnClickHandlers("root") },
    },
    template: `
    <div 
        id="wrapper" 
        class="wrapper" 
        :class="{ 
            'login-page': initialized && !initializedSession || !sessionRequired()
        }"
        v-on:click="onClickEvent"
    >
        <div v-if="sessionRequired()">
            <cookie-consent></cookie-consent>
        </div>
        <template v-if="initialized && !initializedSession && sessionRequired()">
            <template v-if="activePage == 'pluginLicenses'">
                <header id="main-header" class="media-screen-only" style="padding-left: 24px;">
                    <span class="app-name"> Unified Security </span>
                </header>
                <aside id="main-sidebar" class="media-screen-only" >
                    <div id="logoarea">
                        <a href="/sms/" class="logo-big">
                            <img :src="logoLink" class="logo">
                        </a>
                    </div>
                    <nav class="menu-vertical">
                        <ul class="sidebar-menu tenants">
                            <li>
                                <a href="#login">
                                    <i class="fal fa-fw fa-sign-in-alt"></i> <span class="title">Login</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </aside>
                <main :style="'top:'+(mainheaderHeight)+'px'">
                    <plugin-licenses
                        key="pluginLicenses"
                        ref="pluginLicenses"
                    >
                    </plugin-licenses>
                    <footer-template></footer-template>
                </main>
            </template>
            <login ref="loginpage" v-else></login>
        </template>
        <div v-if="initialized && !sessionRequired()">
            <password-reset
                ref="password-reset"
                v-if="activePage === 'password-reset'"
                key="password-reset"
            />
            <email-subscription
                ref="email-subscription"
                v-if="activePage === 'email-subscription'"
                key="email-subscription"
            />
            <android-code-enrollment
                ref="android-code-enrollment"
                v-if="activePage === 'android-code-enrollment'"
                key="android-code-enrollment"
            />
            <ios-code-enrollment
                ref="ios-code-enrollment"
                v-if="activePage === 'ios-code-enrollment'"
                key="ios-code-enrollment"
            />
        </div>
        <div v-if="initialized && sessionRequired()">
            <transition name="fade">
                <header-template v-if="initializedSession">
                </header-template>
            </transition>
            <transition name="fade">
                <aside id="main-sidebar" class="media-screen-only" v-if="initializedSession">
                    <div id="logoarea">
                        <a href="/sms/" class="logo-big">
                            <img :src="logoLink" class="logo">
                        </a>
                        <a href="/sms/" class="logo-small">
                            <img :src="logoLinkSmall" class="logo"> <span class="app-name">Mobile Security</span>
                        </a>
                    </div>
                    <main-sidebar
                        v-if="activeTenantDomain != undefined"
                        ref="navigation"
                    >
                    </main-sidebar>
                </aside>
            </transition>
            <transition name="fade">
                <main 
                    v-if="initializedSession"
                    v-on:scroll.passive="onScroll()" 
                    :class="{'fullscreen':fullscreen}"
                    :style="showLog ? 'bottom:'+userLogHeight+'px' : ''"
                >
                    <div 
                        id="main-inner"
                        ondragover="$('#main-inner').addClass('dragging')"
                        ondragleave="$('#main-inner').removeClass('dragging')"
                        ondrop="$('#main-inner').removeClass('dragging')"
                    >
                        <section id="print-header" class="media-print-only">
                            <div class="print-site-header">
                                <div class="col-xs-12 padding-print-0">
                                    <img class="securepoint-logo" :src="printLogoLink" style="float: left;">
                                </div>
                                <div class="col-xs-12 padding-print-0">
                                    <img class="zentrales-management-logo" :src="zentManagementLink" style="float: right;">
                                </div>
                            </div>
                        </section>
                        <hr class="media-print-only">
                        <page-header 
                            key="pageHeader" 
                            ref="pageHeader"
                            :scrollDistance="client.scrollDistance"
                        ></page-header>
                        <div key="main" id="main-content-wrapper" :style="'min-height: calc(100vh - 258px);'" style="position: relative;">

                            <template v-if="activePage == 'docs'">
                                <docs-page key="docs" ref="docs"></docs-page>
                            </template>
                            <template v-else-if="activeAccountInitialized">

                                <dashboard
                                    ref="dashboard"
                                    v-if="activePage == 'tenant-dashboard'"
                                    key="dashboard"
                                >
                                </dashboard>

                                <itemlist
                                    ref="itemlist"
                                    :key="'itemlist-'+activeTenantDomain+'-list-'+activePage"
                                    v-if="([
                                        'devices',
                                        'iosDevices',
                                        'enterpriseDevices',
                                        'roles',
                                        'certificates',
                                        'licenses',
                                        'users',
                                        'alerts',
                                        'vpns',
                                        'vppassets',
                                        'apps',
                                        'depProfiles',
                                        'profiles',
                                        'iosProfiles',
                                        'androidDevices',
                                        'androidProfiles',
                                        'utms',
                                        'uscUtms',
                                        'usrUtms',
                                        'reports',
                                        'notifications',
                                        'zeroTouchConfigurations', 
                                        'homescreenlayouts',
                                        'uscProfiles',
                                        'apiKeys',
                                        'iosApps',
                                        'windowsEnrollmentTokens',
                                        'windowsVpns',
                                        'secureDnsProfiles',
                                        'uscZtProfiles',
                                        'windowsProfiles',
                                    ].indexOf(activePage) != -1) && ([undefined,'azureSuccess','azureError'].indexOf(context) != -1)"
                                ></itemlist>

                                <inventory
                                    ref="inventory"
                                    key="inventory"
                                    v-if="(['inventory'].indexOf(activePage) != -1) && (context == undefined)"
                                ></inventory>

                                <sms-page-device-details
                                    :key="activeTenantDomain+'-details-'+activePage+'-'+context+'-'+objectId"
                                    ref="deviceDetails"
                                    v-if="activePage == 'iosDevices' && context == 'details'"
                                >
                                </sms-page-device-details>

                                <android-device-details
                                    :key="activeTenantDomain+'-details-'+activePage+'-'+context+'-'+objectId"
                                    ref="deviceDetails"
                                    v-if="activePage == 'androidDevices' && context == 'details'"
                                />
 
                                <sms-page-utm-details
                                    :key="activeTenantDomain+'-details-'+activePage+'-'+context+'-'+objectId"
                                    ref="utmDetails"
                                    v-if="activePage == 'uscUtms' && context == 'details'"
                                >
                                </sms-page-utm-details>

                                <sms-page-vppassetdetails
                                    :key="activeTenantDomain+'-details-'+activePage+'-'+context+'-'+objectId"
                                    ref="assetDetails"
                                    v-if="!canUseNewObjectType('vppassets') && activePage == 'vppassets' && context == 'details'"
                                >
                                </sms-page-vppassetdetails>
                                <page-vppasset-details
                                    :key="activeTenantDomain+'-details-'+activePage+'-'+context+'-'+objectId"
                                    ref="assetDetails"
                                    v-if="canUseNewObjectType('vppassets') && activePage == 'vppassets' && context == 'details'"
                                >
                                </page-vppasset-details>

                                <sms-page-licensedetails
                                    :key="activeTenantDomain+'-details-'+activePage+'-'+context+'-'+objectId"
                                    ref="licenseDetails"
                                    v-if="activePage == 'licenses' && context == 'details'"
                                >
                                </sms-page-licensedetails>
                                
                                <!-- windowsVpns -->
                                <windows-vpns-details
                                    :key="activeTenantDomain+'-details-'+activePage+'-'+context+'-'+objectId"
                                    ref="windowsVpnsDetails"
                                    v-if="activePage == 'windowsVpns' && context == 'details'"
                                >
                                </windows-vpns-details>

                                <apppage
                                    ref="apppage"
                                    :key="activeTenantDomain+'-'+activePage+'-'+context"
                                    v-if="(activePage == 'iosApps') && ((context == 'add') || (context == 'edit'))"
                                >
                                </apppage>
                                
        
                                <mobile-security-settings
                                    ref="mobileSecuritySettings"
                                    :key="activeTenantDomain+'-'+activePage+'-'+context"
                                    v-if="activePage == 'mobileSecuritySettings'"
                                >
                                </mobile-security-settings>

                                <unified-security-settings
                                    ref="unifiedSecuritySettings"
                                    :key="activeTenantDomain+'-'+activePage+'-'+context"
                                    v-if="activePage == 'generalSettings'"
                                >
                                </unified-security-settings>


                                <sms-page-editpage
                                    ref="editpage"
                                    :key="activeTenantDomain+'-details-'+activePage+'-'+context+'-'+objectId"
                                    v-if="
                                    ([
                                        'alerts',
                                        'reports',
                                        'utms',
                                        'usrUtms',
                                        'androidProfiles',
                                        'iosProfiles',
                                        'users',
                                        'vpns',
                                        'notifications',
                                        'inventory',
                                        'depProfiles',
                                        'roles',
                                        'zeroTouchConfigurations',
                                        'homescreenlayouts',
                                        'uscProfiles',
                                        'apiKeys',
                                        'windowsEnrollmentTokens',
                                        'secureDnsProfiles',
                                        'uscZtProfiles',
                                        'windowsProfiles',
                                    ].indexOf(activePage) != -1) && ([
                                        'add',
                                        'edit',
                                        'editEnterprise',
                                        'editDevice',
                                        'editUtm',
                                        'editVPN',
                                        'addVpp',
                                        'editVpp',
                                        'editUscUtm',
                                        'editReportingUtm'
                                    ].indexOf(context) != -1)"
                                >
                                </sms-page-editpage>

                                <sms-page-statspage
                                    ref="statspage"
                                    :key="activeTenantDomain+activePage"
                                    v-if="
                                        (activePage == 'stats-dashboard')
                                    "
                                >
                                </sms-page-statspage>

                                <securedns-statspage
                                    ref="statspage"
                                    :key="activeTenantDomain+activePage"
                                    v-if="
                                        (activePage == 'secureDns-stats')
                                    "
                                >
                                </securedns-statspage>

                                <securedns-protocols
                                    ref="statspage"
                                    :key="activeTenantDomain+activePage"
                                    v-if="
                                        (activePage == 'secureDns-logs')
                                    "
                                >
                                </securedns-protocols>

                                <account-info
                                    ref="accountInfo"
                                    v-if="(activePage == 'account-info')"
                                    :key="activeTenantDomain+activePage"
                                >
                                </account-info>

                                <sms-page-branding
                                    ref="branding"
                                    v-if="(activePage == 'branding')"
                                    :key="activeTenantDomain+activePage"
                                >
                                </sms-page-branding>
        
                                <privacy
                                    ref="privacy"
                                    v-if="(activePage == 'privacy')"
                                    :key="activeTenantDomain+activePage"
                                >
                                </privacy>
                                
                                <audit-log
                                    ref="auditlog"
                                    v-if="(activePage == 'auditlog')"
                                    :key="activeTenantDomain+activePage"
                                >
                                </audit-log>

                                <playground
                                    ref="sandbox"
                                    key="sandbox"
                                    v-if="(activePage == 'sandbox')"
                                >
                                </playground>

                                <warnings
                                    ref="warnings"
                                    key="warnings"
                                    v-if="(activePage == 'warnings')"
                                >
                                </warnings>

                                <plugin-licenses
                                    key="pluginLicenses"
                                    ref="pluginLicenses"
                                    v-if="(activePage == 'pluginLicenses')"
                                >
                                </plugin-licenses>

                                <usc-dashboard
                                    :key="'uscDashboard-'+activeTenantDomain+'-'+activePage+'-'+context+'-'+objectId"
                                    :ref="activePage"
                                    :tenantWide="activePage == 'uscDashboard'"
                                    v-if="(['uscDashboard','uscMyDashboard'].indexOf(activePage) != -1)"
                                >
                                </usc-dashboard>

                                <ms-dashboard
                                    :key="'msDashboard-'+activeTenantDomain+'-'+activePage+'-'+context+'-'+objectId"
                                    :ref="activePage"
                                    :tenantWide="activePage == 'msDashboard'"
                                    v-if="(['msDashboard','msMyDashboard'].indexOf(activePage) != -1)"
                                >
                                </ms-dashboard>
                                <unifiedNetwork ref="unifiedNetwork" v-if="(activePage == 'unifiedNetworkConfiguration')"></unifiedNetwork>
                                <template v-if="activePage == '404'">
                                    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
                                        <div class="row">
                                            <div class="col-xs-24 padding-xs media-screen-only">
                                                <div class="notification bg-red color-white padding-xs">
                                                    <template v-if="objectId != undefined">
                                                        {{ T('The requested object could not be found') }} ({{ objectId }})
                                                    </template>

                                                    <template v-else>
                                                        {{ T('The requested page could not be found') }}
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </template>
                            </template>
                            
                            <template v-else>
                                <section class="padding-xs padding-md-2 padding-lg-4 pagecontent text-center">
                                    <p style="padding-top:calc(50vh - 150px - 64px - 2.5em)">
                                        <loader style="font-size:5em;" class="color-primary"/>
                                    </p>
                                </section>
                            </template>

                        </div>
                    </div>
                    <footer-template v-if="!fullscreen"></footer-template>
                    <scrollToTopButton ref="scrollToTopButton" :mainButton="true"></scrollToTopButton>
                </main>
            </transition>
            <userLog v-if="initializedSession && showLog == true" :setUserLogHeight="setUserLogHeight"/>
            <modals ref="modals" v-if="initializedSession"></modals>
            <widgetarea-add-widget ref="modalWidgetArea" v-if="initializedSession"></widgetarea-add-widget>
        </div>
        <template v-if="!initialized && !initializedSession">
            <transition name="fade">
                <div class="overlay" style="line-height: 100vh; text-align: center;">
                    <loader style="font-size:5em;" class="color-primary"/>
                </div>
            </transition>
        </template>
    </div>
    `,
    methods: {
        setUserLogHeight:function (this:any,height:number) {
            this.userLogHeight = height
        },
        toggleLog: function(this: any) {
            this.showLog = !this.showLog
        },
        toggleFullscreen:function(this:any) {
            this.fullscreen = !this.fullscreen
        },
        canUseNewObjectType: config.canUseNewObjectType,
        async init(this: any) {
            if (useStore().state.session.initialized) {
                devLog.log('[Base]', 'Initializing Vue')
                if (this.activeAccountId != undefined) {
                    devLog.log('[Base]', 'Active tenantdomain is already set by url...')
                    this.initialized = true
                }
                else if (!useStore().state.session.waitForSessionToSetActiveAccountId) {
                    devLog.log('[Base]', 'No active tenantdomain set...')
                    if (useStore().state.session.accounts) {

                        let tenantDomainRegex = new RegExp(/[0-9]*.sms/gm)
                        let tenantDomain: string | RegExpMatchArray | null = router.getFragment().match(tenantDomainRegex)
                        if (Array.isArray(tenantDomain)) {
                            tenantDomain = tenantDomain[0]
                        }
                        devLog.log('[Base]', 'Picking targetDomain: ' + tenantDomain || useStore().state.session.userInfo.targetDomain || useStore().state.session.userInfo.domain)
                        useStore().dispatch(ActionTypes.switchAccount, (tenantDomain || useStore().state.session.userInfo.targetDomain || useStore().state.session.userInfo.domain).replace('.sms', ''))
                        if (!this.activePage || this.activePage == 'login') router.navigate("show-tenant-" + (tenantDomain || useStore().state.session.userInfo.targetDomain || useStore().state.session.userInfo.domain) + "-dashboard")
                        this.initialized = true
                    }
                }
                else {
                    this.initialized = true
                }
            }
            else {
                await timeHelpers.sleep(1000)
                nextTick(() => { this.init() })
            }
        },
        getCurrentYear(this: any) {
            return moment().format('YYYY')
        },
        sessionRequired(this: any) {
            const specialPages = ['password-reset', 'email-subscription', 'android-code-enrollment', 'ios-code-enrollment'];
            return !specialPages.includes(this.activePage);
        },
        onScroll() {
            const $main = $("main") as JQuery<HTMLElement>;
            
            //@ts-ignore
            (<any>this).client.scrollDistance = $main[0].scrollTop;
                //@ts-ignore
                (<any>this).client.scrollHeight = $main[0].scrollHeight;
                    //@ts-ignore
                    (<any>this).client.height = $main.height();
        },
        onClickEvent() {
            const handlers = (<any>this).onClickHandlers
            if (handlers != undefined) {
                for (const [id, handler] of Object.entries(handlers)) {
                    if (typeof handler == "function") {
                        handler()
                    }
                }
            }
        },
        getTenantStatus: async function (this: any) {
            let result: any = {
                "apns": undefined,
                "enterprise": undefined,
                "dep": undefined,
                "vpp": undefined,
                "mdmLicenseExceeded": false
            }

            if (useStore().state.session.accounts[this.activeAccountId].mobileSecurity.settings.android.enterprise.enabled == false) {
                result.enterprise = "not_set_up"
            }
            else {
                result.enterprise = "set_up"
            }

            if (useStore().state.session.accounts[this.activeAccountId].mobileSecurity.settings.ios.apns.expired == true && useStore().state.session.accounts[this.activeAccountId].mobileSecurity.settings.ios.apns.notAfter == "") {
                result.apns = "not_set_up"
            }
            else if (useStore().state.session.accounts[this.activeAccountId].mobileSecurity.settings.ios.apns.matchesPrivateKey == false) {
                result.apns = "wrong_certificate"
            }
            else if (useStore().state.session.accounts[this.activeAccountId].mobileSecurity.settings.ios.apns.expired == true) {
                result.apns = "expired"
            }
            else if (useStore().state.session.accounts[this.activeAccountId].mobileSecurity.settings.ios.apns.daysLeft <= 14) {
                result.apns = "expires_soon"
            }
            else {
                result.apns = "set_up"
            }


            if (useStore().state.session.accounts[this.activeAccountId].mobileSecurity.settings.ios.dep.enabled == false) {
                result.dep = "not_set_up"
            }
            else {
                result.dep = "set_up"
            }

            if (useStore().state.session.accounts[this.activeAccountId].mobileSecurity.settings.ios.vpp.enabled == false) {
                result.vpp = "not_set_up"
            }
            else {
                result.vpp = "set_up"
            }

            result.mdmLicenseExceeded = useStore().state.session.accounts[this.activeAccountId].mobileSecurity.settings.mdmLicenseExceeded

            return result
        },
        checkAppleAndAndroidSetup: async function (this: any) {
            let thisComponent: any = this
            devLog.log('[Base]', 'Checking apple push cert')

            let status: any = await this.getTenantStatus()
            let checkIfNoDevices: boolean = true 


            if (status.apns == 'not_set_up' && status.enterprise == 'not_set_up') {
                devLog.log('[Base]', 'Apple push cert && Andriod Enterprise are not set up')
                dialogs.misc.confirmDialog(
                    useStore().state.session.activeAccountId || "",
                    T('Set up Android Enterprise or your Apple push certificate'),
                    T("Go to Mobile Security settings in order to set up either one of them."),
                    () => {
                        router.navigate('show-tenant-' + thisComponent.activeTenantDomain + '-mobile-security-settings')
                    },
                    undefined,
                    T('Setup'),
                    'fal fa-sync'
                )
            }
            else if (['wrong_certificate', 'expired', 'expires_soon'].indexOf(status.apns) != -1) {

                devLog.log('[Base]', 'Apple push cert does not exist or is expired')

                let content: string =
                    status.apns == 'wrong_certificate'
                        ? T("It seems that you have not uploaded the correct Apple Push Certificate.")
                        : status.apns == 'expires_soon'
                            ? T('Your Apple Push Certificate will expire in less than 14 days. Please extend your Apple Push Certificate.') + ' <strong>' + T("IMPORTANT: Please use the \"Renew\" function in Apple\\'s Push Certificate Portal to extend your existing certificate.") + '</strong>'
                            : T('Your Apple Push Certificate needs to be extended.') + ' <strong>' + T("IMPORTANT: Please use the \"Renew\" function in Apple\\'s Push Certificate Portal to extend your existing certificate.") + '</strong>'


                dialogs.misc.confirmDialog(
                    useStore().state.session.activeAccountId || "",
                    status.apns == 'wrong_certificate' ? T('Wrong Apple Push Certificate') : T('Apple Push Certificate needs to be extended'),
                    content,
                    () => {
                        thisComponent.renderAppleCertificateDialog(checkIfNoDevices, ['expired', 'expires_soon'].indexOf(status.apns))
                    },
                    undefined,
                    T('Setup'),
                    'fal fa-sync'
                )
            }
            else {
                devLog.log('[Base]', 'Apple push cert && Enterprise is fine, nice!')
            }
        },
        renderAppleCertificateDialog: async function (this: any, update: boolean = false) {
            dialogs.mobileSecurity.setApplePushCertificate(this.activeAccountId, update)
        },
        renderAppleDEPDialog: async function (this: any) {
            dialogs.mobileSecurity.setAppleDepDialog(this.activeAccountId, undefined)
        },
        renderAppleVPPDialog: async function (this: any, expired: boolean = false) {
            dialogs.mobileSecurity.setAppleVPPDialog(this.activeAccountId, expired ? "expired" : undefined)
        },
        getCurrentPrivacyLevel: function (this: any) {
            let privacyLevel: any = 0
            if (this.activeAccountId) {
                privacyLevel = useStore().state.session.accounts[this.activeAccountId].unifiedSecurity.privacy.privacyLevel
            }
            return privacyLevel
        },
        setItemlistSearchString: function (this: any, string: string) {
            let thisComponent: any = this
            cookies.set('search', string, 365 * 10)

        },
        webSocketConnectionChecker: function (this: any) {
            devLog.log('WebSocketConnectionChecker', 'Checking websocket connection...', undefined, "info", 2)
            if (websocketHandler.connection && websocketHandler.connection.readyState == 3 || websocketHandler.connection?.readyState == 2) {
                devLog.log('WebSocketConnectionChecker', 'Connection closed, trying to open a new connection...', undefined, "info", 2)
                try {
                    websocketHandler.connect(this.activeAccountId)
                }
                catch (e: any) {
                    devLog.log('WebSocketConnectionChecker', 'Error on opening connection', undefined, "info", 2)
                    console.error(e)
                }
            }
            else if (websocketHandler.connection?.readyState != 0) {
                devLog.log('WebSocketConnectionChecker', 'Connection should exist. Testing...', undefined, "info", 2)
                try {
                    websocketHandler.sendMessage(JSON.stringify({ "topic": "/ping" }))
                    devLog.log('WebSocketConnectionChecker', 'Websocket is still connected.', undefined, "info", 2)
                }
                catch (e: any) {
                    devLog.log('WebSocketConnectionChecker', 'Error on testing connection.', e, "error", 2)
                    devLog.log('WebSocketConnectionChecker', 'Trying to open a new connection...', undefined, "info", 2)
                    websocketHandler.connect(this.activeAccountId)
                }
            }
        },
        checkForVulnerableUtms: async function (this: any) {
            this.tempStorage.usrUtmVersions = []

            let availableAccountIds = Object.keys(useStore().state.session.accounts)


            if (vue.$refs?.modals?.$refs?.modal?.$refs?.modalComponent != undefined && vue.$refs?.modals?.$refs?.modal?.$refs?.modalComponent.tenantCounter != undefined) {
                vue.$refs.modals.$refs.modal.$refs.modalComponent.loaderInfo = "Filter Accounts without UTMs..."
            }
            const accountIdsWithUtms = availableAccountIds.filter((accountId) => {
                return licenseHelpers.hasOneOfLicenses(accountId, ["laas", "unifiedSecurityConsole"])
            })
            let accountsWithVulnerableUtms: { [accountId: string]: Array<"USC" | "USR"> } = {}

            let activeAccountId = useStore().state.session.activeAccountId

            // GET UTMs
            let url = "/sms-mgt-api/api/2.0/tenants/" + tenantHelpers.getTenantDomain(activeAccountId || "") + "/utms?with_subtenants=true" + "&props[]=utmId" + "&props[]=utmname" + "&props[]=license" + "&props[]=messages" + "&select=data.utms[?(license.licenseScopes[?contains(@,%27cloud:messaging%27)%20==`true`])]"
            let utms = await requestHandler.request("GET", url)
            utms.forEach((utm: UscUtm) => {
                objectStores.uscUtms.addOrUpdateObjectInStore(String(utm.license.accountId), utm)
            })


            for (let i = 0; availableAccountIds.length > i; i++) {
                const accountId = availableAccountIds[i]
                let thisUscUtmIds: string[] = []
                let utms = useStore().getters.getObjects({
                    "accountId": accountId,
                    "productType": "unifiedSecurityConsole",
                    "objectType": "uscUtms"
                })
                /*
                if (vue.$refs?.modals?.$refs?.modal?.$refs?.modalComponent != undefined && vue.$refs?.modals?.$refs?.modal?.$refs?.modalComponent.tenantCounter != undefined) {
                    vue.$refs.modals.$refs.modal.$refs.modalComponent.loaderInfo = "Getting UTMs for Account: " + accountId + ".sms (" + (i + 1) + "/" + availableAccountIds.length + ")" + "..."
                }
                */
                let hasUtmWithBums = await objectStores.uscUtms.utmsUpdateCheck(accountId,utms)
                if (hasUtmWithBums) {
                    if (!accountsWithVulnerableUtms[accountId]) {
                        accountsWithVulnerableUtms[accountId] = []
                    }
                    accountsWithVulnerableUtms[accountId].push("USC")
                }

                thisUscUtmIds = (utms || []).map((utm: UscUtm) => {
                    return utm.utmId
                })


                let usrUtmsWithBums = await requestHandler.request("POST", "/sms-mgt-api/api/1.1/tenants/" + accountId + ".sms/stats/execute", {
                    "query": {
                        "modul": "CloudUtm",
                        "name": "USRSystemInfoVersionLatest"
                    }
                })
                this.tempStorage.usrUtmVersions = this.tempStorage.usrUtmVersions.concat(usrUtmsWithBums?.result)
                usrUtmsWithBums = usrUtmsWithBums?.result?.filter((usrUtm: any) => {
                    return thisUscUtmIds.indexOf(usrUtm.device) == -1 && objectStores.uscUtms.isUpdateNeeded({
                        "messages": {
                            "spcli-system-info": {
                                "timestamp": 0,
                                //@ts-ignore
                                "data": {
                                    "version": usrUtm.version
                                }
                            }
                        }
                    })
                })
                if ((usrUtmsWithBums || []).length > 0) {
                    if (!accountsWithVulnerableUtms[accountId]) {
                        accountsWithVulnerableUtms[accountId] = []
                    }
                    accountsWithVulnerableUtms[accountId].push("USR")
                }
            }
            if (Object.keys(accountsWithVulnerableUtms).length) {

                if (vue.$refs?.modals?.$refs?.modal?.$refs?.modalComponent && vue.$refs?.modals?.$refs?.modal?.$refs?.modalComponent.tenantCounter) {
                    vue.$refs.modals.$refs.modal.content.title.text = "Update strongly recommended!"
                    vue.$refs.modals.$refs.modal.modal.buttons[1].text = "OK"
                    vue.$refs.modals.$refs.modal.modal.buttons[1].icon = "fal fa-check"
                    vue.$refs.modals.$refs.modal.$refs.modalComponent.accountsWithVulnerableUtms = accountsWithVulnerableUtms
                    vue.$refs.modals.$refs.modal.modal.buttons[1].loading = false
                    vue.$refs.modals.$refs.modal.modal.buttons[1].disabled = false

                    vue.$refs.modals.$refs.modal.modal.buttons[1].onClick = () => {
                        useStore()?.commit(MutationTypes.removeModal, { accountId:this.activeAccountId })
                    }

                    vue.$refs.modals.$refs.modal.$refs.modalComponent.hasChecked = true
                    vue.$refs.modals.$refs.modal.$refs.modalComponent.loading = false
                }
                else {
                    objectStores.uscUtms.dialogs.renderUtmNeedsUpdate(this.activeAccountId, accountsWithVulnerableUtms)
                }

            }
            else {
                if (vue.$refs?.modals?.$refs?.modal?.$refs?.modalComponent != undefined && vue.$refs?.modals?.$refs?.modal?.$refs?.modalComponent.tenantCounter != undefined) {
                    vue.$refs.modals.$refs.modal.content.title.text = "No vulnerable UTMs found"
                    vue.$refs.modals.$refs.modal.modal.buttons[1].text = "OK"
                    vue.$refs.modals.$refs.modal.modal.buttons[1].icon = "fal fa-check"
                    vue.$refs.modals.$refs.modal.modal.buttons[1].loading = false
                    vue.$refs.modals.$refs.modal.modal.buttons[1].disabled = false

                    vue.$refs.modals.$refs.modal.modal.buttons[1].onClick = function () {
                        useStore()?.commit(MutationTypes.removeModal, { accountId:this.activeAccountId })
                    }

                    vue.$refs.modals.$refs.modal.$refs.modalComponent.hasChecked = true
                    vue.$refs.modals.$refs.modal.$refs.modalComponent.loading = false
                }
            }
        },
        calcTemplateSizes: function (this:any) {
            if (($(window).width() || 768) > 768) {
                this.mainheaderHeight = 64
            }
            else {
                // mobile
                this.mainheaderHeight = 48
            }
        },
        addWebsocketSubscriptions: function(this:any) {
            const store = useStore()
            objectStores.uscUtms.handleGlobalWebsocketHooks()
            products.unifiedSecurityConsole.topologies.view.handleGlobalWebsocketHooks()
            store.commit(MutationTypes.addSubscriptionHook, {
                "hookKey": "log",
                "hookFunction": (message: any) => {
                    let type = message.data?.type || message.topic
                    let deviceId = message.data?.deviceId || ""
                    let alias = message.data?.alias || ""
                    let deviceTitle: string = ""
                    let small: string = ""

                    if (alias) {
                        deviceTitle = alias
                        small = '(' + deviceId.substring(0, 4) + ')'
                        deviceTitle += " " + small
                    }
                    else {
                        deviceTitle = deviceId.substring(0, 4)
                    }

                    if (message.topic?.startsWith("/message/in/create")) {
                        useUserLogStore().addLogEntry("fal fa-fw fa-mobile-alt", 'color-lightblue', T('Received message of type') + ' ' + T(type) + ' ' + T('from device') + ' ' + deviceHelpers.getAliasedShortDeviceId(deviceId, alias), message)
                    }
                    else if (message.topic?.startsWith("/message/out/create")) {
                        useUserLogStore().addLogEntry("fal fa-fw fa-server", "color-lightblue", T('Sent message of type') + ' ' + T(type) + ' ' + T('to device') + ' ' + deviceHelpers.getAliasedShortDeviceId(deviceId, alias), message)
                    }
                    else if (message.topic === "/generic_error") {
                        useUserLogStore().addLogEntry("fal fa-fw fa-exclamation-triangle", "color-red", message.data.message)
                    }
                }
            })
            if (config.canUseNewObjectType("vppassets") && config.canUseNewObjectType("vppUsers") && config.canUseNewObjectType("vppAssignments")) {
                // #32146

                const getVppAssetsDebounced = debounce(2000,() => {
                    products.mobileSecurity.vppassets.queries.getObjectsFromApi(this.activeAccountId)
                })
                const getVppAssignmentsDebounced = debounce(2000,() => {
                    products.mobileSecurity.vppAssignments.queries.getObjectsFromApi(this.activeAccountId)
                })
                const getVppUsersDebounced = debounce(2000,() => {
                    products.mobileSecurity.vppUsers.queries.getObjectsFromApi(this.activeAccountId)
                })


                store.commit(MutationTypes.addSubscriptionHook, {
                    "hookKey": "vppLicenses",
                    "hookFunction": (message: any) => {
                        if (typeof message?.topic == "string" && message.topic.startsWith("/apple/vpp/")) {
                            switch (message.topic) {
                                case "/apple/vpp/asset_count":
                                    getVppAssetsDebounced()
                                    break;
                                case "/apple/vpp/user_management":
                                    getVppAssignmentsDebounced()
                                    getVppAssetsDebounced()
                                    break;
                                case "/apple/vpp/asset_management":
                                    getVppUsersDebounced()
                                    getVppAssignmentsDebounced()
                                    break;
                                default:
                                    devLog.log('[WebSocketConnectionChecker', 'No action found for apple vpp', message.topic, 'warning')
                                    break;
                            }
                        }

                    }
                })
            }
            store.commit(MutationTypes.addSubscriptionHook, {
                "hookKey": "windowsVpnEnrollment",
                "hookFunction": async (message: any) => {
                    const topic: string = message?.topic || ""
                    const tenantDomain: string = message?.tenantDomain || ""
                    const deviceId: string = message?.data?.deviceId || ""
                    if (topic && tenantDomain && deviceId && topic == "/windows/vpn/device/enrolled") {
                        try {
                            await products.unifiedSecurityConsole.windowsVpns.queries.getObjectFromApi(tenantHelpers.getAccountId(tenantDomain), deviceId)
                            await products.unifiedSecurityConsole.windowsVpns.queries.getCountFromApi(tenantHelpers.getAccountId(tenantDomain))
                            await products.unifiedSecurityConsole.windowsVpnStates.queries.getObjectsFromApi(tenantHelpers.getAccountId(tenantDomain))
                        }
                        catch (e: unknown) {
                            console.error(e)
                        }
                    }
                }
            })
            store.commit(MutationTypes.addSubscriptionHook, {
                "hookKey": "devices",
                "hookFunction": (message: any) => {
                    const accountId = store.state.session.activeAccountId || ""
                    const state = store.state
                    if (message.topic == "/device/pong") {

                        let devices = config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.useStore?.().getObjectStore(accountId).objects : state.session.accounts[accountId]?.mobileSecurity.devices?.items

                        if (devices?.length) {
                            if (config.canUseNewObjectType("iosDevices")) {
                                products.mobileSecurity.iosDevices.useStore?.().setObjectTypeObjectProperty(accountId, message.data?.deviceId, message.timestamp, "lastContact")
                            }
                            else {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "accountId": accountId,
                                    "objectId": message.data?.deviceId,
                                    "productType": "mobileSecurity",
                                    "objectType": "devices",
                                    "objectPropertyPath": [],
                                    "property": "lastContact",
                                    "value": message.timestamp
                                })
                            }

                        }
                    }
                    if ((message.topic == "/message/in/create" && message.data?.type == "SIGNIN")) {
                        setTimeout(() => {

                            let requestObjectTypes = []

                            if (config.canUseNewObjectType('iosDevices')) {
                                products.mobileSecurity.iosDevices.queries.getObjectsFromApi(accountId)
                            }
                            else {
                                requestObjectTypes.push('devices')
                            }


                            if (tenantHelpers.hasFunctionality(accountId, 'appledep')) {
                                requestObjectTypes.push('depProfiles')
                                requestObjectTypes.push('depDevices')
                            }
                            store.dispatch(ActionTypes.getObjectInfos, {
                                "accountId": accountId,
                                "objectTypes": requestObjectTypes,
                            })
                            store.dispatch(ActionTypes.getObjectTypeCount, { "accountId": accountId, "objectType": 'iosDevices' })
                        }, 10000)
                    }
                    if (message.topic == "/android/enterprise/device/create") {
                        if (config.canUseNewObjectType("androidDevices")) {
                            products.mobileSecurity.androidDevices.queries.getObjectFromApi(accountId, message.data?.deviceName.split('/')[3])
                            products.mobileSecurity.androidDevices.queries.getCountFromApi(accountId)
                        }
                        else {
                            store.dispatch(ActionTypes.getObjectInfo, {
                                "accountId": accountId,
                                "objectType": 'enterpriseDevices',
                                "queryType": "enterpriseDevices",
                                "objectId": message.data?.deviceName.split('/')[3],
                                "productType": "mobileSecurity"
                            })
                            store.dispatch(ActionTypes.getObjectTypeCount, { "accountId": accountId, "objectType": 'devices', 'queryType': "enterpriseDevices" })
                        }

                    }
                    if (message.topic == "/android/enterprise/device/remove") {
                        if (config.canUseNewObjectType("androidDevices")) {
                            products.mobileSecurity.iosDevices.useStore?.().deleteObjectTypeObjectFromStore(accountId, message.data?.deviceName.split('/')[3])
                        }
                        else {
                            store.dispatch(ActionTypes.deleteObject, {
                                "accountId": accountId,
                                "objectType": 'enterpriseDevices',
                                "queryType": "enterpriseDevices",
                                "objectId": message.data?.deviceName.split('/')[3],
                                "productType": "mobileSecurity"
                            })
                            store.dispatch(ActionTypes.getObjectTypeCount, { "accountId": accountId, "objectType": 'devices', 'queryType': "enterpriseDevices" })
                        }

                    }
                    if (message.topic == "/device/enrolled") {
                        if (config.canUseNewObjectType('iosDevices')) {
                            products.mobileSecurity.iosDevices.queries.getObjectFromApi(accountId, message.data?.deviceId)
                        }
                        else {
                            store.dispatch(ActionTypes.getObjectInfo, {
                                "accountId": accountId,
                                "objectType": "devices",
                                "queryType": "iosDevices",
                                "objectId": message.data?.deviceId,
                                "productType": "mobileSecurity"
                            })
                            store.dispatch(ActionTypes.getObjectTypeCount, { "accountId": accountId, "objectType": 'devices', 'queryType': "iosDevices" })
                        }

                    }
                    if (message.topic == "/device/unenrolled") {

                        let devices = config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.useStore?.().getObjectStore(accountId).objects : state.session.accounts[accountId]?.mobileSecurity.devices?.items
                        if (devices?.length) {
                            if (config.canUseNewObjectType("iosDevices")) {
                                products.mobileSecurity.iosDevices.useStore?.().setObjectTypeObjectProperty(accountId, message.data?.deviceId, false, "signedIn")
                            }
                            else {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "accountId": accountId,
                                    "objectId": message.data?.deviceId,
                                    "productType": "mobileSecurity",
                                    "objectType": "devices",
                                    "objectPropertyPath": [],
                                    "property": "signedIn",
                                    "value": false
                                })
                            }
                            if (config.canUseNewObjectType("iosDevices")) {
                                products.mobileSecurity.iosDevices.useStore?.().setObjectTypeObjectProperty(accountId, message.data?.deviceId, "", "licenseUUID")
                            }
                            else {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "accountId": accountId,
                                    "objectId": message.data?.deviceId,
                                    "productType": "mobileSecurity",
                                    "objectType": "devices",
                                    "objectPropertyPath": [],
                                    "property": "licenseUUID",
                                    "value": ""
                                })
                            }
                            if (config.canUseNewObjectType("iosDevices")) {
                                products.mobileSecurity.iosDevices.useStore?.().setObjectTypeObjectProperty(accountId, message.data?.deviceId, false, "depDevice")
                            }
                            else {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "accountId": accountId,
                                    "objectId": message.data?.deviceId,
                                    "productType": "mobileSecurity",
                                    "objectType": "devices",
                                    "objectPropertyPath": [],
                                    "property": "depDevice",
                                    "value": false
                                })
                            }
                            if (config.canUseNewObjectType("iosDevices")) {
                                products.mobileSecurity.iosDevices.useStore?.().setObjectTypeObjectProperty(accountId, message.data?.deviceId, false, "isDepDevice")
                            }
                            else {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "accountId": accountId,
                                    "objectId": message.data?.deviceId,
                                    "productType": "mobileSecurity",
                                    "objectType": "devices",
                                    "objectPropertyPath": [],
                                    "property": "isDepDevice",
                                    "value": false
                                })
                            }
                            if (config.canUseNewObjectType("iosDevices")) {
                                products.mobileSecurity.iosDevices.useStore?.().setObjectTypeObjectProperty(accountId, message.data?.deviceId, false, "dep")
                            }
                            else {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "accountId": accountId,
                                    "objectId": message.data?.deviceId,
                                    "productType": "mobileSecurity",
                                    "objectType": "devices",
                                    "objectPropertyPath": [],
                                    "property": "dep",
                                    "value": false
                                })
                            }
                            if (config.canUseNewObjectType("iosDevices")) {
                                products.mobileSecurity.iosDevices.useStore?.().setObjectTypeObjectProperty(accountId, message.data?.deviceId, undefined, "depDeviceInfo")
                            }
                            else {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "accountId": accountId,
                                    "objectId": message.data?.deviceId,
                                    "productType": "mobileSecurity",
                                    "objectType": "devices",
                                    "objectPropertyPath": [],
                                    "property": "depDeviceInfo",
                                    "value": undefined
                                })
                            }
                            if (config.canUseNewObjectType("iosDevices")) {
                                products.mobileSecurity.iosDevices.useStore?.().setObjectTypeObjectProperty(accountId, message.data?.deviceId, [], "iosPerUserChannelProfiles")
                            }
                            else {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "accountId": accountId,
                                    "objectId": message.data?.deviceId,
                                    "productType": "mobileSecurity",
                                    "objectType": "devices",
                                    "objectPropertyPath": [],
                                    "property": "iosPerUserChannelProfiles",
                                    "value": []
                                })
                            }
                        }
                    }

                    if (message.topic == "/device/remove") {
                        if (config.canUseNewObjectType('iosDevices')) {
                            products.mobileSecurity.iosDevices.useStore?.().deleteObjectTypeObjectFromStore(accountId, message.data?.deviceId)
                        }
                        else {
                            store.commit(MutationTypes.deleteObject, {
                                "accountId": accountId,
                                "productType": 'mobileSecurity',
                                "objectType": 'devices',
                                "objectIdProperty": 'deviceId',
                                "objectId": message.data?.deviceId
                            })
                            store.dispatch(ActionTypes.getObjectTypeCount, { "accountId": accountId, "objectType": 'iosDevices' })
                        }
                    }

                    if ((message.topic == "/message/in/create" && message.data?.type == "DEVICE" && message.data?.payload?.deviceType == "IOS")) {
                        if (message.data?.payload?.version) {
                            if (config.canUseNewObjectType("iosDevices")) {
                                products.mobileSecurity.iosDevices.useStore?.().setObjectTypeObjectProperty(accountId, message.data?.deviceId, message.data?.payload.version, "version", ["info"])
                            }
                            else {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "accountId": accountId,
                                    "objectType": "devices",
                                    "objectId": message.data?.deviceId,
                                    "objectPropertyPath": ["info"],
                                    "productType": "mobileSecurity",
                                    "property": "version",
                                    "value": message.data?.payload.version
                                })
                            }
                        }
                        if (message.data?.payload?.OSVersion) {
                            if (config.canUseNewObjectType("iosDevices")) {
                                products.mobileSecurity.iosDevices.useStore?.().setObjectTypeObjectProperty(accountId, message.data?.deviceId, message.data?.payload.OSVersion, "OSVersion", ["info"])
                            }
                            else {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "accountId": accountId,
                                    "objectType": "devices",
                                    "objectId": message.data?.deviceId,
                                    "objectPropertyPath": ["info"],
                                    "productType": "mobileSecurity",
                                    "property": "OSVersion",
                                    "value": message.data?.payload.OSVersion
                                })
                            }
                        }
                    }


                    if (message.topic == "/message/out/create" && message.data?.deviceId && message.data?.type == "STATUS" && message.data?.status == "RECEIVED") {
                        if (config.canUseNewObjectType('iosDevices')) {
                            products.mobileSecurity.iosDevices.queries.getObjectFromApi(accountId, message.data?.deviceId)
                        }
                        else {
                            store.dispatch(ActionTypes.getObjectInfo, {
                                "accountId": accountId,
                                "objectType": "devices",
                                "queryType": "iosDevices",
                                "objectId": message.data?.deviceId,
                                "productType": "mobileSecurity"
                            })
                            store.dispatch(ActionTypes.getObjectTypeCount, { "accountId": accountId, "objectType": 'devices', 'queryType': "iosDevices" })
                        }
                    }
                }
            })
            store.commit(MutationTypes.addSubscriptionHook, {
                "hookKey": "frontendNotifications",
                "hookFunction": (message: any) => {
                    const accountId = store.state.session.activeAccountId || ""
                    const state = store.state
                    if (message.topic == "/tenant/notification") {
                        let severityToIcon: any = {
                            "INFO": "fal fa-info",
                            "WARNING": "fal fa-bell",
                            "ERROR": "fal fa-exclamation"
                        }
                        frontendNotifications.addNotification(accountId, {
                            "accountId": accountId,
                            "id": message.data?.id,
                            "content": {
                                "title": {
                                    "text": T(message.data?.titel || 'Notification'),
                                    "icon": severityToIcon[message.data?.severity] || "fal fa-info"
                                },
                                "body": {
                                    "content": message.data?.message
                                }
                            },
                            "onClick": message.data?.state == "NEW" ? async () => {
                                await requestHandler.request('PUT', '/sms-mgt-api/api/' + config.mgtApiVersion + '/tenants/' + accountId + '.sms/tenant-notifications/' + message.data?.id)
                                store.dispatch(ActionTypes.getNotifications, accountId)
                            } : undefined,
                            "onDelete": validationHelpers.isUUID(message.data?.id) ? async () => {
                                return await queries.unifiedSecurity.deleteTenantNotification(accountId, message.data?.id)
                            } : undefined,
                            "timestamp": message.data?.timestamp,
                            "state": message.data?.state.toLowerCase(),
                            "product": message.data?.productName,
                            "highlightNew": true
                        })
                        if (typeof message.data?.titel == "string" && (message.data.titel.includes("Azure") || message.data.titel.includes("Entra ID"))) {
                            useAzureStore().getImportStatuses(accountId)
                        }
                    }
                }
            })
            store.commit(MutationTypes.addSubscriptionHook, {
                "hookKey": "profiles",
                "hookFunction": async (message: any) => {
                    const accountId = store.state.session.activeAccountId || ""
                    /*
                    if (message.topic == "/profile/publish") {
                        if (message.data?.policyName != undefined) {
                            products.mobileSecurity.androidProfiles.queries.getObjectFromApi(accountId, message.data.policyName)
                            products.mobileSecurity.androidProfiles.queries.getCountFromApi(accountId)
                        }
                    }
                    */
                    if (message.topic == "/profiles/publish/end") {
                        products.mobileSecurity.androidProfiles.queries.getObjectsFromApi(accountId, undefined, undefined, undefined, true)
                        products.mobileSecurity.androidProfiles.queries.getCountFromApi(accountId)
                    }
                }
            })
            store.commit(MutationTypes.addSubscriptionHook, {
                "hookKey": "uscUtms",
                "hookFunction": (message: any) => {
                    const accountId = store.state.session.activeAccountId || ""
                    if (message.topic == "/usc/utm/message") {

                        let utmId = message.data?.utmId || undefined
                        if ("utm-event-startup" == message.data?.clientContext && utmId) {
                            store.commit(MutationTypes.setObjectProperty, {
                                "objectType": "ccutmStates",
                                "productType": "unifiedSecurityConsole",
                                "objectId": utmId,
                                "accountId": accountId,
                                "objectPropertyPath": [],
                                "property": "online",
                                "value": true
                            })
                            if (message.data?.data.offlineReason) {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "objectType": "uscUtms",
                                    "productType": "unifiedSecurityConsole",
                                    "objectId": utmId,
                                    "accountId": accountId,
                                    "objectPropertyPath": [],
                                    "property": "offlineReason",
                                    "value": message.data?.data.offlineReason
                                })
                            }
                        }
                        if ("utm-event-disconnect" == message.data?.clientContext && utmId) {
                            store.commit(MutationTypes.setObjectProperty, {
                                "objectType": "ccutmStates",
                                "productType": "unifiedSecurityConsole",
                                "objectId": utmId,
                                "accountId": accountId,
                                "objectPropertyPath": [],
                                "property": "online",
                                "value": false
                            })
                            if (message.data?.data.offlineReason) {
                                store.commit(MutationTypes.setObjectProperty, {
                                    "objectType": "uscUtms",
                                    "productType": "unifiedSecurityConsole",
                                    "objectId": utmId,
                                    "accountId": accountId,
                                    "objectPropertyPath": [],
                                    "property": "offlineReason",
                                    "value": message.data?.data.offlineReason
                                })
                            }
                        }


                        if ("merged-update-info" == message.data?.clientContext && utmId) {
                            store.commit(MutationTypes.setObjectProperty, {
                                "objectType": "uscUtms",
                                "productType": "unifiedSecurityConsole",
                                "objectId": utmId,
                                "accountId": accountId,
                                "objectPropertyPath": ["messages"],
                                "property": "merged-update-info",
                                "value": message.data?.data
                            })
                        }

                        if ("merged-reboot-info" == message.data?.clientContext && utmId) {
                            store.commit(MutationTypes.setObjectProperty, {
                                "objectType": "uscUtms",
                                "productType": "unifiedSecurityConsole",
                                "objectId": utmId,
                                "accountId": accountId,
                                "objectPropertyPath": ["messages"],
                                "property": "merged-reboot-info",
                                "value": message.data?.data
                            })
                        }

                        if (message.data?.clientContext.indexOf("spcli-system-upgrade") != -1 && utmId) {
                            store.dispatch(ActionTypes.getObjectInfo, {
                                "objectType": "uscUtms",
                                "productType": "unifiedSecurityConsole",
                                "objectId": utmId,
                                "accountId": accountId
                            })
                        }

                        if (message.data?.clientContext.indexOf("spcli-interface-address-get") != -1 && utmId) {
                            store.commit(MutationTypes.setObjectProperty, {
                                "objectType": "uscUtms",
                                "productType": "unifiedSecurityConsole",
                                "objectId": utmId,
                                "accountId": accountId,
                                "objectPropertyPath": ["messages"],
                                "property": "spcli-interface-address-get",
                                "value": message.data?.data
                            })
                        }
                    }
                }
            })

            store.commit(MutationTypes.addSubscriptionHook, {
                "hookKey": "apps",
                "hookFunction": async (message: any) => {
                    const accountId = store.state.session.activeAccountId || ""
                    if (message.topic == "/app/create") {
                        if (message.data?.appId != undefined) {
                            if (config.canUseNewObjectType("iosApps")) {
                                products.mobileSecurity.iosApps.queries.getObjectFromApi(accountId, message.data.appId)
                            }
                        }
                    }
                }
            })

            store.commit(MutationTypes.addSubscriptionHook, {
                "hookKey": "windowsVpnMessages",
                "hookFunction": async (message: any) => {
                    if (typeof message.topic == "string" && message.topic.includes("/windows/vpn/") && message.topic.includes("/message")) {
                        const accountId = store.state.session.activeAccountId || ""
                        const data = message.data?.data
                        const clientContext: VPNWebsocketContexts | undefined = message.data?.clientContext
                        const vpnId: string | undefined = message?.data?.vpnId
                        
                        if (vpnId && (clientContext == 'vpn-system-info' || clientContext == 'vpn-connection-get') && data && data?.error != true) {
                            const vpn = products.unifiedSecurityConsole.windowsVpns.useStore?.().getObjectStoreObject(accountId, vpnId)
                            if(vpn?.messages) {
                                vpn.messages[clientContext as keyof typeof vpn.messages] = data
                                products.unifiedSecurityConsole.windowsVpns.useStore?.().setObjectTypeObject(accountId, vpnId, vpn)
                            }
                        }
                        else if(vpnId && clientContext == 'vpn-disconnect') {
                            const vpnState = products.unifiedSecurityConsole.windowsVpnStates.useStore?.().getObjectStoreObject(accountId, vpnId)
                            if(vpnState) {
                                vpnState.online = false
                                products.unifiedSecurityConsole.windowsVpnStates.useStore?.().setObjectTypeObject(accountId, vpnId, vpnState)
                            }
                        }
                        else if(vpnId && clientContext == 'vpn-startup') {
                            const vpnState = products.unifiedSecurityConsole.windowsVpnStates.useStore?.().getObjectStoreObject(accountId, vpnId)
                            if(vpnState) {
                                vpnState.online = true
                                products.unifiedSecurityConsole.windowsVpnStates.useStore?.().setObjectTypeObject(accountId, vpnId, vpnState)
                            }
                            
                        }
                        else if(vpnId && (clientContext == 'vpn-connection-stop' || clientContext == 'vpn-connection-start' || clientContext == "vpn-connection-remove") && data && data?.error != true) {
                            const vpn = products.unifiedSecurityConsole.windowsVpns.useStore?.().getObjectStoreObject(accountId, vpnId)
                            if(vpn?.messages) {
                                vpn.messages["vpn-connection-get"] = data
                                products.unifiedSecurityConsole.windowsVpns.useStore?.().setObjectTypeObject(accountId, vpnId, vpn)
                            }
                        }
                        else if (vpnId && clientContext == "vpn-security-state-changed" && typeof data?.securityConditionState == 'number') {
                            const vpn = products.unifiedSecurityConsole.windowsVpns.useStore?.().getObjectStoreObject(accountId, vpnId)
                            if (vpn) {
                                vpn.securityConditionState = data.securityConditionState

                                if(vpn.messages?.["vpn-system-info"]?.security && data.wsc) {
                                    vpn.messages["vpn-system-info"].security.wsc = data.wsc
                                }

                                products.unifiedSecurityConsole.windowsVpns.useStore?.().setObjectTypeObject(accountId, vpnId, vpn)
                            }
                        }
                    }
                }
            })

            store.commit(MutationTypes.addSubscriptionHook, {
                "hookKey": "windowsVpnMessages",
                "hookFunction": async (message: any) => {
                    if (typeof message?.topic == "string" && message.topic.includes("/windows/vpn/profile/state_changed")) {

                        const accountId = store.state.session.activeAccountId || ""
                        const data: {
                            [vpnId: string]: {
                                appliedProfileId: string,
                                appliedProfileState: AppliedProfileState
                            }
                        } = message.data?.data || {}
                        const clientContext: VPNWebsocketContexts | undefined = message.data?.clientContext

                        if (clientContext == "profile-state-changed" && Object.keys(data).length > 0) {
                            Object.keys(data).forEach((vpnId) => {
                                const vpn = products.unifiedSecurityConsole.windowsVpns.useStore?.().getObjectStoreObject(accountId, vpnId)
                                if (vpn) {
                                    vpn.appliedProfileId = data[vpnId].appliedProfileId
                                    vpn.appliedProfileState = data[vpnId].appliedProfileState
                                }
                            })
                        }

                    }
                }
            })
        }
    },
    async mounted(this: any) {
        let thisComponent = this
        try {
            let userInfo = await queries.unifiedSecurity.checkAuth()
            if (!userInfo) throw "Not signed in"
            await accounts.getAccounts(userInfo)
        }
        catch (e: any) {
            console.error(e)
            if (this.sessionRequired()) {
                router.navigate('login');
            }
            
            this.initialized = true
            setTimeout(() => {
                if (e.data == "TOTP-Token is missing." || e == "TOTP-Token is missing.") {
                    useRouterStore().setContext("enter2FA")
                }
                else if (e.data == "RSP_2FA_REQUIRED" || e == "RSP_2FA_REQUIRED") {
                    useRouterStore().setContext("rsp2faError")
                }
                else if (e.data == "2FA_REQUIRED" || e == "2FA_REQUIRED") {
                    if (this.$refs.loginpage) {
                        this.$refs.loginpage.twoFaUserError = true
                    }
                }
            },200)
            
        }
        $(window).on("resize", () => { 
            thisComponent.calcTemplateSizes()
        })
    },
    watch: {
        initializedSession: async function (this: any, initialized: boolean) {
            if(!this.sessionRequired()) {
                this.initialized = true
                idleTimer.killIdleTimer()
            }
            else if (initialized && !useStore().state.session.waitForSessionToSetActiveAccountId) {
                this.init()
                idleTimer.initIdleTimer()
                this.websocketChecker = new TaskLooper(10000)
                this.websocketChecker.addTask({
                    "id": "websocketCheck",
                    "method": () => {
                        this.webSocketConnectionChecker()
                    }
                })
                this.websocketChecker.startLimiter()
                this.addWebsocketSubscriptions()
                if (Number(moment().format('YYYYMMDD')) <= Number("20240201")) {
                    objectStores.uscUtms.dialogs.utmForcedUpdateDialog(Object.keys(useStore().state.session.accounts))
                }
                if(useStore().state?.session?.userInfo?.updateNotification?.length) {
                    objectStores.users.renderShowPortalupdatesDialog(this.activeAccountId)
                }
                this.calcTemplateSizes()
            }
            else if (useStore().state.session.waitForSessionToSetActiveAccountId) {
                await timeHelpers.sleep(2000)
                this.init()
                idleTimer.initIdleTimer()
                this.websocketChecker = new TaskLooper(10000)
                this.websocketChecker.addTask({
                    "id": "websocketCheck",
                    "method": () => {
                        this.webSocketConnectionChecker()
                    }
                })
                this.websocketChecker.startLimiter()
                this.addWebsocketSubscriptions()
                if (Number(moment().format('YYYYMMDD')) <= Number("20240201")) {
                    objectStores.uscUtms.dialogs.utmForcedUpdateDialog(Object.keys(useStore().state.session.accounts))
                }
                if (useStore().state?.session?.userInfo?.updateNotification?.length) {
                    objectStores.users.renderShowPortalupdatesDialog(this.activeAccountId)
                }
                this.calcTemplateSizes()
            }
            else {
                idleTimer.killIdleTimer()
            }
        },
        activeTenantDomain: function (this: any, newTenantDomain: any, oldTenantDomain: any) {
            devLog.log('[Base]', 'TenantDomain changed')
            let thisComponent: any = this
            if (newTenantDomain != undefined) {
                nextTick(function () {
                    if (oldTenantDomain == undefined) {
                        nextTick(async function () {
                            
                            // <Deeplinks>
                            // 1. Has MDM  or MobSec License and is in a Deeplink
                            if(licenseHelpers.hasOneOfLicenses(thisComponent.activeAccountId, ["Mobile Security", "MDM"], 'valid') 
                            && window?.location?.href?.includes('#ms')) {
                                router.navigate('show-tenant-' + newTenantDomain + '-mobile-security-dashboard')
                            } 
                            // 2. Has USC License and is in a Deeplink
                            else if(licenseHelpers.hasOneOfLicenses(thisComponent.activeAccountId, ["unifiedSecurityConsole"], 'valid') 
                            && window?.location?.href?.includes('#usc')) {
                                router.navigate('show-tenant-' + newTenantDomain + '-usc-dashboard')
                            } 
                            // 3. Does not have the required License but is in a Deeplink -> Navigate to Dashboard
                            else if(window?.location?.href?.includes('#ms') || window?.location?.href?.includes('#usc')) {
                                router.navigate("show-tenant-" + newTenantDomain + "-dashboard")
                            }
                            // </Deeplinks>

                            

                            if (thisComponent.activePage == undefined) {
                                devLog.log('[Base]', 'Redirecting to Tenant-Dashboard')

                                if (document.URL.split('#')[1]) {
                                    router.navigate("#" + document.URL.split('#')[1])
                                }
                                else {
                                    router.navigate("show-tenant-" + newTenantDomain + "-dashboard")
                                }
                            }
                            else {
                                let canOpenRoute = tenantHelpers.canOpenRoute(tenantHelpers.getAccountId(newTenantDomain), thisComponent.activePage)
                                if (!canOpenRoute) {
                                    router.navigate("show-tenant-" + newTenantDomain + "-dashboard")
                                }
                            }
                        })
                    }
                    else if (thisComponent.activePage != "dashboard") {
                        let canOpenRoute = tenantHelpers.canOpenRoute(tenantHelpers.getAccountId(newTenantDomain), thisComponent.activePage)
                        let hash = new URL(window.location.href).hash
                        if (canOpenRoute) {
                            router.navigate(hash.replace(oldTenantDomain, newTenantDomain))
                        }
                        else {
                            router.navigate("show-tenant-" + newTenantDomain + "-dashboard")
                        }
                    }
                })
                useAzureStore().reset()
                useAzureStore().getImportStatuses(tenantHelpers.getAccountId(newTenantDomain))
                this.calcTemplateSizes()
            }
        },
        'activePage': async function (this: any, activePage: any) {
            $('main').scrollTop(0)
            if (this.initialized) {
                devLog.log('[Base]', 'Active page changed')
                this.calcTemplateSizes()
            }
        },
        "productType": function (this: any, productType: keyof ProductType) {
            if (productType) {
                let productApiKey: any = config[productType as "mobileSecurity" | "unifiedSecurityConsole"]?.googleMapsApiKey
                if (productApiKey) {
                    if (this.googleApi != undefined) {
                        $('head script').each(function (i: number, el: any) {
                            if (el.src.indexOf('maps.googleapis.com') != -1) {
                                el.remove()
                            }
                        })
                        if (window.google) {
                            delete (<any>window).google
                        }
                        this.googleApi = undefined
                    }
                    if (this.googleApi == undefined) {
                        this.googleApi = document.createElement('script')
                        this.googleApi.src = 'https://maps.googleapis.com/maps/api/js?key=' + productApiKey + "&callback=dummyGMapsCallback"
                        this.googleApi.defer = true
                        document.head.appendChild(this.googleApi)
                    }

                    if (this.gapi != undefined) {
                        $('head script').each(function (i: number, el: any) {
                            if (el.src.indexOf('apis.google.com') != -1) {
                                el.remove()
                            }
                        })
                        if (window.google) {
                            delete (<any>window).google
                        }
                        this.gapi = undefined
                    }
                    if (this.gapi == undefined) {
                        this.gapi = document.createElement('script')
                        this.gapi.src = 'https://apis.google.com/js/api.js'
                        this.gapi.defer = true
                        document.head.appendChild(this.gapi)
                    }

                }
                this.calcTemplateSizes()
            }
        },
        'context': function (this: any, context: any) {
            $('main').scrollTop(0)
            if (this.initialized) {
                devLog.log('[Base]', 'Context changed')
            }
        }
    },
    async beforeCreate(this: any) {
        moment.locale(typeof i18n.getLanguage() == "undefined" ? "en" : i18n.getLanguage())
    },

    components: {
        "loader": loaderComponent,
        "login": pageLoginComponent,
        "password-reset": pagePasswordResetComponent,
        "android-code-enrollment": pageAndroidCodeEnrollment,
        "ios-code-enrollment": pageiOSCodeEnrollment,
        "email-subscription": emailSubscriptionComponent,
        "page-header": pageHeaderComponent,
        "modals": modalsComponent,
        "mobile-security-settings": mobileSecuritySettingsComponent,
        "account-info": pageAccountComponent,
        "dashboard": dashboardComponent,
        "privacy": pagePrivacyComponent,
        "audit-log": pageAuditLogComponent,
        "inventory": inventoryComponent,
        "notifications": notificationsComponent,
        "plugin-licenses": pagePluginLicensesComponent,
        "itemlist": itemlistComponent,
        "playground": pagePlaygroundComponent,
        "warnings": pageWarningsComponent,
        "cookie-consent": cookieConsentComponent,
        "main-sidebar": mainSidebarComponent,
        "header-template": mainHeaderComponent,
        "footer-template": mainFooterComponent,
        "sms-page-vppassetdetails": pageVppAssetDetailsComponent,
        "page-vppasset-details": vppAssetDetailsComponent,
        "apppage": appPage,
        "sms-page-device-details": deviceDetailsComponent,
        "sms-page-utm-details": utmDetailsComponent,
        "sms-page-licensedetails": licenseDetailsComponent,
        "sms-page-editpage": editpageComponent,
        "sms-page-statspage": statspageComponent,
        "sms-page-branding": pageBrandingComponent,
        "widgetarea-add-widget": widgetareaAddWidgetComponent,
        "unified-security-settings": unifiedSecuritySettingsComponent,
        "ipadUser-delete":ipadUserDeleteComponent,
        "usc-dashboard": uscDashboardComponent,
        "ms-dashboard": msDashboardComponent,
        "docs-page":docsComponent,
        "android-device-details": androidDeviceDetailsVue,
        "unifiedNetwork": unifiedNetwork,
        "windowsVpnsDetails": windowsVpnsDetails,
        "scrollToTopButton": scrollToTopButton,
        "userLog": userLogComponent,
        "securedns-statspage": secureDnsStatsComponent,
        "securedns-protocols": secureDnsProtocolsComponent,
    }
})
    .use(vuexStore)
    .use(VueGridLayout)
    .use(VNetworkGraph)
    .use(HighchartsVue)
    .use(pinia)
    .mount("#appwrapper")
    
declare global {
    interface Window {
        vue: typeof vue;
    }
}
window.vue = vue



export default vue