import ObjectType, { type AccountId, type GetPropertiesObjectList, type ObjectTypePayload } from "../objectType";
import config from "@/classes/config";
import devLog from "@/classes/log";
import requestHandler from "@/queries/requests";
import apis from "@/classes/apis";
import jsonHelpers from "@/helpers/helpers.json";
import { T } from "@/classes/i18n";
import ipaddr from "@/lib/ipaddr"
import tenantHelpers from "@/helpers/helpers.tenants";
import websocketHandler from "@/classes/websocket";
import getterHelpers from "@/helpers/helpers.getters";
import { MutationTypes } from "@/store/vuex.store";
import products from "..";
import type { CreateNode, CreateNodeGroup } from "./topologies";
import { ref } from "vue";

export interface NetworkViewInterface {
    id: number
    name: string
    description?: string
    isCoreCompatible:boolean
    type: number
    owner_id: number
    permissions: number
    refby_uuids: string[]
    interface_type: number
    flags?: string[]
    addresses?: {
        address:string,
        public:boolean
    }[]
    public_key?: string
    listen_port?: number
    options?: { name: string; value: string }[]
    dynDns?: {
        hostname?: string
    }
}
export interface NetworkViewNodeZone {
    id: number
    name: string
    description: string
    type: number
    permissions: number
    flags: string[]
    zone_interface: string
}
export interface NetworkViewNode {
    id: number
    name: string
    type: number
    static_node_addresses: string[]
    description: string
    owner_id: number
    permissions: number
    refby_uuids: string[]
    node_address: string
    /**
     * If node_refs is not empty, the node is a group node
     */
    node_refs?: string[]
    node_zone: NetworkViewNodeZone
}
export interface NetworkViewService {
    "id": number
    "name": string
    "description": string
    "type": number
    "refby_uuids": string[]
    "protocol_id": number
    "protocol": string
    "ct_helper_id": number
    "ct_helper"?: string
    "src-ports"?: string[],
    "dst-ports"?: string[],
    "service_refs"?:string[]
    /**
     * If the service is not Published but is a Service Group
     */
    "services"?: NetworkViewService[]
}

export interface NetworkViewModel {
    id: number
    source: string
    name: string
    version: number
    ctime: number
    mtime: number
    uuid?: string
}

export interface NetworkView {
    "interfaces":NetworkViewInterface[],
    "nodes":NetworkViewNode[],
    "services": NetworkViewService[],
    "models": NetworkViewModel[]
    
}
export interface UtmNode {
    "utmId":string,
    "nkView": null | NetworkView,
    "errors"?:string[]
}

const geoIPRegEx = /^GEOIP:[A-Z]{2}$/,
    hostnameLabelRegEx = /^[a-z\d_]([a-z\d-_]{0,61}[a-z\d_]){0,1}$/i,
    hostnameLastLabelRegEx = /^[a-z_][a-z-_]{0,61}[a-z_]$/i

export class UtmNodes extends ObjectType<UtmNode> {
    gettingNetworkViews = ref(<string[]>[])
    checkIntegrity = ref(new Set<string>())

    networkViewsWithOldModels : string[] = []

    constructor(payload: ObjectTypePayload<UtmNode>) {
        super(payload)
        /**
        * Gets single object from api
        * @param accountId 
        * @param objectId 
        * @param props
        */
        this.queries.getObjectFromApi = async (accountId, objectId, customerId?, props?, updateStore = true, deleteOldObject = false) => {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            let result: UtmNode | Error
            try {
                if (!objectId) throw "Missing objectId"
                let response = await requestHandler.request(this.options.apiInfo.getObjectMethod, this.getSingleObjectUrl(accountId,objectId,customerId) + propertiesString)
                response = apis.parseApiResponse(response)
                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = response as UtmNode
                }
                else {
                    throw "Error getting objects"
                }
                if (updateStore) {
                    if (Array.isArray(result)) {
                        let nodes = result as UtmNode[]
                        nodes.forEach((node) => {
                            if(deleteOldObject == true) {
                                this.useStore?.().deleteObjectTypeObjectFromStore(accountId, node.utmId)
                            }
                            this.useStore?.().setObjectTypeObject(accountId, String(node.utmId), node)
                        })

                    }
                    else {
                        if(deleteOldObject == true) {
                            this.useStore?.().deleteObjectTypeObjectFromStore(accountId, result.utmId)
                        }
                        this.useStore?.().setObjectTypeObject(accountId, String(result.utmId), result)    
                    }
                    
                }
                return result
            }
            catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        }
    }
    getLocalNodesOrServices = async (accountId: string, nodeId: string, topologyId: string, type: "objects" | "services"): Promise<Error | { [key: string]: NetworkViewService | NetworkViewNode }> => {
        let result: Error | { [key: string]: NetworkViewService | NetworkViewNode }
        try {
            if (!nodeId) throw "Missing nodeId"

            let objects = await requestHandler.request("GET", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/sun/topologies/" + topologyId + "/nodes/" + nodeId + "/" + type)
            
            objects = apis.parseApiResponse(objects)
            if (typeof objects == "object") {
                result = objects
            }
            else {
                throw "Error getting objects"
            }
            return objects
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    addNetworkObject = async (accountId: string, nodeId: string, topologyId: string,payload:CreateNode | CreateNodeGroup,method:"POST"|"PUT"="POST") => {
        try {
            if (!nodeId) throw "Missing accountId"
            if (!nodeId) throw "Missing nodeId"
            if (!nodeId) throw "Missing topologyId"
            if (!payload) throw "Missing payload"
            let response = requestHandler.request(method, config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/sun/topologies/" + topologyId + "/nodes/" + nodeId + "/objects", payload)
            return response
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }

    

    view = {
        nodeIsGeoIP: (node:NetworkViewNode) => {
            let nodeAddress = typeof node === 'string' ? node : node.node_address;
            return geoIPRegEx.test(nodeAddress!);
        },
        getHostnameValidationMessage: (text: string, forceTestWithTrailingDot:boolean) => {
            let extendedText = forceTestWithTrailingDot === true && text[text.length - 1] !== '.' ? text + '.' : text,
                labels = extendedText.split('.'),
                hasTrailingDot = extendedText[extendedText.length - 1] === '.',
                invalidLabels: string[];

            if (extendedText.length < 1) {
                return T('Too short!');
            } 
            else if (hasTrailingDot && extendedText.length > 255) {
                return T('Too long!');
            } 
            else if (!hasTrailingDot && extendedText.length > 254) {
                return T('Too long!');
            } 
            else {
                if (hasTrailingDot) {
                    labels = labels.slice(0, -1);

                    invalidLabels = labels.filter(function (label, i) {
                        if (i < labels.length - 1) {
                            return !hostnameLabelRegEx.test(label);
                        } else {
                            return !hostnameLastLabelRegEx.test(label);
                        }
                    });
                } else {
                    invalidLabels = labels.filter(function (label, i) {
                        return !hostnameLabelRegEx.test(label);
                    });
                }

                if (invalidLabels.length > 0) {
                    return T('Some labels are invalid:') + ' ' + invalidLabels.join(' ');
                } else {
                    return '';
                }
            }
        },
        getIconClassForNode: (node : NetworkViewNode) => {
            let type : "host"|"network"|"world"|"vpn-host"|"vpn-network"|"interface"|"geo-ip" = 'network'
            if (node.node_address) {
                const nodeIpAddr = new ipaddr(node.node_address)
                const nodeIpCIDR = nodeIpAddr.cidr
                const zone = node.node_zone

                if (this.view.nodeIsGeoIP(node)) {
                    type = 'geo-ip';
                } 
                else {
                    if (nodeIpAddr.isValidIP6Address()) {
                        if (nodeIpCIDR == 128) {
                            type = 'host';
                        } 
                        else if (nodeIpCIDR == 0 && node.node_zone?.name !== 'internal' && node.node_zone?.name !== 'internal_v6') {
                            type = 'world';
                        }
                    } 
                    else if (nodeIpAddr.isValidIP4Address()) {
                        if (nodeIpCIDR == 32) {
                            type = 'host';
                        } 
                        else if (nodeIpCIDR == 0 && node.node_zone?.name !== 'internal' && node.node_zone?.name !== 'internal_v6') {
                            type = 'world';
                        }
                    } 
                    else if (nodeIpAddr.addr !== null && this.view.getHostnameValidationMessage(nodeIpAddr.addr, true) === '') {
                        type = 'host'
                    }
                }

                var zoneFlags = zone.flags || [];
                if (zone.name.indexOf('vpn-') === 0 || zoneFlags.indexOf('POLICY_IPSEC') > -1 || zoneFlags.indexOf('PPP_VPN') > -1) {
                    if (type === 'network') {
                        type = 'vpn-network';
                    } 
                    else if (type === 'host') {
                        type = 'vpn-host';
                    }
                } 
                else if (zoneFlags.indexOf('INTERFACE') > -1) {
                    type = 'interface';
                }
            }
            return 'icon icon-node-' + type;
        },
        getNodeTypeNameFromNodeType: (type: "host" | "network" | "world" | "vpn-host" | "vpn-network" | "interface" | "geo-ip") => {
            const map = {
                "host":"Network Objects",
                "network":"Network Group",
                "world":"World",
                "vpn-host":"VPN Host",
                "vpn-network":"VPN Network",
                "interface":"Interface",
                "geo-ip":"Geo-IP"
            }
            return map[type]
        },
        getServiceInfo: (nodes: UtmNode[], utmId: string, serviceId: number) => {
            let utmNode = nodes.find((utmNode) => {
                return utmNode.utmId == utmId
            })
            if (utmNode) {
                return utmNode.nkView?.services.find((service: any) => {
                    return service.id == serviceId
                })
            }
            else {
                return undefined
            }
        },
        waitForNetworkViewViaWebsocket: (accountId:string,utmId:string,onMessage?:() => void) => {
            if(websocketHandler.hasHookWithId("networkView/"+utmId) == false) {
                products.unifiedSecurityConsole.utmNodes.gettingNetworkViews.value.push(utmId)
                getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
                    "accountId": accountId,
                    "hookKey": "networkView/"+utmId,
                    "hookFunction": async (message: any) => {
                        if (message.topic == "/usc/utm/" + utmId + "/message" && message.data?.clientContext == "handled-model-nwkview-get") {
                            const networkView = {
                                nkView: message.data.data,
                                utmId: message.data.utmId
                            }
                            products.unifiedSecurityConsole.utmNodes.useStore?.().setObjectTypeObjects(accountId,[networkView])
                            onMessage?.()
                            products.unifiedSecurityConsole.utmNodes.gettingNetworkViews.value = products.unifiedSecurityConsole.utmNodes.gettingNetworkViews.value.filter((id) => { return id !== utmId })
                            this.view.deleteNetworkViewWebsocketHook(accountId,utmId)
                        }
                    }
                })
            }
        },
        deleteNetworkViewWebsocketHook: (accountId:string,utmId:string) => {
            getterHelpers.useStore().commit(MutationTypes.deleteSubscriptionHook, {
                "accountId": accountId,
                "hookKey": "networkView/"+utmId,
            })
        }
    }
}


const utmNodes = new UtmNodes({
    "objectType": "utmNodes",
    "productType": "unifiedNetwork",
    "slug": "utmNodes",
    "hasStore": true,
    "objectTypeInfo": {
        "nameProperty": {
            "primary": "utmId"
        },
        "primaryKeyProperty": {
            "property": "utmId"
        },
    },
    "appearance": {
        "color": "red",
        "iconClass": "fal fa-server",
        "showInSidebar": true,
        "text": {
            "plural": "UTM Nodes",
            "sidebarName": "UTM Nodes",
            "title": "UTM Nodes",
            "singular": "UTM Node"
        }
    },
    "apiInfo": {
        "getCountGETProperties": "?props[]=null&select=data.total",
        "url": config.mgtApiUriNext,
        "getObjectListPath": "/tenants/{tenantDomain}/sun/topologies/nodes/utms/network-view",
        "getObjectPath": "/tenants/{tenantDomain}/sun/topologies/nodes/utms/network-view?ids[]={objectId}"
    }

})
export default utmNodes