import tenantHelpers from "@/helpers/helpers.tenants";
import requestHandler from "@/queries/requests";

export interface GetEnrollment {
    valid: boolean;
}

export interface CheckinEnrollment {
    redirectURL: string;
}

export class CodeEnrollment {
    private enrollmentId: string;

    constructor(enrollmentId: string) {
        this.enrollmentId = enrollmentId;
    }

    public async validate(): Promise<GetEnrollment | false> {
        let result: GetEnrollment | false = false;
        try {
            result = await requestHandler.request("GET", `/sms-mgt-api/api/2.0/enroll/${this.enrollmentId}/pin`);
        } catch (e: any) {
            if (e.status === 401) {
                // Handle 401, e.g. redirect to error page
                // app.router.push({'name':'401'});
            } else {
                console.error(e);
            }
            result = false;
        }
        return result;
    }

    public async checkin(payload: { pin: string }): Promise<CheckinEnrollment | false> {
        let result: CheckinEnrollment | false = false;
        try {
            result = await requestHandler.request("POST", `/sms-mgt-api/api/2.0/enroll/${this.enrollmentId}/pin`, payload);
        } catch (e: any) {
            if (e.status === 401) {
                // Handle 401, e.g. redirect to error page
                // app.router.push({'name':'401'});
            } else {
                console.error(e);
            }
            result = false;
        }
        return result;
    }
}

export default CodeEnrollment;
