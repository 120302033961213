<script setup lang="ts">
    import Drawflow, { type DrawflowConnection, type DrawflowConnectionDetail, type DrawflowModuleData, type DrawflowNode } from 'drawflow'
    import { onMounted, shallowRef, h, getCurrentInstance, render, readonly, ref, computed } from 'vue'
    import NodePeer from './unified-network-drawflow-node-peer.vue'
    import buttonComponent from './button/button.vue';
    import Button from "@/templates/components/button/button";
    import { T } from '@/classes/i18n';
    import getterHelpers from '@/helpers/helpers.getters';
    import { ActionTypes, MutationTypes } from '@/store/vuex.store';
import tenantHelpers from '@/helpers/helpers.tenants';

    interface DrawflowNodeSUNAdd{
        name: string,
        inputs: number,
        outputs: number,
        pos_x: number,
        pos_y: number,
        class: string,
        html: string,
        typenode: boolean | string,
        data?:{            

        }
    }

    interface SunDrawflowApiInputs {
        [input:string]:{
            connections?:Array<{
                node:string // refers to dfNodeId
                input:string // refers to specific input of node
            }>|[]
        }
    }
    interface SunDrawflowApiOutputs {
        [input: string]: {
            connections?: Array<{
                node: string // refers to dfNodeId
                output: string // refers to specific output of node
            }>|[]
        }
    }
    interface SunDrawflowApiObject {
        drawflow:{
            id: number, // id of node (1,2,3,4....)
            class: "utm"|"peer" // type of node
            inputs: SunDrawflowApiInputs, // input nodes
            outputs: SunDrawflowApiOutputs, // output nodes
            posX: number, // x position
            posY: number // y position
        }
        data: { // All additional data goes in here
            title: string // Title of node
        }
    }



    const listNodes = readonly(<DrawflowNodeSUNAdd[]>[
        {
            class: 'client',
            name: 'nodePeerClient',
            html:'nodePeer',
            typenode:"vue",
            inputs:<DrawflowNodeSUNAdd['inputs']> 0,
            outputs: <DrawflowNodeSUNAdd['outputs']> 1,
            data:{
                title: "Client",
                icon: "fal fa-mobile"
            },
            pos_x:0,
            pos_y:0
        },
        {
            class: 'utm',
            name: 'nodePeerServing',
            html: 'nodePeer',
            typenode: "vue",
            inputs: <DrawflowNodeSUNAdd['inputs']>1,
            outputs: <DrawflowNodeSUNAdd['outputs']>0,
            data: {
                title: "UTM",
                icon: "fal fa-server"
            },
            pos_x: 0,
            pos_y: 0
        }
    ])

    const activeAccountId = computed(() => {
        return getterHelpers.useStore().getters.getActiveAccountId
    })

    const editor = shallowRef(<undefined| Drawflow>undefined)
        
    const dialogVisible = ref(false)
    const dialogData = ref({})
    let selectedItem = ref('');
    let lastMoveEvent = ref(<any>null);

    const Vue = { version: 3, h, render };
    const internalInstance = getCurrentInstance()
    if(internalInstance) {
        internalInstance.appContext.app._context.config.globalProperties.$df = editor;
    }

    
    const positionMobile = (ev: any) => {
        lastMoveEvent = ev;
    }

    const drag = (ev:any) => {
        if (ev.type === "touchstart") {
            selectedItem.value = ev.target.closest(".drag-drawflow").getAttribute('data-node');
        } else {
            ev.dataTransfer.setData("node", ev.target.getAttribute('data-node'));
        }
    }
    const drop = (ev:any) => {
        if(Document != null) {
            if (ev.type === "touchend") {
                var parentdrawflow = document.elementFromPoint(lastMoveEvent.touches[0].clientX, lastMoveEvent.touches[0].clientY)?.closest("#drawflow");
                if (parentdrawflow != null) {
                    addNodeToDrawFlow(selectedItem.value, lastMoveEvent.touches[0].clientX, lastMoveEvent.touches[0].clientY);
                }
                selectedItem.value = '';
            } 
            else {
                ev.preventDefault();
                var data = ev.dataTransfer.getData("node");
                addNodeToDrawFlow(data, ev.clientX, ev.clientY);
            }
        }

    }
    const allowDrop = (ev:any) => {
        ev.preventDefault();
    }

    
    const addNodeToDrawFlow = (name:string, pos_x:number, pos_y:number) => {
        if(editor.value) {
            pos_x = pos_x * (editor.value.precanvas.clientWidth / (editor.value.precanvas.clientWidth * editor.value.zoom)) - (editor.value.precanvas.getBoundingClientRect().x * (editor.value.precanvas.clientWidth / (editor.value.precanvas.clientWidth * editor.value.zoom)));
            pos_y = pos_y * (editor.value.precanvas.clientHeight / (editor.value.precanvas.clientHeight * editor.value.zoom)) - (editor.value.precanvas.getBoundingClientRect().y * (editor.value.precanvas.clientHeight / (editor.value.precanvas.clientHeight * editor.value.zoom)));
            const nodeSelected = listNodes.find(ele => ele.class == name);
            if(nodeSelected) {
                editor.value.addNode(name, nodeSelected.inputs, nodeSelected.outputs, pos_x, pos_y, name, {}, name, 'vue');
            }
        }
    }

    onMounted(() => {

        var elements = document.getElementsByClassName('drag-drawflow');
        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener('touchend', drop, false);
            elements[i].addEventListener('touchmove', positionMobile, false);
            elements[i].addEventListener('touchstart', drag, false);
        }

        const element = document.getElementById("drawflow");
        if(element) {
            editor.value = new Drawflow(element, Vue, internalInstance?.appContext.app._context);
            editor.value.editor_mode = "edit"
            editor.value.zoom_max = 2
            editor.value.zoom_min = 0.4
            editor.value.start();

            editor.value.registerNode('nodePeer', NodePeer, {}, {});

            editor.value.import({
            "drawflow": {
                "Home": {
                    "data": {
                        "1": {
                            "id": 1,
                            "class": "utm",
                            "html": "nodePeer",
                            "data": {
                                "title": "UTM - Hamburg - Core-UTM"
                            },
                            "typenode": "vue",
                            "inputs": {
                                "input_1": {
                                    "connections": [
                                        {
                                            "node": "2",
                                            "input": "output_1"
                                        },
                                        {
                                            "node": "3",
                                            "input": "output_1"
                                        }
                                    ]
                                }
                            },
                            "outputs": {

                            },
                            "pos_x": 560,
                            "pos_y": 122
                        },
                        "2": {
                            "id": 2,
                            "class": "client",
                            "html": "nodePeer",
                            "data": {
                                "title": "UTM - Office - Lüneburg"
                            },
                            "typenode": "vue",
                            "inputs": {

                            },
                            "outputs": {
                                "output_1": {
                                    "connections": [
                                        {
                                            "node": "1",
                                            "output": "input_1"
                                        }
                                    ]
                                }
                            },
                            "pos_x": 27,
                            "pos_y": 218
                        },
                        "3": {
                            "id": 3,
                            "class": "client",
                            "html": "nodePeer",
                            "data": {
                                "title": "UTM - Lager - Lüneburg"
                            },
                            "typenode": "vue",
                            "inputs": {

                            },
                            "outputs": {
                                "output_1": {
                                    "connections": [
                                        {
                                            "node": "1",
                                            "output": "input_1"
                                        }
                                    ]
                                }
                            },
                            "pos_x": 27,
                            "pos_y": 28
                        }
                    }
                    
                }
            }
        })
        }
    })

    const toggleFullscreen = () => {
        getterHelpers.useVue()?.toggleFullscreen()
    }

    const showData = () => {

        let frontendObject = editor.value?.drawflow.drawflow.Home.data
        let apiObject : SunDrawflowApiObject[] = []
        if(frontendObject) {
            apiObject = Object.keys(frontendObject).map((key) => {
                    return <SunDrawflowApiObject>{
                        "drawflow":{
                            "id":frontendObject?.[key].id,
                            "class":frontendObject?.[key].class,
                            "inputs":frontendObject?.[key].inputs as SunDrawflowApiInputs,
                            "outputs":frontendObject?.[key].outputs as SunDrawflowApiOutputs,
                            "posX":frontendObject?.[key].pos_x,
                            "posY":frontendObject?.[key].pos_y,
                        },
                        "data":{
                            "title":frontendObject?.[key].data?.title || "",
                        }
                    }
            })
        }



        getterHelpers.useStore().dispatch(ActionTypes.addModal,{
            "id":"showData",
            "accountId":activeAccountId.value,
            "abortable":true,
            "content":{
                "title":{
                    "text":"JSON",
                    "icon":"fal fa-file-code",
                },
                "body":{
                    "use":true,
                    "component":undefined,
                    "content":"<pre>"+JSON.stringify(apiObject,null,2)+"</pre>"
                },
            },
            "buttons":[
                {
                    loading:false,
                    onClick:async function() {
                        getterHelpers.useStore().commit(MutationTypes.removeModal, { "accountId":activeAccountId.value })
                    },
                    icon:"fal fa-check",
                    text:'Nice!',
                    align:"right",
                    disabled:false
                }
            ]
        })
    }

    const fullscreenToggle = ref(new Button({
        "icon":"fa fa-expand",
        "type":"icon", 
        "title":T('Toggle fullscreen'),
        "onClick":toggleFullscreen
    }))
    const showDataButton = ref(new Button({
        "icon":"fa fa-eye",
        "type":"icon", 
        "title":T('Show data'),
        "onClick":showData
    }))

</script>

<template>
    <div style="position: relative;" class="drawflowWrapper">
        <div id="drawflow" class="unified-network" @drop="drop($event)" @dragover="allowDrop($event)"></div>
        <div id="drawflow-controls" >
            <div class="row" style="margin:0;">
                <div class="col-xs-12">
                    &nbsp;
                </div>
                <div class="col-xs-12 text-right">
                    <buttonComponent :button-options="showDataButton"></buttonComponent>        
                    <buttonComponent :button-options="fullscreenToggle"></buttonComponent>        
                </div>
            </div>
        </div>
    </div>
</template>



<style lang="scss">

    #drawflow.unified-network {
        min-height:calc(100vh - 150px - 64px - 46px);
        height:calc(100vh - 150px - 64px - 46px);
        position: relative;
    }
    main.fullscreen {
        overflow: hidden;
    }
    main.fullscreen section.pagecontent div.drawflowWrapper {
        height:100vh;
        margin:-32px;
    }
    main.fullscreen section.pagecontent div.drawflowWrapper #drawflow.unified-network {
        height:100vh;
    }

    #drawflow-controls {
        position: absolute;
        z-index:2;
        background:rgba(0,0,0,0.2);
        left:0;
        right:0;
        bottom:0;
        height:32px;
        line-height:32px;
    }


    

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #494949;
    }
    .container {
        min-height: calc(100vh - 100px);
    }
    .column {
        border-right: 1px solid #494949;
    }
    .column ul {
        padding-inline-start: 0px;
        padding: 10px 10px;
    
    }
    .column li {
        background: transparent;
    }

    .node {
        border-radius: 8px;
        border: 2px solid #494949;
        display: block;
        height:60px;
        line-height:40px;
        padding: 10px;
        margin: 10px 0px;
        cursor: move;

    }




    body {
        --dfBackgroundColor: rgba(249, 249, 249, 1);
        --dfBackgroundSize: 19px;
        --dfBackgroundImage: radial-gradient(rgba(225, 225, 225, 1) 1px, transparent 1px);

        --dfNodeType: flex;
        --dfNodeTypeFloat: none;
        --dfNodeBackgroundColor: rgba(255, 255, 255, 1);
        --dfNodeTextColor: rgba(0, 0, 0, 0.6);
        --dfNodeBorderSize: 0px;
        --dfNodeBorderColor: rgba(0, 0, 0, 0.1);
        --dfNodeBorderRadius: 3px;
        --dfNodeMinHeight: 72px;
        --dfNodeMinWidth: 256px;
        --dfNodePaddingTop: 0px;
        --dfNodePaddingBottom: 0px;
        --dfNodeBoxShadowHL: 0px;
        --dfNodeBoxShadowVL: 0px;
        --dfNodeBoxShadowBR: 0px;
        --dfNodeBoxShadowS: 1px;
        --dfNodeBoxShadowColor: rgba(0, 0, 0, 0.05);

        --dfNodeHoverBackgroundColor: rgba(255, 255, 255, 1);
        --dfNodeHoverTextColor: rgba(0, 0, 0, 0.6);
        --dfNodeHoverBorderSize: 0px;
        --dfNodeHoverBorderColor: rgba(0, 0, 0, 0.05);
        --dfNodeHoverBorderRadius: 3px;

        --dfNodeHoverBoxShadowHL: 0px;
        --dfNodeHoverBoxShadowVL: 0px;
        --dfNodeHoverBoxShadowBR: 2px;
        --dfNodeHoverBoxShadowS: 1px;
        --dfNodeHoverBoxShadowColor: rgba(0, 0, 0, 0.05);

        --dfNodeSelectedBackgroundColor: rgba(255, 255, 255, 1);
        --dfNodeSelectedTextColor: rgba(0, 0, 0, 0.6);
        --dfNodeSelectedBorderSize: 0px;
        --dfNodeSelectedBorderColor: rgba(0, 0, 0, 0.6);
        --dfNodeSelectedBorderRadius: 3px;

        --dfNodeSelectedBoxShadowHL: 0px;
        --dfNodeSelectedBoxShadowVL: 0px;
        --dfNodeSelectedBoxShadowBR: 2px;
        --dfNodeSelectedBoxShadowS: 2px;
        --dfNodeSelectedBoxShadowColor: rgba(0, 0, 0, 0.05);

        --dfInputBackgroundColor: #ffffff;
        --dfInputBorderSize: 1px;
        --dfInputBorderColor: rgba(0, 0, 0, 0.1);
        --dfInputBorderRadius: 50px;
        --dfInputLeft: -8px;
        --dfInputHeight: 16px;
        --dfInputWidth: 16px;

        --dfInputHoverBackgroundColor: rgba(255, 255, 255, 1);
        --dfInputHoverBorderSize: 1px;
        --dfInputHoverBorderColor: rgba(0, 0, 0, 0.21);
        --dfInputHoverBorderRadius: 50px;

        --dfOutputBackgroundColor: #ffffff;
        --dfOutputBorderSize: 1px;
        --dfOutputBorderColor: rgba(0, 0, 0, 0.1);
        --dfOutputBorderRadius: 50px;
        --dfOutputRight: 8px;
        --dfOutputHeight: 16px;
        --dfOutputWidth: 16px;

        --dfOutputHoverBackgroundColor: #ffffff;
        --dfOutputHoverBorderSize: 1px;
        --dfOutputHoverBorderColor: rgba(0, 0, 0, 0.21);
        --dfOutputHoverBorderRadius: 50px;

        --dfLineWidth: 2px;
        --dfLineColor: rgba(188, 188, 188, 1);
        --dfLineHoverColor: rgba(157, 157, 157, 1);
        --dfLineSelectedColor: rgba(229, 43, 43, 1);

        --dfRerouteBorderWidth: 1px;
        --dfRerouteBorderColor: rgba(0, 0, 0, 0.1);
        --dfRerouteBackgroundColor: rgba(188, 188, 188, 1);

        --dfRerouteHoverBorderWidth: 1px;
        --dfRerouteHoverBorderColor: rgba(0, 0, 0, 0.1);
        --dfRerouteHoverBackgroundColor: rgba(229, 43, 43, 1);

        --dfDeleteDisplay: block;
        --dfDeleteColor: rgba(0, 0, 0, 0.6);
        --dfDeleteBackgroundColor: rgba(255, 255, 255, 1);
        --dfDeleteBorderSize: 1px;
        --dfDeleteBorderColor: rgba(0, 0, 0, 0.1);
        --dfDeleteBorderRadius: 50px;
        --dfDeleteTop: -15px;

        --dfDeleteHoverColor: rgba(255, 255, 255, 1);
        --dfDeleteHoverBackgroundColor: rgba(229, 43, 43, 1);
        --dfDeleteHoverBorderSize: 1px;
        --dfDeleteHoverBorderColor: rgba(0, 0, 0, 0.1);
        --dfDeleteHoverBorderRadius: 50px;
        &.darkmode {
            --dfBackgroundColor: #25262A;
            --dfBackgroundImage: radial-gradient(rgb(67, 67, 67) 1px, transparent 1px);

            --dfNodeBackgroundColor: #303338;
            --dfNodeTextColor: rgba(255, 255, 255, 0.6);
            --dfNodeBorderColor: rgba(0, 0, 0, 0.1);
            --dfNodeBoxShadowColor: rgba(0, 0, 0, 0.05);

            --dfNodeHoverBackgroundColor: #303338;
            --dfNodeHoverTextColor: rgba(255, 255, 255, 0.6);
            --dfNodeHoverBorderColor: rgba(0, 0, 0, 0.05);

            --dfNodeHoverBoxShadowColor: rgba(0, 0, 0, 0.05);

            --dfNodeSelectedBackgroundColor: #303338;
            --dfNodeSelectedTextColor: rgba(255, 255, 255, 0.6);
            --dfNodeSelectedBorderColor: rgba(0, 0, 0, 0.6);
            --dfNodeSelectedBoxShadowColor: rgba(0, 0, 0, 0.05);

            --dfInputBackgroundColor: #202125;
            --dfInputBorderColor: rgba(0, 0, 0, 0.1);

            --dfInputHoverBackgroundColor: #202125;
            --dfInputHoverBorderColor: rgba(0, 0, 0, 0.21);

            --dfOutputBackgroundColor: #202125;
            --dfOutputBorderSize: 1px;
            --dfOutputBorderColor: rgba(0, 0, 0, 0.1);
            --dfOutputBorderRadius: 50px;
            --dfOutputRight: 8px;
            --dfOutputHeight: 16px;
            --dfOutputWidth: 16px;

            --dfOutputHoverBackgroundColor: #202125;
            --dfOutputHoverBorderSize: 1px;
            --dfOutputHoverBorderColor: rgba(0, 0, 0, 0.21);
            --dfOutputHoverBorderRadius: 50px;

            --dfLineWidth: 2px;
            --dfLineColor: rgba(188, 188, 188, 1);
            --dfLineHoverColor: rgba(157, 157, 157, 1);
            --dfLineSelectedColor: rgba(229, 43, 43, 1);

            --dfRerouteBorderWidth: 1px;
            --dfRerouteBorderColor: rgba(0, 0, 0, 0.1);
            --dfRerouteBackgroundColor: rgba(188, 188, 188, 1);

            --dfRerouteHoverBorderWidth: 1px;
            --dfRerouteHoverBorderColor: rgba(0, 0, 0, 0.1);
            --dfRerouteHoverBackgroundColor: rgba(229, 43, 43, 1);

            --dfDeleteDisplay: block;
            --dfDeleteColor: rgba(0, 0, 0, 0.6);
            --dfDeleteBackgroundColor: rgba(255, 255, 255, 1);
            --dfDeleteBorderSize: 1px;
            --dfDeleteBorderColor: rgba(0, 0, 0, 0.1);
            --dfDeleteBorderRadius: 50px;
            --dfDeleteTop: -15px;

            --dfDeleteHoverColor: rgba(255, 255, 255, 1);
            --dfDeleteHoverBackgroundColor: rgba(229, 43, 43, 1);
            --dfDeleteHoverBorderSize: 1px;
            --dfDeleteHoverBorderColor: rgba(0, 0, 0, 0.1);
            --dfDeleteHoverBorderRadius: 50px;
        }
    }

    #drawflow {
        background: var(--dfBackgroundColor);
        background-size: var(--dfBackgroundSize) var(--dfBackgroundSize);
        background-image: var(--dfBackgroundImage);
    }

    .drawflow .drawflow-node {
        display: var(--dfNodeType);
        background: var(--dfNodeBackgroundColor);
        color: var(--dfNodeTextColor);
        border: var(--dfNodeBorderSize)  solid var(--dfNodeBorderColor);
        border-radius: var(--dfNodeBorderRadius);
        min-height: var(--dfNodeMinHeight);
        width: auto;
        min-width: var(--dfNodeMinWidth);
        padding-top: var(--dfNodePaddingTop);
        padding-bottom: var(--dfNodePaddingBottom);
        -webkit-box-shadow: var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR) var(--dfNodeBoxShadowS) var(--dfNodeBoxShadowColor);
        box-shadow:  var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR) var(--dfNodeBoxShadowS) var(--dfNodeBoxShadowColor);
        padding:0;
    }

    .drawflow .drawflow-node:hover {
        background: var(--dfNodeHoverBackgroundColor);
        color: var(--dfNodeHoverTextColor);
        border: var(--dfNodeHoverBorderSize)  solid var(--dfNodeHoverBorderColor);
        border-radius: var(--dfNodeHoverBorderRadius);
        -webkit-box-shadow: var(--dfNodeHoverBoxShadowHL) var(--dfNodeHoverBoxShadowVL) var(--dfNodeHoverBoxShadowBR) var(--dfNodeHoverBoxShadowS) var(--dfNodeHoverBoxShadowColor);
        box-shadow:  var(--dfNodeHoverBoxShadowHL) var(--dfNodeHoverBoxShadowVL) var(--dfNodeHoverBoxShadowBR) var(--dfNodeHoverBoxShadowS) var(--dfNodeHoverBoxShadowColor);
    }

    .drawflow .drawflow-node.selected {
        background: var(--dfNodeSelectedBackgroundColor);
        color: var(--dfNodeSelectedTextColor);
        border: var(--dfNodeSelectedBorderSize)  solid var(--dfNodeSelectedBorderColor);
        border-radius: var(--dfNodeSelectedBorderRadius);
        -webkit-box-shadow: var(--dfNodeSelectedBoxShadowHL) var(--dfNodeSelectedBoxShadowVL) var(--dfNodeSelectedBoxShadowBR) var(--dfNodeSelectedBoxShadowS) var(--dfNodeSelectedBoxShadowColor);
        box-shadow:  var(--dfNodeSelectedBoxShadowHL) var(--dfNodeSelectedBoxShadowVL) var(--dfNodeSelectedBoxShadowBR) var(--dfNodeSelectedBoxShadowS) var(--dfNodeSelectedBoxShadowColor);
    }

    .drawflow .drawflow-node .input {
        left: var(--dfInputLeft);
        background: var(--dfInputBackgroundColor);
        border: var(--dfInputBorderSize)  solid var(--dfInputBorderColor);
        border-radius: var(--dfInputBorderRadius);
        height: var(--dfInputHeight);
        width: var(--dfInputWidth);
    }

    .drawflow .drawflow-node .input:hover {
        background: var(--dfInputHoverBackgroundColor);
        border: var(--dfInputHoverBorderSize)  solid var(--dfInputHoverBorderColor);
        border-radius: var(--dfInputHoverBorderRadius);
    }

    .drawflow .drawflow-node .outputs {
        float: var(--dfNodeTypeFloat);
    }

    .drawflow .drawflow-node .output {
        right: var(--dfOutputRight);
        background: var(--dfOutputBackgroundColor);
        border: var(--dfOutputBorderSize)  solid var(--dfOutputBorderColor);
        border-radius: var(--dfOutputBorderRadius);
        height: var(--dfOutputHeight);
        width: var(--dfOutputWidth);
    }

    .drawflow .drawflow-node .output:hover {
        background: var(--dfOutputHoverBackgroundColor);
        border: var(--dfOutputHoverBorderSize)  solid var(--dfOutputHoverBorderColor);
        border-radius: var(--dfOutputHoverBorderRadius);
    }

    .drawflow .connection .main-path {
        stroke-width: var(--dfLineWidth);
        stroke: var(--dfLineColor);
    }

    .drawflow .connection .main-path:hover {
        stroke: var(--dfLineHoverColor);
    }

    .drawflow .connection .main-path.selected {
        stroke: var(--dfLineSelectedColor);
    }

    .drawflow .connection .point {
        stroke: var(--dfRerouteBorderColor);
        stroke-width: var(--dfRerouteBorderWidth);
        fill: var(--dfRerouteBackgroundColor);
    }

    .drawflow .connection .point:hover {
        stroke: var(--dfRerouteHoverBorderColor);
        stroke-width: var(--dfRerouteHoverBorderWidth);
        fill: var(--dfRerouteHoverBackgroundColor);
    }

    .drawflow-delete {
        display: var(--dfDeleteDisplay);
        color: var(--dfDeleteColor);
        background: var(--dfDeleteBackgroundColor);
        border: var(--dfDeleteBorderSize) solid var(--dfDeleteBorderColor);
        border-radius: var(--dfDeleteBorderRadius);
    }

    .parent-node .drawflow-delete {
        top: var(--dfDeleteTop);
    }

    .drawflow-delete:hover {
        color: var(--dfDeleteHoverColor);
        background: var(--dfDeleteHoverBackgroundColor);
        border: var(--dfDeleteHoverBorderSize) solid var(--dfDeleteHoverBorderColor);
        border-radius: var(--dfDeleteHoverBorderRadius);
    }


</style>