<script setup lang="ts">
    import TableNext, { type TableEntryInfo } from '../tableNext.vue';
    const props = defineProps<{
        properties: {
            accountId:string,
            utmId:string
        },
        maximized:boolean
    }>();

    const maxTableHeight = 300
    const networkObjects: any[] = []

    const tableCollumns: TableEntryInfo[] = []
</script>
<template>
    <TableNext
        :max-height="maxTableHeight" 
        :selectable-columns="tableCollumns"
        :subTablePaddingLeft="45" 
        :object-list="networkObjects"
        :has-options="false" 
        :is-searchable="false" 
        :is-filterable="false"
    ></TableNext>
</template>