<script setup lang="ts">
    import config from '@/classes/config';
    import i18n from '@/classes/i18n';
    import timeHelpers from '@/helpers/helpers.time';

    const props = withDefaults(defineProps<{
        showAppName?:boolean
        disableNavigation?:boolean
    }>(),
    {
        showAppName: true,
        disableNavigation: false
    });

    const currentYear = timeHelpers.getCurrentDate('YYYY')
    const version = config.version

    const getFooterLinks = () => {
        let result : string = ""
        if(i18n.getLanguage() == "de") {
            result = '<a href="https://www.securepoint.de/unternehmen/impressum.html" target="_blank" rel="noreferrer noopener">Impressum</a> | <a href="https://www.securepoint.de/unternehmen/agb.html" target="_blank" rel="noreferrer noopener">AGB</a> | <a href="https://www.securepoint.de/unternehmen/datenschutz.html" target="_blank" rel="noreferrer noopener">Datenschutz</a> | <a href="#pluginLicenses">Lizenzen</a>'
        } else {
            result = '<a href="https://www.securepoint.de/en/company/legal-notice.html" target="_blank" rel="noreferrer noopener">Imprint</a> | <a href="https://www.securepoint.de/en/company/terms-conditions.html" target="_blank" rel="noreferrer noopener">GTC</a> | <a href="https://www.securepoint.de/en/company/data-protection.html" target="_blank" rel="noreferrer noopener">Privacy</a>  | <a href="#pluginLicenses">Licenses</a>'
        }
        return result
    }
    
</script>
<template>
    <footer v-if="disableNavigation" class="main-footer text-center">
        <div v-if="showAppName" class="float-md-right hidden-xs">Securepoint Unified Security <small>{{version}}</small></div>
        Copyright &copy; 2016 - {{ currentYear }} Securepoint GmbH
    </footer>
    <footer v-else class="main-footer">
        <div v-if="showAppName" class="float-md-right hidden-xs">Securepoint Unified Security <small>{{version}}</small></div>
        Copyright &copy; 2016 - {{ currentYear }} <a href="https://www.securepoint.de/" target="_blank" rel="noreferrer noopener">Securepoint GmbH</a>&nbsp;&nbsp;•&nbsp;&nbsp;<span id="footerlinks" class="media-screen-only" v-html="getFooterLinks()" v-once></span>
    </footer>
</template>