<script setup lang="ts">
import { type VPNWebsocketContexts, type Connection } from '@/classes/objectTypes/mobileSecurity/windows/windowsVpns';
import { computed, onUnmounted, ref, watch } from 'vue';
import loader from '../components/loader.vue';
import { T } from "@/classes/i18n"
import { onMounted } from 'vue';
import requestHandler from '@/queries/requests';
import config from '@/classes/config';
import getterHelpers from '@/helpers/helpers.getters';
import tenantHelpers from '@/helpers/helpers.tenants';
import { MutationTypes, useStore } from '@/store/vuex.store';
import { type TableEntryInfo, type TableEntryStatus } from '../components/tableNext.vue';
import inputTextComponent from '../inputtypes/input-text.vue';
import buttonComponent from "../components/button/button.vue"
import Button from '../components/button/button';
import moment from 'moment';
import { debounce } from 'throttle-debounce';
import TableNext from '../components/tableNext.vue';
import cellStatus from '../components/cellStatus.vue';
import { useVue } from '@/app';
import i18n from '@/classes/i18n';

const props = defineProps<{
    properties: {
        connection: Connection,
        vpnId: string
    }
}>();

const toggleState = () => {
    if(currentState.value == "infoView") currentState.value = "logView";
    else currentState.value = "infoView"
}
// Ref Values
const loading = ref(false)
const search = ref("")
const currentState = ref(<"logView" | "infoView">"logView")
const infoButton = computed(() => {
    return new Button({
        'disabled': false,
        'loading': false,
        'icon': 'fal fa-info-circle',
        'size': 'sm',
        'onClick': () => {
            toggleState()
            calcHeight()
        },
        'id': 'toggleInfoButton',
        'title': T('Informationen anzeigen'),
        'htmlTooltip': false,
        "style": currentState.value == 'infoView' ? `color: white; stroke: white; background: #e43725;` : undefined
    })
})


//TODO Write Type for LogEntry
type LogEntry = {
    "source": string,
    "isodate": number,
    "severity": "Info" | "Warning" | "Error",
    "data": string,
}
const severityToStatus: { [key: string]: (logEntry: LogEntry) => TableEntryStatus } = {
    "Info": (logEntry) => {
        return {
            "color": "green",
            "tooltip": logEntry.severity
        }
    },
    "Warning": (logEntry) => {
        return {
            "color": "yellow",
            "tooltip": logEntry.severity
        }
    },
    "Error": (logEntry) => {
        return {
            "color": "red",
            "tooltip": logEntry.severity
        }
    }
}
const filterLog = debounce(1000, () => {
    if (search.value != "") {
        const thisLog: LogEntry[] = JSON.parse(JSON.stringify(logEntries.value))
        displayedLogEntries.value = thisLog.filter((logEntry) => {
            if (logEntry?.data?.toLowerCase().includes(search.value.toLowerCase())) return true;
            if (logEntry?.severity?.toLowerCase().includes(search.value.toLowerCase())) return true;
            if (logEntry?.source?.toLowerCase().includes(search.value.toLowerCase())) return true;
            if(typeof logEntry.isodate == 'number' && (<string>getDate(logEntry.isodate, true)).toLowerCase().includes(search.value.toLowerCase())) return true
        })
    }
    else {
        displayedLogEntries.value = JSON.parse(JSON.stringify(logEntries.value))
    }
})
const logEntries = ref(<LogEntry[]>[])
const displayedLogEntries = ref(<LogEntry[]>[])

const connectionLogOptions: TableEntryInfo[] = [
    {
        "text": T("Datum"),
        "property": "isodate",
        "displayType": "text",
        "getValue": (entry: LogEntry) => {
            return getDate(entry.isodate, true)
        },
        "getSortValue": (entry: LogEntry) => {
            return getDate(entry.isodate, true)
        },
        "width": 145,
        "rowClassList": ["connectionLogEntry"],
    },
    {
        "property": "severity",
        "width": i18n.getLanguage() == "en" ? 90 : 115,
        "text": T("Schweregrad"),
        "displayType": "status",
        "getSortValue": (entry: LogEntry) => {
            return entry.severity
        },
        "getValue": (entry: LogEntry) => {
            return severityToStatus[entry.severity] ?  severityToStatus[entry.severity](entry) : {
                "color": "grey",
                "tooltip": T("Keine Daten")
            }
        }
    },
    {
        "text": T("Nachricht"),
        "property": "data",
        "displayType": "text",
        "getValue": (entry: LogEntry) => {
            return entry.data
        }
    }
]
const calcHeight = () => {
    if(useVue()?.$refs?.modals?.$refs?.modal?.getMaximizedState() != true) {
        if(currentState.value == "infoView") {
            tableMaxHeight.value = 330
        }
        else {
            tableMaxHeight.value = 456
        }
    }
    else {
        // MaxHeight = WindowHeight - ModalHeader - ModalFooter - Searchbar - Info
        // Modal-Header: 48 px;
        // Modal-Footer: 68.5 px;
        // Searchbar and paddings: 170 px
        const maxHeight = window.innerHeight - (48 + 65.5 + 170)
        if(currentState.value == "infoView") {
            tableMaxHeight.value = maxHeight - 126
        }
        else {
            tableMaxHeight.value = maxHeight
        }
    }
    // Workaround for not loading table
    toggleState()
    toggleState()
}
// Computed Values
const activeAccountId = computed(() => { return getterHelpers.useStore().getters.getActiveAccountId })
const tableMaxHeight = ref(0)

const getDate = (timestamp: any, dateTime: boolean = false) => {
    let date: any = ""
    if (typeof timestamp == "number") {
        date = moment.unix(timestamp).format(dateTime ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY")
    }
    else {
        date = moment(timestamp).format(dateTime ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY")
    }
    return date;
}

onMounted(async () => {
    loading.value = true
    try {
        const response = await requestHandler.request("POST", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(activeAccountId.value) + "/windows/devices/" + props.properties.vpnId + "/jobs/vpn", {
            "name": "connection-logs",
            "data": {
                "connectionId": props.properties.connection.id
            }
        })

        useStore().commit(MutationTypes.addSubscriptionHook, {
            "accountId": activeAccountId.value,
            "hookKey": "vpnConnectionLog",
            "hookFunction": (message: any) => {
                const messageTopic = message?.topic || message?.data?.topic || ""
                const clientContext: VPNWebsocketContexts = message?.data?.clientContext || ""
                const data: LogEntry[] = message?.data?.data?.logs || []
                if (typeof messageTopic == "string" && messageTopic.includes("/windows/vpn/") && clientContext == "vpn-connection-logs" && Array.isArray(data)) {
                    logEntries.value = data
                    displayedLogEntries.value = data
                    loading.value = false
                    setTimeout(calcHeight, 1)
                }
            }
        })
    }
    catch (e: unknown) {
        console.error(e)
    }
})

watch(search, () => {
    filterLog()
})

onUnmounted(() => {
    useStore().commit(MutationTypes.deleteSubscriptionHook, {
        "accountId": activeAccountId.value,
        "hookKey": "vpnConnectionLog",
    })
})

defineExpose({
    calcHeight
})

</script>

<template>
    <template v-if="loading == true">
        <div class="text-size-3 text-center padding-xs-t-4">
            <div class="text-size-2">
                <loader class="color-red"></loader>
            </div>
        </div>
        <div class="text-center padding-xs-t-2" style="opacity:0.8">
            <span>
                {{ T("Hole Logeinträge...") }}
            </span>
        </div>
    </template>
    <template v-else>
        <div class="padding-xs-2">
            <!-- Searchbar -->
            <div class="row margin-xs-b">
                <div class="col-xs-22 col-lg-22">
                    <inputTextComponent v-model="search" :placeholder="T('Suche Logeinträge')"></inputTextComponent>
                </div>
                <div class="col-xs-2 col-lg-2 text-right">
                    <buttonComponent class="active" :button-options="infoButton"></buttonComponent>
                </div>
            </div>

            <div class="row margin-xs-b-2" v-if="currentState == 'infoView'">
                <div class="col-lg-22 col-xs-22 margin-xs-l">
                    <!-- Description -->
                    {{ T("Das Verbindungs-Log des VPN-Clients kann nach den Schweregraden gefiltert oder sortiert werden. Die Schweregrade sind:") }}

                    <!-- Status: -->
                    <div class="flexrow margin-xs-l margin-xs-t">
                        <div class="col-xs-24">
                            <cellStatus :line-height="22" :align="'left'" :status="{
                                'color': 'green',
                                'statusText': T('Information(Info)'),
                                'tooltip': T('Information(Info)')
                            }">
                            </cellStatus>
                        </div>
                    </div>
                    <div class="flexrow margin-xs-l">
                        <div class="col-xs-24">
                            <cellStatus :line-height="22" :align="'left'" :status="{
                                'color': 'yellow',
                                'statusText': T('Warnungen(Warning)'),
                                'tooltip': T('Warnungen(Warning)')
                            }">
                            </cellStatus>
                        </div>
                    </div>
                    <div class="flexrow margin-xs-l">
                        <div class="col-xs-24">
                            <cellStatus :line-height="22" :align="'left'" :status="{
                                'color': 'red',
                                'statusText': T('Fehler(Error)'),
                                'tooltip': T('Fehler(Error)')
                            }">
                            </cellStatus>
                        </div>
                    </div>
                </div>
            </div>

            <TableNext class="table-s" :selectableColumns="connectionLogOptions" :objectList="displayedLogEntries"
                :is-filterable="false" :is-searchable="false" :has-options="false" :max-height="tableMaxHeight">
            </TableNext>
        </div>
    </template>
</template>
<style>
    .connectionLogEntry {
        max-height: 10px !important;
        height: 10px !important;
        box-sizing: border-box !important;
    }
 
</style>