import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "../components/loader.vue";
import requestHandler from "@/queries/requests";
import i18n, { T } from "@/classes/i18n";
import { MutationTypes, useStore } from "@/store/vuex.store";
import useRouterStore from "@/router/routerStore";
import objectStores from "@/classes/init";
import validationHelpers from "@/helpers/helpers.validation";
import inputPinVue from "../inputtypes/input-pin.vue";
import config from "@/classes/config";


const LANG = i18n.getLanguage()

const utmPrivacyPolicyDialogComponent = {
    mixins: [useGlobalMixin()],
    "name":"utm-privacy-policy-dialog",
    "template": `
        <div>
            <template v-if="initialized && !loading">
                <div class="box-border padding-xs-2" style="height: 350px;overflow: auto;width:100%;">
                    <div class="padding-xs-t padding-xs-x" v-html="privacyPolicy"></div>
                </div>
                <div class="padding-xs-2">
                    <p>    
                        <label class="checkbox">
                            <input type="checkbox" v-model="accepted"><span></span>{{ T("I agree with the privacy policy") }}
                        </label>
                    </p>
                    <p>
                        <label class="checkbox">
                            <input type="checkbox" v-model="saveDecision"><span></span>  {{ T("Don't ask me again to accept this privacy policy") }}
                        </label>
                    </p>
                </div>
                <template v-if="needsPin">
                    <div class="text-center" v-if="errors.length > 0">
                        <template v-for="error in errors">
                            <p class="label bg-red">
                                <i class="fal fa-fw fa-exclamation"></i> {{ T(error) }}
                            </p>
                            <br>
                        </template>
                    </div>
                    <div class="row padding-xs-t form-group">
                        <div class="input col-xs-24 text-center">
                            <label class="form-field" style="display:inline-block">
                                <inputPin v-model="pinEntered" :pinLength="6" :numbersOnly="true"
                                    v-on:submit="submit" :focusOnMount="true" :isDisabled="false"></inputPin>
                            </label>
                        </div>
                    </div>
                    <div class="row padding-xs-t">
                        <div class="col-xs-24">
                            <p class="text-center text-italic">
                                {{ T('To increase security, the new authentication layer is mandatory from UTM version 12.7.0.') }}
                            </p>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else>
                <div class="text-center padding-xs-y-8 col-xs">
                    <div class="text-size-3"><loader class="text-size-2 color-red" /></div>
                    <span v-if="loaderInfo"  style="opacity: 0.8;">{{ T(loaderInfo) }}</span>
                </div>
            </template>
        </div>
    `,
    "data":() => {
        return {
            "initialized":false,
            "loading": false,
            "loaderInfo": T("Waiting for UTM Info..."),
            "privacyPolicy":undefined,
            "accepted":false,
            "saveDecision":false,
            "needsPin": false,
            "utmVersion": "0",
            "pinEntered": "",
            "errors": []
        }
    },
    "computed": {
        "utm": function (this: any) {
            this.refreshCount;
            let utm: any = useStore().getters.getObject({
                "accountId": this.activeAccountId,
                "productType": "unifiedSecurityConsole",
                "objectType": "uscUtms",
                "objectId": useRouterStore().getObjectId || ""
            })
            return utm
        }
    },
    "props": {
        "properties":{
            required:true,
            default:() => {
                return {
                    "callback":undefined
                }
            }
        }
    },
    "methods": {
        "init": async function(this:any) {
            this.privacyPolicy = await requestHandler.request("GET",'client/assets/common/license-conditions/dist/html/'+(<"de"|"en">LANG)+'/utm-privacy-policy.html')
            await objectStores.uscUtms.sendSpcliMessage(this.activeAccountId, this.utm.utmId, { "data": ["system", "info"] })
            this.initialized = true
        },
        "submit": async function (this: any) {
            this.errors = []
            useStore().getters.getActiveModal(this.activeAccountId).buttons[1].loading = true
            useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = true
            const result: any = {
                accepted: this.accepted,
                saveDecision: this.saveDecision,
                pinEntered: this.pinEntered
            }
            this.properties.callback(result)
        }
    },
    "created": async function(this:any) {},
    "mounted": function(this:any) {
        let thisComponent = this
        this.loading = true
        this.init()
        useStore().commit(MutationTypes.addSubscriptionHook, {
            "accountId": thisComponent.activeAccountId,
            "hookKey": "utmPrivacyPolicyDialog",
            "hookFunction": async function (message: any) {
                if (message.topic == ("/usc/utm/" + thisComponent.utm.utmId + "/message")) {
                    let jobType: string = message.data?.type || "spcli"
                    let jobContext: string = message.data?.clientContext.replace(jobType + '-', '').split('-').join(' ') || "UNKNOWN"
                    let messageStatus: number = message.data?.status || message.data?.data?.status
                    console.log(jobContext)
                    console.log(message)
                    try {
                        if ((messageStatus == 500 || messageStatus == 403) && jobContext == 'system upgrade confirm') {
                            let jobStatusText: string = message.data?.data?.message || message.data?.data?.hint?.message || message.data?.data?.[0]?.message || message.data?.data?.payload?.[0]?.message || ''
                            if (jobStatusText == 'locked') jobStatusText = T('The UTM was locked due to too many failed attempts')

                            if (thisComponent.errors.indexOf(T(jobStatusText)) == -1) {
                                thisComponent.errors.push(T(jobStatusText))
                                thisComponent.errors = thisComponent.errors
                            }
                            useStore().getters.getActiveModal(thisComponent.activeAccountId).buttons[1].loading = false
                            useStore().getters.getActiveModal(thisComponent.activeAccountId).buttons[1].disabled = false
                        }
                        if (jobContext == "system info") {
                            let data: any = message.data?.data || {}
                            if (data?.version != undefined) {
                                thisComponent.utmVersion = data.version
                                if (validationHelpers.versionCompare(data.version || "0", config.unifiedSecurityConsole.utmVersionForPin) === -1) {
                                    thisComponent.needsPin = false
                                }
                                else {
                                    thisComponent.needsPin = true
                                }
                                thisComponent.loading = false
                            }
                        }
                        if (messageStatus == 200 && jobContext == "system upgrade confirm") {
                            useStore().commit(MutationTypes.removeModal, {
                                "accountId": thisComponent.activeAccountId
                            })
                        }
                    }
                    catch (e: unknown) {
                        console.error(e)
                    }
                }
            }
        })
    },
    "watch": {
        "accepted": function (this: any, accepted: boolean) {
            if (accepted) {
                if (this.needsPin == true) {
                    if (this.pinEntered.length == 6) useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = false;
                }
                else {
                    useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = false
                }
            }
            else {
                useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = true
            }
        },
        "pinEntered": function (this: any) {
            if (this.needsPin == true) {
                if (this.pinEntered.length < 6) {
                    useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = true
                }
                else if (this.accepted) {
                    useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = false
                }
            }
        }
    },
    "beforeUnmount": function(this:any) {
        useStore().commit(MutationTypes.deleteSubscriptionHook, {
            "accountId": this.activeAccountId,
            "hookKey": "utmPrivacyPolicyDialog"
        })
    },
    "components": {
        "loader":loaderComponent,
        "inputPin": inputPinVue
    }
}
export default utmPrivacyPolicyDialogComponent
