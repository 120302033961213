import ObjectType, { type AccountId, type AddObjectTypeObject, type ObjectTypeObject, type ObjectTypePayload } from "../../objectType"
import jsonHelpers from "@/helpers/helpers.json"

export interface DepDevice {
    color:string
    description:string
    device_assigned_by:string
    device_assigned_date:string
    device_family:string
    model:string
    os:string
    profile_assign_time:string
    profile_status:string
    profile_uuid:string
    serial_number:string
    depDevice:true
    depOnly:boolean
}

class DepDevices extends ObjectType<DepDevice> {
    constructor(payload: ObjectTypePayload<DepDevice>) {
        super(payload)
    }
    /**
    * Converts Object for ObjectTypeStore
    */
    convertObjectForStore(accountId: AccountId, objectBase: AddObjectTypeObject<DepDevice>) {
        objectBase.depDevice = true
        objectBase.depOnly = true
    }
}

const depDevices = new DepDevices({
    "productType": "mobileSecurity",
    "slug": "depdevices",
    "objectType": "depDevices",
    "hasStore": true,
    "appearance": {
        "iconClass": "fal fa-mobile-alt",
        "text": {
            "plural": "Devices",
            "title": "Devices",
            "sidebarName": "Devices",
            "singular": "Device"
        },
        "color": "red",
        "showInSidebar": true,
        "showOnDashboard": true,
    },
    "objectTypeInfo": {
        "primaryKeyProperty": {
            "property": "serial_number",
            "pathToPrimaryProperty": undefined
        },
        "nameProperty": {
            "primary": "alias",
            "pathToPrimaryProperty": undefined,
            "secondary": undefined,
            "pathToSecondaryProperty": undefined
        }
    },
    "apiInfo": {
        "url": "/sms-mgt-api/api/1.1",
        "getCountGETProperties": "?props[]=null&select=count",
        // GET
        "getObjectListResponseProperty": "devices",
        "getObjectListMethod": "POST",
        "getObjectListPath": "/tenants/{tenantDomain}/ios/dep/server/devices",
        // Add
        "addObjectMethod": "POST",
        "addObjectPath": "/tenants/{tenantDomain}/ios/dep/server/devices",
        // Update
        "updateObjectMethod": "PUT",
        "updateObjectPath": "/tenants/{tenantDomain}/ios/dep/server/devices/{objectId}",
        // Delete
        "deleteObjectPath": "/tenants/{tenantDomain}/ios/dep/server/devices/{objectId}",
    }
})
export default depDevices